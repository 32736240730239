import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Typography } from 'antd';
import { Button, useKeyboardListener } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveProject,
  getOpenCurrentPreviewModal,
} from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../../theme/utils';
import {
  apiDownloadDocumentPreview,
  apiDownloadDriveItemThumbnails,
  apiDownloadProjectMailAttachmentPreview,
} from '../../api';
import { Center } from '../../../../components/Center';
import { urltoFile } from '../../../../util';
import {
  colorForAttachmentIcon,
  colorForMimeType,
  iconForAttachment,
  iconForMimeType,
} from '../../util';
import { apiDownloadAttachmentToCache } from '../../../mail/api';
import { putMessageAttachmentToCache } from '../../../mail/actions/actionControllers/messageActionController';
import { DriveItem, Preview } from '../../../../models/Drive';
import { MessageAttachment } from '../../../../models/Message';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useGetLocalUrlByDriveId } from '../../hooks/useGetLocalUrlByDriveId';
import classNames from 'classnames';
import PreviewModalMetaDataForm from './PreviewModalMetaDataForm';
import { setCurrentPreviewModalVisibility } from '../../actions/previewModal';
import { ListRowProps } from 'react-virtualized';
import { VirtualListRef } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualList';
import PreviewModalNavBar from './PreviewModalNavBar';
import PreviewModalPreviewComponent from './PreviewModalPreviewComponent';
import PreviewModalMetaDataFormMultiSelect from './PreviewModalMetaDataFormMultiSelect';
import ArrowKeyList from '@prio365/prio365-react-library/lib/VirtualList/components/ArrowKeyList';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  preview: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    left: 0,
    position: 'fixed',
    top: 0,
    paddingTop: '50px',
    height: '100%',
    width: '100%',
    zIndex: 1008,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
  },
  previewContainer: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.spacing.unit(1),
    zIndex: 1009,
    height: '95%',
  },
  previewItemsPadding: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.spacing.unit(2),
  },
  buttonRight: {
    right: 0,
    position: 'fixed',
    top: 0,
    marginTop: '40px',
    height: '100%',
    width: '5%',
    zIndex: 1006,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    '& > div > button > svg': {
      color: theme.old.palette.chromaticPalette.lightGrey,
    },
  },
  buttonLeft: {
    left: 0,
    position: 'fixed',
    top: 0,
    marginTop: '40px',
    height: '100%',
    width: '5%',
    zIndex: 1007,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    '& > div > button > svg': {
      color: theme.old.palette.chromaticPalette.lightGrey,
    },
  },
  documentList: {
    overflow: 'hidden',
  },
  documentItem: {
    '&:hover': {
      cursor: 'pointer',
    },
    padding: '8px 0px',
    fontSize: 14,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  documentItemLabel: {
    marginLeft: theme.spacing.unit(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listHeight: {
    height: 'calc(100% - 31px)',
  },
  fullHeight: {
    height: '100%',
  },
  secondRowHeight: {
    height: 'calc(100% - 56px)',
  },
}));

interface PreviewModalProps {}

export const PreviewModal: React.FC<PreviewModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listRef = useRef<VirtualListRef>(null);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeProjectId = useSelector(getActiveProject);
  const previewModal = useSelector(getOpenCurrentPreviewModal);
  const groupId = previewModal.groupId;
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(null);
  const [preview, setPreview] = useState<Preview>(null);
  const { getLocalUrlByDriveItem } = useGetLocalUrlByDriveId();
  const [selectedDriveItems, setSelectedDriveItems] = useState<
    (DriveItem | MessageAttachment)[]
  >(previewModal.selectedDriveItems ?? undefined);
  const [waitedOneSecond, setWaitedOneSecond] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

  const isInitialMulti = useMemo(() => {
    return previewModal.selectedDriveItems?.length > 0;
  }, [previewModal.selectedDriveItems]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClosePreview = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      setCurrentPreviewModalVisibility(
        false,
        '',
        '',
        null,
        [],
        null,
        '',
        '',
        false,
        []
      )
    );
    setPreview(null);
    setPageNumber(null);
    setIsLoading(true);
  };

  const fetchAttachmentBytes = useCallback(async () => {
    //Attachment is Image
    const currentAttachment = previewModal.itemList[
      pageNumber
    ] as MessageAttachment;

    const fetchAndSetAttachmentImage = async () => {
      setIsLoading(true);
      let blob: Blob = null;
      if (!currentAttachment.contentBytes) {
        blob = await apiDownloadAttachmentToCache(
          previewModal.projectId,
          previewModal.messageId,
          currentAttachment
        );
      } else {
        blob = await urltoFile(
          `data:${currentAttachment.contentType};base64,${currentAttachment.contentBytes}`,
          currentAttachment.name,
          currentAttachment.contentType
        );
      }

      if (blob === null) {
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.loadPreviewError'),
        });
      }

      setPreview({
        type: 'image',
        src:
          blob !== null
            ? URL.createObjectURL(blob)
            : currentAttachment.contentBytes,
        item: {
          ...currentAttachment,
          contentBytes:
            blob !== null
              ? URL.createObjectURL(blob)
              : currentAttachment.contentBytes,
        },
        mimeType: currentAttachment.contentType,
      });
      dispatch(
        putMessageAttachmentToCache(
          previewModal.projectId,
          previewModal.messageId,
          (previewModal.itemList as MessageAttachment[]).map((_attachment) => {
            if (_attachment.id === currentAttachment.id) {
              return {
                ..._attachment,
                contentBytes:
                  blob !== null
                    ? URL.createObjectURL(blob)
                    : _attachment.contentBytes,
              };
            }
            return _attachment;
          })
        )
      );
      setIsLoading(false);
    };

    //Attachment is no Image
    const fetchAndSetAttachmentPreview = async () => {
      setIsLoading(true);
      const result = await apiDownloadProjectMailAttachmentPreview(
        previewModal.projectId,
        previewModal.messageId,
        currentAttachment.id
      );

      if (result.data) {
        setPreview({
          type: 'iframe',
          src: result.data['url'],
          item: { ...currentAttachment, contentBytes: result.data['url'] },
          mimeType: currentAttachment.contentType,
        });
      } else {
        setPreview({
          type: 'iframe',
          src: null,
          item: { ...currentAttachment, contentBytes: '' },
          mimeType: currentAttachment.contentType,
        });
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.loadPreviewError'),
        });
      }

      setIsLoading(false);
    };

    if (pageNumber !== null) {
      if (
        currentAttachment.contentType === 'image/png' ||
        currentAttachment.contentType === 'image/jpeg'
      ) {
        fetchAndSetAttachmentImage();
      } else {
        fetchAndSetAttachmentPreview();
      }
    }
  }, [
    previewModal.projectId,
    previewModal.messageId,
    previewModal.itemList,
    pageNumber,
    dispatch,
    t,
  ]);

  const fetchDocumentBytes = useCallback(async () => {
    const document = previewModal.itemList[pageNumber] as DriveItem;
    //Document is Image
    const fetchAndSetDocumentImage = async () => {
      setIsLoading(true);
      const result = await apiDownloadDriveItemThumbnails(
        document.id,
        previewModal.groupId
      );
      if (result.data) {
        setPreview({
          type: 'image',
          item: document,
          src: result.data[0].large['url'],
          optional: {
            driveItemWebUrl: document.webUrl,
            lastModifiedDateTime: document.lastModifiedDateTime,
          },
          mimeType: document.file.mimeType,
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.loadPreviewError'),
        });
      }
      setIsLoading(false);
    };

    //Document is no Image

    const fetchAndSetDocumentPreview = async () => {
      setIsLoading(true);
      const result = await apiDownloadDocumentPreview(
        document.id,
        previewModal.groupId
      );
      const localUrl = await getLocalUrlByDriveItem(
        previewModal.groupId,
        activeProjectId,
        document,
        null,
        true
      );
      if (result.data) {
        setPreview({
          type: 'iframe',
          src: result.data['url'],
          item: document,
          optional: {
            localDriveItemUrl: localUrl,
            driveItemWebUrl: document.webUrl,
            lastModifiedDateTime: document.lastModifiedDateTime,
          },
          mimeType: document?.file?.mimeType,
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.loadPreviewError'),
        });
      }
      setIsLoading(false);
    };

    if (pageNumber !== null) {
      if (
        document?.file?.mimeType === 'image/png' ||
        document?.file?.mimeType === 'image/jpeg'
      ) {
        fetchAndSetDocumentImage();
      } else {
        fetchAndSetDocumentPreview();
      }
    }
  }, [
    activeProjectId,
    previewModal.itemList,
    previewModal.groupId,
    pageNumber,
    t,
    getLocalUrlByDriveItem,
  ]);

  const buttonLeftClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const buttonRightClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (pageNumber !== previewModal.itemList?.length - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const onActiveChange = useCallback((index: number, previousIndex: number) => {
    if (index !== previousIndex) {
      setPageNumber(index);
    }
  }, []);

  const isDriveItem = (
    item: DriveItem | MessageAttachment
  ): item is DriveItem => {
    return 'listItemFields' in (item ?? []);
  };

  const rowRendererDriveItemList = useCallback(
    ({ index }: ListRowProps) => {
      const item = previewModal.itemList[index];
      return (
        <div
          onClick={() => setPageNumber(index)}
          className={classes.documentItem}
          style={{
            padding: !(previewModal.driveItem && previewModal.groupId)
              ? 8
              : undefined,
          }}
        >
          <FontAwesomeIcon
            icon={[
              'fas',
              isDriveItem(item)
                ? iconForMimeType((item as DriveItem)?.file?.mimeType)
                : iconForAttachment(item as MessageAttachment),
            ]}
            color={
              isDriveItem(item)
                ? colorForMimeType((item as DriveItem)?.file?.mimeType)
                : colorForAttachmentIcon(
                    (item as MessageAttachment)?.contentType
                  )
            }
          ></FontAwesomeIcon>
          <span className={classes.documentItemLabel} title={item.name}>
            {item.name}
          </span>
        </div>
      );
    },
    [
      previewModal.itemList,
      previewModal.driveItem,
      previewModal.groupId,
      classes,
    ]
  );

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setSelectedDriveItems(previewModal.selectedDriveItems ?? []);
  }, [previewModal.selectedDriveItems]);

  useEffect(() => {
    setPageNumber(previewModal.pageNumber);
  }, [previewModal.pageNumber]);

  useEffect(() => {
    if (previewModal.visibility) {
      if (previewModal.driveItemId && previewModal.groupId) {
        fetchDocumentBytes();
      } else {
        fetchAttachmentBytes();
      }
    }
  }, [
    previewModal.visibility,
    previewModal.driveItemId,
    previewModal.groupId,
    pageNumber,
    fetchAttachmentBytes,
    fetchDocumentBytes,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setWaitedOneSecond(previewModal.visibility);
    }, 400);
  }, [previewModal.visibility]);

  useEffect(() => {
    if (pageNumber || previewModal.pageNumber) {
      listRef?.current?.setActiveIndex(pageNumber ?? previewModal.pageNumber);
      listRef?.current?.forceUpdate();
    }
  }, [previewModal.pageNumber, pageNumber]);

  useEffect(() => {
    if (!isInitialRender) {
      if (selectedDriveItems?.length === 0 && isInitialMulti) {
        listRef?.current?.setActiveIndex(0);
        listRef?.current?.forceUpdate();
        setPageNumber(0);
      }
    } else {
      setIsInitialRender(false);
    }
  }, [selectedDriveItems, isInitialRender, isInitialMulti]);

  useKeyboardListener(
    previewModal.visibility
      ? {
          ArrowLeft: () => {
            if (pageNumber !== 0) {
              setPageNumber(pageNumber - 1);
            }
          },
          ArrowRight: () => {
            if (pageNumber !== previewModal.itemList?.length - 1) {
              setPageNumber(pageNumber + 1);
            }
          },
        }
      : {}
  );
  //#endregion

  return (
    <>
      {previewModal.visibility && waitedOneSecond && (
        <>
          <div
            className={classNames('prio-deprecated-design', classes.preview)}
            onClick={handleClosePreview}
          >
            <Center>
              <div
                style={{ width: '90%' }}
                className={classes.previewContainer}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Flex.Column
                  childrenGap={theme.spacing.unit(1)}
                  className={classes.fullHeight}
                >
                  {true && (
                    <PreviewModalNavBar
                      groupId={groupId}
                      preview={preview}
                      isDriveItem={isDriveItem}
                      selectedDriveItems={selectedDriveItems}
                    />
                  )}

                  <Flex.Row
                    childrenGap={theme.spacing.unit(1)}
                    style={{ zIndex: 1012 }}
                    className={classes.secondRowHeight}
                    flex={1}
                  >
                    {(previewModal.itemList.length > 1 ||
                      selectedDriveItems.length > 0) && (
                      <Flex.Column
                        className={classNames(
                          classes.previewItemsPadding,
                          classes.documentList
                        )}
                        flex={1}
                        childrenGap={theme.spacing.unit(1)}
                      >
                        <Typography.Title level={4}>
                          {t('documents:documentDetails.labels.moreDocuments')}
                        </Typography.Title>
                        <div className={classes.listHeight}>
                          <ArrowKeyList<DriveItem | MessageAttachment>
                            ref={listRef}
                            items={previewModal.itemList as DriveItem[]}
                            rowHeight={40}
                            rowRenderer={rowRendererDriveItemList}
                            registerChild={() => {
                              if (
                                pageNumber > -1 ||
                                previewModal.pageNumber > -1
                              ) {
                                listRef?.current?.setActiveIndex(
                                  pageNumber ?? previewModal.pageNumber
                                );
                                listRef?.current?.forceUpdate();
                              }
                            }}
                            rowsAreSelectable={
                              !!(
                                previewModal.driveItemId && previewModal.groupId
                              )
                            }
                            onSelectionChange={setSelectedDriveItems}
                            selectedItems={selectedDriveItems}
                            onActiveChange={onActiveChange}
                          />
                        </div>
                      </Flex.Column>
                    )}
                    <Flex.Column
                      className={classes.previewItemsPadding}
                      flex={isDriveItem(previewModal.itemList[0]) ? 3 : 4}
                    >
                      <PreviewModalPreviewComponent
                        preview={preview}
                        selectedDriveItems={selectedDriveItems}
                        isLoading={isLoading}
                      />
                    </Flex.Column>
                    {isDriveItem(previewModal.itemList[0]) &&
                      selectedDriveItems.length === 0 && (
                        <Flex.Column
                          className={classes.previewItemsPadding}
                          flex={1}
                        >
                          {(preview?.item || selectedDriveItems.length > 0) && (
                            <PreviewModalMetaDataForm
                              groupId={groupId}
                              selectedDriveItems={
                                selectedDriveItems as DriveItem[]
                              }
                              driveItem={
                                previewModal.itemList[pageNumber] as DriveItem
                              }
                              activeProjectId={activeProjectId}
                            />
                          )}
                        </Flex.Column>
                      )}
                    {selectedDriveItems.length > 0 && (
                      <Flex.Column
                        className={classes.previewItemsPadding}
                        flex={1}
                      >
                        <PreviewModalMetaDataFormMultiSelect
                          groupId={groupId}
                          selectedDriveItems={selectedDriveItems as DriveItem[]}
                          projectId={activeProjectId}
                        />
                      </Flex.Column>
                    )}
                  </Flex.Row>
                </Flex.Column>
              </div>
            </Center>

            {selectedDriveItems.length === 0 && (
              <div className={classes.buttonLeft}>
                <Center>
                  <Button
                    type="link"
                    shape="circle"
                    iconProp={['fas', 'chevron-left']}
                    onClick={buttonLeftClick}
                    style={{
                      backgroundColor: theme.old.palette.chromaticPalette.white,
                      color: theme.old.palette.chromaticPalette.lightGrey,
                      alignItems: 'center',
                      fontSize: 15,
                      zIndex: 9999,
                    }}
                    disabled={pageNumber === 0}
                  />
                </Center>
              </div>
            )}
            {selectedDriveItems.length === 0 && (
              <div className={classes.buttonRight}>
                <Center>
                  <Button
                    type="link"
                    shape="circle"
                    iconProp={['fas', 'chevron-right']}
                    onClick={buttonRightClick}
                    style={{
                      backgroundColor: theme.old.palette.chromaticPalette.white,
                      color: theme.old.palette.chromaticPalette.lightGrey,
                      alignItems: 'center',
                      fontSize: 15,
                      zIndex: 9999,
                    }}
                    disabled={pageNumber === previewModal.itemList.length - 1}
                  />
                </Center>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PreviewModal;
