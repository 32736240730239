import React, { useEffect, useState } from 'react';
import { Dropdown, Input, List, Menu, Popover, Table } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  ProjectDistributionContact,
  ProjectDistributionList,
} from '../../../models/ProjectDistributionList';
import { ColumnProps } from 'antd/lib/table';
import { tableTranslations } from '../../../util/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { TableRowSelection } from 'antd/lib/table/interface';
import Flex from '../../../components/Flex';
import { getContactsByIdState } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import { ProjectDistributionListId } from '../../../models/Types';
import { FilterFilled } from '@ant-design/icons';
import Tag from 'antd/es/tag';
import { MENU_BUTTON_SIZE } from '../../../constants';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
  },
  table: {
    overflow: 'hidden',
    '& .ant-table-row .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-sticky-holder': {
      zIndex: 0,
    },
    '& .ant-table-sticky-scroll': {
      display: 'none',
    },
    '& .ant-spin-nested-loading': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-spin-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  menuColum: {
    padding: '0!important',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  menuButton: {
    height: MENU_BUTTON_SIZE,
    backgroundColor: theme.old.components.table.menuButton.backgroundColor,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.typography.colors.base,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(4) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(4) > button': {
      visibility: 'visible',
    },
    '& > td:nth-child(5) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(5) > button': {
      visibility: 'visible',
    },
  },
  dropDownFilter: {
    padding: theme.old.spacing.unit(2),
  },
  dropDownFilterButton: {
    width: '100%',
    fontSize: 14,
  },
  popoverTitle: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 500,
  },
  font: {
    fontSize: 14,
  },
  listRoot: {
    maxHeight: 450,
    overflowY: 'scroll',
  },
  list: {
    height: '100%',
    overflow: 'auto',
  },
  listItem: {
    minWidth: 350,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(3)}px`,
    '& .ant-list-item-meta-title': {
      wordBreak: 'break-word',
    },
  },
  popover: {
    '& .ant-popover-inner-content': {
      padding: 0,
    },
    '& .ant-popover-title': {
      padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(3)}px`,
    },
  },
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  filter: {
    padding: `${theme.old.spacing.unit(1.5)}px 0`,
  },
}));

interface ProjectDistributionListTableProps {
  className?: string;
  filterClassName?: string;
  distributionLists: ProjectDistributionList[];
  loading?: boolean;
  onRowClick?: (list: ProjectDistributionList) => void;
  onDelete?: (list: ProjectDistributionList) => void;
  onRowSelectionChange?: (selectedLists: ProjectDistributionList[]) => void;
  showPopover?: boolean;
}

export const ProjectDistributionListTable: React.FC<
  ProjectDistributionListTableProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    filterClassName,
    distributionLists,
    loading,
    onRowClick,
    onDelete,
    onRowSelectionChange,
    showPopover,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setClickedListId(null);
  }, [showPopover]);

  const menu = (entry: ProjectDistributionList) => (
    <Menu>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          onDelete(entry);
        }}
      >
        {t('projects:projectDistributionList.table.delete')}
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          onRowClick(entry);
        }}
      >
        {t('projects:projectDistributionList.table.edit')}
      </Menu.Item>
    </Menu>
  );

  const rowSelection: TableRowSelection<ProjectDistributionList> = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (onRowSelectionChange) onRowSelectionChange(selectedRows);
    },
  };

  const filterDropDown = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex: string
  ) => (
    <Flex.Column
      className={classes.dropDownFilter}
      childrenGap={theme.old.spacing.unit(1)}
    >
      <Input
        placeholder={t(`projects:projectDistributionList.table.${dataIndex}`)}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          switch (dataIndex) {
            case 'name':
              setNameFilter(selectedKeys ?? []);
              break;
            case 'projectDistributionContacts':
              setMailsFilter(selectedKeys ?? []);
              break;
          }
          confirm();
        }}
      />
      <Flex.Row flex={1}>
        <Flex.Item flex={1}>
          <Button
            className={classes.dropDownFilterButton}
            onClick={(event) => {
              switch (dataIndex) {
                case 'name':
                  setNameFilter([]);
                  break;
                case 'projectDistributionContacts':
                  setMailsFilter([]);
                  break;
              }
              event.stopPropagation();
              clearFilters();
            }}
            type="link"
            style={{
              color: theme.old.palette.chromaticPalette.red,
              backgroundColor: 'transparent',
            }}
          >
            {t('projects:projectDistributionList.table.filter.filterReset')}
          </Button>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Button
            className={classes.dropDownFilterButton}
            onClick={() => {
              switch (dataIndex) {
                case 'name':
                  setNameFilter(selectedKeys ?? []);
                  break;
                case 'projectDistributionContacts':
                  setMailsFilter(selectedKeys ?? []);
                  break;
              }
              confirm();
            }}
          >
            {t('projects:projectDistributionList.table.filter.filterSearch')}
          </Button>
        </Flex.Item>
      </Flex.Row>
    </Flex.Column>
  );

  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [mailsFilter, setMailsFilter] = useState<string[]>([]);

  const [clickedListId, setClickedListId] =
    useState<ProjectDistributionListId>(null);

  const handleOnRowClick = (record: ProjectDistributionList) => {
    if (onRowClick) {
      onRowClick(record);
    }
    if (showPopover) {
      if (clickedListId && clickedListId === record.projectDistributionListId) {
        setClickedListId(null);
      } else if (clickedListId !== record.projectDistributionListId) {
        setClickedListId(record.projectDistributionListId);
      }
    }
  };

  const contactsById = useSelector(getContactsByIdState);

  const filterRecords: (
    value: string,
    record: ProjectDistributionList
  ) => boolean = (value, record) => {
    return (
      record.projectDistributionContacts.filter((entry) => {
        const filterString = value
          ?.toLocaleLowerCase()
          ?.replace(/[\W_]+/g, ' ')
          ?.replace(/ /g, '');
        if (entry.contactId) {
          const firstNameToLowerCase =
            contactsById[entry.contactId]?.firstName
              ?.toLocaleLowerCase()
              ?.replace(/[\W_]+/g, ' ')
              ?.replace(/ /g, '') ?? '';
          const lastNameToLowerCase =
            contactsById[entry.contactId]?.lastName
              ?.toLocaleLowerCase()
              ?.replace(/[\W_]+/g, ' ')
              ?.replace(/ /g, '') ?? '';
          return (
            `${firstNameToLowerCase}${lastNameToLowerCase}`.includes(
              filterString
            ) ||
            `${lastNameToLowerCase}${firstNameToLowerCase}`.includes(
              filterString
            )
          );
        }
        const eMail = entry.eMail
          ?.toLocaleLowerCase()
          ?.replace(/[\W_]+/g, ' ')
          ?.replace(/ /g, '');
        return eMail.includes(filterString);
      }).length > 0
    );
  };

  const columns: ColumnProps<ProjectDistributionList>[] = [
    {
      title: t('projects:projectDistributionList.table.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filteredValue: nameFilter,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return filterDropDown(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          'name'
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filtered ? theme.old.palette.primaryColor : undefined,
          }}
        />
      ),
      onFilter: (value: string, record) => {
        return filterRecords(value, record);
      },
      ellipsis: true,
    },
    {
      title: t(
        'projects:projectDistributionList.table.projectDistributionContacts'
      ),
      dataIndex: 'projectDistributionContacts',
      filteredValue: mailsFilter,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return filterDropDown(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          'projectDistributionContacts'
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filtered ? theme.old.palette.primaryColor : undefined,
          }}
        />
      ),
      onFilter: (value: string, record) => {
        return filterRecords(value, record);
      },
      ellipsis: true,
      render: (
        value: ProjectDistributionContact[],
        record: ProjectDistributionList
      ) => {
        const joinedValue = value.map((item) => item.eMail).join('; ');
        return (
          <Popover
            overlayClassName={classes.popover}
            visible={record.projectDistributionListId === clickedListId}
            title={
              <Flex.Row alignItems="center">
                <Flex.Item flex={1}>
                  <div className={classes.popoverTitle}>{record.name}</div>
                </Flex.Item>
                <Button
                  shape="circle"
                  type="link"
                  iconProp={['fal', 'times']}
                  onClick={(event) => {
                    event.stopPropagation();
                    setClickedListId(null);
                  }}
                />
              </Flex.Row>
            }
            content={
              <div className={classes.listRoot}>
                <List
                  dataSource={value}
                  className={classes.list}
                  renderItem={(item) => (
                    <List.Item
                      onClick={(e) => e.stopPropagation()}
                      className={classes.listItem}
                    >
                      <List.Item.Meta
                        title={item.eMail}
                        description={
                          item.contactId
                            ? `${contactsById[item.contactId].firstName} ${
                                contactsById[item.contactId].lastName
                              }`
                            : ''
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            }
            trigger="click"
          >
            {joinedValue.length > 35
              ? `${joinedValue.substring(0, 35)}...`
              : joinedValue}
          </Popover>
        );
      },
    },
    ...(onDelete
      ? [
          {
            render: (_, record) => (
              <Dropdown
                overlay={menu(record)}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  iconProp={['fal', 'ellipsis-v']}
                  className={classes.menuButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Dropdown>
            ),
            className: classes.menuColum,
            width: onDelete ? MENU_BUTTON_SIZE : 0,
          },
          {
            render: (_, record) => (
              <Button
                type="link"
                iconProp={['fal', 'trash']}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete(record);
                }}
              />
            ),
            width: onDelete ? 80 : 0,
          },
        ]
      : []),
  ];

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      {(nameFilter.length !== 0 || mailsFilter.length !== 0) && (
        <Flex.Row
          alignItems="center"
          className={classNames(classes.filter, filterClassName)}
        >
          <Flex.Item flex={1}>
            {nameFilter.length !== 0 && (
              <Tag
                className={classes.font}
                closable
                onClose={() => setNameFilter([])}
              >{`${t(
                'projects:projectDistributionList.table.name'
              )}: ${nameFilter}`}</Tag>
            )}
            {mailsFilter.length !== 0 && (
              <Tag
                className={classes.font}
                closable
                onClose={() => setMailsFilter([])}
              >{`${t(
                'projects:projectDistributionList.table.projectDistributionContacts'
              )}: ${mailsFilter}`}</Tag>
            )}
          </Flex.Item>
          <Button
            type="link"
            className={classes.danger}
            onClick={() => {
              setNameFilter([]);
              setMailsFilter([]);
            }}
            iconProp={['fal', 'trash-alt']}
          >
            {t('projects:projectDistributionList.table.filter.resetAll')}
          </Button>
        </Flex.Row>
      )}
      <Table<ProjectDistributionList>
        className={classes.table}
        columns={columns}
        dataSource={distributionLists}
        pagination={false}
        rowKey={(row) => row.projectDistributionListId}
        scroll={{ x: 'scroll', y: 'calc(100% - 56px)' }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              event.stopPropagation();
              handleOnRowClick(record);
            },
          };
        }}
        locale={tableTranslations(t)}
        loading={{
          spinning: loading,
          indicator: <PrioSpinner alignSelf />,
        }}
        rowClassName={classes.row}
        onHeaderRow={() => {
          return {
            onClick: () => {
              setClickedListId(null);
            },
          };
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
      />
    </Flex.Column>
  );
};

export default ProjectDistributionListTable;
