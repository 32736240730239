import React, { useEffect, useRef } from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { makePrioStyles } from '../theme/utils';
import classNames from 'classnames';
import {
  DEFAULT_BR_VALUE,
  DEFAULT_FROALA_CONFIG,
  DEFAULT_FROALA_EVENTS,
  DEFAULT_TOOLBAR_BUTTONS,
} from '../modules/mail/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& p': {
      marginBottom: '0px',
    },
    border: theme.old.borders.sub,
    borderRadius: 2,
    '&:hover': {
      borderColor: 'var(--ant-primary-5)',
    },
    '& .fr-box.fr-basic': {
      borderRadius: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .fr-box.fr-basic.fr-bottom .fr-wrapper': {
      border: 'none',
      borderRadius: 0,
      overflow: 'auto',
      height: 'calc(100% - 40px)',
    },
    '& .fr-box.fr-basic .fr-element': {
      height: '100%',
      padding: theme.old.spacing.unit(2),
      fontFamily: 'inherit',
    },
    '& .fr-toolbar.fr-bottom': {
      border: 'none',
      borderRadius: 0,
      '& .fr-newline': {
        display: 'none',
      },
    },
    '& .fr-toolbar .fr-btn-grp': {
      margin: `0 ${theme.old.spacing.unit(2)}px`,
    },
    '& .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn':
      {
        margin: 0,
      },
    '& .fr-toolbar .fr-command.fr-btn.fr-active, .fr-popup .fr-command.fr-btn.fr-active, .fr-modal .fr-command.fr-btn.fr-active':
      {
        color: 'var(--ant-primary-5)',
      },
  },
  disabled: {
    cursor: 'not-allowed',
  },
}));

interface HTMLTextEditorProps {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

export const HTMLTextEditor: React.FC<HTMLTextEditorProps> = (props) => {
  const { className, value, disabled, onChange, onClick } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const ref = useRef(null);

  const [bodyState] = React.useState<string>(value ?? DEFAULT_BR_VALUE);

  const config = {
    ...DEFAULT_FROALA_CONFIG,
    placeholderText: '',
    wordPasteModal: true,
    language: 'de',
    quickInsertEnabled: false,
    toolbarBottom: true,
    toolbarSticky: false,
    toolbarButtons: {
      moreText: {
        ...DEFAULT_TOOLBAR_BUTTONS.text,
        buttonsVisible: 5,
      },
      moreParagraph: {
        ...DEFAULT_TOOLBAR_BUTTONS.paragraph,
        buttonsVisible: 3,
      },
    },
    events: {
      ...DEFAULT_FROALA_EVENTS,
      contentChanged: function () {
        // Do something here.
        // this is the editor instance.
        if (onChange) {
          onChange(this.html.get());
        }
      },
      initialized: () => {
        if (ref?.current) {
          const editor = ref?.current?.editor;
          editor.events.on('drop', handleDropEvent, true);
          if (disabled) {
            editor?.edit?.off();
            editor?.toolbar?.disable();
          } else {
            editor?.edit?.on();
            editor?.toolbar?.enable();
          }
        }
      },
    },
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleDropEvent = (dropEvent) => {
    const itemList: DataTransferItemList =
      dropEvent?.originalEvent?.dataTransfer?.items ?? null;
    const files: FileList =
      dropEvent?.originalEvent?.dataTransfer?.files ?? null;

    var areNonImageFilesOrText: boolean = true;
    var isNoFile: boolean = false;

    if (itemList) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; areNonImageFilesOrText && i < itemList.length; i++) {
        // If dropped items aren't files, reject them
        if (itemList[i]?.kind === 'file') {
          if (!itemList[i].type.match(/image\/(\D)+/)) {
            areNonImageFilesOrText = false;
          }
        } else {
          isNoFile = true;
        }
      }
    } else if (files) {
      //Use DataTransfer interface to access the file(s)
      for (
        var index = 0;
        areNonImageFilesOrText && index < files.length;
        index++
      ) {
        if (files.item(index).type.match(/image\/(\D)+/)) {
          areNonImageFilesOrText = false;
        }
      }
    } else {
      isNoFile = true;
    }

    if (areNonImageFilesOrText) {
      if (!isNoFile) {
        dropEvent.preventDefault();
        dropEvent.stopPropagation();
      }
      return true;
    }
    return false;
  };

  //#endregion

  //#region ------------------------------ useEffects
  useEffect(() => {
    if (ref.current) {
      if (disabled) {
        ref?.current?.editor?.edit?.off();
        ref?.current?.editor?.toolbar?.disable();
      } else {
        ref?.current?.editor?.edit?.on();
        ref?.current?.editor?.toolbar?.enable();
      }
    }
  }, [ref, disabled]);
  //#endregion

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <FroalaEditorComponent
        ref={ref}
        tag="textarea"
        config={config}
        model={bodyState}
      />
    </div>
  );
};

export default HTMLTextEditor;
