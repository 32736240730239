import React, { useState } from 'react';
import {} from 'antd';
import { SingleDatePicker } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import './styles/customSingleDatePicker.css';
import { Moment } from 'moment-timezone';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../theme/utils';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    width: '100%',
  },
  navigationIcons: {
    position: 'absolute',
    top: 26,
  },
}));

interface CustomSingleDatePickerProps {
  id: string;
  className?: string;
  value?: any;
  onChange?: (changedDate: Moment) => void;
  focused?: any;
  onFocusChange?: (focusedInput: any) => void;
  anchorDirection?: String;
  small?: boolean;
  regular?: boolean;
  twoMonths?: boolean;
  withFullScreenPortal?: boolean;
  daySize?: number;
  hideKeyboardShortcutsPanel?: boolean;
  minimumNights?: number;
  showClearDates?: boolean;
  isOutsideRange?: boolean;
  showDefaultInputIcon?: boolean;
  inputIconPosition?: string;
  disabled?: boolean;
  style?: any;
  blockDatesBefore?: Moment;
  blockDatesAfter?: Moment;
  allowClear?: boolean;
}

export const CustomSingleDatePicker: React.FC<CustomSingleDatePickerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [focusedInput, setFocusedInput] = useState(props.focused ?? null);

  const isDateBlocked = (date) => {
    if (!props.blockDatesBefore && !props.blockDatesAfter) {
      return false;
    }
    if (props?.blockDatesBefore && !props.blockDatesAfter) {
      return date.isAfter(props?.blockDatesBefore, 'day');
    }
    if (!props?.blockDatesBefore && props.blockDatesAfter) {
      return date.isBefore(props?.blockDatesAfter, 'day');
    }
    return (
      date.isBefore(props?.blockDatesBefore, 'day') ||
      date.isAfter(props?.blockDatesAfter, 'day')
    );
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, props.className)}>
      <SingleDatePicker
        id="id"
        disabled={props.disabled}
        initialDate={{
          _isAMomentObject: true,
          _isUTC: false,
          _pf: {
            empty: false,
            unusedTokens: [],
            unusedInput: [],
          },
        }}
        date={props.value} // momentPropTypes.momentObj or null,
        onDateChange={props.onChange} // PropTypes.func.isRequired,
        focused={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(_focusedInput) => {
          setFocusedInput(_focusedInput['focused']);
        }} // PropTypes.func.isRequired,
        anchorDirection={props.anchorDirection}
        small={props.small}
        regular={props.regular}
        numberOfMonths={!props.twoMonths ? 1 : 2}
        withFullScreenPortal={props.withFullScreenPortal}
        daySize={props.daySize}
        hideKeyboardShortcutsPanel={props.hideKeyboardShortcutsPanel}
        minimumNights={props.minimumNights}
        showClearDates={props.showClearDates}
        showDefaultInputIcon={props.showDefaultInputIcon}
        inputIconPosition={props.inputIconPosition}
        placeholder={t('tasks:todoForm.dueDatePicker.options.customDate')}
        style={props.style}
        isDayBlocked={isDateBlocked}
        showClearDate={props.allowClear}
        isOutsideRange={(day) => {
          if (props.isOutsideRange) {
            let _day = day as Moment;
            if (_day.isBefore(moment())) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }}
        navPrev={
          <FontAwesomeIcon
            className={classes.navigationIcons}
            style={{ left: 28 }}
            icon={['fal', 'chevron-left']}
          />
        }
        navNext={
          <FontAwesomeIcon
            className={classes.navigationIcons}
            style={{ right: 28 }}
            icon={['fal', 'chevron-right']}
          />
        }
      />
    </div>
  );
};

export default CustomSingleDatePicker;
