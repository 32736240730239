import React, { useEffect, useMemo, useState } from 'react';
import DocumentTemplateFormSelector from './DocumentTemplateFormSelector';
import { useTranslation } from 'react-i18next';
import { DriveItem } from '../../../../models/Drive';
import {
  CompanyId,
  ContactId,
  DocumentTemplateId,
  DriveItemId,
  GroupId,
  OfficeId,
  ProjectId,
} from '../../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentTemplates } from '../../actions/documentTemplates';
import {
  Col,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { colon, rowGutter } from '../../../../util/forms';
import moment from 'moment';
import { fileNameRegex } from '../../util';
import {
  CreateDocumentReportRequest,
  CreateDocumentRequest,
  Placeholder,
  PlaceholderKeyValue,
} from '../../../../models/Document';
import ContactPicker from '../../../contacts/components/ContactPicker';
import CompanyPicker from '../../../companies/components/CompanyPicker';
import OfficePicker from '../../../companies/components/OfficePicker';
import { DocumentTemplate } from '../../../../models/DocumentTemplate';
import { distinct } from '../../../../util';
import {
  documentTemplatesGetTemplates,
  getCompaniesByIdState,
  getCurrentFolderItem,
  getCurrentRemoteItem,
  getProject,
  getProjectByIdState,
  getUserMeContactId,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { CompaniesByIdState } from '../../../companies/reducers/companies';
import { ProjectByIdState } from '../../../projects/reducers/projects';
import DriveItemBreadcrumb from '../DriveItemBreadcrumb/DriveItemBreadcrumb';
import ProjectPicker from '../../../projects/components/ProjectPicker';
import { Project } from '../../../../models/Project';
import { TimeRecord, TimeRecordsFilter } from '../../../../models/TimeRecord';
import {
  apiCreateDocument,
  apiCreateDocumentReport,
  apiDownloadDocumentTemplatePreview,
  apiFetchDocumentPrefix,
  apiFetchDocumentTemplateSuggestionPath,
} from '../../api';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import { fetchDriveItemsSagaAction } from '../../actions';
import FolderSelectionDrawer from '../Drawers/FolderSelectionDrawer';
import { PathSuggestionRadioButton } from './PathSuggestionRadioButton';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  newDocument: {
    height: 'calc(100% - 294px)',
  },
  projectPickerWidth: {
    width: '100%',
  },
  prefixLabel: {
    whiteSpace: 'nowrap',
  },
  targetFolderBreadcrumb: {
    marginTop: theme.old.spacing.unit(1),
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  editTargetFolderIcon: {
    color: `${theme.old.palette.primaryColor}!important`,
  },
  leftPanel: {
    height: '100%',
    overflow: 'hidden',
  },
  noTemplateSelected: {
    margin: `${theme.old.spacing.unit(3)}px 0`,
  },
  spacing: {
    marginBottom: theme.old.spacing.defaultPadding,
  },
  buttonSpacing: {
    marginRight: theme.old.spacing.defaultPadding,
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  radioGroup: {
    overflow: 'hidden',
    width: '100%',
    '& .ant-space-vertical': {
      width: '100%',
    },
    '& .ant-radio-wrapper': {
      overflow: 'hidden',
      width: '100%',
    },
    '& .ant-radio-wrapper > span:last-child': {
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
    },
  },
  formSelectorFullHeight: {
    height: '100%',
  },
  boldFont: {
    fontWeight: 600,
  },
}));

const placeholderRegex = /@([A-Za-z.]+)\b/g;
const KNOWN_SELECTABLE_PLACEHOLDERS = [
  'project',
  'contact',
  'company',
  'office',
  'internaloffice',
  'creator',
  'date',
];

/*
#################### const static methods
*/
const mainOfficeSelector = (internalCompanyId: CompanyId) =>
  createSelector<[(state: RootReducerState) => CompaniesByIdState], OfficeId>(
    (state) => getCompaniesByIdState(state),
    (companyByIds) => {
      return (
        companyByIds[internalCompanyId]?.offices?.find(
          (office) => office.isMainOffice
        )?.officeId ?? null
      );
    }
  );

const internalCompanySelector = (selectedProjectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => ProjectByIdState], CompanyId>(
    (state) => getProjectByIdState(state),
    (projectsById) =>
      projectsById ? projectsById[selectedProjectId]?.subsidiaryId : null
  );

interface DocumentTemplateFormWrapperProps {
  className?: string;
  parentFolderDriveItem?: DriveItem;
  projectId?: ProjectId;
  groupId?: GroupId;
  showOnlyReports?: boolean;
  timeRecords?: TimeRecord[];
  filter?: TimeRecordsFilter;
  projectPickerFilter?: (project: Project) => boolean;
  onSuccess?: (targetFolder: DriveItem) => void;
  onStartCreateDocument?: () => void;
  onCancel?: () => void;
  onChange?: () => void;
  onThumbnailURLChange?: (thumbnailURL: String[]) => void;
  onSelectedTemplateChange?: (selectedTemplate: any) => void;
}

export const DocumentTemplateFormWrapper: React.FC<
  DocumentTemplateFormWrapperProps
> = (props) => {
  const {
    className,
    parentFolderDriveItem,
    projectId,
    showOnlyReports,
    timeRecords,
    projectPickerFilter,
    onStartCreateDocument,
    onSuccess,
    onCancel,
    onThumbnailURLChange = () => {},
    onSelectedTemplateChange = () => {},
    filter,
  } = props;

  const theme = useTheme<PrioTheme>();
  //#region ------------------------------------------------- Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  //#endregion

  //#region  ------------------------------------------------- States / Attributes / Selectors
  const currentRemoteItem = useSelector(getCurrentRemoteItem);

  const [selectedProjectId, setSelectedProjectId] =
    useState<ProjectId>(projectId);
  const internalCompanyIdFromProjectId = useSelector(
    internalCompanySelector(selectedProjectId)
  );
  const mainOfficeId = useSelector(
    mainOfficeSelector(internalCompanyIdFromProjectId)
  );
  const [prefix, setPrefix] = useState<string>(moment().format('YYYY-MM-DD_'));
  const [templateId, setTemplateId] = useState<DocumentTemplateId>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [targetFolderId, setTargetFolderId] = useState<DriveItemId>(
    parentFolderDriveItem?.id
  );

  const targetFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, targetFolderId)
  );

  const [saveFolderDriveItemId, setSaveFolderDriveItemId] =
    useState<DriveItemId>(targetFolderId);

  const [saveLocationSuggestions, setSaveLocationSuggestions] =
    useState<DriveItem[]>();
  const [saveFolderChoice, setSaveFolderChoice] = useState<
    'customFolder' | string
  >('customFolder');
  const [selectTargetFolderDrawerVisible, setSelectTargetFolderDrawerVisible] =
    useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<Boolean>(true);
  const documentTemplates = useSelector(documentTemplatesGetTemplates);
  const [isMounted, setIsMounted] = useState(false);
  const userId = useSelector(getUserMeContactId);

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, selectedProjectId)
  );
  //#endregion

  //#region  ------------------------------------------------- Memos
  const selectedTemplate = useMemo(
    () =>
      templateId &&
      documentTemplates.find((t) => t.documentTemplateId === templateId),
    [templateId, documentTemplates]
  );

  const placeholder = useMemo(
    () =>
      selectedTemplate &&
      extractPlaceholders(
        selectedTemplate,
        selectedProjectId,
        mainOfficeId,
        userId
      ),
    [selectedTemplate, selectedProjectId, mainOfficeId, userId]
  );

  const [hasInternalOffice, hasCompany, hasOffice, hasContact] = useMemo(
    () => mapToSinglePlaceholders(placeholder),
    [placeholder]
  );

  const initialValues = useMemo(
    () =>
      selectedTemplate
        ? {
            documentName: '',
            saveFolderDriveItemId: '',
            tags: [],
            projectId: selectedProjectId,
            documentTemplateId: selectedTemplate.documentTemplateId,
            contact: hasContact ? '' : undefined,
            office: hasOffice ? '' : undefined,
            company: hasCompany ? '' : undefined,
            internalOffice: hasInternalOffice ? mainOfficeId : undefined,
          }
        : null,
    [
      selectedProjectId,
      selectedTemplate,
      hasInternalOffice,
      hasCompany,
      hasOffice,
      hasContact,
      mainOfficeId,
    ]
  );
  //#endregion

  //#region  ------------------------------------------------- Effects
  useEffect(() => {
    onSelectedTemplateChange(selectedTemplate);
  }, [selectedTemplate, onSelectedTemplateChange]);

  useEffect(() => {
    async function fetchDocumentTemplateSuggestionPath(
      templateId: string,
      selectedProjectId: ProjectId
    ) {
      const { data } = await apiFetchDocumentTemplateSuggestionPath(
        templateId,
        selectedProjectId
      );
      if (data) {
        setSaveLocationSuggestions(data);
      }
    }

    async function fetchPrefix() {
      const { data } = await apiFetchDocumentPrefix(selectedProjectId);
      if (data) {
        setPrefix(data.value);
      }
    }

    if (selectedProjectId) {
      fetchPrefix();
    }
    if (templateId && selectedProjectId) {
      fetchDocumentTemplateSuggestionPath(templateId, selectedProjectId);
    }
  }, [selectedProjectId, templateId]);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    dispatch(fetchDocumentTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (targetFolder) {
      setTargetFolderId(targetFolder.id);
    }
  }, [targetFolder]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, placeholder, form]);

  useEffect(() => {
    async function getThumbnails() {
      onThumbnailURLChange([]);
      const data = await apiDownloadDocumentTemplatePreview(
        selectedTemplate.documentTemplateId
      );

      onThumbnailURLChange([data.data['url']]);
    }

    if (selectedTemplate && selectedTemplate.documentTemplateId) {
      getThumbnails();
    } else {
    }
  }, [selectedTemplate, onThumbnailURLChange]);
  //#endregion

  //#region  ------------------------------------------------- helper methods

  const onCallFinished = () => {
    onSuccess(targetFolder);
    if (isMounted) {
      setIsSaving(false);
      onCreateDocumentSuccess();
    }
  };

  const startCreateDocument = () => {
    notification.open({
      message: t('common:success'),
      description: t(
        'documents:newDocumentForm.successMessages.startCreateDocument'
      ),
    });
    if (onStartCreateDocument) {
      onStartCreateDocument();
    }
  };
  //#endregion

  //#region  ------------------------------------------------- onChangeEvents
  const onTemplateIdChange = (templateId: DocumentTemplateId) => {
    setTemplateId(templateId);
  };
  const updateCollapsed = () => {
    setCollapsed(!collapsed);
  };
  //#endregion

  //#region  ------------------------------------------------- handleEvents
  const handleOnBreadcrumbItemClick = (driveItemId: DriveItemId) => {
    setTargetFolderId(driveItemId);
    if (!!project) {
      const { groupId, projectId } = project;
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          driveItemId,
          250,
          driveItemId === null,
          false
        )
      );
    }
  };

  const openTargetFolderDrawer = () => {
    setSelectTargetFolderDrawerVisible(true);
  };

  const handleTargetFolderDrawerOk = (targetFolder: DriveItem | null) => {
    setSelectTargetFolderDrawerVisible(false);
    setTargetFolderId(targetFolder?.id);
    setSaveFolderDriveItemId(targetFolder?.id);
  };
  const handleTargetFolderDrawerCancel = () => {
    setSelectTargetFolderDrawerVisible(false);
  };

  const handleProjectChange = (givenProjectId: ProjectId) => {
    setSelectedProjectId(givenProjectId);
  };

  const onCreateDocumentSuccess = () => {};
  const handleSaveFolderChoice = (e: RadioChangeEvent) => {
    setSaveFolderChoice(e.target.value);
    if (e.target.value !== 'customFolder') {
      setSaveFolderDriveItemId(e.target.value);
    } else {
      setTargetFolderId(targetFolder !== null ? targetFolder?.id : null);
      setSaveFolderDriveItemId(targetFolder !== null ? targetFolder?.id : null);
    }
  };

  const handleOnFinish = async (createDocumentRequest) => {
    const placeholderRequest = placeholder.map(({ name, values }) => ({
      name,
      values: values.map((value) => {
        switch (value.key.toLowerCase()) {
          case 'internaloffice': {
            return {
              key: value.key,
              id: createDocumentRequest.internalOffice,
            };
          }
          case 'company': {
            return {
              key: value.key,
              id: createDocumentRequest.company,
            };
          }
          case 'office': {
            return {
              key: value.key,
              id: createDocumentRequest.office,
            };
          }
          case 'contact': {
            return {
              key: value.key,
              id: createDocumentRequest.contact,
            };
          }
          default: {
            return value;
          }
        }
      }),
    }));

    const requestPayloadDocumentRequest: CreateDocumentRequest = {
      ...createDocumentRequest,
      placeholder: placeholderRequest,
      documentName: `${prefix}${createDocumentRequest.documentName}`,
      saveFolderDriveItemId: saveFolderDriveItemId,
      projectId: selectedProjectId,
      documentTemplateId: templateId,
    };
    if (selectedTemplate.isReport) {
      const requestPayload: CreateDocumentReportRequest = {
        createDocument: requestPayloadDocumentRequest,
        timeRecords: timeRecords,
        reportFilterDto: filter,
      };
      setIsSaving(true);
      startCreateDocument();
      await apiCreateDocumentReport(requestPayload);
      onCallFinished();
    } else {
      const requestPayload: CreateDocumentRequest =
        requestPayloadDocumentRequest;
      setIsSaving(true);
      startCreateDocument();
      await apiCreateDocument(requestPayload);
      onCallFinished();
    }
  };
  //#endregion

  //#region ------------------------------------------------- BODY
  const pathSuggestions = () => {
    return saveLocationSuggestions?.map((driveItem, i) => {
      const pathItems = driveItem.parentReference.path.split('/').slice(3);
      return (
        <PathSuggestionRadioButton
          driveItem={driveItem}
          pathItems={pathItems}
          project={project}
        />
      );
    });
  };
  //#endregion

  //#region ------------------------------------------------- BODY
  return (
    <>
      <Flex.Row className={classNames(classes.root, className)}>
        <Flex.Column flex={3}>
          <DocumentTemplateFormSelector
            className={classNames({
              [classes.formSelectorFullHeight]: !(
                templateId && selectedProjectId
              ),
            })}
            isSaving={false}
            showOnlyReports={showOnlyReports}
            onTemplateIdChange={onTemplateIdChange}
            onCancel={onCancel}
            onChange={updateCollapsed}
            cancelable={!(templateId && selectedProjectId)}
          >
            {!projectId && (
              <>
                <Divider />
                <Form layout="vertical">
                  <Flex.Row className={classes.leftPanel}>
                    <Flex.Item flex={1}>
                      <Row gutter={theme.old.spacing.unit(rowGutter)}>
                        <Col span={24}>
                          <Form.Item
                            label={t(
                              'documents:newDocumentForm.labels.project'
                            )}
                          >
                            <ProjectPicker
                              filter={projectPickerFilter}
                              onChange={handleProjectChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Flex.Item>
                  </Flex.Row>
                </Form>
              </>
            )}
          </DocumentTemplateFormSelector>
          {templateId && selectedProjectId && (
            <>
              <Divider style={{ marginTop: '8px' }} />

              <Form
                form={form}
                onFinish={handleOnFinish}
                layout="vertical"
                initialValues={initialValues}
                id={'prio-project-new-documents-form'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                <Flex.Row className={classes.fullHeight}>
                  <Flex.Item flex={1} className={classes.flex}>
                    <div
                      style={{
                        flex: 1,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                      }}
                    >
                      <Row gutter={theme.old.spacing.unit(rowGutter)}>
                        <Col span={24}>
                          <Form.Item
                            colon={colon}
                            label={t(
                              'documents:newDocumentForm.labels.documentName'
                            )}
                            required
                          >
                            <Flex.Row
                              alignItems="baseline"
                              childrenGap={theme.old.spacing.unit(1)}
                            >
                              <Typography.Text className={classes.prefixLabel}>
                                {prefix}
                              </Typography.Text>
                              <Form.Item
                                name="documentName"
                                colon={colon}
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'documents:newDocumentForm.validation.missingDocumentName'
                                    ),
                                  },
                                  {
                                    message: t(
                                      'documents:newDocumentForm.validation.invalidDocumentName'
                                    ),
                                    pattern: fileNameRegex,
                                  },
                                ]}
                              >
                                <Input disabled={isSaving} />
                              </Form.Item>
                            </Flex.Row>
                          </Form.Item>
                        </Col>
                      </Row>
                      {hasInternalOffice && (
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              name="internalOffice"
                              colon={colon}
                              label={t(
                                'documents:newDocumentForm.labels.internalOffice'
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                  ),
                                },
                              ]}
                            >
                              <OfficePicker onlyInternal disabled={isSaving} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {hasCompany && (
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              name="company"
                              colon={colon}
                              label={t(
                                'documents:newDocumentForm.labels.company'
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                  ),
                                },
                              ]}
                            >
                              <CompanyPicker disabled={isSaving} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {hasOffice && (
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              name="office"
                              colon={colon}
                              label={t(
                                'documents:newDocumentForm.labels.office'
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                  ),
                                },
                              ]}
                            >
                              <OfficePicker disabled={isSaving} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {hasContact && (
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={24}>
                            <Form.Item
                              name="contact"
                              colon={colon}
                              label={t(
                                'documents:newDocumentForm.labels.contact'
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'documents:newDocumentForm.validation.missingPlaceholderChoice'
                                  ),
                                },
                              ]}
                            >
                              <ContactPicker disabled={isSaving} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      <Row gutter={theme.old.spacing.unit(rowGutter)}>
                        <Col span={24}>
                          <Typography.Text className={classes.boldFont}>
                            {t(
                              'documents:newDocumentForm.labels.saveFolderChoice'
                            )}
                          </Typography.Text>
                          <Form.Item
                            label={
                              <div>
                                {t(
                                  `documents:newDocumentForm.labels.targetFolder`
                                )}
                                <Button
                                  onClick={openTargetFolderDrawer}
                                  iconProp={['fal', 'pen']}
                                  type="link"
                                ></Button>
                              </div>
                            }
                          >
                            {
                              <Radio.Group
                                value={saveFolderChoice}
                                onChange={handleSaveFolderChoice}
                                disabled={isSaving}
                                className={classes.radioGroup}
                              >
                                <Space direction="vertical">
                                  <Radio
                                    value="customFolder"
                                    key="customFolder"
                                  >
                                    <DriveItemBreadcrumb
                                      driveItem={
                                        targetFolder ?? parentFolderDriveItem
                                      }
                                      remoteItem={currentRemoteItem}
                                      project={project}
                                      onItemClick={handleOnBreadcrumbItemClick}
                                      disableLinkNavigation
                                    />
                                  </Radio>
                                </Space>
                              </Radio.Group>
                            }
                          </Form.Item>
                          {saveLocationSuggestions?.length > 0 && (
                            <Form.Item
                              label={t(
                                'documents:newDocumentForm.labels.folderSuggestions'
                              )}
                            >
                              {
                                <Radio.Group
                                  value={saveFolderChoice}
                                  onChange={handleSaveFolderChoice}
                                  disabled={isSaving}
                                  className={classes.radioGroup}
                                >
                                  <Space direction="vertical">
                                    {pathSuggestions()}
                                  </Space>
                                </Radio.Group>
                              }
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <Flex.Row>
                      <Flex.Item flex={1}>
                        <Button
                          type="default"
                          onClick={onCancel}
                          disabled={isSaving}
                          style={{ marginLeft: '-8px' }}
                        >
                          {t('common:actions.cancel')}
                        </Button>
                      </Flex.Item>
                      <Button htmlType="submit" disabled={isSaving}>
                        {t('common:actions.save')}
                      </Button>
                    </Flex.Row>
                  </Flex.Item>
                </Flex.Row>
              </Form>
            </>
          )}
        </Flex.Column>
      </Flex.Row>
      {project && (
        <FolderSelectionDrawer
          visible={selectTargetFolderDrawerVisible}
          onOk={handleTargetFolderDrawerOk}
          onCancel={handleTargetFolderDrawerCancel}
          projectId={selectedProjectId}
          selectedFolder={targetFolder?.id ?? parentFolderDriveItem?.id}
        />
      )}
    </>
  );
  //#endregion
};

const extractPlaceholders: (
  documentTemplate: DocumentTemplate,
  selectedProjectId: ProjectId,
  mainOfficeId: OfficeId,
  userId: ContactId
) => Placeholder[] = (
  documentTemplate,
  selectedProjectId,
  mainOfficeId,
  userId
) => {
  return documentTemplate.placeholders.map(({ name, value }) => ({
    name: name,
    values: distinct(
      Array.from(value.matchAll(placeholderRegex), (m) => m[1]).map(
        (placeholderString) => placeholderString.split('.')[0]
      )
    )
      .filter((placeholderString) =>
        KNOWN_SELECTABLE_PLACEHOLDERS.includes(placeholderString.toLowerCase())
      )
      .map((placeholderString) => {
        switch (placeholderString.toLowerCase()) {
          case 'project': {
            return { key: placeholderString, id: selectedProjectId };
          }
          case 'internaloffice': {
            return { key: placeholderString, id: mainOfficeId };
          }
          case 'creator': {
            return { key: placeholderString, id: userId };
          }
          case 'date': {
            return { key: placeholderString, id: '' };
          }
          default: {
            return { key: placeholderString, id: '' };
          }
        }
      }),
  }));
};

const mapToSinglePlaceholders: (
  placeholders: Placeholder[]
) => [boolean, boolean, boolean, boolean] = (placeholders) => {
  if (!!placeholders) {
    const reducedKeys = distinct(
      placeholders
        .reduce<PlaceholderKeyValue[]>((previous, current) => {
          return previous.concat(current.values);
        }, [])
        .map((value) => value.key.toLowerCase())
    );
    return [
      reducedKeys.includes('internaloffice'),
      reducedKeys.includes('company'),
      reducedKeys.includes('office'),
      reducedKeys.includes('contact'),
    ];
  }
  return [false, false, false, false];
};
export default DocumentTemplateFormWrapper;
