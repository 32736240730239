import { DriveItem } from '../../../models/Drive';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Project } from '../../../models/Project';
import { DriveUserRemoteItem } from '../../../models/Document';
import { MessageAttachment } from '../../../models/Message';
import { calcTextWidth } from '../../../util/calcTextWidth';
import { BreadcrumbItem } from '../../../models/Breadcrumb';
import { PrioTheme } from '../../../theme/types';

export const isDriveItemFolder: (driveItem: DriveItem) => boolean = (
  driveItem
) => {
  const isOneNote = checkIfOneNote(driveItem);
  if (!!driveItem?.folder && !isOneNote) {
    return true;
  }
  return driveItem?.file?.mimeType.toLowerCase() === 'folder';
};

export const iconForFile: (driveItem: DriveItem) => IconName = (driveItem) => {
  if (checkIfOneNote(driveItem)) return 'notebook';
  if (checkIfLink(driveItem?.name)) {
    return 'external-link';
  }
  return iconForMimeType(driveItem?.file?.mimeType);
};

export const iconForAttachment: (
  messageAttachment: MessageAttachment
) => IconName = (messageAttachment) => {
  if (checkIfLink(messageAttachment?.name)) {
    return 'external-link';
  }
  return iconForMimeType(messageAttachment.contentType);
};

export const getDriveItemType: (driveItem: DriveItem) => string = (
  driveItem
) => {
  if (checkIfOneNote(driveItem)) return 'onenote';
  switch (driveItem?.file?.mimeType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return 'ms-word';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
      return 'ms-excel';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      return 'ms-powerpoint';
    case 'application/vnd.ms-project':
      return 'ms-project';
    case 'application/visio':
    case 'application/x-visio':
    case 'application/vnd.visio':
    case 'application/vnd.ms-visio.drawing':
    case 'application/vnd.visio2013':
    case 'application/vnd.ms-visio.drawing.main+xml':
    case 'application/visio.drawing':
    case 'application/vsd ':
    case 'application/x-vsd':
    case 'image/x-vsd':
      return 'ms-visio';
    case 'application/zip':
      return 'zip';
    default:
      return null;
  }
};

export const getPDFType: (driveItem: DriveItem) => string = (driveItem) => {
  switch (driveItem?.file?.mimeType) {
    case 'application/pdf':
      return 'pdf';
    default:
      return null;
  }
};

export const checkIfLink: (fileName: string) => boolean = (fileName) => {
  return fileName?.toLowerCase().endsWith('.url');
};

export const checkIfOneNote: (driveItem: DriveItem) => boolean = (
  driveItem
) => {
  return driveItem?.package?.type === 'oneNote';
};

export const iconForMimeType: (mimeType: String) => IconName = (mimeType) => {
  const _mimeType = mimeType?.replace('\n\nDocument', '');
  switch (_mimeType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return 'file-word';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
      return 'file-excel';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      return 'file-powerpoint';
    case 'application/pdf':
      return 'file-pdf';
    case 'image/png':
    case 'image/jpeg':
    case 'image/tiff':
      return 'file-image';
    case 'message/rfc822':
      return 'envelope';
    case 'video/mp4':
      return 'file-video';
    case 'application/x-zip-compressed':
    case 'application/zip':
      return 'file-archive';
    default:
      return 'file';
  }
};

export const colorForIcon: (mimeType: string) => string = (mimeType) => {
  return colorForMimeType(mimeType);
};

export const colorForAttachmentIcon: (type: string) => string = (type) => {
  return colorForMimeType(type);
};

export const replaceMyDriveItemPath = (
  item: DriveUserRemoteItem,
  project: Project
) => {
  let returnPath = item.path ?? '';
  let path = returnPath.split('root:').slice(0);

  if (path.length >= 2) {
    returnPath = path[1] + '/';
  }
  if (returnPath === '') {
    returnPath = '/';
  }
  return project.number + returnPath;
};

export const colorForMimeType: (mimeType: String) => string = (mimeType) => {
  const _mimeType = mimeType?.replace('\n\nDocument', '');
  switch (_mimeType) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return '#2B579A';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
      return '#217346';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      return '#B7472A';
    case 'application/pdf':
      return '#F60002';
    case 'message/rfc822':
      return '#A4A7A9';
    case 'image/png':
    case 'image/jpeg':
    case 'image/tiff':
    case 'video/mp4':
      return '#005B70';
    case 'application/x-zip-compressed':
    case 'application/zip':
      return '#F8D96F';
    default:
      return '#393939';
  }
};

/* eslint-disable no-control-regex */
export const fileNameRegex =
  /^[^\u0022\u003C\u003E\u007C\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\u0008\u0009\u000A\u000B\u000C\u000D\u000E\u000F\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001A\u001B\u001C\u001D\u001E\u001F\u003A\u002A\u003F\u005C\u002F]*[a-zA-Z0-9\u0028\u0029\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]$/;

export const folderNameRegex =
  /^[^\u0022\u003C\u003E\u007C\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\u0008\u0009\u000A\u000B\u000C\u000D\u000E\u000F\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001A\u001B\u001C\u001D\u001E\u001F\u003A\u002A\u003F\u005C\u002F]*[a-zA-Z0-9\u0028\u0029\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]$/;

export const acceptedFileEndings =
  '.xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .doc, .dot, .docx, .docm, .dotx, .dotm, .docb, .ppt, .pot, .pps, .pptx, .pptm, .potx, .potm, .ppam, .ppsx, .ppsm, .sldx, .sldm';
/* eslint-enable no-control-regex */

export const spreadBreadcrumbItems = (
  resizeObserver: ResizeObserver,
  currentBreadcrumbItems: BreadcrumbItem[],
  breadcrumbItems: BreadcrumbItem[],
  theme: PrioTheme,
  isInDrawer: boolean,
  equals: (a: any, b: any) => boolean,
  setCurrentBreadcrumbItems: (items: BreadcrumbItem[]) => void,
  setResizeObserver: (observer: ResizeObserver) => void
) => {
  if (
    !equals(
      currentBreadcrumbItems.map(({ id }) => ({
        id,
      })),
      breadcrumbItems.map(({ id }) => ({
        id,
      }))
    )
  ) {
    setCurrentBreadcrumbItems(breadcrumbItems);
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
    const items = [...breadcrumbItems];
    items.splice(breadcrumbItems.length - 1);
    const seperatorWidth =
      calcTextWidth('/', theme.old.typography.fontSize.base) + 8;
    const dropDownWidth = 14;
    const widths = items.map(
      (item) =>
        calcTextWidth(item.label, theme.old.typography.fontSize.base) +
        seperatorWidth +
        dropDownWidth
    );
    const correlatedWidths = widths.map((_, index, _widths) => {
      const _widthsCopy = [..._widths];
      return _widthsCopy
        .splice(index)
        .reduce<number>((sum, value) => sum + value, 0);
    });
    setResizeObserver(
      new ResizeObserver((entries) => {
        const _correlatedWidths = correlatedWidths;
        const _breadcrumbItems = [...breadcrumbItems];
        if (entries.length === 1) {
          const entry = entries[0];
          const _children = entry.target.children;
          const _entryWidth = entry.contentRect.width;

          if (_children.length > 3) {
            const _firstChildWidth = (entry.target.firstChild as HTMLDivElement)
              .offsetWidth;
            const _secondChildWidth =
              calcTextWidth('...', theme.old.typography.fontSize.base) +
              dropDownWidth +
              seperatorWidth;
            const _spacingForIcons = isInDrawer ? 0 : 34;
            const _lastChildWidth =
              (
                (entry.target.lastChild.firstChild
                  .firstChild as HTMLDivElement) ??
                (entry.target.lastChild.firstChild as HTMLDivElement)
              ).offsetWidth + _spacingForIcons;
            const _thirdChild = _children[2];
            const _thirdChildChildren = _thirdChild.children;
            _correlatedWidths.forEach((width, index) => {
              if (
                _entryWidth <
                width + _firstChildWidth + _lastChildWidth + _secondChildWidth
              ) {
                if (_thirdChildChildren[index]) {
                  _thirdChildChildren[index].setAttribute(
                    'style',
                    'display: none'
                  );
                  _breadcrumbItems[index] = {
                    ..._breadcrumbItems[index],
                    isHidden: true,
                  };
                }
              } else if (_thirdChildChildren[index]) {
                _thirdChildChildren[index].setAttribute(
                  'style',
                  'display: unset'
                );
                _breadcrumbItems[index] = {
                  ..._breadcrumbItems[index],
                  isHidden: false,
                };
              }
            });
          }
        }
        setCurrentBreadcrumbItems(_breadcrumbItems);
      })
    );
  }
};
