import React from 'react';
import { Drawer, Divider, Tag, Tabs } from 'antd';
import { PrioReleaseNote } from '../../../models/Prio365Features';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: () => void;
  selectedVersion: string;
  features: PrioReleaseNote[];
  bugs: PrioReleaseNote[];
}

const ReleaseNotesDrawer: React.FC<Props> = ({
  visible,
  onClose,
  selectedVersion,
  features,
  bugs,
}) => {
  const { TabPane } = Tabs;
  const groupByModule = (data: PrioReleaseNote[]) => {
    const grouped: { [module: string]: PrioReleaseNote[] } = {};
    data.forEach((note) => {
      const { Module } = note;
      if (!grouped[Module]) {
        grouped[Module] = [];
      }
      grouped[Module].push(note);
    });

    return grouped;
  };

  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const groupedFeatures = groupByModule(features);
  const groupedBugs = groupByModule(bugs);

  return (
    <Drawer
      title={`Version ${selectedVersion}`}
      placement="right"
      onClose={onClose}
      width={theme.old.components.drawerWidth}
      visible={visible}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Features" key="1">
          <div>
            {Object.keys(groupedFeatures).map((module, index) => (
              <div key={module}>
                {module ? (
                  <h2>
                    {t('zendesk:module')}: {module}
                  </h2>
                ) : (
                  <h2> {t('zendesk:general')}</h2>
                )}

                {groupedFeatures[module].map((feature, featureIndex) => (
                  <div key={featureIndex}>
                    {feature.PublicTitle && <h3>{feature.PublicTitle}</h3>}
                    <div dangerouslySetInnerHTML={{ __html: feature.Public }} />
                    <Tag color="purple">{feature.Type}</Tag>
                    <Tag color="green">{feature.Version}</Tag>
                    {feature.SubModule && (
                      <Tag color="cyan">{feature.SubModule}</Tag>
                    )}
                    <Divider />
                  </div>
                ))}
              </div>
            ))}
          </div>{' '}
        </TabPane>
        <TabPane tab="Bugs" key="2">
          <div>
            {Object.keys(groupedBugs).map((module, index) => (
              <div key={module}>
                {module ? (
                  <h2>
                    {t('zendesk:module')}: {module}
                  </h2>
                ) : (
                  <h2> {t('zendesk:general')}</h2>
                )}
                {groupedBugs[module].map((bug, bugIndex) => (
                  <div key={bugIndex}>
                    {bug.PublicTitle && <h3>{bug.PublicTitle}</h3>}
                    <div dangerouslySetInnerHTML={{ __html: bug.Public }} />
                    <Tag color="red">{bug.Type}</Tag>
                    <Tag color="green">{bug.Version}</Tag>
                    {bug.SubModule && <Tag color="cyan">{bug.SubModule}</Tag>}

                    <Divider />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default ReleaseNotesDrawer;
