import { all, fork, debounce, spawn, select } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from '../i18n';
import {
  DEBOUNCED_FETCH_OFFICE_HOLIDAYS_ME,
  DEBOUNCE_ABSENCES_ME,
  fetchAbsencesMe,
  fetchOfficeHolidays,
} from '../modules/absences/actions';
import { ReduxAction } from '../models/Redux';
import { DateTimeString, OfficeId } from '../models/Types';
import {
  DEBOUNCE_TIMERECORDS_ME,
  fetchMyTimeRecords,
} from '../modules/timeRecords/actions';
import {
  DEBOUNCE_MONTHLY_CLOSE_ME,
  fetchMonthlyCloseMe,
} from '../modules/timeKeeping/actions';
import { dispatchSaga } from '../util/sagas';
import moment from 'moment';
import { OfficeHoliday } from '../models/AbsenceProposal';
import { getOfficeHolidays } from '../apps/main/rootReducer';

function* debounceAbsencesMe(
  action: ReduxAction<{
    from?: DateTimeString;
    to?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { from, to },
    } = action;
    yield spawn(dispatchSaga, fetchAbsencesMe(from, to));
  } catch (error) {
    console.error('Error in debounceAbsencesMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('absences:errorMessages.fetchOverviewError'),
    });
  }
}
function* debounceTimeRecordsMe(
  action: ReduxAction<{
    startDate?: DateTimeString;
    endDate?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { startDate, endDate },
    } = action;
    yield spawn(dispatchSaga, fetchMyTimeRecords(startDate, endDate));
  } catch (error) {
    console.error('Error in debounceTimeRecordsMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('timeRecords:errorMessages.fetchError'),
    });
  }
}
function* debounceMonthlyCloseMe(
  action: ReduxAction<{
    from?: DateTimeString;
    to?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { from, to },
    } = action;
    yield spawn(dispatchSaga, fetchMonthlyCloseMe(from, to));
  } catch (error) {
    console.error('Error in debounceMonthlyCloseMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('timeKeeping:messages.errorMessages.fetchError'),
    });
  }
}
function* debounceOfficeHolidaysMe(
  action: ReduxAction<{
    officeId: OfficeId;
    from?: DateTimeString;
    to?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { officeId },
    } = action;
    const officeHolidaysCurrentYear: OfficeHoliday[] = yield select(
      getOfficeHolidays
    );

    if (
      !officeHolidaysCurrentYear.find((holiday) =>
        moment(holiday.date).isSame(moment(), 'year')
      )
    ) {
      yield spawn(dispatchSaga, fetchOfficeHolidays(officeId));
    }

    // yield spawn(dispatchSaga, fetchOfficeHolidays(officeId));
  } catch (error) {
    console.error('Error in debounceOfficeHolidaysMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('absences:errorMessages.fetchOfficeHolidaysError'),
    });
  }
}
function* watchDebouncedAbsencesMe() {
  yield debounce(500, DEBOUNCE_ABSENCES_ME, debounceAbsencesMe);
}
function* watchDebouncedTimerecordsMe() {
  yield debounce(500, DEBOUNCE_TIMERECORDS_ME, debounceTimeRecordsMe);
}
function* watchDebouncedMonthlyCloseMe() {
  yield debounce(500, DEBOUNCE_MONTHLY_CLOSE_ME, debounceMonthlyCloseMe);
}
function* watchDebouncedOfficeHolidaysMe() {
  yield debounce(
    500,
    DEBOUNCED_FETCH_OFFICE_HOLIDAYS_ME,
    debounceOfficeHolidaysMe
  );
}

export default function* watchDebouncedAction() {
  yield all([
    fork(watchDebouncedAbsencesMe),
    fork(watchDebouncedTimerecordsMe),
    fork(watchDebouncedMonthlyCloseMe),
    fork(watchDebouncedOfficeHolidaysMe),
  ]);
}
