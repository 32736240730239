import { PrioTheme } from '../../../../theme/types';
import classNames from 'classnames';
import { Button, Divider } from 'antd';

import DebouncedInputSearch from '../../../../components/DebouncedInputField/DebouncedInputSearch';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import {
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  UltimateFilterSearchRef,
  UltimateFilterTagConfig,
  createUltimateFilter,
} from '../../../../components/UltimateFilter/createUltimateFilter';
import { GroupId, ProjectId } from '../../../../models/Types';
import ContactPicker from '../../../contacts/components/ContactPicker';
import CompanyPicker from '../../../companies/components/CompanyPicker';
import { useDocumentSearch } from './useDocumentSearch';
import Flex from '../../../../components/Flex';
import {
  QuickSearchConfig,
  UltimateFilterQuickSearchTags,
} from '../../../../components/UltimateFilter/QuickSearch/UltimateFilterQuickSearchTags';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import DriveitemtagsPicker from '../DriveitemtagsPicker';
import { FormikProps } from 'formik';
import { makePrioStyles } from '../../../../theme/utils';
import {
  AdvancedDocumentSearchDto,
  DocumentSearchFormModel,
} from '../../../../models/Document';

const { UltimateFilter, UltimateForm } =
  createUltimateFilter<DocumentSearchFormModel>();

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  dropDownBody: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: theme.palette.boxShadow.large,
    borderRadius: 2,
    border: 0,
    backgroundClip: 'padding-box',
    padding: theme.spacing.defaultPadding,
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  divider: {
    width: '100%',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.baseSpacing,
  },

  datePicker: {
    maxWidth: '230px',
  },
  selectInput: {
    width: '100%',
  },
  actionButtonRow: {
    paddingTop: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
}));

export interface DocumentSearchRef {
  resetSearch: () => void;
  hasValue: boolean;
}

export interface DocumentSearchProps {
  className?: string;
  groupId: GroupId;
  projectId: ProjectId;
  searchOptions?: {
    searchTextInput?: {
      value?: string;
      onChange?: (value: string) => void;
      onSubmit?: (value: string) => void;
    };
    advancedSearch?: {
      value?: AdvancedDocumentSearchDto;
      onChange?: (value: AdvancedDocumentSearchDto) => void;
      onSubmit?: (value: AdvancedDocumentSearchDto) => void;
    };
  };
  iconWhenValue?: ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  onAdvancedSearch?: (advancedSearchtermQueryString: string) => void;
}

const DocumentSearch: ForwardRefRenderFunction<
  DocumentSearchRef,
  DocumentSearchProps
> = (
  {
    className,
    onFocus: onFocusFromParent,
    onBlur: onBlurFromParent,
    searchOptions,
    iconWhenValue,
    projectId,
  },
  forwardedRef
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useImperativeHandle(forwardedRef, () => ({
    resetSearch: () => {
      ultimateFilterRef?.current?.resetSearch();
    },
    hasValue: ultimateFilterRef?.current?.hasValue,
  }));

  const { advancedSearchTerm, dispatchAdvancedSearchTerm } =
    useDocumentSearch();

  const documentlQueryModelInitialValues: DocumentSearchFormModel = {
    alternativeName: '',
    keyWords: [],
    companyIds: [],
    contactIds: [],
  };

  //#region ------------------------------ States / Attributes / Selectors

  const initialAdvancedFilterValues = _.merge(
    {},
    documentlQueryModelInitialValues,
    advancedSearchTerm
  );

  const ultimateFilterRef =
    useRef<UltimateFilterSearchRef<DocumentSearchFormModel> | null>(null);

  const me = useSelector(getUserMe);
  //#endregion

  //#region -------------------------------- Handlers
  const onBlurDebouced = _.debounce(() => {
    onBlurFromParent?.();
  }, 200);

  const onBlur = () => {
    if (
      ultimateFilterRef?.current?.formik?.dirty &&
      ultimateFilterRef?.current?.formik?.isValid
    ) {
      ultimateFilterRef?.current?.submit();
    }

    if (ultimateFilterRef?.current?.hasValue) {
      onBlurFromParent?.();
    } else {
      onBlurDebouced();
    }
  };

  const onFocus = () => {
    onFocusFromParent?.();
  };

  const onAdvancedSearchSubmit = (values: AdvancedDocumentSearchDto) => {
    searchOptions?.advancedSearch?.onSubmit?.(values);
  };

  const onAdvancedSearchChange = (
    values: AdvancedDocumentSearchDto,
    changedValue: [keyof DocumentSearchFormModel, any]
  ) => {
    dispatchAdvancedSearchTerm({
      type: changedValue[0],
      value: changedValue[1],
    });
    searchOptions?.advancedSearch?.onChange?.(values);
  };

  const onTextSearchInputChange = (value: string) => {
    searchOptions?.searchTextInput?.onChange?.(value);
  };

  const onTextSearchInputSubmit = (value: string) => {
    searchOptions?.searchTextInput?.onSubmit(value);
  };

  const onAdvancedSearchReset = () => {
    dispatchAdvancedSearchTerm({ type: 'RESET' });
  };

  const onFilterSubmitButtonClick = (
    _: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    ultimateFilterRef?.current?.submit();
  };

  const onFilterrRsetButtonClick = (
    _: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    ultimateFilterRef?.current?.resetSearch(true);
  };
  //#endregion

  //#region -------------------------------- Effects
  useEffect(() => {
    ultimateFilterRef?.current?.resetSearch();
  }, [projectId, ultimateFilterRef]);
  //#endregion

  const renderFormButtonBar = (_: FormikProps<AdvancedDocumentSearchDto>) => (
    <Flex.Row className={classes.actionButtonRow} justifyContent="flex-end">
      <Button onClick={onFilterrRsetButtonClick}>
        {t('mail:mailListFilter.actions.delete')}
      </Button>
      <Button type="primary" onClick={onFilterSubmitButtonClick}>
        {t('mail:mailListFilter.actions.search')}
      </Button>
    </Flex.Row>
  );

  const tagsPopOverConfig: UltimateFilterTagConfig<DocumentSearchFormModel> = {
    alternativeName: {
      contentType: 'text',
      tagLabel: t('documents:driveListFilter.labels.alternativeName'),
      popoverLabel: t(
        'documents:driveListFilter.popoverLabels.alternativeName'
      ),
    },
    keyWords: {
      contentType: 'textArray',
      tagLabel: t('documents:driveListFilter.labels.keyWords'),
      popoverLabel: t('documents:driveListFilter.popoverLabels.keyWords'),
    },
    companyIds: {
      contentType: 'companies',
      tagLabel: t('documents:driveListFilter.labels.companies'),
      popoverLabel: t('documents:driveListFilter.popoverLabels.companies'),
    },
    contactIds: {
      contentType: 'contacts',
      tagLabel: t('documents:driveListFilter.labels.contacts'),
      popoverLabel: t('documents:driveListFilter.popoverLabels.contacts'),
    },
  };

  const quickSearchConfig: QuickSearchConfig = {
    assignedToMe: {
      value: {
        name: t('documents:driveListFilter.labels.quickSearch.assignedToMe'),
        value: [me.id],
      },

      onClick(value) {
        ultimateFilterRef?.current?.setFilterValue({ contactIds: value }, true);
      },
    },
  };

  return (
    <div className={classNames(className, classes.root)}>
      <UltimateFilter
        filterDropdownClassName={classes.dropDownBody}
        tagsPopOverConfig={tagsPopOverConfig}
        ref={ultimateFilterRef}
        placeholder={t('documents:search.placeHolder')}
        initialFilterValues={initialAdvancedFilterValues}
        iconWhenValue={iconWhenValue}
        searchInputValue={searchOptions?.searchTextInput?.value}
        onFilterSubmit={onAdvancedSearchSubmit}
        onFilterChange={onAdvancedSearchChange}
        onFilterSubmitReset={onAdvancedSearchReset}
        onSearchInputSubmit={onTextSearchInputSubmit}
        onSearchInputChange={onTextSearchInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        dropDownRender={renderFormButtonBar}
      >
        {() => (
          <div className={classes.formColumn}>
            <UltimateFilterQuickSearchTags config={quickSearchConfig} />
            <Divider className={classes.divider} />
            <div className={classes.formRow}>
              <UltimateForm.Item
                label={t('documents:driveListFilter.labels.alternativeName')}
                name="alternativeName"
              >
                {({ value, onChange }) => (
                  <DebouncedInputSearch value={value} onChange={onChange} />
                )}
              </UltimateForm.Item>
              <UltimateForm.Item label={'Schlagworte'} name="keyWords">
                {({ value, onChange }) => (
                  <DriveitemtagsPicker
                    projectId={projectId}
                    value={value}
                    onChange={onChange}
                  />
                )}
              </UltimateForm.Item>
            </div>
            <div className={classes.formRow}>
              <UltimateForm.Item
                label={t('documents:driveListFilter.labels.contacts')}
                name="contactIds"
              >
                {({ value, onChange }) => (
                  <ContactPicker
                    multiple
                    value={value}
                    onChange={onChange}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={classes.selectInput}
                  />
                )}
              </UltimateForm.Item>
              <UltimateForm.Item
                label={t('documents:driveListFilter.labels.companies')}
                name="companyIds"
              >
                {({ value, onChange }) => (
                  <CompanyPicker
                    multiple
                    value={value}
                    onChange={onChange}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={classes.selectInput}
                  />
                )}
              </UltimateForm.Item>
            </div>
          </div>
        )}
      </UltimateFilter>
    </div>
  );
};
export default forwardRef(DocumentSearch);
