import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  menu: {
    background: 'none',
    border: 'none',
    padding: `0 ${theme.old.spacing.defaultPadding}px`,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string
) => (
  <Menu.Item key={itemKey}>
    <Link to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {t(`absences:hrAbsenceMenu.navigation.${itemKey}`)}
    </Link>
  </Menu.Item>
);

interface HRAbsenceMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
  isOffice?: boolean;
}

export const HRAbsenceMenu: React.FC<HRAbsenceMenuProps> = (props) => {
  const { selectedList, urlPrefix, className, isOffice = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <Typography.Title className={classes.title}>
        {t('absences:hrAbsenceMenu.title')}
      </Typography.Title>
      <Menu
        className={classes.menu}
        selectedKeys={[selectedList]}
        mode="inline"
        inlineIndent={theme.old.components.menuInlineIndent}
      >
        {navItem('overview', 'map', t, classes, urlPrefix)}
        {navItem('openproposals', 'road', t, classes, urlPrefix)}
        {navItem('allproposals', 'receipt', t, classes, urlPrefix)}
        {navItem('remainingabsence', 'island-tropical', t, classes, urlPrefix)}
        {isOffice &&
          navItem('holidays', 'star-christmas', t, classes, urlPrefix)}
      </Menu>
    </Flex.Column>
  );
};
