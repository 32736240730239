import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popover, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { MonthlyClose } from '../../../models/TimeKeeping';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import Flex from '../../../components/Flex';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTimeString, TimeRecordId } from '../../../models/Types';
import { createSelector } from 'reselect';
import {
  RootReducerState,
  getTimeRecordIds,
  getTimeRecordsByIdState,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { TimeRecordsByIdState } from '../../timeRecords/reducers/timeRecords';
import { User } from '../../../models/User';
import { useSelector } from 'react-redux';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  circularProgressbar: {
    marginLeft: -2,
    marginRight: 6,
    width: 60,
    height: 60,
    paddingTop: 6,
  },
  heading: {
    fontSize: 14,
  },
  popover: {
    width: 300,
  },
  infoIcon: {
    padding: '0px 8px',
    height: '14px',
    width: '14px',
    color: theme.old.palette.chromaticPalette.grey,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const timeRecordSumSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => TimeRecordsByIdState,
      (state: RootReducerState) => TimeRecordId[],
      (state: RootReducerState) => User,
    ],
    number
  >(getTimeRecordsByIdState, getTimeRecordIds, getUserMe, (byId, ids, userMe) =>
    ids.reduce((sum, id) => {
      const timeRecord = byId[id];
      if (
        timeRecord &&
        timeRecord.contactId === userMe.id &&
        timeRecord.day.substring(0, 7) === monthDateTime.substring(0, 7)
      ) {
        sum += timeRecord.durationInMinutes;
      }
      return sum;
    }, 0)
  );

interface TimeRecordHoursCircularProgressChartProps {
  className?: string;
  monthlyClose: MonthlyClose;
}

export const TimeRecordHoursCircularProgressChart: React.FC<
  TimeRecordHoursCircularProgressChartProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, monthlyClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const timeRecordSum = useSelector(
    timeRecordSumSelector(monthlyClose?.month?.substring(0, 7) ?? '')
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      <CircularProgressbarWithChildren
        value={
          ((timeRecordSum === 0 ? 0 : timeRecordSum / 60) /
            (monthlyClose?.actualWorkHours ?? 1)) *
          100
        }
        circleRatio={0.75}
        strokeWidth={16}
        className={classes.circularProgressbar}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: 'butt',
          trailColor: theme.old.palette.backgroundPalette.base,
          pathColor: theme.old.palette.chromaticPalette.lightBlue,
        })}
      />

      <Flex.Column justifyContent="center">
        <Flex.Row alignItems="center">
          <Typography.Text type="secondary" className={classes.heading}>
            {t(
              'timeAndLeaveManagement:calendar.toolbar.proportionTimerecording'
            )}
          </Typography.Text>
          <Popover
            trigger="click"
            content={
              <Typography.Paragraph
                className={classes.popover}
                style={{ marginBottom: '0px' }}
              >
                {t('timeAndLeaveManagement:calendar.toolbar.popover2')}
              </Typography.Paragraph>
            }
          >
            <FontAwesomeIcon
              icon={['fal', 'info-circle']}
              className={classes.infoIcon}
            />
          </Popover>
        </Flex.Row>
        <div>
          {`${timeRecordSum === 0 ? 0 : timeRecordSum / 60} h von ${
            monthlyClose?.actualWorkHours ?? 0
          } h`}
        </div>
      </Flex.Column>
    </Flex.Row>
  );
};

export default TimeRecordHoursCircularProgressChart;
