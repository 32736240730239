import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Divider, Form, Row, Select } from 'antd';
import {
  CompanyId,
  InvoiceStatus,
  InvoiceType,
  ProjectId,
} from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { rowGutter } from '../../../util/forms';
import Flex from '../../../components/Flex';
import { AccountingFilter, Invoice } from '../../../models/Accounting';
import CompanyPicker from '../../companies/components/CompanyPicker';
import ProjectPicker from '../../projects/components/ProjectPicker';
import { distinct } from '../../../util';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  input: {
    width: '100%',
  },
  resetButton: {
    fontSize: 14,
    padding: 0,
    height: '100%',
  },
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  radioLabelCol: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
}));

interface AdvancedInvoiceFilterProps {
  invoices: Invoice[];
  companyFilter?: boolean;
  projectFilter?: boolean;
  typeFilter?: boolean;
  statusFilter?: boolean;
  onFilterChange?: (value: AccountingFilter) => void;
  isFilterReset?: boolean;
}

export const AdvancedInvoiceFilter: React.FC<AdvancedInvoiceFilterProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    invoices,
    companyFilter,
    projectFilter,
    typeFilter,
    statusFilter,
    onFilterChange,
    isFilterReset,
  } = props;
  //#endregion
  //#region -------------------------------- State declaration

  const [currentFilter, setCurrentFilter] = useState<AccountingFilter>({});

  const projectIds = distinct(invoices?.map((invoice) => invoice.projectId));
  const companyIds = distinct(
    invoices?.map((invoice) => invoice.recipientCompanyId)
  );
  const status = distinct(invoices?.map((invoice) => invoice.status));
  const types = distinct(invoices?.map((invoice) => invoice.type));

  const [form] = Form.useForm();
  //#endregion
  //#region -------------------------------- Methods
  const calculateSpan = () => {
    const filters = [companyFilter, projectFilter, typeFilter, statusFilter];
    const filtersCount = filters.filter(Boolean).length;
    return 24 / filtersCount;
  };

  const onFilterChangeHandler = (filter: AccountingFilter) => {
    setCurrentFilter(filter);
    if (onFilterChange) {
      onFilterChange(filter);
    }
  };

  //#endregion
  //#region -------------------------------- Handle methods
  const onCompanyChange = (value: CompanyId[]) => {
    onFilterChangeHandler({ ...currentFilter, companyIds: value });
  };

  const onProjectChange = (value: ProjectId[]) => {
    onFilterChangeHandler({ ...currentFilter, projectIds: value });
  };

  const onTypeChange = (value: InvoiceType[]) => {
    onFilterChangeHandler({ ...currentFilter, types: value });
  };

  const onStatusChange = (value: InvoiceStatus[]) => {
    onFilterChangeHandler({ ...currentFilter, status: value });
  };

  //#endregion
  //#region -------------------------------- Hooks
  useEffect(() => {
    if (isFilterReset) form.resetFields();
  }, [isFilterReset, form]);
  //#endregion

  return (
    <Collapse ghost className={classes.collapse} defaultActiveKey={['0']}>
      <Collapse.Panel
        header={
          <Flex.Row flex={1}>
            <div className={classes.radioLabelCol}>
              {t('common:filter.label.filterHeader')}
            </div>
            <Divider className={classes.divider} />
          </Flex.Row>
        }
        key="1"
      >
        <Form
          className={classes.root}
          initialValues={{}}
          form={form}
          layout="vertical"
          onValuesChange={(changedValue) => {
            if (changedValue.companyId) {
              onCompanyChange(changedValue.companyId);
            }
            if (changedValue.projectId) {
              onProjectChange(changedValue.projectId);
            }
            if (changedValue.invoiceType) {
              onTypeChange(changedValue.invoiceType);
            }
            if (changedValue.invoiceStatus) {
              onStatusChange(changedValue.invoiceStatus);
            }
          }}
        >
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            {companyFilter && (
              <Col span={calculateSpan()}>
                <Form.Item
                  label={t('controlling:filter.recipientCompanyId')}
                  name={'companyId'}
                >
                  <CompanyPicker
                    className={classes.fullWidth}
                    label={t('common:filter.placeholder.companyId')}
                    multiple
                    value={currentFilter.companyIds}
                    onChange={onCompanyChange}
                    onlyCompanyIds={companyIds}
                    fetch
                  />
                </Form.Item>
              </Col>
            )}
            {projectFilter && (
              <Col span={calculateSpan()}>
                <Form.Item
                  label={t('common:filter.label.projectId')}
                  name={'projectId'}
                >
                  <ProjectPicker
                    className={classes.fullWidth}
                    label={t('common:filter.placeholder.projectId')}
                    value={currentFilter.projectIds}
                    onChange={onProjectChange}
                    onlyProjectIds={projectIds}
                    multiple
                    contextType="global"
                  />
                </Form.Item>
              </Col>
            )}
            {typeFilter && (
              <Col span={calculateSpan()}>
                <Form.Item
                  label={t('common:filter.label.invoiceType')}
                  name={'invoiceId'}
                >
                  <Select<InvoiceType[]>
                    className={classes.fullWidth}
                    mode="multiple"
                    placeholder={t('common:filter.placeholder.invoiceType')}
                    value={currentFilter.types}
                    onChange={onTypeChange}
                  >
                    {types.map((invoiceType) => (
                      <Select.Option key={invoiceType} value={invoiceType}>
                        {t(`common:filter.invoiceType.${invoiceType}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {statusFilter && (
              <Col span={calculateSpan()}>
                <Form.Item
                  label={t('common:filter.label.invoiceStatus')}
                  name={'invoiceStatus'}
                >
                  <Select<InvoiceStatus[]>
                    className={classes.fullWidth}
                    mode="multiple"
                    placeholder={t('common:filter.placeholder.invoiceStatus')}
                    value={currentFilter.status}
                    onChange={onStatusChange}
                    //disabled={true}
                  >
                    {status.map((invoiceStatus) => (
                      <Select.Option key={invoiceStatus} value={invoiceStatus}>
                        {t(`common:filter.invoiceStatus.${invoiceStatus}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default AdvancedInvoiceFilter;
