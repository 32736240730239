import React, {
  Key,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Checkbox, Table, TableProps, Typography } from 'antd';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';
import { ColumnType } from 'antd/lib/table';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';

const useStyles = makePrioStyles((theme) => ({
  virtualTable: {
    height: '100%',
    '& .ant-spin-nested-loading': {
      height: '100%',
    },
    '& .ant-spin-container': {
      height: '100%',
    },
    '& .ant-table': {
      height: '100%',
    },
    '& .ant-table-container': {
      height: '100%',
    },
  },
  virtualList: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    '& > div:hover': {
      cursor: 'pointer',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  selectedRow: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
  },
  virtualTableCell: {
    borderBottom: theme.old.borders.content,
    display: 'flex',
    alignItems: 'center',
    padding: theme.old.spacing.unit(2),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  virtualTableCellSelection: {
    borderBottom: theme.old.borders.content,
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(1)}px`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  virtualTableCellLast: {},
}));

interface VirtualTableProps<RecordType> extends TableProps<RecordType> {
  headerHeight?: number;
}

function VirtualTable<RecordType extends object = any>(
  props: VirtualTableProps<RecordType>
) {
  //#region ------------------------------ Defaults
  const { columns, dataSource, rowSelection, onRow } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const ref = useRef<List>(null);
  const hasCheckbox = rowSelection?.type === 'checkbox';

  const [selectedRows, setSelectedRows] = useState<RecordType[]>([]);

  const selectedRowKeys: React.Key[] = useMemo(() => {
    return selectedRows.map(
      (row, index) => (row as any).key ?? index.toString()
    );
  }, [selectedRows]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleRowClick = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      rowData: RecordType,
      rowIndex: number
    ) => {
      if (onRow) {
        onRow(rowData, rowIndex)?.onClick(event);
      }
    },
    [onRow]
  );

  const handleSelected = useCallback(
    (value: any, selected: boolean) => {
      let newSelectedRows: RecordType[] = [];
      if (selected) {
        newSelectedRows = [value, ...selectedRows];
      } else {
        newSelectedRows = selectedRows.filter(
          (row) => (row as any).key !== (value as any).key
        );
      }
      setSelectedRows(newSelectedRows);
      if (rowSelection?.onChange) {
        rowSelection.onChange(
          newSelectedRows.map(
            (row, index) => (row as any).key ?? index.toString()
          ),
          newSelectedRows
        );
      }
    },
    [selectedRows, rowSelection]
  );

  const filterSelctionOnDataChange = useCallback(() => {
    const filteredSelection = selectedRows.filter((row) =>
      dataSource.find((entry) => (entry as any).key === (row as any).key)
    );
    if (filteredSelection.length < selectedRows.length) {
      setSelectedRows(filteredSelection);
      if (rowSelection?.onChange) {
        rowSelection.onChange(
          filteredSelection.map(
            (row, index) => (row as any).key ?? index.toString()
          ),
          filteredSelection
        );
      }
    }
  }, [dataSource, selectedRows, rowSelection]);
  //#endregion

  //#region ------------------------------ Components
  const bodyRendererCallback = useCallback(
    function bodyRenderer<RecordType>(
      rawData: RecordType[],
      { width }: any,
      selectedRowKeys: React.Key[]
    ) {
      return (
        <InfiniteLoader
          isRowLoaded={
            ({ index }) => index < rawData.length //isRowLoaded ?? (({ index }) => index < (totalItemCount ?? items.length))
          }
          loadMoreRows={async ({ startIndex, stopIndex }) => {}} //loadMoreRows ?? (async ({ startIndex, stopIndex }) => {})}
          rowCount={rawData.length + 10} //totalItemCount ?? items.length + (threshold ?? 0)}
          threshold={10}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  rowCount={rawData.length}
                  rowHeight={55}
                  height={height - 55}
                  width={width}
                  overscanRowCount={2}
                  onRowsRendered={onRowsRendered}
                  ref={(element) => {
                    registerChild(element);
                    ref.current = element;
                  }}
                  rowRenderer={({ index, style }) => {
                    const rowEntry = rawData[index];
                    const selected: boolean = selectedRowKeys.includes(
                      (rowEntry as any)?.key ?? index.toString()
                    );
                    return (
                      <TableRow
                        {...{
                          rowEntry,
                          rowIndex: index,
                          handleRowClick,
                          selected,
                          style,
                          hasCheckbox,
                          columns,
                          onSelectedChange: handleSelected,
                        }}
                      />
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      );
    },
    [hasCheckbox, columns, handleSelected, handleRowClick]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    filterSelctionOnDataChange();
  }, [dataSource, filterSelctionOnDataChange]);

  useEffect(() => {
    if (ref.current) {
      ref.current.forceUpdate();
    }
  }, [ref, selectedRowKeys, props.dataSource]);
  //#endregion

  return (
    <Table<RecordType>
      {...props}
      className={classNames(classes.virtualTable, props.className)}
      columns={columns}
      components={{
        //@ts-ignore
        body: (rawData, style) =>
          bodyRendererCallback(rawData as RecordType[], style, selectedRowKeys),
      }}
      rowSelection={
        rowSelection
          ? {
              columnWidth: 32,
              selectedRowKeys: selectedRowKeys,
              onSelectAll: (
                selected: boolean,
                selectedRows: RecordType[],
                changeRows: RecordType[]
              ) => {
                if (rowSelection?.onSelectAll) {
                  rowSelection.onSelectAll(selected, selectedRows, changeRows);
                }
                setSelectedRows(selectedRows);
              },
              ...rowSelection,
            }
          : undefined
      }
    />
  );
}

export default React.memo(VirtualTable) as typeof VirtualTable;

interface TableRowProps<RecordType> {
  rowEntry: RecordType;
  rowIndex: number;
  handleRowClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    rowData: RecordType,
    rowIndex: number
  ) => void;
  selected: boolean;
  style: React.CSSProperties;
  hasCheckbox: boolean;
  columns: any;
  onSelectedChange: (value: RecordType, selected: boolean) => void;
}

function TableRow<RecordType>(props: TableRowProps<RecordType>) {
  const {
    rowEntry,
    rowIndex,
    handleRowClick,
    selected,
    style,
    hasCheckbox,
    columns,
    onSelectedChange,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [rowSelected, setRowSelected] = useState<boolean>(selected ?? false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSelected = (value: boolean) => {
    setRowSelected(value);
    onSelectedChange(rowEntry, value);
  };
  //#endregion

  //#region ------------------------------ useEffects
  useEffect(() => {
    setRowSelected(selected);
  }, [selected]);
  //#endregion

  return (
    <div
      className={classNames(classes.row, {
        [classes.selectedRow]: rowSelected,
      })}
      onClick={(e) => {
        handleRowClick(e, rowEntry, rowIndex);
      }}
      style={style}
      key={rowIndex}
    >
      {(hasCheckbox ? [{ width: 56 }, ...columns] : columns).map(
        (column, columnIndex) => {
          return TableCell<RecordType>({
            columnIndex,
            rowIndex,
            rowEntry,
            columnEntry: column as any,
            columns,
            hasCheckbox,
            handleSelected,
            selected,
          });
        }
      )}
    </div>
  );
}

interface TableCellProps<RecordType> {
  columnIndex: number;
  rowIndex: number;
  columns: any;
  rowEntry: RecordType;
  columnEntry: ColumnType<RecordType>;
  hasCheckbox: boolean;
  handleSelected: (value: boolean) => void;
  selected: boolean;
}

function TableCell<RecordType>(props: TableCellProps<RecordType>) {
  const {
    columnIndex,
    rowIndex,
    columns,
    rowEntry,
    columnEntry,
    hasCheckbox,
    handleSelected,
    selected,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const renderedCell: React.ReactNode | null = useMemo(
    () =>
      (columnEntry as any)?.render ? (
        typeof (columnEntry as any)?.render === 'string' ? (
          <Typography.Text
            title={(rowEntry as any)[(columnEntry as any).dataIndex]}
            ellipsis
          >
            {(columnEntry as any).render(
              (rowEntry as any)[(columnEntry as any).dataIndex],
              rowEntry,
              rowIndex
            )}
          </Typography.Text>
        ) : (
          (columnEntry as any)?.render(
            (rowEntry as any)[(columnEntry as any).dataIndex],
            rowEntry,
            rowIndex
          )
        )
      ) : null,
    [rowIndex, columnEntry, rowEntry]
  );
  //#endregion

  //#region ------------------------------ Components
  const content = useMemo(
    () =>
      renderedCell ? (
        renderedCell
      ) : (
        <Typography.Text
          title={(rowEntry as any)[(columnEntry as any).dataIndex]}
          ellipsis
        >
          {(rowEntry as any)[(columnEntry as any).dataIndex]}
        </Typography.Text>
      ),
    [renderedCell, rowEntry, columnEntry]
  );
  //#endregion

  if (hasCheckbox && columnIndex === 0) {
    return (
      <div
        className={classes.virtualTableCellSelection}
        onClick={(e) => {
          e.stopPropagation();
        }}
        key="checkbox"
      >
        <Checkbox
          checked={selected}
          onChange={(e) => {
            handleSelected(e.target.checked);
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames('row', classes.virtualTableCell, {
        [classes.virtualTableCellLast]: columnIndex === columns.length - 1,
        test: rowIndex === 1,
      })}
      style={
        columnEntry?.width
          ? {
              minWidth: columnEntry?.width,
            }
          : {
              flex: 1,
            }
      }
      key={columnEntry.dataIndex as Key}
    >
      {content}
    </div>
  );
}
