import React from 'react';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';
import { DateTimeString } from '../../../models/Types';
import moment, { Moment } from 'moment';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    paddingRight: theme.old.spacing.defaultPadding,
  },
  iconTextGroup: {
    marginBottom: theme.old.spacing.unit(1.5),
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
    color: theme.old.palette.primaryColor,
  },
  date: {
    '&.ant-typography': {
      color: theme.old.palette.primaryColor,
      margin: 0,
    },
    paddingBottom: theme.old.spacing.baseSpacing,
  },
  monthYear: {
    '&.ant-typography': {
      color: theme.old.palette.primaryColor,
      margin: 0,
    },
    paddingBottom: theme.old.spacing.defaultPadding,
  },
}));

interface TrainingDayLocationInfoProps {
  trainingDays?: Array<TrainingSchedule>;
  eventLocation?: string;
  isOnlineTraining?: boolean;
}

interface TrainingSchedule {
  startTime?: DateTimeString;
  endTime?: DateTimeString;
}

export const TrainingDayLocationInfo: React.FC<TrainingDayLocationInfoProps> = (
  props
) => {
  const { trainingDays, eventLocation, isOnlineTraining } = props;
  const classes = useStyles();
  const start =
    trainingDays.length > 0 ? moment(trainingDays[0].startTime) : null;
  const end =
    trainingDays.length > 0
      ? moment(trainingDays[trainingDays.length - 1].endTime)
      : null;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Flex.Row justifyContent="space-between">
        {dateTitle(classes, start)}
        {!start?.isSame(end, 'day') ? (
          <Typography.Title className={classes.date}>-</Typography.Title>
        ) : (
          ''
        )}
        {!start?.isSame(end, 'day') ? dateTitle(classes, end) : ''}
      </Flex.Row>
      {scheduleGroup(trainingDays, classes)}
      <div className={classes.iconTextGroup}>
        <FontAwesomeIcon
          fixedWidth
          icon={['fal', 'map-marker-alt']}
          className={classes.icon}
        />
        {eventLocation}
      </div>
      {isOnlineTraining ? (
        <div className={classes.iconTextGroup}>
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'globe-europe']}
            className={classes.icon}
          />
          {t('profile:training.isOnlineTraining')}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const dateTitle = (classes: Classes, day: Moment) => (
  <div>
    <Typography.Title className={classes.date}>
      {moment(day).format('DD')}.
    </Typography.Title>
    <Typography.Paragraph className={classes.monthYear}>
      {moment(day).format('MMMM') + ' ' + moment(day).format('YYYY')}
    </Typography.Paragraph>
  </div>
);

const scheduleGroup = (list: Array<TrainingSchedule>, classes: Classes) => (
  <Flex.Row className={classes.iconTextGroup}>
    <Flex.Item>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', 'clock']}
        className={classes.icon}
      />
    </Flex.Item>
    <Flex.Item>
      <Flex.Column>
        {list.map((element) => (
          <Flex.Item key={element.startTime}>
            {moment(element.startTime).format('HH:mm') +
              ' - ' +
              moment(element.endTime).format('HH:mm') +
              (list.length > 1
                ? ', ' + moment(element.endTime).format('DD. MMMM YYYY')
                : '')}
          </Flex.Item>
        ))}
      </Flex.Column>
    </Flex.Item>
  </Flex.Row>
);

export default TrainingDayLocationInfo;
