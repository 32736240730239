import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getContactsByIdState,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import { formatMinutesFloat, isoDateFormat } from '../../../util';
import { TimeRecord } from '../../../models/TimeRecord';
import { ColumnProps } from 'antd/lib/table';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { ProjectId, TimeRecordId } from '../../../models/Types';
import { sumUpDurationInMinutes } from '../util';
import {
  TableRowSelection,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { TFunction } from 'i18next';
import { tableTranslations } from '../../../util/table';
import PrioSpinner from '../../../components/PrioSpinner';
import VirtualTable from '../../../components/VirtualTable';
import classNames from 'classnames';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxHeight: '100%',
    height: '100%',
    '& .ant-table-row .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-sticky-holder': {
      zIndex: 0,
    },
    '& .ant-table-sticky-scroll': {
      display: 'none',
    },
    '& .ant-spin-nested-loading': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-spin-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-container': {
      overflow: 'hidden',
      height: '100%',
    },
  },
  lastDays: {
    marginTop: `${theme.old.spacing.unit(4)}px !important`,
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(6)': {
      ...theme.old.components.table.secondaryColumn,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& > td:nth-child(7)': theme.old.components.table.secondaryColumn,
  },
}));

interface TimeRecordsTableProps {
  className?: string;
  timeRecords: TimeRecord[];
  showProject?: boolean;
  pagination?: false | TablePaginationConfig;
  onTimeRecordClick?: (timeRecordId: TimeRecordId) => void;
  onRowSelectionChange?: (selectedTimeRecordIds: TimeRecordId[]) => void;
  loading?: boolean;
}

interface TableEntry extends TimeRecord {
  key: string;
  contactName: string;
  projectName: string;
}

const columns: (
  t: TFunction,
  showProject?: boolean
) => ColumnProps<TableEntry>[] = (t, showProject) => [
  {
    title: t('timeRecords:table.columnTitle.day'),
    dataIndex: 'day',
    key: 'day',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.day?.localeCompare(b.day),
    render: (value) => moment(value, isoDateFormat).format('DD.MM.YYYY'),
    ellipsis: true,
  },
  {
    title: t('timeRecords:table.columnTitle.title'),
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => a.title?.localeCompare(b.title),
    ellipsis: true,
  },
  {
    title: t('timeRecords:table.columnTitle.contactName'),
    dataIndex: 'contactName',
    key: 'contactName',
    sorter: (a, b) => a.contactName?.localeCompare(b.contactName),
    ellipsis: true,
  },
  {
    title: t('timeRecords:table.columnTitle.duration'),
    dataIndex: 'durationInMinutes',
    key: 'durationInMinutes',
    sorter: (a, b) => a.durationInMinutes - b.durationInMinutes,
    render: (value) => formatMinutesFloat(value ?? 0),
    ellipsis: true,
  },
  ...(showProject
    ? [
        {
          title: t('timeRecords:table.columnTitle.projectName'),
          dataIndex: 'projectName',
          key: 'projectName',
          sorter: (a, b) => a.projectName?.localeCompare(b.projectName),
          ellipsis: true,
        },
      ]
    : []),
  {
    title: t('timeRecords:table.columnTitle.kilometerDistance'),
    dataIndex: 'kilometerDistance',
    key: 'kilometerDistance',
    sorter: (a, b) => a.durationInMinutes - b.durationInMinutes,
    ellipsis: true,
  },
  {
    title: t('timeRecords:table.columnTitle.invoiceNumber'),
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    render: (value) => value ?? '-',
    sorter: (a, b) => a.invoiceNumber?.localeCompare(b.invoiceNumber),
    ellipsis: true,
  },
];

const generateProjectName = (
  projectId: ProjectId,
  projectsByIdState: ProjectByIdState
) => {
  if (projectsByIdState && projectId && projectsByIdState[projectId]) {
    const project = projectsByIdState[projectId];
    return `${project.number ? `${project.number} ` : ''}${
      project.shortName ?? project.name
    }`;
  }
  return '';
};

export const TimeRecordsTable: React.FC<TimeRecordsTableProps> = (props) => {
  const {
    className,
    onTimeRecordClick,
    onRowSelectionChange,
    timeRecords,
    showProject,
    loading,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const sortedRecords = timeRecords.sort((a, b) => b.day.localeCompare(a.day));
  const contactsById = useSelector(getContactsByIdState);
  const projectsByIdState = useSelector(getProjectByIdState);

  const tableData: TableEntry[] = sortedRecords.map((timeRecord) => ({
    key: timeRecord.timeRecordId,
    ...timeRecord,
    projectName: generateProjectName(timeRecord.projectId, projectsByIdState),
    contactName: `${contactsById[timeRecord.contactId]
      ?.firstName} ${contactsById[timeRecord.contactId]?.lastName}`,
    durationInMinutes:
      timeRecord.durationInMinutes ??
      sumUpDurationInMinutes(timeRecord.timeRecordEntries),
  }));

  const rowSelection: TableRowSelection<TableEntry> = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (onRowSelectionChange)
        onRowSelectionChange(selectedRowKeys.map((key) => key.toString()));
    },
  };

  return (
    <div className={classNames(classes.root, className)}>
      <VirtualTable<TableEntry>
        columns={columns(t, showProject)}
        headerHeight={56}
        className={classes.table}
        dataSource={tableData}
        scroll={{ y: 300, x: '100%' }}
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        onRow={
          onTimeRecordClick &&
          ((record: TableEntry, rowIndex) => {
            return {
              onClick: () => {
                if (record.timeRecordId) onTimeRecordClick(record.timeRecordId);
              },
            };
          })
        }
        rowClassName={classes.row}
        loading={{
          spinning: loading,
          indicator: <PrioSpinner />,
        }}
        locale={tableTranslations(t)}
      />
    </div>
  );
};

export default TimeRecordsTable;
