import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getMyProjectIds,
  getProject,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import ProjectProfileMenu from './ProjectProfileMenu';
import ProjectMenu from './ProjectMenu';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
    transition: 'padding 0.2s',
  },
}));

interface ProjectMainMenuCollapsedProps {
  activeProjectId: string;
  selectedSubModule: string;
  pathPrefix: string;
}

export const ProjectMainMenuCollapsed: React.FC<
  ProjectMainMenuCollapsedProps
> = (props) => {
  const { activeProjectId, selectedSubModule, pathPrefix } = props;
  const classes = useStyles();

  //#region ------------------------------ States / Attributes / Selectors
  const activeProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, activeProjectId)
  );

  const myProjectIds = useSelector(getMyProjectIds);
  const myProjectIdsSet = useMemo(() => new Set(myProjectIds), [myProjectIds]);
  const isProjectMember = myProjectIdsSet.has(activeProject?.projectId);
  //#endregion

  if (activeProjectId === 'me') {
    return (
      <ProjectProfileMenu
        collapsed
        urlPrefix={`${pathPrefix}me/`}
        selectedList={selectedSubModule}
        className={classes.menu}
        hideTrainings
        hidePersonalFile
      />
    );
  }

  if (!activeProject || activeProject?.isTemporary) {
    return null;
  }

  return (
    <ProjectMenu
      projectId={activeProject.projectId}
      selectedProjectId={activeProjectId}
      selectedSubModule={selectedSubModule}
      masterPlanId={activeProject.masterPlanId}
      groupId={activeProject.groupId}
      oneDrivePath={activeProject.oneDrivePath}
      emailSuffix={activeProject.eMailSuffix}
      emailPrefix={activeProject.eMailPrefix}
      isProjectMember={isProjectMember}
      pathPrefix={pathPrefix}
      subsidiaryId={activeProject.subsidiaryId}
      sharedMailboxUserId={activeProject.sharedMailboxUserId}
      collapsed
    />
  );
};

export default ProjectMainMenuCollapsed;
