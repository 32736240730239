import React, { useState } from 'react';
import { Popover, Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { TimelineEvent } from './TimelineComponent';
import { ItemContext } from 'react-calendar-timeline';
import { compactDateFormatString } from '../../../../util';
import ContactText from '../../../contacts/components/ContactText';
import classNames from 'classnames';
import { OfficeId } from '../../../../models/Types';
import {
  apiAcceptAbsenceProposal,
  apiAcceptOfficeAbsenceProposal,
  apiDeclineAbsenceProposal,
  apiDeclineOfficeAbsenceProposal,
} from '../../api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import useAccessRights from '../../../users/hooks/useAccessRights';

const useStyles = makePrioStyles((theme) => ({
  root: {
    background: theme.old.palette.primaryColor,
    border: `1px solid #fff`,
    borderRadius: 2,
    boxSizing: 'border-box',
    fontSize: 12,
    position: 'absolute',
    color: theme.old.typography.colors.inverted,
  },
  title: {
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: `0px ${theme.old.spacing.defaultPadding}px`,
  },
  titleText: {
    width: '100%',
    fontSize: 12,
    color: theme.old.typography.colors.inverted,
  },
  popover: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    width: 650 - 2 * theme.old.spacing.unit(4),
    margin: `${theme.old.spacing.unit(2.5)}px ${theme.old.spacing.unit(2)}px`,
    marginTop: 0,
  },
  info: {
    width: '100%',
  },
  infoTitle: {
    fontWeight: 'normal',
    fontSize: 16,
  },
  infoGroup: {
    padding: `${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(3)}px`,
    background: theme.old.palette.backgroundPalette.sub,
    width: 650 - 2 * theme.old.spacing.unit(4),
    borderRadius: 1,
  },
  infoHead: {
    color: theme.old.typography.colors.muted,
    fontSize: 12,
    '& span': {
      color: theme.old.typography.colors.muted,
    },
  },
  infoContent: {
    color: '#000',
    fontSize: 16,
  },
  cancel: {
    height: 0,
    marginTop: theme.old.spacing.unit(2.5),
    marginRight: theme.old.spacing.unit(1),
  },
  annualLeaveRequested: {
    background: theme.old.palette.chromaticPalette.yellow,
  },
  annualLeaveAccepted: {
    background: theme.old.palette.chromaticPalette.green,
  },
  annualLeavePlanned: {
    background: theme.old.palette.chromaticPalette.grey,
  },
  paidSpecialLeaveRequested: {
    background: `repeating-linear-gradient(45deg,${theme.old.palette.chromaticPalette.yellow},${theme.old.palette.chromaticPalette.yellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 20px)`,
  },
  paidSpecialLeaveAccepted: {
    background: `repeating-linear-gradient(45deg,${theme.old.palette.chromaticPalette.green},${theme.old.palette.chromaticPalette.green} 10px,${theme.old.palette.chromaticPalette.darkGreen} 10px,${theme.old.palette.chromaticPalette.darkGreen} 20px)`,
  },
  paidSpecialLeavePlanned: {
    background: `repeating-linear-gradient(45deg,${theme.old.palette.primaryColor},${theme.old.palette.primaryColor} 10px,${theme.old.palette.backgroundPalette.content} 10px,${theme.old.palette.backgroundPalette.content} 20px)`,
  },
  overtimeCompensationRequested: {
    background: `repeating-linear-gradient(45deg,${theme.old.palette.chromaticPalette.yellow},${theme.old.palette.chromaticPalette.yellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 20px)`,
  },
  overtimeCompensationAccepted: {
    background: `repeating-linear-gradient(45deg,${theme.old.palette.chromaticPalette.green},${theme.old.palette.chromaticPalette.green} 10px,${theme.old.palette.chromaticPalette.darkGreen} 10px,${theme.old.palette.chromaticPalette.darkGreen} 20px)`,
  },
  overtimeCompensationPlanned: {
    background: `repeating-linear-gradient(45deg,${theme.old.palette.primaryColor},${theme.old.palette.primaryColor} 10px,${theme.old.palette.backgroundPalette.content} 10px,${theme.old.palette.backgroundPalette.content} 20px)`,
  },
  dayOfIllnessRequested: {
    background: `repeating-linear-gradient(90deg,${theme.old.palette.chromaticPalette.yellow},${theme.old.palette.chromaticPalette.yellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 10px,${theme.old.palette.chromaticPalette.darkYellow} 20px)`,
  },
  dayOfIllnessAccepted: {
    background: `repeating-linear-gradient(90deg,${theme.old.palette.chromaticPalette.green},${theme.old.palette.chromaticPalette.green} 10px,${theme.old.palette.chromaticPalette.darkGreen} 10px,${theme.old.palette.chromaticPalette.darkGreen} 20px)`,
  },
  dayOfIllnessPlanned: {
    background: `repeating-linear-gradient(90deg,${theme.old.palette.primaryColor},${theme.old.palette.primaryColor} 10px,${theme.old.palette.backgroundPalette.content} 10px,${theme.old.palette.backgroundPalette.content} 20px)`,
  },
  nonAnnualLeaveAbsence: {
    background: theme.old.palette.chromaticPalette.red,
  },
}));

interface TimelineItemProps {
  item?: TimelineEvent;
  itemContext?: ItemContext;
  getItemProps?: any;
  getResizeProps?: any;
  onReload: VoidFunction;
  officeId: OfficeId | null;
  context: 'me' | 'office' | 'global';
}

export const TimelineItem: React.FC<TimelineItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    item,
    item: { absenceProposal },
    getItemProps,
    itemContext,
    onReload,
    officeId: officeIdValue,
    context,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();

  const style = {
    height: getItemProps(item.itemProps).style.height,
    left: itemContext.dimensions.left,
    lineHeight: getItemProps(item.itemProps).style.lineHeight,
    top: itemContext.dimensions.top,
    width: itemContext.dimensions.width,
    zIndex: getItemProps(item.itemProps).style.zIndex,
    borderRadius: 2,
  };
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [visible, setVisible] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const officeId = officeIdValue ?? item.additionalInfo.contact?.officeId;

  const {
    writeOtherUserAbsenceByGlobalRole,
    writeOtherUserAbsenceByOfficeRole,
  } = useAccessRights(
    ['writeOtherUserAbsenceByGlobalRole', 'writeOtherUserAbsenceByOfficeRole'],
    {
      officeId,
    }
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const declineProposal = async () => {
    setSubmitting(true);
    const { result } = writeOtherUserAbsenceByGlobalRole
      ? await apiDeclineAbsenceProposal(absenceProposal.absenceProposalId)
      : await apiDeclineOfficeAbsenceProposal(
          absenceProposal.absenceProposalId,
          officeId
        );
    if (result.status >= 200 && result.status < 300) {
      onReload();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.declineAbsenceProposalError'),
      });
    }
    setSubmitting(false);
  };

  const acceptProposal = async () => {
    setSubmitting(true);
    const { result } = writeOtherUserAbsenceByGlobalRole
      ? await apiAcceptAbsenceProposal(absenceProposal.absenceProposalId)
      : await apiAcceptOfficeAbsenceProposal(
          absenceProposal.absenceProposalId,
          officeId
        );
    if (result.status >= 200 && result.status < 300) {
      onReload();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.acceptAbsenceProposalError'),
      });
    }
    setSubmitting(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  let stateClass = null;

  switch (absenceProposal.absenceType) {
    case 'annualLeave':
      if (absenceProposal.absenceState === 'requested') {
        stateClass = classes.annualLeaveRequested;
      } else if (absenceProposal.absenceState === 'accepted') {
        stateClass = classes.annualLeaveAccepted;
      } else if (absenceProposal.absenceState === 'planned') {
        stateClass = classes.annualLeavePlanned;
      }
      break;
    case 'annualLeavePlanning':
      if (absenceProposal.absenceState === 'requested') {
        stateClass = classes.annualLeavePlanned;
      } else if (absenceProposal.absenceState === 'accepted') {
        stateClass = classes.annualLeavePlanned;
      } else if (absenceProposal.absenceState === 'planned') {
        stateClass = classes.annualLeavePlanned;
      }
      break;
    case 'overtimeCompensation':
      if (absenceProposal.absenceState === 'requested') {
        stateClass = classes.overtimeCompensationRequested;
      } else if (absenceProposal.absenceState === 'accepted') {
        stateClass = classes.overtimeCompensationAccepted;
      } else if (absenceProposal.absenceState === 'planned') {
        stateClass = classes.overtimeCompensationPlanned;
      }
      break;
    case 'paidSpecialLeave':
      if (absenceProposal.absenceState === 'requested') {
        stateClass = classes.paidSpecialLeaveRequested;
      } else if (absenceProposal.absenceState === 'accepted') {
        stateClass = classes.paidSpecialLeaveAccepted;
      } else if (absenceProposal.absenceState === 'planned') {
        stateClass = classes.paidSpecialLeavePlanned;
      }
      break;
    case 'dayOfIllness':
      if (absenceProposal.absenceState === 'requested') {
        stateClass = classes.dayOfIllnessRequested;
      } else if (absenceProposal.absenceState === 'accepted') {
        stateClass = classes.dayOfIllnessAccepted;
      } else if (absenceProposal.absenceState === 'planned') {
        stateClass = classes.dayOfIllnessPlanned;
      }
      break;
    default:
      stateClass = classes.nonAnnualLeaveAbsence;
  }

  return (
    <Popover
      trigger="click"
      placement={'topLeft'}
      arrowPointAtCenter
      visible={visible}
      content={
        <>
          <Flex.Row className={classes.cancel}>
            <Flex.Item flex={1} />
            <Button
              shape="circle"
              type="link"
              iconProp={['fal', 'times']}
              onClick={() => setVisible(false)}
            />
          </Flex.Row>
          <div className={classes.popover}>
            <Flex.Column className={classes.info} childrenGap={10}>
              <Typography.Text className={classes.infoTitle}>
                {item.title}
              </Typography.Text>
              <Flex.Row className={classes.infoGroup} alignItems="center">
                <Flex.Item flex={1} className={classes.infoHead}>
                  {t('calendar:timeline.popover.reason')}
                </Flex.Item>
                <Flex.Item className={classes.infoContent} alignSelf="end">
                  {t(`absences:types.${absenceProposal.absenceType}`)}
                </Flex.Item>
              </Flex.Row>
              <Flex.Column
                className={classes.infoGroup}
                childrenGap={theme.old.spacing.baseSpacing}
              >
                <Flex.Row alignItems="center">
                  <Flex.Item flex={1} className={classes.infoHead}>
                    {t('calendar:timeline.popover.applicant')}
                  </Flex.Item>
                  <Flex.Item
                    className={classes.infoContent}
                    alignSelf="flex-end"
                  >
                    <ContactText contactId={absenceProposal.applicantId} />
                  </Flex.Item>
                </Flex.Row>
                <Flex.Row alignItems="center">
                  <Flex.Item flex={1} className={classes.infoHead}>
                    {t('calendar:timeline.popover.officeName')}
                  </Flex.Item>
                  <Flex.Item
                    className={classes.infoContent}
                    alignSelf="flex-end"
                  >
                    {item.additionalInfo.officeName}
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
              <Flex.Column
                className={classes.infoGroup}
                childrenGap={theme.old.spacing.baseSpacing}
              >
                <Flex.Row alignItems="center">
                  <Flex.Item flex={1} className={classes.infoHead}>
                    {t('calendar:timeline.popover.from')}
                  </Flex.Item>
                  <Flex.Item
                    className={classes.infoContent}
                    alignSelf="flex-end"
                  >
                    {compactDateFormatString(absenceProposal.from)}
                  </Flex.Item>
                </Flex.Row>
                <Flex.Row alignItems="center">
                  <Flex.Item flex={1} className={classes.infoHead}>
                    {t('calendar:timeline.popover.to')}
                  </Flex.Item>
                  <Flex.Item
                    className={classes.infoContent}
                    alignSelf="flex-end"
                  >
                    {compactDateFormatString(absenceProposal.to)}
                  </Flex.Item>
                </Flex.Row>
                <Flex.Row alignItems="center">
                  <Flex.Item flex={1} className={classes.infoHead}>
                    {t('calendar:timeline.popover.sumHolidays')}
                  </Flex.Item>
                  <Flex.Item
                    className={classes.infoContent}
                    alignSelf="flex-end"
                  >
                    {absenceProposal.absentWorkdays}
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
              <Flex.Row className={classes.infoGroup} alignItems="center">
                <Flex.Item flex={1} className={classes.infoHead}>
                  {t('calendar:timeline.popover.superior')}
                </Flex.Item>
                <Flex.Item className={classes.infoContent} alignSelf="flex-end">
                  {item.additionalInfo.managerName}
                </Flex.Item>
              </Flex.Row>
              {absenceProposal.reason && (
                <Flex.Column
                  className={classes.infoGroup}
                  childrenGap={theme.old.spacing.baseSpacing}
                >
                  <Flex.Item className={classes.infoHead}>
                    {t('calendar:timeline.popover.comment')}{' '}
                    <ContactText contactId={absenceProposal.applicantId} />
                  </Flex.Item>
                  <Flex.Item className={classes.infoContent}>
                    {absenceProposal.reason}
                  </Flex.Item>
                </Flex.Column>
              )}
            </Flex.Column>
            {absenceProposal.absenceState === 'requested' &&
              context !== 'me' &&
              (writeOtherUserAbsenceByGlobalRole ||
                writeOtherUserAbsenceByOfficeRole) && (
                <Flex.Row className={classes.infoGroup} alignItems="center">
                  <Flex.Item flex={1} className={classes.infoHead}>
                    {t('calendar:timeline.popover.actions')}
                  </Flex.Item>
                  <Button onClick={acceptProposal} disabled={submitting}>
                    {t('absences:actions.accept')}
                  </Button>
                  <Button
                    type="default"
                    onClick={declineProposal}
                    disabled={submitting}
                  >
                    {t('absences:actions.decline')}
                  </Button>
                </Flex.Row>
              )}
          </div>
        </>
      }
    >
      <div
        {...getItemProps(item.itemProps)}
        style={style}
        className={classNames(classes.root, stateClass)}
        onClick={(e) => {
          setVisible(true);
        }}
      >
        <div className={classes.title}>
          <Typography.Text className={classes.titleText}>
            {compactDateFormatString(absenceProposal.from) +
              ' - ' +
              compactDateFormatString(absenceProposal.to)}
          </Typography.Text>
        </div>
      </div>
    </Popover>
  );
};

export default TimelineItem;
