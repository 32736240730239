import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';

import Prio from './Prio';
import GlobalContactsDrawer from '../contacts/components/GlobalContactsDrawer';
import GlobalNotificationsDrawer from '../notifications/components/GlobalNotificationsDrawer';
import GlobalZendeskDrawer from '../zendesk/components/GlobalZendeskDrawer';
import PrioSpinner from '../../components/PrioSpinner';
import { TextLoop } from 'react-text-loop-next';
import { useTranslation } from 'react-i18next';
import useDocumentVisible from '../../hooks/useDocumentVisible';
import useDoodle from '../doodles/hooks/useDoodle';
import MainDoodle from '../doodles/components/MainDoodle';
import { makePrioStyles } from '../../theme/utils';
import { useSelector } from 'react-redux';
import {
  getCurrentPrioVersion,
  getIsInitialDataLoading,
  RootReducerState,
} from '../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { mapLastVersions } from '../main/NewPrioVersionModal';
import packageJson from '../../../package.json';
import GlobalTimeAndLeaveManagementDrawer from '../timeAndLeaveManagement/components/GlobalTimeAndLeaveManagementDrawer';

import useBeforeUnloadBrowser from '../../hooks/useBeforeUnloadBrowser';
import * as Sentry from '@sentry/react';
import { KeyboardProvider } from '@prio365/prio365-react-library';
import MainProvider from '../main/MainProvider';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
  },
  infoContainer: {
    position: 'absolute',
    top: 168,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    '& > div': {
      transition: 'none!important',
    },
  },
}));

const blockedFetchingCauseOfVersionDelta = createSelector<
  [(state: RootReducerState) => string],
  boolean
>(
  getCurrentPrioVersion,
  (prioVersion) =>
    prioVersion !== packageJson.version &&
    !!mapLastVersions(prioVersion, packageJson.version)
);

export const Module: React.FC<{}> = () => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const { t } = useTranslation();

  const isInitialDataLoading = useSelector(getIsInitialDataLoading);

  const blockedFetching = useSelector(blockedFetchingCauseOfVersionDelta);

  const doodleType = useDoodle();
  //#endregion

  //#region ------------------------------ Effects
  useBeforeUnloadBrowser();
  useDocumentVisible();
  //#endregion

  if (isInitialDataLoading) {
    return (
      <>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="gigantic" />
        </div>
        {!blockedFetching && (
          <div className={classes.infoContainer}>
            <TextLoop mask className={classes.fullWidth}>
              <div>{t('common:initialDataLoadInfo.fetchingUser')}</div>
              <div>{t('common:initialDataLoadInfo.fetchingProjects')}</div>
              <div>{t('common:initialDataLoadInfo.fetchingContacts')}</div>
              <div>{t('common:initialDataLoadInfo.fetchingCompanies')}</div>
              <div>{t('common:initialDataLoadInfo.fetchingNotifications')}</div>
            </TextLoop>
          </div>
        )}
      </>
    );
  }

  if (doodleType !== 'none') {
    return (
      <KeyboardProvider>
        <MainProvider>
          <MainDoodle type={doodleType} />
        </MainProvider>
      </KeyboardProvider>
    );
  }

  return (
    <KeyboardProvider>
      <MainProvider>
        <Routes>
          <Route path="*" element={<Navigate to="prio" />} />
          <Route path="prio/*" element={<Prio />} />
        </Routes>
        <GlobalContactsDrawer className="prio-deprecated-design" />
        <GlobalTimeAndLeaveManagementDrawer className="prio-deprecated-design" />
        <GlobalNotificationsDrawer />
        <GlobalZendeskDrawer className="prio-deprecated-design" />
      </MainProvider>
    </KeyboardProvider>
  );
};

export default Module;
