import React from 'react';
import { Drawer, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import { Employee } from '../../../models/Employee';
import { EditPersonnelFileDataForm } from './EditPersonnelDataForm';
import { EmployeeId } from '../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

interface EditPersonnelDataDrawerProps {
  onClose?: VoidFunction;
  open?: boolean;
  employeeId?: EmployeeId;
  employee?: Employee;
  patchEmployee: (patchData: Employee) => Promise<void>;
}
const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  importUser: {
    height: '100%',
  },
  importAadUser: {
    flex: 1,
  },
  title: {
    paddingBottom: theme.old.spacing.unit(2),
    marginTop: -theme.old.spacing.unit(1),
  },
  scrollabe: {
    flex: 1,
    overflow: 'auto',
  },
  drawer: {
    zIndex: 999,
  },
}));

export const EditPersonnelDataDrawer: React.FC<EditPersonnelDataDrawerProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { onClose, open, employeeId, employee, patchEmployee } = props;

  const closeDrawer = () => {
    if (onClose) {
      onClose();
    }
  };

  const renderContent = () => {
    return (
      <Flex.Column className={classes.importUser}>
        <div className={classes.title}>
          <Typography.Title level={2}>
            {t('profile:titles.editPersonneldata')}
          </Typography.Title>
        </div>
        <div className={classes.scrollabe}>
          <EditPersonnelFileDataForm
            employeeId={employeeId}
            employee={employee}
            isSaving={false}
            onClose={onClose}
            datePickerLocale={undefined}
            patchEmployee={patchEmployee}
          />
        </div>
      </Flex.Column>
    );
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={closeDrawer}
      visible={open}
      width={theme.old.components.drawerWidth}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.drawer}
      destroyOnClose
    >
      {renderContent()}
    </Drawer>
  );
};

export default EditPersonnelDataDrawer;
