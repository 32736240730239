import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  AbsenceOverview,
  AbsenceProposal,
  AbsenceProposalRequest,
  OfficeHoliday,
  UpdateAbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import { DispatchAction, ReduxAction } from '../../../models/Redux';
import {
  AbsenceProposalId,
  DateTimeString,
  OfficeId,
} from '../../../models/Types';

export const DEBOUNCED_FETCH_OFFICE_HOLIDAYS_ME =
  PRIO + 'DEBOUNCED_FETCH_OFFICE_HOLIDAYS_ME';

export const debouncedFetchOfficeHolidaysMe: (
  officeId: OfficeId,
  from?: DateTimeString,
  to?: DateTimeString
) => ReduxAction<{
  officeId: OfficeId;
  from?: DateTimeString;
  to?: DateTimeString;
}> = (officeId, from, to) => ({
  type: DEBOUNCED_FETCH_OFFICE_HOLIDAYS_ME,
  meta: {
    officeId,
    from,
    to,
  },
});

export const FETCH_OFFICE_HOLIDAY_REQUEST =
  PRIO + 'FETCH_OFFICE_HOLIDAY_REQUEST';
export const FETCH_OFFICE_HOLIDAY_COMMIT = PRIO + 'FETCH_OFFICE_HOLIDAY_COMMIT';
export const FETCH_OFFICE_HOLIDAY_ROLLBACK =
  PRIO + 'FETCH_OFFICE_HOLIDAY_ROLLBACK';

export const fetchOfficeHolidays: (
  officeId: OfficeId
) => DispatchAction<unknown, OfficeHoliday[]> = (officeId) => ({
  type: FETCH_OFFICE_HOLIDAY_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/office/${officeId}/OfficeHoliday`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_OFFICE_HOLIDAY_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_OFFICE_HOLIDAY_ROLLBACK,
        snackbarErrorMessage: {
          label: 'module:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

export const DEBOUNCE_ABSENCES_ME = PRIO + 'DEBOUNCE_ABSENCES_ME';

export const debounceAbsencesMe: (
  from?: DateTimeString,
  to?: DateTimeString
) => ReduxAction<{
  from?: DateTimeString;
  to?: DateTimeString;
}> = (from, to) => ({
  type: DEBOUNCE_ABSENCES_ME,
  meta: {
    from,
    to,
  },
});

export const SYNC_ABSENCES_ME = PRIO + 'SYNC_ABSENCES_ME';

export const syncAbsencesMe: (
  from?: DateTimeString,
  to?: DateTimeString
) => ReduxAction<{
  from?: DateTimeString;
  to?: DateTimeString;
}> = (from, to) => ({
  type: SYNC_ABSENCES_ME,
  meta: {
    from,
    to,
  },
});

export const FETCH_ABSENCES_ME_REQUEST = PRIO + 'FETCH_ABSENCES_ME_REQUEST';
export const FETCH_ABSENCES_ME_COMMIT = PRIO + 'FETCH_ABSENCES_ME_COMMIT';
export const FETCH_ABSENCES_ME_ROLLBACK = PRIO + 'FETCH_ABSENCES_ME_ROLLBACK';

export const fetchAbsencesMe: (
  from?: DateTimeString,
  to?: DateTimeString
) => DispatchAction<
  { from: DateTimeString; to: DateTimeString },
  AbsenceOverview
> = (from, to) => ({
  type: FETCH_ABSENCES_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/absence/absenceProposal/me${
          from
            ? `?${new URLSearchParams({
                from,
                to,
              }).toString()}`
            : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_ABSENCES_ME_COMMIT,
        meta: {
          from,
          to,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_ABSENCES_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'absences:errorMessages.fetchOverviewError',
          timeout: 6,
        },
        meta: {
          from,
          to,
        },
      },
    },
    from,
    to,
  },
});

export const CREATE_ABSENCE_PROPOSAL_REQUEST =
  PRIO + 'CREATE_ABSENCE_PROPOSAL_REQUEST';
export const CREATE_ABSENCE_PROPOSAL_COMMIT =
  PRIO + 'CREATE_ABSENCE_PROPOSAL_COMMIT';
export const CREATE_ABSENCE_PROPOSAL_ROLLBACK =
  PRIO + 'CREATE_ABSENCE_PROPOSAL_ROLLBACK';

export const createAbsenceProposal: (
  absenceProposalRequest: AbsenceProposalRequest
) => DispatchAction<
  { absenceProposalRequest: AbsenceProposalRequest },
  AbsenceProposal
> = (absenceProposalRequest) => ({
  type: CREATE_ABSENCE_PROPOSAL_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/absence/absenceProposal/me/proposal`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: absenceProposalRequest,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_ABSENCE_PROPOSAL_COMMIT,
        meta: { absenceProposalRequest },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_ABSENCE_PROPOSAL_ROLLBACK,
        meta: { absenceProposalRequest },
        snackbarErrorMessage: {
          label: 'absences:errorMessages.createAbsenceProposalError',
          timeout: 6,
        },
      },
    },
    absenceProposalRequest,
  },
});

export const DELETE_ABSENCE_PROPOSAL_REQUEST =
  PRIO + 'DELETE_ABSENCE_PROPOSAL_REQUEST';
export const DELETE_ABSENCE_PROPOSAL_COMMIT =
  PRIO + 'DELETE_ABSENCE_PROPOSAL_COMMIT';
export const DELETE_ABSENCE_PROPOSAL_ROLLBACK =
  PRIO + 'DELETE_ABSENCE_PROPOSAL_ROLLBACK';

export const deleteAbsenceProposalMe: (
  absenceProposalId: AbsenceProposalId,
  rollbackAbsenceProposal: AbsenceProposal
) => DispatchAction<
  {
    absenceProposalId: AbsenceProposalId;
    rollbackAbsenceProposal: AbsenceProposal;
  },
  unknown
> = (absenceProposalId, rollbackAbsenceProposal) => ({
  type: DELETE_ABSENCE_PROPOSAL_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/absence/absenceProposal/me/${absenceProposalId}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_ABSENCE_PROPOSAL_COMMIT,
        meta: { absenceProposalId, rollbackAbsenceProposal },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_ABSENCE_PROPOSAL_ROLLBACK,
        meta: { absenceProposalId, rollbackAbsenceProposal },
        snackbarErrorMessage: {
          label: 'absences:errorMessages.deleteAbsenceProposalError',
          timeout: 6,
        },
      },
    },
    absenceProposalId,
    rollbackAbsenceProposal,
  },
});

export const REVOKE_ABSENCE_PROPOSAL_ME_REQUEST =
  PRIO + 'REVOKE_ABSENCE_PROPOSAL_ME_REQUEST';
export const REVOKE_ABSENCE_PROPOSAL_ME_COMMIT =
  PRIO + 'REVOKE_ABSENCE_PROPOSAL_ME_COMMIT';
export const REVOKE_ABSENCE_PROPOSAL_ME_ROLLBACK =
  PRIO + 'REVOKE_ABSENCE_PROPOSAL_ME_ROLLBACK';

export const revokeAbsenceProposal: (
  absenceProposalId: AbsenceProposalId,
  rollbackAbsenceProposal: AbsenceProposal
) => DispatchAction<
  {
    absenceProposalId: AbsenceProposalId;
    rollbackAbsenceProposal: AbsenceProposal;
  },
  unknown
> = (absenceProposalId, rollbackAbsenceProposal) => ({
  type: REVOKE_ABSENCE_PROPOSAL_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/absence/absenceProposal/${absenceProposalId}/revoke/me`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: REVOKE_ABSENCE_PROPOSAL_ME_COMMIT,
        meta: { absenceProposalId, rollbackAbsenceProposal },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: REVOKE_ABSENCE_PROPOSAL_ME_ROLLBACK,
        meta: { absenceProposalId, rollbackAbsenceProposal },
        snackbarErrorMessage: {
          label: 'absences:errorMessages.revokeAbsenceProposalError',
          timeout: 6,
        },
      },
    },
    absenceProposalId,
    rollbackAbsenceProposal,
  },
});

export const CHANGE_ABSENCE_PROPOSAL_REQUEST =
  PRIO + 'CHANGE_ABSENCE_PROPOSAL_REQUEST';
export const CHANGE_ABSENCE_PROPOSAL_COMMIT =
  PRIO + 'CHANGE_ABSENCE_PROPOSAL_COMMIT';
export const CHANGE_ABSENCE_PROPOSAL_ROLLBACK =
  PRIO + 'CHANGE_ABSENCE_PROPOSAL_ROLLBACK';

export const changeAbsenceProposal: (
  updateAbsenceProposalRequest: UpdateAbsenceProposalRequest,
  absenceProposalId: AbsenceProposalId,
  rollbackAbsenceProposal: AbsenceProposal,
  isMe: boolean
) => DispatchAction<
  {
    updateAbsenceProposalRequest: UpdateAbsenceProposalRequest;
    absenceProposalId: AbsenceProposalId;
    rollbackAbsenceProposal: AbsenceProposal;
    isMe: boolean;
  },
  AbsenceProposal
> = (
  updateAbsenceProposalRequest,
  absenceProposalId,
  rollbackAbsenceProposal,
  isMe
) => ({
  type: CHANGE_ABSENCE_PROPOSAL_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/absence/absenceProposal/${absenceProposalId}/change`,
        method: 'PUT',
        json: updateAbsenceProposalRequest,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CHANGE_ABSENCE_PROPOSAL_COMMIT,
        meta: {
          updateAbsenceProposalRequest,
          absenceProposalId,
          rollbackAbsenceProposal,
          isMe,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CHANGE_ABSENCE_PROPOSAL_ROLLBACK,
        meta: {
          updateAbsenceProposalRequest,
          absenceProposalId,
          rollbackAbsenceProposal,
          isMe,
        },
        snackbarErrorMessage: {
          label: 'absences:errorMessages.updateAbsenceProposalError',
          timeout: 6,
        },
      },
    },
    updateAbsenceProposalRequest,
    absenceProposalId,
    rollbackAbsenceProposal,
    isMe,
  },
});

export const UPDATE_LOCAL_ABSENCE_PROPOSAL =
  PRIO + 'UPDATE_LOCAL_ABSENCE_PROPOSAL';

export const updateLocalAbsenceProposal = (
  absenceProposal: AbsenceProposal
) => ({
  type: UPDATE_LOCAL_ABSENCE_PROPOSAL,
  payload: absenceProposal,
});
