import { useSelector } from 'react-redux';
import {
  getAllProjectIds,
  getCompaniesByIdState,
  getMyProjectIds,
  getProjectByIdState,
  getProjectsIsFetching,
} from '../../../apps/main/rootReducer';
import { ProjectId, ProjectTabViews } from '../../../models/Types';
import { ProjectByIdState } from '../reducers/projects';
import { useMemo } from 'react';
import { sortProjects } from '../utils';

interface ContactSearchProps {
  searchTerm: string;
  type: ProjectTabViews;
  archivedProjectsHidden?: boolean;
}

export const useProjectSearch: (
  props: ContactSearchProps
) => [ProjectId[], ProjectByIdState, boolean] = (props) => {
  const { type, searchTerm, archivedProjectsHidden } = props;
  const isFetching = useSelector(getProjectsIsFetching);

  const companiesById = useSelector(getCompaniesByIdState);

  const byId = useSelector(getProjectByIdState);
  const ids = useSelector(type !== 'all' ? getMyProjectIds : getAllProjectIds);

  const sortedIds = useMemo(() => {
    const idsDependingOnArchived = archivedProjectsHidden
      ? ids.filter((id) => !byId[id].isArchived)
      : ids;

    const idsDependingOnArchivedAndFavorite =
      type === 'favorites'
        ? idsDependingOnArchived.filter((id) => byId[id].favorite)
        : idsDependingOnArchived;

    const filteredProject = idsDependingOnArchivedAndFavorite.map(
      (id) => byId[id]
    );

    return sortProjects(filteredProject).map((project) => project.projectId);
  }, [byId, ids, type, archivedProjectsHidden]);

  return useMemo(() => {
    if (!searchTerm || searchTerm === '') {
      return [sortedIds, byId, isFetching];
    }
    const filteredProjectIds = sortedIds.filter((id) => {
      const project = byId[id];
      const subsidiary = companiesById[project.subsidiaryId];
      const company = companiesById[project.companyId];

      const projectInformation = `${project.number} ${project.shortName} ${project.name} ${project.eMailPrefix} ${project.eMailSuffix}`;
      const subsidiaryInformation = `${subsidiary?.shortName ?? ''} ${
        subsidiary?.fullName ?? ''
      } ${subsidiary?.fullName2 ?? ''} `;
      const companyInformation = `${company?.shortName ?? ''} ${
        company?.fullName ?? ''
      } ${company?.fullName2 ?? ''} `;

      const s = projectInformation
        .concat(subsidiaryInformation)
        .concat(companyInformation)
        ?.toLocaleLowerCase()
        ?.replace(/[^a-zA-Z0-9_-]+/g, ' ')
        ?.replace(/ /g, '');

      const searchTermArray = searchTerm
        ?.toLocaleLowerCase()
        ?.replace(/[^a-zA-Z0-9_-]+/g, ' ')
        ?.split(' ');

      let numberOfMatches = 0;
      searchTermArray.forEach((searchTerm) => {
        if (s.includes(searchTerm)) {
          numberOfMatches++;
        }
      });

      if (numberOfMatches === searchTermArray.length) {
        return true;
      } else {
        return false;
      }
    });

    return [filteredProjectIds, byId, isFetching];
  }, [sortedIds, byId, companiesById, isFetching, searchTerm]);
};

export default useProjectSearch;
