import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import React, { useEffect, useState } from 'react';
import { makePrioStyles } from '../theme/utils';
import classNames from 'classnames';

const smallSize = 14;
const middleSize = 20;
const largeSize = 32;
const giganticSize = 64;

declare type PrioSpinnerType = 'small' | 'default' | 'large' | 'gigantic';
const sizes: Array<PrioSpinnerType> = ['small', 'default', 'large', 'gigantic'];

const animation = (
  position: 'topRight' | 'bottomLeft',
  borderRadius: string,
  distance: number
) => {
  return {
    '0%': {
      borderRadius,
      transform: 'rotate(0deg)',
    },
    '50%': {
      borderRadius: giganticSize,
      transform: `translate(${
        position === 'topRight' ? `${distance}px` : `-${distance}px`
      }, ${
        position === 'topRight' ? `-${distance}px` : `${distance}px`
      }) rotate(90deg)`,
    },
    '100%': {
      borderRadius,
      transform: 'rotate(0deg)',
    },
  };
};

const itemProps = (size: number, position: 'topRight' | 'bottomLeft') => {
  if (position === 'topRight') {
    return {
      position: 'absolute',
      right: 0,
      top: 0,
      width: size / 2,
      height: size / 2,
      borderRadius: `${size / 4}px ${size / 4}px ${size / 4}px 0`,
      background:
        'linear-gradient(45deg, rgba(232,70,47,1) 0%, rgba(234,91,37,1) 50%, rgba(243,147,37,1) 100%)',
    };
  }
  return {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: size / 2,
    height: size / 2,
    borderRadius: `${size / 4}px 0 ${size / 4}px ${size / 4}px`,
    background:
      'linear-gradient(45deg, rgba(209,66,125,1) 10%, rgba(230,51,56,1) 50%, rgba(232,70,47,1) 100%)',
  };
};
const mapTypeToNumber = (type: PrioSpinnerType) => {
  switch (type) {
    case 'small': {
      return smallSize;
    }
    case 'default': {
      return middleSize;
    }
    case 'large': {
      return largeSize;
    }
    case 'gigantic': {
      return giganticSize;
    }
    default: {
      return middleSize;
    }
  }
};

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: (props: any) =>
      props.alignSelf ? '100%' : props.spinSize + props.spinSize / 2,
    width: (props: any) =>
      props.alignSelf ? '100%' : props.spinSize + props.spinSize / 2,
  },
  spinnerContainer: {
    width: (props: any) => props.spinSize + props.spinSize / 2,
    height: (props: any) => props.spinSize + props.spinSize / 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerText: { marginTop: 4 },
  spinner: {
    width: (props: any) => props.spinSize,
    height: (props: any) => props.spinSize,
    animation: `$container_spin 2s infinite`,
  },
  spinner_stand: {
    width: (props: any) => props.spinSize,
    height: (props: any) => props.spinSize,
    position: 'relative',
  },
  '@keyframes container_spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(720deg)',
    },
  },
  ...sizes.reduce((map, item) => {
    const itemSize = mapTypeToNumber(item);
    map[`topRight_${item}`] = {
      ...itemProps(itemSize, 'topRight'),
      animation: `$top_right_dot_animation_${item} 2s infinite`,
    };
    map[`topRight_${item}_stand`] = {
      ...itemProps(itemSize, 'topRight'),
    };
    map[`@keyframes top_right_dot_animation_${item}`] = animation(
      'topRight',
      `${itemSize / 4}px ${itemSize / 4}px ${itemSize / 4}px 0`,
      itemSize / 8
    );
    map[`bottomLeft_${item}`] = {
      ...itemProps(itemSize, 'bottomLeft'),
      animation: `$bottom_left_dot_animation_${item} 2s infinite`,
    };
    map[`bottomLeft_${item}_stand`] = {
      ...itemProps(itemSize, 'bottomLeft'),
    };
    map[`@keyframes bottom_left_dot_animation_${item}`] = animation(
      'bottomLeft',
      `${itemSize / 4}px 0 ${itemSize / 4}px ${itemSize / 4}px`,
      itemSize / 8
    );
    return map;
  }, {}),
}));

const mapTypeToClass: (
  type: PrioSpinnerType,
  classes: Classes,
  stand: boolean
) => { topRight: string; bottomLeft: string } = (
  type: PrioSpinnerType,
  classes: Classes,
  stand: boolean
) => ({
  topRight: classes[`topRight_${type ?? 'default'}${stand ? '_stand' : ''}`],
  bottomLeft:
    classes[`bottomLeft_${type ?? 'default'}${stand ? '_stand' : ''}`],
});

interface PrioSpinnerProps {
  className?: string;
  size?: PrioSpinnerType;
  tip?: string;
  alignSelf?: boolean;
  stand?: boolean;
  delay?: number;
}

export const PrioSpinner: React.FC<PrioSpinnerProps> = (props) => {
  const { className, size, tip, alignSelf, stand, delay } = props;
  const classes = useStyles({
    spinSize: mapTypeToNumber(size),
    alignSelf: alignSelf ?? false,
  });

  const [isVisible, setIsVisible] = useState<boolean>(!delay);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, delay);
  }, [delay]);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.spinnerContainer}>
        <div className={stand ? classes.spinner_stand : classes.spinner}>
          {isVisible && (
            <>
              <div className={mapTypeToClass(size, classes, stand).topRight} />
              <div
                className={mapTypeToClass(size, classes, stand).bottomLeft}
              />
            </>
          )}
        </div>
      </div>
      <div className={classes.spinnerText}>{tip}</div>
    </div>
  );
};

export default PrioSpinner;
