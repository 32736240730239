import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  AutoComplete,
  Divider,
  Typography,
  DatePicker,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';

import { useSelector, useDispatch } from 'react-redux';
import {
  getTitleSuggestions,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { fetchTitleSuggestions } from '../../settings/actions/titleSuggestions';
import { TitleSuggestion } from '../../../models/TitleSuggestion';
import { fallBackTitleOptions } from '../../contacts/components/ContactForm';
import { rowGutter, colon } from '../../../util/forms';
import { defaultPhonePattern } from '../../../util';
import {
  AddEmployee,
  allFormOfEmploymentTypes,
  FormOfEmployment,
} from '../../../models/Employee';
import OfficePicker from '../../companies/components/OfficePicker';
import ContactPicker from '../../contacts/components/ContactPicker';
import { Configuration } from '../../../models/Configuration';
import { ConfigurationKeys, EMailSuffix } from '../../../models/Types';
import NumberInput from '../../../components/NumberInput';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  apiFetchConfigurations,
  apiFetchEmailSuffixes,
} from '../../settings/api';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { apiValidateEmail } from '../../users/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
  },
  column: {
    height: '100%',
  },
  tabs: {},
  tabsPane: {},
  cancelButton: {
    marginTop: theme.old.spacing.unit(2),
  },
  fullWidth: {
    width: '100%',
  },
  scrollable: {
    height: 'calc(100% - 0px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  eMailSuffixSelect: {
    '&:before': {
      content: '"@"',
      display: 'inline-block',
      position: 'absolute',
      left: -22,
      top: 3,
      color: theme.old.typography.colors.muted,
    },
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
    marginTop: -theme.old.spacing.unit(1),
  },
  headingFirst: {
    marginBottom: theme.old.spacing.unit(2),
  },
  divider: {
    marginTop: '0px',
  },
  checkboxWeekdays: {
    marginBottom: theme.old.spacing.unit(2),
  },
  checkboxWeekend: {
    marginBottom: theme.old.spacing.unit(1),
  },
}));

interface EmployeeFormProps {
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinishCreate: (value: AddEmployee) => Promise<boolean>;
  onCancel?: () => void;
}

const emptyEmployee: AddEmployee = {
  formOfEmployment: 'fulltime',
  hoursPerWeek: 40,
  holidayEntitlement: 24,
  workingDayMonday: true,
  workingDayTuesday: true,
  workingDayWednesday: true,
  workingDayThursday: true,
  workingDayFriday: true,
  workingDaySaturday: false,
  workingDaySunday: false,
  templateContactId: '',
};

export const EmployeeForm: React.FC<EmployeeFormProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    cancelLabel,
    onFinishCreate,
    onCancel,
    disableActionButton,
    disableForm: disabled,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState<boolean>(false);

  const [emailSuffixes, setEmailSuffixes] = useState<EMailSuffix[]>([]);
  const [loadSuffixes, setLoadSuffixes] = useState<boolean>(false);
  const [emailValidating, setEmailValidating] = useState<boolean>(false);
  const titleSuggestions: TitleSuggestion[] = useSelector<
    RootReducerState,
    TitleSuggestion[]
  >(getTitleSuggestions);

  const disableForm = disabled || saving;
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  const userMe = useSelector(getUserMe);

  useEffect(() => {
    dispatch(fetchTitleSuggestions());
  }, [dispatch]);

  useEffect(() => {
    if (userMe) {
      form.setFieldsValue({ managerId: userMe.id });
    }
  }, [form, userMe]);

  useEffect(() => {
    setLoadSuffixes(true);
    const loadEmailSuffixes = async () => {
      const { data } = await apiFetchEmailSuffixes();
      if (data) {
        setEmailSuffixes(data);
        if (data?.length > 0) {
          form.setFieldsValue({
            eMailSuffix: data[0],
          });
        }
      }
      setLoadSuffixes(false);
    };

    loadEmailSuffixes();
  }, [form]);

  const cancel = () => {
    form.resetFields();
    onCancel();
  };

  const validateEmail = async (validate) => {
    setEmailValidating(true);

    var eMailSuffix = form.getFieldValue('eMailSuffix');
    var mail = validate + '@' + eMailSuffix;

    const { data } = await apiValidateEmail(mail);
    setEmailValidating(false);
    if (data) {
      if (data?.validEmail) {
        return Promise.resolve('validate Email');
      } else {
        return Promise.reject(t('users:form.validation.invalidEmail'));
      }
    } else {
      return Promise.reject(t('users:form.validation.invalidEmail'));
    }
  };

  const handleFinish: (value: AddEmployee) => void = async (
    value: AddEmployee
  ) => {
    setSaving(true);
    const newEMail = value.eMailPrefix + '@' + value.eMailSuffix;
    const dateOfEmployment = value.dateOfEmployment
      ? moment(value.dateOfEmployment)
      : undefined;
    const success = await onFinishCreate({
      ...value,
      eMail: newEMail,
      dateOfEmployment:
        dateOfEmployment?.toISOString(true).split('T')[0] ?? undefined,
    });
    if (success) {
      form.resetFields();
    }
    setSaving(false);
  };

  const titleOptions = titleSuggestions.map((suggestion) => ({
    value: suggestion.value,
  }));

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  useEffect(() => {
    if (!disableForm) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) setConfigurations(data);
      };
      loadConfigs();
    }
  }, [disableForm]);

  return (
    <Form<AddEmployee>
      className={classes.root}
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      initialValues={emptyEmployee}
      scrollToFirstError
    >
      <Flex.Column className={classes.column}>
        <div className={classes.scrollable}>
          <Flex.Row>
            <Flex.Item flex={1}>
              <div className={classes.headingFirst}>
                <Typography.Text type="secondary">
                  {t('hr:addEmployeeForm.pivot.general')}
                </Typography.Text>
              </div>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={12}>
                  <Form.Item
                    name={'gender'}
                    colon={colon}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.gender'
                        ),
                      },
                    ]}
                  >
                    <Select
                      disabled={disableForm}
                      placeholder={t('hr:addEmployeeForm.labels.gender')}
                    >
                      <Select.Option value="male">
                        {t('common:gender.male')}
                      </Select.Option>
                      <Select.Option value="female">
                        {t('common:gender.female')}
                      </Select.Option>
                      <Select.Option value="diverse">
                        {t('common:gender.diverse')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={'title'} colon={colon}>
                    <AutoComplete
                      options={titleOptions || fallBackTitleOptions}
                      placeholder={t('hr:addEmployeeForm.labels.title')}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={12}>
                  <Form.Item
                    name={'firstName'}
                    colon={colon}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.firstName'
                        ),
                      },
                    ]}
                  >
                    <Input
                      disabled={disableForm}
                      placeholder={t('hr:addEmployeeForm.labels.firstName')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'lastName'}
                    colon={colon}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.lastName'
                        ),
                      },
                    ]}
                  >
                    <Input
                      disabled={disableForm}
                      placeholder={t('hr:addEmployeeForm.labels.lastName')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={12}>
                  <Form.Item
                    name={'officeId'}
                    label={t('hr:addEmployeeForm.labels.officeId')}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.officeId'
                        ),
                      },
                    ]}
                  >
                    <OfficePicker disabled={disableForm} onlyInternal />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'managerId'}
                    label={t('hr:addEmployeeForm.labels.managerId')}
                  >
                    <ContactPicker
                      contactType="InternalContact"
                      disabled={disableForm}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Flex.Item>
          </Flex.Row>
          <Divider className={classes.divider} />
          <div className={classes.heading}>
            <Typography.Text type="secondary">
              {t('hr:addEmployeeForm.pivot.contactData')}
            </Typography.Text>
          </div>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name={'phone'}
                label={t('hr:addEmployeeForm.labels.phone')}
                colon={colon}
                rules={[
                  {
                    message: t(
                      'hr:addEmployeeForm.validation.invalidPhoneNumber'
                    ),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'cellphone'}
                label={t('hr:addEmployeeForm.labels.cellphone')}
                colon={colon}
                rules={[
                  {
                    message: t(
                      'hr:addEmployeeForm.validation.invalidPhoneNumber'
                    ),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={theme.old.spacing.unit(rowGutter)}
            style={{ marginTop: theme.old.spacing.unit(3) }}
          >
            <Col span={12}>
              <Form.Item
                name="eMailPrefix"
                label={t('hr:addEmployeeForm.labels.eMailPrefix')}
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.missingEmailPrefix'),
                  },
                  () => ({
                    async validator(rule, value) {
                      await validateEmail(value);
                    },
                  }),
                ]}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="eMailSuffix"
                label={t('hr:addEmployeeForm.labels.eMailSuffix')}
                colon={colon}
                validateTrigger="onChange"
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:addEmployeeForm.validation.missing.emailSuffix'
                    ),
                  },
                ]}
              >
                <Select
                  className={classes.eMailSuffixSelect}
                  disabled={disableForm}
                >
                  {emailSuffixes.map((emailSuffix) => (
                    <Select.Option value={emailSuffix} key={emailSuffix}>
                      {emailSuffix}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider className={classes.divider} />
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name={'templateContactId'}
                label={t('hr:addEmployeeForm.labels.templateContactId')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:addEmployeeForm.validation.missing.templateContactId'
                    ),
                  },
                ]}
              >
                <ContactPicker
                  contactType="InternalContact"
                  disabled={disableForm}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={classes.heading}>
            <Typography.Text type="secondary">
              {t('hr:addEmployeeForm.pivot.contractData')}
            </Typography.Text>
          </div>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name="formOfEmployment"
                label={t('hr:addEmployeeForm.labels.formOfEmployment')}
                colon={colon}
              >
                <Select<FormOfEmployment> disabled={disableForm}>
                  {allFormOfEmploymentTypes.map((type) => (
                    <Select.Option value={type} key={type}>
                      {t(`hr:formOfEmploymentTypes.${type}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="hoursPerWeek"
                label={t('hr:addEmployeeForm.labels.hoursPerWeek')}
                colon={colon}
              >
                <NumberInput disabled={disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name="dateOfEmployment"
                label={t('hr:addEmployeeForm.labels.dateOfEmployment')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:addEmployeeForm.validation.missing.dateOfEmployment'
                    ),
                  },
                ]}
              >
                <DatePicker
                  disabled={disableForm}
                  locale={datePickerLocale}
                  format="DD.MM.YYYY"
                  suffixIcon={
                    <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
                  }
                  className={classes.fullWidth}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="holidayEntitlement"
                label={t('hr:addEmployeeForm.labels.holidayEntitlement')}
                colon={colon}
              >
                <NumberInput disabled={disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Flex.Column flex={1}>
            <div
              className={classes.heading}
              style={{ marginTop: theme.old.spacing.unit(2) }}
            >
              <Typography.Text type="secondary">
                {t('hr:addEmployeeForm.labels.workingDays')}
              </Typography.Text>
            </div>
            <Flex.Row flex={1} gutter={rowGutter}>
              <Col span={4}>
                <Form.Item
                  name="workingDayMonday"
                  label={t('hr:addEmployeeForm.labels.workingDayMonday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="workingDayTuesday"
                  label={t('hr:addEmployeeForm.labels.workingDayTuesday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="workingDayWednesday"
                  label={t('hr:addEmployeeForm.labels.workingDayWednesday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="workingDayThursday"
                  label={t('hr:addEmployeeForm.labels.workingDayThursday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="workingDayFriday"
                  label={t('hr:addEmployeeForm.labels.workingDayFriday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
            </Flex.Row>
            <Flex.Row>
              <Col span={4}>
                <Form.Item
                  name="workingDaySaturday"
                  label={t('hr:addEmployeeForm.labels.workingDaySaturday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekend}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="workingDaySunday"
                  label={t('hr:addEmployeeForm.labels.workingDaySunday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekend}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Col>
            </Flex.Row>
          </Flex.Column>
        </div>
        <Flex.Row alignItems="end" className={classes.actionButtonsRow}>
          <Col span={24}>
            <Form.Item className={classes.submitButtonFormItem}>
              {onCancel && (
                <Button
                  type="default"
                  onClick={cancel}
                  className={classes.cancelButton}
                  style={{ marginRight: theme.old.spacing.unit(1) }}
                >
                  {cancelLabel}
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  disableActionButton || emailValidating || loadSuffixes
                }
              >
                {t('common:save')}
              </Button>
            </Form.Item>
          </Col>
        </Flex.Row>
      </Flex.Column>
    </Form>
  );
};

export default EmployeeForm;
