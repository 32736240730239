import { useTranslation } from 'react-i18next';
import { notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import {
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { User } from '../../../models/User';
import {
  apiCreateProjectFolder,
  apiCreateProjectSharepoint,
  apiFixProject,
  apiFixProjectSubscription,
  apiPrecacheResync,
  apiStartProjectSubscription,
} from '../api';
import { useParams } from 'react-router-dom';
import { Project } from '../../../models/Project';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
  },

  label: {
    color: 'rgba(0, 0, 0, 0.45)',
  },
}));

interface AdministrationProps {}

export const AdministrationPage: React.FC<AdministrationProps> = (props) => {
  //#region ------------------------------ Defaults
  //const {} = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { projectId } = useParams();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const userMe = useSelector<RootReducerState, User>(getUserMe);

  const handleOkFixProjectSubscription = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      const result = await apiFixProjectSubscription(project.projectId);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t(
            'projects:successMessages.resetFolderAccessRightSuccessMessage'
          ),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.resetFolderAccessRightError'),
        });
      }
    } else {
    }
  };

  const handleOkCreateSharepointModal = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      const result = await apiCreateProjectSharepoint(project.projectId);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t(
            'projects:successMessages.resetFolderAccessRightSuccessMessage'
          ),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.resetFolderAccessRightError'),
        });
      }
    } else {
    }
  };

  const handleOkStartProjectSubscription = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      const result = await apiStartProjectSubscription(project.projectId);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t(
            'projects:successMessages.resetFolderAccessRightSuccessMessage'
          ),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.resetFolderAccessRightError'),
        });
      }
    } else {
    }
  };

  const handleOkSecurityGroups = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      const result = await apiFixProject(project.projectId);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t(
            'projects:successMessages.resetFolderAccessRightSuccessMessage'
          ),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.resetFolderAccessRightError'),
        });
      }
    } else {
    }
  };

  const handleOkCreateFolder = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      if (project.projectFileSystemStructureId) {
        const result = await apiCreateProjectFolder(
          project.projectId,
          project.projectFileSystemStructureId
        );

        if (result.status >= 200 && result.status < 300) {
          notification.open({
            message: t('common:success'),
            description: t(
              'projects:successMessages.createNewFolderSuccessMessage'
            ),
          });
        } else {
          notification.open({
            message: t('common:error'),
            description: t('projects:errorMessages.createNewFolderError'),
          });
        }
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.createNewFolderError'),
        });
      }
    }
  };

  const handleOkEmailCacheReset = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      const result = await apiPrecacheResync(project.projectId);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t('projects:successMessages.resetEmailCacheSuccess'),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.resetEmailCacheError'),
        });
      }
    } else {
    }
  };

  const showModal = (
    type:
      | 'resetEmailCacheModal'
      | 'resetFolderModal'
      | 'securityGroupModal'
      | 'startProjectSubscriptionModal'
      | 'fixProjectSubscriptionModal'
      | 'createSharepointModal'
  ) => {
    switch (type) {
      case 'resetEmailCacheModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkEmailCacheReset();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;
      case 'resetFolderModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkCreateFolder();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;
      case 'securityGroupModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkSecurityGroups();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;
      case 'startProjectSubscriptionModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkStartProjectSubscription();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;
      case 'fixProjectSubscriptionModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkFixProjectSubscription();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;
      case 'createSharepointModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkCreateSharepointModal();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;

      default:
        break;
    }
  };

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <h2>{t('projects:adminSetting.title')}</h2>
      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <Flex.Row justifyContent="space-between">
          <Typography.Text className={classes.label}>
            {t('projects:adminSetting.resetFolderAccessRight')}
          </Typography.Text>
          <Button onClick={() => showModal('securityGroupModal')}>
            {t('projects:adminSetting.buttons.securityGroupButton')}
          </Button>
        </Flex.Row>
        <Flex.Row justifyContent="space-between">
          <Typography.Text className={classes.label}>
            {t('projects:adminSetting.createNewFolder')}
          </Typography.Text>
          <Button onClick={() => showModal('resetFolderModal')}>
            {t('projects:adminSetting.buttons.resetFolderButton')}
          </Button>
        </Flex.Row>
        <Flex.Row justifyContent="space-between">
          <Typography.Text className={classes.label}>
            {t('projects:adminSetting.emailCacheReset')}
          </Typography.Text>
          <Button onClick={() => showModal('resetEmailCacheModal')}>
            {t('projects:adminSetting.buttons.resetEmailCacheButton')}
          </Button>
        </Flex.Row>
        <Flex.Row justifyContent="space-between">
          <Typography.Text className={classes.label}>
            {t('projects:adminSetting.startProjectSubscription')}
          </Typography.Text>
          <Button onClick={() => showModal('startProjectSubscriptionModal')}>
            {t('projects:adminSetting.buttons.startProjectSubscriptionButton')}
          </Button>
        </Flex.Row>
        <Flex.Row justifyContent="space-between">
          <Typography.Text className={classes.label}>
            {t('projects:adminSetting.fixProjectSubscription')}
          </Typography.Text>
          <Button onClick={() => showModal('fixProjectSubscriptionModal')}>
            {t('projects:adminSetting.buttons.fixProjectSubscriptionButton')}
          </Button>
        </Flex.Row>
        <Flex.Row justifyContent="space-between">
          <Typography.Text className={classes.label}>
            {t('projects:adminSetting.createSharepoint')}
          </Typography.Text>
          <Button
            disabled={!!project.groupId}
            onClick={() => showModal('createSharepointModal')}
          >
            {t('projects:adminSetting.buttons.createSharepointButton')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default AdministrationPage;
