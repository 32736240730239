import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook as falAddressBook,
  faAddressCard as falAddressCard,
  faAlarmClock as falAlarmClock,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faAngleDoubleRight as falAngleDoubleRight,
  faArchive as falArchive,
  faArrowDown as falArrowDown,
  faArrowDownRight as falArrowDownRight,
  faArrowFromLeft as falArrowFromLeft,
  faArrowRight as falArrowRight,
  faArrowRotateRight as falArrowRotateRight,
  faArrowRotateLeft as falArrowRotateLeft,
  faArrowUp as falArrowUp,
  faArrowUpRightFromSquare as falArrowUpRightFromSquare,
  faArrowUpToLine as falArrowUpToLine,
  faArrowUpRight as falArrowUpRight,
  faArrowRightToBracket as falArrowRightToBracket,
  faArrowToBottom as falArrowToBottom,
  faArrowToTop as falArrowToTop,
  faArrowTurnDownRight as falArrowTurnDownRight,
  faArrowDownArrowUp as falArrowDownArrowUp,
  faBaby as falBaby,
  faBan as falBan,
  faBell as falBell,
  faBoxArchive as falBoxArchive,
  faBuilding as falBuilding,
  faBusinessTime as falBusinessTime,
  faCalendar as falCalendar,
  faCalendarAlt as falCalendarAlt,
  faCalendarCheck as falCalendarCheck,
  faCalendarCirclePlus as falCalendarCirclePlus,
  faCalendarClock as falCalendarClock,
  faCalendarDay as falCalendarDay,
  faCalendarMinus as falCalendarMinus,
  faCalendarPlus as falCalendarPlus,
  faCalendarRange as falCalendarRange,
  faCalendarStar as falCalendarStar,
  faCalendarWeek as falCalendarWeek,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faChevronCircleRight as falChevronCircleRight,
  faChevronCircleLeft as falChevronCircleLeft,
  faChevronDoubleLeft as falChevronDoubleLeft,
  faChevronDoubleRight as falChevronDoubleRight,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faChevronUp as falChevronUp,
  faCircle as falCircle,
  faClipboardCheck as falClipboardCheck,
  faClipboardList as falClipboardList,
  faClock as falClock,
  faClockSeven as falClockSeven,
  faCloud as falCloud,
  faCoffee as falCoffee,
  faCoins as falCoins,
  faMoneyBillTransfer as falMoneyBillTransfer,
  faCompress as falCompress,
  faCog as falCog,
  faCopy as falCopy,
  faDotCircle as falDotCircle,
  faEdit as falEdit,
  faEllipsisV as falEllipsisV,
  faEllipsisH as falEllipsisH,
  faEmptySet as falEmptySet,
  faEnvelope as falEnvelope,
  faEnvelopes as falEnvelopes,
  faEnvelopeOpen as falEnvelopeOpen,
  faEnvelopeOpenDollar as falEnvelopeOpenDollar,
  faEnvelopeOpenText as falEnvelopeOpenText,
  faEuroSign as falEuroSign,
  faExchange as falExchange,
  faExclamation as falExclamation,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faExpand as falExpand,
  faExternalLinkAlt as falExternalLinkAlt,
  faEye as falEye,
  faFile as falFile,
  faFiles as falFiles,
  faFileAlt as falFileAlt,
  faFileCsv as falFileCsv,
  faFileExcel as falFileExcel,
  faFileExport as falFileExport,
  faFileImage as falFileImage,
  faFileMedical as falFileMedical,
  faFileUpload as falFileUpload,
  faFlag as falFlag,
  faFolder as falFolder,
  faFolderBookmark as falFolderBookmark,
  faFolderPlus as falFolderPlus,
  faFolders as falFolders,
  faFolderTree as falFolderTree,
  faGlassCitrus as falGlassCitrus,
  faGlobe as falGlobe,
  faGlobeEurope as falGlobeEurope,
  faGripVertical as falGripVertical,
  faHandHoldingDollar as falHandsHoldingDollar,
  faHistory as falHistory,
  faHorizontalRule as falHorizontalRule,
  faImage as falImage,
  faInbox as falInbox,
  faInboxIn as falInboxIn,
  faInfoCircle as falInfoCircle,
  faIslandTropical as falIslandTropical,
  faLink as falLink,
  faLock as falLock,
  faLongArrowDown as falLongArrowDown,
  faLongArrowUp as falLongArrowUp,
  faMap as falMap,
  faMapMarkerAlt as falMapMarkerAlt,
  faMobile as falMobile,
  faMinus as falMinus,
  faMinusCircle as falMinusCircle,
  faMinusSquare as falMinusSquare,
  faMoneyBill as falMoneyBill,
  faNotebook as falNotebook,
  faNotes as falNotes,
  faPaperclip as falPaperclip,
  faPaperPlane as falPaperPlane,
  faPen as falPen,
  faPencil as falPencil,
  faPeopleArrows as falPeopleArrows,
  faPhone as falPhone,
  faPills as falPills,
  faPlay as falPlay,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faPlusSquare as falPlusSquare,
  faPrint as falPrint,
  faPuzzlePiece as falPuzzlePiece,
  faQuestionCircle as falQuestionCircle,
  faReceipt as falReceipt,
  faRectangleHistory as falRectangleHistory,
  faRectangleLandscape as falRectangleLandscape,
  faRedo as falRedo,
  faRepeat as falRepeat,
  faReply as falReply,
  faReplyAll as falReplyAll,
  faRoad as falRoad,
  faSearch as falSearch,
  faSigma as falSigma,
  faSignOut as falSignOut,
  faSpinnerThird as falSpinnerThird,
  faStar as falStar,
  faSync as falSync,
  faTachometerAlt as falTachometerAlt,
  faTachometerAltFast as falTachometerAltFast,
  faTasks as falTasks,
  faTasksAlt as falTasksAlt,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faTrash as falTrash,
  faTrashAlt as falTrashAlt,
  faTrashRestore as falTrashRestore,
  faTrashUndo as falTrashUndo,
  faTriangleExclamation as falTriangleExclamation,
  faTurnDownRight as falTurnDownRight,
  faUniversity as falUniversity,
  faUpload as falUpload,
  faUser as falUser,
  faUserCog as falUserCog,
  faUserClock as falUserClock,
  faUserEdit as falUserEdit,
  faUserFriends as falUserFriends,
  faUserGraduate as falUserGraduate,
  faUserPlus as falUserPlus,
  faUsers as falUsers,
  faUserShield as falUserShield,
  faVideo as falVideo,
  faFaceWorried as falFaceWorried,
  faStarChristmas as falStarChristmas,
  faBriefcase as falBriefcase,
  faSquareArrowRight as falSquareArrowRight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faBarsProgress as farBarsProgress,
  faCheck as farCheck,
  faMinus as farMinus,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAdjust as fasAdjust,
  faBan as fasBan,
  faBell as fasBell,
  faCheckCircle as fasCheckCircle,
  faChevronCircleLeft as fasChevronCircleLeft,
  faChevronCircleRight as fasChevronCircleRight,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircle as fasCircle,
  faCloud as fasCloud,
  faEnvelope as fasEnvelope,
  faExclamationCircle as fasExclamationCircle,
  faExternalLink as fasExternalLink,
  faFile as fasFile,
  faFileArchive as fasFileArchive,
  faFileExcel as fasFileExcel,
  faFileImage as fasFileImage,
  faFilePdf as fasFilePdf,
  faFilePowerpoint as fasFilePowerpoint,
  faFileVideo as fasFileVideo,
  faFileWord as fasFileWord,
  faFlag as fasFlag,
  faFolder as fasFolder,
  faNotebook as fasNotebook,
  faStar as fasStar,
  faTag as fasTag,
  faTimesCircle as fasTimesCircle,
  faTurnDownRight as fasTurnDownRight,
  faCircleCheck as fasCirlceCheck,
  faCircleXmark as fasCirlceXMark,
  faSignOutAlt as fasSignOutAlt,
  faBug as fasBug,
  faHeart as fasHeart,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFolderXmark as fadFolderXmark,
  faUserRobot as fadUserRobot,
} from '@fortawesome/pro-duotone-svg-icons';

const falIcons: IconDefinition[] = [
  falAddressBook,
  falAddressCard,
  falAlarmClock,
  falAngleUp,
  falAngleDown,
  falAngleDoubleRight,
  falArchive,
  falArrowDown,
  falArrowDownRight,
  falArrowFromLeft,
  falArrowRight,
  falArrowRotateRight,
  falArrowRotateLeft,
  falArrowUp,
  falArrowUpRightFromSquare,
  falArrowUpToLine,
  falArrowUpRight,
  falArrowRightToBracket,
  falArrowToBottom,
  falArrowToTop,
  falArrowTurnDownRight,
  falArrowDownArrowUp,
  falBaby,
  falBan,
  falBell,
  falBoxArchive,
  falBuilding,
  falBusinessTime,
  falCalendar,
  falCalendarAlt,
  falCalendarCheck,
  falCalendarCirclePlus,
  falCalendarClock,
  falCalendarDay,
  falCalendarMinus,
  falCalendarPlus,
  falCalendarRange,
  falCalendarStar,
  falCalendarWeek,
  falCheck,
  falCheckCircle,
  falChevronCircleLeft,
  falChevronCircleRight,
  falChevronDoubleLeft,
  falChevronDoubleRight,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falChevronUp,
  falCircle,
  falClipboardCheck,
  falClipboardList,
  falClock,
  falClockSeven,
  falCloud,
  falCoffee,
  falCoins,
  falCompress,
  falMoneyBillTransfer,
  falCog,
  falCopy,
  falDotCircle,
  falEdit,
  falEllipsisV,
  falEllipsisH,
  falEmptySet,
  falEnvelope,
  falEnvelopes,
  falEnvelopeOpen,
  falEnvelopeOpenDollar,
  falEnvelopeOpenText,
  falEuroSign,
  falExchange,
  falExclamation,
  falExclamationCircle,
  falExclamationTriangle,
  falExpand,
  falExternalLinkAlt,
  falEye,
  falFile,
  falFiles,
  falFileAlt,
  falFileCsv,
  falFileExcel,
  falFileExport,
  falFileImage,
  falFileMedical,
  falFileUpload,
  falFlag,
  falFolder,
  falFolderBookmark,
  falFolderPlus,
  falFolders,
  falFolderTree,
  falGlassCitrus,
  falGlobe,
  falGlobeEurope,
  falHandsHoldingDollar,
  falGripVertical,
  falHistory,
  falHorizontalRule,
  falImage,
  falInbox,
  falInboxIn,
  falInfoCircle,
  falIslandTropical,
  falLink,
  falLock,
  falLongArrowDown,
  falLongArrowUp,
  falMap,
  falMapMarkerAlt,
  falMobile,
  falMinus,
  falMinusCircle,
  falMinusSquare,
  falMoneyBill,
  falNotebook,
  falNotes,
  falPaperclip,
  falPaperPlane,
  falPen,
  falPencil,
  falPeopleArrows,
  falPhone,
  falPills,
  falPlay,
  falPlus,
  falPlusCircle,
  falPlusSquare,
  falPrint,
  falPuzzlePiece,
  falQuestionCircle,
  falReceipt,
  falRectangleHistory,
  falRectangleLandscape,
  falRedo,
  falRepeat,
  falReply,
  falReplyAll,
  falRoad,
  falSearch,
  falSigma,
  falSignOut,
  falSpinnerThird,
  falStar,
  falSync,
  falTachometerAlt,
  falTachometerAltFast,
  falTasks,
  falTasksAlt,
  falTimes,
  falTimesCircle,
  falTrash,
  falTrashAlt,
  falTrashRestore,
  falTrashUndo,
  falTriangleExclamation,
  falTurnDownRight,
  falUniversity,
  falUpload,
  falUser,
  falUserCog,
  falUserClock,
  falUserEdit,
  falUserFriends,
  falUserGraduate,
  falUserPlus,
  falUsers,
  falUserShield,
  falVideo,
  falFaceWorried,
  falStarChristmas,
  falBriefcase,
  falSquareArrowRight,
];

const farIcons: IconDefinition[] = [farBarsProgress, farCheck, farMinus];

const fasIcons: IconDefinition[] = [
  fasAdjust,
  fasBan,
  fasBell,
  fasCheckCircle,
  fasChevronCircleLeft,
  fasChevronCircleRight,
  fasChevronLeft,
  fasChevronRight,
  fasCircle,
  fasCloud,
  fasEnvelope,
  fasExclamationCircle,
  fasExternalLink,
  fasFile,
  fasFileArchive,
  fasFileExcel,
  fasFileImage,
  fasFilePdf,
  fasFilePowerpoint,
  fasFileVideo,
  fasFileWord,
  fasFlag,
  fasFolder,
  fasNotebook,
  fasStar,
  fasTag,
  fasTimesCircle,
  fasTurnDownRight,
  fasCirlceCheck,
  fasCirlceXMark,
  fasSignOutAlt,
  fasBug,
  fasHeart,
];

const fadIcons: IconDefinition[] = [fadUserRobot, fadFolderXmark];

const icons = {
  add: () => {
    library.add(...falIcons, ...farIcons, ...fasIcons, ...fadIcons);
  },
};

export default icons;
