import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';

import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { ChartItem } from './ChartItem';
import { useSelector } from 'react-redux';
import { getAbsenceOverview } from '../../../../apps/main/rootReducer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.old.spacing.unit(2),
    width: '100%',
  },
  mainContent: {
    paddingLeft: 15,
  },
}));

interface AbsenceInsightsChartProps {
  className?: string;
}
export const AbsenceInsightsChart: React.FC<AbsenceInsightsChartProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  const absenceOverview = useSelector(getAbsenceOverview);

  //#region ------------------------------ States / Attributes / Selectors
  const {
    remainingDaysPreviousYear,
    vacationDay,
    takenVacationDays,
    requestedVacationDays,
    acceptedVacationDays,
  } = absenceOverview ?? {
    remainingDaysPreviousYear: 0,
    vacationDay: 0,
    takenVacationDays: 0,
    requestedVacationDays: 0,
    acceptedVacationDays: 0,
  };
  const remainingVacationDays =
    vacationDay +
    remainingDaysPreviousYear -
    takenVacationDays -
    acceptedVacationDays -
    requestedVacationDays;

  const ref = useRef<HTMLDivElement>(null);

  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>(null);

  const [showFullText, setShowFullText] = useState<boolean>(true);
  const classes = useStyles({ showFullText });

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!resizeObserver) {
      const _resizeObserver = new ResizeObserver((entries) => {
        if (entries.length === 1) {
          const visibleWidth = entries[0].contentRect.width;
          setShowFullText(visibleWidth > 648);
        }
      });
      setResizeObserver(_resizeObserver);
    }
  }, [resizeObserver]);

  useEffect(() => {
    if (ref.current && resizeObserver) {
      resizeObserver.observe(ref.current);
    }
  }, [ref, resizeObserver]);

  useEffect(() => {
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [resizeObserver]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)} ref={ref}>
      <Flex.Column
        className={classNames(classes.root, className)}
        childrenGap={theme.old.spacing.unit(2)}
        ref={ref}
      >
        <Flex.Column
          className={classes.mainContent}
          childrenGap={theme.old.spacing.baseSpacing}
        >
          <Flex.Row alignItems="center">
            {remainingDaysPreviousYear > 0 && (
              <ChartItem
                iconName="history"
                days={remainingDaysPreviousYear}
                label={t(
                  'absences:absenceOverview.popOver.remainingDaysPreviousYear'
                )}
                color={theme.old.palette.chromaticPalette.blue}
                showFullText={showFullText}
                includeNegativeNumbers
              />
            )}
            <ChartItem
              iconName="island-tropical"
              days={vacationDay}
              label={t('absences:absenceOverview.popOver.vacationDay')}
              color={theme.old.palette.chromaticPalette.darkBlue}
              showFullText={showFullText}
            />
            {remainingDaysPreviousYear < 0 && (
              <ChartItem
                iconName="history"
                days={remainingDaysPreviousYear}
                label={t(
                  'absences:absenceOverview.popOver.remainingDaysPreviousYear'
                )}
                color={theme.old.palette.chromaticPalette.red}
                showFullText={showFullText}
                includeNegativeNumbers
              />
            )}
          </Flex.Row>
          <Flex.Row alignItems="center">
            <ChartItem
              iconName="calendar-alt"
              days={takenVacationDays}
              label={t('absences:absenceOverview.popOver.takenVacationDays')}
              color={theme.old.palette.chromaticPalette.grey}
              showFullText={showFullText}
            />
            <ChartItem
              iconName="calendar-check"
              days={acceptedVacationDays}
              label={t('absences:absenceOverview.popOver.acceptedVacationDays')}
              color={theme.old.palette.chromaticPalette.green}
              showFullText={showFullText}
            />
            <ChartItem
              iconName="calendar-plus"
              days={requestedVacationDays}
              label={t(
                'absences:absenceOverview.popOver.requestedVacationDays'
              )}
              color={theme.old.palette.chromaticPalette.yellow}
              showFullText={showFullText}
            />
            <ChartItem
              days={remainingVacationDays}
              iconName="calendar"
              label={t(
                'absences:absenceOverview.popOver.remainingVacationDays'
              )}
              color={theme.old.palette.chromaticPalette.darkBlue}
              showFullText={showFullText}
            />
          </Flex.Row>
        </Flex.Column>
      </Flex.Column>
    </div>
  );
};

export default AbsenceInsightsChart;
