import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Divider, Popover, Typography } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { DateTimeString } from '../../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getMonthlyCloseMeByMonth,
} from '../../../../apps/main/rootReducer';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import WorkingHoursCircularProgressChart from '../WorkingHoursCircularProgressChart';
import TimeRecordHoursCircularProgressChart from '../TimeRecordHoursCircularProgressChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  label: {
    margin: 'auto',
  },
  heading: {
    fontSize: 14,
  },
  summary: {
    margin: '12px 0px',
  },
  backgroundColorPanel: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    padding: '6px',
  },
  backgroundColorPanelDivider: {
    marginRight: '12px',
  },
  chart: {
    justifyContent: 'center',
  },
  marginDivider: {
    margin: '8px 0px',
  },
  popoverLarge: {
    width: '450px',
  },
  popoverSmall: {
    width: '300px',
  },
  infoIcon: {
    padding: '0px 8px',
    height: '14px',
    width: '14px',
    color: theme.old.palette.chromaticPalette.grey,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface ToolbarContentProps {
  className?: string;
  date: DateTimeString;
}

export const ToolbarContent: React.FC<ToolbarContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, date } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyCloseMe = useSelector<RootReducerState, MonthlyClose>((state) =>
    getMonthlyCloseMeByMonth(state, date.substring(0, 7))
  );

  const isCurrentMonth: boolean = useMemo(() => {
    return date.substring(0, 7) === moment().format('YYYY-MM');
  }, [date]);

  const nonTransferableOvertimeHoursUsed: number = useMemo(() => {
    const nonTransferableOvertimeHours =
      monthlyCloseMe?.nonTransferableOvertimeHours;
    const hoursUntilNonTransferableOvertimeHoursExceeded =
      monthlyCloseMe?.hoursUntilNonTransferableOvertimeHoursExceeded;
    if (
      nonTransferableOvertimeHours <
      hoursUntilNonTransferableOvertimeHoursExceeded
    ) {
      return 0;
    }
    if (
      nonTransferableOvertimeHours >
        hoursUntilNonTransferableOvertimeHoursExceeded &&
      hoursUntilNonTransferableOvertimeHoursExceeded >= 0
    ) {
      return (
        nonTransferableOvertimeHours -
        hoursUntilNonTransferableOvertimeHoursExceeded
      );
    }
    if (hoursUntilNonTransferableOvertimeHoursExceeded < 0) {
      return nonTransferableOvertimeHours;
    }
    return 0;
  }, [monthlyCloseMe]);

  const workingHoursBalance: number = useMemo(() => {
    const actualWorkHours = monthlyCloseMe?.actualWorkHours ?? 0;
    const expectedWorkHours = isCurrentMonth
      ? monthlyCloseMe?.expectedWorkHoursToDate
      : monthlyCloseMe?.expectedWorkHours ?? 0;
    return (
      actualWorkHours - expectedWorkHours - nonTransferableOvertimeHoursUsed
    );
  }, [monthlyCloseMe, isCurrentMonth, nonTransferableOvertimeHoursUsed]);

  const homeOfficeDays =
    monthlyCloseMe?.timeKeepingDays?.filter(({ type }) => type === 'homeOffice')
      .length ?? 0;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row className={classes.summary}>
        <Flex.Column
          flex={6}
          className={classNames(
            classes.backgroundColorPanel,
            classes.backgroundColorPanelDivider
          )}
        >
          <Flex.Row justifyContent="space-around">
            <Flex.Column flex={3} alignItems="space-around">
              <WorkingHoursCircularProgressChart
                className={classes.chart}
                monthlyClose={monthlyCloseMe}
              />
            </Flex.Column>
            <Flex.Column flex={4}>
              <TimeRecordHoursCircularProgressChart
                className={classes.chart}
                monthlyClose={monthlyCloseMe}
              />
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
        <Flex.Column
          flex={4}
          className={classNames(
            classes.backgroundColorPanel,
            classes.backgroundColorPanelDivider
          )}
          justifyContent="center"
        >
          <Flex.Row justifyContent="space-around">
            <Flex.Column alignItems="center">
              <Typography.Text type="secondary" className={classes.heading}>
                {t(
                  'timeAndLeaveManagement:calendar.toolbar.accumulatedOvertimeHours'
                )}
              </Typography.Text>
              <div
                style={{
                  color:
                    (monthlyCloseMe?.accumulatedOvertimeHours ?? 0) < 0
                      ? theme.old.palette.chromaticPalette.red
                      : undefined,
                }}
              >{`${monthlyCloseMe?.accumulatedOvertimeHours ?? 0} h`}</div>
            </Flex.Column>
            <Divider
              type="vertical"
              style={{
                height: '100%',
                borderColor: theme.old.borders.colors.main,
              }}
            />
            <Flex.Column alignItems="center">
              <Flex.Row alignItems="center">
                <Typography.Text type="secondary" className={classes.heading}>
                  {t('timeAndLeaveManagement:calendar.toolbar.overtimeChange')}
                </Typography.Text>
                <Popover
                  trigger="click"
                  content={
                    <>
                      <Typography.Paragraph
                        style={{ marginBottom: '0px' }}
                        className={
                          isCurrentMonth
                            ? classes.popoverLarge
                            : classes.popoverSmall
                        }
                      >
                        {t(
                          'timeAndLeaveManagement:calendar.toolbar.popover3.intro'
                        )}
                      </Typography.Paragraph>
                      <Divider className={classes.marginDivider} />
                      <div style={{ paddingLeft: 24 }}>
                        <div style={{ paddingLeft: 40 }}>
                          {t(
                            'timeAndLeaveManagement:calendar.toolbar.popover3.calc1',
                            {
                              value: monthlyCloseMe?.actualWorkHours ?? 0,
                            }
                          )}
                        </div>
                        <div>
                          <span
                            style={{ width: '40px', display: 'inline-block' }}
                          >
                            &#8722;
                          </span>
                          <span>
                            {t(
                              `timeAndLeaveManagement:calendar.toolbar.popover3.calc2_${
                                isCurrentMonth ? 'current' : 'past'
                              }`,
                              {
                                value:
                                  monthlyCloseMe?.expectedWorkHoursToDate ?? 0,
                              }
                            )}
                          </span>
                        </div>
                        {monthlyCloseMe?.nonTransferableOvertimeHours > 0 && (
                          <div>
                            <span
                              style={{ width: '40px', display: 'inline-block' }}
                            >
                              &#8722;
                            </span>
                            <span>
                              {t(
                                `timeAndLeaveManagement:calendar.toolbar.popover3.calc3`,
                                {
                                  value: nonTransferableOvertimeHoursUsed ?? 0,
                                }
                              )}
                            </span>
                          </div>
                        )}
                        <div style={{ marginBottom: '8px', marginTop: '8px' }}>
                          <span
                            style={{ width: '40px', display: 'inline-block' }}
                          >
                            =
                          </span>
                          <span>
                            {t(
                              `timeAndLeaveManagement:calendar.toolbar.popover3.interimResult${
                                workingHoursBalance < 0
                                  ? 'Negative'
                                  : monthlyCloseMe?.nonTransferableOvertimeHours >
                                    0
                                  ? 'PositiveNontransferables'
                                  : 'Positive'
                              }`,
                              {
                                value: workingHoursBalance ?? 0,
                              }
                            )}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{ width: '40px', display: 'inline-block' }}
                          >
                            &#8722;
                          </span>
                          <span>
                            {t(
                              'timeAndLeaveManagement:calendar.toolbar.popover3.calc4',
                              {
                                value:
                                  monthlyCloseMe?.overtimeCompensationHours ??
                                  0,
                              }
                            )}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{ width: '40px', display: 'inline-block' }}
                          >
                            &#8722;
                          </span>
                          <span>
                            {t(
                              'timeAndLeaveManagement:calendar.toolbar.popover3.calc5',
                              {
                                value:
                                  monthlyCloseMe?.compensationPaymentHours ?? 0,
                              }
                            )}
                          </span>
                        </div>
                        <div style={{ marginBottom: '8px' }}>
                          <span
                            style={{ width: '40px', display: 'inline-block' }}
                          >
                            +/&#8722;
                          </span>
                          <span>
                            {t(
                              'timeAndLeaveManagement:calendar.toolbar.popover3.calc6',
                              {
                                value: monthlyCloseMe?.overtimeCorrection ?? 0,
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          <span
                            style={{ width: '40px', display: 'inline-block' }}
                          >
                            =
                          </span>
                          <span style={{ fontWeight: 500 }}>
                            {t(
                              'timeAndLeaveManagement:calendar.toolbar.popover3.calc7',
                              {
                                value: monthlyCloseMe?.overtimeHoursChange ?? 0,
                              }
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                >
                  <FontAwesomeIcon
                    icon={['fal', 'info-circle']}
                    className={classes.infoIcon}
                  />
                </Popover>
              </Flex.Row>
              <div
                style={{
                  color:
                    (monthlyCloseMe?.overtimeHoursChange ?? 0) < 0
                      ? theme.old.palette.chromaticPalette.red
                      : undefined,
                }}
              >{`${monthlyCloseMe?.overtimeHoursChange ?? 0} h`}</div>
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
        <Flex.Column
          flex={3}
          className={classes.backgroundColorPanel}
          justifyContent="center"
        >
          <Flex.Row justifyContent="space-around">
            <Flex.Column alignItems="center">
              <Typography.Text type="secondary" className={classes.heading}>
                {t('timeAndLeaveManagement:calendar.toolbar.homeOffice')}
              </Typography.Text>
              <div>
                {`${homeOfficeDays} ${
                  homeOfficeDays !== 1
                    ? t('common:calendar:days')
                    : t('common:calendar:day')
                }`}
              </div>
            </Flex.Column>
            <Flex.Column alignItems="center">
              <Typography.Text type="secondary" className={classes.heading}>
                {t('timeAndLeaveManagement:calendar.toolbar.absence')}
              </Typography.Text>
              <div>
                {monthlyCloseMe
                  ? `${monthlyCloseMe?.absenceDays ?? 0} ${
                      monthlyCloseMe.absenceDays !== 1
                        ? t('common:calendar.days')
                        : t('common:calendar.day')
                    }`
                  : ''}
              </div>
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
      </Flex.Row>
    </Flex.Column>
  );
};

export default ToolbarContent;
