import React, { useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { MENU_BUTTON_SIZE } from '../../../constants';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import releaseNotes from './notes.json';
import ReleaseNotesDrawer from './ReleaseNoteDrawer';
import { PrioReleaseNote } from '../../../models/Prio365Features';
import { Card } from '@prio365/prio365-react-library';
import { useDispatch } from 'react-redux';
import { openZendeskDrawer } from '../actions/drawer';
import { useTranslation } from 'react-i18next';
import { Button } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    margin: `${theme.old.spacing.unit(3)}px`,
  },
  space: {
    display: 'flex',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  inputFieldContainer: {
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px 0`,
  },
  details: {
    float: 'right',
  },
  inputField: {
    marginBottom: theme.old.spacing.baseSpacing,
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  listContainer: {
    height: 'calc(100% - 40px)',
    overflowY: 'auto',
  },
  listBorder: {
    '& .ant-list-items': {
      borderBottom: theme.old.borders.content,
    },
  },
  listItem: {
    cursor: 'pointer',
    height: theme.old.spacing.unit(8),
    '-webkit-transition': 'all 0.3s ease-out',
    transition: 'all 0.3s ease-out',
    '&.ant-list-item': {
      padding: `0 ${theme.old.spacing.unit(3)}px`,
      borderTop: theme.old.borders.content,
      borderBottom: 0,
    },
    '& > button': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
    '&:hover > button': {
      visibility: 'visible',
    },
  },
  listItemMeta: {
    '& .ant-list-item-meta-title': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      marginBottom: 0,
      paddingRight: theme.old.spacing.defaultPadding,
    },
  },
  userflowButton: {
    padding: '0!important',
    height: '100%',
    width: MENU_BUTTON_SIZE,
    backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
    '&:focus': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
    '&.ant-btn': {
      transition: 'none',
    },
  },
  prioNewsButton: {
    margin: '24px 24px 0px 24px',
  },
}));

interface ZendeskWidgetHelpFormProps {}

export const ZendeskWidgetHelpForm: React.FC<
  ZendeskWidgetHelpFormProps
> = () => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [subDrawerVisible, setSubDrawerVisible] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [features, setFeatures] = useState<PrioReleaseNote[]>([]);
  const [bugs, setBugs] = useState<PrioReleaseNote[]>([]);

  const groupByVersions = () => {
    const grouped: { [version: string]: { features: number; bugs: number } } =
      {};

    releaseNotes.value.forEach((note) => {
      const { Version, Type } = note;
      if (!grouped[Version]) {
        grouped[Version] = {
          features: 0,
          bugs: 0,
        };
      }

      if (Type === 'Feature') {
        grouped[Version].features += 1;
      } else if (Type === 'Bug') {
        grouped[Version].bugs += 1;
      }
    });

    return grouped;
  };

  const dispatch = useDispatch();
  const groupedReleaseNotes = groupByVersions();
  const closeDrawer = () => dispatch(openZendeskDrawer(false));

  const startUserFlow = (version: string) => {
    var code = '';

    closeDrawer();
    switch (version) {
      case '1.1.2':
        code = '5bad1a5c-1b39-43b0-bace-3607395c15b1';
        break;
      case '1.1.3':
        code = '09ff8b1d-a462-400d-8508-fec10dd3d034';
        break;
    }
    setTimeout(() => window.open(`?userflow=${code}`, '_self'), 500);
  };

  const handleCardClick = (version: string) => {
    setSelectedVersion(version);

    const featuresForVersion = releaseNotes.value.filter(
      (note) => note.Version === version && note.Type === 'Feature'
    );
    setFeatures(featuresForVersion);

    const bugsForVersion = releaseNotes.value.filter(
      (note) => note.Version === version && note.Type === 'Bug'
    );
    setBugs(bugsForVersion);

    setSubDrawerVisible(true);
  };

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.old.spacing.unit(2)}
    >
      {Object.keys(groupedReleaseNotes).map((version) => (
        <Card
          secondaryCard={true}
          title={`${t('zendesk:version')} ${version}`}
          contentNode={
            <>
              <p>
                {t('zendesk:features')}: {groupedReleaseNotes[version].features}{' '}
                | {t('zendesk:bugs')}: {groupedReleaseNotes[version].bugs} |{' '}
                {t('zendesk:updatetour')}:
                <Button
                  type="link"
                  onClick={() => startUserFlow(version)}
                  iconProp={['fal', 'play']}
                  style={{ marginLeft: '8px' }}
                ></Button>
                <Button
                  className={classes.details}
                  type="link"
                  onClick={() => handleCardClick(version)}
                  iconProp={['fal', 'chevron-right']}
                ></Button>
              </p>
            </>
          }
        ></Card>
      ))}
      <ReleaseNotesDrawer
        visible={subDrawerVisible}
        onClose={() => setSubDrawerVisible(false)}
        selectedVersion={selectedVersion}
        features={features}
        bugs={bugs}
      />
    </Flex.Column>
  );
};

export default ZendeskWidgetHelpForm;
