import React from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { ContactId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { RootReducerState, getContacts } from '../../../apps/main/rootReducer';
import { Contact } from '../../../models/Contact';
import BasisPicker, {
  BasisPickerSelectOptions,
} from '../../../components/BasisPicker';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface TimeRecordsFilterContactPickerProps {
  contactIds: ContactId[];
  onChange?: (value: string | string[]) => void;
  disabled?: boolean;
  defaultValue?: ContactId | ContactId[];
  value?: ContactId | ContactId[];
}

export const TimeRecordsFilterContactPicker: React.FC<
  TimeRecordsFilterContactPickerProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const { contactIds, onChange, disabled, value, defaultValue } = props;

  const contacts = useSelector<RootReducerState, Contact[]>((state) =>
    getContacts(state, contactIds)
  ).sort((a: Contact, b: Contact) => {
    const lastNameCompare = a.lastName?.localeCompare(b.lastName);
    if (lastNameCompare !== 0) return lastNameCompare;
    return a.firstName?.localeCompare(b.firstName);
  });

  const selectOptions: BasisPickerSelectOptions[] = contacts.map(
    (contact, index) => {
      return {
        label: `${
          contact && contact.firstName
            ? contact.firstName
            : t('common:errorMessages.unknownObject')
        } ${
          contact && contact.lastName
            ? contact.lastName
            : t('common:errorMessages.unknownObject')
        }`,
        value:
          contact && contact.contactId
            ? contact.contactId
            : `${t('common:errorMessages.unknownObject')} - ${index}`,
        key:
          contact && contact.contactId
            ? contact.contactId
            : `${t('common:errorMessages.unknownObject')} - ${index}`,
        isItalic: !contact,
      };
    }
  );
  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks

  //#endregion

  return (
    <BasisPicker
      defaultValue={defaultValue}
      value={value}
      className={classes.root}
      items={selectOptions}
      optionFilterProp="label"
      multipleSelect
      onChange={onChange}
      disabled={disabled}
      placeHolder={t('common:all')}
    />
  );
};

export default TimeRecordsFilterContactPicker;
