import { notification } from 'antd';
import { DriveItem } from '../../../models/Drive';
import { GroupId, ProjectId } from '../../../models/Types';
import { apiFetchDocument, apiFetchDocumentWithCaching } from '../api';
import i18n from '../../../i18n';
import { getDriveItemType, getPDFType } from '../util';
import { useSelector } from 'react-redux';
import {
  getDocumentSettings,
  getDocumentsCachingEnabled,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { useCallback } from 'react';

export const useGetLocalUrlByDriveId = () => {
  const me = useSelector(getUserMe);
  const documentSettings = useSelector(getDocumentSettings);
  const isDocumentsCachingEnabled =
    useSelector(getDocumentsCachingEnabled) ?? false;

  const getLocalUrlByDriveItem = useCallback(
    async (
      groupId: GroupId,
      projectId: ProjectId,
      driveItem: DriveItem,
      setIsFetching?: (value: boolean) => void,
      openPdf?: boolean
    ): Promise<string> => {
      let item: DriveItem = driveItem;
      if (!driveItem.parentReference?.path) {
        if (setIsFetching) {
          setIsFetching(true);
        }
        const { data } =
          isDocumentsCachingEnabled === true
            ? await apiFetchDocumentWithCaching(projectId, driveItem.id)
            : await apiFetchDocument(groupId, driveItem.id);
        if (setIsFetching) {
          setIsFetching(false);
        }
        if (data) {
          item = data;
        } else {
          notification.open({
            message: i18n.t('common:error'),
            description: i18n.t(
              'documents:errorMessages.fetchDriveItemErrorLink'
            ),
          });
          return null;
        }
      }
      const type = getDriveItemType(item);
      const pdfType = getPDFType(item);

      if (item.webDavUrl) {
        if (type) {
          if (type === 'onenote') {
            return `${type}:${item.webDavUrl}`;
          }
          return `${type}:ofe|ofc|u|${item.webDavUrl}`;
        }

        if (pdfType) {
          if (documentSettings.defaultPdfProgram === 'pdfXchange') {
            if (openPdf !== undefined && openPdf) {
              return `prio365://openpdf=${item.webDavUrl}`;
            }
          }
          if (documentSettings.defaultPdfProgram === 'adobeAcrobat') {
            if (openPdf !== undefined && openPdf) {
              return `acrobat2018://openConnectorFile/?storageId=sharepoint&${
                !!me?.mail ? `userId=${me?.mail}&` : ''
              }fileId=${item.webDavUrl}`;
            }
          }
        }
      }

      return item.webUrl;
    },
    [documentSettings.defaultPdfProgram, me?.mail, isDocumentsCachingEnabled]
  );

  return {
    getLocalUrlByDriveItem,
  };
};
