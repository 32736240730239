import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useMemo } from 'react';
import { makePrioStyles } from '../../../../theme/utils';
import { calcTextWidth } from '../../../../util/calcTextWidth';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: ({ showFullText, textWidth }) =>
    showFullText
      ? {}
      : {
          '&:hover': {
            minWidth: textWidth + 2 * 41,
          },
        },
  chart: {
    position: 'relative',
    height: 40,
    minWidth: ({ showFullText }) => (showFullText ? 'unset' : 34), //64,
    transition: '0.2s all',
  },
  chartIconWrapper: {
    position: 'relative',
  },
  chartIcon: {
    position: 'absolute',
    height: 35,
    width: 35,
    left: -14,
    top: 0,
    borderRadius: 32,
    border: `2px solid ${theme.old.borders.colors.content}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartBar: {
    height: 40,
    borderRadius: 4,
    padding: '0 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.old.typography.colors.inverted,
  },
  description: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.old.typography.colors.invertedMuted,
  },
  iconCircleWrapper: {
    left: '-15px',
    position: 'absolute',
  },
  iconCircle: {
    border: `1px solid ${theme.old.borders.colors.content}`,
    position: 'absolute',
    borderRadius: 32,
    height: 40,
    width: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  iconCircleNumericalData: {
    opacity: '1',
    '&:hover': {
      opacity: '0',
    },
  },
}));

interface ChartItemProps {
  className?: string;
  iconName?: IconName;
  label?: string;
  days?: number;
  color: string;
  includeNegativeNumbers?: boolean;
  showFullText?: boolean;
}

export const ChartItem = ({
  className,
  iconName,
  label,
  days,
  color,
  includeNegativeNumbers,
  showFullText,
  ...props
}: ChartItemProps) => {
  //#region ------------------------------ Defaults
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const description = days;

  const textWidth = useMemo(
    () => Math.round(label ? calcTextWidth(label, '15px') : 0),
    [label]
  );
  const classes = useStyles({ textWidth, showFullText });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (days === 0 || (!includeNegativeNumbers && days < 0)) {
    return null;
  }

  if (!label && !description && !iconName) {
    return (
      <div
        className={classNames(classes.chart, classes.root, className)}
        style={{ flex: days, backgroundColor: color }}
      />
    );
  }

  return (
    <div
      className={classNames(classes.chart, className, {
        [classes.root]: !showFullText,
      })}
      style={{ flex: Math.abs(days) }}
    >
      {iconName && (
        <div className={classes.iconCircleWrapper}>
          <div
            className={classes.iconCircle}
            style={{ backgroundColor: color }}
          >
            <div className={classNames(classes.iconCircle)}>
              <div>{days}</div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.chartBar} style={{ backgroundColor: color }}>
        {label && <div className={classes.title}>{label}</div>}
      </div>
    </div>
  );
};
