import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import UserAvatar from './UserAvatar';
import Flex from './Flex';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../apps/main/rootReducer';
import useDebounce from '../hooks/useDebounce';
import classNames from 'classnames';
import { clearPrioCache } from '../actions';
import { loggedOut } from '../modules/auth/actions';
import { logoutWithAccount } from '../store/authEffect';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';
import { makePrioStyles } from '../theme/utils';
import LanguageSelector from '../modules/profile/components/LanguageSelector';

const useStyles = makePrioStyles((theme) => ({
  root: {
    zIndex: 999,
    right: 10,
    top: 48,
    width: 320,
    position: 'absolute',
    borderRadius: 2,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: '0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%)',
    marginLeft: '0!important',
    transition: 'all 0.2s',
    opacity: 0,
  },
  rootOpen: {
    opacity: 1,
  },
  informationSection: {
    padding: `${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px 0`,
  },
  logoutButton: {
    '&.ant-btn': {
      padding: theme.old.spacing.unit(1.5),
      height: 'unset',
    },
  },
  info: {
    overflow: 'hidden',
  },
  name: {
    '&.ant-typography': {
      marginBottom: 3,
    },
  },
  avatar: {
    cursor: 'pointer',
    marginRight: theme.old.spacing.unit(1),
  },
}));

interface AccountInformationPanelProps {}

export const AccountInformationPanel: React.FC<AccountInformationPanelProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const me = useSelector(getUserMe);
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const [visible, setVisible] = useState<boolean>();
  const debouncedVisible = useDebounce(visible, 200);
  //#endregion

  //#region -------------------------------- Methods

  const signOut = () => {
    dispatch(clearPrioCache());
    dispatch(loggedOut());
    logoutWithAccount();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (visible) {
      ref.current?.focus();
    }
  }, [visible, ref]);
  //#endregion

  return (
    <div
      tabIndex={-1}
      onBlur={(event) => {
        if (
          event.relatedTarget !== buttonRef.current &&
          event.relatedTarget?.id !== 'languageSelector'
        ) {
          setVisible(false);
        }
      }}
    >
      <div onClick={() => setVisible(!visible)} className={classes.avatar}>
        <UserAvatar size="medium" />
      </div>
      <div
        className={classNames(classes.root, {
          [classes.rootOpen]: visible,
        })}
        ref={ref}
      >
        {(visible || debouncedVisible) && (
          <Flex.Column>
            <Flex.Row
              justifyContent="space-between"
              className={classes.informationSection}
              childrenGap={theme.old.spacing.unit(1.5)}
            >
              <Flex.Item>
                <UserAvatar size="extraLarge" />
              </Flex.Item>
              <Flex.Column className={classes.info} flex={1}>
                <Flex.Item>
                  <Typography.Title className={classes.name} level={3}>
                    {me?.displayName ?? ''}
                  </Typography.Title>
                </Flex.Item>
                <Flex.Item>
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '320px',
                    }}
                  >
                    <Tooltip zIndex={999999} title={me?.mail ?? ''}>
                      <Typography.Text
                        style={{
                          fontSize: 13,
                        }}
                      >
                        {me?.mail ?? ''}
                      </Typography.Text>
                    </Tooltip>
                  </div>
                </Flex.Item>
                <Flex.Item>
                  <LanguageSelector />
                </Flex.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row justifyContent="flex-end">
              <Flex.Item>
                <Tooltip
                  title={t('common:navigationBar.signOut')}
                  placement="bottom"
                >
                  <div>
                    <Button
                      onClick={signOut}
                      type="link"
                      ref={buttonRef}
                      className={classes.logoutButton}
                      suffixIconProp={['fal', 'sign-out']}
                    >
                      <>{t('common:navigationBar.signOut')}</>
                    </Button>
                  </div>
                </Tooltip>
              </Flex.Item>
            </Flex.Row>
          </Flex.Column>
        )}
      </div>
    </div>
  );
};

export default AccountInformationPanel;
