import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import {} from '../../../util/officeJs';
import { TemplateSettings } from './TemplateSettings';
import { GenericSettings } from './GenericSettings';
import { UserRolePage } from '../../users/components/UserRolePage';
import { InternalCompanyManagement } from '../../companies/components/InternalCompanyManagement';
import useAccessRights from '../../users/hooks/useAccessRights';
import ThemeSettingsModule from '../../../theme/components/ThemeSettingsModule';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../theme/utils';
import PersonnelFiles from '../../hr/components/personnelFiles/PersonnelFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    gap: '1rem',
    fontSize: theme.old.typography.fontSize.base,
    fontWeight: theme.old.typography.fontWeight.bold,
  },
}));

interface SettingsModuleProps {}

export const SettingsModule: React.FC<SettingsModuleProps> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    showAllPrioSettings,
    showUserRoleSettings,
    showTemplateSettings,
    showUserCompanyData,
  } = useAccessRights([
    'showAllPrioSettings',
    'showUserRoleSettings',
    'showTemplateSettings',
    'showUserCompanyData',
  ]);

  if (
    ![
      showAllPrioSettings,
      showUserCompanyData,
      showTemplateSettings,
      showUserCompanyData,
    ].includes(true)
  )
    return (
      <div className={classes.root}>
        <div className={classes.center}>
          <FontAwesomeIcon size="5x" icon={['fal', 'face-worried']} />
          {t('common:no_access_rights.settings.general')}
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      {/* <ContactsSelectionList type={selectedList as ContactSearchType} /> */}
      <Routes>
        {showUserRoleSettings && (
          <Route path="user-roles">
            <Route path="" element={<UserRolePage pathPrefix={'../'} />} />
            <Route
              path="office/:officeId/*"
              element={<UserRolePage pathPrefix={'../'} />}
            />
          </Route>
        )}
        {showUserCompanyData && (
          <Route
            path="users/*"
            element={
              <PersonnelFiles
                className={classes.fullHeight}
                onlyShowTabs={['companyData']}
                hideNavBar
              />
            }
          />
        )}
        {showTemplateSettings && (
          <Route path="templates">
            <Route path="" element={<Navigate to="documents" replace />} />
            <Route
              path=":selectedList/*"
              element={
                <TemplateSettings
                  pathPrefix="../"
                  className={classes.fullHeight}
                />
              }
            />
            <Route
              path="office/:officeId/:selectedList/*"
              element={
                <TemplateSettings
                  pathPrefix="../"
                  className={classes.fullHeight}
                />
              }
            />
          </Route>
        )}
        {showAllPrioSettings && (
          <>
            <Route path="generic">
              <Route path="" element={<Navigate to="keyValue" replace />} />
              <Route
                path=":selectedList/*"
                element={
                  <GenericSettings
                    pathPrefix="../"
                    className={classes.fullHeight}
                  />
                }
              />
            </Route>
            <Route
              path="subsidiaries"
              element={
                <InternalCompanyManagement className={classes.fullHeight} />
              }
            />
            <Route
              path="/"
              element={
                <div className="prio-flex-center-center prio-flex-column">
                  <Typography.Text>
                    <Trans i18nKey={'settings:noSettingsSelected'} />
                  </Typography.Text>
                </div>
              }
            />
            <Route path="theme" element={<ThemeSettingsModule />} />
          </>
        )}
      </Routes>
    </div>
  );
};

export default SettingsModule;
