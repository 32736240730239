import React, { useEffect, useState } from 'react';
import { Modal, notification, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { TaskForm } from './TaskForm';
import { apiCreateTask, apiCreateTodoTask } from '../api';
import { CreateTaskRequest } from '../../../models/Task';
import TodoForm from './Todo/TodoForm';
import { CreateTodoTaskRequest, TodoTaskFormModel } from '../../../models/Todo';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Project } from '../../../models/Project';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { MessageId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: '75%',
    '& .ant-modal-content': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-modal-body': {
      overflow: 'auto',
      paddingTop: '0px',
      height: 'calc(100% - 56px)',
    },
  },
  form: {},
}));

interface TasksModalProps {
  visible: boolean;
  preSelectedTask?: CreateTaskRequest;
  preSelectedTodoTask?: CreateTodoTaskRequest;
  sourceEmailUrl?: string;
  messageId?: MessageId;
  closeModal: VoidFunction;
}

export const TasksModal: React.FC<TasksModalProps> = (props) => {
  const { visible, preSelectedTask, closeModal, sourceEmailUrl, messageId } =
    props;
  const classes = useStyles();

  var currentProject: Project;

  currentProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, preSelectedTask && preSelectedTask.projectId)
  );

  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleFinish = async (value: CreateTaskRequest) => {
    value.sourceEmailUrl = sourceEmailUrl;
    value.messageId = messageId;
    setIsSaving(true);
    const { result } = await apiCreateTask(value.projectId, value);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      closeModal();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('tasks:form.errorMessages.createTask'),
      });
      return false;
    }
  };

  const handleFinishTodoTask = async (value: TodoTaskFormModel) => {
    const { dueDate: dueDateTimeMoment, reminderDate: reminderDateTimeMoment } =
      value;
    const todoTask: CreateTodoTaskRequest = {
      todoTask: {
        title: value.title,
        status: value.status,
        importance: value.importance,
        isReminderOn: value.reminderDate ? true : false,
        dueDateTime: dueDateTimeMoment && {
          dateTime: dueDateTimeMoment?.toISOString(),
          timeZone: moment.tz.guess(),
        },
        reminderDateTime: reminderDateTimeMoment && {
          dateTime: reminderDateTimeMoment?.toISOString(),
          timeZone: moment.tz.guess(),
        },
        body: {
          content: value.description,
        },
      },
    };
    setIsSaving(true);
    const { result } = await apiCreateTodoTask(value.todoListId, todoTask);
    setIsSaving(true);
    if (result.status >= 200 && result.status < 300) {
      closeModal();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('tasks:todoForm.errorMessages.createTask'),
      });
      closeModal();
      return false;
    }
  };
  const handleCancel = () => {
    closeModal();
  };
  useEffect(() => {});

  return (
    <Modal
      className={classes.root}
      title={t('tasks:modal.title')}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
      width={800}
    >
      <Tabs defaultActiveKey="0">
        {currentProject && currentProject.masterPlanId && (
          <Tabs.TabPane
            tab="Planner"
            key="0"
            disabled={
              preSelectedTask && preSelectedTask.projectId ? false : true
            }
          >
            <TaskForm
              actionLabel={t('tasks:form.actionLabel')}
              cancelLabel={t('common:actions.cancel')}
              className={classes.form}
              onFinish={handleFinish}
              onCancel={handleCancel}
              disableForm={isSaving}
              resetOnFinish
              //@ts-ignore
              initialValues={preSelectedTask}
              isModal={visible}
              disableActionButton={isSaving}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="ToDo" key="1">
          <TodoForm
            actionLabel={t('common:save')}
            cancelLabel={t('common:actions.cancel')}
            onFinish={handleFinishTodoTask}
            onCancel={handleCancel}
            initialValues={{
              todoListId: null,
              title: preSelectedTask && preSelectedTask.title,
              description: preSelectedTask && preSelectedTask.description,
              dueDate: preSelectedTask && moment(preSelectedTask.dueDateTime),
            }}
            isModal={visible}
            resetOnFinish
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default TasksModal;
