import React, { useEffect, useState } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { ProjectId } from '../../../models/Types';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Col, Modal, Row, Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import AddProjectExtensionDrawer from './ProjectExtension/AddProjectExtensionDrawer';
import ProjectExtensionDrawer, {
  ProjectExtensionDrawerTabs,
} from './ProjectExtension/ProjectExtensionDrawer';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  apiDeleteProjectExtensionForProject,
  apiFetchInternalProjectContacts,
  apiFetchProjectExtensionsForProject,
} from '../api';
import {
  CreateProjectExtension,
  ProjectExtension,
} from '../../../models/ProjectExtension';
import { InternalProjectContact } from '../../../models/ProjectContacts';
import { Card } from '@prio365/prio365-react-library';
import { rowGutter } from '../../../util/forms';
import { getIconByType } from '../../../util/icon';
import PrioSpinner from '../../../components/PrioSpinner';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'hidden',
  },
  grid: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  colGrid: {
    padding: theme.old.spacing.baseSpacing,
  },
  addCard: {
    height: '100%',
    width: '100%',
    marginRight: theme.old.spacing.baseSpacing,
    marginBottom: -theme.old.spacing.baseSpacing * 2,
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  iconAdd: {
    margin: 'auto',
    height: '100px;',
  },
  wrapContainer: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.spacing.unit(2)}px ${theme.spacing.unit(2)}px`,
    marginBottom: `${theme.spacing.unit(2)}px`,
    '& > div:not(:last-child)': {
      marginBottom: `${theme.spacing.unit(2)}px`,
    },
  },
  wrapContainerWhiteFull: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.spacing.unit(2)}px ${theme.spacing.unit(2)}px`,
    marginBottom: `${theme.spacing.unit(2)}px`,
    '& > div:not(:last-child)': {
      marginBottom: `${theme.spacing.unit(2)}px`,
    },
    height: '100%',
  },
  secondary: {
    color: theme.typography.colors.muted,
  },
  contentContainerFloat: {
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .ant-form-item': {
      width: '100%',
    },
    '& .ant-row': {
      width: '100%',
    },
  },
}));

interface AddInsPageProps {
  projectId: ProjectId;
}

export const AddInsPage: React.FC<AddInsPageProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();
  const { projectId } = props;
  const [
    addProjectExtensionDrawerVisible,
    setAddProjectExtensionDrawerVisible,
  ] = useState<boolean>(false);
  const [projectExtensionDrawerVisible, setProjectExtensionDrawerVisible] =
    useState<boolean>(false);
  const [loadingExtensions, setLoadingExtensions] = useState<boolean>(false);
  const [projectExtensionDrawerTab, setProjectExtensionDrawerTab] =
    useState<ProjectExtensionDrawerTabs>('settings');
  //TODO

  const [projectExtentions, setProjectExtentions] = useState<
    ProjectExtension[]
  >([]);

  const [projectExtentionsToAdd, setProjectExtentionsToAdd] = useState<
    CreateProjectExtension[]
  >([]);
  const [internalProjectContacts, setInternalProjectContacts] = useState<
    InternalProjectContact[]
  >([]);

  const [selectedProjectExtentions, setSelectedProjectExtentions] =
    useState<ProjectExtension>();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [deleteProjectExtension, setDeleteProjectExtension] =
    useState<ProjectExtension>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const removeProjectExtension = async () => {
    var extensionId = deleteProjectExtension.projectExtensionId;
    var { result } = await apiDeleteProjectExtensionForProject(
      projectId,
      extensionId
    );
    if (result.status > 299 || result.status < 200) {
      notification.open({
        message: t('common:error'),
      });
    }
    setDeleteModalVisible(false);
    setProjectExtensionDrawerVisible(false);
    setDeleteProjectExtension(null);
    // delete extension id from projectExtentions
    const newProjectExtentions = projectExtentions.filter(
      (projectExtension) => projectExtension.projectExtensionId !== extensionId
    );
    setProjectExtentions(newProjectExtentions);
  };

  const projectExtensionCreated = (projectExtension: ProjectExtension) => {
    setProjectExtentions([...projectExtentions, projectExtension]);
    setSelectedProjectExtentions(projectExtension);

    const removeAdded = projectExtentionsToAdd.filter(
      (ext) =>
        projectExtension.projectExtensionType !== ext.projectExtensionType
    );
    setProjectExtentionsToAdd(removeAdded);
  };

  const reloadExtensions = async () => {
    const { data } = await apiFetchProjectExtensionsForProject(projectId);
    if (data) {
      setProjectExtentions(data);
    }
  };

  useEffect(() => {
    if (projectId) {
      setDisabled(true);
      const getExtensions = async () => {
        setLoadingExtensions(true);
        const { data } = await apiFetchProjectExtensionsForProject(projectId);
        if (data) {
          setProjectExtentions(data);
        }

        setLoadingExtensions(false);
      };
      getExtensions();
      setDisabled(false);
    }
  }, [disabled, projectId, setLoadingExtensions]);

  useEffect(() => {
    if (projectId) {
      const getInternalContacts = async (projectId) => {
        const { data } = await apiFetchInternalProjectContacts(projectId);
        if (data) {
          setInternalProjectContacts(data);
        }
      };
      getInternalContacts(projectId);
    }
  }, [projectId]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapContainerWhite}>
          <div className={classes.contentContainerFloat}>
            <Button
              onClick={() => {
                setAddProjectExtensionDrawerVisible(true);
              }}
              iconProp={['fal', 'puzzle-piece']}
            >
              {t(`projects:addIn.add`)}
            </Button>
          </div>{' '}
        </div>
        <div className={classes.wrapContainerWhiteFull}>
          <h3>{t(`projects:addIn.active.title`)}</h3>
          {(loadingExtensions ||
            projectExtentions === null ||
            projectExtentions?.length === 0) && (
            <Card
              secondaryCard
              contentNode={
                <Row justify="center">
                  {loadingExtensions && <PrioSpinner />}
                  {(projectExtentions === null ||
                    projectExtentions?.length === 0) && (
                    <p>{t(`projects:addIn.empty`)}</p>
                  )}
                </Row>
              }
            />
          )}
          {projectExtentions
            .sort((a, b) =>
              a.projectExtensionType.localeCompare(b.projectExtensionType)
            )
            .map((projectExtension, index) => (
              <Card
                secondaryCard
                contentNode={
                  <Row
                    gutter={theme.spacing.unit(rowGutter)}
                    justify="space-between"
                    align="middle"
                  >
                    <Col span={1}>
                      <FontAwesomeIcon
                        icon={[
                          'fal',
                          getIconByType(projectExtension?.projectExtensionType),
                        ]}
                      />
                    </Col>
                    <Col span={13}>{projectExtension?.displayName}</Col>
                    <Col span={10}>
                      <Row align="middle" justify="end">
                        <span className={classes.secondary}>
                          (
                          {internalProjectContacts?.length
                            ? `${projectExtension?.accesses?.filter(
                                (access) => access.shouldHaveAccess
                              ).length}/${internalProjectContacts?.length}`
                            : '0'}
                          )
                        </span>{' '}
                        <Button
                          type="link"
                          disabled={projectExtension.hasDefaultAccess}
                          onClick={() => {
                            setSelectedProjectExtentions(projectExtension);
                            setProjectExtensionDrawerTab('users');
                            setProjectExtensionDrawerVisible(true);
                          }}
                          iconProp={['fal', 'users']}
                        ></Button>
                        <Button
                          type="link"
                          onClick={() => {
                            setSelectedProjectExtentions(projectExtension);
                            setProjectExtensionDrawerTab('settings');
                            setProjectExtensionDrawerVisible(true);
                          }}
                          iconProp={['fal', 'cog']}
                        ></Button>
                      </Row>
                    </Col>
                  </Row>
                }
              ></Card>
            ))}
        </div>
      </div>
      <AddProjectExtensionDrawer
        addProjectExtensionDrawerVisible={addProjectExtensionDrawerVisible}
        projectId={projectId}
        setAddProjectExtensionDrawerVisible={
          setAddProjectExtensionDrawerVisible
        }
        reloadProjectExtension={reloadExtensions}
      />
      <ProjectExtensionDrawer
        projectExtensionDrawerVisible={projectExtensionDrawerVisible}
        setProjectExtensionDrawerVisible={setProjectExtensionDrawerVisible}
        projectExtension={selectedProjectExtentions}
        addProjectExtension={undefined}
        projectExtensionDrawerTab={projectExtensionDrawerTab}
        setProjectExtensionDrawerTab={setProjectExtensionDrawerTab}
        projectId={projectId}
        projectExtensionCreated={(newProjectExtension) => {
          projectExtensionCreated(newProjectExtension);
        }}
        projectExtensionUpdated={(updatedProjectExtension) => {
          setSelectedProjectExtentions(updatedProjectExtension);
        }}
        removeExtension={(projectExtension) => {
          setDeleteModalVisible(true);
          setDeleteProjectExtension(projectExtension);
        }}
        reloadProjectExtension={reloadExtensions}
      />
      <Modal
        title={t('projects:addIn.delete.title')}
        okText={t('projects:addIn.delete.okText')}
        cancelText={t('projects:addIn.delete.cancelText')}
        onOk={removeProjectExtension}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        visible={deleteModalVisible}
      >
        <Flex.Column>
          <Typography.Text>
            {t('projects:addIn.delete.confirm')}
          </Typography.Text>
          <ul>
            <li>{deleteProjectExtension?.displayName}</li>
          </ul>
        </Flex.Column>
      </Modal>
    </>
  );
};

export default AddInsPage;
