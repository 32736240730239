import React, { useReducer, useEffect, useMemo, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { rowGutter } from '../../../util/forms';
import moment, { Moment } from 'moment';
import { Select, Form, Row, Col, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  TimeRecord,
  TimeRecordsFilter as TimeRecordsFilterI,
} from '../../../models/TimeRecord';
import {
  CompanyId,
  ContactId,
  EditTimeRecordContextType,
  OfficeRole,
  ProjectId,
} from '../../../models/Types';
import Flex from '../../../components/Flex';
import AdvancedTimeRecordsFilter from './AdvancedTimeRecordsFilter';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import useDebounce from '../../../hooks/useDebounce';
import CustomDateRangePicker from '../../../components/CustomDateRangePicker';

const { Text } = Typography;
const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.white,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  lastColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface TimeRecordsFilterProps {
  className?: string;
  onChange?: (filter: TimeRecordsFilterI) => void;
  projectId?: ProjectId;
  companyId?: CompanyId;
  startPeriod?: Moment;
  endPeriod?: Moment;
  count?: number;
  selected?: number;
  contextType: EditTimeRecordContextType;
  officeRoles?: OfficeRole[];
  onlyOffice?: boolean;
  timeRecordsToFilter?: TimeRecord[];
  onProjectChange?: (projectIds: ProjectId[]) => void;
  onOfficeChange?: (projectIds: CompanyId[]) => void;
  onContactChange?: (projectIds: ContactId[]) => void;
  onResetAdvancedFilter?: () => void;
  currentCompanyFilterValue?: CompanyId[];
  currentProjectFilterValue?: ProjectId[];
  currentContactFilterValue?: ContactId[];
}

interface TimeRecordsFilterAction {
  filter:
    | 'companyIds'
    | 'projectIds'
    | 'contactIds'
    | 'fromTo'
    | 'period'
    | 'RESET';
  value?: string[] | string | Moment[];
}

type PreselectedPeriod =
  | 'today'
  | 'lastWeek'
  | 'currentWeek'
  | 'lastMonth'
  | 'currentMonth'
  | 'lastYear'
  | 'currentYear'
  | 'custom';

interface Period {
  from: Moment;
  to: Moment;
}

const today: Period = {
  from: moment().startOf('D'),
  to: moment().endOf('D'),
};

const currentWeek: Period = {
  from: moment().startOf('week'),
  to: moment().endOf('week'),
};

const lastWeek: Period = {
  from: moment().subtract(1, 'week').startOf('week'),
  to: moment().subtract(1, 'week').endOf('week'),
};

const currentMonth: Period = {
  from: moment().startOf('month'),
  to: moment().endOf('month'),
};

const lastMonth: Period = {
  from: moment().subtract(1, 'month').startOf('month'),
  to: moment().subtract(1, 'month').endOf('month'),
};
const currentYear: Period = {
  from: moment().startOf('year'),
  to: moment().endOf('year'),
};
const lastYear: Period = {
  from: moment().subtract(1, 'year').startOf('year'),
  to: moment().subtract(1, 'year').endOf('year'),
};

export const TimeRecordsFilter: React.FC<TimeRecordsFilterProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    onChange,
    companyId,
    startPeriod,
    endPeriod,
    count,
    selected,
    contextType,
    timeRecordsToFilter,
    onProjectChange,
    onOfficeChange,
    onContactChange,
    onResetAdvancedFilter,
    currentCompanyFilterValue,
    currentProjectFilterValue,
    currentContactFilterValue,
  } = props;
  const { t } = useTranslation();

  const [fromToDates, setFromToDates] = useState<Moment[]>([
    moment().startOf('day'),
    moment().startOf('day'),
  ]);

  const [timeRecordsFilter, dispatchTimeRecordsFilter] = useReducer<
    React.Reducer<TimeRecordsFilterI, TimeRecordsFilterAction>
  >(
    (state: TimeRecordsFilterI, action: TimeRecordsFilterAction) => {
      if (action.filter === 'period') {
        const periodValue = action.value as PreselectedPeriod;
        let period: Period;
        switch (periodValue) {
          case 'today':
            period = today;
            setFromToDates([today.from, today.to]);
            break;
          case 'lastWeek':
            period = lastWeek;
            setFromToDates([lastWeek.from, lastWeek.to]);
            break;
          case 'currentWeek':
            period = currentWeek;
            setFromToDates([currentWeek.from, currentWeek.to]);
            break;
          case 'currentMonth':
            period = currentMonth;
            setFromToDates([currentMonth.from, currentMonth.to]);
            break;
          case 'lastMonth':
            period = lastMonth;
            setFromToDates([lastMonth.from, lastMonth.to]);
            break;
          case 'currentYear':
            period = currentYear;
            setFromToDates([currentYear.from, currentYear.to]);
            break;
          case 'lastYear':
            period = lastYear;
            setFromToDates([lastYear.from, lastYear.to]);
            break;
        }
        return {
          ...state,
          ...(period ?? {}),
        };
      } else if (action.filter === 'fromTo') {
        const [from, to] = action.value as Moment[];
        return {
          ...state,
          from,
          to,
        };
      } else if (action.filter === 'RESET') {
        let resetPeriod = today;
        return { ...resetPeriod };
      } else if (action.value?.length !== 0) {
        return {
          ...state,
          [action.filter]: action.value,
        };
      }
      return {
        ...state,
        [action.filter]: undefined,
      };
    },
    {
      ...(companyId ? { companyIds: [companyId] } : {}),
    }
  );

  const debouncedTimeRecordsFilter = useDebounce(timeRecordsFilter, 1000);

  useEffect(() => {
    if (
      onChange &&
      debouncedTimeRecordsFilter.to &&
      debouncedTimeRecordsFilter.from
    ) {
      onChange(debouncedTimeRecordsFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTimeRecordsFilter]);

  useEffect(() => {
    if (companyId) {
      dispatchTimeRecordsFilter({ filter: 'companyIds', value: [companyId] });
    }
  }, [companyId]);

  useMemo(() => {
    if (
      timeRecordsFilter.from === undefined &&
      timeRecordsFilter.to === undefined
    ) {
      if (startPeriod) {
        timeRecordsFilter.from = startPeriod;
      }
      if (endPeriod) {
        timeRecordsFilter.to = endPeriod;
      }
    }
  }, [startPeriod, endPeriod, timeRecordsFilter]);

  const handlePeriodChange = (value: PreselectedPeriod) => {
    dispatchTimeRecordsFilter({ filter: 'period', value });
  };

  const handleFromToChange = (value: { startDate; endDate }) => {
    let startDate = value['startDate'] as Moment;
    let endDate = value['endDate'] as Moment;
    if (startDate && endDate) {
      setFromToDates([startDate, endDate]);
      dispatchTimeRecordsFilter({
        filter: 'fromTo',
        value: [startDate.startOf('day'), endDate.endOf('day')],
      });
    } else {
      setFromToDates([startDate, endDate]);
    }
  };

  const matchSelectedPeriod: () => PreselectedPeriod = () => {
    if (
      timeRecordsFilter.from.isSame(today.from) &&
      timeRecordsFilter.to.isSame(today.to)
    )
      return 'today';
    if (
      timeRecordsFilter.from.isSame(lastWeek.from) &&
      timeRecordsFilter.to.isSame(lastWeek.to)
    )
      return 'lastWeek';
    if (
      timeRecordsFilter.from.isSame(currentWeek.from) &&
      timeRecordsFilter.to.isSame(currentWeek.to)
    )
      return 'currentWeek';
    if (
      timeRecordsFilter.from.isSame(lastMonth.from) &&
      timeRecordsFilter.to.isSame(lastMonth.to)
    )
      return 'lastMonth';
    if (
      timeRecordsFilter.from.isSame(currentMonth.from) &&
      timeRecordsFilter.to.isSame(currentMonth.to)
    )
      return 'currentMonth';
    if (
      timeRecordsFilter.from.isSame(lastYear.from) &&
      timeRecordsFilter.to.isSame(lastYear.to)
    )
      return 'lastYear';
    if (
      timeRecordsFilter.from.isSame(currentYear.from) &&
      timeRecordsFilter.to.isSame(currentYear.to)
    )
      return 'currentYear';
    return 'custom';
  };

  const period: PreselectedPeriod | null =
    timeRecordsFilter.from && timeRecordsFilter.to
      ? matchSelectedPeriod()
      : 'currentWeek';

  const handleReset = () => {
    dispatchTimeRecordsFilter({ filter: 'RESET' });
    setFromToDates([moment().startOf('day'), moment().startOf('day')]);
    onResetAdvancedFilter();
  };

  return (
    <Form layout="vertical" className={classNames(classes.root, className)}>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={8}>
              <Form.Item label={t('common:filter.label.period')}>
                <Select<PreselectedPeriod>
                  className={classes.fullWidth}
                  placeholder={t('common:filter.placeholder.period')}
                  defaultValue="currentWeek"
                  value={period}
                  onChange={handlePeriodChange}
                >
                  <Select.Option value="today">
                    {t('common:filter.today')}
                  </Select.Option>
                  <Select.Option value="currentWeek">
                    {t('common:filter.currentWeek')}
                  </Select.Option>
                  <Select.Option value="lastWeek">
                    {t('common:filter.lastWeek')}
                  </Select.Option>
                  <Select.Option value="currentMonth">
                    {t('common:filter.currentMonth')}
                  </Select.Option>
                  <Select.Option value="lastMonth">
                    {t('common:filter.lastMonth')}
                  </Select.Option>
                  <Select.Option value="currentYear">
                    {t('common:filter.currentYear')}
                  </Select.Option>
                  <Select.Option value="lastYear">
                    {t('common:filter.lastYear')}
                  </Select.Option>
                  <Select.Option value="custom" disabled>
                    {t('common:filter.custom')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={' '}>
                <CustomDateRangePicker
                  disabled={false}
                  startDate={fromToDates[0]} // momentPropTypes.momentObj or null,
                  startDateId="controlling_timeoverview_startDateId" // PropTypes.string.isRequired,
                  startDatePlaceholderText={moment()
                    .add('1', 'day')
                    .startOf('day')
                    .format('DD.MM.YYYY')}
                  endDate={fromToDates[1]} // momentPropTypes.momentObj or null,
                  endDateId="controlling_timeoverview_endDateId"
                  // PropTypes.string.isRequired,
                  endDatePlaceholderText={moment()
                    .add('1', 'day')
                    .startOf('day')
                    .format('DD.MM.YYYY')}
                  onDatesChange={handleFromToChange} // PropTypes.func.isRequired,
                  anchorDirection={'ANCHOR_RIGHT'}
                  small={true}
                  regular={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                  minimumNights={0}
                  isOutsideRange={false}
                />
              </Form.Item>
            </Col>
            <Col span={8} className={classes.lastColumn}>
              <Flex.Row
                flex={1}
                alignItems={'center'}
                justifyContent="flex-end"
                childrenGap={theme.old.spacing.defaultPadding}
              >
                <div>
                  {count > 0 && (
                    <Text>
                      {t('accounting:table.results')}:{' '}
                      {selected > 0 && <Text>{selected}/</Text>}
                      {count}
                    </Text>
                  )}
                </div>
                {(timeRecordsFilter.contactIds ||
                  timeRecordsFilter.companyIds ||
                  timeRecordsFilter.from ||
                  timeRecordsFilter.to ||
                  timeRecordsFilter.projectIds) && (
                  <Button
                    type="link"
                    className={classes.danger}
                    onClick={handleReset}
                    iconProp={['fal', 'trash']}
                  >
                    {t('common:filter.resetFilter')}
                  </Button>
                )}
              </Flex.Row>
            </Col>
          </Row>
          <AdvancedTimeRecordsFilter
            companyFilterValue={currentCompanyFilterValue}
            projectFilterValue={currentProjectFilterValue}
            contactFilterValue={currentContactFilterValue}
            timeRecords={timeRecordsToFilter}
            onProjectChange={onProjectChange}
            onOfficeChange={onOfficeChange}
            onContactChange={onContactChange}
            companyFilter={true}
            projectFilter={true}
            contactFilter={contextType !== 'me'}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default TimeRecordsFilter;
