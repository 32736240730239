import { Form, Input, Switch, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import { ProjectExtensionId, ProjectId } from '../../../../../../models/Types';
import { makePrioStyles } from '../../../../../../theme/utils';
import { colon } from '../../../../../../util/forms';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    position: 'relative',
  },
}));

export interface LinkProjectExtensionFormModel {
  url: string;
  displayName: string;
  hasDefaultAccess: boolean;
}

interface LinkProjectExtensionFormProps {
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export const LinkProjectExtensionForm: React.FC<
  LinkProjectExtensionFormProps
> = (props) => {
  const {
    addProjectExtension,
    projectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;
  const classes = useStyles();
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState<boolean>(false);

  const { t } = useTranslation();

  const saveOrUpdateExtension = async (
    linkProjectExtensionFormModel: LinkProjectExtensionFormModel
  ) => {
    if (projectExtension && !addProjectExtension) {
      let update: UpdateProjectExtension = {
        displayName: linkProjectExtensionFormModel.displayName,
        value: linkProjectExtensionFormModel.url,
        hasDefaultAccess: linkProjectExtensionFormModel.hasDefaultAccess,
        key: null,
      };
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        update
      );
    } else if (addProjectExtension && !projectExtension) {
      setDisabled(true);
      let newProjectExtension: CreateProjectExtension = {
        displayName: linkProjectExtensionFormModel.displayName,
        value: linkProjectExtensionFormModel.url,
        hasDefaultAccess: linkProjectExtensionFormModel.hasDefaultAccess,
        projectExtensionType: 'url',
        key: null,
      };
      await createProjectExtension(projectId, newProjectExtension);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (projectExtension) {
      form.setFieldsValue(projectExtension);
    }
  }, [projectExtension, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      className={classes.root}
      initialValues={{
        displayName: 'Link',
        url: 'https://',
        hasDefaultAccess: true,
      }}
      onFinish={(values) => {
        if (saveOrUpdateExtension) {
          saveOrUpdateExtension(values);
        }
      }}
      onValuesChange={(_, values) => {
        //nothingToDo
      }}
    >
      <Typography.Title level={4}>{t('projects:addIn.link')}</Typography.Title>
      <Form.Item
        name="displayName"
        colon={colon}
        label={t('projects:addIn.extensions.link.form.displayName')}
        required={true}
        rules={[
          {
            required: true,
            message: t('projects:form.validation.displayNameRequired'),
          },
        ]}
      >
        <Input disabled={projectExtension != null || disabled} />
      </Form.Item>

      <Form.Item
        name="url"
        colon={colon}
        label={t('projects:addIn.extensions.link.form.url')}
        required={true}
        rules={[
          {
            required: true,
            message: t('projects:form.validation.urlRequired'),
          },
        ]}
      >
        <Input disabled={projectExtension != null || disabled} />
      </Form.Item>

      <Form.Item
        name="hasDefaultAccess"
        colon={colon}
        label={t('projects:addIn.extensions.form.hasDefaultAccess')}
        required={true}
        rules={[
          {
            required: true,
            message: t('projects:form.validation.hasDefaultAccessRequired'),
          },
        ]}
        valuePropName="checked"
      >
        <Switch disabled={true} />
      </Form.Item>
      {!projectExtension && (
        <Button htmlType="submit" disabled={disabled}>
          {t('projects:addIn.extensions.form.save')}
        </Button>
      )}
    </Form>
  );
};

export default LinkProjectExtensionForm;
