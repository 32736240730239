import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import VirtualTable2, {
  VColumn,
} from '../../../../components/VirtualTable/VirtualTable2';
import ContactText from '../../../contacts/components/ContactText';
import PrioSpinner from '../../../../components/PrioSpinner';
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiRecalculateMonthlyClose } from '../../../timeKeeping/api';
import WaitButton from '../../../../components/WaitButton';
import { MonthlyCloseId, OfficeId } from '../../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa': {
      width: '14px',
    },
  },
  cell: {
    padding: 10,
  },
  row: {
    cursor: 'pointer',
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
}));

interface HRMonthlyClosePageTableProps {
  className?: string;
  tableId: string;
  loading?: boolean;
  onRowClick?: (entry: MonthlyClose) => void;
  monthlyCloses: MonthlyClose[];
  onRowSelectionChange?: (selectedMonthlyClose: MonthlyClose[]) => void;
  onRecalculate?: (
    monthlyClose: MonthlyClose,
    previousId: MonthlyCloseId
  ) => void;
  selectedMonthlyCloses: MonthlyClose[];
  officeId?: OfficeId;
}

export const HRMonthlyClosePageTable: React.FC<HRMonthlyClosePageTableProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    tableId,
    monthlyCloses,
    loading,
    selectedMonthlyCloses,
    officeId,
    onRowClick,
    onRowSelectionChange,
    onRecalculate,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contactsById = useSelector(getContactsByIdState);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSelectionChange = (items: MonthlyClose[]) => {
    if (onRowSelectionChange) {
      onRowSelectionChange(items);
    }
  };

  const handleRecalulate = async (monthlyClose: MonthlyClose) => {
    const { data } = await apiRecalculateMonthlyClose(
      monthlyClose.employeeId,
      officeId
    );
    if (data && onRecalculate) {
      const newMonthlyClose = data.find(({ month }) =>
        moment(month).isSame(moment(monthlyClose.month), 'month')
      );
      if (newMonthlyClose) {
        onRecalculate(newMonthlyClose, monthlyClose.monthlyCloseId);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const noItemsScreen = useCallback(
    () => (
      <div className={classes.noItemsScreen}>
        <div className={classes.noItemsIconContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className={classes.noItemsText}>
                {t('documents:table.noItems')}
              </span>
            }
          />
        </div>
      </div>
    ),
    [classes, t]
  );
  //#endregion

  //#region ------------------------------ Columns
  const columns: VColumn<MonthlyClose>[] = [
    {
      Cell: ({
        row: {
          original: { employeeConfirmation },
        },
      }) => {
        return (
          employeeConfirmation && (
            <div
              title={t('hr:timeAndLeaveManagement.monthlyCloseTable.closed')}
            >
              <FontAwesomeIcon icon={['fal', 'lock']} />
            </div>
          )
        );
      },
      title: () => (
        <div title={t('hr:timeAndLeaveManagement.monthlyCloseTable.closed')}>
          <FontAwesomeIcon icon={['fal', 'lock']} />
        </div>
      ),
      width: 3,
      minWidth: 34,
      id: 'status',
      accessor: 'employeeConfirmation',
      className: classes.cell,
      sortType: (rowA, rowB) => {
        const monthlyCloseA = !!rowA.original.employeeConfirmation;
        const monthlyCloseB = !!rowB.original.employeeConfirmation;
        if (monthlyCloseA === monthlyCloseB) return 0;
        if (monthlyCloseA) return -1;
        return 1;
      },
    },
    {
      id: 'employeeId',
      width: 15,
      title: t('hr:timeAndLeaveManagement.monthlyCloseTable.employeeId'),
      accessor: 'employeeId',
      sortType: (rowA, rowB) => {
        const a = contactsById[rowA.original.employeeId.toLowerCase()];
        const b = contactsById[rowB.original.employeeId.toLowerCase()];
        const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
        if (lastNameCompare !== 0) return lastNameCompare;
        return a?.firstName?.localeCompare(b?.firstName);
      },
      Cell: ({ row: { original } }) => (
        <ContactText contactId={original.employeeId.toLowerCase()} hasTitle />
      ),
      className: classes.cell,
    },
    {
      Cell: (cellProps) => {
        return cellProps.row.original.accumulatedOvertimeHours;
      },
      title: t(
        'hr:timeAndLeaveManagement.monthlyCloseTable.accumulatedOvertimeHours'
      ),
      width: 8,
      id: 'accumulatedOvertimeHours',
      accessor: 'accumulatedOvertimeHours',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.accumulatedOvertimeHours}`;
      },
    },
    {
      Cell: (cellProps) => {
        return cellProps.row.original.overtimeHoursChange;
      },
      title: t(
        'hr:timeAndLeaveManagement.monthlyCloseTable.overtimeHoursChange'
      ),
      width: 10,
      id: 'overtimeHoursChange',
      accessor: 'overtimeHoursChange',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.overtimeHoursChange}`;
      },
    },
    {
      Cell: (cellProps) => {
        return cellProps.row.original.compensationPaymentHours;
      },
      title: t(
        'hr:timeAndLeaveManagement.monthlyCloseTable.compensationPaymentHours'
      ),
      width: 10,
      id: 'compensationPaymentHours',
      accessor: 'compensationPaymentHours',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.compensationPaymentHours}`;
      },
    },
    {
      Cell: (cellProps) => {
        return cellProps.row.original.hoursPerWeek;
      },
      title: t('hr:timeAndLeaveManagement.monthlyCloseTable.hoursPerWeek'),
      width: 9,
      id: 'hoursPerWeek',
      accessor: 'hoursPerWeek',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.hoursPerWeek}`;
      },
    },
    {
      Cell: (cellProps) => {
        return `${cellProps.row.original.actualWorkHours} / ${cellProps.row.original.expectedWorkHours}`;
      },
      title: t(
        'hr:timeAndLeaveManagement.monthlyCloseTable.actualExpectedWorkHours'
      ),
      width: 9,
      id: 'actualWorkHours',
      accessor: 'actualWorkHours',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.actualWorkHours} / ${monthlyClose.expectedWorkHours}`;
      },
    },
    {
      Cell: (cellProps) => {
        return cellProps.row.original.totalWorkingDays;
      },
      title: t('hr:timeAndLeaveManagement.monthlyCloseTable.totalWorkingDays'),
      width: 10,
      id: 'totalWorkingDays',
      accessor: 'totalWorkingDays',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.totalWorkingDays}`;
      },
    },
    {
      Cell: (cellProps) => {
        return cellProps.row.original.absenceDays;
      },
      title: t('hr:timeAndLeaveManagement.monthlyCloseTable.absenceDays'),
      width: 10,
      id: 'absenceDays',
      accessor: 'absenceDays',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return `${monthlyClose.absenceDays}`;
      },
    },
    {
      Cell: (cellProps) => {
        if (cellProps.row.original.overviewCreatedAt?.includes('0001-01-01')) {
          return moment(cellProps.row.original.employeeConfirmationDate).format(
            'DD.MM.YYYY'
          );
        }
        return (
          <Flex.Row alignItems="center" width="100%">
            <Flex.Item
              flex={1}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {moment(cellProps.row.original.overviewCreatedAt).format(
                'DD.MM.YYYY, HH:mm'
              ) + ` ${t('common:moment.aClock')}`}
            </Flex.Item>
            <WaitButton
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await handleRecalulate(cellProps.row.original);
              }}
              iconProp={['fal', 'arrow-rotate-right']}
              style={{ background: 'transparent' }}
              showSpinner
            />
          </Flex.Row>
        );
      },
      title: t('hr:timeAndLeaveManagement.monthlyCloseTable.overviewCreatedAt'),
      width: 15,
      id: 'overviewCreatedAt',
      accessor: 'overviewCreatedAt',
      className: classes.cell,
      cellTitle: (monthlyClose) => {
        return monthlyClose.overviewCreatedAt?.includes('0001-01-01')
          ? t('hr:timeAndLeaveManagement.monthlyCloseTable.closed')
          : moment(monthlyClose.overviewCreatedAt).format('DD.MM.YYYY, HH:mm') +
              ` ${t('common:moment.aClock')}`;
      },
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <VirtualTable2
      id={tableId}
      className={classNames(classes.root, className)}
      columns={columns}
      data={monthlyCloses}
      noItemsScreen={noItemsScreen()}
      loading={
        loading &&
        monthlyCloses.length === 0 && {
          loadingType: 'table',
          indicator: <PrioSpinner alignSelf />,
        }
      }
      onRow={{
        triggerFunctions: (record: MonthlyClose) => ({
          onClick: onRowClick
            ? (event) => {
                event.stopPropagation();
                event.preventDefault();
                onRowClick(record);
              }
            : null,
        }),
      }}
      onChange={onSelectionChange}
      classNameTableRow={(record) => classNames(classes.row)}
      dataToForceRender={monthlyCloses}
      selected={({ monthlyCloseId }) =>
        selectedMonthlyCloses.some(
          ({ monthlyCloseId: mcid }) => mcid === monthlyCloseId
        )
      }
      rowsAreSelectable
      columnsResizable
    />
  );
};

export default HRMonthlyClosePageTable;
