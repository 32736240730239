import { combineReducers, Reducer } from 'redux';
import { Project } from '../../../models/Project';
import {
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_COMMIT,
  CREATE_PROJECT_ROLLBACK,
  CREATE_SUBPROJECT_REQUEST,
  CREATE_SUBPROJECT_COMMIT,
  CREATE_SUBPROJECT_ROLLBACK,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_ROLLBACK,
  FETCH_PROJECTS_COMMIT,
  FETCH_PROJECTS_OVERVIEW_REQUEST,
  FETCH_PROJECTS_OVERVIEW_ROLLBACK,
  FETCH_PROJECTS_OVERVIEW_COMMIT,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_ROLLBACK,
  UPDATE_PROJECT_COMMIT,
  ADD_TO_PROJECT_FAVORITES_REQUEST,
  ADD_TO_PROJECT_FAVORITES_ROLLBACK,
  REMOVE_FROM_PROJECT_FAVORITES_REQUEST,
  REMOVE_FROM_PROJECT_FAVORITES_ROLLBACK,
  UPDATE_PROJECT_STATUS,
  UPDATE_PROJECT_MASTERPLAN_REQUEST,
  UPDATE_PROJECT_MASTERPLAN_ROLLBACK,
  SET_PROJECT_MASTERPLAN,
  FETCH_PROJECT_BY_ID_COMMIT,
} from '../actions';
import { ProjectId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface ProjectsState {
  byId: ProjectByIdState;
  allIds: string[];
  myIds: string[];
  redirects: RedirectState;
  meta: ProjectsMeta;
}

export interface ProjectByIdState {
  [projectId: string]: Project;
}

const byId: Reducer<ProjectByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_OVERVIEW_COMMIT:
    case FETCH_PROJECTS_COMMIT: {
      const { payload } = action;
      return (
        ((payload as Array<Project>) ?? [])
          // .filter((p) => !p?.isArchived)
          .reduce(function (map, item) {
            map[item.projectId] = item;
            return map;
          }, state)
      );
    }
    case FETCH_PROJECT_BY_ID_COMMIT: {
      const { payload } = action;
      return {
        ...state,
        [payload.projectId]: payload,
      };
    }

    /** CREATE */

    case CREATE_PROJECT_REQUEST: {
      const {
        payload: { project },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: {
          projectId: temporaryId,
          ...project,
          isTemporary: true,
        },
      };
    }

    case CREATE_PROJECT_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      // eslint-disable-next-line
      const { [temporaryId]: project_, ...newState } = state;
      return newState;
    }

    case CREATE_PROJECT_COMMIT: {
      const {
        payload: { projectId },
        meta: { temporaryId },
      } = action;
      const currentProject = state[temporaryId];
      if (!currentProject) return state;
      const { [temporaryId]: project, ...newState } = state;
      return {
        ...newState,
        [projectId]: {
          ...project,
          isTemporary: false,
          projectId,
        },
      };
    }

    case CREATE_SUBPROJECT_REQUEST: {
      const {
        payload: { subproject },
        meta: { temporaryId, parentProject },
      } = action;
      return {
        ...state,
        [temporaryId]: {
          projectId: temporaryId,
          isTemporary: true,
          parentProject: parentProject.projectId,
          name: subproject.name,
          number: subproject.subprojectNumber,
          shortName: subproject.shortName,
        },
      };
    }

    case CREATE_SUBPROJECT_COMMIT: {
      const {
        payload,
        meta: { temporaryId },
      } = action;
      const currentProject = state[temporaryId];
      if (!currentProject) return state;
      const { [temporaryId]: _, ...newState } = state;
      return {
        ...newState,
        [payload.projectId]: {
          ...payload,
          isTemporary: false,
        },
      };
    }

    case CREATE_SUBPROJECT_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      // eslint-disable-next-line
      const { [temporaryId]: _, ...newState } = state;
      return newState;
    }

    /** UPDATE */
    case UPDATE_PROJECT_REQUEST: {
      const {
        payload: { project },
      } = action;
      if (!state[project.projectId]) return state;
      return {
        ...state,
        [project.projectId]: {
          ...state[project.projectId],
          ...project,
          isUpdating: true,
        },
      };
    }
    case UPDATE_PROJECT_STATUS: {
      const {
        meta: { projectId },
        payload: { projectStatus },
      } = action;
      if (!state[projectId]) return state;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          projectStatus,
        },
      };
    }

    case UPDATE_PROJECT_ROLLBACK: {
      const {
        meta: { projectId, rollbackState },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...rollbackState,
        },
      };
    }

    case UPDATE_PROJECT_COMMIT: {
      const {
        meta: { projectId },
        payload: updatedProject,
      } = action;
      if (!state[projectId]) return state;
      return {
        ...state,
        [projectId]: {
          ...updatedProject,
          isUpdating: false,
        },
      };
    }

    case REMOVE_FROM_PROJECT_FAVORITES_ROLLBACK:
    case ADD_TO_PROJECT_FAVORITES_REQUEST: {
      const {
        meta: { projectId },
      } = action;
      if (!state[projectId]) return state;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          favorite: true,
        },
      };
    }

    case ADD_TO_PROJECT_FAVORITES_ROLLBACK:
    case REMOVE_FROM_PROJECT_FAVORITES_REQUEST: {
      const {
        meta: { projectId },
      } = action;
      if (!state[projectId]) return state;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          favorite: false,
        },
      };
    }

    case SET_PROJECT_MASTERPLAN:
    case UPDATE_PROJECT_MASTERPLAN_REQUEST: {
      const {
        meta: { projectId },
        payload: { masterPlanId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          masterPlanId: masterPlanId,
        },
      };
    }

    case UPDATE_PROJECT_MASTERPLAN_ROLLBACK: {
      const {
        meta: { projectId, rollbackMasterPlanId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          masterPlanId: rollbackMasterPlanId,
        },
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

const allIds: Reducer<Array<string>, any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_PROJECTS_OVERVIEW_COMMIT: {
      const { payload } = action;
      return (
        ((payload as Array<Project>) ?? [])
          // .filter((p) => !p?.isArchived)
          .map((item) => item.projectId)
      );
    }

    case CREATE_SUBPROJECT_REQUEST:
    case CREATE_PROJECT_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }

    case CREATE_SUBPROJECT_ROLLBACK:
    case CREATE_PROJECT_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      return state.filter((id) => id !== temporaryId);
    }

    case CREATE_SUBPROJECT_COMMIT:
    case CREATE_PROJECT_COMMIT: {
      const {
        payload: { projectId },
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return [...state.filter((id) => id !== temporaryId), projectId];
    }

    case FETCH_PROJECT_BY_ID_COMMIT: {
      const {
        payload: { projectId },
      } = action;
      if (state.includes(projectId)) return state;
      return [...state, projectId];
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }

    default:
      return state;
  }
};

const myIds: Reducer<Array<string>, any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_PROJECTS_COMMIT: {
      const { payload } = action;
      return (
        ((payload as Array<Project>) ?? [])
          // .filter((p) => !p?.isArchived)
          .map((item) => item.projectId)
      );
    }

    case CREATE_SUBPROJECT_REQUEST:
    case CREATE_PROJECT_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }

    case CREATE_SUBPROJECT_ROLLBACK:
    case CREATE_PROJECT_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      return state.filter((id) => id !== temporaryId);
    }

    case CREATE_SUBPROJECT_COMMIT:
    case CREATE_PROJECT_COMMIT: {
      const {
        payload: { projectId },
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return [...state.filter((id) => id !== temporaryId), projectId];
    }

    case FETCH_PROJECT_BY_ID_COMMIT: {
      const {
        meta: { projectId, isProjectMember },
      } = action;
      if (!isProjectMember) return state;
      if (state.includes(projectId)) return state;

      return [...state, projectId];
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }

    default:
      return state;
  }
};

export interface RedirectState {
  [temporaryId: string]: ProjectId;
}
const redirects: Reducer<RedirectState, any> = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SUBPROJECT_COMMIT:
    case CREATE_PROJECT_COMMIT: {
      const {
        payload: { projectId },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: projectId,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

interface ProjectsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ProjectsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECTS_OVERVIEW_REQUEST:
    case FETCH_PROJECTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROJECTS_OVERVIEW_COMMIT:
    case FETCH_PROJECTS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECTS_OVERVIEW_ROLLBACK:
    case FETCH_PROJECTS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'projects:errorMessages.fetchError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byId,

  allIds,
  myIds,
  redirects,
  meta,
});

export const getAllProjects: (state: any) => Array<Project> = (state) => {
  return (state?.myIds ?? [])
    .map((id) => state.byId[id])
    .filter((p) => !!p)
    .sort((a: Project, b: Project) => {
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    });
};
export const getProject: (state: any, projectId: string) => Project = (
  state,
  projectId
) => state.byId[projectId];
export const getProjectByIdState: (state: any) => ProjectByIdState = (state) =>
  state.byId;
export const getRedirect: (state: any, temporaryId: string) => string = (
  state,
  temporaryId
) => state.redirects[temporaryId];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;

export const getProjectsOverview: (state: any) => Array<Project> = (state) => {
  return (state?.allIds ?? [])
    .map((id) => state.byId[id])
    .filter((p) => !!p)
    .sort((a: Project, b: Project) => {
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    });
};

export const getMyProjectIds: (state: ProjectsState) => ProjectId[] = (state) =>
  state.myIds;

export const getAllProjectIds: (state: ProjectsState) => ProjectId[] = (
  state
) => state.allIds;
