import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col, Input, Table, Typography, Divider } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { rowGutter } from '../../../util/forms';
import { useTranslation } from 'react-i18next';
import { EmailString, ProjectId } from '../../../models/Types';
import {
  CreateProjectDistributionList,
  ProjectDistributionContact,
  ProjectDistributionList,
} from '../../../models/ProjectDistributionList';
import { ColumnProps } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import {
  getAllContacts,
  getContactsByIdState,
} from '../../../apps/main/rootReducer';
import { createTemporaryId } from '../../../util';
import { tableTranslations } from '../../../util/table';
import AdressSelect from '../../mail/components/AdressSelect';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
  },
  tabs: {
    overflow: 'visible',
  },
  table: {
    '& .ant-table': {
      background: 'transparent',
    },
    '& .ant-table-thead > tr > th': {
      background: 'transparent',
      borderBottom: theme.old.borders.sub,
      color: theme.old.palette.primaryColor,
      '&:first-child': {
        paddingLeft: theme.old.spacing.unit(1),
      },
      '&:last-child': {
        paddingRight: theme.old.spacing.unit(1),
      },
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-tbody > tr > td': {
      background: 'transparent',
      borderBottom: theme.old.borders.sub,
      '&:first-child': {
        paddingLeft: theme.old.spacing.unit(1),
      },
      '&:last-child': {
        paddingRight: theme.old.spacing.unit(3),
      },
    },
  },
  fullHeight: {
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    width: '100%',
    marginTop: theme.old.spacing.unit(2),
  },
  fullWidth: {
    width: '100%',
  },
  mailColumn: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    cursor: 'pointer',
    '& > td': {
      lineHeight: '24px',
    },
    '& > td:nth-child(3) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(3) > button': {
      visibility: 'visible',
    },
  },
  addContactsRow: {
    marginBottom: theme.old.spacing.unit(2),
  },
  errorTable: {
    color: theme.old.palette.chromaticPalette.red,
  },
  button: {
    background: 'transparent',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  tableRecipients: {
    height: 'calc(100% - 42px - 84px - 49px - 86px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

interface ProjectDistributionListFormProps {
  initialValues?: ProjectDistributionList;
  onFinish: (value: ProjectDistributionList) => void;
  onCancel: () => void;
  onDelete?: (value: ProjectDistributionList) => void;
  cancelLabel?: string;
  actionLabel?: string;
  deleteLabel?: string;
  enableActionButton: boolean;
  disableForm?: boolean;
  projectId?: ProjectId;
}

export const ProjectDistributionListForm: React.FC<
  ProjectDistributionListFormProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    initialValues,
    onCancel,
    onFinish,
    onDelete,
    cancelLabel,
    actionLabel,
    deleteLabel,
    enableActionButton,
    disableForm,
    projectId,
  } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const cancel = () => {
    form.resetFields();
    setShowTableError(false);
    setDistributionContacts([]);
    setName('');
    onCancel();
  };

  const handleOnFinish = (value: CreateProjectDistributionList) => {
    if (!showTableError && onFinish) {
      onFinish({
        projectId: projectId,
        projectDistributionListId:
          initialValues?.projectDistributionListId ?? createTemporaryId(),
        projectDistributionContacts: distributionContacts,
        rowVersion: initialValues?.rowVersion ?? undefined,
        ...value,
      });
    } else {
      setShowTableError(true);
    }
  };

  const [name, setName] = useState<string>(initialValues?.name ?? '');

  const [distributionContacts, setDistributionContacts] = useState<
    ProjectDistributionContact[]
  >(initialValues?.projectDistributionContacts ?? []);

  const emptyValues: CreateProjectDistributionList = useMemo(
    () => ({
      projectId: projectId,
      name: name,
      projectDistributionContacts: distributionContacts,
    }),
    [projectId, name, distributionContacts]
  );

  useEffect(() => {
    setDistributionContacts(initialValues?.projectDistributionContacts ?? []);
    setName(initialValues?.name ?? '');
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue(emptyValues);
  }, [emptyValues, form]);

  const handleOnDelete = () => {
    if (initialValues && onDelete) {
      onDelete(initialValues);
    }
  };

  const [showTableError, setShowTableError] = useState<boolean>(false);

  const addContactsToTable = () => {
    const addedContacts: ProjectDistributionContact[] = mailsToAdd.map(
      (mail) => {
        return {
          contactId: allContacts.find((contact) => contact.eMail === mail)
            ?.contactId,
          eMail: mail,
        };
      }
    );
    setDistributionContacts(distributionContacts.concat(addedContacts));
    setShowTableError(false);

    setMailsToAdd(null);
    setIsFilterEmailPickerShown(false);
  };

  const cancelAddContactsToTable = () => {
    setMailsToAdd(null);
    setIsFilterEmailPickerShown(false);
  };

  const [isFilterEmailPickerShown, setIsFilterEmailPickerShown] =
    useState<boolean>(false);

  const [mailsToAdd, setMailsToAdd] = useState<EmailString[]>(null);

  const allContacts = useSelector(getAllContacts);
  const contactsById = useSelector(getContactsByIdState);

  const columns: ColumnProps<ProjectDistributionContact>[] = [
    {
      title: 'E-Mail',
      dataIndex: 'eMail',
      width: '50%',
      fixed: 'left',
      sorter: (a, b) => a.eMail.localeCompare(b.eMail),
      render: (value) =>
        value && <div className={classes.mailColumn}>{value}</div>,
    },
    {
      title: 'Kontakt',
      dataIndex: 'contactId',
      width: 'calc(50% - 80px)',
      fixed: 'left',
      sorter: (a, b) => a.eMail.localeCompare(b.eMail),
      render: (value) =>
        value && (
          <div>{`${
            contactsById[value]?.firstName
              ? `${contactsById[value]?.firstName} `
              : ''
          }${contactsById[value]?.lastName ?? ''}`}</div>
        ),
    },
    {
      render: (_, record) => <Button iconProp={['fal', 'trash']} type="link" />,
      width: 80,
      fixed: 'right',
    },
  ];

  return (
    <Form<CreateProjectDistributionList>
      className={classes.root}
      onFinish={handleOnFinish}
      initialValues={emptyValues}
      form={form}
      layout="vertical"
      onValuesChange={(changedValues: CreateProjectDistributionList) => {
        setName(changedValues.name);
      }}
    >
      <Flex.Column className={classes.fullHeight}>
        <div style={{ height: '100%', overflow: 'hidden' }}>
          <Typography.Title>
            {`${t('projects:projectDistributionList.form.title')}: ${name}`}
          </Typography.Title>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name={'name'}
                label={t('projects:projectDistributionList.form.details.name')}
                rules={[
                  {
                    required: true,
                    message: t(
                      'projects:projectDistributionList.form.validation.missingName'
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'projects:projectDistributionList.form.details.name'
                  )}
                  disabled={disableForm}
                  value={name}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={rowGutter} className={classes.addContactsRow}>
            <Col span={24}>
              <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
                <div style={{ fontWeight: '600' }}>
                  {t('projects:projectDistributionList.form.contacts.title')}
                </div>
                {!isFilterEmailPickerShown ? (
                  <Button
                    onClick={() => setIsFilterEmailPickerShown(true)}
                    iconProp={['fal', 'plus']}
                  >
                    {t(
                      'projects:projectDistributionList.form.contacts.addContact'
                    )}
                  </Button>
                ) : (
                  <>
                    <AdressSelect
                      changeHandler={setMailsToAdd}
                      className={classes.fullWidth}
                      projectId={projectId}
                      excludedMails={distributionContacts.map(
                        (contact) => contact.eMail
                      )}
                      bordered
                      closeableTags
                    />
                    <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
                      <Flex.Item flex={1} />

                      <Button
                        className={classes.button}
                        onClick={cancelAddContactsToTable}
                        type="default"
                      >
                        {t(
                          'projects:projectDistributionList.form.contacts.cancel'
                        )}
                      </Button>
                      <Button onClick={addContactsToTable}>
                        {t(
                          'projects:projectDistributionList.form.contacts.add'
                        )}
                      </Button>
                    </Flex.Row>
                  </>
                )}
              </Flex.Column>
            </Col>
          </Row>
          <Row
            gutter={rowGutter}
            className={classes.tableRecipients}
            style={{ marginRight: '0px' }}
          >
            <Col span={24}>
              <Table<ProjectDistributionContact>
                className={classes.table}
                rowClassName={classes.row}
                rowKey={(row) => row.eMail}
                columns={columns}
                dataSource={distributionContacts}
                style={{
                  marginBottom: showTableError ? theme.old.spacing.unit(2) : 0,
                }}
                pagination={false}
                sticky={true}
                locale={tableTranslations(t)}
              />

              {showTableError && (
                <Typography.Text className={classes.errorTable}>
                  {t(
                    'projects:projectDistributionList.form.validation.missingEmail'
                  )}
                </Typography.Text>
              )}
            </Col>
          </Row>
        </div>
        <Flex.Item alignSelf="end" className={classes.actionButtonsRow}>
          <Row justify="end">
            <Col span={24}>
              <Flex.Row>
                <Flex.Item flex={1}>
                  {deleteLabel && initialValues && (
                    <Button
                      className={classes.danger}
                      type="default"
                      onClick={handleOnDelete}
                    >
                      {deleteLabel}
                    </Button>
                  )}
                </Flex.Item>
                <Form.Item className={classes.submitButtonFormItem}>
                  <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
                    {onCancel && (
                      <Button
                        className={classes.button}
                        type="default"
                        onClick={cancel}
                      >
                        {cancelLabel}
                      </Button>
                    )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !enableActionButton ||
                        showTableError ||
                        isFilterEmailPickerShown
                      }
                    >
                      {actionLabel}
                    </Button>
                  </Flex.Row>
                </Form.Item>
              </Flex.Row>
            </Col>
          </Row>
        </Flex.Item>
      </Flex.Column>
    </Form>
  );
};

export default ProjectDistributionListForm;
