import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseUpdate,
} from '../../../../models/TimeKeeping';
import { EmployeeId, MonthlyCloseId, OfficeId } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import Flex from '../../../../components/Flex';
import { colon, rowGutter } from '../../../../util/forms';
import {
  apiGetMonthlyCloseEditableId,
  apiReopenMonthlyCloses,
  apiUpdateMonthlyClose,
} from '../../api';
import equals from 'deep-equal';
import { compactDateWithWeekDayFormatString } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .ant-typography.ant-typography-secondary': {
      fontSize: theme.old.typography.fontSize.label,
      paddingBottom: theme.old.spacing.unit(0.5),
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    '& > div:not(:last-child)': {
      marginBottom: theme.old.spacing.defaultPadding,
    },
    '& $overtimeCorrection': {},
  },
  marginBottomWarningPanel: {
    marginBottom: theme.old.spacing.unit(2),
  },
  textField: {},
  horizontalDivider: {
    margin: `0 ${theme.old.spacing.defaultPadding}px`,
    borderLeft: theme.old.borders.content,
  },
  overtimeCorrection: {
    '& .ant-form-item-control-input-content': {
      width: 100,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    '& .ant-form-item-control-input-content::after': {
      content: '"h"',
      marginLeft: 6,
    },
  },
  undoButton: {
    marginLeft: theme.old.spacing.unit(1),
  },
}));

interface HRMonthlyCloseDetailsDrawerProps {
  monthlyClose?: MonthlyClose;
  setOpen: (open: boolean) => void;
  setSelectedMonthlyClose: (value: MonthlyClose) => void;
  officeId?: OfficeId;
  updateMonthlyClose?: (monthlyClose: MonthlyClose) => void;
  onFinish?: (monthlyClose: MonthlyClose) => void;
  reloadMonthlyCloses: () => void;
}

export const HRMonthlyCloseDetailsDrawer: React.FC<
  HRMonthlyCloseDetailsDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    monthlyClose,
    setOpen,
    officeId,
    onFinish,
    setSelectedMonthlyClose,
    reloadMonthlyCloses,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [updateForm] = Form.useForm<MonthlyCloseUpdate>();

  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyCloseDetails: MonthlyCloseUpdate = useMemo(
    () => ({
      overtimeCorrection: monthlyClose?.overtimeCorrection ?? 0,
      notes: monthlyClose?.notes,
    }),
    [monthlyClose]
  );

  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [isEqual, setIsEqual] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningPanelLastMonth, setShowWarningPanelLastMonth] =
    useState<boolean>(false);
  const showWarningPanelNotClosed = !monthlyClose?.employeeConfirmation;

  const [overviewCorrectionDelta, setOverviewCorrectionDelta] =
    useState<number>(0);
  const [monthlyCloseToEdit, setMonthlyCloseToEdit] =
    useState<MonthlyCloseId>(null);
  const isThisMonthlyCloseEditable =
    monthlyClose?.monthlyCloseId === monthlyCloseToEdit;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const _onFinish = async (currentValues: MonthlyCloseUpdate) => {
    setIsSaving(true);
    const { data } = await apiUpdateMonthlyClose(
      officeId,
      currentValues,
      monthlyClose?.monthlyCloseId
    );
    if (data) {
      if (onFinish) {
        onFinish(data);
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t('common:errorMessages.saveChangeError'),
      });
    }
    setIsSaving(false);
  };

  const handleOnCancel = () => {
    setOpen(false);
    setSelectedMonthlyClose(null);
  };

  const handleUndoMonthlyClose = async () => {
    const { result } = await apiReopenMonthlyCloses(
      monthlyClose.monthlyCloseId,
      officeId
    );

    if (result.status <= 200 && result.status < 300) {
      reloadMonthlyCloses();
      setIsClosed(false);
    }
    if (result.status >= 400) {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.errorMessage'
        ),
      });
    }
  };

  const showUndoModal = () => {
    Modal.confirm({
      icon: null,
      title: t('hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.title'),
      content: t(
        'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.content'
      ),
      okText: t('hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.okText'),
      cancelText: t(
        'hr:timeAndLeaveManagement.monthlyCloseForm.undoModal.cancelText'
      ),
      onOk: handleUndoMonthlyClose,
      onCancel() {},
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (monthlyCloseDetails) {
      updateForm.setFieldsValue(monthlyCloseDetails);
    } else {
      updateForm.resetFields();
    }
    setIsEqual(true);
  }, [monthlyCloseDetails, updateForm]);

  useEffect(() => {
    if (monthlyClose) {
      const getFormDisabled = async (employeeId: EmployeeId) => {
        const { data } = await apiGetMonthlyCloseEditableId(employeeId);
        if (data) {
          setMonthlyCloseToEdit(data?.editableMonthlyCloseId);
          if (monthlyClose.monthlyCloseId === data?.editableMonthlyCloseId) {
            setIsClosed(true);
            setShowWarningPanelLastMonth(false);
          } else {
            setIsClosed(false);
            setShowWarningPanelLastMonth(true);
          }
        }
      };
      getFormDisabled(monthlyClose.employeeId);
      setOverviewCorrectionDelta(0);
    }
  }, [monthlyClose, isClosed]);
  //#endregion

  return (
    <div className={classes.root}>
      <Form<MonthlyCloseUpdate>
        initialValues={monthlyCloseDetails}
        form={updateForm}
        onFinish={_onFinish}
        layout="vertical"
        className={classes.form}
        onValuesChange={(changedValues, values: MonthlyCloseUpdate) => {
          if (
            changedValues.overtimeCorrection !== undefined &&
            changedValues.overtimeCorrection !== null
          ) {
            setOverviewCorrectionDelta(
              changedValues.overtimeCorrection -
                (monthlyClose?.overtimeCorrection ?? 0)
            );
          }
          if (equals(values, monthlyCloseDetails)) {
            setIsEqual(true);
          } else {
            setIsEqual(false);
          }
        }}
      >
        <Flex.Column flex={1} overflowY="auto" overflowX="hidden">
          <Flex.Row>
            <Flex.Column flex={1}>
              <Typography.Title level={4}>
                {t('hr:timeAndLeaveManagement.monthlyCloseForm.hoursOverview')}
              </Typography.Title>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.actualWorkHours'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      monthlyClose?.actualWorkHours ?? 0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.expectedWorkHours'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      monthlyClose?.expectedWorkHoursToDate ?? 0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.overtimeCompensationHours'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      monthlyClose?.overtimeCompensationHours ?? 0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.compensationPaymentHours'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      monthlyClose?.compensationPaymentHours ?? 0
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
            </Flex.Column>
            <div className={classes.horizontalDivider} />
            <Flex.Column flex={1}>
              <Typography.Title level={4}>
                {t(
                  'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHours'
                )}
              </Typography.Title>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursWithoutCorrection'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      (monthlyClose?.accumulatedOvertimeHours ?? 0) -
                      (monthlyClose?.overtimeCorrection ?? 0)
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursChangeWithoutCorrection'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      (monthlyClose?.overtimeHoursChange ?? 0) -
                      (monthlyClose?.overtimeCorrection ?? 0)
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    className={classes.overtimeCorrection}
                    name="overtimeCorrection"
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.overtimeCorrection'
                    )}
                    colon={colon}
                    rules={[
                      () => ({
                        async validator(rule, value) {
                          if (value === null) {
                            return Promise.reject(
                              t(
                                'hr:timeAndLeaveManagement.monthlyCloseForm.warning.invalidValue'
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      step={0.25}
                      parser={(value) =>
                        parseFloat(value.replace(',', '.')) as 0.25
                      }
                      disabled={!isClosed || isSaving}
                      defaultValue={monthlyClose?.overtimeCorrection ?? 0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursWithCorrection'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      (monthlyClose?.accumulatedOvertimeHours ?? 0) +
                      overviewCorrectionDelta
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.accumulatedOvertimeHoursChangeWithCorrection'
                    )}
                    colon={colon}
                  >
                    <span className={classes.textField}>{`${
                      (monthlyClose?.overtimeHoursChange ?? 0) +
                      overviewCorrectionDelta
                    } h`}</span>
                  </Form.Item>
                </Col>
              </Row>
            </Flex.Column>
          </Flex.Row>
          <Divider />
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="notes"
                label={t('hr:timeAndLeaveManagement.monthlyCloseForm.notes')}
                colon={colon}
              >
                <Input.TextArea
                  style={{ height: '75px' }}
                  disabled={!isClosed || isSaving}
                  defaultValue={monthlyClose?.notes}
                />
              </Form.Item>
            </Col>
          </Row>
          {monthlyClose?.employeeConfirmation && (
            <Row>
              <Col span={24}>
                <Typography.Text type="secondary">
                  {t('hr:timeAndLeaveManagement.monthlyCloseForm.closedAt')}{' '}
                  {compactDateWithWeekDayFormatString(
                    monthlyClose?.employeeConfirmationDate
                  )}
                </Typography.Text>
                {isThisMonthlyCloseEditable && (
                  <Button
                    onClick={showUndoModal}
                    className={classes.undoButton}
                    suffixIconProp={['fal', 'arrow-turn-down-right']}
                    type="default"
                    style={{ transform: 'rotate(180deg)' }}
                  ></Button>
                )}
              </Col>
            </Row>
          )}
        </Flex.Column>
        {showWarningPanelNotClosed && (
          <Flex.Column
            childrenGap={theme.old.spacing.unit(2)}
            className={classes.marginBottomWarningPanel}
          >
            <Alert
              message={t(
                'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.noEmployeeConfirmation'
              )}
              type="warning"
            />
          </Flex.Column>
        )}
        {showWarningPanelLastMonth && !showWarningPanelNotClosed && (
          <Flex.Column
            childrenGap={theme.old.spacing.unit(2)}
            className={classes.marginBottomWarningPanel}
          >
            <Alert
              message={t(
                'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.notLastClosedMonth'
              )}
              type="warning"
            />
          </Flex.Column>
        )}
        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={isSaving}
            onClick={handleOnCancel}
            type="link"
            style={{ marginRight: '8px' }}
          >
            {t('hr:timeAndLeaveManagement.monthlyCloseForm.cancel')}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={!isClosed || isEqual || isSaving}
          >
            {t('hr:timeAndLeaveManagement.monthlyCloseForm.save')}
          </Button>
        </Flex.Row>
      </Form>
    </div>
  );
};

export default HRMonthlyCloseDetailsDrawer;
