import { PRIO } from '../../../constants';
import { DriveItem, ListItemFieldColumnName } from '../../../models/Drive';
import { MessageAttachment } from '../../../models/Message';
import { DriveItemListItemId } from '../../../models/Types';

export interface OpenCurrentPreviewModalAction {
  type: string;
  visibility: boolean;
  driveItemId: string;
  groupId: string;
  driveItem: DriveItem;
  messageAttachment: MessageAttachment[];
  messageId: string;
  projectId: string;
  isLoading: boolean;
  selectedDriveItems: DriveItem[];
}

export const OPEN_CURRENT_PREVIEW_MODAL = PRIO + 'OPEN_CURRENT_PREVIEW_MODAL';

export const setCurrentPreviewModalVisibility = (
  visibility: boolean,
  driveItemId: string,
  groupId: string,
  driveItem: DriveItem,
  itemList: MessageAttachment[] | DriveItem[],
  pageNumber: number,
  messageId: string,
  projectId: string,
  isLoading: boolean,
  selectedDriveItems: DriveItem[]
) => ({
  type: OPEN_CURRENT_PREVIEW_MODAL,
  visibility,
  driveItemId,
  groupId,
  driveItem,
  itemList,
  pageNumber,
  messageId,
  projectId,
  isLoading,
  selectedDriveItems,
});

export const UPDATE_DRIVE_ITEM_META_DATA_PREVIEW_MODAL =
  PRIO + 'UPDATE_DRIVE_ITEM_META_DATA_PREVIEW_MODAL';

export const updateDriveItemMetaDataPreviewModal = (
  update: Array<{
    listItemId: DriveItemListItemId;
    columnName: ListItemFieldColumnName;
    values: string[];
  }>
) => ({
  type: UPDATE_DRIVE_ITEM_META_DATA_PREVIEW_MODAL,
  update,
});
