import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getIsDocumentMetaDataSaving,
  getSendingMessageIdsMe,
} from '../apps/main/rootReducer';
import { MessageId } from '../models/Types';
import equals from 'deep-equal';
import { createSelector } from 'reselect';
import { DraftsMessagesStateByProjectId } from '../modules/mail/reducers/projects/drafts';
import { ProgressBySessionIdState } from '../modules/documents/reducers/uploadLists';

const sendingMessageIdsProjectsSelector = createSelector<
  [(state: RootReducerState) => DraftsMessagesStateByProjectId],
  MessageId[]
>(
  (state) => state.mail.projects.drafts,
  (drafts) =>
    Object.keys(drafts).reduce((acc, projectId) => {
      const sendingMessageIds = drafts[projectId].sendingMessageIds;
      if (sendingMessageIds) {
        return [...acc, ...sendingMessageIds];
      }
      return acc;
    }, [] as MessageId[])
);

const useMemoSendingMessageIds = () => {
  const [memoizedSendingMessageIdsMe, setMemoizedSendingMessageIdsMe] =
    useState<MessageId[]>([]);
  const [
    memoizedSendingMessageIdsProjects,
    setMemoizedSendingMessageIdsProjects,
  ] = useState<MessageId[]>([]);

  const sendMessageIdsProjects = useSelector<RootReducerState, MessageId[]>(
    sendingMessageIdsProjectsSelector
  );
  const sendMessageIdsMe = useSelector<RootReducerState, MessageId[]>((state) =>
    getSendingMessageIdsMe(state)
  );

  useEffect(() => {
    if (!equals(memoizedSendingMessageIdsMe, sendMessageIdsMe)) {
      setMemoizedSendingMessageIdsMe(sendMessageIdsMe);
    }
  }, [sendMessageIdsMe, memoizedSendingMessageIdsMe]);

  useEffect(() => {
    if (!equals(memoizedSendingMessageIdsProjects, sendMessageIdsProjects)) {
      setMemoizedSendingMessageIdsProjects(sendMessageIdsProjects);
    }
  }, [sendMessageIdsProjects, memoizedSendingMessageIdsProjects]);

  return useMemo(() => {
    return [
      ...memoizedSendingMessageIdsMe,
      ...memoizedSendingMessageIdsProjects,
    ];
  }, [memoizedSendingMessageIdsMe, memoizedSendingMessageIdsProjects]);
};

const uploadProgressAllGroupsSelector = createSelector<
  [(state: RootReducerState) => ProgressBySessionIdState],
  boolean
>(
  (state) => state.documents.uploadLists.progressBySessionId,
  (progressBySessionId) => {
    const sessionIds = Object.keys(progressBySessionId);
    if (sessionIds.length === 0) {
      return false;
    }
    const sizeSum = sessionIds.reduce((sum, sessionId) => {
      const progressByFileId = progressBySessionId[sessionId];
      const fileIds = Object.keys(progressByFileId);
      if (fileIds.length === 0) {
        return sum;
      }
      const sizeSum = fileIds.reduce((sum, fileId) => {
        const { size } = progressByFileId[fileId];
        return sum + size;
      }, 0);
      return sum + sizeSum;
    }, 0);

    const uploadedSizeSum = sessionIds.reduce((sum, sessionId) => {
      const progressByFileId = progressBySessionId[sessionId];
      const fileIds = Object.keys(progressByFileId);
      if (fileIds.length === 0) {
        return sum;
      }
      const uploadedSizeSum = fileIds.reduce((sum, fileId) => {
        const { uploadedSize } = progressByFileId[fileId];
        return sum + uploadedSize;
      }, 0);
      return sum + uploadedSizeSum;
    }, 0);
    return uploadedSizeSum < sizeSum;
  }
);

const useDriveItemUploads = () => {
  const isPending = useSelector(uploadProgressAllGroupsSelector);
  return useMemo(() => {
    return isPending;
  }, [isPending]);
};

const useDocumentMetaDataUpdate = () => {
  const isUpdatingState = useSelector(getIsDocumentMetaDataSaving);
  return useMemo(() => {
    const isUpdating = Object.values(isUpdatingState).some(
      (isUpdatingDriveItem) => isUpdatingDriveItem === true
    );
    return isUpdating;
  }, [isUpdatingState]);
};

const useBeforeUnloadBrowser = () => {
  const memoizedSendingMessageIds = useMemoSendingMessageIds();
  const isUploading = useDriveItemUploads();
  const isDocumentMetaDataSaving = useDocumentMetaDataUpdate();

  const handleClose = useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  useEffect(() => {
    if (
      memoizedSendingMessageIds.length > 0 ||
      isUploading ||
      isDocumentMetaDataSaving
    ) {
      window.addEventListener('beforeunload', handleClose);
    } else {
      window.removeEventListener('beforeunload', handleClose);
    }
    return () => {
      window.removeEventListener('beforeunload', handleClose);
    };
  }, [
    memoizedSendingMessageIds.length,
    isUploading,
    isDocumentMetaDataSaving,
    handleClose,
  ]);

  return useMemo(() => {
    return (
      memoizedSendingMessageIds.length > 0 ||
      isUploading ||
      isDocumentMetaDataSaving
    );
  }, [memoizedSendingMessageIds.length, isUploading, isDocumentMetaDataSaving]);
};

export default useBeforeUnloadBrowser;
