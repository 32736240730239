import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompanyId,
  DocumentTemplateCategoryId,
  DocumentTemplateId,
} from '../../../../models/Types';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { Col, Collapse, Form, Row } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import CompanyPicker from '../../../companies/components/CompanyPicker';
import DocumentTemplateCategoryPicker from '../DocumentTemplateCategoryPicker';
import DocumentTemplatePicker from '../DocumentTemplatePicker';
import { rowGutter } from '../../../../util/forms';
import { useDispatch } from 'react-redux';
import { fetchDocumentTemplates } from '../../actions/documentTemplates';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  newDocument: {
    height: 'calc(100% - 294px)',
  },
  rightPanel: {
    borderLeft: theme.old.borders.sub,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  collapsablePanel: {
    '& .ant-collapse-header': {
      fontWeight: '600',
    },
  },
  centerButton: {
    margin: 'auto 0',
  },
  justifyBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

interface DocumentTemplateFormSelectorProps {
  className?: string;
  children?: React.ReactNode;
  isSaving: boolean;
  showOnlyReports?: boolean;
  onTemplateIdChange?: (documentTemplateId: DocumentTemplateId) => void;
  onCancel: () => void;
  onChange?: () => void;
  cancelable?: boolean;
}

export const DocumentTemplateFormSelector: React.FC<
  DocumentTemplateFormSelectorProps
> = (props) => {
  const {
    className,
    children,
    isSaving,
    showOnlyReports,
    onTemplateIdChange,
    onCancel,
    onChange,
    cancelable,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [internalCompanyId, setInternalCompanyId] = useState<CompanyId>(null);
  const [categoryId, setCategoryId] = useState<DocumentTemplateCategoryId>();
  const [templateId, setTemplateId] = useState<DocumentTemplateId>();

  // states

  const internalCompanyChange = (companyId: string | string[]) => {
    setTemplateId(null);
    if (companyId === 'empty') {
      setInternalCompanyId(null);
    } else {
      setInternalCompanyId(companyId as string);
    }
  };
  const handleTemplateChange = (templateId: DocumentTemplateId) => {
    setTemplateId(templateId);
    if (onTemplateIdChange) {
      onTemplateIdChange(templateId);
    }
  };

  const reloadDocumentTemplates = () => {
    dispatch(fetchDocumentTemplates());
  };

  useEffect(() => {
    dispatch(fetchDocumentTemplates());
  }, [dispatch]);

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      id={'prio-project-document-template-selector'}
    >
      <Form layout="vertical">
        <Collapse
          ghost
          className={classes.collapse}
          defaultActiveKey={['1']}
          onChange={onChange}
        >
          <Collapse.Panel
            header={t('documents:newDocumentForm.labels.templateChoice')}
            key="1"
            className={classes.collapsablePanel}
          >
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={12}>
                <Form.Item
                  label={t('documents:newDocumentForm.labels.subsidiary')}
                >
                  <CompanyPicker
                    companyType="InternalCompany"
                    label={t(
                      'documents:newDocumentForm.placeholders.subsidiary'
                    )}
                    value={internalCompanyId ?? 'empty'}
                    onChange={internalCompanyChange}
                    disabled={isSaving}
                    emptyLabel={t('common:all')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('documents:newDocumentForm.labels.category')}
                >
                  <DocumentTemplateCategoryPicker
                    onChange={setCategoryId}
                    disabled={isSaving}
                    emptyLabel={t('common:all')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={23}>
                <Form.Item
                  label={t('documents:newDocumentForm.labels.template')}
                >
                  <DocumentTemplatePicker
                    value={templateId}
                    onChange={handleTemplateChange}
                    showSearch
                    placeholder={t(
                      'documents:newDocumentForm.placeholders.template'
                    )}
                    emptyMessage={t(
                      'documents:newDocumentForm.noMatchingTemplate'
                    )}
                    filter={(template) =>
                      ((showOnlyReports === true
                        ? template.isReport
                        : !template.isReport && !categoryId) ||
                        template.categories.includes(categoryId)) &&
                      (!internalCompanyId ||
                        template.companies.includes(internalCompanyId))
                    }
                    disabled={isSaving}
                  />
                </Form.Item>
              </Col>
              <Col span={1} className={classes.centerButton}>
                <Button
                  type="link"
                  size="small"
                  iconProp={['fal', 'sync']}
                  onClick={reloadDocumentTemplates}
                ></Button>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Form>

      <Flex.Column flex={1}>{children}</Flex.Column>
      {cancelable && (
        <Flex.Row
          style={{ marginTop: theme.old.spacing.unit(3), width: '100%' }}
        >
          <Flex.Item flex={1} className={classes.justifyBetween}>
            <Button
              type="default"
              onClick={onCancel}
              style={{ marginRight: '8px' }}
            >
              {t('common:actions.cancel')}
            </Button>
            <Button disabled={true}>{t('common:actions.save')}</Button>
          </Flex.Item>
        </Flex.Row>
      )}
    </Flex.Column>
  );
};

export default DocumentTemplateFormSelector;
