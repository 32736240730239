import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Modal, Row } from 'antd';
import { rowGutter } from '../../../../util/forms';
import { Project } from '../../../../models/Project';
import { useForm } from 'antd/lib/form/Form';
import { apiFetchConfigurations } from '../../../settings/api';
import { ConfigurationKeys } from '../../../../models/Types';
import {
  createTemporaryId,
  defaultProjectNrPlaceholder,
} from '../../../../util';
import { useDispatch } from 'react-redux';
import { createSubproject, setActiveProjectTabView } from '../../actions';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface CreateSubprojectModalProps {
  visible: boolean;
  project: Project;
  onClose: VoidFunction;
}

export const CreateSubprojectModal: React.FC<CreateSubprojectModalProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { visible, project, onClose } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const initialValues = useMemo(
    () => ({
      name: '',
      subprojectNumber: `${project.number}-`,
      shortName: '',
    }),
    [project]
  );

  const [projectNrPlaceholder, setProjectNumberPlaceholder] =
    useState<string>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOk = () => {
    form.validateFields().then((values) => {
      dispatch(createSubproject(values, project, createTemporaryId()));
      dispatch(setActiveProjectTabView('myProjects'));
      handleClose();
    });
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) {
        const config = data.find(
          (configuration) =>
            configuration.key === ConfigurationKeys.PLACEHOLDER_PROJECTNR
        );
        setProjectNumberPlaceholder(config?.value ?? null);
      }
    };
    loadConfigs();
  }, []);
  //#endregion

  return (
    <Modal
      visible={visible}
      className={classes.root}
      onOk={handleOk}
      onCancel={handleClose}
      okText={t('projects:createSubprojectModal.okText')}
      cancelText={t('projects:createSubprojectModal.cancelText')}
      title={t('projects:createSubprojectModal.title')}
      destroyOnClose
    >
      <Form form={form} initialValues={initialValues} layout="vertical">
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              label={t('projects:createSubprojectModal.labels.number')}
              name="subprojectNumber"
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.missingNumber'),
                },
                () => ({
                  async validator(rule, value) {
                    if (
                      value === initialValues.subprojectNumber ||
                      value === project.number
                    ) {
                      return Promise.reject(
                        t('projects:form.validation.invalidNumber')
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                placeholder={
                  projectNrPlaceholder ?? defaultProjectNrPlaceholder
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              label={t('projects:createSubprojectModal.labels.name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.missingName'),
                },
                () => ({
                  async validator(rule, value) {
                    if (value === project.name) {
                      return Promise.reject(
                        t('projects:form.validation.invalidName')
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('projects:createSubprojectModal.labels.shortName')}
              name="shortName"
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.missingShortName'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateSubprojectModal;
