import React, { ReactNode } from 'react';
import CompaniesProvider from '../companies/components/CompaniesProvider';
import ContactsProvider from '../contacts/components/ContactsProvider';
import InternalProjectContactsProvider from '../projects/components/InternalProjectContactsProvider';
import ProjectsProvider from '../projects/components/ProjectsProvider';
import OfficesProvider from '../companies/components/OfficeProvider';

interface MainProviderProps {
  children?: ReactNode;
}

export const MainProvider: React.FC<MainProviderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <ProjectsProvider>
      <CompaniesProvider>
        <ContactsProvider>
          <OfficesProvider>
            <InternalProjectContactsProvider>
              {children}
            </InternalProjectContactsProvider>
          </OfficesProvider>
        </ContactsProvider>
      </CompaniesProvider>
    </ProjectsProvider>
  );
};

export default MainProvider;
