import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& h1.ant-typography, .ant-typography h1': {
      margin: 0,
    },
  },
  title: {
    lineHeight: '44px',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: 0,
  },
  menu: {
    background: 'none',
    border: 'none',
    height: '100%',
    padding: `0 ${theme.old.spacing.defaultPadding}px`,
    width: '320px',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface TimeAndLeaveManagementMenuProps {
  selectedList?: string;
  className?: string;
}

export const TimeAndLeaveManagementMenu: React.FC<
  TimeAndLeaveManagementMenuProps
> = (props) => {
  const { className, selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <Typography.Title className={classes.title}>
        {t('projects:timeAndLeaveManagementMenu.title')}
      </Typography.Title>
      <Menu
        className={classes.menu}
        selectedKeys={[selectedList]}
        mode="inline"
        inlineIndent={12}
      >
        <Menu.Item key="summary">
          <Link
            to={`../summary?month=${moment().toISOString().substring(0, 7)}`}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'tachometer-alt-fast']}
              className={classes.icon}
            />
            {t('projects:timeAndLeaveManagementMenu.navigation.summary')}
          </Link>
        </Menu.Item>
        <Menu.Item key="timeRecords">
          <Link to="../timeRecords">
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'user-clock']}
              className={classes.icon}
            />
            {t('projects:timeAndLeaveManagementMenu.navigation.timeRecords')}
          </Link>
        </Menu.Item>
        <Menu.Item key="absences">
          <Link to="../absences">
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'island-tropical']}
              className={classes.icon}
            />
            {t('projects:timeAndLeaveManagementMenu.navigation.absences')}
          </Link>
        </Menu.Item>
        <Menu.Item key="absencesOverview">
          <Link to="../absencesOverview">
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'map']}
              className={classes.icon}
            />
            {t(
              'projects:timeAndLeaveManagementMenu.navigation.absencesOverview'
            )}
          </Link>
        </Menu.Item>
      </Menu>
    </Flex.Column>
  );
};
