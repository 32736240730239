import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { colon, rowGutter } from '../../../../util/forms';
import { makePrioStyles } from '../../../../theme/utils';
import { Employee } from '../../../../models/Employee';
import { EmployeeId } from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { bicPattern, ibanPattern } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  bankDataBoxLeft: {
    paddingRight: theme.old.spacing.unit(1),
  },
}));

interface BankDataFormModel {
  iban?: string;
  bic?: string;
}

interface PersonnelFileBankDataProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const PersonnelFileBankData: React.FC<PersonnelFileBankDataProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { employeeId, employee, isSaving, patchEmployee } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [bankDataForm] = Form.useForm<BankDataFormModel>();
  const [editModeBankData, setEditModeBankData] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const editBankData = () => {
    setEditModeBankData(true);
  };
  const cancelEditBankData = () => {
    bankDataForm.setFieldsValue({
      iban: employee.iban,
      bic: employee.bic,
    });
    setEditModeBankData(false);
  };
  const submitBankDataForm = () => {
    bankDataForm.submit();
  };

  const handleFinishBankData = async (bankData: BankDataFormModel) => {
    const patchData: Employee = {
      ...bankData,
    };
    await patchEmployee(patchData).then(() => setEditModeBankData(false));
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setEditModeBankData(false);
  }, [employeeId]);

  useEffect(() => {
    if (employee) {
      bankDataForm.setFieldsValue({
        iban: employee.iban,
        bic: employee.bic,
      });
    }
  }, [employee, bankDataForm]);
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Form<BankDataFormModel>
        form={bankDataForm}
        onFinish={handleFinishBankData}
        layout="vertical"
        className={classes.form}
      >
        <Flex.Row flex={1}>
          <Flex.Column
            flex={2}
            childrenGap={theme.old.spacing.unit(1)}
            className={classes.bankDataBoxLeft}
          >
            <Row gutter={rowGutter}>
              <Col
                span={12}
                style={{ paddingRight: theme.old.spacing.unit(1) }}
              >
                <Form.Item
                  name="iban"
                  label={t('hr:personnelFile.form.labels.iban')}
                  colon={colon}
                  rules={[
                    {
                      message: t(
                        'hr:personnelFile.form.validation.invalidIban'
                      ),
                      pattern: ibanPattern,
                    },
                  ]}
                >
                  <Input disabled={!editModeBankData || isSaving} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="bic"
                  label={t('hr:personnelFile.form.labels.bic')}
                  colon={colon}
                  rules={[
                    {
                      message: t('hr:personnelFile.form.validation.invalidBic'),
                      pattern: bicPattern,
                    },
                  ]}
                >
                  <Input disabled={!editModeBankData || isSaving} />
                </Form.Item>
              </Col>
            </Row>
          </Flex.Column>
        </Flex.Row>
      </Form>
      <Flex.Column>
        <Flex.Item flex={1} />
        <Flex.Row alignSelf="flex-end" childrenGap={theme.old.spacing.unit(1)}>
          {editModeBankData && (
            <Button
              type="default"
              onClick={cancelEditBankData}
              disabled={isSaving}
              style={{ marginRight: theme.old.spacing.unit(1) }}
            >
              {t('common:actions.cancel')}
            </Button>
          )}
          <Button
            type={editModeBankData ? 'primary' : 'link'}
            onClick={editModeBankData ? submitBankDataForm : editBankData}
            disabled={isSaving}
          >
            {editModeBankData ? t('common:save') : t('common:edit')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default PersonnelFileBankData;
