declare global {
  interface Window {
    REACT_APP_API_HOST_NAME: string;
    REACT_APP_API_REDIRECT_URL: string;
    REACT_APP_API_REDIRECT_URL_POST: string;
    REACT_APP_ZENDESK_API_HOST_NAME: string;
    REACT_APP_WS_DISABLED: string;
    REACT_APP_API_WS_URL: string;
    REACT_APP_ENVIRONMENT: string;
    CLIENT_ID: string;
    AUTHORITY: string;
    SCOPES: string;
    SUPPORT_EMAIL: string;
    REACT_APP_CONTACT_OFFICE_CHANGE: string;
    FLUTTER_APP_CLIENT_ID: string;
    FLUTTER_APP_TENANT_NAME: string;
    RETEACH_URL: string;
  }
}

export const REACT_APP_CONTACT_OFFICE_CHANGE =
  window.REACT_APP_CONTACT_OFFICE_CHANGE;
export const FLUTTER_APP_TENANT_NAME = window.FLUTTER_APP_TENANT_NAME;
export const FLUTTER_APP_CLIENT_ID = window.FLUTTER_APP_CLIENT_ID;
export const REACT_APP_API_HOST_NAME = window.REACT_APP_API_HOST_NAME;
export const REACT_APP_API_REDIRECT_URL = window.REACT_APP_API_REDIRECT_URL;
export const REACT_APP_API_REDIRECT_URL_POST =
  window.REACT_APP_API_REDIRECT_URL_POST;
export const REACT_APP_ZENDESK_API_HOST_NAME =
  window.REACT_APP_ZENDESK_API_HOST_NAME;
export const REACT_APP_WS_DISABLED = window.REACT_APP_WS_DISABLED;
export const REACT_APP_API_WS_URL = window.REACT_APP_API_WS_URL;
export const REACT_APP_ENVIRONMENT = window.REACT_APP_ENVIRONMENT;
export const CLIENT_ID = window.CLIENT_ID;
export const AUTHORITY = window.AUTHORITY;
export const SCOPES = window.SCOPES;
export const SUPPORT_EMAIL = window.SUPPORT_EMAIL;
export const RETEACH_URL = window.RETEACH_URL;
