import React from 'react';
import AbsenceProposalForm from './AbsenceProposalForm';
import {
  AbsenceOverview,
  AbsenceProposal,
  AbsenceProposalRequest,
  UpdateAbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeAbsenceProposal,
  debounceAbsencesMe,
} from '../../absences/actions';
import { getUserMe } from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import Title from 'antd/lib/typography/Title';
import { fullDateFormatFormatString } from '../../../util';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
  },
}));

interface UpdateAbsenceProposalProps {
  className?: string;
  absenceProposal: AbsenceProposal;
  absenceOverview: AbsenceOverview;
  onFinish?: VoidFunction;
  setDrawerOpen?: (open: boolean) => void;
}

export const UpdateAbsenceProposal: React.FC<UpdateAbsenceProposalProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { absenceProposal, absenceOverview, onFinish, setDrawerOpen } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const updateAbsenceProposal = async (request: AbsenceProposalRequest) => {
    const updateAbsenceProposalRequest: UpdateAbsenceProposalRequest = {
      substituteId: request.substituteId,
      notifyContactIds: request.notifyContactIds,
      from: request.from,
      to: request.to,
      firstIsHalfDay: request.firstIsHalfDay,
      lastIsHalfDay: request.lastIsHalfDay,
      note: request.note,
      rowVersion: absenceProposal.rowVersion,
    };
    dispatch(
      changeAbsenceProposal(
        updateAbsenceProposalRequest,
        absenceProposal.absenceProposalId,
        absenceProposal,
        userMe.id.toLowerCase() === absenceProposal.applicantId.toLowerCase()
      )
    );

    if (userMe.id.toLowerCase() === absenceProposal.applicantId.toLowerCase()) {
      dispatch(debounceAbsencesMe());
    }

    if (onFinish) {
      onFinish();
    }
  };

  const generateHeading = (absenceProposal?: AbsenceProposal) => {
    if (!absenceProposal) {
      return '';
    }
    const from = absenceProposal.from;
    const to = absenceProposal.to;

    const fromAsArray = fullDateFormatFormatString(from).split(' ');

    const isOneDay = moment(from).isSame(moment(to), 'day');
    const withinSameYear = moment(from).isSame(moment(to), 'year');

    return t(
      `absences:absenceOverview.detailsDrawerTitle${
        isOneDay ? 'Short' : 'Long'
      }Date`,
      {
        type: t(`absences:types.${absenceProposal?.absenceType}`),
        from: withinSameYear
          ? `${fromAsArray[0]} ${fromAsArray[1]}`
          : fullDateFormatFormatString(from),
        to: fullDateFormatFormatString(to),
      }
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column>
      <Flex.Item>
        <Title level={2}>{generateHeading(absenceProposal)}</Title>
      </Flex.Item>
      <Flex.Item flex={1}>
        <AbsenceProposalForm
          className={classes.root}
          actionLabel={
            absenceProposal?.absenceState === 'requested' ||
            absenceProposal?.absenceState === 'planned'
              ? t('absences:updateAbsenceProposal.action')
              : ''
          }
          onFinish={updateAbsenceProposal}
          initialValues={absenceProposal}
          canSelectApplicant={false}
          absenceOverview={absenceOverview}
          disableForm={
            absenceProposal?.absenceState !== 'requested' &&
            absenceProposal?.absenceState !== 'planned'
          }
          editMode
          isUpdateAbsenceProposal
          absenceState={absenceProposal?.absenceState}
          setDrawerOpen={setDrawerOpen}
        />
      </Flex.Item>
    </Flex.Column>
  );
};

export default UpdateAbsenceProposal;
