import React, { useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { TrainingRequest } from '../../../models/Training';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { TrainingId } from '../../../models/Types';
import { getUserMe } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import { apiCreateTrainingRequests } from '../../hr/api';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    paddingLeft: theme.old.spacing.defaultPadding,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  title: {
    '&.ant-typography': {
      marginBottom: theme.old.spacing.unit(1.5),
    },
  },
  accepted: {
    '&.ant-typography': {
      color: theme.old.palette.chromaticPalette.green,
    },
    color: theme.old.palette.chromaticPalette.green,
  },
  request: {
    '&.ant-typography': {
      color: theme.old.palette.chromaticPalette.grey,
    },
    color: theme.old.palette.chromaticPalette.grey,
  },
  declined: {
    '&.ant-typography': {
      color: theme.old.palette.chromaticPalette.red,
    },
    color: theme.old.palette.chromaticPalette.red,
  },
}));

interface TrainingRequestModuleProps {
  trainingId: TrainingId;
  myTrainingRequest?: TrainingRequest;
}

export const TrainingRequestModule: React.FC<TrainingRequestModuleProps> = (
  props
) => {
  const { myTrainingRequest, trainingId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const userMe = useSelector(getUserMe);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [createdTrainingRequest, setCreatedTrainingRequest] =
    useState<TrainingRequest | null>();

  const submitTrainingRequest = async () => {
    setIsRequesting(true);

    const { result, data } = await apiCreateTrainingRequests(trainingId, [
      userMe.id,
    ]);

    if (result.status >= 200 && result.status < 300) {
      if (Array.isArray(data) && data.length === 1) {
        setCreatedTrainingRequest(data[0]);
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'profile:training.trainingRequest.errorMessages.createTrainingRequest'
        ),
      });
    }
    setIsRequesting(false);
  };

  const trainingRequest = myTrainingRequest ?? createdTrainingRequest;

  return (
    <Flex.Column className={classes.root}>
      <Typography.Title level={3} className={classes.title}>
        Anmeldung
      </Typography.Title>
      {trainingRequest == null ? (
        <Button
          iconProp={['fal', 'user-plus']}
          onClick={submitTrainingRequest}
          disabled={!userMe || isRequesting}
        >
          <span>{t('profile:training.trainingRequest.sendRequest')}</span>
        </Button>
      ) : trainingRequest.trainingRequestState === 'requested' ? (
        <div className={classes.request}>
          {applicationInfoItem(
            'paper-plane',
            t('profile:training.trainingRequest.requested'),
            classes
          )}
        </div>
      ) : trainingRequest.trainingRequestState === 'accepted' ? (
        <div className={classes.accepted}>
          {applicationInfoItem(
            'check',
            t('profile:training.trainingRequest.confirmedAt') +
              ' ' +
              moment(trainingRequest.registrationDate).format('DD. MMMM YYYY'),
            classes
          )}
        </div>
      ) : trainingRequest.trainingRequestState === 'declined' ? (
        <div className={classes.declined}>
          {applicationInfoItem(
            'times',
            t('profile:training.trainingRequest.declined'),
            classes
          )}
        </div>
      ) : (
        <p>toDo</p>
      )}
    </Flex.Column>
  );
};

export default TrainingRequestModule;

const applicationInfoItem = (
  icon: IconName,
  text: string,
  classes: Classes
) => (
  <div>
    <FontAwesomeIcon fixedWidth icon={['fal', icon]} className={classes.icon} />
    {text}
  </div>
);
