import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import * as ConfigValues from '../../../util/configValues';
import { apiUrl } from '../../../api';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiFetchConfigurationByKey } from '../api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(3)}px`,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  extensionStatus: {
    padding: theme.old.spacing.unit(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.old.spacing.unit(1),
  },
  installExtensionTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.old.spacing.unit(0.5),
  },
  extensionInfo: {
    width: '100%',
    boxShadow: theme.old.palette.boxShadow.regular,
    display: 'flex',
    flexDirection: 'start',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  signOutFromExtensionButton: {
    padding: 0,
  },
  extensionInstalationStatusTextRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.old.spacing.unit(1),
    alignItems: 'center',
  },
  validCheckMark: {
    color: theme.old.palette.chromaticPalette.green,
  },
  invalidCheckMark: {
    color: theme.old.palette.chromaticPalette.red,
  },
}));

interface PrioExtensionSettingsPageProps {
  className?: string;
}

interface CustomWindowMessageEvent<T> extends MessageEvent {
  data: {
    type: string;
    message: T;
  };
}

interface ExtensionInfo {
  extensionId: string;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

interface ExtensionConfig {
  tenantId: string;
  clientId: string;
  scope: string;
  apiUrl: string;
}

export const PrioExtensionSettingsPage: React.FC<
  PrioExtensionSettingsPageProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [extensionInfo, setExtensionInfo] = useState<ExtensionInfo>(null);
  const [extensionClientId, setExtensionClientId] = useState<string>();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const checkExtension = async () => {
    window.postMessage({ type: 'CheckExtension' }, '*');
  };

  const onSignOutFromExtension = () => {
    window.postMessage({ type: 'SignOut' }, '*');
  };

  const onSignInToExtension = () => {
    window.postMessage({ type: 'SignIn' }, '*');
  };
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const onCheckResponseReceived = (
      event: CustomWindowMessageEvent<ExtensionInfo>
    ) => {
      if (event.data.type === 'CheckExtensionResponse') {
        setExtensionInfo(event.data.message);
      }
    };
    window.addEventListener('message', onCheckResponseReceived);

    return () => {
      window.removeEventListener('message', onCheckResponseReceived);
    };
  }, []);

  useEffect(() => {
    const onUpdateExtensionInfo = (
      event: CustomWindowMessageEvent<ExtensionInfo>
    ) => {
      if (event.data.type === 'UpdateExtensionInfo') {
        setExtensionInfo(event.data.message);
      }
    };
    window.addEventListener('message', onUpdateExtensionInfo);
    return () => {
      window.removeEventListener('message', onUpdateExtensionInfo);
    };
  }, []);

  useEffect(() => {
    const fetchExtensionClientId = async () => {
      const { data } = await apiFetchConfigurationByKey(
        'ChromeExtensionClientId'
      );
      setExtensionClientId(data?.value);
    };
    fetchExtensionClientId();
  }, []);

  useEffect(() => {
    checkExtension();
  }, []);

  useEffect(() => {
    const configureExtension = () => {
      const message: ExtensionConfig = {
        tenantId: ConfigValues.AUTHORITY.split('/').at(-1),
        clientId: extensionClientId,
        scope: ConfigValues.SCOPES,
        apiUrl: apiUrl,
      };
      window.postMessage({ type: 'ConfigureExtension', message }, '*');
    };

    if (extensionInfo && extensionClientId) {
      configureExtension();
    }
  }, [extensionClientId, extensionInfo]);

  //#endregion

  //#region -------------------------------- Components
  const renderExtensionSettings = () => {
    if (extensionInfo && extensionClientId) {
      return (
        <>
          <div className={classes.extensionInstalationStatusTextRow}>
            <FontAwesomeIcon
              icon={['fas', 'circle-check']}
              className={classes.validCheckMark}
            />
            <Typography.Text>
              {`${t(`profile:prioExtension.installed`)}`}
            </Typography.Text>
          </div>
          {extensionInfo.isAuthenticated ? (
            <>
              <div className={classes.extensionInstalationStatusTextRow}>
                <FontAwesomeIcon
                  icon={['fas', 'circle-check']}
                  className={classes.validCheckMark}
                />
                <Typography.Text>
                  {`${t(`profile:prioExtension.loggedIn`)}`}
                </Typography.Text>
              </div>
              <Button
                className={classes.signOutFromExtensionButton}
                onClick={onSignOutFromExtension}
                iconProp={['fas', 'sign-out-alt']}
                type="default"
              >
                {`${t(`profile:prioExtension.logOut`)}`}
              </Button>
            </>
          ) : (
            <>
              <div className={classes.extensionInstalationStatusTextRow}>
                <FontAwesomeIcon
                  icon={['fas', 'circle-xmark']}
                  className={classes.invalidCheckMark}
                />
                <Typography.Text>
                  {`${t(`profile:prioExtension.loggedOut`)}`}
                </Typography.Text>
              </div>
              <Button
                className={classes.signOutFromExtensionButton}
                onClick={onSignInToExtension}
                iconProp={['fas', 'sign-in-alt']}
                type="default"
              >
                {`${t(`profile:prioExtension.logIn`)}`}
              </Button>
            </>
          )}
        </>
      );
    } else if (!extensionClientId) {
      return (
        <>
          <div className={classes.extensionInstalationStatusTextRow}>
            <FontAwesomeIcon
              icon={['fas', 'circle-xmark']}
              className={classes.invalidCheckMark}
            />
            <div className={classes.installExtensionTextContainer}>
              <Typography.Text>
                {`${t(`profile:prioExtension.notConfigured`)}`}
              </Typography.Text>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.extensionInstalationStatusTextRow}>
            <FontAwesomeIcon
              icon={['fas', 'circle-xmark']}
              className={classes.invalidCheckMark}
            />
            <div className={classes.installExtensionTextContainer}>
              <Typography.Text>
                {`${t(`profile:prioExtension.notInstalled`)}`}
              </Typography.Text>
              <Typography.Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/prio-file-interceptor/cdiajppfkdinacildfcbgopfmelhlhnh"
              >
                {`${t(`profile:profileNavigation.prioExtension`)}`}
              </Typography.Link>
            </div>
          </div>
        </>
      );
    }
  };
  //#endregion
  return (
    <div className={classNames(classes.root, className)}>
      <Typography.Title>
        {t('profile:profileNavigation.prioExtension')}
      </Typography.Title>
      <div className={classes.extensionInfo}>
        <div className={classes.extensionStatus}>
          {renderExtensionSettings()}
        </div>
      </div>
    </div>
  );
};

export default PrioExtensionSettingsPage;
