import React, { useCallback, useEffect, useState } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Dropdown, Input, Menu, Table, Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { useTranslation } from 'react-i18next';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { ColumnProps } from 'antd/lib/table';
import { tableTranslations } from '../../../util/table';
import {
  apiArchiveDocumentTagSet,
  apiCreateDocumentTagSet,
  apiFetchDocumentTagSets,
  apiFetchDocumentTags,
  apiUnarchiveDocumentTagSet,
  apiUpdateDocumentTagSet,
} from '../../documents/api';
import { DocumentTag, DocumentTagSet } from '../../../models/Document';
import DocumentTagPicker from '../../documents/components/DocumentTagPicker';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    height: '100%',
    overflowY: 'auto',
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-thead > tr > th:first-child': {
      paddingLeft: theme.old.spacing.baseSpacing,
    },
    '& .ant-table-tbody > tr > td:first-child': {
      paddingLeft: theme.old.spacing.defaultPadding,
    },
    '& .ant-table-tbody > tr > td:last-child': {
      paddingRight: theme.old.spacing.defaultPadding,
    },
  },
  fullWidth: {
    width: '100%',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  actionRow: {
    padding: theme.old.spacing.defaultPadding,
    paddingTop: 0,
  },
  title: {
    padding: theme.old.spacing.defaultPadding,
    marginBottom: '0!important',
  },
  buttonContextMenu: {
    marginRight: theme.old.spacing.defaultPadding,
  },
  italic: {
    fontStyle: 'italic',
  },
}));

interface TableEntry {
  displayName: string;
  value: string[];
  id: string;
  addSettingRow: boolean;
  updateSettingRow: boolean;
  isTemporary: boolean;
  isArchived: boolean;
}

interface GlobalProjectSettingsProjectDocumentPrefixProps {
  className?: string;
}

export const GlobalProjectSettingsProjectDocumentPrefix: React.FC<
  GlobalProjectSettingsProjectDocumentPrefixProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const { className } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [newSettingRowActive, setNewSettingRowActive] = useState(false);
  const [documentTagSets, setDocumentTagSets] = useState<DocumentTagSet[]>([]);

  const [newDocumentTagSetName, setNewDocumentTagSetName] = useState<string>();
  const [newDocumentTags, setNewDocumentTags] = useState<string[]>([]);

  const [updatedDocumentTags, setUpdatedDocumentTags] = useState<string[]>([]);
  const [updatedTableEntry, setUpdatedTableEntry] = useState<TableEntry>(null);

  const [actionButtonDisabled, setActionButtonDisabled] =
    useState<boolean>(true);
  const [isArchivedTagSetsVisible, setIsArchivedTagSetsVisible] =
    useState<boolean>(false);

  const [allDocumentTags, setAllDocumentTags] = useState<DocumentTag[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const enableNewSettingRow = () => setNewSettingRowActive(true);
  const cancelNewSettingRow = () => setNewSettingRowActive(false);

  const onDocumentTagsChange = (values: string[], record: TableEntry) => {
    record?.addSettingRow
      ? setNewDocumentTags(values)
      : setUpdatedDocumentTags(values);
    setUpdatedTableEntry(record);
  };

  const getDocumentTagSets = useCallback(async () => {
    try {
      const { data, result } = await apiFetchDocumentTagSets(true);
      if (data) {
        setDocumentTagSets(data);
      }
      if (result.status >= 400) {
        notification.open({
          message: t('common:error'),
          description: t('settings:errorMessages.fetchDocumentTagSetsError'),
        });
      }
    } catch (error) {}
  }, [t]);

  const archiveDocumentTagSet = async (
    DocumentTagSetId: string,
    isArchived: boolean
  ) => {
    try {
      const { result, data } = isArchived
        ? await apiUnarchiveDocumentTagSet(DocumentTagSetId)
        : await apiArchiveDocumentTagSet(DocumentTagSetId);
      if (data) {
        setDocumentTagSets(
          documentTagSets.map((set) =>
            set.documentTagSetId === DocumentTagSetId ? data : set
          )
        );
      }
      if (result.status >= 400) {
        notification.open({
          message: t('common:error'),
          description: t(
            `settings:errorMessages.${
              isArchived ? 'un' : ''
            }archiveDocumentTagSetError`
          ),
        });
      } else {
      }
    } catch (error) {}
    getDocumentTagSets();
  };

  const addDocumentTagSet = async () => {
    const requestBody: {
      name: string;
      description: string;
      containedTags: string[];
    } = {
      name: newDocumentTagSetName,
      description: '',
      containedTags: newDocumentTags,
    };
    try {
      const { result, data } = await apiCreateDocumentTagSet(requestBody);
      if (data) {
        setDocumentTagSets([...documentTagSets, data]);
        setNewDocumentTagSetName('');
        setNewDocumentTags([]);
        cancelNewSettingRow();
      }

      if (result.status >= 400) {
        notification.open({
          message: t('common:error'),
          description: t('settings:errorMessages.createDocumentTagSetsError'),
        });
      }
    } catch (error) {}
  };

  const loadDocumentTags = useCallback(async () => {
    try {
      setIsFetching(true);
      const { data } = await apiFetchDocumentTags();
      if (data && Array.isArray(data)) {
        setAllDocumentTags(
          data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
        );
      }
      setIsFetching(false);
    } catch {}
  }, [setAllDocumentTags]);

  const updateDocumentTagSet = useCallback(
    async (record: TableEntry) => {
      const requestBody: {
        name: string;
        description: string;
        containedTags: string[];
      } = {
        name: record.displayName,
        description: '',
        containedTags: updatedDocumentTags,
      };
      try {
        const { result, data } = await apiUpdateDocumentTagSet(
          record.id,
          requestBody
        );
        if (data) {
          setDocumentTagSets(
            documentTagSets.map((set) =>
              set.documentTagSetId === record.id ? data : set
            )
          );
          setUpdatedDocumentTags([]);
          setUpdatedTableEntry(null);
          loadDocumentTags();
        }
        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t('settings:errorMessages.updateDocumentTagSetsError'),
          });
        }
      } catch (error) {}
    },
    [updatedDocumentTags, documentTagSets, t, loadDocumentTags]
  );

  const onShowArchivedTagSetsClick = () => {
    setIsArchivedTagSetsVisible(!isArchivedTagSetsVisible);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    getDocumentTagSets();
  }, [getDocumentTagSets]);

  useEffect(() => {
    loadDocumentTags();
  }, [loadDocumentTags]);

  useEffect(() => {
    if (newDocumentTagSetName && newDocumentTags?.length > 0) {
      setActionButtonDisabled(false);
    } else {
      setActionButtonDisabled(true);
    }
  }, [newDocumentTagSetName, newDocumentTags]);

  useEffect(() => {
    if (updatedTableEntry && updatedDocumentTags?.length > 0) {
      const temporaryUpdatedDocumentTags: DocumentTag[] =
        updatedDocumentTags.map((tag) => {
          return {
            documentTagId: 'temporaryId',
            name: tag,
            description: '',
            isArchived: false,
            isInUse: false,
          };
        });

      documentTagSets.map((set) =>
        set.documentTagSetId === updatedTableEntry.id
          ? (set.containedTags = temporaryUpdatedDocumentTags)
          : set
      );
      updateDocumentTagSet(updatedTableEntry);
    }
  }, [
    updatedDocumentTags,
    updatedTableEntry,
    documentTagSets,
    updateDocumentTagSet,
  ]);
  //#endregion

  //#region ------------------------------ Components
  const contextMenu = (
    <Menu>
      <Menu.Item onClick={onShowArchivedTagSetsClick}>
        {isArchivedTagSetsVisible
          ? t('settings:projectSettingsTable.actions.hideArchivedTagSets')
          : t('settings:projectSettingsTable.actions.showArchivedTagSets')}
      </Menu.Item>
    </Menu>
  );
  // #endregion

  //#region ------------------------------ Table
  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('settings:projectSettingsTable.columnTitles.documentTagSet'),
      dataIndex: 'displayName',
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      render: (value: string, record: TableEntry) =>
        record.addSettingRow ? (
          <Input
            value={record.addSettingRow ? newDocumentTagSetName : value}
            placeholder={t(
              'settings:projectSettingsTable.newEntry.placeHolderName'
            )}
            onChange={(e) => setNewDocumentTagSetName(e.currentTarget.value)}
          />
        ) : (
          <Typography.Text
            className={classNames({ [classes.italic]: record.isArchived })}
          >
            {value}
          </Typography.Text>
        ),
      width: 200,
    },
    {
      title: t('settings:projectSettingsTable.columnTitles.documentTags'),
      dataIndex: 'value',
      render: (value: string[], record: TableEntry) => (
        <DocumentTagPicker
          value={record.addSettingRow ? newDocumentTags : value}
          className={classes.fullWidth}
          allowCreate
          allowDelete
          onChange={(values) => {
            onDocumentTagsChange(values, record);
          }}
          allDocumentTags={allDocumentTags}
          setAllDocumentTags={setAllDocumentTags}
          isFetching={isFetching}
        ></DocumentTagPicker>
      ),
      width: 500,
    },
    {
      render: (_, record) =>
        record.addSettingRow ? (
          <Button
            disabled={actionButtonDisabled}
            onClick={addDocumentTagSet}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            className="hourly-rate-suggestion-table-delete-button"
            onClick={() => {
              archiveDocumentTagSet(record.id, record.isArchived);
            }}
            type="link"
            suffixIconProp={['fal', record.isArchived ? 'trash-undo' : 'trash']}
          ></Button>
        ),
      width: 80,
    },
  ];

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewSettingRow}
        disabled={newSettingRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('settings:projectSettingsTable.actions.addTagSet')}
      </Button>
    </div>
  );

  const data: TableEntry[] = documentTagSets
    .filter((set) =>
      !isArchivedTagSetsVisible ? set.isArchived === false : set
    )
    .map((set: DocumentTagSet) => ({
      id: set.documentTagSetId,
      key: set.documentTagSetId,
      displayName: set.name,
      value: set.containedTags.map((tag) => tag.name),
      isTemporary: false,
      isArchived: set.isArchived,
      addSettingRow: false,
      updateSettingRow: false,
    }));
  //#endregion

  if (documentTagSets === null) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column>
        <Flex.Row alignItems="center">
          <Flex.Item flex={1}>
            <Typography.Title className={classes.title}>
              {t('settings:projectSettingsTable.title.projectDocumentTags')}
            </Typography.Title>
          </Flex.Item>
          <Dropdown overlay={contextMenu} trigger={['click']}>
            <Button
              className={classes.buttonContextMenu}
              type="link"
              iconProp={['fal', 'ellipsis-v']}
            ></Button>
          </Dropdown>
        </Flex.Row>
        <Table
          className={classes.root}
          columns={columns}
          pagination={false}
          dataSource={
            newSettingRowActive
              ? [
                  ...data,
                  {
                    id: 'addSetting',
                    key: 'addSetting',
                    displayName: '',
                    value: '',
                    addSettingRow: true,
                  },
                ]
              : data
          }
          footer={footer}
          locale={tableTranslations(t)}
        />
      </Flex.Column>
    </div>
  );
};

export default GlobalProjectSettingsProjectDocumentPrefix;
