import React, { useMemo } from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
import ProjectContactsSubModule from '../../contacts/components/ProjectContactsSubModule';
import MailProjectSubModule from '../../mail/components/projects/MailProjectSubModule';
import TimeRecordControllingSubModule from '../../timeRecords/components/TimeRecordControllingSubModule';
import TimeRecordSubModule from '../../timeRecords/components/TimeRecordSubModule';
import DocumentsSubModule from '../../documents/components/DocumentsSubModule';
import ProjectSummary from './ProjectSummary';
import ProjectSettings from './ProjectSettings';
import { useSelector } from 'react-redux';
import {
  getMyProjectIds,
  getProject,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import InvoicesControllingSubModule from '../../accounting/components/InvoicesControllingSubModule';
import { ProjectId } from '../../../models/Types';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

export const ProjectsModule: React.FC = () => {
  const { projectId } = useParams();
  return <MemoizedProjectsModule projectId={projectId} />;
};

interface MemoizedProjectsModuleProps {
  projectId: ProjectId;
}

export const MemoizedProjectsModule: React.FC<MemoizedProjectsModuleProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const { projectId } = props;
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const project = useSelector<RootReducerState, Project>((state) =>
      getProject(state, projectId)
    );

    const myProjectIds = useSelector(getMyProjectIds);
    const myProjectIdsSet = useMemo(
      () => new Set(myProjectIds),
      [myProjectIds]
    );
    const isProjectMember = myProjectIdsSet.has(projectId);
    //#endregion

    if (!isProjectMember) {
      return (
        <Routes>
          <Route
            path="summary/*"
            element={<ProjectSummary projectId={projectId} />}
          />
          <Route path="settings">
            <Route path="*" element={<Navigate to="edit" replace />} />
            <Route
              path=":selectedList/*"
              element={<ProjectSettings projectId={projectId} />}
            />
          </Route>
        </Routes>
      );
    }

    return (
      <Routes>
        <Route
          path="contacts/*"
          element={<ProjectContactsSubModule projectId={projectId} />}
        />
        {project?.sharedMailboxUserId && (
          <Route
            path="mail/*"
            element={<MailProjectSubModule projectId={projectId} />}
          />
        )}

        <Route
          path="timeRecordControlling/*"
          element={<TimeRecordControllingSubModule projectId={projectId} />}
        />
        <Route
          path="timeRecord/*"
          element={<TimeRecordSubModule projectId={projectId} />}
        />
        {project?.groupId && (
          <Route
            path="documents/*"
            element={<DocumentsSubModule projectId={projectId} />}
          />
        )}
        {/* {project?.oneDrivePath !== undefined ? (
        <Route path="openexperience/*" element={<ProjectOpenExperience />} />
      ) : (
        <></>
      )} */}

        <Route
          path="invoiceControlling/*"
          element={<InvoicesControllingSubModule projectId={projectId} />}
        />
        <Route
          path="summary/*"
          element={
            <ProjectSummary
              projectId={projectId}
              isProjectMember={isProjectMember}
            />
          }
        />
        <Route path="settings">
          <Route path="*" element={<Navigate to="edit" replace />} />
          <Route
            path=":selectedList/*"
            element={<ProjectSettings projectId={projectId} />}
          />
        </Route>
      </Routes>
    );
  });

export default ProjectsModule;
