import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import jsonMergePatch from 'json-merge-patch';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import ExternalCompanyForm from './ExternalCompanyForm';
import {
  setContactsDrawerState,
  closeContactsDrawer,
} from '../../contacts/actions/drawer';
import { Result } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  getContactsDrawerState,
  getCompany,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Company, UpdateExternalCompanyRequest } from '../../../models/Company';
import { updateExternalCompany, updateExternalOffice } from '../actions';
import equals from 'deep-equal';
import { useForm } from 'antd/lib/form/Form';
import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px`,
  },
  form: {
    marginTop: theme.old.spacing.unit(4),
  },
}));

interface EditCompanyProps {
  className?: string;
  formClassName?: string;
  noAvatar?: boolean;
}

export const EditCompany: React.FC<EditCompanyProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { className, formClassName } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const { selectedCompany, open } = useSelector(getContactsDrawerState);
  const company: Company = useSelector<RootReducerState, Company>(
    (state) => selectedCompany && getCompany(state, selectedCompany)
  );

  const { editExternalCompany: canEditExternalCompany } = useAccessRights([
    'editExternalCompany',
  ]);
  //#endregion

  //#region -------------------------------- Methods

  const onFinish = async (value: Company) => {
    const {
      companyId,
      rowVersion,
      offices: currentOffices,
      bankAccounts,
      parentCompanyId,
      fullName2,
      companyType,
      isArchived,
      ...currentCompany
    } = company;
    const {
      offices: updatedOffices,
      bankAccounts: _1,
      isArchived: _2,
      ...updatedCompany
    } = value;

    if (company.companyType === 'ExternalCompany') {
      if (!equals(currentCompany, updatedCompany)) {
        dispatch(
          updateExternalCompany(
            jsonMergePatch.generate(
              currentCompany,
              updatedCompany
            ) as UpdateExternalCompanyRequest,
            selectedCompany,
            company.rowVersion,
            company
          )
        );
      }
    }
    updatedOffices?.forEach((updatedOffice) => {
      const currentOffice = currentOffices.find(
        (o) => o.officeId === updatedOffice.officeId
      );
      if (!equals(currentOffice, updatedOffice)) {
        if (company.companyType === 'ExternalCompany') {
          updatedOffice.officeType = 'externalOffice';
        }

        if (updatedOffice.officeType === 'externalOffice') {
          console.warn('external Office');
          dispatch(
            updateExternalOffice(
              updatedOffice,
              company.companyId,
              currentOffice.officeId,
              currentOffice.rowVersion,
              currentOffice
            )
          );
        }
      }
    });

    dispatch(
      setContactsDrawerState({
        view: 'companyDetail',
      })
    );
  };

  const onCancel = () => {
    dispatch(closeContactsDrawer());
  };

  const closeDrawer = () => dispatch(closeContactsDrawer());
  //#endregion

  if (selectedCompany) {
    return (
      <Flex.Column className={classNames(classes.root, className)}>
        <ExternalCompanyForm
          form={form}
          actionLabel={t('companies:newCompany.actionLabel')}
          cancelLabel={t('common:actions.cancel')}
          className={classNames(classes.form, formClassName)}
          onFinish={onFinish}
          onCancel={onCancel}
          disableForm={!open || !canEditExternalCompany}
          initialValues={company}
          avatar
        />
      </Flex.Column>
    );
  }
  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <Result
        status="500"
        title={t('common:weAreSorry')}
        extra={
          <Button onClick={closeDrawer}>{t('common:actions.close')}</Button>
        }
      />
    </div>
  );
};

export default EditCompany;
