import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  List,
  Row,
  Select,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { InternalProjectContact } from '../../../../models/ProjectContacts';
import {
  ProjectExtension,
  ProjectExtensionAccess,
  ProjectExtensionAccessOption,
  UpdateUserExtensionAccess,
} from '../../../../models/ProjectExtension';
import { ProjectId } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  apiFetchInternalProjectContacts,
  apiUpdateProjectExtensionAccesses,
} from '../../api';
//import SharedMailboxProjectExtensionAccessForm from './Forms/SharedMailboxProjectExtensionAccessForm';
//import ProjectExtensionAccessDrawer from './ProjectExtensionAccessDrawer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    position: 'relative',
  },
  spacing: {
    marginBottom: theme.old.spacing.defaultPadding,
  },
  list: {
    width: '100%',
  },
}));

export interface ProjectExtensionAccessWithContact
  extends ProjectExtensionAccess {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

export interface InternalProjectContactWithContact
  extends InternalProjectContact {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

interface ExtensionAccessListProps {
  projectExtension: ProjectExtension;
  projectId: ProjectId;
  reloadProjectExtension: () => void;
}

export const ExtensionAccessList: React.FC<ExtensionAccessListProps> = (
  props
) => {
  const { projectExtension, projectId, reloadProjectExtension } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [
    projectExtensionAccessesWithContact,
    setProjectExtensionAccessesWithContact,
  ] = useState<ProjectExtensionAccessWithContact[]>(null);

  const [filteredData, setFilteredData] = useState<
    ProjectExtensionAccessWithContact[]
  >([]);

  const [contactWithNoAccess, setContactWithNoAccess] = useState<
    InternalProjectContactWithContact[]
  >([]);
  const [internalProjectContacts, setInternalProjectContacts] = useState<
    InternalProjectContact[]
  >([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState('');

  const [formAddUser] = Form.useForm();
  /*
  const [
    projectExtensionAccessDrawerVisible,
    setProjectExtensionAccessDrawerVisible,
  ] = useState<boolean>(false);


  const [selectedProjectExtensionAccess, setSelectedProjectExtensionAccess] =
    useState<ProjectExtensionAccess>(null);
  */
  // get project Extension Access list
  const contactsByIdState = useSelector(getContactsByIdState);

  useEffect(() => {
    let accessList: ProjectExtensionAccessWithContact[] = [];
    projectExtension?.accesses?.forEach((access) => {
      var contact = contactsByIdState[access.contactId];
      accessList.push({
        ...access,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        fullName: `${contact?.firstName} ${contact?.lastName}`,
      });
    });
    setProjectExtensionAccessesWithContact(accessList);
    setFilteredData(accessList.filter((map) => map.shouldHaveAccess));
  }, [projectExtension, internalProjectContacts, contactsByIdState]);

  useEffect(() => {
    if (projectId) {
      const getInternalContacts = async (projectId) => {
        const { data } = await apiFetchInternalProjectContacts(projectId);
        if (data) {
          setInternalProjectContacts(data);
          var noAccess = data.filter((contact) => {
            if (
              !projectExtension.accesses.find(
                (access) =>
                  access.contactId === contact.contactId &&
                  access.shouldHaveAccess
              )
            ) {
              return contact;
            } else {
              return null;
            }
          });
          var listToSave: InternalProjectContactWithContact[] = [];
          noAccess.forEach((element) => {
            var contact = contactsByIdState[element.contactId];
            if (contact != null) {
              listToSave.push({
                ...element,
                firstName: contact.firstName,
                lastName: contact.lastName,
                fullName: `${contact.firstName} ${contact.lastName}`,
              });
            }
          });
          setContactWithNoAccess(listToSave);
        }
      };
      getInternalContacts(projectId);
    }
  }, [projectId, contactsByIdState, projectExtension.accesses]);

  const handleClick = (item: ProjectExtensionAccessWithContact) => {
    let newSelectedItems;
    if (selectedItems.includes(item.contactId)) {
      newSelectedItems = selectedItems.filter((i) => i !== item.contactId);
    } else {
      newSelectedItems = [...selectedItems, item.contactId];
    }
    setSelectedItems(newSelectedItems);
  };

  const handleSelectAll = (state: CheckboxChangeEvent) => {
    if (state.target.checked) {
      setSelectedItems(filteredData.map((row) => row.contactId));
    } else {
      setSelectedItems([]);
    }
  };

  const getOptionsString: (
    options: ProjectExtensionAccessOption[]
  ) => React.ReactNode = (options: ProjectExtensionAccessOption[]) => {
    switch (projectExtension.projectExtensionType) {
      /*
      case 'sharedMailbox':
        return (
          <SharedMailboxProjectExtensionAccessForm
            projectExtensionAccessOptions={options}
          />
        );
        */ //`${t(`projects:addIn.extensions.sharedMailbox.journal`)}: `;
      default:
        return '';
    }
  };

  /*
  const openProjectExtensionAccess = (
    projectExtensionAccess: ProjectExtensionAccess
  ) => {
    setSelectedProjectExtensionAccess(projectExtensionAccess);
    setProjectExtensionAccessDrawerVisible(true);
  };
  */
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === '') {
      setFilteredData(
        projectExtensionAccessesWithContact?.filter(
          (item) => item.shouldHaveAccess
        )
      );
    } else {
      setFilteredData(
        projectExtensionAccessesWithContact?.filter(
          (item) =>
            item.shouldHaveAccess &&
            (item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    }
  };

  const addExtensionAccesses = async (formValues) => {
    setInProgress(true);
    var contactIds = formValues.addProjectContacts;
    var contactsToAdd = contactIds.map((contactId) =>
      internalProjectContacts.find((contact) => contact.contactId === contactId)
    );
    var accesses: ProjectExtensionAccess[] = contactsToAdd.map((contact) => {
      return {
        contactId: contact.contactId,
        shouldHaveAccess: true,
        projectExtensionAccessOptions: [],
        ProjectExtensionId: projectExtension.projectExtensionId,
      };
    });
    // update api call
    const { data } = await apiUpdateProjectExtensionAccesses(
      projectId,
      accesses
    );
    if (data) {
      let contactsToAdd = filteredData;
      data.forEach((newAccess) => {
        var contact = contactsByIdState[newAccess.contactId];
        contactsToAdd.push({
          ...newAccess,
          firstName: contact.firstName,
          lastName: contact.lastName,
          fullName: `${contact.firstName} ${contact.lastName}`,
        });
      });
      setProjectExtensionAccessesWithContact(contactsToAdd);
      setFilteredData(contactsToAdd);

      var noAccessContacts = contactWithNoAccess.filter((ic) => {
        if (!data.find((access) => access.contactId === ic.contactId)) {
          return ic;
        } else {
          return null;
        }
      });

      setContactWithNoAccess(noAccessContacts);
      // update
      if (reloadProjectExtension) {
        reloadProjectExtension();
      }
    }
    formAddUser.resetFields();
    setInProgress(false);
  };

  const removeExtensionAccesses = async () => {
    setInProgress(true);
    var accessDeletions: UpdateUserExtensionAccess[] = selectedItems.map(
      (contactId) => {
        return {
          contactId: contactId,
          shouldHaveAccess: false,
          options: [],
          projectExtensionId: projectExtension.projectExtensionId,
        };
      }
    );
    // update api call
    await apiUpdateProjectExtensionAccesses(projectId, accessDeletions);
    if (reloadProjectExtension) {
      reloadProjectExtension();
    }
    // update access
    var filteredDeletedAccess = filteredData.filter(
      (access) => !selectedItems.includes(access.contactId)
    );
    setFilteredData(filteredDeletedAccess);

    setSelectedItems([]);
    setInProgress(false);
  };

  return (
    <>
      <Row className={classes.spacing}>
        <Form
          form={formAddUser}
          className={classes.root}
          onFinish={(values) => {
            addExtensionAccesses(values);
          }}
        >
          <Flex.Column flex={12}>
            <Form.Item
              name="addProjectContacts"
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.projectContactRequired'),
                },
              ]}
            >
              <Select mode="tags">
                {contactWithNoAccess.map((InternalProjectContact) => (
                  <Select.Option
                    id={InternalProjectContact.contactId}
                    key={InternalProjectContact.contactId}
                    value={InternalProjectContact.contactId}
                    title={InternalProjectContact.fullName}
                  >
                    {InternalProjectContact.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex.Column>
          <Flex.Column flex={1}>
            <Button htmlType="submit" type="primary" disabled={inProgress}>
              {t(`projects:addIn.access.add`)}
            </Button>
          </Flex.Column>
        </Form>
      </Row>
      <Divider plain>{t(`projects:addIn.access.hasAccess`)}</Divider>
      <Row>
        <Flex.Column flex={1}>
          <Checkbox
            checked={
              selectedItems.length !== 0 &&
              filteredData.length === selectedItems.length &&
              filteredData
                .map((map) => map.projectExtensionAccessId)
                .every((value, index) => value === selectedItems[index])
            }
            onChange={(state) => handleSelectAll(state)}
          ></Checkbox>
        </Flex.Column>
        <Flex.Column flex={16}>
          <Input
            placeholder={t(`projects:addIn.access.search`)}
            onChange={handleSearch}
            value={searchTerm}
          />
        </Flex.Column>
        {selectedItems?.length > 0 && (
          <Flex.Column flex={4}>
            <Col>
              <Button
                onClick={() => {
                  removeExtensionAccesses();
                }}
                disabled={inProgress}
              >
                ({selectedItems.length}) {t(`projects:addIn.access.remove`)}
              </Button>
            </Col>
          </Flex.Column>
        )}
      </Row>
      <Row>
        <List
          dataSource={filteredData}
          className={classes.list}
          renderItem={(item) => (
            <List.Item
              key={item.projectExtensionAccessId}
              actions={[
                projectExtension.projectExtensionType === 'jira' && (
                  /*
                  <Button onClick={() => openProjectExtensionAccess(item)}>
                    <FontAwesomeIcon
                      style={{ right: 28 }}
                      icon={['fal', 'chevron-right']}
                    />
                  </Button>
                  */ <></>
                ),
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Checkbox
                    checked={selectedItems.includes(item.contactId)}
                    onChange={() => handleClick(item)}
                  ></Checkbox>
                }
                title={item.fullName}
                description={getOptionsString(
                  item.projectExtensionAccessOptions
                )}
              />
            </List.Item>
          )}
        />
      </Row>
      {/* <Row>
      <ProjectExtensionAccessDrawer
        projectExtensionAccessDrawerVisible={
          projectExtensionAccessDrawerVisible
        }
        setProjectExtensionAccessDrawerVisible={
          setProjectExtensionAccessDrawerVisible
        }
        projectExtensionAccess={selectedProjectExtensionAccess}
        projectExtension={projectExtension}
      />
      */}
    </>
  );
};

export default ExtensionAccessList;
