import React from 'react';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { OfficeId } from '../../../models/Types';
import { HRAbsenceProposalPage } from './HRAbsenceProposalPage';

interface HRAbsenceOpenProposalPageProps {
  className?: string;
  onRowSelectionChange?: (selectedProposals: AbsenceProposal[]) => void;
  officeId?: OfficeId;
}

export const HRAbsenceOpenProposalPage: React.FC<
  HRAbsenceOpenProposalPageProps
> = ({ className, onRowSelectionChange, officeId }) => {
  return (
    <HRAbsenceProposalPage
      className={className}
      onlyOpen
      onRowSelectionChange={onRowSelectionChange}
      officeId={officeId}
    />
  );
};

export default HRAbsenceOpenProposalPage;
