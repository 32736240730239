import React, { useEffect, useState } from 'react';
import { AttributeValidations } from '../../../../../../models/Project';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import { ProjectExtensionId, ProjectId } from '../../../../../../models/Types';
import { apiValidateProjectExtensionForProject } from '../../../../api';
import SharedMailboxProjectExtensionForm, {
  SharedMailboxProjectExtensionFormModel,
} from './SharedMailboxProjectExtensionForm';

interface SharedMailboxProjectExtensionFormWrapperProps {
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export const SharedMailboxProjectExtensionFormWrapper: React.FC<
  SharedMailboxProjectExtensionFormWrapperProps
> = (props) => {
  const {
    projectExtension,
    addProjectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;

  const [validations, setValidations] = useState<AttributeValidations>(null);
  const [sharedMailboxForm, setSharedMailboxForm] =
    useState<SharedMailboxProjectExtensionFormModel>({
      displayName: '',
      email: '',
      hasDefaultAccess: true,
    });

  useEffect(() => {
    if (projectExtension) {
      setSharedMailboxForm({
        displayName: projectExtension.displayName,
        email: projectExtension.value,
        hasDefaultAccess: projectExtension.hasDefaultAccess,
      });
    }
  }, [projectExtension]);

  const validateData = async (
    sharedMailboxProjectExtensionFormModel: SharedMailboxProjectExtensionFormModel
  ) => {
    setValidations(null);
    var email = sharedMailboxProjectExtensionFormModel.eMailPrefix
      ? sharedMailboxProjectExtensionFormModel.eMailPrefix +
        '@' +
        sharedMailboxProjectExtensionFormModel.eMailSuffix
      : null;
    let createExtension: CreateProjectExtension = {
      displayName: sharedMailboxProjectExtensionFormModel.displayName,
      projectExtensionType: 'sharedMailbox',
      value: email,
    };

    const { data } = await apiValidateProjectExtensionForProject(
      projectId,
      createExtension
    );

    if (data) {
      setValidations(data);
    } else {
      setValidations(null);
    }
  };

  const saveOrUpdateExtension = async (
    sharedMailboxProjectExtensionFormModel: SharedMailboxProjectExtensionFormModel
  ) => {
    if (projectExtension && !addProjectExtension) {
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        {
          displayName: sharedMailboxProjectExtensionFormModel.displayName,
          value:
            sharedMailboxProjectExtensionFormModel.eMailPrefix +
            '@' +
            sharedMailboxProjectExtensionFormModel.eMailSuffix,

          hasDefaultAccess:
            sharedMailboxProjectExtensionFormModel?.hasDefaultAccess ?? true,
        }
      );
    } else if (addProjectExtension && !projectExtension) {
      await createProjectExtension(projectId, {
        displayName: sharedMailboxProjectExtensionFormModel.displayName,
        projectExtensionType: 'sharedMailbox',
        value:
          sharedMailboxProjectExtensionFormModel.eMailPrefix +
          '@' +
          sharedMailboxProjectExtensionFormModel.eMailSuffix,

        hasDefaultAccess:
          sharedMailboxProjectExtensionFormModel?.hasDefaultAccess ?? true,
      });
    }
  };

  return (
    <SharedMailboxProjectExtensionForm
      sharedMailboxProjectExtensionFormModel={sharedMailboxForm}
      saveForm={(sharedMailboxProjectExtensionFormModel) => {
        saveOrUpdateExtension(sharedMailboxProjectExtensionFormModel);
      }}
      isCreation={!projectExtension && addProjectExtension != null}
      projectId={projectId}
      validateData={(sharedMailboxProjectExtensionFormModel) => {
        validateData(sharedMailboxProjectExtensionFormModel);
      }}
      validations={validations}
    />
  );
};
