import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DatePicker, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { OfficeId } from '../../../../models/Types';
import HRCompensationPaymentsTable from './HRCompensationPaymentsTable';
import {
  apiDeleteCompensationPayment,
  apiFetchCompenationPayments,
  apiPayCompensationPayment,
} from '../../../timeKeeping/api';
import {
  CompensationPayment,
  CompensationPaymentFilter,
} from '../../../../models/TimeKeeping';
import moment, { Moment } from 'moment';
import Flex from '../../../../components/Flex';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import useDatePickerLocale from '../../../../hooks/useDatePickerLocale';
import CreateCompensationPaymentsDrawer from './CreateCompensationPaymentsDrawer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
  },
  table: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  picker: {
    minWidth: 200,
  },
}));

export interface HRCompensationPaymentsPageRefProps {
  openCreateCompensationPaymentDrawer: () => void;
  payOut: () => void;
  delete: () => void;
}

interface HRCompensationPaymentsPageProps {
  className?: string;
  officeId?: OfficeId;
  setMultipleCompensationPaymentsSelected: (value: boolean) => void;
  setSelectedCompensationPayments: (value: CompensationPayment[]) => void;
}

export const HRCompensationPaymentsPage = forwardRef<
  HRCompensationPaymentsPageRefProps,
  HRCompensationPaymentsPageProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const {
    className,
    officeId,
    setMultipleCompensationPaymentsSelected,
    setSelectedCompensationPayments,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [compensationPayments, setCompensationPayments] = useState<
    CompensationPayment[]
  >([]);

  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment().startOf('month')
  );
  const [loadingCompensationPayments, setLoadingCompensationPayments] =
    useState<boolean>(false);

  const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);
  const [selectedPayments, setSelectedPayments] = useState<
    CompensationPayment[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchCompensationPayments = useCallback(
    async (request: CompensationPaymentFilter, officeId?: OfficeId) => {
      setLoadingCompensationPayments(true);
      const { data } = await apiFetchCompenationPayments(request, officeId);
      if (data) {
        setCompensationPayments(data);
      }
      setLoadingCompensationPayments(false);
    },
    []
  );

  const handleOnChangeDatePicker = (value: Moment) => {
    setSelectedDate(value.clone());
    const from = value.clone().startOf('month').toISOString(true).split('T')[0];
    const to = value.clone().endOf('month').toISOString(true).split('T')[0];
    fetchCompensationPayments(
      {
        from,
        to,
        officeIds: [officeId],
        includeAlreadyPaidOut: true,
      },
      officeId
    );
  };

  const handleOnCreateDrawerClose = (shouldReload?: boolean) => {
    if (shouldReload) {
      fetchCompensationPayments(
        {
          from: selectedDate
            .clone()
            .startOf('month')
            .toISOString(true)
            .split('T')[0],
          to: selectedDate
            .clone()
            .endOf('month')
            .toISOString(true)
            .split('T')[0],
          officeIds: [officeId],
          includeAlreadyPaidOut: true,
        },
        officeId
      );
    }
    setShowCreateDrawer(false);
  };

  const handleOnSelectPayments = (payments: CompensationPayment[]) => {
    setSelectedPayments(payments);
    setSelectedCompensationPayments(payments);
    setMultipleCompensationPaymentsSelected(payments.length > 0);
  };

  const handleReload = () => {
    fetchCompensationPayments(
      {
        from: selectedDate
          .clone()
          .startOf('month')
          .toISOString(true)
          .split('T')[0],
        to: selectedDate.clone().endOf('month').toISOString(true).split('T')[0],
        officeIds: [officeId],
        includeAlreadyPaidOut: true,
      },
      officeId
    );
  };

  const handleOnPayOut = async () => {
    const promises = selectedPayments.map(async ({ compensationPaymentId }) => {
      return await apiPayCompensationPayment(compensationPaymentId, officeId);
    });
    await Promise.all(promises).then(() => {
      fetchCompensationPayments(
        {
          from: selectedDate
            .clone()
            .startOf('month')
            .toISOString(true)
            .split('T')[0],
          to: selectedDate
            .clone()
            .endOf('month')
            .toISOString(true)
            .split('T')[0],
          officeIds: [officeId],
          includeAlreadyPaidOut: true,
        },
        officeId
      );
    });
    setSelectedPayments([]);
    setMultipleCompensationPaymentsSelected(false);
  };

  const showPayOutModal = () => {
    const translationSuffix =
      selectedPayments.length > 1 ? 'Multiple' : 'Single';
    Modal.confirm({
      icon: null,
      title: t(
        `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.title`,
        {
          amount: selectedPayments.length,
        }
      ),
      content: t(
        `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.content`
      ),
      okText: t(
        `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.okText`
      ),
      cancelText: t(
        `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.cancelText`
      ),
      onOk: handleOnPayOut,
      onCancel() {},
    });
  };

  const handleOnDelete = async () => {
    const promises = selectedPayments.map(async ({ compensationPaymentId }) => {
      return await apiDeleteCompensationPayment(
        compensationPaymentId,
        officeId
      );
    });
    await Promise.all(promises).then(() => {
      fetchCompensationPayments(
        {
          from: selectedDate
            .clone()
            .startOf('month')
            .toISOString(true)
            .split('T')[0],
          to: selectedDate
            .clone()
            .endOf('month')
            .toISOString(true)
            .split('T')[0],
          officeIds: [officeId],
          includeAlreadyPaidOut: true,
        },
        officeId
      );
    });
    setSelectedPayments([]);
    setMultipleCompensationPaymentsSelected(false);
  };

  const showDeleteModal = () => {
    const translationSuffix =
      selectedPayments.length > 1 ? 'Multiple' : 'Single';
    Modal.confirm({
      icon: null,
      title: t(
        `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.title`,
        {
          amount: selectedPayments.length,
        }
      ),
      content: t(
        `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.content`
      ),
      okText: t(
        `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.okText`
      ),
      cancelText: t(
        `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.cancelText`
      ),
      onOk: handleOnDelete,
      onCancel() {},
    });
  };

  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    openCreateCompensationPaymentDrawer: () => {
      setShowCreateDrawer(true);
    },
    payOut: showPayOutModal,
    delete: showDeleteModal,
  }));

  useEffect(() => {
    const from = moment().startOf('month').toISOString(true).split('T')[0];
    const to = moment().endOf('month').toISOString(true).split('T')[0];
    fetchCompensationPayments(
      {
        from,
        to,
        officeIds: [officeId],
        includeAlreadyPaidOut: true,
      },
      officeId
    );
  }, [officeId, fetchCompensationPayments]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row>
        <DatePicker
          onChange={handleOnChangeDatePicker}
          picker="month"
          placeholder={t('hr:timeAndLeaveManagement.monthPicker')}
          className={classes.picker}
          locale={datePickerLocale}
          value={selectedDate}
          allowClear={false}
        />
        <Button iconProp={['fal', 'search']} type="link"></Button>
      </Flex.Row>
      <HRCompensationPaymentsTable
        className={classes.table}
        officeId={officeId}
        compensationPayments={compensationPayments}
        selectedPayments={selectedPayments}
        setSelectedPayments={handleOnSelectPayments}
        loading={loadingCompensationPayments}
        reload={handleReload}
      />
      <CreateCompensationPaymentsDrawer
        visible={showCreateDrawer}
        officeId={officeId}
        onClose={handleOnCreateDrawerClose}
      />
    </Flex.Column>
  );
});

export default HRCompensationPaymentsPage;
