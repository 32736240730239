import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initials, fullContactTitle } from '../../../../util';
import { getInitialsColorHexFromName } from '../../../../util/initialColors';
import { Employee } from '../../../../models/Employee';
import { InternalContact } from '../../../../models/Contact';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveContactAsOfficeRole,
  unarchiveContactAsOfficeRole,
  updateInternalContact,
} from '../../../contacts/actions';
import { useLocation, useParams } from 'react-router-dom';
import {
  apiCreateMessageDraft,
  apiUpdateMessageDraft,
} from '../../../mail/api';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { rowGutter } from '../../../../util/forms';
import { useForm } from 'antd/lib/form/Form';
import { getUserMe, RootReducerState } from '../../../../apps/main/rootReducer';
import { User } from '../../../../models/User';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  firstActionButton: {
    marginLeft: -14,
  },
  title: {
    '& > svg': {
      visibility: 'hidden',
    },
    '&:hover > svg': {
      visibility: 'visible',
    },
  },
}));

interface PersonnelFileHeaderProps {
  className?: string;
  contact: InternalContact;
  offBoard: () => void;
  employee: Employee;
  noAvatar?: boolean;
  pathPrefix?: string;
}

export const PersonnelFileHeader: React.FC<PersonnelFileHeaderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, noAvatar, contact, employee } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const fullContactName = fullContactTitle(contact);

  const userMe = useSelector<RootReducerState, User>(getUserMe);

  const computedInitials = initials(contact.firstName, contact.lastName);

  const avatarColor = getInitialsColorHexFromName(fullContactName);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [archiveModalVisible, setArchiveModalVisible] =
    useState<boolean>(false);

  const { officeId } = useParams();

  const [renameForm] = useForm();
  const location = useLocation();
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleOpenEMailMeFromHR = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    doShowNewEMailMeMessageFromContact();
  };

  const archive = () => {
    setArchiveModalVisible(true);
  };
  const handleArchive = () => {
    dispatch(archiveContactAsOfficeRole(contact.contactId, officeId));
    setArchiveModalVisible(false);
  };

  const unarchive = () => {
    dispatch(
      unarchiveContactAsOfficeRole(
        contact.contactId,
        officeId ?? contact.officeId
      )
    );
  };

  const doShowNewEMailMeMessageFromContact = async () => {
    setIsCreating(true);
    const { data: message } = await apiCreateMessageDraft('me');
    if (message) {
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: contact.eMail ?? contact.eMail2,
          },
        },
      ];
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }
    setIsCreating(false);
  };

  const handleNameEdit = () => {
    let firstName = contact.firstName;
    let lastName = contact.lastName;

    renameForm.setFieldsValue({ lastName, firstName });
    Modal.confirm({
      icon: null,
      title: t('hr:personnelFile.modal.title'),
      content: (
        <Form
          form={renameForm}
          initialValues={{ lastName, firstName }}
          layout="vertical"
          onValuesChange={(currentValues) => {
            if (currentValues.firstName) {
              firstName = currentValues.firstName;
            }
            if (currentValues.lastName) {
              lastName = currentValues.lastName;
            }
          }}
        >
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name="firstName"
                label={t('hr:personnelFile.modal.labels.firstName')}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:personnelFile.modal.validation.missingFirstName'
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                name="lastName"
                label={t('hr:personnelFile.modal.labels.lastName')}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:personnelFile.modal.validation.missingLastName'
                    ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
      okText: t('hr:personnelFile.modal.okText'),
      cancelText: t('hr:personnelFile.modal.cancelText'),
      okButtonProps: {
        disabled: !lastName && !firstName,
      },
      onOk() {
        dispatch(
          updateInternalContact(
            { firstName, lastName },
            contact.contactId,
            contact.rowVersion,
            contact,
            officeId
          )
        );
      },
      onCancel() {},
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      {!noAvatar && (
        <Avatar
          size={96}
          style={{
            backgroundColor: avatarColor,
            fontSize: 32,
            fontWeight: theme.old.typography.fontWeight.bold,
            minWidth: 96,
          }}
        >
          {computedInitials}
        </Avatar>
      )}
      <Flex.Column
        padding={
          noAvatar
            ? `0 ${theme.old.spacing.unit(2)}px 0 0`
            : `0 ${theme.old.spacing.unit(2)}px`
        }
        flex={2}
        justify-content="center"
      >
        <Flex.Row
          alignItems="baseline"
          className={classes.title}
          childrenGap={theme.old.spacing.unit(1)}
        >
          <Flex.Row
            alignItems="baseline"
            className={classes.title}
            childrenGap={theme.old.spacing.unit(1)}
          >
            <Typography.Title
              style={{ cursor: 'pointer', paddingLeft: '8px' }}
              level={2}
            >
              {fullContactName}
            </Typography.Title>
            <FontAwesomeIcon
              icon={['fal', 'pen']}
              color={theme.old.typography.colors.muted}
              style={{ cursor: 'pointer' }}
              onClick={handleNameEdit}
            />
          </Flex.Row>
        </Flex.Row>
        {userMe.prioData.globalRoles.includes('globalAdmin') &&
        location.pathname.match(/\/settings\/users/) ? (
          <Flex.Row>
            <Button
              type="link"
              iconProp={['fal', 'archive']}
              onClick={contact.isArchived ? unarchive : archive}
            >
              {contact.isArchived
                ? t('hr:personnelFile.actions.unarchive')
                : t('hr:personnelFile.actions.archive')}
            </Button>
          </Flex.Row>
        ) : (
          <Flex.Row childrenGap={theme.old.spacing.unit(1)}>
            {(contact.eMail || employee.eMail) && (
              <Button
                type="link"
                iconProp={['fal', 'envelope']}
                onClick={handleOpenEMailMeFromHR}
                loading={isCreating}
                disabled={isCreating}
              >
                {t('hr:personnelFile.actions.eMail')}
              </Button>
            )}
            {(contact.cellphone || contact.phone || employee?.phone) && (
              <Button
                type="link"
                iconProp={['fal', 'phone']}
                href={`tel:${
                  contact.cellphone ?? contact.phone ?? employee?.phone
                }`}
              >
                {t('hr:personnelFile.actions.call')}
              </Button>
            )}
            <Button type="link" iconProp={['fal', 'folder']}>
              {t('hr:personnelFile.actions.newFile')}
            </Button>
            <Button type="link" iconProp={['fal', 'file-excel']}>
              {t('hr:personnelFile.actions.excel')}
            </Button>
            <Button
              type="link"
              iconProp={['fal', 'archive']}
              onClick={contact.isArchived ? unarchive : archive}
            >
              {contact.isArchived
                ? t('hr:personnelFile.actions.unarchive')
                : t('hr:personnelFile.actions.archive')}
            </Button>
            {/* <Button
          type="link"
          icon={<FontAwesomeIcon icon={['fal', 'ban']} />}
          onClick={offBoard}
        >
          {t('hr:personnelFile.actions.offBoard')}
        </Button> */}
          </Flex.Row>
        )}
      </Flex.Column>
      <Modal
        title={t('hr:personnelFile.archiveModal.title')}
        visible={archiveModalVisible}
        onOk={handleArchive}
        okText={t('hr:personnelFile.archiveModal.okButtonText')}
        cancelText={t('hr:personnelFile.archiveModal.cancelButtonText')}
        onCancel={() => setArchiveModalVisible(false)}
      >
        {t('hr:personnelFile.archiveModal.message')}{' '}
      </Modal>
    </Flex.Row>
  );
};

export default PersonnelFileHeader;
