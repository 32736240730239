import React, { DragEvent, ReactElement, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import pickAttrs from 'rc-util/lib/pickAttrs';
import { v4 as uuid } from 'uuid';
import traverseFileTree from './traverseFileTree';
import attrAccept from './attr-accept';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  button: {
    cursor: 'pointer',
    borderRadius: theme.old.borders.radius,
  },
}));

export interface PrioFile extends File {
  sessionId: string;
  fileId: string;
}

export interface UploadFieldProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onError' | 'onProgress'
  > {
  name?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  directory?: boolean;
  accept?: string;
  multiple?: boolean;
  id?: string;
  isButton?: boolean;
  onBeforeUpload?: (fileList: PrioFile[], sessionId: string) => void;
  onUploadRequest?: (file: PrioFile, sessionId: string) => Promise<void>;
  onAfterUpload?: (fileList: PrioFile[], sessionId: string) => void;
}

export const UploadField: React.FC<UploadFieldProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    id,
    accept,
    directory,
    multiple,
    capture,
    children,
    isButton,
    disabled,
    ...otherProps
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const inputRef = useRef<HTMLInputElement>(null);
  const uid = useMemo(() => uuid(), []);

  const dirProps = useMemo(() => {
    if (directory) {
      return {
        webkitdirectory: 'webkitdirectory',
        directory: 'directory',
      };
    }
    return {};
  }, [directory]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = inputRef.current;
    if (!el) {
      return;
    }
    const { children } = props;
    if (children && (children as ReactElement).type === 'button') {
      const parent = el.parentNode as HTMLInputElement;
      parent.focus();
      parent.querySelector('button').blur();
    }
    el.click();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sessionId = uuid();

    const { files } = e.target;
    let fileList: PrioFile[] = [];

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i) as PrioFile;
      if (file) {
        file.sessionId = sessionId;
        file.fileId = uuid();
        if (!directory || attrAccept(file, accept)) {
          fileList.push(file);
        }
      }
    }

    uploadFiles(fileList, sessionId);
  };

  const handleOnFileDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { multiple } = props;

    if (e.type === 'dragover') {
      return;
    }
    const items = Array.prototype.slice.call(e.dataTransfer.items);
    if (items.some((x) => x?.kind !== 'file')) {
      return;
    }

    const sessionId = uuid();

    let fileList: PrioFile[] = [];

    if (directory) {
      fileList = await traverseFileTree(
        Array.prototype.slice.call(e.dataTransfer.items),
        (_file: PrioFile) => attrAccept(_file, accept),
        sessionId
      );
    } else {
      let files = Array.from(e.dataTransfer.files).filter((file: PrioFile) =>
        attrAccept(file, accept)
      );

      if (multiple === false) {
        files = files.slice(0, 1);
      }

      files.forEach((file: PrioFile) => {
        fileList.push(file);
      });
    }

    uploadFiles(fileList, sessionId);
  };

  const uploadFiles = async (fileList: PrioFile[], sessionId: string) => {
    const { onBeforeUpload, onUploadRequest, onAfterUpload } = props;

    if (onBeforeUpload) {
      onBeforeUpload(fileList, sessionId);
    }

    fileList
      .filter((file) => file !== null)
      .forEach(async (file) => {
        if (onUploadRequest) {
          await onUploadRequest(file as PrioFile, sessionId);
        }
      });

    if (onAfterUpload) {
      onAfterUpload(fileList, sessionId);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.button]: isButton,
      })}
      onDrop={!disabled && handleOnFileDrop}
      onClick={!disabled && isButton ? handleOnClick : undefined}
    >
      <input
        {...pickAttrs(otherProps, { aria: true, data: true })}
        id={id}
        type="file"
        ref={inputRef}
        onClick={(e) => e.stopPropagation()} // https://github.com/ant-design/ant-design/issues/19948
        key={uid}
        style={{ display: 'none' }}
        accept={accept}
        {...dirProps}
        multiple={multiple}
        onChange={!disabled && isButton ? handleOnChange : undefined}
        {...(capture != null ? { capture } : {})}
      />
      {children}
    </div>
  );
};

export default UploadField;
