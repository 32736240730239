import React, { useEffect, useRef, useState } from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { Trans, useTranslation } from 'react-i18next';

import { ContactsNavigation } from './ContactsNavigation';
import ContactDetailsPage from './ContactDetailsPage';
import Flex from '../../../components/Flex';
import CompanyDetailsPage from '../../companies/components/CompanyDetailsPage';
import NavigationBar from '../../../components/NavigationBar';
import { CompanyId, ContactId, ProjectId } from '../../../models/Types';
import useAccessRights from '../../users/hooks/useAccessRights';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../theme/utils';
import {
  useExportCompaniesToExcelFile,
  useExportContactsToExcelFile,
} from '../export';
import { Contact } from '../../../models/Contact';
import { Company } from '../../../models/Company';
import { ContactListItem } from './ContactList';
import { ContactSelectionListRef } from './ContactSelectionList';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  contactList: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface ProjectContactsSubModuleProps {
  projectId: ProjectId;
}

export const ProjectContactsSubModule: React.FC<
  ProjectContactsSubModuleProps
> = (props) => {
  const { projectId } = props;
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  const contactSelectionListRef = useRef<ContactSelectionListRef>(null);
  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);

  const [selectedContactIds, setSelectedContactIds] = useState<ContactId[]>([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<CompanyId[]>([]);

  const exportContactsToExcelFile = useExportContactsToExcelFile();
  const exportCompaniesToExcelFile = useExportCompaniesToExcelFile();

  const { exportContact: canExportContact, exportCompany: canExportCompany } =
    useAccessRights(['exportContact', 'exportCompany']);

  const onExportContactsToExcelFile = () => {
    const selectedContacts = contactSelectionListRef.current
      ?.getAllItems()
      ?.filter(
        (item: ContactListItem) =>
          item.type === 'contact' &&
          selectedContactIds.includes((item.value as Contact).contactId)
      )
      ?.map((item: ContactListItem) => item.value as Contact);

    if (selectedContacts?.length > 0)
      exportContactsToExcelFile(selectedContacts);
  };

  const onExportCompaniesToExcelFile = () => {
    const selectedCompanies = contactSelectionListRef.current
      ?.getAllItems()
      ?.filter(
        (item: ContactListItem) =>
          item.type === 'company' &&
          selectedCompanyIds.includes((item.value as Company).companyId)
      )
      ?.map((item: ContactListItem) => item.value as Company);

    if (selectedCompanies?.length > 0)
      exportCompaniesToExcelFile(selectedCompanies);
  };

  useEffect(() => {
    setSelectedContactIds(
      selectedListIds
        ?.filter((id: string) => id.includes('contact-'))
        ?.map((id: string) => id.replace('contact-', ''))
    );
    setSelectedCompanyIds(
      selectedListIds
        ?.filter((id: string) => id.includes('company-'))
        ?.map((id: string) => id.replace('company-', ''))
    );
  }, [selectedListIds]);

  if (location.pathname.match(/^.*\/projects\/.*\/contacts\/?$/))
    return <Navigate to="members" />;

  return (
    <Flex.Column className={classes.root}>
      <NavigationBar>
        <Routes>
          <Route
            path="members/*"
            element={
              <Button
                disabled={
                  !(canExportContact && canExportCompany) ||
                  !selectedListIds?.length
                }
                onClick={onExportContactsToExcelFile}
                type="default"
                iconProp={['fal', 'file-excel']}
              >
                <span>{t('contacts:navigationBar.exportToExcelFile')}</span>
              </Button>
            }
          />
          <Route
            path="external/*"
            element={
              <Button
                disabled={
                  !(canExportContact && canExportCompany) ||
                  !selectedListIds?.length
                }
                onClick={onExportContactsToExcelFile}
                iconProp={['fal', 'file-excel']}
                type="default"
              >
                <span>{t('contacts:navigationBar.exportToExcelFile')}</span>
              </Button>
            }
          />
          <Route
            path="internal/*"
            element={
              <Button
                disabled={
                  !(canExportContact && canExportCompany) ||
                  !selectedListIds?.length
                }
                onClick={onExportContactsToExcelFile}
                iconProp={['fal', 'file-excel']}
                type="default"
              >
                <span>{t('contacts:navigationBar.exportToExcelFile')}</span>
              </Button>
            }
          />
          <Route
            path="companies/*"
            element={
              <Button
                disabled={
                  !(canExportContact && canExportCompany) ||
                  !selectedListIds?.length
                }
                onClick={onExportCompaniesToExcelFile}
                iconProp={['fal', 'file-excel']}
                type="default"
              >
                <span>{t('contacts:navigationBar.exportToExcelFile')}</span>
              </Button>
            }
          />
        </Routes>
      </NavigationBar>
      <Flex.Row flex={2} className={classes.contactList}>
        <Routes>
          <Route path=":selectedList">
            <Route
              path=""
              element={
                <ContactsNavigation
                  ref={contactSelectionListRef}
                  projectId={projectId}
                  setSelectedListIds={setSelectedListIds}
                  selectedListIds={selectedListIds}
                  pathPrefix=""
                />
              }
            />
            <Route
              path="contact/:contactId/details"
              element={
                <ContactsNavigation
                  ref={contactSelectionListRef}
                  projectId={projectId}
                  pathPrefix="../"
                  setSelectedListIds={setSelectedListIds}
                  selectedListIds={selectedListIds}
                />
              }
            />
            <Route
              path="company/:companyId/details"
              element={
                <ContactsNavigation
                  ref={contactSelectionListRef}
                  projectId={projectId}
                  pathPrefix="../"
                  setSelectedListIds={setSelectedListIds}
                  selectedListIds={selectedListIds}
                />
              }
            />
          </Route>
        </Routes>
        <Flex.Item flex={3}>
          <Routes>
            <Route
              path=":selectedList/contact/:contactId/details"
              element={<ContactDetailsPage />}
            />
            <Route
              path=":selectedList/company/:companyId/details"
              element={<CompanyDetailsPage />}
            />
            <Route
              path=":selectedList"
              element={
                <div className="prio-flex-center-center prio-flex-column">
                  <Typography.Text>
                    <Trans
                      i18nKey={'contacts:contactDetails.noContactSelected'}
                    />
                  </Typography.Text>
                </div>
              }
            />
          </Routes>
        </Flex.Item>
      </Flex.Row>
    </Flex.Column>
  );
};

export default ProjectContactsSubModule;
