import React, { CSSProperties, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';

import { syncGlobalProjects } from '../../projects/actions';
import { Project } from '../../../models/Project';
import {
  RootReducerState,
  getAllProjects,
  getOffice,
  getProjectsIsFetching,
  getProjectsOverview,
  getUserMe,
  getInternalOfficesByIdState,
  getInternalOfficeIds,
} from '../../../apps/main/rootReducer';
import { distinct, isTemporaryId } from '../../../util';
import { useTranslation } from 'react-i18next';
import {
  FetchProjectContextType,
  OfficeId,
  OfficeRole,
  ProjectId,
} from '../../../models/Types';
import { Office } from '../../../models/Office';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortProjects } from '../utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
  },
}));

export const projectSelector = (
  contextType: FetchProjectContextType,
  officeId?: OfficeId,
  officeRoles?: OfficeRole[],
  onlyOfficeProjects?: boolean
) =>
  createSelector<
    [
      (state: RootReducerState) => Project[],
      (state: RootReducerState) => Project[],
      (state: RootReducerState) => { [id: OfficeId]: Office },
      (state: RootReducerState) => OfficeId[],
      (state: RootReducerState) => Office,
      (state: RootReducerState) => {
        [key: string]: OfficeRole[];
      },
    ],
    Project[]
  >(
    getAllProjects,
    getProjectsOverview,
    getInternalOfficesByIdState,
    getInternalOfficeIds,
    (state) => getOffice(state, officeId),
    (state) => getUserMe(state)?.prioData?.officeRoles,
    (
      projectsMe,
      projectsAll,
      officeById,
      officeIds,
      office,
      officeRolesByOfficeId
    ) => {
      if (contextType === 'office') {
        if (officeId) {
          return projectsAll.filter(
            (project) => office?.companyId === project?.subsidiaryId
          );
        }
        const officeProjects = projectsAll.filter((project) =>
          officeIds
            .map((id) => officeById[id])
            .filter((office) => !!office)
            .find(
              (office) =>
                (officeRoles ?? []).find(
                  (role) =>
                    officeRolesByOfficeId[office.officeId]?.includes(role)
                ) && office?.companyId === project?.subsidiaryId
            )
        );
        if (onlyOfficeProjects) {
          return officeProjects;
        }
        return distinct([...officeProjects, ...projectsMe]);
      } else {
        return contextType === 'me' ? projectsMe : projectsAll;
      }
    }
  );

declare type Placement = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';

interface ProjectPickerProps {
  value?: string | string[];
  label?: string;
  onChange?: (value: string | string[]) => void;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  filter?: (project: Project) => boolean;
  className?: string;
  fetch?: boolean;
  maxTagCount?: number;
  contextType?: FetchProjectContextType;
  officeId?: OfficeId;
  officeRoles?: OfficeRole[];
  onlyOfficeProjects?: boolean;
  onlyProjectIds?: ProjectId[];
  bordered?: boolean;
  dropdownStyle?: CSSProperties;
  placement?: Placement;
  dropdownMatchSelectWidth?: boolean | number;
}

export const ProjectPicker: React.FC<ProjectPickerProps> = (props) => {
  const classes = useStyles();
  const {
    className,
    value,
    label,
    onChange,
    disabled,
    multiple,
    fetch,
    filter,
    maxTagCount,
    contextType,
    officeId,
    officeRoles,
    onlyOfficeProjects,
    onlyProjectIds,
    bordered,
    dropdownStyle,
    placement,
    dropdownMatchSelectWidth,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (fetch) {
      if (contextType !== 'me') {
        dispatch(syncGlobalProjects());
      }
    }
  }, [dispatch, fetch, contextType]);

  const projectsFromSelector: Project[] = useSelector(
    projectSelector(
      contextType ?? 'me',
      officeId,
      officeRoles,
      onlyOfficeProjects
    )
  );

  const handleOnChange: (value: string) => void = onChange
    ? (value: string) => {
        onChange(value ?? '');
      }
    : null;

  const activeProjects = useMemo(() => {
    const filteredProjects = projectsFromSelector.filter(
      (project) =>
        !isTemporaryId(project.projectId) && (!filter || filter(project))
    );
    return sortProjects(filteredProjects);
  }, [projectsFromSelector, filter]);

  const projects = useMemo(
    () =>
      onlyProjectIds
        ? activeProjects.filter((project) =>
            onlyProjectIds.includes(project.projectId)
          )
        : activeProjects,
    [activeProjects, onlyProjectIds]
  );

  const isFetching = useSelector(getProjectsIsFetching);

  return (
    <Select
      maxTagCount={maxTagCount}
      maxTagPlaceholder={(omitted) =>
        t('common:select.omittedPlaceholder', { count: omitted.length })
      }
      className={classNames(classes.root, className, {
        [classes.disabledPicker]: disabled || isFetching,
      })}
      mode={multiple ? 'multiple' : null}
      showSearch
      value={value}
      placeholder={label}
      optionFilterProp="label"
      onChange={handleOnChange}
      filterOption={(input, option) =>
        option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || isFetching}
      loading={isFetching}
      bordered={bordered}
      dropdownStyle={dropdownStyle}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      placement={placement}
      options={(projects ?? []).map((project: Project) => ({
        value: project.projectId,
        label: (
          <>
            {project.parentProject && (
              <FontAwesomeIcon
                icon={['fal', 'horizontal-rule']}
                size="sm"
                className={classes.icon}
              />
            )}
            {`${project.number} ${project.shortName ?? project.name}`}
          </>
        ),
        title: `${project.number} ${project.shortName ?? project.name}`,
      }))}
    />
  );
};

export default ProjectPicker;
