import React from 'react';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { OfficeId } from '../../../models/Types';
import { HRAbsenceProposalPage } from './HRAbsenceProposalPage';

interface HRAbsenceAllProposalPageProps {
  className?: string;
  onRowSelectionChange?: (selectedProposals: AbsenceProposal[]) => void;
  officeId?: OfficeId;
}

export const HRAbsenceAllProposalPage: React.FC<
  HRAbsenceAllProposalPageProps
> = ({ className, onRowSelectionChange, officeId }) => {
  return (
    <HRAbsenceProposalPage
      className={className}
      onRowSelectionChange={onRowSelectionChange}
      officeId={officeId}
    />
  );
};

export default HRAbsenceAllProposalPage;
