import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  InteractJSEvent,
  TimelineGroup,
  TimelineItem,
} from '../../../../components/Timeline/types';
import { TimeAndLeaveManagementTimelineItem } from '../../../timeAndLeaveManagement/selectors';
import { DateTimeString } from '../../../../models/Types';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import { TimeRecord } from '../../../../models/TimeRecord';
import { TimeKeepingDay } from '../../../../models/TimeKeeping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../../../i18n';
import moment from 'moment';
import { getSnapPixelFromDelta } from '../../../../components/Timeline/utils';
import useResizeObserver from 'use-resize-observer';
import type { Interactable } from '@interactjs/core/Interactable';
import interact from 'interactjs';
import { v4 as uuid } from 'uuid';
import { TimeTrackingSuggestion } from '../../../../models/Calendar';

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  entryItem: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
  entryContent: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: `0 ${theme.old.spacing.baseSpacing}px`,
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  breakOuter: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  breakInner: {
    height: '85%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    color: theme.old.typography.colors.muted,
    backgroundColor: theme.old.palette.backgroundPalette.main,
    borderRadius: theme.old.borders.radius,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 10,
  },
}));

export interface TimeEntry {
  id: string;
  startTime: DateTimeString;
  endTime: DateTimeString;
  isBreak: boolean;
}

const renderTextToValueOfItemType = (
  value:
    | AbsenceProposal
    | TimeRecord
    | TimeKeepingDay
    | TimelineItem
    | TimeTrackingSuggestion,
  type: 'absence' | 'timeKeeping' | 'timeRecord' | 'timeTrackingSuggestion'
) => {
  switch (type) {
    case 'absence': {
      const _value = value as AbsenceProposal;
      if (moment(_value.from).isSame(_value.to, 'day')) {
        return `${i18n.t(
          `absences:form.absenceTypes.${_value.absenceType}`
        )}, ${moment(_value.from).format('DD.MM.YYYY')}`;
      }
      return `${i18n.t(
        `absences:form.absenceTypes.${_value.absenceType}`
      )}, ${moment(_value.from).format('DD.MM.YYYY')} - ${moment(
        _value.to
      ).format('DD.MM.YYYY')}`;
    }
    case 'timeKeeping': {
      const _value = value as TimeKeepingDay;
      const minStartTime = Math.min(
        ...(_value?.timeKeepingEntries ?? [0]).map((entry) =>
          new Date(entry.startTime).getTime()
        )
      );
      const maxEndTime = Math.max(
        ...(_value?.timeKeepingEntries ?? [0]).map((entry) =>
          new Date(entry.endTime).getTime()
        )
      );
      return i18n.t('common:toWithDates', {
        start: moment(minStartTime).format('HH:mm'),
        end: moment(maxEndTime).format('HH:mm'),
      });
    }
    case 'timeRecord': {
      return (value as TimeRecord)?.title ?? '';
    }
    default: {
      return '';
    }
  }
};

const setPopoverDisplay = (display: 'flex' | 'none') => {
  try {
    const popovers = document.getElementsByClassName(
      'prio-timelinine-item-popover'
    );
    for (let i = 0; i < popovers.length; i++) {
      const element = popovers.item(i) as HTMLDivElement;
      if (element) {
        element.style.display = display;
      }
    }
  } catch (e) {}
};

interface TimeKeepingTimelinieItemProps {
  className?: string;
  item: TimeAndLeaveManagementTimelineItem;
  group: TimelineGroup;
  pixelsPerMs: number;
  gridSnapInMilliseconds: number;
  onEntriesChange: (
    entries: TimeEntry[],
    item: TimeAndLeaveManagementTimelineItem,
    group: TimelineGroup
  ) => Promise<boolean>;
}

export const TimeKeepingTimelinieItem: React.FC<
  TimeKeepingTimelinieItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    item,
    group,
    pixelsPerMs,
    gridSnapInMilliseconds,
    onEntriesChange,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { type, value } = item;

  const entries: TimeEntry[] = useMemo(
    () =>
      (
        item.entries ?? [
          {
            startTime: item.startDateTime,
            endTime: item.endDateTime,
          },
        ]
      ).map((entry) => ({
        ...entry,
        isBreak: false,
        id: uuid(),
      })),
    [item]
  );

  const interactableBreaks = useRef<Interactable>(null);

  const sortedEntriesWithBreaks: (TimeEntry & {
    width: number;
  })[] = useMemo(
    () =>
      entries
        .sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        )
        .reduce((currentArray, entry, index, array) => {
          const { startTime, endTime } = entry;
          const start = new Date(startTime).getTime();
          const end = new Date(endTime).getTime();
          const width = (end - start) * pixelsPerMs;
          if (currentArray.length === 0) {
            return [
              {
                ...entry,
                width: width - gridSnapInMilliseconds * pixelsPerMs,
              },
            ];
          }
          const lastEntry = currentArray[currentArray.length - 1];

          const lastEntryEnd = new Date(lastEntry.endTime).getTime();
          const entryStart = new Date(entry.startTime).getTime();

          if (lastEntryEnd < entryStart) {
            const breakWidth = (entryStart - lastEntryEnd) * pixelsPerMs;
            currentArray.push({
              id: uuid(),
              startTime: lastEntry.endTime,
              endTime: entry.startTime,
              isBreak: true,
              width: breakWidth,
            });
          }
          currentArray.push({
            ...entry,
            width:
              array.length - 1 === index
                ? width - gridSnapInMilliseconds * pixelsPerMs
                : width,
          });

          return currentArray;
        }, []),
    [entries, pixelsPerMs, gridSnapInMilliseconds]
  );

  const itemStartTime = useMemo(
    () =>
      Math.min(
        ...entries.map(({ startTime }) => new Date(startTime).getTime()),
        ...(entries.length === 0 ? [0] : [])
      ),
    [entries]
  );

  const itemEndTime = useMemo(
    () =>
      Math.max(
        ...entries.map(({ endTime }) => new Date(endTime).getTime()),
        ...(entries.length === 0 ? [0] : [])
      ),
    [entries]
  );

  const initialWidth = useMemo(
    () =>
      (itemEndTime - itemStartTime) * pixelsPerMs -
      2 * gridSnapInMilliseconds * pixelsPerMs,
    [itemEndTime, itemStartTime, pixelsPerMs, gridSnapInMilliseconds]
  );

  const title = useMemo(
    () => renderTextToValueOfItemType(value, type),
    [value, type]
  );

  const sumBreaksWidth = useMemo(() => {
    const breaks = sortedEntriesWithBreaks.filter(({ isBreak }) => isBreak);
    const width = breaks.reduce((sum, { width }) => sum + width, 0);
    return (
      width +
      Math.floor(breaks.length / 2) * gridSnapInMilliseconds * pixelsPerMs
    );
  }, [sortedEntriesWithBreaks, gridSnapInMilliseconds, pixelsPerMs]);

  const { ref, width } = useResizeObserver();
  const snappedWidthDelta = getSnapPixelFromDelta(
    initialWidth - (width ?? 0),
    pixelsPerMs,
    gridSnapInMilliseconds
  );

  const sortedEntriesBreaksOnly: (TimeEntry & {
    width: number;
    left: number;
  })[] = useMemo(
    () =>
      sortedEntriesWithBreaks
        .filter(({ isBreak }) => isBreak)
        .reduce((currentArray, entry, index, array) => {
          const { startTime } = entry;
          const delta =
            (new Date(startTime).getTime() - itemStartTime) * pixelsPerMs -
            gridSnapInMilliseconds * pixelsPerMs;

          let left =
            delta - snappedWidthDelta < 0 ? 0 : delta - snappedWidthDelta;
          if (
            index > 0 &&
            left <
              currentArray[index - 1].left +
                currentArray[index - 1].width +
                gridSnapInMilliseconds * pixelsPerMs
          ) {
            left =
              currentArray[index - 1].left +
              currentArray[index - 1].width +
              gridSnapInMilliseconds * pixelsPerMs;
          }
          currentArray.push({
            ...entry,
            left,
          });
          return currentArray;
        }, []),
    [
      sortedEntriesWithBreaks,
      itemStartTime,
      pixelsPerMs,
      snappedWidthDelta,
      gridSnapInMilliseconds,
    ]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleResizeEnd = useCallback(
    async (
      id: string,
      deltaXInS: number,
      deltaWInS: number,
      initialLeft: number,
      initialWidth: number
    ) => {
      const breakIndex = sortedEntriesWithBreaks.findIndex(
        ({ id: _id }) => id === _id
      );
      if (breakIndex > -1) {
        if (deltaXInS === 0) {
          const entryIndex = entries.findIndex(
            ({ id: _id }) => _id === sortedEntriesWithBreaks[breakIndex + 1].id
          );
          if (entryIndex > -1) {
            const { startTime, ...rest } = entries[entryIndex];
            const _entries = [...entries];
            _entries.splice(entryIndex, 1, {
              ...rest,
              startTime: moment(startTime)
                .add(deltaWInS, 'seconds')
                .toISOString(true)
                .split('.')[0],
            });
            const success = await onEntriesChange(_entries, item, group);

            const _breakIndex = sortedEntriesBreaksOnly.findIndex(
              ({ id: _id }) => id === _id
            );
            if (!success && _breakIndex > -1) {
              try {
                const element: HTMLElement = document
                  .querySelectorAll(
                    `.prio-timeline-item[data-item-id="${item.id}"] .prio-timeline-item-break`
                  )
                  ?.item(_breakIndex) as HTMLElement;

                element.style.width = `${initialWidth}px`;
                element.style.left = `${initialLeft}px`;
                element.setAttribute('initialWidth', `${initialWidth}px`);
                element.setAttribute('initialLeft', `${initialLeft}px`);
              } catch (error) {}
            }
          }
        } else {
          const entryIndex = entries.findIndex(
            ({ id: _id }) => _id === sortedEntriesWithBreaks[breakIndex - 1].id
          );
          if (entryIndex > -1) {
            const { endTime, ...rest } = entries[entryIndex];
            const _entries = [...entries];
            _entries.splice(entryIndex, 1, {
              ...rest,
              endTime: moment(endTime)
                .add(deltaXInS, 'seconds')
                .toISOString(true)
                .split('.')[0],
            });
            const success = await onEntriesChange(_entries, item, group);

            const _breakIndex = sortedEntriesBreaksOnly.findIndex(
              ({ id: _id }) => id === _id
            );
            if (!success && _breakIndex > -1) {
              try {
                const element: HTMLElement = document
                  .querySelectorAll(
                    `.prio-timeline-item[data-item-id="${item.id}"] .prio-timeline-item-break`
                  )
                  ?.item(_breakIndex) as HTMLElement;

                element.style.width = `${initialWidth}px`;
                element.style.left = `${initialLeft}px`;
                element.setAttribute('initialWidth', `${initialWidth}px`);
                element.setAttribute('initialLeft', `${initialLeft}px`);
              } catch (error) {}
            }
          }
        }
      }
    },
    [
      entries,
      sortedEntriesWithBreaks,
      sortedEntriesBreaksOnly,
      item,
      group,
      onEntriesChange,
    ]
  );

  const handleDragEnd = useCallback(
    async (id: string, deltaXInS: number, initialLeft: number) => {
      const breakIndex = sortedEntriesWithBreaks.findIndex(
        ({ id: _id }) => id === _id
      );
      if (breakIndex > -1) {
        const leftEntryIndex = entries.findIndex(
          ({ id: _id }) => _id === sortedEntriesWithBreaks[breakIndex - 1].id
        );
        const rightEntryIndex = entries.findIndex(
          ({ id: _id }) => _id === sortedEntriesWithBreaks[breakIndex + 1].id
        );
        if (leftEntryIndex > -1 && rightEntryIndex > -1) {
          const { endTime: leftEndTime, ...leftRest } = entries[leftEntryIndex];
          const { startTime: rightStartTime, ...rightRest } =
            entries[rightEntryIndex];
          const _entries = [...entries];
          _entries.splice(leftEntryIndex, 1, {
            ...leftRest,
            endTime: moment(leftEndTime)
              .add(deltaXInS, 'seconds')
              .toISOString(true)
              .split('.')[0],
          });
          _entries.splice(rightEntryIndex, 1, {
            ...rightRest,
            startTime: moment(rightStartTime)
              .add(deltaXInS, 'seconds')
              .toISOString(true)
              .split('.')[0],
          });
          const success = await onEntriesChange(_entries, item, group);

          const _breakIndex = sortedEntriesBreaksOnly.findIndex(
            ({ id: _id }) => id === _id
          );
          if (!success && _breakIndex > -1) {
            try {
              const element: HTMLElement = document
                .querySelectorAll(
                  `.prio-timeline-item[data-item-id="${item.id}"] .prio-timeline-item-break`
                )
                ?.item(_breakIndex) as HTMLElement;
              element.style.left = `${initialLeft}px`;
              element.setAttribute('initialLeft', `${initialLeft}px`);
            } catch (error) {}
          }
        }
      }
    },
    [
      entries,
      sortedEntriesWithBreaks,
      sortedEntriesBreaksOnly,
      item,
      group,
      onEntriesChange,
    ]
  );

  const handleBreakRemove = (id: string) => {
    const breakIndex = sortedEntriesWithBreaks.findIndex(
      ({ id: _id }) => id === _id
    );
    if (breakIndex > -1) {
      const leftEntryIndex = entries.findIndex(
        ({ id: _id }) => _id === sortedEntriesWithBreaks[breakIndex - 1].id
      );
      const rightEntryIndex = entries.findIndex(
        ({ id: _id }) => _id === sortedEntriesWithBreaks[breakIndex + 1].id
      );
      if (leftEntryIndex > -1 && rightEntryIndex > -1) {
        const { endTime: leftEndTime, ...leftRest } = entries[leftEntryIndex];
        const { endTime: rightEndTime } = entries[rightEntryIndex];
        const _entries = [...entries];
        _entries.splice(leftEntryIndex, 1, {
          ...leftRest,
          endTime: rightEndTime,
        });
        _entries.splice(rightEntryIndex, 1);
        onEntriesChange(_entries, item, group);
      }
    }
  };

  const handleOnKeydown: (
    event: React.KeyboardEvent<HTMLDivElement>,
    id: string
  ) => void = (event, id) => {
    switch (event.key) {
      case 'Delete': {
        event.stopPropagation();
        event.preventDefault();
        handleBreakRemove(id);
        break;
      }
      default: {
        break;
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (item) {
      const element: HTMLElement = document
        .querySelectorAll(`.prio-timeline-item[data-item-id="${item.id}"]`)
        ?.item(0) as HTMLElement;
      if (element) {
        if (sumBreaksWidth > 0) {
          element.style.minWidth = `${
            sumBreaksWidth + 2 * gridSnapInMilliseconds * pixelsPerMs
          }px`;
        } else {
          element.style.minWidth = `${gridSnapInMilliseconds * pixelsPerMs}px`;
        }
      }
    }
  }, [item, sumBreaksWidth, gridSnapInMilliseconds, pixelsPerMs]);

  useEffect(() => {
    if (interactableBreaks?.current) {
      interactableBreaks?.current.unset();
    }
    interactableBreaks.current = interact(
      `.prio-timeline-item[data-item-id="${item.id}"] .${classes.breakOuter}`
    )
      .resizable({
        enabled: !item.disabled,
        edges: { left: true, right: true, bottom: false, top: false },
        margin: 7,
        modifiers: [
          interact.modifiers.snapSize({
            targets: [
              interact.snappers.grid({
                width: pixelsPerMs * gridSnapInMilliseconds,
                height: 50,
              }),
            ],
          }),
        ],
      })
      .on('resizestart', (e: InteractJSEvent) => {
        const _item = e.currentTarget;
        _item.setAttribute('initialWidth', _item.style.width);
        _item.setAttribute('initialLeft', _item.style.left);
        _item.setAttribute('drag-x', '0');
        setPopoverDisplay('none');
        try {
          const element: HTMLElement = document
            .querySelectorAll(`.prio-timeline-item[data-item-id="${item.id}"]`)
            ?.item(0) as HTMLElement;
          element.setAttribute('isResizing', 'true');
        } catch (error) {}
      })
      .on('resizemove', (e: InteractJSEvent) => {
        const resizeHandler = e.edges.left ? 'left' : 'right';
        const item = e.currentTarget;
        const _initialLeft = parseFloat(
          e.target.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
        );
        const _initialWidth = parseFloat(
          e.target.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
        );

        const deltaX = (parseFloat(item.getAttribute('drag-x')) || 0) + e.dx;
        const deltaWidth = e.rect.width - _initialWidth;

        if (_initialWidth + deltaWidth > 1) {
          const breakEntryIndex = sortedEntriesBreaksOnly.findIndex(
            ({ id }) => id === e.target.getAttribute('data-id')
          );
          if (resizeHandler === 'left') {
            if (breakEntryIndex > -1) {
              const { left, width } =
                breakEntryIndex === 0
                  ? { left: -pixelsPerMs * gridSnapInMilliseconds, width: 0 }
                  : sortedEntriesBreaksOnly[breakEntryIndex - 1];
              if (_initialLeft + deltaX > left + width) {
                e.target.style.left = `${_initialLeft + deltaX}px`;
                e.target.style.width = `${_initialWidth + deltaWidth}px`;
              }
            }
          } else {
            if (breakEntryIndex > -1) {
              const left =
                breakEntryIndex < sortedEntriesBreaksOnly.length - 1
                  ? sortedEntriesBreaksOnly[breakEntryIndex + 1].left - 1
                  : initialWidth + 1;
              if (left > _initialLeft + _initialWidth + deltaWidth) {
                e.target.style.width = `${_initialWidth + deltaWidth}px`;
              }
            }
          }
        }
        item.setAttribute('drag-x', `${deltaX}`);
      })
      .on('resizeend', (e: InteractJSEvent) => {
        const _item = e.currentTarget;
        const id = _item.getAttribute('data-id');
        const width = parseFloat(_item.style.width?.replace(/px$/, '') ?? '0');
        const _initialWidth = parseFloat(
          _item.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
        );

        const left = parseFloat(_item.style.left?.replace(/px$/, '') ?? '0');
        const _initialLeft = parseFloat(
          _item.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
        );

        const deltaXInS =
          Math.round((1 / (pixelsPerMs * 1000 * 60)) * (left - _initialLeft)) *
          60;
        const deltaWInS =
          Math.round(
            (1 / (pixelsPerMs * 1000 * 60)) * (width - _initialWidth)
          ) * 60;

        if (deltaWInS !== 0) {
          handleResizeEnd(
            id,
            deltaXInS,
            deltaWInS,
            _initialLeft,
            _initialWidth
          );
        }

        _item.setAttribute('initialLeft', _item.style.left);
        _item.setAttribute('initialWidth', _item.style.width);
        _item.setAttribute('drag-x', '0');
        setPopoverDisplay('flex');
        try {
          const element: HTMLElement = document
            .querySelectorAll(`.prio-timeline-item[data-item-id="${item.id}"]`)
            ?.item(0) as HTMLElement;
          element.setAttribute('isResizing', 'false');
        } catch (error) {}
      })
      .draggable({
        enabled: !item.disabled,
        modifiers: [
          interact.modifiers.snapSize({
            targets: [
              interact.snappers.grid({
                width: pixelsPerMs * gridSnapInMilliseconds,
                height: 50,
              }),
            ],
          }),
        ],
      })
      .on('dragstart', (e: InteractJSEvent) => {
        const _item = e.target;
        _item.setAttribute('initialLeft', _item.style.left);
        _item.setAttribute('initialWidth', _item.style.width);
        _item.setAttribute('drag-x', '0');
        setPopoverDisplay('none');
        try {
          const element: HTMLElement = document
            .querySelectorAll(`.prio-timeline-item[data-item-id="${item.id}"]`)
            ?.item(0) as HTMLElement;
          element.setAttribute('isDragging', 'true');
        } catch (error) {}
      })
      .on('dragmove', (e: InteractJSEvent) => {
        const target = e.target;
        let dx = (parseFloat(target.getAttribute('drag-x')) || 0) + e.dx;
        const _initialLeft = parseFloat(
          e.target.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
        );
        const _initialWidth = parseFloat(
          e.target.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
        );

        const breakEntryIndex = sortedEntriesBreaksOnly.findIndex(
          ({ id }) => id === e.target.getAttribute('data-id')
        );
        if (breakEntryIndex > -1) {
          const leftBreak =
            breakEntryIndex === 0
              ? { left: -1, width: 0 }
              : sortedEntriesBreaksOnly[breakEntryIndex - 1];

          const rightBreakLeftBoundary =
            breakEntryIndex < sortedEntriesBreaksOnly.length - 1
              ? sortedEntriesBreaksOnly[breakEntryIndex + 1].left - 1
              : initialWidth + 1;

          if (
            leftBreak.left + leftBreak.width < _initialLeft + dx &&
            rightBreakLeftBoundary > _initialLeft + dx + _initialWidth
          ) {
            e.target.style.left = `${_initialLeft + dx}px`;

            target.setAttribute('drag-x', `${dx}`);
          }
        }
      })
      .on('dragend', (e: InteractJSEvent) => {
        const _item = e.target;
        _item.setAttribute('isDragging', 'false');
        const itemId = _item.getAttribute('data-id');

        const left = parseFloat(_item.style.left?.replace(/px$/, '') ?? '0');
        const initialLeft = parseFloat(
          _item.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
        );

        const deltaXInS =
          Math.round((1 / (pixelsPerMs * 1000 * 60)) * (left - initialLeft)) *
          60;

        if (deltaXInS !== 0) {
          handleDragEnd(itemId, deltaXInS, initialLeft);
        }

        _item.setAttribute('initialLeft', _item.style.left);
        _item.setAttribute('initialWidth', _item.style.width);
        _item.setAttribute('drag-x', '0');
        setPopoverDisplay('flex');
        try {
          const element: HTMLElement = document
            .querySelectorAll(`.prio-timeline-item[data-item-id="${item.id}"]`)
            ?.item(0) as HTMLElement;
          element.setAttribute('isDragging', 'false');
        } catch (error) {}
      });
  }, [
    classes.breakOuter,
    item,
    interactableBreaks,
    sortedEntriesWithBreaks,
    sortedEntriesBreaksOnly,
    pixelsPerMs,
    gridSnapInMilliseconds,
    initialWidth,
    handleResizeEnd,
    handleDragEnd,
  ]);
  //#endregion

  return (
    <div
      className={classNames(classes.root, className)}
      title={title}
      style={{ minWidth: sumBreaksWidth }}
      ref={ref}
    >
      {sortedEntriesWithBreaks.map(({ isBreak, width }, index) => {
        return (
          <div
            key={index}
            className={classes.entryItem}
            style={{ width, minWidth: isBreak ? width : undefined }}
          >
            {isBreak ? (
              <div
                className={classes.entryContent}
                style={{ height: '100%' }}
              />
            ) : (
              <div className={classes.entryContent}>
                {index === 0 ? title : null}
              </div>
            )}
          </div>
        );
      })}
      {sortedEntriesBreaksOnly.map(({ width, left, id }) => {
        return (
          <>
            <div
              className={classes.overlay}
              style={{
                width: 2 * gridSnapInMilliseconds * pixelsPerMs,
                left: left - 2 * gridSnapInMilliseconds * pixelsPerMs,
              }}
              data-id={id}
            />
            <div
              className={classNames(
                'prio-timeline-item-break',
                classes.breakOuter
              )}
              style={{
                width,
                left,
              }}
              data-id={id}
              tabIndex={0}
              onKeyDown={(event) => handleOnKeydown(event, id)}
            >
              <div className={classes.breakInner}>
                <FontAwesomeIcon icon={['fal', 'coffee']} />
              </div>
            </div>
            <div
              className={classes.overlay}
              style={{
                width: gridSnapInMilliseconds * pixelsPerMs,
                left: left + width,
              }}
              data-id={id}
            />
          </>
        );
      })}
    </div>
  );
};

export default TimeKeepingTimelinieItem;
