import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Flex from '../../../../components/Flex';

import CreateProjectSelect from './CreateProjectSelect';
import CreateProjectWrapper from './CreateProjectWrapper';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    padding: `${theme.spacing.unit(4)}px ${theme.spacing.unit(4)}px`,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    width: '100%',
    overflow: 'auto',
  },
  form: {
    marginTop: theme.spacing.unit(4),
  },
  fullPage: {
    height: '100%',
    width: '100%',
  },
  steps: {
    width: '100%',
    marginBottom: `${theme.spacing.baseSpacing * 4}px`,
  },
}));

interface CreateProjectProps {}

export const CreateProject: React.FC<CreateProjectProps> = (props) => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const step = queryParams.get('step');

  return (
    <Flex.Column className={classes.root} alignItems="center">
      <Routes>
        <Route
          path="*"
          element={<CreateProjectSelect className={classes.form} />}
        />
        <Route
          path="step/*"
          element={<CreateProjectWrapper className={classes.form} />}
        ></Route>
        <Route
          path="step/:projectId/*"
          element={
            <CreateProjectWrapper
              className={classes.form}
              setActiveKey={step}
            />
          }
        ></Route>
      </Routes>
    </Flex.Column>
  );
};

export default CreateProject;
