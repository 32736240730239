import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import GridLayout, { Layout } from 'react-grid-layout';
import DashboardBaseItem from './DashboardBaseItem';
import { DashboardItem, DashboardWidgetType } from '../../../models/Dashboard';
import { v4 as uuid } from 'uuid';
import useAccessRights from '../../users/hooks/useAccessRights';
import * as ConfigValues from '../../../util/configValues';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    '& .react-grid-placeholder': {
      backgroundColor: theme.old.palette.backgroundPalette.active.content,
    },
    '& .react-grid-item': {
      backgroundColor: theme.old.palette.backgroundPalette.content,
      boxShadow: theme.old.palette.boxShadow.regular,
      '& > .react-resizable-handle': {
        visibility: 'hidden',
      },
      '&:hover > .react-resizable-handle': {
        visibility: 'visible',
      },
    },
  },
  item: {
    padding: theme.old.spacing.unit(2),
    overflow: 'hidden',
  },
}));

const typeToMinSize = (type: DashboardWidgetType) => {
  switch (type) {
    default: {
      return {
        minW: 3,
        minH: 2,
      };
    }
  }
};

interface DashboardProps {
  className?: string;
  dashboardItems?: DashboardItem[];
}

export const Dashboard: React.FC<DashboardProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, dashboardItems } = props;
  const classes = useStyles(props);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { showHrModule } = useAccessRights(['showHrModule']);

  const items: DashboardItem[] = useMemo(
    () =>
      dashboardItems
        ? dashboardItems
        : [
            {
              dashboardItemId: uuid(),
              type: 'timeKeepingToday',
              layout: {
                x: 0,
                y: 0,
                width: 3,
                height: 2,
              },
            },
            {
              dashboardItemId: uuid(),
              type: 'openTimeKeepings',
              layout: {
                x: 3,
                y: 0,
                width: 3,
                height: 2,
              },
            },
            ...((showHrModule
              ? [
                  {
                    dashboardItemId: uuid(),
                    type: 'timekeepingHR',
                    layout: {
                      x: 6,
                      y: 0,
                      width: 3,
                      height: 2,
                    },
                  },
                ]
              : []) as DashboardItem[]),

            {
              dashboardItemId: uuid(),
              type: 'absenceOverview',
              layout: {
                x: 0,
                y: 2,
                width: 3,
                height: 2,
              },
            },
            {
              dashboardItemId: uuid(),
              type: 'upcomingAbsences',
              layout: {
                x: 3,
                y: 2,
                width: 3,
                height: 2,
              },
            },
            ...(ConfigValues.RETEACH_URL
              ? [
                  {
                    dashboardItemId: uuid(),
                    type: 'reteachOverview',
                    layout: {
                      x: 6,
                      y: 2,
                      width: 3,
                      height: 2,
                    },
                  } as DashboardItem,
                ]
              : []),

            // TODO: Comment in when Timekeeping is available
            /*
    {
      dashboardItemId: uuid(),
      type: 'timekeepingInfo',
      layout: {
        x: 0,
        y: 0,
        width: 3,
        height: 2,
      },
    },
    {
      dashboardItemId: uuid(),
      type: 'absenceNotifications',
      layout: {
        x: 6,
        y: 0,
        width: 3,
        height: 3,
      },
    },
    {
      dashboardItemId: uuid(),
      type: 'inboxOverview',
      layout: {
        x: 6,
        y: 0,
        width: 3,
        height: 3,
      },
    },
    */
          ],
    [showHrModule, dashboardItems]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <GridLayout
        className="layout"
        isDraggable={false}
        isResizable={false}
        layout={items.map<Layout>(
          ({ dashboardItemId, layout: { x, y, width, height }, type }) => ({
            x,
            y,
            w: width,
            h: height,
            i: dashboardItemId,
            ...typeToMinSize(type),
          })
        )}
        cols={12}
        width={1920}
        rowHeight={160}
      >
        {items.map(({ dashboardItemId, type, data }) => (
          <div key={dashboardItemId} className={classes.item}>
            <DashboardBaseItem
              dashboardItemId={dashboardItemId}
              type={type}
              data={data}
            />
          </div>
        ))}
      </GridLayout>
    </div>
  );
};

export default Dashboard;
