import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import { Form, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import AbsenceOverviewChart from '../../../absences/components/overview/AbsenceOverviewChart';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAbsenceByIdState,
  getAbsenceIds,
  getAbsenceOverview,
  getAbsencesMeIsFetching,
  getMonthlyCloseMeByMonth,
} from '../../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { AbsencesById } from '../../../absences/reducers/absences';
import { AbsenceProposalId } from '../../../../models/Types';
import moment from 'moment';
import { debounceAbsencesMe } from '../../../absences/actions';
import { Link } from 'react-router-dom';
import { colon } from '../../../../util/forms';
import { MonthlyClose } from '../../../../models/TimeKeeping';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  table: {
    marginTop: theme.old.spacing.unit(3),
    flex: 1,
    overflow: 'hidden',
    '& .ant-table': {
      fontSize: theme.old.typography.fontSize.small,
    },
    '& .ant-table-tbody > tr > td': {
      padding: theme.old.spacing.baseSpacing,
    },
  },
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overtimePanel: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    padding: '3px 6px',
    margin: '0px 6px 3px 6px',
    height: '58px',
    '& .ant-form-item-label': {
      padding: '0px',
    },
  },
}));

const absenceProposalSelector = createSelector<
  [
    (state: RootReducerState) => AbsencesById,
    (state: RootReducerState) => AbsenceProposalId[],
  ],
  AbsenceProposal[]
>(getAbsenceByIdState, getAbsenceIds, (absencesById, absenceIds) => {
  return absenceIds
    .reduce((currentArray, id) => {
      const absence = absencesById[id];
      if (absence && moment(absence.to).isSameOrAfter(moment())) {
        currentArray.push(absence);
      }
      return currentArray;
    }, [] as AbsenceProposal[])
    .sort((a, b) => {
      return moment(a.from).diff(moment(b.from));
    });
});

interface DashboardAbsenceOverviewItemProps {
  className?: string;
}

export const DashboardAbsenceOverviewItem: React.FC<
  DashboardAbsenceOverviewItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const absenceOverview = useSelector(getAbsenceOverview);
  const absenceProposals = useSelector(absenceProposalSelector);

  const isFetching = useSelector(getAbsencesMeIsFetching);

  const monthlyCloseMeThisMonth = useSelector<RootReducerState, MonthlyClose>(
    (state) => getMonthlyCloseMeByMonth(state, moment().format('YYYY-MM'))
  );

  const currentOvertimeHours =
    monthlyCloseMeThisMonth?.accumulatedOvertimeHours ?? 0;

  const currentMonthOvertimeChange =
    monthlyCloseMeThisMonth?.overtimeHoursChange ?? 0;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(debounceAbsencesMe());
  }, [dispatch]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      {isFetching && !absenceOverview ? (
        <Flex.Item flex={1} className={classes.spinner}>
          <PrioSpinner size="large" />
        </Flex.Item>
      ) : (
        <>
          <Flex.Row
            childrenGap={theme.old.spacing.baseSpacing}
            marginBottom={8}
          >
            <Flex.Item flex={1}>
              <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
                {t('dashboard:holidayEntitlementAndOvertime.title')}
              </Typography.Title>
            </Flex.Item>
            <Link to={'../timeAndLeaveManagement/absences'}>
              <Button
                iconProp={['fal', 'arrow-right-to-bracket']}
                type="default"
                style={{ fontSize: 16 }}
              />
            </Link>
          </Flex.Row>
          <AbsenceOverviewChart
            absenceOverview={{ ...absenceOverview, absenceProposals }}
          />
          <Flex.Row childrenGap={theme.old.spacing.baseSpacing}>
            <Typography.Title
              level={4}
              ellipsis
              style={{ marginTop: 24, marginLeft: 6 }}
            >
              {t('dashboard:holidayEntitlementAndOvertime.overtime')}
            </Typography.Title>
          </Flex.Row>
          {monthlyCloseMeThisMonth && (
            <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
              <Flex.Column flex={1}>
                <Form
                  layout="vertical"
                  className={classNames(classes.overtimePanel)}
                >
                  <Form.Item
                    name={'1'}
                    label={t(
                      'dashboard:holidayEntitlementAndOvertime.overtimeTotal'
                    )}
                    colon={colon}
                    style={{
                      color:
                        (currentOvertimeHours ?? 0) < 0
                          ? theme.old.palette.chromaticPalette.red
                          : (currentOvertimeHours ?? 0) > 0
                          ? theme.old.palette.chromaticPalette.green
                          : undefined,
                    }}
                  >
                    {`${currentOvertimeHours} h`}
                  </Form.Item>
                </Form>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form
                  layout="vertical"
                  className={classNames(classes.overtimePanel)}
                >
                  <Form.Item
                    name={'2'}
                    label={t(
                      'dashboard:holidayEntitlementAndOvertime.overtimeChangeCurrentMonth',
                      {
                        month: moment().format('MMMM'),
                      }
                    )}
                    colon={colon}
                  >
                    <Flex.Row
                      childrenGap={theme.old.spacing.unit(1)}
                      alignItems="center"
                    >
                      <span
                        style={{
                          color:
                            (currentMonthOvertimeChange ?? 0) < 0
                              ? theme.old.palette.chromaticPalette.red
                              : (currentMonthOvertimeChange ?? 0) > 0
                              ? theme.old.palette.chromaticPalette.green
                              : undefined,
                        }}
                      >{`${currentMonthOvertimeChange > 0 ? '+' : ''}${
                        currentMonthOvertimeChange ?? 0
                      } h`}</span>
                    </Flex.Row>
                  </Form.Item>
                </Form>
              </Flex.Column>
            </Flex.Row>
          )}
        </>
      )}
    </Flex.Column>
  );
};

export default DashboardAbsenceOverviewItem;
