import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import CreateProjectDataForm from './CreateProjectDataForm';
import { PrioTheme } from '../../../../theme/types';
import CreateProjectControllingForm from './CreateProjectControllingForm';
import CreateProjectAddInForm from './CreateProjectAddIn';
import CreateProjectMemberForm from './CreateProjectMember';
import CreateProjectSummaryForm from './CreateProjectSummary';
import { useNavigate, useParams } from 'react-router-dom';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    overflow: 'hidden',
    minHeight: `${theme.spacing.unit(8)}px`,
  },
  wrapContainerWhiteMain: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    overflow: 'hidden',
    height: '100%',
  },
  contentContainer: {
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  contentContainerFloat: {
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  contentScrollable: {
    overflowY: 'auto',
    height: '100%',
    paddingBottom: `${theme.spacing.unit(4)}px`,
  },

  rootTabs: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    '& .ant-tabs-nav': {
      padding: `0px ${theme.spacing.unit(2)}px`,
      margin: '0px',
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
    '& .ant-tabs-content': {
      backgroundColor: theme.old.palette.backgroundPalette.sub,
      marginTop: `${theme.spacing.unit(2)}px`,
      height: '100%',
    },
    '& .ant-tabs-content-holder': {
      backgroundColor: theme.old.palette.backgroundPalette.sub,
    },
  },
  rootTabPane: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    height: '100%',
  },
}));

interface CreateProjectWrapperProps {
  className?: string;
  setActiveKey?: string;
}

export const CreateProjectWrapper: React.FC<CreateProjectWrapperProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  // eslint-disable-next-line
  const { className, setActiveKey } = props;
  // eslint-disable-next-line
  const theme = useTheme() as PrioTheme;
  const classes = useStyles();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion
  const [activeTabKey, setActiveTabKey] = useState<string>(
    setActiveKey ?? 'data'
  );
  const [activeProjectId, setActiveProjectId] = useState<string>(projectId);

  const tabsChange = (activeKey: string) => {
    nextStep(projectId, activeKey);
  };

  const nextStep = async (projectIdToSet: string, step: string) => {
    setActiveProjectId(projectIdToSet);
    setActiveTabKey(step);
    navigate(`../step/${projectIdToSet}?step=${step}`);
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.wrapContainerWhiteMain}>
          <Tabs
            animated={false}
            defaultActiveKey="data"
            activeKey={activeTabKey}
            onChange={tabsChange}
            className={classes.rootTabs}
          >
            <TabPane
              tab={<>{t('projects:projectCreation.tabs.data')}</>}
              key="data"
              className={classes.rootTabPane}
            >
              <CreateProjectDataForm
                nextStep={nextStep}
                projectId={activeProjectId}
              ></CreateProjectDataForm>
            </TabPane>
            <TabPane
              tab={<>{t('projects:projectCreation.tabs.settings')}</>}
              key="settings"
              disabled={activeProjectId === undefined}
            >
              <CreateProjectControllingForm
                nextStep={nextStep}
                projectId={activeProjectId}
              />
            </TabPane>
            <TabPane
              tab={<>{t('projects:projectCreation.tabs.member')}</>}
              key="member"
              disabled={activeProjectId === undefined}
            >
              <CreateProjectMemberForm
                nextStep={nextStep}
                projectId={activeProjectId}
              />
            </TabPane>
            <TabPane
              tab={<>{t('projects:projectCreation.tabs.addins')}</>}
              key="addins"
              disabled={activeProjectId === undefined}
            >
              <CreateProjectAddInForm
                nextStep={nextStep}
                projectId={activeProjectId}
              />
            </TabPane>
            <TabPane
              tab={<>{t('projects:projectCreation.tabs.summary')}</>}
              key="summary"
              disabled={activeProjectId === undefined}
            >
              <CreateProjectSummaryForm
                nextStep={nextStep}
                projectId={activeProjectId}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default CreateProjectWrapper;
