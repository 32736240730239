import {
  ForwardRefRenderFunction,
  MutableRefObject,
  ReactNode,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MailFolderId, ProjectId } from '../../../../models/Types';

import moment from 'moment';
import Flex from '../../../../components/Flex';
import { SpecialMailFolders } from '../../actions/types';
import {
  AdvancedMailSearchDto,
  AdvancedMailSearchFlagStatus,
  MailSearchFormModel,
} from '../../../../models/MailSearch';
import { MailFolder } from '../../../../models/MailFolder';

import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

import AdressSelect from '../AdressSelect';
import { Importance } from '../../../../models/Message';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import MailCategorySelect from '../Categories/MessageDetailsCategorySelect';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { useMailSearch } from './useMailSearch';
import DebouncedInputSearch from '../../../../components/DebouncedInputField/DebouncedInputSearch';
import {
  UltimateFilterTagConfig,
  UltimateFilterSearchRef,
  createUltimateFilter,
  IgnoreValueConfig,
  countPropertiesWithValues,
} from '../../../../components/UltimateFilter/createUltimateFilter';
import ContactPicker from '../../../contacts/components/ContactPicker';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import {
  QuickSearchConfig,
  UltimateFilterQuickSearchTags,
} from '../../../../components/UltimateFilter/QuickSearch/UltimateFilterQuickSearchTags';
import classNames from 'classnames';
import { MailFilterContext } from './MailFilterContext';
import { colorFromPreset } from '../../util';

const { UltimateFilter, UltimateForm } =
  createUltimateFilter<MailSearchFormModel>();

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
  },
  fullWidth: {
    width: '100%',
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          padding: `0 ${theme.old.spacing.unit(1.5)}px`,
          background: 'transparent',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
    '&.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover':
      {
        zIndex: 1,
      },
    '& .ant-input-group': {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      height: 32,
    },
    '& .ant-input-group-addon': { padding: 0, display: 'block' },
    '& .ant-input-group-addon:first-child': { width: 125 },
    '& .ant-input-group-addon:last-child': { width: 50 },
    '& .ant-input-group-addon .ant-select': {
      margin: 0,
    },
    '& .ant-input-affix-wrapper': {
      padding: `0 11px`,
    },
    '& .ant-input-prefix': {
      overflowX: 'scroll',
      overflowY: 'hidden',
      maxWidth: 'calc(100% - 52.125px)',
      paddingTop: 4,
    },
    '& .ant-input-affix-wrapper > input.ant-input': {
      minWidth: 4,
      padding: `4px 0`,
    },
    '& .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector':
      {
        height: 30,
      },
    '& .ant-select-single .ant-select-selector .ant-select-selection-item': {
      lineHeight: '28px',
    },
  },
  tag: {
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    maxWidth: '100%',
    height: 19.6,
    marginTop: 2,
    marginBottom: 2,
    background: '#f5f5f5',
    border: '1px solid #f0f0f0',
    borderRadius: 2,
    cursor: 'default',
    transition: 'font-size 0.3s, line-height 0.3s, height 0.3s',
    userSelect: 'none',
    marginInlineEnd: 3.2,
    paddingInlineStart: 8,
    paddingInlineEnd: 4,
    alignItems: 'center',
  },
  button: {
    '&.ant-btn': {
      height: 24,
      padding: 0,
    },
  },
  popover: {
    '& .ant-popover-inner-content': {
      maxHeight: 500,
      maxWidth: 400,
      overflowY: 'scroll',
    },
  },
  listItem: {
    '& > button': {
      visibility: 'hidden',
    },
    '&:hover > button': {
      visibility: 'visible',
    },
  },
  selectIcon: {
    marginRight: theme.old.spacing.unit(2),
  },
  listItemText: {
    flex: 1,
  },
  lastSearchTermTrigger: {
    ...theme.old.components.mailListFilter,
    width: '100%',
    color: theme.old.typography.colors.muted,
    fontStyle: 'italic',
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.old.spacing.unit(0.5),
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: theme.old.typography.fontSize.label,
  },
  checkBoxField: {
    alignItems: 'baseline',
  },
  emailSearchFilter: {
    ...theme.old.components.mailListFilter,
    maxHeight: '65vh',
    paddingTop: theme.old.spacing.defaultPadding,
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingBottom: theme.old.spacing.defaultPadding,
  },
  actionButtonRow: {
    paddingTop: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
}));

const getDisplayName: (
  mailFolder: MailFolder,
  specialMailFolders: SpecialMailFolders,
  t: TFunction
) => string = (mailFolder, specialMailFolders, t) => {
  if (!mailFolder || !specialMailFolders) return mailFolder.displayName;
  switch (mailFolder.id) {
    case specialMailFolders['inboxFolder']?.id:
      return t('mail:mailNav.folderNames.inbox');
    case specialMailFolders['sendFolder']?.id:
      return t('mail:mailNav.folderNames.sentItems');
    case specialMailFolders['draftFolder']?.id:
      return t('mail:mailNav.folderNames.drafts');
    case specialMailFolders['deletedFolder']?.id:
      return t('mail:mailNav.folderNames.deletedItems');
    default:
      return mailFolder.displayName;
  }
};
export interface MailSearchRef {
  resetSearch: () => void;
  hasValue: boolean;
}

interface MailSearchProps {
  className?: string;
  searchOptions?: {
    searchTextInput?: {
      value?: string;
      onChange?: (
        value: string,
        mailFolder: MailFolderId,
        hasValue: boolean
      ) => void;
      onSubmit?: (value: string, mailFolder: MailFolderId) => void;
    };
    advancedSearch?: {
      value?: AdvancedMailSearchDto;
      onChange?: (
        value: AdvancedMailSearchDto,
        mailFolder: MailFolderId,
        hasValue: boolean
      ) => void;
      onSubmit?: (
        value: AdvancedMailSearchDto,
        mailFolder: MailFolderId
      ) => void;
    };
  };
  valuesIgnoreConfig?: IgnoreValueConfig<MailSearchFormModel>;
  onFocus?: () => void;
  onBlur?: (hasValue: boolean) => void;
  iconWhenValue?: ReactNode;
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  specialMailFolders: SpecialMailFolders;
  changeExpanded?: (value: boolean) => void;
  currentSearchMailFolderId?: MailFolderId;
  expanded: boolean;
}

const MailSearch: ForwardRefRenderFunction<MailSearchRef, MailSearchProps> = (
  {
    className,
    projectId,
    mailFolderId: rootMailFolderId,
    specialMailFolders,
    valuesIgnoreConfig,
    searchOptions,
    onBlur: onBlurFromParent,
    onFocus: onFocusFromParent,
    expanded,
  },
  forwardedRef
) => {
  const { t } = useTranslation();
  const theme = useTheme() as PrioTheme;
  const classes = useStyles();

  //#region -------------------------------- Defaults

  const importanceArray: Importance[] = ['high', 'low', 'normal'];

  const flagStatusArray: AdvancedMailSearchFlagStatus[] = [
    'notFlagged',
    'complete',
    'flagged',
  ];
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  useImperativeHandle(forwardedRef, () => ({
    resetSearch: () => {
      ultimateFilterRef?.current?.resetSearch();
    },
    hasValue: ultimateFilterRef?.current?.hasValue,
  }));

  const ultimateFilterRef =
    useContext<MutableRefObject<UltimateFilterSearchRef<MailSearchFormModel>>>(
      MailFilterContext
    );

  const [currentSearchMailFolderId, setCurrentSearchMailFolderId] =
    useState<MailFolderId>(rootMailFolderId);

  const me = useSelector(getUserMe);

  const {
    advancedSearchTerm,
    dispatchAdvancedSearchTerm,
    colorMap,
    lastSearchTerm,
    saveSearchTermToLastSearchTerm,
    mailFolders,
  } = useMailSearch({
    projectId,
    currentSearchMailFolderId,
    specialMailFolders,
  });

  const emailQueryModelInitialValues: MailSearchFormModel = useMemo(
    () => ({
      from: [],
      to: [],
      cc: [],
      subject: '',
      period: {
        dateFrom: null,
        dateTo: null,
      },
      categories: [],
      categoryAndConjunction: null,
      keywords: '',
      importance: [],
      followupFlagStatus: [],
      hasAttachments: null,
      isRead: null,
      attachment: '',
      assignedTo: [],
    }),
    []
  );

  const tagsPopOverConfig: UltimateFilterTagConfig<MailSearchFormModel> =
    useMemo(
      () => ({
        assignedTo: {
          contentType: 'contacts',
          tagLabel: t('mail:mailListFilter.labels.assignedTo'),
          popoverLabel: t('mail:mailListFilter.labels.assignedTo'),
        },
        from: {
          contentType: 'textArray',
          tagLabel: t('mail:mailListFilter.labels.from'),
        },
        to: {
          contentType: 'textArray',
          tagLabel: t('mail:mailListFilter.labels.to'),
        },
        cc: {
          contentType: 'textArray',
          tagLabel: t('mail:mailListFilter.labels.cc'),
        },
        subject: {
          contentType: 'text',
          tagLabel: t('mail:mailListFilter.labels.subject'),
        },
        keywords: {
          contentType: 'text',
          tagLabel: t('mail:mailListFilter.labels.keywords'),
        },
        period: {
          contentType: 'period',
          tagLabel: t('mail:mailListFilter.labels.period'),
        },
        attachment: {
          contentType: 'text',
          tagLabel: t('mail:mailListFilter.labels.attachment'),
        },
        categories: {
          contentType: 'customArray',
          tagLabel: t('mail:mailListFilter.labels.categories'),
          customRender: (value) => {
            return (
              <>
                <FontAwesomeIcon
                  icon={['fas', 'tag']}
                  color={colorFromPreset(colorMap[value], theme)}
                  className={classes.selectIcon}
                />
                <Typography.Text
                  ellipsis
                  title={value}
                  className={classes.listItemText}
                >
                  {value}
                </Typography.Text>
              </>
            );
          },
        },
        importance: {
          contentType: 'textArray',
          tagLabel: t('mail:mailListFilter.labels.importance'),
          customLabel: {
            renderLabelInArray: (value) => {
              return t(`mail:mailListFilter.importance.${value}`);
            },
          },
        },
        followupFlagStatus: {
          contentType: 'textArray',
          tagLabel: t('mail:mailListFilter.labels.followupFlagStatus'),
          customLabel: {
            renderLabelInArray: (value) => {
              return t(`mail:mailListFilter.flagStatus.${value}`);
            },
          },
        },
        hasAttachments: {
          contentType: 'none',
          tagLabel: t('mail:mailListFilter.labels.hasAttachments'),
          popoverLabel: t('mail:mailListFilter.labels.hasAttachments'),
        },
        isRead: {
          contentType: 'none',
          tagLabel: t('mail:mailListFilter.labels.isUnread'),
          popoverLabel: t('mail:mailListFilter.labels.isUnread'),
        },
        categoryAndConjunction: {
          contentType: 'none',
          tagLabel: t('mail:mailListFilter.labels.categoryAndConjunction'),
          popoverLabel: t('mail:mailListFilter.labels.categoryAndConjunction'),
        },
      }),
      [classes.listItemText, classes.selectIcon, colorMap, t, theme]
    );

  const quickSearchConfig: QuickSearchConfig = useMemo(
    () => ({
      lastSearch: {
        value: {
          name: t(`mail:mailListQuickFilter.labels.lastSearch`),
          value: lastSearchTerm,
        },
        onClick() {
          if (typeof lastSearchTerm === 'string') {
            ultimateFilterRef?.current?.setTextValue?.(lastSearchTerm, true);
          } else {
            ultimateFilterRef?.current?.setFilterValue(lastSearchTerm, true);
          }
        },
      },
      assignedToMe: {
        nonVisible: projectId === 'me',
        value: {
          name: t(`mail:mailListFilter.quickSearchLabels.assignedToMe`),
          value: [me?.id],
        },
        onClick(value) {
          ultimateFilterRef?.current?.setFilterValue?.(
            { assignedTo: value },
            true
          );
        },
      },
      isUnread: {
        value: {
          name: t(`mail:mailListFilter.labels.isUnread`),
          value: false,
        },
        onClick(value) {
          ultimateFilterRef?.current?.setFilterValue?.({ isRead: value }, true);
        },
      },
      hasAttachments: {
        value: {
          name: t(`mail:mailListFilter.labels.hasAttachments`),
          value: true,
        },

        onClick(value) {
          ultimateFilterRef?.current?.setFilterValue?.(
            { hasAttachments: value },
            true
          );
        },
      },
      dateTimeRange: {
        defaultValue: {
          name: t(`mail:mailListFilter.quickSearchLabels.date.currentWeek`),

          value: {
            dateFrom: moment().startOf('isoWeek').format('YYYY-MM-DD'),
            dateTo: moment().endOf('isoWeek').format('YYYY-MM-DD'),
          },
        },
        options: [
          {
            name: t(`mail:mailListFilter.quickSearchLabels.date.currentWeek`),
            value: {
              dateFrom: moment().startOf('isoWeek').format('YYYY-MM-DD'),
              dateTo: moment().endOf('isoWeek').format('YYYY-MM-DD'),
            },
          },
          {
            name: t(`mail:mailListFilter.quickSearchLabels.date.lastWeek`),
            value: {
              dateFrom: moment()
                .subtract(1, 'week')
                .startOf('isoWeek')
                .format('YYYY-MM-DD'),
              dateTo: moment()
                .subtract(1, 'week')
                .endOf('isoWeek')
                .format('YYYY-MM-DD'),
            },
          },
          {
            name: t(`mail:mailListFilter.quickSearchLabels.date.currentMonth`),
            value: {
              dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
              dateTo: moment().endOf('month').format('YYYY-MM-DD'),
            },
          },
          {
            name: t(`mail:mailListFilter.quickSearchLabels.date.lastMonth`),
            value: {
              dateFrom: moment()
                .subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD'),
              dateTo: moment()
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD'),
            },
          },
          {
            name: t(`mail:mailListFilter.quickSearchLabels.date.currentYear`),
            value: {
              dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
              dateTo: moment().endOf('year').format('YYYY-MM-DD'),
            },
          },
          {
            name: t(`mail:mailListFilter.quickSearchLabels.date.lastYear`),
            value: {
              dateFrom: moment()
                .subtract(1, 'year')
                .startOf('year')
                .format('YYYY-MM-DD'),
              dateTo: moment()
                .subtract(1, 'year')
                .endOf('year')
                .format('YYYY-MM-DD'),
            },
          },
        ],
        onClick(value) {
          ultimateFilterRef?.current?.setFilterValue?.({ period: value }, true);
        },
      },
      followUpStatus: {
        defaultValue: {
          name: t('mail:mailListQuickFilter.labels.flagStatus.flagged'),
          value: ['flagged'],
        },
        options: [
          {
            name: t('mail:mailListQuickFilter.labels.flagStatus.flagged'),
            value: ['flagged'],
          },
          {
            name: t('mail:mailListQuickFilter.labels.flagStatus.notFlagged'),
            value: ['notFlagged'],
          },
          {
            name: t('mail:mailListQuickFilter.labels.flagStatus.complete'),
            value: ['complete'],
          },
        ],

        onClick(value) {
          ultimateFilterRef?.current?.setFilterValue?.(
            { followupFlagStatus: value },
            true
          );
        },
      },
    }),
    [lastSearchTerm, me?.id, projectId, t, ultimateFilterRef]
  );

  const initialAdvancedFilterValues = _.merge(
    {},
    emailQueryModelInitialValues,
    advancedSearchTerm
  );

  //#endregion

  //#region -------------------------------- Handlers
  const onAdvancedSearchSubmit = (values: AdvancedMailSearchDto) => {
    searchOptions?.advancedSearch?.onSubmit?.(
      values,
      currentSearchMailFolderId
    );
  };

  const onAdvancedSearchChange = (
    values: AdvancedMailSearchDto,
    changedValue: [keyof AdvancedMailSearchDto, any]
  ) => {
    dispatchAdvancedSearchTerm({
      type: changedValue[0],
      value: changedValue[1],
    });

    searchOptions?.advancedSearch?.onChange?.(
      values,
      currentSearchMailFolderId,
      countPropertiesWithValues(values, valuesIgnoreConfig) > 0
    );
  };

  const onTextSearchInputChange = (value: string) => {
    searchOptions?.searchTextInput?.onChange?.(
      value,
      currentSearchMailFolderId,
      !!ultimateFilterRef?.current?.input?.current?.input?.value?.trim()
    );
  };

  const onTextSearchInputSubmit = (value: string) => {
    saveSearchTermToLastSearchTerm(value);
    searchOptions?.searchTextInput?.onSubmit(value, currentSearchMailFolderId);
  };

  const onAdvancedSearchReset = () => {
    dispatchAdvancedSearchTerm({ type: 'RESET' });
  };

  const onBlurDebouced = _.debounce(() => {
    onBlurFromParent?.(ultimateFilterRef?.current?.hasValue);
  }, 200);

  const onBlur = () => {
    if (
      ultimateFilterRef?.current?.formik?.dirty &&
      ultimateFilterRef?.current?.formik?.isValid
    ) {
      ultimateFilterRef?.current?.submit();
    }

    if (ultimateFilterRef?.current?.hasValue) {
      onBlurFromParent?.(ultimateFilterRef?.current?.hasValue);
    } else {
      onBlurDebouced();
    }
  };

  const onFocus = () => {
    onFocusFromParent?.();
  };

  const onDropdownOpen = () => {};

  const onFilterSubmitButtonClick = (
    _: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    ultimateFilterRef?.current?.submit().then(() => {
      saveSearchTermToLastSearchTerm();
    });
  };

  const onFilterresetButtonClick = (
    _: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    ultimateFilterRef?.current?.resetSearch(true);
  };
  //#endregion

  //#region -------------------------------- Effects
  useEffect(() => {
    ultimateFilterRef?.current?.resetSearch();
  }, [projectId, ultimateFilterRef]);

  useEffect(() => {
    setCurrentSearchMailFolderId(rootMailFolderId);
  }, [projectId, rootMailFolderId]);
  //#endregion
  //#region ------------------------------ Components
  const tooltipIgnoredField = useCallback(
    (keyName: string) => (
      <Tooltip
        title={t('mail:mailListFilter.info.notFilterWithAllowed', {
          name: t(`mail:mailListFilter.labels.${keyName}`),
        })}
      >
        <FontAwesomeIcon
          color={theme.old.typography.colors.muted}
          icon={['fal', 'info-circle']}
          size="sm"
        />
      </Tooltip>
    ),
    [theme, t]
  );

  const tooltipOnlyForFilter = useCallback(
    (keyName: string) => (
      <Tooltip
        title={t('mail:mailListFilter.info.onlyForFiltering', {
          name: t(`mail:mailListFilter.labels.${keyName}`),
        })}
      >
        <FontAwesomeIcon
          color={theme.old.typography.colors.muted}
          icon={['fal', 'info-circle']}
          size="sm"
        />
      </Tooltip>
    ),
    [theme, t]
  );

  useEffect(() => {
    ultimateFilterRef?.current.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchMailFolderId]);

  const renderFormButtonBar = (_: FormikProps<AdvancedMailSearchDto>) => (
    <Flex.Row className={classes.actionButtonRow} justifyContent="flex-end">
      <Button onClick={onFilterresetButtonClick}>
        {t('mail:mailListFilter.actions.delete')}
      </Button>
      <Button type="primary" onClick={onFilterSubmitButtonClick}>
        {t('mail:mailListFilter.actions.search')}
      </Button>
    </Flex.Row>
  );

  //#endregion

  return (
    <UltimateFilter
      valuesIgnoreConfig={valuesIgnoreConfig}
      className={classNames(classes.root, className)}
      filterDropdownClassName={classes.emailSearchFilter}
      tagsPopOverConfig={tagsPopOverConfig}
      ref={ultimateFilterRef}
      placeholder={t('mail:mailSelectionList.search.placeHolder')}
      initialFilterValues={initialAdvancedFilterValues}
      searchInputValue={searchOptions?.searchTextInput?.value}
      disableDropDown={!specialMailFolders && projectId !== 'favorites'}
      onFilterSubmit={onAdvancedSearchSubmit}
      onFilterChange={onAdvancedSearchChange}
      onFilterSubmitReset={onAdvancedSearchReset}
      onSearchInputSubmit={onTextSearchInputSubmit}
      onSearchInputChange={onTextSearchInputChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onDropDownOpen={onDropdownOpen}
      dropDownRender={renderFormButtonBar}
      addonBefore={
        expanded && (
          <MailFolderSelect
            currentSearchMailFolderId={currentSearchMailFolderId}
            mailFolders={mailFolders}
            specialMailFolders={specialMailFolders}
            projectId={projectId}
            onMailFolderChange={(value) => {
              setCurrentSearchMailFolderId(value);
            }}
          />
        )
      }
    >
      {() => (
        <>
          <div className={classes.formColumn}>
            <UltimateFilterQuickSearchTags config={quickSearchConfig} />
            <Divider />
            <UltimateForm.Item
              label={t('mail:mailListFilter.labels.from')}
              name="from"
            >
              {({ onChange, value }) => (
                <AdressSelect
                  className={classes.fullWidth}
                  value={value}
                  changeHandler={(value) => {
                    onChange(value);
                  }}
                  maxCount={'responsive'}
                  projectId={projectId}
                  bordered
                  closeableTags
                />
              )}
            </UltimateForm.Item>
            <UltimateForm.Item
              label={
                <div className={classes.labelRow}>
                  {t('mail:mailListFilter.labels.to')}
                  {tooltipIgnoredField('to')}
                </div>
              }
              name="to"
            >
              {({ onChange, value }) => (
                <AdressSelect
                  className={classes.fullWidth}
                  value={value}
                  changeHandler={(value) => {
                    onChange(value);
                  }}
                  maxCount={'responsive'}
                  projectId={projectId}
                  bordered
                  closeableTags
                />
              )}
            </UltimateForm.Item>
            <UltimateForm.Item
              label={
                <div className={classes.labelRow}>
                  {t('mail:mailListFilter.labels.cc')}
                  {tooltipIgnoredField('cc')}
                </div>
              }
              name="cc"
            >
              {({ onChange, value }) => (
                <AdressSelect
                  className={classes.fullWidth}
                  value={value}
                  changeHandler={(value) => {
                    onChange(value);
                  }}
                  maxCount={'responsive'}
                  projectId={projectId}
                  bordered
                  closeableTags
                />
              )}
            </UltimateForm.Item>
            {!(projectId === 'me' || projectId === 'favorites') && (
              <UltimateForm.Item
                label={t('mail:mailListFilter.labels.assignedTo')}
                name="assignedTo"
              >
                {({ onChange, value }) => (
                  <ContactPicker
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ width: '100%' }}
                    multiple
                    value={value}
                    onChange={(v) => onChange(v)}
                    projectId={projectId}
                    onlyInternalProject
                  />
                )}
              </UltimateForm.Item>
            )}
            <UltimateForm.Item
              label={
                <div className={classes.labelRow}>
                  {t('mail:mailListFilter.labels.subject')}
                  {tooltipIgnoredField('subject')}
                </div>
              }
              name="subject"
            >
              {({ onChange, value }) => (
                <DebouncedInputSearch
                  className={classes.fullWidth}
                  value={value}
                  onChange={onChange}
                  allowClear
                />
              )}
            </UltimateForm.Item>
            <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
              <UltimateForm.Item
                label={t('mail:mailListFilter.labels.dateFrom')}
                name={'period.dateFrom'}
              >
                {({ onChange, value, values }) => {
                  const typedValues = values as AdvancedMailSearchDto;
                  return (
                    <CustomSingleDatePicker
                      id={'mailListFilterDateFrom'}
                      value={value ? moment(value) : null}
                      onChange={(value) => {
                        onChange(value?.format('YYYY-MM-DD'));
                      }}
                      anchorDirection={'ANCHOR_RIGHT'}
                      small={true}
                      regular={false}
                      twoMonths={false}
                      withFullScreenPortal={false}
                      daySize={30}
                      hideKeyboardShortcutsPanel={true}
                      showDefaultInputIcon={false}
                      inputIconPosition={'after'}
                      blockDatesBefore={
                        typedValues?.period?.dateTo
                          ? moment(typedValues?.period?.dateTo)
                          : undefined
                      }
                      allowClear={true}
                    />
                  );
                }}
              </UltimateForm.Item>
              <UltimateForm.Item
                label={t('mail:mailListFilter.labels.dateTo')}
                name={'period.dateTo'}
              >
                {({ onChange, value, values }) => {
                  const typedValues = values as AdvancedMailSearchDto;
                  return (
                    <CustomSingleDatePicker
                      id={'mailListFilterDateTo'}
                      value={value ? moment(value) : null}
                      onChange={(value) => {
                        onChange(value?.format('YYYY-MM-DD'));
                      }}
                      anchorDirection={'ANCHOR_LEFT'}
                      small={true}
                      regular={false}
                      twoMonths={false}
                      withFullScreenPortal={false}
                      daySize={30}
                      hideKeyboardShortcutsPanel={true}
                      inputIconPosition={'after'}
                      blockDatesAfter={
                        typedValues?.period?.dateFrom
                          ? moment(typedValues?.period?.dateFrom)
                          : undefined
                      }
                      allowClear={true}
                    />
                  );
                }}
              </UltimateForm.Item>
            </Flex.Row>
            {projectId !== 'favorites' && (
              <>
                <UltimateForm.Item
                  label={
                    <div className={classes.labelRow}>
                      {t('mail:mailListFilter.labels.categories')}
                      {tooltipOnlyForFilter('subject')}
                    </div>
                  }
                  name="categories"
                >
                  {({ onChange, value }) => (
                    <MailCategorySelect
                      values={value}
                      colorMap={colorMap}
                      onChange={onChange}
                      maxCount={'responsive'}
                    />
                  )}
                </UltimateForm.Item>
                <UltimateForm.Item
                  label={(value) =>
                    t(
                      `mail:mailListFilter.actions.${
                        value ? 'andCategoriesSwitch' : 'orCategoriesSwitch'
                      }`
                    )
                  }
                  name="categoryAndConjunction"
                  labelAlignment="right"
                >
                  {({ onChange, value, values }) => {
                    const typedValues = values as AdvancedMailSearchDto;
                    return (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        disabled={typedValues?.categories?.length === 0}
                      />
                    );
                  }}
                </UltimateForm.Item>
              </>
            )}
            <UltimateForm.Item
              label={t('mail:mailListFilter.labels.keywords')}
              name="keywords"
            >
              {({ onChange, value }) => (
                <DebouncedInputSearch
                  className={classes.fullWidth}
                  value={value}
                  onChange={onChange}
                  allowClear
                />
              )}
            </UltimateForm.Item>
            <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
              <UltimateForm.Item
                label={t('mail:mailListFilter.labels.importance')}
                name="importance"
              >
                {({ onChange, value }) => (
                  <Select<Importance[]>
                    className={classes.fullWidth}
                    value={value ?? []}
                    mode="multiple"
                    options={importanceArray.map((typ) => ({
                      label: t(`mail:mailListFilter.importance.${typ}`),
                      value: typ,
                    }))}
                    onChange={onChange}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    maxTagCount={'responsive'}
                  />
                )}
              </UltimateForm.Item>
              <UltimateForm.Item
                label={
                  <div className={classes.labelRow}>
                    {t('mail:mailListFilter.labels.followupFlagStatus')}
                    {tooltipOnlyForFilter('followupFlagStatus')}
                  </div>
                }
                name="followupFlagStatus"
              >
                {({ onChange, value }) => (
                  <Select<AdvancedMailSearchFlagStatus[]>
                    className={classes.fullWidth}
                    value={value ?? []}
                    mode="multiple"
                    options={flagStatusArray.map((typ) => ({
                      label: t(`mail:mailListFilter.flagStatus.${typ}`),
                      value: typ,
                    }))}
                    onChange={onChange}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    maxTagCount={'responsive'}
                  />
                )}
              </UltimateForm.Item>
            </Flex.Row>
            <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
              <UltimateForm.Item
                label={t('mail:mailListFilter.labels.hasAttachments')}
                name="hasAttachments"
                labelAlignment="right"
                innerClassName={classes.checkBoxField}
              >
                {({ onChange, value }) => (
                  <Checkbox
                    className={classes.checkboxLabel}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              </UltimateForm.Item>
              <UltimateForm.Item
                label={t('mail:mailListFilter.labels.isUnread')}
                name="isRead"
                labelAlignment="right"
              >
                {({ onChange, value }) => (
                  <Checkbox
                    className={classes.checkboxLabel}
                    onChange={(e) => onChange(!e.target.checked)}
                    checked={value === false}
                  />
                )}
              </UltimateForm.Item>
            </Flex.Row>
            <UltimateForm.Item
              label={
                <div className={classes.labelRow}>
                  {t('mail:mailListFilter.labels.attachment')}
                  {tooltipIgnoredField('attachment')}
                </div>
              }
              name="attachment"
            >
              {({ onChange, value }) => (
                <DebouncedInputSearch
                  className={classes.fullWidth}
                  value={value}
                  onChange={onChange}
                  allowClear
                />
              )}
            </UltimateForm.Item>
          </div>
        </>
      )}
    </UltimateFilter>
  );
};

export default forwardRef(MailSearch);

interface MailFolderSelectProps {
  currentSearchMailFolderId: MailFolderId;
  projectId: ProjectId;
  mailFolders: MailFolder[];
  specialMailFolders: SpecialMailFolders;
  onMailFolderChange?: (value: MailFolderId) => void;
}

const MailFolderSelect = memo(
  ({
    onMailFolderChange: onMailFolderChangeFromParent,
    currentSearchMailFolderId,
    projectId,
    mailFolders,
    specialMailFolders,
  }: MailFolderSelectProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onMailFolderChange = (value: MailFolderId) => {
      onMailFolderChangeFromParent?.(value);
    };

    return (
      <Select<string>
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className={classes.fullWidth}
        onChange={onMailFolderChange}
        value={currentSearchMailFolderId}
      >
        <Select.Option value={'allFolders'} key="allFolders">
          {t('mail:mailListFilter.allFolders')}
        </Select.Option>
        {projectId === 'favorites' ? (
          <Select.Option value={'inbox'} key="inbox">
            {t('mail:mailListFilter.inbox')}
          </Select.Option>
        ) : (
          mailFolders.map((mailFolder) => {
            const isInbox =
              specialMailFolders?.inboxFolder?.id === mailFolder.id;
            return (
              <Select.Option
                value={isInbox ? 'inbox' : mailFolder.id}
                key={isInbox ? 'inbox' : mailFolder.id}
              >
                {getDisplayName(mailFolder, specialMailFolders, t)}
              </Select.Option>
            );
          })
        )}
      </Select>
    );
  }
);
