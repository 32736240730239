import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Collapse, Menu } from 'antd';

import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Project } from '../../../models/Project';
import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  collapse: {
    backgroundColor: 'transparent',
    '& > .ant-collapse-item': {
      borderBottom: 'none',
    },
    '& > .ant-collapse-item-active > .ant-collapse-header': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    padding: `0 ${theme.old.spacing.unit(1)}px`,
  },
  menu: {
    background: 'none',
    border: 'none',
    '&.ant-menu .ant-menu-item-selected:after': {
      display: 'none',
    },
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface TimeRecordsMenuProps {
  className?: string;
  projectId: string;
  pathPrefix?: string;
}

export const TimeRecordsMenu: React.FC<TimeRecordsMenuProps> = (props) => {
  const classes = useStyles();
  const { className, projectId, pathPrefix } = props;
  const { selectedList } = useParams();
  const { t } = useTranslation();
  const { showAllProjectTimeRecords } = useAccessRights(
    ['showAllProjectTimeRecords'],
    { projectId }
  );
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  return (
    <Flex.Column flexGrow={1} className={classNames(classes.root, className)}>
      <Typography.Title className={classes.title}>
        {t('timeRecords:title')}
      </Typography.Title>
      <Collapse
        bordered={false}
        className={classes.collapse}
        defaultActiveKey={['mainProject']}
      >
        <Collapse.Panel
          key="mainProject"
          header={`${project?.number ?? '...'} ${project?.shortName ?? ''}`}
        >
          <Menu
            className={classes.menu}
            selectedKeys={[selectedList]}
            mode="inline"
          >
            {navItem('my', 'user-clock', t, classes, pathPrefix)}
            {showAllProjectTimeRecords
              ? navItem('all', 'user-clock', t, classes, pathPrefix)
              : null}
          </Menu>
        </Collapse.Panel>
      </Collapse>
    </Flex.Column>
  );
};

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string
) => (
  <Menu.Item key={itemKey}>
    <Link to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {t(`timeRecords:menu.${itemKey}`)}
    </Link>
  </Menu.Item>
);

export default TimeRecordsMenu;
