import { Drawer } from '@prio365/prio365-react-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTemplatePreview } from '../DocumentTemplateFrom/DocumentTemplatePreview';
import DocumentTemplateFormWrapper from '../DocumentTemplateFrom/DocumentTemplateFormWrapper';
import { DriveItem } from '../../../../models/Drive';

interface NewDocumentDrawerProps {
  visible?: boolean;
  driveItem: DriveItem;
  projectId: string;
  groupId: string;
  onCancel?: () => void;
  onSuccess?: (driveItem?: DriveItem) => void;
}

export const NewDocumentDrawer: React.FC<NewDocumentDrawerProps> = (props) => {
  const {
    visible = false,
    onCancel = () => {},
    onSuccess = () => {},
    driveItem,
    projectId,
    groupId,
  } = props;
  //#region ------------------------------------------------- Defaults
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [thumbnailURL, setThumbnailURL] = useState<String[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  function handleClose() {
    setSelectedTemplate({});
    setThumbnailURL([]);
    onCancel();
  }
  //#endregion
  return (
    <Drawer
      zIndex={1000}
      title={t('documents:navigationBar.createDocument')}
      visible={visible}
      onClose={handleClose}
      mask={
        <DocumentTemplatePreview
          thumbnailURL={thumbnailURL}
          selectedTemplate={selectedTemplate}
        />
      }
    >
      <DocumentTemplateFormWrapper
        parentFolderDriveItem={driveItem}
        projectId={projectId}
        groupId={groupId}
        onCancel={handleClose}
        onSuccess={onSuccess}
        onThumbnailURLChange={setThumbnailURL}
        onSelectedTemplateChange={setSelectedTemplate}
        showOnlyReports={false}
        projectPickerFilter={(project) => !project.isArchived}
      />
    </Drawer>
  );
};
