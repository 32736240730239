import React, { useState } from 'react';
import { DriveItem } from '../../../../models/Drive';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colorForIcon, getDriveItemType, iconForFile } from '../../util';
import { Project } from '../../../../models/Project';
import {
  PRIO_DEFAULT_SPACING,
  PRIO_DEFAULT_TYPOGRAPHY,
} from '../../../../theme/types/default';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'inline-flex',
  },
  iconSize: {
    fontSize: '24px',
    marginLeft: PRIO_DEFAULT_SPACING.defaultPadding,
    marginRight: PRIO_DEFAULT_SPACING.defaultPadding,
  },
  buttons: {
    textAlign: 'left',
  },
  buttonIcon: {
    textAlign: 'center',
    width: '35px',
  },
  secondary: {
    color: PRIO_DEFAULT_TYPOGRAPHY.colors.muted,
    fontSize: PRIO_DEFAULT_TYPOGRAPHY.fontSize.small,
  },
  spacing: {
    marginTop: PRIO_DEFAULT_SPACING.baseSpacing,
  },
}));

interface DocumentSuccessNotificationProps {
  driveItem: DriveItem;
  project: Project;
  notificationKey: string;
}

export const DocumentSuccessNotification: React.FC<
  DocumentSuccessNotificationProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { driveItem, project, notificationKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isFetchingUrl, setIsFetchingUrl] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onOpenDocument = async (openType: string) => {
    if (driveItem !== undefined) {
      if (openType === 'local') {
        if (!isFetchingUrl) {
          setIsFetchingUrl(true);
          const a = document.createElement('a');

          const type = getDriveItemType(driveItem);
          let url = null;
          if (driveItem.webDavUrl) {
            if (type) {
              url = `${type}:ofe|ofc|u|${driveItem.webDavUrl}`;
            }
          }

          if (url) {
            a.href = url;
            if (!getDriveItemType(driveItem)) {
              a.target = '_blank';
            }
            document.body.appendChild(a);
            a.click();
          }
          document.body.removeChild(a);
        }
      }
      if (openType === 'web') {
        window.open(driveItem.webUrl, '_blank');
      }
      if (openType === 'prio') {
        window.open(
          `${window.location.protocol}//${window.location.hostname}/module/prio/projects/${project.projectId}/documents/folder/${driveItem.parentReference.id}`,
          '_self'
        );
      }
    }
    setIsFetchingUrl(false);
    notification.close(notificationKey);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <div className={classes.spacing}>
        <Flex.Row>
          <Flex.Column flex={0} childrenGap={PRIO_DEFAULT_SPACING.baseSpacing}>
            <div className={classes.iconSize}>
              <FontAwesomeIcon
                icon={['fas', iconForFile(driveItem)]}
                color={colorForIcon(driveItem.file.mimeType)}
              />
            </div>
          </Flex.Column>
          <Flex.Row>
            <Flex.Column
              flex={1}
              childrenGap={PRIO_DEFAULT_SPACING.baseSpacing}
            >
              <p>
                {project.number} - {project.shortName ?? project.name}
                <br />
                <span className={classes.secondary}>{driveItem.name}</span>
              </p>
            </Flex.Column>
          </Flex.Row>
        </Flex.Row>
        <Flex.Row>
          <Flex.Row>
            <Button
              type="default"
              size="small"
              className={classes.buttons}
              onClick={() => onOpenDocument('local')}
              iconProp={['fal', 'folder-tree']}
            >
              {/* <span className={classes.buttonIcon}>
              </span> */}
              {t('documents:newDocumentForm.open.local')}
            </Button>
            <Button
              type="default"
              size="small"
              className={classes.buttons}
              onClick={() => onOpenDocument('web')}
              iconProp={['fal', 'globe']}
            >
              {t('documents:newDocumentForm.open.web')}
            </Button>
            <Button
              type="default"
              size="small"
              className={classes.buttons}
              onClick={() => onOpenDocument('prio')}
              iconProp={['fal', 'grip-vertical']}
            >
              {t('documents:newDocumentForm.open.prio')}
            </Button>
          </Flex.Row>
        </Flex.Row>
      </div>
    </>
  );
};

export default DocumentSuccessNotification;
