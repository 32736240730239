import React, { useState } from 'react';
import useAccessRights from '../../users/hooks/useAccessRights';
import AbsenceProposalForm from './AbsenceProposalForm';
import {
  AbsenceOverview,
  AbsenceProposal,
  AbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContact,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { notification } from 'antd';
import {
  apiCreateAbsenceMeProposal,
  apiCreateAbsenceOfficeProposal,
  apiCreateAbsenceProposal,
} from '../api';
import { Center } from '../../../components/Center';
import { Contact } from '../../../models/Contact';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';
import { ApiResult } from '../../../api';
import { fetchAbsencesMe } from '../../absences/actions';
import {
  closeTimeAndLeaveManagementDrawer,
  setTimeAndLeaveManagementDrawer,
} from '../../timeAndLeaveManagement/actions';
import { DateTimeString } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  spinContainer: {
    height: 400,
  },
  newAbsenceProposal: {
    height: '100%',
  },
}));

interface NewAbsenceProposalProps {
  absenceOverview: AbsenceOverview;
  startDateTime?: DateTimeString;
  endDateTime?: DateTimeString;
  onFinish?: VoidFunction;
}

export const NewAbsenceProposal: React.FC<NewAbsenceProposalProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  // eslint-disable-next-line
  const classes = useStyles();
  const { absenceOverview, startDateTime, endDateTime, onFinish } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    writeOtherUserAbsenceByGlobalRole,
    writeOtherUserAbsenceByOfficeRole,
  } = useAccessRights([
    'writeOtherUserAbsenceByGlobalRole',
    'writeOtherUserAbsenceByOfficeRole',
  ]);
  const canSelectApplicant = React.useMemo(
    () =>
      writeOtherUserAbsenceByGlobalRole || writeOtherUserAbsenceByOfficeRole,
    [writeOtherUserAbsenceByGlobalRole, writeOtherUserAbsenceByOfficeRole]
  );
  const userMe = useSelector(getUserMe);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, userMe.id)
  );

  const emptyAbsenceProposal: AbsenceProposalRequest = React.useMemo(
    () => ({
      applicantId: userMe.id,
      absenceType: 'annualLeave',
      from: startDateTime ?? null,
      to: endDateTime ?? null,
      firstIsHalfDay: false,
      lastIsHalfDay: false,
      principalId: contact?.managerId,
    }),
    [userMe, contact, startDateTime, endDateTime]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnFinish = async (request: AbsenceProposalRequest) => {
    if (request.applicantId !== userMe.id && !canSelectApplicant) {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.createAbsenceProposalError'),
      });
    } else {
      if (request.applicantId !== userMe.id) {
        setIsSaving(true);

        const createProposal = () =>
          new Promise<ApiResult<AbsenceProposal>>((resolve, reject) => {
            try {
              if (writeOtherUserAbsenceByGlobalRole) {
                resolve(apiCreateAbsenceProposal(request));
              } else if (writeOtherUserAbsenceByOfficeRole) {
                resolve(apiCreateAbsenceOfficeProposal(request));
              } else {
                reject();
              }
            } catch (error) {
              reject(error);
            }
          });

        const { result } = await createProposal();

        if (result.status >= 200 && result.status < 300) {
          dispatch(
            setTimeAndLeaveManagementDrawer({ createdObjectType: 'absence' })
          );
          if (onFinish) {
            onFinish();
          }
          dispatch(closeTimeAndLeaveManagementDrawer());
        } else {
          notification.open({
            message: t('common:error'),
            description: t('absences:errorMessages.createAbsenceProposalError'),
          });
        }
        setIsSaving(false);
      } else {
        setIsSaving(true);
        const { result } = await apiCreateAbsenceMeProposal(request);

        if (result.status >= 200 && result.status < 300) {
          dispatch(
            setTimeAndLeaveManagementDrawer({ createdObjectType: 'absence' })
          );
          if (onFinish) {
            onFinish();
          }
          dispatch(fetchAbsencesMe());
          dispatch(closeTimeAndLeaveManagementDrawer());
        } else {
          notification.open({
            message: t('common:error'),
            description: t('absences:errorMessages.createAbsenceProposalError'),
          });
        }
        setIsSaving(false);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (!userMe) {
    return (
      <Center className={classes.spinContainer}>
        <PrioSpinner />
      </Center>
    );
  }

  return (
    <AbsenceProposalForm
      actionLabel={t('absences:newAbsenceProposal.action')}
      onFinish={handleOnFinish}
      initialValues={emptyAbsenceProposal}
      canSelectApplicant={canSelectApplicant}
      absenceOverview={absenceOverview}
      disableForm={isSaving}
      className={classes.newAbsenceProposal}
      isNewAbsenceProposal
    />
  );
};

export default NewAbsenceProposal;
