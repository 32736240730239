import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import moment, { Moment } from 'moment';
import {
  allFormOfEmploymentTypes,
  Employee,
  FormOfEmployment,
} from '../../../../models/Employee';
import { EmployeeId } from '../../../../models/Types';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colon, rowGutter } from '../../../../util/forms';
import NumberInput from '../../../../components/NumberInput';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    flex: 1,
    overflow: 'visible',
    '& .ant-input-number': {
      width: '100%',
    },
  },
  contractDataBoxLeft: {
    borderRight: theme.old.borders.content,
    paddingRight: theme.old.spacing.unit(3),
  },
  fullWidth: {
    width: '100%',
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
  dateOfEmployment: {
    '& .SingleDatePicker': {
      zIndex: 801,
    },
  },
  endOfContract: {
    '& .SingleDatePicker': {
      zIndex: 701,
    },
  },
}));

interface ContractDataFormModel {
  dateOfEmployment?: Moment;
  endOfContract?: Moment;
  hoursPerWeek?: number; //int
  formOfEmployment?: FormOfEmployment;
  probationDurationWeek?: number; //int
  workingDayMonday?: boolean;
  workingDayTuesday?: boolean;
  workingDayWednesday?: boolean;
  workingDayThursday?: boolean;
  workingDayFriday?: boolean;
  workingDaySaturday?: boolean;
  workingDaySunday?: boolean;
  nonTransferableOvertimeHours?: number;
  timeKeepingEnableDate?: Moment;
}

const contractDataFromEmployee: (
  employee: Employee
) => ContractDataFormModel = (employee) => ({
  dateOfEmployment: employee?.dateOfEmployment
    ? moment(employee?.dateOfEmployment)
    : null,
  probationDurationWeek: employee?.probationDurationWeek,
  endOfContract: employee?.endOfContract
    ? moment(employee?.endOfContract)
    : null,
  formOfEmployment: employee?.formOfEmployment,
  hoursPerWeek: employee?.hoursPerWeek,
  workingDayMonday: employee?.workingDayMonday,
  workingDayTuesday: employee?.workingDayTuesday,
  workingDayWednesday: employee?.workingDayWednesday,
  workingDayThursday: employee?.workingDayThursday,
  workingDayFriday: employee?.workingDayFriday,
  workingDaySaturday: employee?.workingDaySaturday,
  workingDaySunday: employee?.workingDaySunday,
  nonTransferableOvertimeHours:
    employee?.nonTransferableOvertimeHours === 0 ||
    !employee?.nonTransferableOvertimeHours
      ? employee?.nonTransferableOvertimeHoursPercent ?? 0
      : employee?.nonTransferableOvertimeHours ?? 0,
  timeKeepingEnableDate: employee?.timeKeepingEnableDate
    ? moment(employee?.timeKeepingEnableDate)
    : null,
});

interface PersonnelFileContractDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const PersonnelFileContractDataForm: React.FC<
  PersonnelFileContractDataFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { employeeId, employee, isSaving, patchEmployee } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [contractDataForm] = Form.useForm<ContractDataFormModel>();
  const [editModeContractData, setEditModeContractData] =
    useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const [isAbsolute, setIsAbsolute] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const editContractData = () => {
    setEditModeContractData(true);
  };
  const cancelEditContractData = () => {
    contractDataForm.setFieldsValue(contractDataFromEmployee(employee));
    setEditModeContractData(false);
  };
  const submitContractDataForm = () => {
    contractDataForm.submit();
  };

  const handleFinishContractData = async (
    contractData: ContractDataFormModel
  ) => {
    const {
      nonTransferableOvertimeHours,
      timeKeepingEnableDate,
      dateOfEmployment,
      endOfContract,
      ...rest
    } = contractData;
    const patchData: Employee = {
      ...rest,
      ...(isAbsolute
        ? {
            nonTransferableOvertimeHoursPercent: 0,
            nonTransferableOvertimeHours: nonTransferableOvertimeHours,
          }
        : {
            nonTransferableOvertimeHours: 0,
            nonTransferableOvertimeHoursPercent: nonTransferableOvertimeHours,
          }),
      ...(timeKeepingEnableDate
        ? {
            timeKeepingEnableDate: timeKeepingEnableDate
              ?.toISOString(true)
              .split('T')[0],
          }
        : {}),
      dateOfEmployment:
        dateOfEmployment?.toISOString(true).split('T')[0] ?? null,
      endOfContract: endOfContract?.toISOString(true).split('T')[0] ?? null,
    };
    await patchEmployee(patchData).then(() => setEditModeContractData(false));
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setEditModeContractData(false);
  }, [employeeId]);

  useEffect(() => {
    if (employee) {
      contractDataForm.setFieldsValue(contractDataFromEmployee(employee));
      setIsAbsolute(
        !employee.nonTransferableOvertimeHoursPercent ||
          employee.nonTransferableOvertimeHoursPercent === 0
      );
    }
  }, [employee, contractDataForm]);

  useEffect(() => {
    setDisabled(!editModeContractData || isSaving);
  }, [editModeContractData, isSaving]);
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Form<ContractDataFormModel>
        form={contractDataForm}
        onFinish={handleFinishContractData}
        layout="vertical"
        className={classes.form}
      >
        <Flex.Row flex={1}>
          <Flex.Column flex={1} className={classes.contractDataBoxLeft}>
            <Typography.Text type="secondary" className={classes.heading}>
              {t('hr:personnelFile.form.labels.general')}
            </Typography.Text>
            <Row gutter={rowGutter}>
              <Col
                span={12}
                style={{ paddingRight: theme.old.spacing.unit(1) }}
              >
                <Form.Item
                  name="dateOfEmployment"
                  label={t('hr:personnelFile.form.labels.dateOfEmployment')}
                  colon={colon}
                  className={classes.dateOfEmployment}
                >
                  <CustomSingleDatePicker
                    id="edit_personnelfile_employmentdate_id"
                    anchorDirection={'ANCHOR_RIGHT'}
                    small={true}
                    regular={false}
                    twoMonths={false}
                    disabled={disabled}
                    withFullScreenPortal={false}
                    daySize={30}
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    inputIconPosition={'after'}
                  ></CustomSingleDatePicker>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="formOfEmployment"
                  label={t('hr:personnelFile.form.labels.formOfEmployment')}
                  colon={colon}
                >
                  <Select<FormOfEmployment>
                    disabled={disabled}
                    className={disabled ? classes.disabledPicker : ''}
                  >
                    {allFormOfEmploymentTypes.map((type) => (
                      <Select.Option value={type} key={type}>
                        {t(`hr:formOfEmploymentTypes.${type}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col
                span={12}
                style={{ paddingRight: theme.old.spacing.unit(1) }}
              >
                <Form.Item
                  name="endOfContract"
                  label={t('hr:personnelFile.form.labels.endOfContract')}
                  colon={colon}
                  className={classes.endOfContract}
                >
                  <CustomSingleDatePicker
                    id="edit_personnelfile_endOfContract_id"
                    anchorDirection={'ANCHOR_RIGHT'}
                    small={true}
                    regular={false}
                    twoMonths={false}
                    disabled={disabled}
                    withFullScreenPortal={false}
                    daySize={30}
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    inputIconPosition={'after'}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="hoursPerWeek"
                  label={t('hr:personnelFile.form.labels.hoursPerWeek')}
                  colon={colon}
                >
                  <NumberInput disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col
                span={12}
                style={{ paddingRight: theme.old.spacing.unit(1) }}
              >
                <Form.Item
                  name="timeKeepingEnableDate"
                  label={t(
                    'hr:personnelFile.form.labels.timeKeepingEnableDate'
                  )}
                  colon={colon}
                >
                  <CustomSingleDatePicker
                    id="edit_personnelfile_timeKeepingEnableDate_id"
                    anchorDirection={'ANCHOR_RIGHT'}
                    small={true}
                    regular={false}
                    twoMonths={false}
                    disabled={disabled}
                    withFullScreenPortal={false}
                    daySize={30}
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    inputIconPosition={'after'}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t(
                    'hr:personnelFile.form.labels.nonTransferableOvertimeHours'
                  )}
                >
                  <Flex.Row childrenGap={theme.old.spacing.unit(1)}>
                    <Flex.Item flex={1}>
                      <Form.Item
                        name="nonTransferableOvertimeHours"
                        colon={colon}
                      >
                        <InputNumber
                          disabled={disabled}
                          min={0}
                          max={isAbsolute ? 999 : 100}
                          step={isAbsolute ? 0.25 : 1}
                          formatter={(value) => `${value}`.replace('.', ',')}
                          parser={(value) =>
                            parseFloat(value.replace(',', '.')) as 0 | 999 | 100
                          }
                        />
                      </Form.Item>
                    </Flex.Item>
                    <Flex.Item>
                      <Form.Item>
                        <Select
                          options={[
                            { value: 'absolute', label: 'h' },
                            { value: 'relativ', label: '%' },
                          ]}
                          defaultValue={'absolute'}
                          value={isAbsolute ? 'absolute' : 'relativ'}
                          disabled={disabled}
                          onChange={(value) => {
                            value === 'absolute'
                              ? setIsAbsolute(true)
                              : setIsAbsolute(false);
                          }}
                          className={disabled ? classes.disabledPicker : ''}
                          style={{
                            width: 60,
                          }}
                        ></Select>
                      </Form.Item>
                    </Flex.Item>
                  </Flex.Row>
                </Form.Item>
              </Col>
            </Row>
          </Flex.Column>
          <Flex.Column
            flex={1}
            padding={`0 0 0 ${theme.old.spacing.unit(3.5)}px`}
          >
            <Typography.Text type="secondary" className={classes.heading}>
              {t('hr:personnelFile.form.labels.workingDays')}
            </Typography.Text>
            <Row gutter={rowGutter}>
              <Col span={5}>
                <Form.Item
                  name="workingDayMonday"
                  label={t('hr:personnelFile.form.labels.workingDayMonday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="workingDayTuesday"
                  label={t('hr:personnelFile.form.labels.workingDayTuesday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="workingDayWednesday"
                  label={t('hr:personnelFile.form.labels.workingDayWednesday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="workingDayThursday"
                  label={t('hr:personnelFile.form.labels.workingDayThursday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="workingDayFriday"
                  label={t('hr:personnelFile.form.labels.workingDayFriday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col span={5}>
                <Form.Item
                  name="workingDaySaturday"
                  label={t('hr:personnelFile.form.labels.workingDaySaturday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="workingDaySunday"
                  label={t('hr:personnelFile.form.labels.workingDaySunday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
          </Flex.Column>
        </Flex.Row>
        <Divider />
      </Form>
      <Flex.Column>
        <Flex.Row alignSelf="flex-end" childrenGap={theme.old.spacing.unit(1)}>
          {editModeContractData && (
            <Button
              type="link"
              onClick={cancelEditContractData}
              disabled={isSaving}
              style={{ marginRight: theme.old.spacing.unit(1) }}
            >
              {t('common:actions.cancel')}
            </Button>
          )}
          <Button
            type={editModeContractData ? 'primary' : 'link'}
            onClick={
              editModeContractData ? submitContractDataForm : editContractData
            }
            disabled={isSaving}
          >
            {editModeContractData ? t('common:save') : t('common:edit')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default PersonnelFileContractDataForm;
