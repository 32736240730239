import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Menu, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  menu: {
    background: 'none',
    border: 'none',
    padding: `0 ${theme.old.spacing.defaultPadding}px`,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  divider: {
    marginBottom: 0,
  },
}));

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string
) => (
  <Menu.Item key={itemKey}>
    <Link to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {t(`settings:genericSettingsMenu.navigation.${itemKey}`)}
    </Link>
  </Menu.Item>
);

interface GenericSettingsMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
}

export const GenericSettingsMenu: React.FC<GenericSettingsMenuProps> = (
  props
) => {
  const { className, selectedList, urlPrefix } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <Typography.Title className={classes.title}>
        {t('settings:genericSettingsMenu.title')}
      </Typography.Title>
      <Menu
        className={classes.menu}
        selectedKeys={[selectedList]}
        mode="inline"
        inlineIndent={theme.old.components.menuInlineIndent}
      >
        {navItem('keyValue', 'cog', t, classes, urlPrefix)}
        {navItem('titles', 'user-graduate', t, classes, urlPrefix)}
      </Menu>
      <Divider className={classes.divider} />
      <Typography.Title className={classes.title}>
        {t('settings:genericSettingsMenu.projectTitle')}
      </Typography.Title>
      <Menu
        className={classes.menu}
        selectedKeys={[selectedList]}
        mode="inline"
        inlineIndent={theme.old.components.menuInlineIndent}
      >
        {navItem('projectSetting', 'cog', t, classes, urlPrefix)}
        {navItem('mail', 'envelope', t, classes, urlPrefix)}
        {navItem('documents', 'folder-tree', t, classes, urlPrefix)}
        {navItem('kilometerRates', 'road', t, classes, urlPrefix)}
        {navItem('hourlyRates', 'receipt', t, classes, urlPrefix)}
        {navItem('internalJobTitles', 'user-graduate', t, classes, urlPrefix)}
        {navItem('externalJobTitles', 'user-graduate', t, classes, urlPrefix)}
      </Menu>
    </Flex.Column>
  );
};
