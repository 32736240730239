import { useEffect, useMemo, useRef } from 'react';
import moment, { Moment } from 'moment';
import interact from 'interactjs';
import type { Interactable } from '@interactjs/core/Interactable';
import { InteractJSEvent } from './types';
import { getSnapPixelFromDelta } from './utils';
import { ItemsByGroupIdAction } from './TimelineBody';
import { v4 as uuid } from 'uuid';
import { createTemporaryId } from '../../util';

const setPopoverDisplay = (timelineId: string, display: 'flex' | 'none') => {
  try {
    const popovers = document.querySelectorAll(
      `#${timelineId} .prio-timelinine-item-popover`
    );
    for (let i = 0; i < popovers.length; i++) {
      const element = popovers.item(i) as HTMLDivElement;
      if (element) {
        element.style.display = display;
      }
    }
  } catch (e) {}
};

const useInteract: (
  timelineId: string,
  startTime: Moment,
  endTime: Moment,
  pixelsPerMs: number,
  gridSnapInMilliseconds: number,
  prefixWidth: number,
  dispatchUpdate: (action: ItemsByGroupIdAction) => Promise<void>,
  dispatchItemsByGroupId: React.Dispatch<ItemsByGroupIdAction>,
  isCreating: boolean,
  disableResize?: boolean,
  disableDrag?: boolean,
  disableAdd?: boolean
) => void = (
  timelineId,
  startTime,
  endTime,
  pixelsPerMs,
  gridSnapInMilliseconds,
  prefixWidth,
  dispatchUpdate,
  dispatchItemsByGroupId,
  isCreating,
  disableResize,
  disableDrag,
  disableAdd
) => {
  //#region ------------------------------ States / Attributes / Selectors
  const maxRight = useMemo(
    () => moment(endTime).diff(startTime, 'milliseconds') * pixelsPerMs,
    [endTime, startTime, pixelsPerMs]
  );

  const interactableGrid = useRef<Interactable>(null);

  const interactableElements = useRef<Interactable>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ UPDATE
  useEffect(() => {
    if (interactableElements.current) {
      interactableElements.current.unset();
    }
    interactableElements.current = interact(
      `#${timelineId}  .prio-timeline-item:not([is-creating="true"]):not([update-disabled="true"])`
    )
      .resizable({
        enabled: !disableResize,
        edges: { left: true, right: true, bottom: false, top: false },
        margin: 7,
        modifiers: [
          interact.modifiers.snapSize({
            targets: [
              interact.snappers.grid({
                width: pixelsPerMs * gridSnapInMilliseconds,
                height: 50,
              }),
            ],
          }),
        ],
      })
      .on('resizestart', (e: InteractJSEvent) => {
        try {
          const item = e.currentTarget;
          item.setAttribute('initialWidth', item.style.width);
          item.setAttribute('initialLeft', item.style.left);
          item.setAttribute('drag-x', '0');
          setPopoverDisplay(timelineId, 'none');
          item.setAttribute('isResizing', 'true');
        } catch (error) {}
      })
      .on('resizemove', (e: InteractJSEvent) => {
        try {
          const resizeHandler = e.edges.left ? 'left' : 'right';
          const item = e.currentTarget;
          const initialLeft = parseFloat(
            e.target.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
          );
          const initialWidth = parseFloat(
            e.target.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
          );

          const minWidth = parseFloat(
            e.target.style.minWidth?.replace(/px$/, '') ?? '0'
          );

          const deltaX = (parseFloat(item.getAttribute('drag-x')) || 0) + e.dx;
          const deltaWidth = e.rect.width - initialWidth;

          const snappedDeltaX = getSnapPixelFromDelta(
            deltaX,
            pixelsPerMs,
            gridSnapInMilliseconds
          );
          const snappedDeltaWidth = getSnapPixelFromDelta(
            deltaWidth,
            pixelsPerMs,
            gridSnapInMilliseconds
          );

          if (initialWidth + snappedDeltaWidth > minWidth - 1) {
            if (resizeHandler === 'left') {
              if (initialLeft + snappedDeltaX > -1) {
                e.target.style.left = `${initialLeft + snappedDeltaX}px`;
                e.target.style.width = `${initialWidth + snappedDeltaWidth}px`;
              }
            } else {
              if (
                maxRight + 1 >
                initialLeft + initialWidth + snappedDeltaWidth
              ) {
                e.target.style.width = `${initialWidth + snappedDeltaWidth}px`;
              }
            }
          }
          item.setAttribute('drag-x', `${deltaX}`);
        } catch (error) {}
      })
      .on('resizeend', (e: InteractJSEvent) => {
        try {
        } catch (error) {}
        const item = e.currentTarget;
        item.setAttribute('isResizing', 'false');
        const groupId = item.getAttribute('data-group-id');
        const itemId = item.getAttribute('data-item-id');
        const width = parseFloat(item.style.width?.replace(/px$/, '') ?? '0');
        const initialWidth = parseFloat(
          item.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
        );

        const left = parseFloat(item.style.left?.replace(/px$/, '') ?? '0');
        const initialLeft = parseFloat(
          item.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
        );

        const deltaXInS =
          Math.round((1 / (pixelsPerMs * 1000 * 60)) * (left - initialLeft)) *
          60;
        const deltaWInS =
          Math.round((1 / (pixelsPerMs * 1000 * 60)) * (width - initialWidth)) *
          60;

        dispatchUpdate({
          type: 'UPDATE',
          itemId,
          groupId,
          deltaXInS,
          deltaWInS,
          actionId: uuid(),
        });
        item.setAttribute('initialLeft', item.style.left);
        item.setAttribute('initialWidth', item.style.width);
        item.setAttribute('drag-x', '0');
        setPopoverDisplay(timelineId, 'flex');
        item.setAttribute('isResizing', 'false');
      })
      .draggable({
        enabled: !disableDrag,
        modifiers: [
          interact.modifiers.snapSize({
            targets: [
              interact.snappers.grid({
                width: pixelsPerMs * gridSnapInMilliseconds,
                height: 50,
              }),
            ],
          }),
        ],
      })
      .on('dragstart', (e: InteractJSEvent) => {
        try {
          const item = e.target;
          item.setAttribute('isDragging', 'true');
          item.setAttribute('initialLeft', item.style.left);
          item.setAttribute('initialWidth', item.style.width);
          item.setAttribute('drag-x', '0');
          setPopoverDisplay(timelineId, 'none');
        } catch (error) {}
      })
      .on('dragmove', (e: InteractJSEvent) => {
        try {
          const target = e.target;
          let dx = (parseFloat(target.getAttribute('drag-x')) || 0) + e.dx;
          const initialLeft = parseFloat(
            e.target.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
          );
          const initialWidth = parseFloat(
            e.target.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
          );

          // Snap the movement to the current snap interval
          const snapDx = getSnapPixelFromDelta(
            dx,
            pixelsPerMs,
            gridSnapInMilliseconds
          );
          if (
            initialLeft + snapDx > -1 &&
            maxRight + 1 > initialLeft + snapDx + initialWidth
          ) {
            e.target.style.left = `${initialLeft + snapDx}px`;

            target.setAttribute('drag-x', `${dx}`);
          }
        } catch (error) {}
      })
      .on('dragend', (e: InteractJSEvent) => {
        try {
          const item = e.target;
          item.setAttribute('isDragging', 'false');
          const groupId = item.getAttribute('data-group-id');
          const itemId = item.getAttribute('data-item-id');
          const width = parseFloat(item.style.width?.replace(/px$/, '') ?? '0');
          const initialWidth = parseFloat(
            item.getAttribute('initialWidth')?.replace(/px$/, '') ?? '0'
          );

          const left = parseFloat(item.style.left?.replace(/px$/, '') ?? '0');
          const initialLeft = parseFloat(
            item.getAttribute('initialLeft')?.replace(/px$/, '') ?? '0'
          );

          const deltaXInS =
            Math.round((1 / (pixelsPerMs * 1000 * 60)) * (left - initialLeft)) *
            60;
          const deltaWInS =
            Math.round(
              (1 / (pixelsPerMs * 1000 * 60)) * (width - initialWidth)
            ) * 60;
          dispatchUpdate({
            type: 'UPDATE',
            itemId,
            groupId,
            deltaXInS,
            deltaWInS,
            actionId: uuid(),
          });
          item.setAttribute('initialLeft', item.style.left);
          item.setAttribute('initialWidth', item.style.width);
          item.setAttribute('drag-x', '0');
          setPopoverDisplay(timelineId, 'flex');
        } catch (error) {}
      });
  }, [
    timelineId,
    disableResize,
    disableDrag,
    maxRight,
    pixelsPerMs,
    prefixWidth,
    gridSnapInMilliseconds,
    dispatchUpdate,
  ]);
  //#endregion

  //#region ------------------------------ CREATE
  useEffect(() => {
    if (interactableGrid?.current) {
      interactableGrid?.current.unset();
    }
    if (!disableAdd && !isCreating) {
      interactableGrid.current = interact(
        `#${timelineId} .prio-timeline-item-row:not([creation-disabled="true"])`
      )
        .draggable({
          enabled: !disableAdd && !isCreating,
          cursorChecker() {
            return null;
          },
          modifiers: [
            interact.modifiers.snapSize({
              targets: [
                interact.snappers.grid({
                  width: pixelsPerMs * gridSnapInMilliseconds,
                  height: 50,
                }),
              ],
            }),
          ],
        })
        .on('dragstart', (e: InteractJSEvent) => {
          try {
            const row = e.currentTarget;
            const groupId = row.getAttribute('data-group-id');
            const snapX = getSnapPixelFromDelta(
              e.clientX0 - e.rect.left,
              pixelsPerMs,
              gridSnapInMilliseconds
            );
            const deltaXInS = Math.round((1 / (pixelsPerMs * 1000)) * snapX);
            dispatchItemsByGroupId({
              type: 'ADD',
              groupId,
              deltaXInS,
              actionId: uuid(),
            });

            const item: HTMLElement = document
              .querySelectorAll(
                `#${timelineId} .prio-timeline-item[is-creating="true"`
              )
              ?.item(0) as HTMLElement;
            item.setAttribute('initialStart', `${snapX}`);
          } catch (error) {}
        })
        .on('dragmove', (e: InteractJSEvent) => {
          try {
            const item: HTMLElement = document
              .querySelectorAll(
                `#${timelineId} .prio-timeline-item[is-creating="true"`
              )
              ?.item(0) as HTMLElement;

            if (!item.getAttribute('initialStart')) {
              const left = parseFloat(item.style.left.replace(/px$/, ''));
              item.setAttribute('initialStart', `${left}`);
              item.style.left = `${left}px`;
            }
            if (!item.getAttribute('drag-x')) {
              item.setAttribute('drag-x', '0');
            }
            const initialStart = parseFloat(
              item.getAttribute('initialStart') ?? '0'
            );

            let dx = (parseFloat(item.getAttribute('drag-x')) || 0) + e.dx;

            // Snap the movement to the current snap interval
            const snapDx = getSnapPixelFromDelta(
              dx,
              pixelsPerMs,
              gridSnapInMilliseconds
            );

            if (
              initialStart + snapDx > -1 &&
              maxRight + 1 > initialStart + snapDx
            ) {
              if (snapDx <= 0) {
                item.style.left = `${initialStart + snapDx}px`;
              }
              item.style.width = `${Math.abs(snapDx)}px`;
            }
            item.setAttribute('drag-x', `${dx}`);
          } catch (error) {}
        })
        .on('dragend', (e: InteractJSEvent) => {
          try {
            const row = e.currentTarget;
            const groupId = row.getAttribute('data-group-id');
            const item: HTMLElement = document
              .querySelectorAll(
                `#${timelineId} .prio-timeline-item[is-creating="true"`
              )
              ?.item(0) as HTMLElement;
            const itemId = item.getAttribute('data-item-id');
            const newItemId = createTemporaryId();
            const left = parseFloat(item.style.left.replace(/px$/, ''));
            const width = parseFloat(item.style.width.replace(/px$/, ''));
            const initialStart = parseFloat(
              item.getAttribute('initialStart') ?? '0'
            );

            const deltaXInS =
              Math.round(
                (1 / (pixelsPerMs * 1000 * 60)) * (left - initialStart)
              ) * 60;
            const deltaWInS =
              Math.round((1 / (pixelsPerMs * 1000 * 60)) * width) * 60;

            dispatchUpdate({
              type: 'UPDATE',
              itemId,
              groupId,
              deltaXInS,
              deltaWInS,
              actionId: uuid(),
              newItemId,
            });
            item.setAttribute('initialStart', '0');
            item.setAttribute('is-creating', 'false');
            item.setAttribute('drag-x', '0');
            item.setAttribute('data-item-id', newItemId);
          } catch (error) {}
        });
    }
  }, [
    timelineId,
    isCreating,
    disableAdd,
    maxRight,
    pixelsPerMs,
    prefixWidth,
    gridSnapInMilliseconds,
    dispatchUpdate,
    dispatchItemsByGroupId,
  ]);
  //#endregion
};

export default useInteract;
