import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Menu, Typography } from 'antd';

import useAccessRights from '../../users/hooks/useAccessRights';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  getActiveMailFolderId,
  getLastOpenDriveItemFolder,
  projectHasProjectNews,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { TFunction } from 'i18next';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import { MailFolderId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflowY: 'auto',
  },
  menu: {
    background: 'none',
    border: 'none',
    transition: 'padding 0.2s',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  menuItemPlanner: {
    '&:hover .prio-project-menu-external-link': {
      opacity: 1,
    },
  },
  iconPlanner: {
    opacity: 0,
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
    '-webkit-transition': 'all 0.3s ease-out',
    transition: 'all 0.3s ease-out',
  },
  settingsMenuItem: {
    fontSize: '14px!important',
  },
  settingsMenuItemIcon: {
    marginRight: theme.old.spacing.unit(1.5) + 3,
    width: 18,
  },
  flex1: {
    flex: 1,
  },
  badgeStatus: {
    '& .ant-badge-status-dot': {
      top: -3,
    },
  },
  badge: {
    '& .ant-badge-dot': {
      top: theme.old.spacing.baseSpacing,
      right: theme.old.spacing.baseSpacing,
      boxShadow: '0 0 0 1px transparent',
    },
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
  menuCollapsed: {
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'row',
    },
  },
  itemCollapsed: {
    '&.ant-menu-item > .ant-menu-title-content': {
      overflow: 'hidden',
    },
  },
  itemShowBadge: {
    '&.ant-menu-item > .ant-menu-title-content': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
}));

interface ProjectMenuProps {
  projectId: string;
  className?: string;
  selectedProjectId?: string;
  selectedSubModule?: string;
  masterPlanId?: string;
  groupId?: string;
  oneDrivePath?: string;
  emailPrefix?: string;
  emailSuffix?: string;
  isProjectMember?: boolean;
  pathPrefix?: string;
  collapsed?: boolean;
  subsidiaryId?: string;
  sharedMailboxUserId?: string;
}

export const ProjectMenu: React.FC<ProjectMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    projectId,
    selectedProjectId,
    selectedSubModule,
    masterPlanId,
    groupId,
    emailSuffix,
    emailPrefix,
    isProjectMember,
    pathPrefix,
    collapsed,
    subsidiaryId,
    sharedMailboxUserId,
    className,
    // oneDrivePath,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isSelectedProject = selectedProjectId === projectId;

  const { showProjectSettings, showProjectControlling } = useAccessRights(
    ['showProjectSettings', 'showProjectControlling'],
    { projectId, companyId: subsidiaryId }
  );

  const hasProjectNews = useSelector<RootReducerState, boolean>((state) =>
    projectHasProjectNews(state, projectId)
  );

  const activeMailFolderId = useSelector<RootReducerState, MailFolderId>(
    (state) => getActiveMailFolderId(state, projectId)
  );
  //#endregion

  const lastOpenFolderDriveItemIds = useSelector((state: RootReducerState) =>
    getLastOpenDriveItemFolder(state, projectId)
  );

  return (
    <Menu
      selectedKeys={isSelectedProject ? [selectedSubModule] : []}
      className={classNames(classes.menu, className, {
        [classes.menuCollapsed]: collapsed,
      })}
      mode="inline"
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {navItem(
        'summary',
        'tachometer-alt',
        t,
        theme,
        classes,
        projectId,
        pathPrefix,
        collapsed
      )}
      {isProjectMember &&
        sharedMailboxUserId &&
        navItem(
          'mail',
          'envelope',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          hasProjectNews,
          activeMailFolderId ?? undefined
        )}
      {isProjectMember &&
        navItem(
          'contacts',
          'users',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {isProjectMember && masterPlanId && emailSuffix && groupId && (
        <Menu.Item
          key="tasks"
          className={classNames(classes.menuItemPlanner, {
            [classes.itemCollapsed]: collapsed,
          })}
          title={
            <>
              <FontAwesomeIcon
                fixedWidth
                icon={['fal', 'tasks']}
                className={classes.icon}
              />
              <Typography.Text className={classes.navItemText}>
                {t('projects:navigation.tasks')}
              </Typography.Text>
            </>
          }
        >
          <a
            href={`https://tasks.office.com/${emailSuffix}/de-DE/Home/Planner/#/plantaskboard?groupId=${groupId}&planId=${masterPlanId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex.Row>
              <Flex.Item flex={1}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'tasks']}
                  className={classes.icon}
                />
                {!collapsed && (
                  <Typography.Text className={classes.navItemText}>
                    {t('projects:navigation.tasks')}
                  </Typography.Text>
                )}
              </Flex.Item>
              <Flex.Item>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'external-link-alt']}
                  className={classNames(
                    'prio-project-menu-external-link',
                    classes.iconPlanner
                  )}
                />
              </Flex.Item>
            </Flex.Row>
          </a>
        </Menu.Item>
      )}
      {isProjectMember && emailPrefix && emailSuffix && (
        <Menu.Item
          key="calendar"
          className={classNames(classes.menuItemPlanner, {
            [classes.itemCollapsed]: collapsed,
          })}
          title={
            <>
              <FontAwesomeIcon
                fixedWidth
                icon={['fal', 'calendar']}
                className={classes.icon}
              />
              <Typography.Text className={classes.navItemText}>
                {t('projects:navigation.calendar')}
              </Typography.Text>
            </>
          }
        >
          <a
            href={`https://outlook.office.com/calendar/${emailPrefix}@${emailSuffix}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex.Row>
              <Flex.Item flex={1}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'calendar']}
                  className={classes.icon}
                />
                {!collapsed && (
                  <Typography.Text className={classes.navItemText}>
                    {t('projects:navigation.calendar')}
                  </Typography.Text>
                )}
              </Flex.Item>
              <Flex.Item>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'external-link-alt']}
                  className={classNames(
                    'prio-project-menu-external-link',
                    classes.iconPlanner
                  )}
                />
              </Flex.Item>
            </Flex.Row>
          </a>
        </Menu.Item>
      )}
      {isProjectMember &&
        !showProjectSettings &&
        !showProjectControlling &&
        navItem(
          'timeRecord',
          'user-clock',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {/* {isProjectMember &&
        oneDrivePath &&
        navItem(
          'openexperience',
          'building',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          undefined,
          'openexperience'
        )} */}
      {isProjectMember &&
        groupId &&
        navItem(
          'documents',
          'folder-tree',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          false,
          lastOpenFolderDriveItemIds?.documentsPage &&
            `folder/${lastOpenFolderDriveItemIds?.documentsPage}`
        )}
      {isProjectMember &&
        showProjectControlling &&
        navItem(
          'invoiceControlling',
          'euro-sign',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {isProjectMember &&
        showProjectControlling &&
        navItem(
          'timeRecordControlling',
          'user-clock',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed
        )}
      {showProjectSettings &&
        navItem(
          'settings',
          'cog',
          t,
          theme,
          classes,
          projectId,
          pathPrefix,
          collapsed,
          undefined,
          'edit'
        )}
    </Menu>
  );
};

export default ProjectMenu;

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  theme: PrioTheme,
  classes: Classes,
  projectId: string,
  urlPrefix?: string,
  collapsed?: boolean,
  showBadge?: boolean,
  suffix?: string,
  onClick?: VoidFunction
) => {
  const link = (
    <Link
      to={`${urlPrefix ?? ''}${projectId}/${itemKey}${
        suffix ? `/${suffix}` : ''
      }`}
      className={showBadge ? classes.flex1 : undefined}
    >
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {!collapsed && (
        <Typography.Text className={classes.navItemText}>
          {t(`projects:navigation.${itemKey}`)}
        </Typography.Text>
      )}
    </Link>
  );
  return (
    <Menu.Item
      key={itemKey}
      className={classNames({
        [classes.itemShowBadge]: !collapsed && showBadge,
      })}
      title={
        <>
          {icon !== null ? (
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', icon]}
              className={classes.icon}
            />
          ) : (
            <span className={classes.settingsIcon} />
          )}
          <Typography.Text className={classes.navItemText}>
            {t(`projects:navigation.${itemKey}`)}
          </Typography.Text>
        </>
      }
      onClick={onClick}
    >
      {collapsed && showBadge ? (
        <Badge
          className={classes.badge}
          dot
          color={theme.old.palette.primaryColor}
        >
          {link}
        </Badge>
      ) : (
        link
      )}
      {!collapsed && showBadge ? (
        <Badge
          className={classes.badgeStatus}
          dot
          color={theme.old.palette.primaryColor}
        ></Badge>
      ) : null}
    </Menu.Item>
  );
};
