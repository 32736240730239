import React from 'react';
import classNames from 'classnames';
import MainDoodle from '../../doodles/components/MainDoodle';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));

interface MockProps {
  className?: string;
}

export const Mock: React.FC<MockProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <MainDoodle type="florian" />
    </div>
  );
};

export default Mock;
