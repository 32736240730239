import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Divider, Empty, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { AutoSizer, List } from 'react-virtualized';
import { Moment } from 'moment';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { debounceMonthlyCloseMe } from '../../../timeKeeping/actions';
import {
  RootReducerState,
  getMonthlyCloseMeByMonth,
} from '../../../../apps/main/rootReducer';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import { DateTimeString } from '../../../../models/Types';
import { openTimeAndLeaveManagementDrawer } from '../../../timeAndLeaveManagement/actions';
import { Link } from 'react-router-dom';
import MonthlyCloseConfirmDrawer from '../../../timeKeeping/components/MonthlyCloseConfirmDrawer';
import {
  apiFetchEditableMonthlyClose,
  apiFetchMonthlyCloseMeById,
} from '../../../timeKeeping/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: `0 ${theme.old.spacing.unit(0.5)}px`,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
    '&:not(:last-child)': {
      borderBottom: theme.old.borders.content,
    },
  },
  listItemInner: {
    height: '100%',
    width: '100%',
  },
  activeListItem: {
    color: theme.old.palette.primaryColor,
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  prevMonthlyClose: {
    color: theme.old.palette.chromaticPalette.yellow,
    fontWeight: theme.old.typography.fontWeight.bold,
  },
}));

interface DashboardOpenTimekeepingsItemProps {
  className?: string;
}

export const DashboardOpenTimekeepingsItem: React.FC<
  DashboardOpenTimekeepingsItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment());
  const [goBackButtonDisabled, setGoBackButtonDisabled] =
    useState<boolean>(true);
  const goAheadButtonDisabled = currentMonth.isSame(moment(), 'month');
  const [nextToCloseMonthlyCloseMonth, setNextToCloseMonthlyCloseMonth] =
    useState<string>(undefined);

  const monthlyCloseMe = useSelector<RootReducerState, MonthlyClose>((state) =>
    getMonthlyCloseMeByMonth(
      state,
      currentMonth.toISOString(true).substring(0, 7)
    )
  );

  const employeeId = useMemo(() => {
    return monthlyCloseMe?.employeeId;
  }, [monthlyCloseMe?.employeeId]);

  const prevMonthlyCloseMonth = useMemo(() => {
    return currentMonth
      .clone()
      .subtract(1, 'months')
      .toISOString(true)
      .substring(0, 7);
  }, [currentMonth]);

  const prevMonthlyCloseMe = useSelector<RootReducerState, MonthlyClose>(
    (state) => getMonthlyCloseMeByMonth(state, prevMonthlyCloseMonth)
  );

  const missingWorkingDays = (monthlyClose: MonthlyClose) => {
    if (monthlyClose) {
      const { shouldBeWorkingDays, timeKeepingDays } = monthlyClose;
      return shouldBeWorkingDays
        .reduce((currentArray, day) => {
          const timeKeepingDay = timeKeepingDays.find((timeKeepingDay) =>
            moment(timeKeepingDay?.timeKeepingEntries[0]?.startTime).isSame(
              day,
              'day'
            )
          );
          if (!timeKeepingDay) {
            currentArray.push(day.split('T')[0]);
          }
          return currentArray;
        }, [] as string[])
        .sort((a, b) => moment(b).diff(moment(a)));
    }
    return [];
  };

  const missingWorkingDaysCurrentMonth: string[] = useMemo(() => {
    return missingWorkingDays(monthlyCloseMe);
  }, [monthlyCloseMe]);

  const numberOfMissingWorkingDaysCurrentPreviousMonth: number = useMemo(() => {
    return missingWorkingDays(prevMonthlyCloseMe).length;
  }, [prevMonthlyCloseMe]);

  const [confirmDrawerVisible, setConfirmDrawerVisible] =
    useState<boolean>(false);

  const prevMonthlyCloseNeedsConfirmation = useMemo(() => {
    return !prevMonthlyCloseMe?.employeeConfirmation;
  }, [prevMonthlyCloseMe]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const debounceFetchMonthlyClose = useCallback(
    (day: Moment) => {
      const start = day
        .clone()
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = day
        .clone()
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      dispatch(debounceMonthlyCloseMe(start, end));
    },
    [dispatch]
  );

  const handleOnPrevClick = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
    debounceFetchMonthlyClose(currentMonth.clone().subtract(1, 'month'));
  };

  const handleOnNextClick = () => {
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
    debounceFetchMonthlyClose(currentMonth.clone().add(1, 'month'));
  };

  const handleOnTodayClick = () => {
    setCurrentMonth(moment());
    debounceFetchMonthlyClose(moment());
  };

  const handleOnConfirmMonthlyCloseClick = () => {
    setConfirmDrawerVisible(true);
  };

  const handleOnBookTimeKeepingDayClick = (day: DateTimeString) => {
    dispatch(
      openTimeAndLeaveManagementDrawer({
        tab: 'timeKeeping',
        selectedTimeRange: {
          startDateTime: day,
          endDateTime: day,
        },
      })
    );
  };

  const handleOnConfirmDrawerClose = () => {
    setConfirmDrawerVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    debounceFetchMonthlyClose(moment());
    setCurrentMonth(moment());
  }, [debounceFetchMonthlyClose]);

  useEffect(() => {
    if (employeeId) {
      const fetchClosableMonthlyClose = async () => {
        const { data } = await apiFetchEditableMonthlyClose(employeeId);

        if (data) {
          const { nextToCloseMonthlyCloseId } = data;

          const { data: nextToCloseMonthlyClose } =
            await apiFetchMonthlyCloseMeById(nextToCloseMonthlyCloseId);

          if (nextToCloseMonthlyClose) {
            setNextToCloseMonthlyCloseMonth(nextToCloseMonthlyClose.month);
          }
        }
      };
      fetchClosableMonthlyClose();
    }
  }, [employeeId]);

  useEffect(() => {
    const currentMonthIsNextToClose = moment(currentMonth).isSameOrBefore(
      nextToCloseMonthlyCloseMonth,
      'month'
    );

    const prevMonthIsNextToCloseAndHasNoOpenTimekeepings =
      numberOfMissingWorkingDaysCurrentPreviousMonth === 0 &&
      prevMonthlyCloseMonth === nextToCloseMonthlyCloseMonth?.substring(0, 7);

    setGoBackButtonDisabled(
      currentMonthIsNextToClose ||
        prevMonthIsNextToCloseAndHasNoOpenTimekeepings
    );
  }, [
    nextToCloseMonthlyCloseMonth,
    currentMonth,
    prevMonthlyCloseMonth,
    numberOfMissingWorkingDaysCurrentPreviousMonth,
  ]);
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      childrenGap={theme.old.spacing.unit(2)}
    >
      <Flex.Row childrenGap={theme.old.spacing.baseSpacing} alignItems="center">
        <Typography.Title level={3} style={{ margin: 0, flex: 1 }}>
          {t('dashboard:openTimeKeepings.title')}
        </Typography.Title>
        <Link
          to={`../timeAndLeaveManagement/summary?month=${currentMonth
            .toISOString(true)
            .substring(0, 7)}`}
        >
          <Button
            iconProp={['fal', 'arrow-right-to-bracket']}
            type="default"
            style={{ fontSize: 16 }}
          />
        </Link>
      </Flex.Row>
      <Flex.Row alignItems="center" childrenGap={theme.old.spacing.unit(1)}>
        <Button
          iconProp={['fal', 'calendar-day']}
          onClick={handleOnTodayClick}
          type="default"
        />
        <Button
          iconProp={['fal', 'chevron-left']}
          onClick={handleOnPrevClick}
          disabled={goBackButtonDisabled}
          type="default"
        />
        <div style={{ minWidth: 100, textAlign: 'center' }}>
          {currentMonth.format('MMMM YYYY')}
        </div>
        <Button
          iconProp={['fal', 'chevron-right']}
          onClick={handleOnNextClick}
          disabled={goAheadButtonDisabled}
          type="default"
        />
      </Flex.Row>
      <Divider style={{ marginBottom: '0px', marginTop: '8px' }} />
      <Flex.Item flex={1}>
        {missingWorkingDaysCurrentMonth.length +
          (prevMonthlyCloseNeedsConfirmation ? 1 : 0) ===
        0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span
                style={{
                  textAlign: 'center',
                  color: theme.old.typography.colors.muted,
                }}
              >
                {t('dashboard:openTimeKeepings.noOpenTimeKeepings')}
              </span>
            }
          />
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={
                  missingWorkingDaysCurrentMonth.length +
                  (prevMonthlyCloseNeedsConfirmation ? 1 : 0)
                }
                rowHeight={40}
                rowRenderer={({ index, key, style }) => {
                  if (prevMonthlyCloseNeedsConfirmation && index === 0) {
                    return (
                      <div
                        key={key}
                        style={style}
                        className={classNames(
                          classes.listItem,
                          classes.prevMonthlyClose
                        )}
                        onClick={handleOnConfirmMonthlyCloseClick}
                      >
                        <Flex.Row
                          className={classes.listItemInner}
                          childrenGap={theme.old.spacing.unit(1)}
                          alignItems="center"
                        >
                          <div style={{ flex: 1 }}>
                            {currentMonth
                              .clone()
                              .subtract(1, 'months')
                              .format('MMMM YYYY')}
                          </div>
                          <div>
                            {t(
                              'dashboard:openTimeKeepings.missingMonthlyCloses.book'
                            )}
                          </div>
                          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                        </Flex.Row>
                      </div>
                    );
                  }
                  const _index = prevMonthlyCloseNeedsConfirmation
                    ? index - 1
                    : index;
                  const day = missingWorkingDaysCurrentMonth[_index];
                  return (
                    <div
                      key={key}
                      style={style}
                      className={classNames(classes.listItem, {
                        [classes.activeListItem]: moment(day).isSame(
                          moment(),
                          'day'
                        ),
                      })}
                      onClick={() => handleOnBookTimeKeepingDayClick(day)}
                    >
                      <Flex.Row
                        className={classes.listItemInner}
                        childrenGap={theme.old.spacing.unit(1)}
                        alignItems="center"
                      >
                        <div style={{ flex: 1 }}>
                          {moment(day).format('dd, DD.MM.YYYY')}
                        </div>
                        <div>
                          {t(
                            'dashboard:openTimeKeepings.missingWorkingDays.book'
                          )}
                        </div>
                        <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                      </Flex.Row>
                    </div>
                  );
                }}
              />
            )}
          </AutoSizer>
        )}
      </Flex.Item>
      <MonthlyCloseConfirmDrawer
        visible={confirmDrawerVisible}
        monthlyClose={prevMonthlyCloseMe}
        onClose={handleOnConfirmDrawerClose}
        enableEditableTimeKeepingDays
      />
    </Flex.Column>
  );
};

export default DashboardOpenTimekeepingsItem;
