import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../theme/utils';
import { rowGutter } from '../../util/forms';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
import CustomDateRangePicker from '../../components/CustomDateRangePicker';
import moment, { Moment } from 'moment';
import equals from 'deep-equal';
import Flex from '../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  resetButton: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.white,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

interface FilterAction {
  filter: 'fromTo' | 'period' | 'INIT' | 'RESET';

  value?: string[] | string | Moment[];
  init?: Period;
}

export interface TimespanFilter {
  from?: Moment; //absolut
  to?: Moment; //absolut
}

type PreselectedPeriod =
  | 'today'
  | 'lastWeek'
  | 'currentWeek'
  | 'lastMonth'
  | 'currentMonth'
  | 'lastYear'
  | 'currentYear'
  | 'custom';

interface Period {
  from: Moment;
  to: Moment;
}

const today: Period = {
  from: moment().startOf('D'),
  to: moment().endOf('D'),
};

const currentWeek: Period = {
  from: moment().startOf('isoWeek'),
  to: moment().endOf('isoWeek'),
};

const lastWeek: Period = {
  from: moment().subtract(1, 'week').startOf('isoWeek'),
  to: moment().subtract(1, 'week').endOf('isoWeek'),
};

const currentMonth: Period = {
  from: moment().startOf('month'),
  to: moment().endOf('month'),
};

const lastMonth: Period = {
  from: moment().subtract(1, 'month').startOf('month'),
  to: moment().subtract(1, 'month').endOf('month'),
};
const currentYear: Period = {
  from: moment().startOf('year'),
  to: moment().endOf('year'),
};
const lastYear: Period = {
  from: moment().subtract(1, 'year').startOf('year'),
  to: moment().subtract(1, 'year').endOf('year'),
};

const matchSelectedPeriod: (filter: TimespanFilter) => PreselectedPeriod = (
  filter
) => {
  if (filter.from.isSame(today.from) && filter.to.isSame(today.to))
    return 'today';
  if (filter.from.isSame(lastWeek.from) && filter.to.isSame(lastWeek.to))
    return 'lastWeek';
  if (filter.from.isSame(currentWeek.from) && filter.to.isSame(currentWeek.to))
    return 'currentWeek';
  if (filter.from.isSame(lastMonth.from) && filter.to.isSame(lastMonth.to))
    return 'lastMonth';
  if (
    filter.from.isSame(currentMonth.from) &&
    filter.to.isSame(currentMonth.to)
  )
    return 'currentMonth';
  if (filter.from.isSame(lastYear.from) && filter.to.isSame(lastYear.to))
    return 'lastYear';
  if (filter.from.isSame(currentYear.from) && filter.to.isSame(currentYear.to))
    return 'currentYear';
  return 'custom';
};

interface TimespanPeriodFilterProps {
  filterUpdated: (filter: TimespanFilter) => void;
  initFilter?: TimespanFilter;
  resetAdvancedInvoiceFilter?: () => void;
}

export const TimespanPeriodFilter: React.FC<TimespanPeriodFilterProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { filterUpdated, initFilter, resetAdvancedInvoiceFilter } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [fromToDates, setFromToDates] = useState<Moment[]>([
    initFilter.from,
    initFilter.to,
  ]);

  const [filter, dispatchFilter] = useReducer<
    React.Reducer<TimespanFilter, FilterAction>
  >((state: TimespanFilter, action: FilterAction) => {
    if (action.filter === 'period') {
      const periodValue = action.value as PreselectedPeriod;
      let period: Period;
      switch (periodValue) {
        case 'today':
          period = today;
          setFromToDates([today.from, today.to]);
          break;
        case 'lastWeek':
          period = lastWeek;
          setFromToDates([lastWeek.from, lastWeek.to]);
          break;
        case 'currentWeek':
          period = currentWeek;
          setFromToDates([currentWeek.from, currentWeek.to]);
          break;
        case 'currentMonth':
          period = currentMonth;
          setFromToDates([currentMonth.from, currentMonth.to]);
          break;
        case 'lastMonth':
          period = lastMonth;
          setFromToDates([lastMonth.from, lastMonth.to]);
          break;
        case 'currentYear':
          period = currentYear;
          setFromToDates([currentYear.from, currentYear.to]);
          break;
        case 'lastYear':
          period = lastYear;
          setFromToDates([lastYear.from, lastYear.to]);
          break;
      }
      return {
        ...state,
        ...(period ?? {}),
      };
    } else if (action.filter === 'fromTo') {
      const [from, to] = action.value as Moment[];
      return {
        ...state,
        from,
        to,
      };
    } else if (action.filter === 'INIT') {
      if (equals(action.init, state)) {
        return state;
      }
      return action.init;
    } else if (action.filter === 'RESET') {
      return {
        from: moment().startOf('isoWeek'),
        to: moment().endOf('isoWeek'),
      };
    } else if (action.value?.length !== 0) {
      return {
        ...state,
        [action.filter]: action.value,
      };
    }
    return {
      ...state,
      [action.filter]: undefined,
    };
  }, {});

  const period: PreselectedPeriod | null =
    filter.from && filter.to ? matchSelectedPeriod(filter) : null;

  const handlePeriodChange = (value: PreselectedPeriod) => {
    dispatchFilter({ filter: 'period', value });
  };
  const resetTimespanPeriodFilter = () => {
    dispatchFilter({ filter: 'RESET' });
    setFromToDates([moment().startOf('month'), moment().endOf('day')]);
  };
  const resetFilters = () => {
    resetAdvancedInvoiceFilter();
    resetTimespanPeriodFilter();
  };

  const handleFromToChange = (value: { startDate; endDate }) => {
    let startDate = value['startDate'] as Moment;
    let endDate = value['endDate'] as Moment;
    if (startDate && endDate) {
      setFromToDates([startDate, endDate]);
      dispatchFilter({
        filter: 'fromTo',
        value: [startDate.startOf('day'), endDate.endOf('day')],
      });
    } else {
      setFromToDates([startDate, endDate]);
    }
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!equals(period, filter)) {
      filterUpdated(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  //#endregion

  return (
    <>
      <Form layout="vertical" className={classes.root}>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={8}>
            <Form.Item label={t('common:filter.label.period')}>
              <Select<PreselectedPeriod>
                className={classes.fullWidth}
                placeholder={t('common:filter.placeholder.period')}
                defaultValue="currentWeek"
                value={period}
                onChange={handlePeriodChange}
              >
                <Select.Option value="today">
                  {t('common:filter.today')}
                </Select.Option>
                <Select.Option value="currentWeek">
                  {t('common:filter.currentWeek')}
                </Select.Option>
                <Select.Option value="lastWeek">
                  {t('common:filter.lastWeek')}
                </Select.Option>
                <Select.Option value="currentMonth">
                  {t('common:filter.currentMonth')}
                </Select.Option>
                <Select.Option value="lastMonth">
                  {t('common:filter.lastMonth')}
                </Select.Option>
                <Select.Option value="currentYear">
                  {t('common:filter.currentYear')}
                </Select.Option>
                <Select.Option value="lastYear">
                  {t('common:filter.lastYear')}
                </Select.Option>
                <Select.Option value="custom" disabled>
                  {t('common:filter.custom')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={' '}>
              <CustomDateRangePicker
                disabled={false}
                startDate={fromToDates[0]} // momentPropTypes.momentObj or null,
                startDateId="controlling_timeoverview_startDateId" // PropTypes.string.isRequired,
                startDatePlaceholderText={moment()
                  .add('1', 'day')
                  .startOf('day')
                  .format('DD.MM.YYYY')}
                endDate={fromToDates[1]} // momentPropTypes.momentObj or null,
                endDateId="controlling_timeoverview_endDateId"
                // PropTypes.string.isRequired,
                endDatePlaceholderText={moment()
                  .add('1', 'day')
                  .startOf('day')
                  .format('DD.MM.YYYY')}
                onDatesChange={handleFromToChange} // PropTypes.func.isRequired,
                anchorDirection={'right'}
                small={true}
                regular={false}
                withFullScreenPortal={false}
                daySize={30}
                hideKeyboardShortcutsPanel={true}
                minimumNights={0}
                showClearDates={false}
                isOutsideRange={false}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ alignItems: 'center', display: 'flex' }}>
            <Flex.Row flex={1} alignItems="center" justifyContent="flex-end">
              <Button
                type="link"
                className={classes.resetButton}
                onClick={resetFilters}
                iconProp={['fal', 'trash']}
              >
                {t('common:filter.resetFilter')}
              </Button>
            </Flex.Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TimespanPeriodFilter;
