import React, { useCallback, useEffect, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { notification } from 'antd';
import { AbsenceManagementTable } from './AbsenceManagementTable';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { useTranslation } from 'react-i18next';
import { OfficeId } from '../../../models/Types';
import {
  apiAcceptAbsenceProposal,
  apiAcceptOfficeAbsenceProposal,
  apiDeclineAbsenceProposal,
  apiDeclineOfficeAbsenceProposal,
  apiFetchAbsenceProposals,
  apiFetchOfficeAbsenceProposals,
} from '../api';
import { useSelector } from 'react-redux';
import {
  getOfficesByIdState,
  getOfficesIsFetching,
} from '../../../apps/main/rootReducer';
import AbsenceProposalDetailsDrawer from './AbsenceProposalDetailsDrawer';
import TimeRangeFilter from '../../../components/TimeRangeFilter';
import { Moment } from 'moment-timezone';
import classNames from 'classnames';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    flex: 1,
  },
  content: {
    padding: theme.old.spacing.defaultPadding,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
    '& > div': {
      height: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  revokeButton: {
    color: theme.old.palette.chromaticPalette.red,
    background: 'transparent',
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  table: {
    flex: 1,
    overflow: 'hidden',
  },
}));

interface HRAbsenceProposalPageProps {
  className?: string;
  onlyOpen?: boolean;
  onRowSelectionChange?: (selectedProposals: AbsenceProposal[]) => void;
  officeId?: OfficeId;
}

export const HRAbsenceProposalPage: React.FC<HRAbsenceProposalPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { className, onlyOpen, onRowSelectionChange, officeId } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [open, setOpen] = useState<boolean>(false);

  const [selectedAbsenceProposal, setSelectedAbsenceProposal] =
    useState<AbsenceProposal | null>(null);

  const [selectedCount, setSelectedCount] = useState<number>(0);

  const [currentFilter, setCurrentFilter] = useState<{
    from: Moment;
    to: Moment;
  }>(null);

  const [absenceProposals, setAbsenceProposals] = useState<
    AbsenceProposal[] | null
  >();

  const [isAbsenceProposalsLoading, setIsAbsenceProposalsLoading] =
    useState<boolean>(false);

  const officesById = useSelector(getOfficesByIdState);
  const isOfficesByIdFetching = useSelector(getOfficesIsFetching);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchAbsenceProposal = useCallback(
    async (filter: { from: Moment; to: Moment }) => {
      setIsAbsenceProposalsLoading(true);
      try {
        const { data } = officeId
          ? await apiFetchOfficeAbsenceProposals(officeId, filter)
          : await apiFetchAbsenceProposals(filter);
        if (data) {
          const filtered = onlyOpen
            ? data.filter(
                (p) =>
                  (p.absenceState === 'requested' ||
                    p.absenceState === 'revokeRequested') &&
                  p.absenceType !== 'annualLeavePlanning'
              )
            : data;

          setAbsenceProposals(
            filtered.map(
              ({
                principalId,
                applicantId,
                substituteId,
                notifyContactIds,
                ...rest
              }) => ({
                ...rest,
                principalId: principalId.toLowerCase(),
                applicantId: applicantId.toLowerCase(),
                substituteId: substituteId.toLowerCase(),
                notifyContactIds: notifyContactIds.map((id) =>
                  id.toLowerCase()
                ),
              })
            )
          );
        } else {
          notification.open({
            message: t('common:error'),
            description: t('absences:errorMessages.fetchOverviewError'),
          });
        }
      } catch {}
      setIsAbsenceProposalsLoading(false);
    },
    [t, onlyOpen, officeId]
  );

  const handleClose = () => {
    setOpen(false);
    setSelectedAbsenceProposal(null);
  };

  const onAbsenceSelect = (absenceProposal: AbsenceProposal) => {
    setSelectedAbsenceProposal(absenceProposal);
    setOpen(true);
  };

  const declineProposal = async (absenceProposal: AbsenceProposal) => {
    const { result } = officeId
      ? await apiDeclineOfficeAbsenceProposal(
          absenceProposal.absenceProposalId,
          officeId
        )
      : await apiDeclineAbsenceProposal(absenceProposal.absenceProposalId);
    if (result.status >= 200 && result.status < 300) {
      fetchAbsenceProposal(currentFilter);
      handleClose();
    } else {
      notification.open({
        message: t('common:error'),
        description:
          absenceProposal.absenceState === 'revokeRequested'
            ? t('absences:errorMessages.declineAbsenceProposalRevokeError')
            : t('absences:successMessages.declineAbsenceProposalError'),
      });
    }
  };

  const acceptProposal = async (absenceProposal: AbsenceProposal) => {
    const { result } = officeId
      ? await apiAcceptOfficeAbsenceProposal(
          absenceProposal.absenceProposalId,
          officeId
        )
      : await apiAcceptAbsenceProposal(absenceProposal.absenceProposalId);
    if (result.status >= 200 && result.status < 300) {
      fetchAbsenceProposal(currentFilter);
      handleClose();
    } else {
      notification.open({
        message: t('common:error'),
        description:
          absenceProposal.absenceState === 'revokeRequested'
            ? t('absences:errorMessages.declineAbsenceProposalRevokeError')
            : t('absences:successMessages.declineAbsenceProposalError'),
      });
    }
  };

  const updateAbsenceTable = (absenceProposal: AbsenceProposal) => {
    const copyAbsenceProposals = [...absenceProposals];
    const index = absenceProposals.findIndex(
      (proposal) =>
        proposal.absenceProposalId === absenceProposal.absenceProposalId
    );
    if (index > -1) {
      if (
        onlyOpen &&
        !(
          absenceProposal.absenceState === 'requested' ||
          absenceProposal.absenceState === 'revokeRequested'
        )
      ) {
        copyAbsenceProposals.splice(index, 1);
      } else {
        copyAbsenceProposals.splice(index, 1, absenceProposal);
      }
      setAbsenceProposals(copyAbsenceProposals);
    }
  };
  //#endregion

  const handleRowSelectionChange = (selectedProposals: AbsenceProposal[]) => {
    if (onRowSelectionChange) {
      onRowSelectionChange(selectedProposals);
    }
    setSelectedCount(selectedProposals.length);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (currentFilter) {
      fetchAbsenceProposal(currentFilter);
    }
  }, [currentFilter, fetchAbsenceProposal]);
  //#endregion

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      <Flex.Column className={classes.content}>
        <TimeRangeFilter<{}>
          count={(absenceProposals ?? []).length}
          selected={selectedCount}
          onChange={setCurrentFilter}
          defaultPeriod={'currentAndNextYear'}
        />
        <AbsenceManagementTable
          className={classes.table}
          absenceProposals={absenceProposals}
          onRowClick={onAbsenceSelect}
          officesById={officeId ? null : officesById}
          onAcceptProposal={acceptProposal}
          onDeclineProposal={declineProposal}
          onRowSelectionChange={handleRowSelectionChange}
          loading={isAbsenceProposalsLoading || isOfficesByIdFetching}
        />
        <AbsenceProposalDetailsDrawer
          open={open}
          setOpen={setOpen}
          absenceProposal={selectedAbsenceProposal}
          officesById={officesById}
          setSelectedAbsenceProposal={setSelectedAbsenceProposal}
          officeId={officeId}
          updateAbsenceTable={updateAbsenceTable}
        ></AbsenceProposalDetailsDrawer>
      </Flex.Column>
    </Flex.Row>
  );
};

export default HRAbsenceProposalPage;
