import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Collapse,
  Divider,
  Form,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { OfficeId } from '../../../models/Types';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import Table, { ColumnProps } from 'antd/lib/table';
import { RemainingAbsence } from '../../../models/RemainingAbsence';
import { tableTranslations } from '../../../util/table';
import { rowGutter } from '../../../util/forms';
import OfficePicker from '../../companies/components/OfficePicker';
import moment from 'moment-timezone';
import { apiFetchAbsenceOverview } from '../api';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../apps/main/rootReducer';
import PrioSpinner from '../../../components/PrioSpinner';
import Flex from '../../../components/Flex';
import { distinctArray } from '../../../util';
import HRRemainingAbsenceContactPicker from './HRRemainingAbsenceContactPicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const { Text } = Typography;

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
    '& .ant-spin-nested-loading': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-spin-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  fullWidth: {
    width: '100%',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  lastColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.old.spacing.defaultPadding,
    flex: 1,
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(2) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(2) > button': {
      visibility: 'visible',
    },
  },
  secondaryColumn: theme.old.components.table.secondaryColumn,
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  radioLabelCol: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface HRRestAbsencePageProps {
  className?: string;
  onRowSelectionChange?: (selectedProposals: AbsenceProposal[]) => void;
  officeId?: OfficeId;
}

export const HRRemainingAbsencePage: React.FC<HRRestAbsencePageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { officeId } = props;
  const classes = useStyles(props);
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const contactsByIdState = useSelector(getContactsByIdState);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [year, setYear] = useState<number>(moment().year());

  const [offices, setOffices] = useState<OfficeId[]>([]);

  const [isRemainingAbsenceLoading, setIsRemainingAbsenceLoading] =
    useState<boolean>(false);

  const [contactsToFilter, setContactsToFilter] = useState<string[]>([]);

  const [remainingAbsenceList, setRemainingAbsenceList] = useState<
    RemainingAbsence[]
  >([]);

  //#region ------------------------------ Methods / Handlers

  const fetchAbsenceProposal = useCallback(
    async (_year: number) => {
      setIsRemainingAbsenceLoading(true);
      setYear(_year);
      if (offices.length !== 0 || officeId) {
        try {
          const { data } = await apiFetchAbsenceOverview(
            officeId,
            offices,
            _year
          );

          if (data) {
            setRemainingAbsenceList(data as RemainingAbsence[]);
          } else {
            setRemainingAbsenceList([]);
            setContactsToFilter([]);
            notification.open({
              message: t('common:error'),
              description: t('absences:errorMessages.fetchOverviewError'),
            });
          }
        } catch (e) {}
      } else {
        setRemainingAbsenceList([]);
        setContactsToFilter([]);
      }
      setIsRemainingAbsenceLoading(false);
    },
    [t, officeId, offices]
  );

  const handleOfficePicker = (offices: OfficeId[]) => {
    setOffices(offices);
  };

  const handleYearPicker = (year: number) => {
    fetchAbsenceProposal(year);
  };

  const handleContactPicker = (selectedContacts: string[]) => {
    setContactsToFilter(selectedContacts);
  };

  const handleFilterReset = () => {
    setContactsToFilter([]);
  };

  const filterContactsIds = distinctArray(
    remainingAbsenceList,
    'employeeId'
  ).map((absence) => absence.employeeId);

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    fetchAbsenceProposal(year);
  }, [year, fetchAbsenceProposal]);
  //#endregion

  const getYearOptions = () => {
    var options = [];

    for (let i = 0; i < 3; i++) {
      options.push(
        <Select.Option value={moment().year() - i}>
          {moment().year() - i}
        </Select.Option>
      );
    }

    return options;
  };

  const columns: ColumnProps<RemainingAbsence>[] = [
    {
      sorter: (a, b) =>
        contactsByIdState[a.employeeId.toLowerCase()]?.lastName?.localeCompare(
          contactsByIdState[b.employeeId.toLowerCase()]?.lastName
        ),
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.applicantId'
      ),

      dataIndex: 'applicantId',
      render: (value, record) => (
        <>
          {contactsByIdState[record.employeeId]?.firstName +
            ' ' +
            contactsByIdState[record.employeeId]?.lastName}
        </>
      ),
    },
    {
      sorter: (a, b) =>
        a.remainingDaysPreviousYear - b.remainingDaysPreviousYear,
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.preYearRemainingAbsenceDays'
      ),

      className: classes.secondaryColumn,
      dataIndex: 'preYearRemainingAbsenceDays',
      render: (value, record) => <>{record.remainingDaysPreviousYear}</>,
    },
    {
      sorter: (a, b) => a.remainingVacationDays - b.remainingVacationDays,
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.remainingAbsenceDays'
      ),
      className: classes.secondaryColumn,
      dataIndex: 'remainingAbsenceDays',
      render: (value, record) => <>{record.remainingVacationDays}</>,
    },
    {
      sorter: (a, b) => a.entitlementOfYear - b.entitlementOfYear,
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.holidayEntitlements'
      ),
      className: classes.secondaryColumn,
      dataIndex: 'holidayEntitlements',
      render: (value, record) => <>{record.entitlementOfYear}</>,
    },
    {
      sorter: (a, b) => a.takenVacationDays - b.takenVacationDays,
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.takenVacationDays'
      ),
      className: classes.secondaryColumn,
      dataIndex: 'applicantakenVacationDaystId',
      render: (value, record) => <>{record.takenVacationDays}</>,
    },
    {
      sorter: (a, b) => a.requestedVacationDays - b.requestedVacationDays,
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.requestedVacationDays'
      ),
      className: classes.secondaryColumn,
      dataIndex: 'requestedVacationDays',
      render: (value, record) => <>{record.requestedVacationDays}</>,
    },
    {
      sorter: (a, b) => a.acceptedVacationDays - b.acceptedVacationDays,
      title: t(
        'absences:remainingAbsenceManagement.table.columnTitle.acceptedVacationDays'
      ),
      className: classes.secondaryColumn,
      dataIndex: 'acceptedVacationDays',
      render: (value, record) => <>{record.acceptedVacationDays}</>,
    },
  ];

  return (
    <Form layout="vertical" className={classes.content}>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={8}>
          <Form.Item label={t('common:filter.label.period')}>
            <Select
              className={classes.fullWidth}
              placeholder={t('common:filter.placeholder.period')}
              value={year}
              defaultValue={year}
              onChange={handleYearPicker}
              onSearch={(value) => {
                if (value.length === 4 && !isNaN(Number(value))) {
                  handleYearPicker(Number(value));
                }
              }}
              showSearch
            >
              {getYearOptions()}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t('common:filter.label.offices')}>
            <OfficePicker
              multiple={!officeId ? true : false}
              defaultValue={officeId}
              disabled={officeId ? true : false}
              onChange={handleOfficePicker}
              label={t('absences:absenceTimeline.officePicker.label')}
            />
          </Form.Item>
        </Col>
        <Col span={8} className={classes.lastColumn}>
          <Flex.Row
            flex={1}
            alignItems={'center'}
            justifyContent="flex-end"
            childrenGap={theme.old.spacing.defaultPadding}
          >
            <div>
              {remainingAbsenceList.length > 0 && (
                <Text>
                  {t('accounting:table.results')}:{' '}
                  {contactsToFilter.length === 0
                    ? remainingAbsenceList.length
                    : remainingAbsenceList.filter((item) =>
                        contactsToFilter.includes(item.employeeId)
                      ).length}
                </Text>
              )}
            </div>
            {contactsToFilter && (
              <Button
                type="link"
                className={classes.danger}
                onClick={handleFilterReset}
                iconProp={['fal', 'trash-alt']}
              >
                {t('common:filter.resetFilter')}
              </Button>
            )}
          </Flex.Row>
        </Col>
      </Row>
      <Collapse ghost className={classes.collapse} defaultActiveKey={['1']}>
        <Collapse.Panel
          header={
            <Flex.Row flex={1}>
              <div className={classes.radioLabelCol}>
                {t('common:filter.label.filterHeader')}
              </div>
              <Divider className={classes.divider} />
            </Flex.Row>
          }
          key="1"
        >
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={8}>
              <Form.Item label={t('common:filter.label.employees')}>
                <HRRemainingAbsenceContactPicker
                  value={contactsToFilter}
                  contactIds={filterContactsIds}
                  onChange={handleContactPicker}
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <div className={classes.table}>
        <Table<RemainingAbsence>
          className={classes.root}
          dataSource={
            contactsToFilter.length > 0
              ? remainingAbsenceList.filter((item) =>
                  contactsToFilter.includes(item.employeeId)
                )
              : remainingAbsenceList
          }
          columns={columns}
          scroll={{ x: 'max-content', y: 'calc(100% - 64px)' }}
          locale={tableTranslations(t)}
          rowClassName={classes.row}
          loading={{
            spinning: isRemainingAbsenceLoading,
            indicator: <PrioSpinner alignSelf />,
          }}
          pagination={false}
          sticky
        />
      </div>
    </Form>
  );
};

export default HRRemainingAbsencePage;
