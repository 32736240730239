import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Empty, Menu, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import { CompensationPayment } from '../../../../models/TimeKeeping';
import VirtualTable2, {
  VColumn,
} from '../../../../components/VirtualTable/VirtualTable2';
import moment from 'moment';
import PrioSpinner from '../../../../components/PrioSpinner';
import ContactText from '../../../contacts/components/ContactText';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../../apps/main/rootReducer';
import HREditCompensationPaymentDrawer from './HREditCompensationPaymentDrawer';
import { OfficeId } from '../../../../models/Types';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../../theme/types';
import { MENU_BUTTON_SIZE } from '../../../../constants';
import {
  apiDeleteCompensationPayment,
  apiPayCompensationPayment,
} from '../../../timeKeeping/api';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa': {
      width: '14px',
    },
  },
  cell: {
    padding: 10,
  },
  lastCell: { padding: '10px 0px 10px 10px' },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  row: {},
  menuButton: {
    backgroundColor: 'transparent',
    height: MENU_BUTTON_SIZE,
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
}));

interface HRCompensationPaymentsTableProps {
  className?: string;
  officeId?: OfficeId;
  compensationPayments: CompensationPayment[];
  selectedPayments: CompensationPayment[];
  setSelectedPayments: (payments: CompensationPayment[]) => void;
  reload: VoidFunction;
  loading: boolean;
}

export const HRCompensationPaymentsTable: React.FC<
  HRCompensationPaymentsTableProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    officeId,
    compensationPayments,
    selectedPayments,
    loading,
    setSelectedPayments,
    reload,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contactsById = useSelector(getContactsByIdState);
  const [paymentToPayOut, setPaymentToPayOut] =
    useState<CompensationPayment>(null);
  const [paymentToEdit, setPaymentToEdit] = useState<CompensationPayment>(null);
  const [paymentToDelete, setPaymentToDelete] =
    useState<CompensationPayment>(null);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleRowClick = (payment: CompensationPayment) => {
    onEditCompensationPayment(payment);
  };

  const onPayOutCompensationPayment = (payment: CompensationPayment) => {
    setPaymentToPayOut(payment);
  };
  const onPayOutModalOkAction = async () => {
    await apiPayCompensationPayment(
      paymentToPayOut.compensationPaymentId,
      officeId
    );
    reload();
    setPaymentToPayOut(null);
  };
  const onPayOutModalCancelAction = () => {
    setPaymentToPayOut(null);
  };

  const onEditCompensationPayment = (payment: CompensationPayment) => {
    setPaymentToEdit(payment);
  };
  const onEditDrawerSaveAction = () => {
    setPaymentToEdit(null);
    reload();
  };
  const onEditDrawerCancelAction = () => {
    setPaymentToEdit(null);
  };

  const onDeleteCompensationPayment = (payment: CompensationPayment) => {
    setPaymentToDelete(payment);
  };
  const onDeleteModalOkAction = async () => {
    await apiDeleteCompensationPayment(
      paymentToDelete.compensationPaymentId,
      officeId
    );
    reload();
    setPaymentToDelete(null);
  };
  const onDeleteModalCancelAction = () => {
    setPaymentToDelete(null);
  };
  //#endregion

  //#region ------------------------------ Components
  const noItemsScreen = useCallback(
    () => (
      <div className={classes.noItemsScreen}>
        <div className={classes.noItemsIconContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className={classes.noItemsText}>
                {t('documents:table.noItems')}
              </span>
            }
          />
        </div>
      </div>
    ),
    [classes, t]
  );

  const menu = (payment: CompensationPayment) => {
    const disableActionButton = !!payment.payoutDate;
    return (
      <Menu
        onClick={(e) => {
          e.domEvent.stopPropagation();
        }}
      >
        <Menu.Item
          disabled={disableActionButton}
          onClick={(e) => {
            e.domEvent.preventDefault();
            onPayOutCompensationPayment(payment);
          }}
        >
          {t(
            'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.payOut'
          )}
        </Menu.Item>
        <Menu.Item
          disabled={disableActionButton}
          onClick={(e) => {
            e.domEvent.preventDefault();
            onEditCompensationPayment(payment);
          }}
        >
          {t(
            'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.edit'
          )}
        </Menu.Item>
        <Menu.Item
          disabled={disableActionButton}
          onClick={(e) => {
            e.domEvent.preventDefault();
            onDeleteCompensationPayment(payment);
          }}
        >
          {t(
            'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.delete'
          )}
        </Menu.Item>
      </Menu>
    );
  };
  //#endregion

  //#region ------------------------------ Columns
  const columns: VColumn<CompensationPayment>[] = [
    {
      Cell: ({
        row: {
          original: { payoutDate },
        },
      }) => {
        return (
          payoutDate && (
            <div
              title={t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.paidOut'
              )}
            >
              <FontAwesomeIcon icon={['fal', 'lock']} />
            </div>
          )
        );
      },
      title: () => (
        <div
          title={t(
            'hr:timeAndLeaveManagement.compensationPaymentsTable.payout'
          )}
        >
          <FontAwesomeIcon icon={['fal', 'lock']} />
        </div>
      ),
      width: 3,
      minWidth: 34,
      id: 'status',
      accessor: 'payoutDate',
      className: classes.cell,
      sortType: (rowA, rowB) => {
        const paymentA = !!rowA.original.payoutDate;
        const paymentB = !!rowB.original.payoutDate;
        if (paymentA === paymentB) return 0;
        if (paymentA) return -1;
        return 1;
      },
    },
    {
      id: 'monthlyCloseId',
      width: 18,
      title: t(
        'hr:timeAndLeaveManagement.compensationPaymentsTable.employeeId'
      ),
      accessor: 'monthlyCloseId',
      sortType: (rowA, rowB) => {
        const employeeIdA = rowA.original.employeeId;
        const employeeIdB = rowB.original.employeeId;
        const a = contactsById[employeeIdA?.toLowerCase() ?? ''];
        const b = contactsById[employeeIdB?.toLowerCase() ?? ''];
        const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
        if (lastNameCompare !== 0) return lastNameCompare;
        return a?.firstName?.localeCompare(b?.firstName);
      },
      Cell: ({
        row: {
          original: { employeeId },
        },
      }) => {
        if (!employeeId) {
          return '';
        }
        return <ContactText contactId={employeeId} />;
      },
      className: classes.cell,
    },
    {
      Cell: ({
        row: {
          original: { hours },
        },
      }) => {
        return hours;
      },
      title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.hours'),
      width: 8,
      id: 'hours',
      accessor: 'hours',
      className: classes.cell,
    },
    {
      Cell: ({
        row: {
          original: { payoutDate },
        },
      }) => {
        if (payoutDate && !payoutDate.includes('0001-01-01T00:00:00')) {
          return moment(payoutDate).format('DD.MM.YYYY');
        }
        return '-';
      },
      title: t(
        'hr:timeAndLeaveManagement.compensationPaymentsTable.payoutDate'
      ),
      width: 10,
      id: 'payoutDate',
      accessor: 'payoutDate',
      className: classes.cell,
    },
    {
      Cell: ({
        row: {
          original: { expectedPayoutDate },
        },
      }) => {
        if (
          expectedPayoutDate &&
          !expectedPayoutDate.includes('0001-01-01T00:00:00')
        ) {
          return moment(expectedPayoutDate).format('DD.MM.YYYY');
        }
        return '-';
      },
      title: t(
        'hr:timeAndLeaveManagement.compensationPaymentsTable.expectedPayoutDate'
      ),
      width: 11,
      id: 'expectedPayoutDate',
      accessor: 'expectedPayoutDate',
      className: classes.cell,
    },
    {
      Cell: ({
        row: {
          original: { paidBy },
        },
      }) => {
        return paidBy ? <ContactText contactId={paidBy} /> : '-';
      },
      title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.paidBy'),
      width: 16,
      id: 'paidBy',
      accessor: 'paidBy',
      className: classes.cell,
      sortType: (rowA, rowB) => {
        const a = contactsById[rowA.original.paidBy?.toLowerCase() ?? ''];
        const b = contactsById[rowB.original.paidBy?.toLowerCase() ?? ''];
        const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
        if (lastNameCompare !== 0) return lastNameCompare;
        return a?.firstName?.localeCompare(b?.firstName);
      },
    },
    {
      Cell: ({
        row: {
          original: { createdDate },
        },
      }) => {
        return moment(createdDate).format('DD.MM.YYYY');
      },
      title: t(
        'hr:timeAndLeaveManagement.compensationPaymentsTable.createdDate'
      ),
      width: 15,
      id: 'createdDate',
      accessor: 'createdDate',
      className: classes.cell,
    },
    {
      Cell: (cellProps) => {
        const payment = cellProps?.row?.original;

        return (
          <Flex.Row
            alignItems="center"
            childrenGap={theme.old.spacing.defaultPadding}
          >
            <Flex.Item flex={1}>
              <ContactText contactId={payment.createdBy} />
            </Flex.Item>
            <Dropdown
              overlay={menu(payment)}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                iconProp={['fal', 'ellipsis-v']}
                className={classes.menuButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Dropdown>
          </Flex.Row>
        );
      },
      title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.createdBy'),
      width: 19,
      id: 'createdBy',
      accessor: 'createdBy',
      className: classes.lastCell,
      sortType: (rowA, rowB) => {
        const a = contactsById[rowA.original.createdBy?.toLowerCase() ?? ''];
        const b = contactsById[rowB.original.createdBy?.toLowerCase() ?? ''];
        const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
        if (lastNameCompare !== 0) return lastNameCompare;
        return a?.firstName?.localeCompare(b?.firstName);
      },
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setDrawerOpen(!!paymentToEdit);
  }, [paymentToEdit]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <VirtualTable2<
        CompensationPayment,
        {
          compensationPayments: CompensationPayment[];
        }
      >
        id={'cpt-1'}
        columns={columns}
        data={compensationPayments.sort((pA, pB) => {
          const employeeIdA = pA.employeeId;
          const employeeIdB = pB.employeeId;
          const a = contactsById[employeeIdA?.toLowerCase() ?? ''];
          const b = contactsById[employeeIdB?.toLowerCase() ?? ''];
          const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
          if (lastNameCompare !== 0) return lastNameCompare;
          return a?.firstName?.localeCompare(b?.firstName);
        })}
        dataToForceRender={{ compensationPayments }}
        noItemsScreen={noItemsScreen()}
        loading={
          loading &&
          compensationPayments.length === 0 && {
            loadingType: 'table',
            indicator: <PrioSpinner alignSelf />,
          }
        }
        selected={({ compensationPaymentId }) =>
          !!selectedPayments.find(
            (p) => p.compensationPaymentId === compensationPaymentId
          )
        }
        onRow={{
          triggerFunctions: (compensationPayment) => ({
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              handleRowClick(compensationPayment);
            },
          }),
        }}
        onChange={setSelectedPayments}
        classNameTableRow={classes.row}
        rowsAreSelectable
        columnsResizable
      />
      <Modal
        visible={!!paymentToPayOut}
        title={t(`hr:timeAndLeaveManagement.payOutModalSingle.title`, {
          amount: selectedPayments.length,
        })}
        okText={t(`hr:timeAndLeaveManagement.payOutModalSingle.okText`)}
        cancelText={t(`hr:timeAndLeaveManagement.payOutModalSingle.cancelText`)}
        onOk={onPayOutModalOkAction}
        onCancel={onPayOutModalCancelAction}
      >
        {t(`hr:timeAndLeaveManagement.payOutModalSingle.content`)}
      </Modal>
      <HREditCompensationPaymentDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        officeId={officeId}
        compensationPayment={paymentToEdit}
        onSave={onEditDrawerSaveAction}
        onCancel={onEditDrawerCancelAction}
        onPayOut={onPayOutCompensationPayment}
        onDelete={onDeleteCompensationPayment}
      />
      <Modal
        visible={!!paymentToDelete}
        title={t(`hr:timeAndLeaveManagement.deleteModalSingle.title`, {
          amount: selectedPayments.length,
        })}
        okText={t(`hr:timeAndLeaveManagement.deleteModalSingle.okText`)}
        cancelText={t(`hr:timeAndLeaveManagement.deleteModalSingle.cancelText`)}
        onOk={onDeleteModalOkAction}
        onCancel={onDeleteModalCancelAction}
      >
        {t(`hr:timeAndLeaveManagement.deleteModalSingle.content`)}
      </Modal>
    </div>
  );
};

export default HRCompensationPaymentsTable;
