import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Avatar, Empty, Modal, Select, Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllInternalOffices,
  getContact,
  getContactsByIdState,
  getOfficeMeAllOffices,
  getUserMe,
} from '../../../../apps/main/rootReducer';
import { Office } from '../../../../models/Office';
import { fetchInternalOffices } from '../../../companies/actions';
import {
  MonthlyClose,
  OvertimeHours,
  TimeKeepingDay,
} from '../../../../models/TimeKeeping';
import {
  apiApproveTimeKeepingDay,
  apiFetchEmployeeOvertimeHours,
  apiFetchMonthlyClose,
  apiFetchTimeKeepingDays,
  apiRejectTimeKeepingDay,
} from '../../../timeKeeping/api';
import { ContactId, OfficeId } from '../../../../models/Types';
import { v4 as uuid } from 'uuid';
import { stringToColour } from '../../../../util';
import moment from 'moment';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import ProgressDots from '../../../../components/Progress/ProgressDots';
import { Contact } from '../../../../models/Contact';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    fontWeight: theme.old.typography.fontWeight.regular,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme.old.spacing.unit(2),
    '&:not(:last-child)': {
      borderBottom: theme.old.borders.content,
    },
  },
  dots: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}));

interface ListItem {
  id: string;
  contactId: ContactId;
  name?: string;
  firstName?: string;
  lastName?: string;
  eMail?: string;
  type: 'overtimeHour' | 'timeKeepingDayApproval' | 'missingMonthlyClose';
  value: OvertimeHours | TimeKeepingDay | MonthlyClose;
}

interface DashboardHRTimeKeepingOverviewProps {
  className?: string;
}

export const DashboardHRTimeKeepingOverview: React.FC<
  DashboardHRTimeKeepingOverviewProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const contactMe = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, userMe?.id)
  );

  const isGlobalHR = useMemo(() => {
    return userMe?.prioData?.globalRoles?.includes('globalHR') ?? false;
  }, [userMe]);

  const myOffices = useSelector(getOfficeMeAllOffices);

  const globalOffices = useSelector(getAllInternalOffices) as Office[];

  const offices = useMemo(() => {
    if (isGlobalHR) {
      return globalOffices;
    } else {
      return myOffices.filter(
        ({ officeId }) =>
          userMe.prioData.officeRoles[officeId]?.includes('officeHR')
      );
    }
  }, [myOffices, globalOffices, userMe, isGlobalHR]);

  const contactsById = useSelector(getContactsByIdState);

  const [selectedOfficeId, setSelectedOfficeId] = useState<string>(
    contactMe?.officeId
  );

  const [overtimeHours, setOvertimeHours] = useState<ListItem[]>([]);

  const [timeKeepingDays, setTimeKeepingDays] = useState<ListItem[]>([]);

  const [missingMonthlyClose, setMissingMonthlyClose] = useState<ListItem[]>(
    []
  );

  const items: ListItem[] = useMemo(() => {
    return [...overtimeHours, ...timeKeepingDays, ...missingMonthlyClose]
      .map(({ contactId, ...rest }) => ({
        ...rest,
        contactId: contactId,
        name: `${contactsById[contactId]?.firstName ?? ''} ${
          contactsById[contactId]?.lastName ?? ''
        }`,
        firstName: contactsById[contactId]?.firstName ?? '',
        lastName: contactsById[contactId]?.lastName ?? '',
        eMail: contactsById[contactId]?.eMail ?? '',
      }))
      .sort((a, b) => {
        const lastNameCompare = a.lastName?.localeCompare(b.lastName);
        if (lastNameCompare !== 0) return lastNameCompare;
        return a.firstName?.localeCompare(b.firstName);
      });
  }, [overtimeHours, timeKeepingDays, missingMonthlyClose, contactsById]);

  const [selectedItem, setSelectedItem] = useState<ListItem>(null);

  const [isFetchingOvertimeHours, setIsFetchingOvertimeHours] =
    useState<boolean>(false);

  const [isFetchingTimeKeepingDays, setIsFetchingTimeKeepingDays] =
    useState<boolean>(false);

  const [isFetchingMonthlyClose, setIsFetchingMonthlyClose] =
    useState<boolean>(false);

  const abortControllerTimeKeepingDayRef = useRef<AbortController>(null);
  const abortControllerMonthlyCloseRef = useRef<AbortController>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ListItem
  ) => void = (e, item) => {
    switch (item.type) {
      case 'overtimeHour': {
        break;
      }
      case 'timeKeepingDayApproval': {
        setSelectedItem(item);
        break;
      }
      case 'missingMonthlyClose': {
        break;
      }
    }
  };

  const handleCloseTimeKeepingModal = () => {
    setSelectedItem(null);
  };

  const handleCancelTimeKeepingModal = async () => {
    handleCloseTimeKeepingModal();
    const { timeKeepingDayId } = selectedItem.value as TimeKeepingDay;
    const { data } = await apiRejectTimeKeepingDay(
      timeKeepingDayId,
      selectedOfficeId
    );
    if (data) {
      setTimeKeepingDays([
        ...timeKeepingDays.filter(({ id }) => id !== timeKeepingDayId),
      ]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('timeKeeping:messages.errorMessages.rejectError'),
      });
    }
  };

  const handleOkTimeKeepingModal = async () => {
    handleCloseTimeKeepingModal();
    const { timeKeepingDayId } = selectedItem.value as TimeKeepingDay;
    const { data } = await apiApproveTimeKeepingDay(
      timeKeepingDayId,
      selectedOfficeId
    );
    if (data) {
      setTimeKeepingDays([
        ...timeKeepingDays.filter(({ id }) => id !== timeKeepingDayId),
      ]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('timeKeeping:messages.errorMessages.approveError'),
      });
    }
  };

  const fetchTimeKeepingDays = useCallback(async (officeId?: OfficeId) => {
    if (abortControllerTimeKeepingDayRef.current) {
      abortControllerTimeKeepingDayRef.current.abort();
    }
    abortControllerTimeKeepingDayRef.current = new AbortController();
    setIsFetchingTimeKeepingDays(true);
    const { data } = await apiFetchTimeKeepingDays(
      officeId,
      abortControllerTimeKeepingDayRef.current
    );
    if (data) {
      setTimeKeepingDays(
        data.map((timeKeepingDay) => ({
          id: timeKeepingDay.timeKeepingDayId,
          contactId: timeKeepingDay.employeeId,
          value: timeKeepingDay,
          type: 'timeKeepingDayApproval',
        }))
      );
    }
    setIsFetchingTimeKeepingDays(false);
  }, []);

  const fetchMonthlyClose = useCallback(async (officeId?: OfficeId) => {
    if (abortControllerMonthlyCloseRef.current) {
      abortControllerMonthlyCloseRef.current.abort();
    }
    abortControllerMonthlyCloseRef.current = new AbortController();
    setIsFetchingMonthlyClose(true);
    const { data } = await apiFetchMonthlyClose(
      {
        from: moment()
          .subtract(1, 'month')
          .startOf('month')
          .toISOString(true)
          .split('T')[0],
        to: moment()
          .subtract(1, 'month')
          .endOf('month')
          .toISOString(true)
          .split('T')[0],
        userIds: [],
        officeIds: [],
      },
      officeId,
      abortControllerMonthlyCloseRef.current
    );
    if (data) {
      setMissingMonthlyClose(
        data.reduce((currentArray, monthlyClose) => {
          const { employeeId, employeeConfirmation, monthlyCloseId } =
            monthlyClose;
          if (!employeeConfirmation) {
            return currentArray.concat([
              {
                id: monthlyCloseId,
                contactId: employeeId,
                value: monthlyClose,
                type: 'missingMonthlyClose',
              },
            ]);
          }
          return currentArray;
        }, [] as ListItem[])
      );
    }
    setIsFetchingMonthlyClose(false);
  }, []);

  const handleOnSelect = (officeId: OfficeId) => {
    setSelectedOfficeId(officeId);
    fetchTimeKeepingDays(officeId);
    fetchMonthlyClose(officeId);
  };
  //#endregion

  //#region ------------------------------ Components
  const getDescription = (item: ListItem) => {
    switch (item.type) {
      case 'overtimeHour': {
        return t(`dashboard:hrTimeKeepingOverview.descriptions.${item.type}`);
      }
      case 'timeKeepingDayApproval': {
        const value = item.value as TimeKeepingDay;
        return t(`dashboard:hrTimeKeepingOverview.descriptions.${item.type}`, {
          date: moment(value?.timeKeepingEntries[0]?.startTime).format(
            'dddd, DD. MMMM YYYY'
          ),
        });
      }
      case 'missingMonthlyClose': {
        return t(`dashboard:hrTimeKeepingOverview.descriptions.${item.type}`, {
          month: moment((item.value as MonthlyClose).month).format('MMMM YYYY'),
        });
      }
    }
  };

  const getContent = (item: ListItem) => {
    switch (item.type) {
      case 'overtimeHour': {
        return (
          <div
            style={{
              color:
                (item.value as OvertimeHours).accumulatedOvertimeHours < 0
                  ? theme.old.palette.chromaticPalette.red
                  : undefined,
            }}
          >
            {`${(item.value as OvertimeHours).accumulatedOvertimeHours}h`}
          </div>
        );
      }
      case 'timeKeepingDayApproval': {
        return (
          <div>
            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
          </div>
        );
      }
      case 'missingMonthlyClose': {
        return null;
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedOfficeId === null && contactMe) {
      setSelectedOfficeId(contactMe.officeId);
    }
  }, [selectedOfficeId, contactMe]);

  useEffect(() => {
    if (isGlobalHR) {
      dispatch(fetchInternalOffices());
    }
  }, [isGlobalHR, dispatch]);

  useEffect(() => {
    const fetchOvertimeHours = async () => {
      setIsFetchingOvertimeHours(true);
      const { data } = await apiFetchEmployeeOvertimeHours();
      if (data) {
        setOvertimeHours(
          data.map((overtimeHours) => ({
            id: uuid(),
            contactId: overtimeHours.employeeId,
            value: overtimeHours,
            type: 'overtimeHour',
          }))
        );
      }
      setIsFetchingOvertimeHours(false);
    };
    fetchOvertimeHours();
  }, []);

  useEffect(() => {
    if (selectedOfficeId) {
      fetchMonthlyClose(selectedOfficeId);
      fetchTimeKeepingDays(selectedOfficeId);
    }
  }, [selectedOfficeId, fetchMonthlyClose, fetchTimeKeepingDays]);
  //#endregion

  return (
    <>
      {(isFetchingMonthlyClose ||
        isFetchingOvertimeHours ||
        isFetchingTimeKeepingDays) && (
        <div className={classes.dots}>
          <ProgressDots />
        </div>
      )}
      <Flex.Column
        className={classNames(classes.root, className)}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <Flex.Row childrenGap={theme.old.spacing.baseSpacing}>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
            {t('dashboard:hrTimeKeepingOverview.title')}
          </Typography.Title>
        </Flex.Row>
        {offices.length > 1 && (
          <Select<string>
            options={offices
              .sort(({ name: a }, { name: b }) => a.localeCompare(b))
              .map(({ officeId, name }) => ({
                value: officeId,
                label: name,
              }))}
            value={selectedOfficeId}
            onSelect={handleOnSelect}
            optionFilterProp="label"
            showSearch
          />
        )}
        <Flex.Item flex={1}>
          {items.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span
                  style={{
                    textAlign: 'center',
                    color: theme.old.typography.colors.muted,
                  }}
                >
                  {t('common:table.noItems')}
                </span>
              }
            />
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={items.length}
                  rowHeight={64}
                  rowRenderer={({ index, style }: ListRowProps) => {
                    const item = items[index];
                    return (
                      <div
                        className={classes.listItem}
                        onClick={(e) => handleOnClick(e, item)}
                        style={{
                          ...style,
                          cursor:
                            item.type === 'timeKeepingDayApproval'
                              ? 'pointer'
                              : 'default',
                        }}
                      >
                        <Avatar
                          className={classes.avatar}
                          style={{
                            backgroundColor: stringToColour(item.eMail),
                          }}
                        >
                          {`${item.firstName ? item.firstName[0] : ''}${
                            item.lastName ? item?.lastName[0] : ''
                          }`}
                        </Avatar>
                        <Flex.Column
                          flex={1}
                          marginRight={theme.old.spacing.unit(2)}
                          marginLeft={theme.old.spacing.unit(2)}
                        >
                          <Typography.Title
                            level={4}
                            style={{ margin: 0 }}
                            ellipsis
                          >
                            {item.name}
                          </Typography.Title>
                          <Typography.Text ellipsis type="secondary">
                            {getDescription(item)}
                          </Typography.Text>
                        </Flex.Column>
                        {getContent(item)}
                      </div>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Flex.Item>
      </Flex.Column>
      <Modal
        visible={
          (selectedItem?.value as TimeKeepingDay)?.timeKeepingDayId !==
          undefined
        }
        onCancel={handleCloseTimeKeepingModal}
        title={t('dashboard:hrTimeKeepingOverview.modal.title')}
        closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
        footer={
          <Flex.Row justifyContent="flex-end">
            <Button
              onClick={handleCancelTimeKeepingModal}
              style={{ marginRight: '8px' }}
              type="link"
            >
              {t('dashboard:hrTimeKeepingOverview.modal.cancelText')}
            </Button>
            <Button type="primary" onClick={handleOkTimeKeepingModal}>
              {t('dashboard:hrTimeKeepingOverview.modal.okText')}
            </Button>
          </Flex.Row>
        }
      >
        <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
          <Flex.Item>
            <span>
              {`${t('dashboard:hrTimeKeepingOverview.modal.content1', {
                name: selectedItem?.name ?? '',
              })} ${moment(
                (selectedItem?.value as TimeKeepingDay)?.timeKeepingEntries[0]
                  ?.startTime
              ).format('dddd')}, `}
            </span>
            <strong>
              {moment(
                (selectedItem?.value as TimeKeepingDay)?.timeKeepingEntries[0]
                  ?.startTime
              ).format('DD. MMMM YYYY')}
            </strong>
            <span>
              {`, ${t('dashboard:hrTimeKeepingOverview.modal.content2')} `}
            </span>
            <strong>
              {moment(
                (
                  selectedItem?.value as TimeKeepingDay
                )?.timeKeepingEntries?.sort((a, b) =>
                  moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
                )[0]?.startTime
              ).format('H:mm')}
            </strong>
            <span>
              {` ${t('dashboard:hrTimeKeepingOverview.modal.content3')} `}
            </span>
            <strong>
              {moment(
                (
                  selectedItem?.value as TimeKeepingDay
                )?.timeKeepingEntries?.sort((a, b) =>
                  moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
                )[
                  (selectedItem?.value as TimeKeepingDay)?.timeKeepingEntries
                    ?.length - 1
                ]?.endTime
              ).format('H:mm')}
            </strong>
            <span>{` ${t(
              'dashboard:hrTimeKeepingOverview.modal.content4'
            )}`}</span>
          </Flex.Item>
          {(selectedItem?.value as TimeKeepingDay)?.notes && (
            <Flex.Item>
              <Flex.Column>
                <Typography.Text type="secondary">
                  {`${t('dashboard:hrTimeKeepingOverview.modal.content5')}`}
                </Typography.Text>
                <Typography.Text>
                  {(selectedItem?.value as TimeKeepingDay)?.notes}
                </Typography.Text>
              </Flex.Column>
            </Flex.Item>
          )}
          <Flex.Item>
            <span>{` ${t(
              'dashboard:hrTimeKeepingOverview.modal.content6'
            )}`}</span>
          </Flex.Item>
        </Flex.Column>
      </Modal>
    </>
  );
};

export default DashboardHRTimeKeepingOverview;
