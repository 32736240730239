import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Popover } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { useParams } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import HRTimekeepingMenu from './HRTimekeepingMenu';
import NavigationBar from '../../../../components/NavigationBar';
import HRMonthlyClosePage, {
  HRMonthlyClosePageRefProps,
} from './HRMonthlyClosePage';
import HRCompensationPaymentsPage, {
  HRCompensationPaymentsPageRefProps,
} from './HRCompensationPaymentsPage';
import { useTranslation } from 'react-i18next';
import { PrioTheme } from '../../../../theme/types';
import equals from 'deep-equal';
import { CompensationPayment } from '../../../../models/TimeKeeping';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
  overflow: {
    overflow: 'hidden',
  },
  popover: {
    width: '350px',
  },
}));

interface HRTimekeepingProps {
  className?: string;
  pathPrefix?: string;
}

export const HRTimekeeping: React.FC<HRTimekeepingProps> = (props) => {
  //#region ------------------------------ Defaults

  const { className, pathPrefix } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const { selectedList, officeId } = useParams();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyClosesPageRef = useRef<HRMonthlyClosePageRefProps>(null);

  const compensationPaymentsPageRef =
    useRef<HRCompensationPaymentsPageRefProps>(null);

  const [
    multipleCompensationPaymentsSelected,
    setMultipleCompensationPaymentsSelected,
  ] = useState<boolean>(false);

  const [selectedCompensationPayments, setSelectedCompensationPayments] =
    useState<CompensationPayment[]>([]);

  const notPaidOutCompensationPaymentsSelected =
    selectedCompensationPayments.filter(
      (compensationPayment) => !compensationPayment.payoutDate
    );

  const isPaidOutCompensationPaymentsSelected = !equals(
    selectedCompensationPayments,
    notPaidOutCompensationPaymentsSelected
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Components
  let navigationBar: React.ReactNode;
  let component: React.ReactNode;
  switch (selectedList) {
    /*
    case 'overview':
      component = <h1>Overview</h1>;
      break;
     
    case 'timekeeping':
      component = (
        <HRTimekeepingPage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;
       */
    case 'monthlyClose':
      component = (
        <HRMonthlyClosePage
          className={classNames(classes.content)}
          officeId={officeId}
          ref={monthlyClosesPageRef}
          setMultipleCompensationPaymentsSelected={
            setMultipleCompensationPaymentsSelected
          }
        />
      );
      navigationBar = (
        <>
          <Button
            type="primary"
            iconProp={['fal', 'plus']}
            onClick={() => {
              if (monthlyClosesPageRef?.current) {
                monthlyClosesPageRef.current.openCreateCompensationPaymentDrawer();
              }
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.navigationBar.monthlyCloses.createCompensationPayment'
            )}
          </Button>
        </>
      );
      break;

    case 'compensationPayments':
      component = (
        <HRCompensationPaymentsPage
          className={classNames(classes.content)}
          officeId={officeId}
          ref={compensationPaymentsPageRef}
          setMultipleCompensationPaymentsSelected={
            setMultipleCompensationPaymentsSelected
          }
          setSelectedCompensationPayments={setSelectedCompensationPayments}
        />
      );
      navigationBar = (
        <>
          <Button
            type="primary"
            iconProp={['fal', 'plus']}
            onClick={() => {
              if (compensationPaymentsPageRef?.current) {
                compensationPaymentsPageRef.current.openCreateCompensationPaymentDrawer();
              }
            }}
          >
            {t(
              `hr:timeAndLeaveManagement.navigationBar.compensationPayments.createCompensationPayment`
            )}
          </Button>
          <Button
            disabled={
              !multipleCompensationPaymentsSelected ||
              isPaidOutCompensationPaymentsSelected
            }
            type="link"
            onClick={() => {
              if (compensationPaymentsPageRef?.current) {
                compensationPaymentsPageRef.current.payOut();
              }
            }}
          >
            {t(
              `hr:timeAndLeaveManagement.navigationBar.compensationPayments.payCompensationPayment`
            )}
          </Button>
          <Button
            disabled={
              !multipleCompensationPaymentsSelected ||
              isPaidOutCompensationPaymentsSelected
            }
            type="link"
            onClick={() => {
              if (compensationPaymentsPageRef?.current) {
                compensationPaymentsPageRef.current.delete();
              }
            }}
          >
            {t(
              `hr:timeAndLeaveManagement.navigationBar.compensationPayments.deleteCompensationPayment`
            )}
          </Button>
          {multipleCompensationPaymentsSelected &&
            isPaidOutCompensationPaymentsSelected && (
              <Popover
                placement="bottom"
                trigger={'click'}
                content={
                  <div className={classes.popover}>
                    {t(
                      `hr:timeAndLeaveManagement.navigationBar.compensationPayments.popoverInfoText`
                    )}
                  </div>
                }
              >
                <Button iconProp={['fal', 'info-circle']} type="link"></Button>
              </Popover>
            )}
        </>
      );
      break;
    default:
      break;
  }
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>{navigationBar}</NavigationBar>
      <Flex.Row flex={1} className={classes.overflow}>
        <HRTimekeepingMenu
          selectedList={selectedList}
          urlPrefix={pathPrefix}
          className={classes.menu}
        />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default HRTimekeeping;
