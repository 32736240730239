import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InternalProjectContactsTable from './InternalProjectContactsTable';
import { InternalProjectContactItem } from '../../../models/ProjectContacts';

import { ProjectId } from '../../../models/Types';
import { fetchInternalProjectContacts, fetchHourlyRates } from '../actions';
import {
  getContactsByIdState,
  getInternalProjectContactsData,
  getInternalProjectContactsIsFetching,
} from '../../../apps/main/rootReducer';
import equals from 'deep-equal';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  noEdit: {
    padding: theme.old.spacing.unit(2),
  },
  table: {},
}));

const useInternalProjectContacts = (projectId: ProjectId) => {
  const internalProjectsContacs = useSelector(getInternalProjectContactsData);
  const contactsByIdState = useSelector(getContactsByIdState);

  const [contactItems, setContactItems] = useState<
    InternalProjectContactItem[]
  >([]);

  useEffect(() => {
    const _contactItems: InternalProjectContactItem[] = (
      internalProjectsContacs[projectId] ?? []
    )
      .map((projectContact) => ({
        internalProjectContact: projectContact,
        contact: contactsByIdState[projectContact.contactId],
      }))
      .filter((item) => !!item.contact)
      .sort((item) =>
        item.contact.lastName.localeCompare(item.contact.lastName)
      );
    if (!equals(_contactItems, contactItems)) {
      setContactItems(_contactItems);
    }
  }, [internalProjectsContacs, contactsByIdState, contactItems, projectId]);

  return contactItems;
};

interface InternalProjectContactsTablePageProps {
  className?: string;
  projectId: ProjectId;
}

export const InternalProjectContactsTablePage: React.FC<InternalProjectContactsTablePageProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const classes = useStyles();
    const { className, projectId } = props;
    const dispatch = useDispatch();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const contactItems: InternalProjectContactItem[] =
      useInternalProjectContacts(projectId);
    const isFetching = useSelector(getInternalProjectContactsIsFetching);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      dispatch(fetchHourlyRates(projectId));
      dispatch(fetchInternalProjectContacts(projectId));
    }, [dispatch, projectId]);
    //#endregion

    return (
      <div className={classNames(classes.root, className)}>
        <InternalProjectContactsTable
          className={classes.table}
          contactItems={contactItems}
          projectId={projectId}
          loading={isFetching && contactItems.length === 0}
          isSubproject={false}
        />
      </div>
    );
  });

export default InternalProjectContactsTablePage;
