import { Project } from '../../../models/Project';

export const sortProjects: (projects: Project[]) => Project[] = (projects) => {
  const parentProjects = projects
    .filter((project) => !project.parentProject)
    .sort((a: Project, b: Project) => {
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    });
  const subProjectsByParentProjectId: {
    [parenProjectId: string]: Project[];
  } = projects.reduce((map, subProject) => {
    const parentProjectId = subProject.parentProject;
    if (parentProjectId) {
      if (!map[parentProjectId]) {
        map[parentProjectId] = [];
      }
      map[parentProjectId].push(subProject);
    }
    return map;
  }, {});
  return parentProjects.reduce((acc, parentProject) => {
    const subProjects = subProjectsByParentProjectId[parentProject.projectId];
    acc.push(parentProject);
    if (subProjects) {
      acc.push(
        ...subProjects.sort((a: Project, b: Project) => {
          const numberCompare = a.number.localeCompare(b.number);
          if (numberCompare !== 0) return numberCompare;
          return a.name.localeCompare(b.name);
        })
      );
    }
    return acc;
  }, []);
};
