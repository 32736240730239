import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Select } from 'antd';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    moment.locale(value);
  };

  return (
    <Select
      id="languageSelector"
      value={i18n.language}
      onChange={changeLanguage}
      style={{ zIndex: 999999, position: 'relative' }}
    >
      <Select.Option value="en">English</Select.Option>
      <Select.Option value="de">Deutsch</Select.Option>
      {/**
      <Select.Option value="fr">Français</Select.Option>
      <Select.Option value="es">Español</Select.Option>
       */}
    </Select>
  );
};

export default LanguageSelector;
