import React, { useState } from 'react';
import { Drawer, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { OfficeId } from '../../../models/Types';
import {
  AbsenceProposal,
  UpdateAbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import AbsenceProposalDetails from './AbsenceProposalDetails';
import { InternalOffice } from '../../../models/Office';
import AbsenceProposalDetailsEdit from './AbsenceProposalDetailsEdit';
import useAccessRights from '../../users/hooks/useAccessRights';
import { apiUpdateAbsenceProposal } from '../api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import { updateLocalAbsenceProposal } from '../actions';

interface AbsenceProposalDrawerProps {
  absenceProposal?: AbsenceProposal;
  officesById: { [officeId: string]: InternalOffice };
  open?: boolean;
  setOpen: (open: boolean) => void;
  setSelectedAbsenceProposal: (value: AbsenceProposal) => void;
  officeId?: OfficeId;
  updateAbsenceTable?: (absenceProposal: AbsenceProposal) => void;
}

export const AbsenceProposalDetailsDrawer: React.FC<
  AbsenceProposalDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    absenceProposal,
    officesById,
    open,
    setOpen,
    setSelectedAbsenceProposal,
    officeId,
    updateAbsenceTable,
  } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const accessRights = useAccessRights(['writeOtherUserAbsence']);
  const canSelectApplicant = React.useMemo(
    () => accessRights['writeOtherUserAbsence'],
    [accessRights]
  );

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [subDrawerOpen, setSubDrawerOpen] = useState<boolean>(false);

  const userMe = useSelector(getUserMe);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const updateAbsenceProposal = async (
    request: UpdateAbsenceProposalRequest
  ) => {
    setIsSaving(true);
    const { result, data } = await apiUpdateAbsenceProposal(
      request,
      absenceProposal.absenceProposalId,
      officeId
    );
    if (result.status >= 200 && result.status < 300) {
      if (data) {
        updateAbsenceTable(data);
        setSelectedAbsenceProposal(data);
        if (userMe?.id.toLowerCase() === data.applicantId.toLowerCase()) {
          dispatch(updateLocalAbsenceProposal(data));
        }
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.updateAbsenceProposalError'),
      });
    }
    setIsSaving(false);
  };
  //#endregion

  //#region ------------------------------ Effects

  //#endregion

  return (
    <Drawer
      width={theme.old.components.drawerWidth}
      closable={true}
      onClose={() => setOpen(false)}
      visible={open}
      destroyOnClose={true}
    >
      <AbsenceProposalDetails
        absenceProposal={absenceProposal}
        officesById={officesById}
        setSubDrawerOpen={setSubDrawerOpen}
        setSelectedAbsenceProposal={setSelectedAbsenceProposal}
        setOpen={setOpen}
        officeId={officeId}
        updateAbsenceTable={updateAbsenceTable}
      ></AbsenceProposalDetails>
      <Drawer
        width={theme.old.components.drawerWidth}
        closable={true}
        onClose={() => setSubDrawerOpen(false)}
        visible={subDrawerOpen}
        destroyOnClose={true}
      >
        <AbsenceProposalDetailsEdit
          absenceProposal={absenceProposal}
          disableForm={isSaving}
          updateAbsenceProposal={updateAbsenceProposal}
          canSelectApplicant={canSelectApplicant}
          setSubDrawerOpen={setSubDrawerOpen}
          officeId={officeId}
        ></AbsenceProposalDetailsEdit>
      </Drawer>
    </Drawer>
  );
};

export default AbsenceProposalDetailsDrawer;
