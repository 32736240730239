import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import moment from 'moment';
import { CompensationPayment } from '../../../../models/TimeKeeping';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
} from '../../../../apps/main/rootReducer';
import { Contact } from '../../../../models/Contact';
import equals from 'deep-equal';
import { apiUpdateCompensationPayment } from '../../../timeKeeping/api';
import { OfficeId } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fullDateFormatFormatString } from '../../../../util';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  label: {
    fontSize: theme.old.typography.fontSize.label,
    color: theme.old.typography.colors.muted,
    paddingBottom: 4,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  dividerMargin: {
    margin: `0px ${theme.old.spacing.unit(3)}px`,
  },
  notes: { width: '100%' },
  labelWaitingFor: {
    color: 'rgba(0, 0, 0, 0.45)',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

interface HREditCompensationPaymentDrawerProps {
  className?: string;
  officeId?: OfficeId;
  compensationPayment: CompensationPayment;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  onPayOut: (payment: CompensationPayment) => void;
  onDelete: (payment: CompensationPayment) => void;
}

export const HREditCompensationPaymentDrawer: React.FC<
  HREditCompensationPaymentDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    officeId,
    compensationPayment,
    drawerOpen,
    setDrawerOpen,
    onSave,
    onCancel,
    onPayOut,
    onDelete,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const isCompansationPaymentPaid = compensationPayment?.payoutDate !== null;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEqual, setIsEqual] = useState<boolean>(true);

  const [updatedCompensationPayment, setUpdatedCompensationPayment] =
    useState<CompensationPayment>(compensationPayment);

  const employee = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, compensationPayment?.employeeId)
  );

  const createdBy = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, compensationPayment?.createdBy)
  );

  const paidBy = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, compensationPayment?.paidBy)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSave = async () => {
    setIsLoading(true);
    await apiUpdateCompensationPayment(
      updatedCompensationPayment,
      compensationPayment.compensationPaymentId,
      officeId
    );
    onSave();
    setIsLoading(false);
  };

  const handleOnCancel = () => {
    setDrawerOpen(false);
    onCancel();
  };

  const handleOnPayOut = async () => {
    onPayOut(compensationPayment);
    setDrawerOpen(false);
  };

  const handleOnDelete = async () => {
    onDelete(compensationPayment);
    setDrawerOpen(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setUpdatedCompensationPayment(compensationPayment);
  }, [compensationPayment]);

  useEffect(() => {
    setIsEqual(equals(updatedCompensationPayment, compensationPayment));
  }, [updatedCompensationPayment, compensationPayment]);
  //#endregion

  return (
    <Drawer
      width={theme.old.components.drawerWidth}
      closable={true}
      onClose={handleOnCancel}
      visible={drawerOpen}
      destroyOnClose={true}
      className={classNames(classes.root, className)}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
    >
      <Form<CompensationPayment>
        layout="vertical"
        className={classes.fullHeight}
        onFinish={handleSave}
        onChange={() =>
          setIsEqual(equals(updatedCompensationPayment, compensationPayment))
        }
      >
        <Flex.Column
          childrenGap={theme.old.spacing.unit(2)}
          className={classes.fullHeight}
        >
          <Typography.Title level={2} ellipsis>
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.title'
            )}
          </Typography.Title>
          <Flex.Item flex={1}>
            <Flex.Row childrenGap={theme.old.spacing.unit(4)}>
              <Flex.Column flex={1}>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.employee'
                    )}
                  >
                    {`${employee?.firstName ?? ''} ${employee?.lastName ?? ''}`}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.hours'
                    )}
                  >
                    <InputNumber
                      value={updatedCompensationPayment?.hours}
                      placeholder={compensationPayment?.hours.toString()}
                      onChange={(value) => {
                        if (value) {
                          setUpdatedCompensationPayment({
                            ...updatedCompensationPayment,
                            hours: value,
                          });
                        }
                      }}
                      onBlur={(value) => {
                        if (!value.target.value) {
                          setUpdatedCompensationPayment({
                            ...updatedCompensationPayment,
                            hours: compensationPayment?.hours,
                          });
                        }
                      }}
                      min={0.25}
                      step={0.25}
                      precision={2}
                      formatter={(value) => `${value}`.replace('.', ',')}
                      parser={(value) =>
                        parseFloat(value.replace(',', '.')) as 0.25
                      }
                      disabled={isCompansationPaymentPaid || isLoading}
                    />
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.expectedPayoutDate'
                    )}
                  >
                    <CustomSingleDatePicker
                      id="create_compensation_payments_modal_id"
                      value={moment(
                        updatedCompensationPayment?.expectedPayoutDate
                      )}
                      onChange={(date) => {
                        setUpdatedCompensationPayment({
                          ...updatedCompensationPayment,
                          expectedPayoutDate: date
                            ?.startOf('day')
                            .toISOString(true)
                            .split('.')[0],
                        });
                      }}
                      anchorDirection={'ANCHOR_RIGHT'}
                      small={true}
                      regular={false}
                      twoMonths={false}
                      withFullScreenPortal={false}
                      daySize={30}
                      hideKeyboardShortcutsPanel={true}
                      showDefaultInputIcon={true}
                      inputIconPosition={'after'}
                      disabled={isCompansationPaymentPaid || isLoading}
                    />
                  </Form.Item>
                </Flex.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.createdBy'
                    )}
                  >
                    {`${createdBy?.firstName ?? ''} ${
                      createdBy?.lastName ?? ''
                    }`}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.createdDate'
                    )}
                  >
                    {fullDateFormatFormatString(
                      compensationPayment?.createdDate
                    )}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.paidBy'
                    )}
                  >
                    {compensationPayment?.payoutDate ? (
                      `${paidBy?.firstName ?? ''} ${paidBy?.lastName ?? ''}`
                    ) : (
                      <div className={classes.labelWaitingFor}>
                        {t(
                          'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.waitingFor'
                        )}
                      </div>
                    )}
                  </Form.Item>
                </Flex.Item>
                <Flex.Item>
                  <Form.Item
                    label={t(
                      'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.payoutDate'
                    )}
                  >
                    {compensationPayment?.payoutDate
                      ? fullDateFormatFormatString(
                          compensationPayment?.payoutDate
                        )
                      : '-'}
                  </Form.Item>
                </Flex.Item>
              </Flex.Column>
            </Flex.Row>
            <Divider />
            <Flex.Row>
              <Flex.Item className={classes.fullWidth}>
                <Form.Item
                  className={classes.fullWidth}
                  label={t(
                    'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.notes'
                  )}
                >
                  <Input.TextArea
                    className={classes.notes}
                    disabled={isCompansationPaymentPaid}
                    defaultValue={updatedCompensationPayment?.notes}
                    onChange={(notes) => {
                      setUpdatedCompensationPayment({
                        ...updatedCompensationPayment,
                        notes: notes.target.value,
                      });
                    }}
                  >
                    {updatedCompensationPayment?.notes}
                  </Input.TextArea>
                </Form.Item>
              </Flex.Item>
            </Flex.Row>
          </Flex.Item>
          {isCompansationPaymentPaid && (
            <Flex.Row clasName={classes.fullWidth}>
              <Alert
                message={t(
                  'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.warningAlreadyPaid'
                )}
                type="warning"
              />
            </Flex.Row>
          )}
          <Flex.Row justifyContent="flex-end">
            <Button
              disabled={isCompansationPaymentPaid || !isEqual || isLoading}
              onClick={handleOnPayOut}
              style={{ marginRight: '8px' }}
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.payOut'
              )}
            </Button>
            <Button
              disabled={isCompansationPaymentPaid || isLoading}
              onClick={handleOnDelete}
              style={{ marginRight: 'auto' }}
              className={classes.danger}
              type="link"
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.delete'
              )}
            </Button>
            <Button
              disabled={false}
              onClick={handleOnCancel}
              type="link"
              style={{ marginRight: '8px' }}
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.cancel'
              )}
            </Button>
            <Button
              type="primary"
              disabled={isCompansationPaymentPaid || isEqual || isLoading}
              onClick={handleSave}
            >
              {t(
                'hr:timeAndLeaveManagement.compensationPaymentsTable.compensationPaymentDetailsDrawer.save'
              )}
            </Button>
          </Flex.Row>
        </Flex.Column>
      </Form>
    </Drawer>
  );
};

export default HREditCompensationPaymentDrawer;
