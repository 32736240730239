import classNames from 'classnames';
import { Select } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useQuery } from 'react-query';
import { ProjectId } from '../../../models/Types';
import { apiGetDriveItemTags } from '../api';

const useStyles = makePrioStyles((theme) => ({
  root: { width: '100%' },
  selectInput: {
    width: '100%',
  },
}));

interface DriveitemtagsPickerProps {
  className?: string;
  value?: any;
  projectId?: ProjectId;
  includeArchived?: boolean;
  onChange: (value: any) => void;
}

export const DriveitemtagsPicker = ({
  className,
  value,
  onChange,
  projectId,
  includeArchived,
}: DriveitemtagsPickerProps) => {
  //#region ------------------------------ Defaults

  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  const { data: documentTagsResponse, isLoading } = useQuery(
    ['documentTagsQuery', projectId, includeArchived],
    ({ queryKey }) => {
      const projectId = queryKey[1] as ProjectId;
      const includeArchived = queryKey[2] as boolean;
      return apiGetDriveItemTags(projectId, includeArchived);
    }
  );

  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Select
        loading={isLoading}
        value={value}
        onChange={onChange}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        notFoundContent={null}
        className={classes.selectInput}
        mode="multiple"
      >
        {documentTagsResponse?.data?.map((documentTag) => (
          <Select.Option
            value={documentTag.name}
            key={documentTag.documentTagId}
          >{`${documentTag.name}`}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default DriveitemtagsPicker;
