import React, {
  useState,
  Reducer,
  useReducer,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Table, Typography, Input, Tooltip } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';

import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { tableTranslations } from '../../../../util/table';
import {
  HolidayEntitlementId,
  HolidayEntitlement,
} from '../../../../models/Employee';
import NumberInput from '../../../../components/NumberInput';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  holidayTable: {
    overflow: 'hidden',
    flex: 1,
    '& .ant-table-row .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .internal-project-contacts-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-sticky-holder': {
      zIndex: 0,
    },
    '& .ant-table-sticky-scroll': {
      display: 'none',
    },
    '& .ant-spin-nested-loading': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-spin-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-body': {
      overflow: 'hidden',
      height: 'calc(100% - 56px)',
      overflowY: 'auto',
    },
  },
  infoIcon: {
    marginLeft: theme.old.spacing.unit(2),
  },
  actionButtonRow: {
    marginTop: theme.old.spacing.unit(2),
  },
  numberInput: {
    flex: 1,
  },
  title: {
    marginTop: theme.old.spacing.unit(1),
    marginBottom: theme.old.spacing.unit(3),
  },
}));

interface HolidayEntitlementsTableProps {
  entitlements: HolidayEntitlement[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    entitlements?: HolidayEntitlement[]
  ) => void;
  onNewEntitlement?: (holidayEntitlement: HolidayEntitlement) => void;
  updatedHolidayEntitlements: HolidayEntitlement[];
  onSaveUpdatedHolidayEntitlements: () => void;
  isSaving?: boolean;
}

interface TableEntry extends HolidayEntitlement {
  addEntitlementsRow: boolean;
  isTemporary: boolean;
}

interface NewEntitlement extends HolidayEntitlement {
  isValid: boolean;
}

interface UpdateNewEntitlementsAction {
  holidayEntitlement: number;
  remainingEntitlement?: number;
}

export const HolidayEntitlementsTable: React.FC<
  HolidayEntitlementsTableProps
> = (props) => {
  const classes = useStyles();
  const {
    entitlements: originalEntitlements,
    onIsDirtyChanged,
    onNewEntitlement,
    onSaveUpdatedHolidayEntitlements,
    isSaving,
    updatedHolidayEntitlements,
  } = props;
  const [entitlements, setEntitlements] =
    useState<HolidayEntitlement[]>(originalEntitlements);
  const { t } = useTranslation();
  const [newEntitlementRowActive, setNewEntitlementsRowActive] =
    useState(false);

  const tableBottomRef = useRef(null);
  const maxYear: number | null = useMemo(
    () =>
      entitlements?.length > 0
        ? entitlements.reduce(
            (max, entitlement) =>
              entitlement.year > max ? entitlement.year : max,
            1900
          )
        : null,
    [entitlements]
  );

  useEffect(() => {
    setEntitlements(originalEntitlements);
    if (onIsDirtyChanged) {
      onIsDirtyChanged(false, originalEntitlements);
    }
  }, [originalEntitlements, onIsDirtyChanged]);

  const validate: (state: NewEntitlement) => boolean = (
    state: NewEntitlement
  ) => !Number.isNaN(state.holidayEntitlement) && state.holidayEntitlement >= 0;

  const emptyNewEntitlement: () => NewEntitlement = () => ({
    employeeHolidayEntitlementId: 'new',
    year: maxYear ? maxYear + 1 : new Date().getFullYear(),
    holidayEntitlement: 24,
    isValid: true,
  });

  const [newEntitlement, updateNewEntitlement] = useReducer<
    Reducer<NewEntitlement, UpdateNewEntitlementsAction | 'clear'>
  >((state: NewEntitlement, action: UpdateNewEntitlementsAction | 'clear') => {
    if (action === 'clear') {
      return emptyNewEntitlement();
    }
    const updatedState = {
      ...state,
      holidayEntitlement: action.holidayEntitlement,
    };
    return {
      ...updatedState,
      isValid: validate(updatedState),
    };
  }, emptyNewEntitlement());

  const updateEntitlement = (
    employeeHolidayEntitlementId: HolidayEntitlementId,
    holidayEntitlement: number,
    remainingEntitlement?: number
  ) => {
    const updatedEntitlements = entitlements.map((entry) =>
      entry.employeeHolidayEntitlementId === employeeHolidayEntitlementId
        ? { ...entry, holidayEntitlement, remainingEntitlement }
        : entry
    );
    setEntitlements(updatedEntitlements);

    if (onIsDirtyChanged) {
      const isDirty = !equals(updatedEntitlements, originalEntitlements);
      onIsDirtyChanged(isDirty, updatedEntitlements);
    }
  };

  const saveNewEntitlement = () => {
    if (onNewEntitlement) {
      onNewEntitlement(newEntitlement);
      cancelNewEntitlementsRow();
      updateNewEntitlement('clear');
    }
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('hr:holidayEntitlementsTable.columnTitles.year'),
      dataIndex: 'year',
      sorter: (a, b) => a.year - b.year,
      render: (value, record: TableEntry, index: number) =>
        record.addEntitlementsRow ? (
          <Input value={value} disabled />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        ),
    },
    {
      title: t('hr:holidayEntitlementsTable.columnTitles.holidayEntitlement'),
      dataIndex: 'holidayEntitlement',
      render: (value, record) => (
        <Flex.Row>
          <NumberInput
            value={
              record.addEntitlementsRow
                ? newEntitlement.holidayEntitlement
                : value
            }
            onChange={(value) => {
              record.addEntitlementsRow
                ? updateNewEntitlement({
                    holidayEntitlement: value,
                    remainingEntitlement: record.remainingEntitlement,
                  })
                : updateEntitlement(
                    record.employeeHolidayEntitlementId,
                    value,
                    record.remainingEntitlement
                  );
            }}
            disabled={isSaving || record.year < new Date().getUTCFullYear() - 1}
          />
          {record.year < new Date().getUTCFullYear() - 1 && (
            <Tooltip
              title={
                record.year < new Date().getUTCFullYear() - 1
                  ? t('hr:holidayEntitlementsTable.tooltip.NotChangeable')
                  : ''
              }
            >
              <FontAwesomeIcon
                icon={['fal', 'info-circle']}
                className={classes.infoIcon}
              />
            </Tooltip>
          )}
        </Flex.Row>
      ),
    },
    {
      title: t('hr:holidayEntitlementsTable.columnTitles.remainingEntitlement'),
      dataIndex: 'remainingEntitlement',
      render: (value, record) => (
        <Flex.Row>
          <NumberInput
            value={
              record.addEntitlementsRow
                ? newEntitlement.remainingEntitlement
                : value
            }
            onChange={(value) => {
              if (!record.addEntitlementsRow) {
                updateEntitlement(
                  record.employeeHolidayEntitlementId,
                  record.holidayEntitlement,
                  value
                );
              }
            }}
            placeholder={
              (
                record.addEntitlementsRow
                  ? newEntitlement.year >= new Date().getUTCFullYear() - 1
                    ? t(
                        'hr:holidayEntitlementsTable.placeholder.remainingEntitlementCalculated'
                      )
                    : ''
                  : record.year >= new Date().getUTCFullYear() - 1
              )
                ? t(
                    'hr:holidayEntitlementsTable.placeholder.remainingEntitlementCalculated'
                  )
                : ''
            }
            disabled={
              isSaving ||
              (record.addEntitlementsRow
                ? newEntitlement.year >= new Date().getUTCFullYear() - 1
                : record.year >= new Date().getUTCFullYear() - 1 ||
                  record.year < new Date().getUTCFullYear() - 2)
            }
          />
          {record.addEntitlementsRow
            ? newEntitlement.year >= new Date().getUTCFullYear() - 1
            : record.year >= new Date().getUTCFullYear() - 1 ||
              (record.year < new Date().getUTCFullYear() - 2 && (
                <Tooltip
                  title={
                    record.year < new Date().getUTCFullYear() - 1
                      ? t('hr:holidayEntitlementsTable.tooltip.NotChangeable')
                      : ''
                  }
                >
                  <FontAwesomeIcon
                    icon={['fal', 'info-circle']}
                    className={classes.infoIcon}
                  />
                </Tooltip>
              ))}
        </Flex.Row>
      ),
    },
    {
      render: (_, record) =>
        record.addEntitlementsRow && (
          <>
            <Button
              ref={tableBottomRef}
              disabled={!newEntitlement.isValid || isSaving}
              onClick={saveNewEntitlement}
              iconProp={['fal', 'plus']}
            ></Button>
          </>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = useMemo(
    () =>
      entitlements
        .sort((a, b) => a.year - b.year)
        .map((entitlement: HolidayEntitlement) => ({
          ...entitlement,
          isTemporary: false, //config.isTemporary ?? false,
          addEntitlementsRow: false,
        })),
    [entitlements]
  );

  const enableNewEntitlementsRow = () => setNewEntitlementsRowActive(true);
  //Enable when personel file module is properly refactored
  // useEffect(() => {
  //   if (tableBottomRef?.current) {
  //     tableBottomRef?.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [newEntitlementRowActive, tableBottomRef]);

  const cancelNewEntitlementsRow = () => setNewEntitlementsRowActive(false);

  return (
    <>
      <div className={classes.root}>
        <Table
          rowKey={(entry) => entry.employeeHolidayEntitlementId}
          columns={columns}
          className={classes.holidayTable}
          pagination={false}
          dataSource={
            newEntitlementRowActive
              ? [
                  ...data,
                  {
                    year: maxYear ? maxYear + 1 : new Date().getFullYear(),
                    holidayEntitlement: 24,
                    addEntitlementsRow: true,
                    isTemporary: true,
                    employeeHolidayEntitlementId: 'new',
                  },
                ]
              : data
          }
          locale={tableTranslations(t)}
          sticky
        />

        <Flex.Row
          justifyContent="space-between"
          className={classes.actionButtonRow}
        >
          <Button
            type="link"
            onClick={enableNewEntitlementsRow}
            disabled={newEntitlementRowActive || isSaving}
            iconProp={['fal', 'plus']}
            style={{ marginRight: '8px' }}
          >
            {t('hr:holidayEntitlementsTable.actions.add')}
          </Button>

          <Button
            onClick={onSaveUpdatedHolidayEntitlements}
            disabled={!updatedHolidayEntitlements || isSaving}
          >
            {t('common:save')}
          </Button>
        </Flex.Row>
      </div>
    </>
  );
};

export default HolidayEntitlementsTable;
