import React from 'react';
import classNames from 'classnames';
import { Table } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { Invoice, InvoicePayment, Money } from '../../../models/Accounting';
import { TFunction } from 'i18next';
import CustomTag from '../../../components/CustomTag';
import { InvoiceStatus } from '../../../models/Types';
import { compactDateFormatString, formatMoney } from '../../../util';
import Flex from '../../../components/Flex';
import { MENU_BUTTON_SIZE } from '../../../constants';
import PrioSpinner from '../../../components/PrioSpinner';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getCompaniesByIdState,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import { tableTranslations } from '../../../util/table';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  lastDays: {
    marginTop: `${theme.old.spacing.unit(4)}px !important`,
  },
  row: {
    verticalAlign: 'top',
    cursor: 'pointer',
    '& > td': {
      lineHeight: '24px',
    },
    '& > td:nth-child(4) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(4) > button': {
      visibility: 'visible',
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  menuButton: {
    padding: '0!important',
    height: MENU_BUTTON_SIZE,
    width: MENU_BUTTON_SIZE,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
  },
  tableHeader: {
    color: theme.old.palette.primaryColor,
  },
  tag: {
    padding: `0 ${theme.old.spacing.baseSpacing}px`,
    height: 24,
  },
  tagText: {
    lineHeight: '24px',
  },
  stateCell: {
    height: 64,
    padding: '4px 0!important',
  },
  state: {
    height: '100%',
    width: 5,
  },
  stateGreen: {
    backgroundColor: theme.old.palette.chromaticPalette.green,
  },
  stateRed: {
    backgroundColor: theme.old.palette.chromaticPalette.red,
  },
  colorGreen: {
    color: theme.old.palette.chromaticPalette.green,
  },
  colorRed: {
    color: theme.old.palette.chromaticPalette.red,
  },
  wrap: {
    overflow: 'hidden',
  },
}));

interface InvoicesTableProps {
  className?: string;
  invoices?: Invoice[];
  onRowClick?: (entry: Invoice) => void;
  showPayments: boolean;
  loading?: boolean;
}

export const InvoicesTable: React.FC<InvoicesTableProps> = (props) => {
  const { className, invoices, onRowClick, showPayments, loading } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const companiesById = useSelector(getCompaniesByIdState);
  const projectsById = useSelector(getProjectByIdState);

  // currently not needed. Comment in for "Kostenstelle / Niederlassung"
  // const [officeById, setOfficeById] = useState<{ [officeId: string]: Office }>(
  //   {}
  // );
  // useEffect(() => {
  //   const controller = new AbortController();
  //   const signal = controller.signal;
  //   const loadOffices = async () => {
  //     try {
  //       const result = await fetch(`${apiUrl}/contact/Office`, {
  //         headers: { Authorization: `Bearer ${await getAccessToken()}` },
  //         signal,
  //       });
  //       const resultData = await result.json();
  //       if (resultData && Array.isArray(resultData)) {
  //         setOfficeById(
  //           (resultData as Office[]).reduce<{ [officeId: string]: Office }>(
  //             (map, office) => {
  //               map[office.officeId] = office;
  //               return map;
  //             },
  //             {}
  //           )
  //         );
  //       }
  //     } catch {}
  //   };
  //   loadOffices();
  //   return () => {
  //     controller.abort();
  //   };
  // }, []);

  const columns: ColumnProps<Invoice>[] = [
    {
      className: classes.stateCell,
      render: (_, record) => {
        let className = '';
        if (record.type === 'incomingInvoice') {
          className = classes.stateRed;
        } else {
          className = classes.stateGreen;
        }
        return <div className={classNames(className, classes.state)}></div>;
      },
      width: 5,
    },
    {
      title: headerItem('number', t, classes),
      dataIndex: 'number',
      sorter: (a, b) => a.number.localeCompare(b.number),
      width: 250,
    },
    {
      title: headerItem('recipientCompanyId', t, classes),
      dataIndex: 'recipientCompanyId',
      render: (value) => (
        <>
          <p
            className={classes.wrap}
            title={
              companiesById?.[value]
                ? companiesById?.[value].shortName +
                    ' - ' +
                    companiesById?.[value].fullName ??
                  companiesById?.[value].shortName
                : ''
            }
          >
            {companiesById?.[value]
              ? companiesById?.[value].shortName +
                  ' - ' +
                  companiesById?.[value].fullName ??
                companiesById?.[value].shortName
              : ''}
          </p>
        </>
      ),
      sorter: (a, b) =>
        companiesById?.[a.recipientCompanyId]?.fullName?.localeCompare(
          companiesById?.[b.recipientCompanyId]?.fullName
        ),

      width: 300,
    },
    {
      title: headerItem('grossSum', t, classes),
      dataIndex: 'grossSum',
      render: (value, record) =>
        value &&
        (showPayments ? (
          <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
            <div>{formatMoney(value)}</div>
            {record.invoicePayments.map(
              ({ invoicePaymentId, debitSum, paymentType: type }) => (
                <div
                  key={invoicePaymentId}
                  className={
                    type === 'payment' ? classes.colorGreen : classes.colorRed
                  }
                >
                  {formatMoney(debitSum)}
                </div>
              )
            )}
          </Flex.Column>
        ) : (
          formatMoney(value)
        )),
      sorter: (a, b) => a.grossSum.value - b.grossSum.value,
      width: 150,
    },
    {
      title: headerItem('remainingGross', t, classes),
      dataIndex: 'invoicePayments',
      render: (value, record) =>
        record.netSum !== null
          ? formatMoney(
              value !== null ? calcRemainingGross(record, value) : record.netSum
            )
          : null,
      sorter: (a, b) => {
        const remainingGrossA =
          a.netSum !== null
            ? a.invoicePayments !== null
              ? calcRemainingGross(a, a.invoicePayments).value
              : a.netSum.value
            : 0;
        const remainingGrossB =
          b.netSum !== null
            ? b.invoicePayments !== null
              ? calcRemainingGross(b, b.invoicePayments).value
              : b.netSum.value
            : 0;
        return remainingGrossA - remainingGrossB;
      },
      width: 150,
    },
    {
      title: headerItem('netSum', t, classes),
      dataIndex: 'netSum',
      render: (value) => (value ? formatMoney(value) : 0),
      sorter: (a, b) => a.netSum.value - b.netSum.value,
      width: 150,
    },
    {
      title: headerItem('remainingNet', t, classes),
      dataIndex: 'invoicePayments',
      render: (value, record) =>
        record.netSum !== null
          ? formatMoney(
              value !== null ? calcRemainingNet(record, value) : record.netSum
            )
          : null,
      sorter: (a, b) => {
        const remainingNetA =
          a.netSum !== null
            ? a.invoicePayments !== null
              ? calcRemainingNet(a, a.invoicePayments).value
              : a.netSum.value
            : 0;
        const remainingNetB =
          b.netSum !== null
            ? b.invoicePayments !== null
              ? calcRemainingNet(b, b.invoicePayments).value
              : b.netSum.value
            : 0;
        return remainingNetA - remainingNetB;
      },
      width: 150,
    },
    {
      title: headerItem('status', t, classes),
      dataIndex: 'status',
      render: (value, record) =>
        value &&
        (showPayments ? (
          <Flex.Column
            childrenGap={theme.old.spacing.unit(2)}
            alignItems="flex-start"
          >
            <CustomTag
              type="text"
              className={classes.tag}
              textClassName={classes.tagText}
              color={tagBackground(value, theme)}
              text={t(`accounting:table.tags.${value}`)}
            />
            {record.invoicePayments.map(
              ({ invoicePaymentId, paymentType: type }) => (
                <div
                  key={invoicePaymentId}
                  className={
                    type === 'payment' ? classes.colorGreen : classes.colorRed
                  }
                >
                  {t(`accounting:invoicePaymentType.${type}`)}
                </div>
              )
            )}
          </Flex.Column>
        ) : (
          <CustomTag
            type="text"
            className={classes.tag}
            textClassName={classes.tagText}
            color={tagBackground(value, theme)}
            text={t(`accounting:table.tags.${value}`)}
          />
        )),
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 150,
    },
    {
      title: headerItem('invoiceDate', t, classes),
      dataIndex: 'invoiceDate',
      render: (value, record) =>
        value &&
        (showPayments ? (
          <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
            <div>{compactDateFormatString(value)}</div>
            {record.invoicePayments.map(({ invoicePaymentId, date }) => (
              <div
                key={invoicePaymentId}
                className={
                  record.type === 'incomingInvoice'
                    ? classes.colorGreen
                    : classes.colorRed
                }
              >
                {compactDateFormatString(date)}
              </div>
            ))}
          </Flex.Column>
        ) : (
          compactDateFormatString(value)
        )),
      sorter: (a, b) =>
        moment(a.invoiceDate).unix() - moment(b.invoiceDate).unix(),
      defaultSortOrder: 'ascend',
      width: 150,
    },
    {
      title: headerItem('title', t, classes),
      dataIndex: 'title',
      width: 150,
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
    },
    {
      title: headerItem('projectId', t, classes),
      dataIndex: 'projectId',
      render: (value) =>
        projectsById?.[value]?.name ?? projectsById?.[value]?.shortName,
      sorter: (a, b) => {
        const projectA = projectsById?.[a.projectId];
        const projectB = projectsById?.[b.projectId];
        return (projectA?.name ?? projectA?.shortName ?? '').localeCompare(
          projectB?.name ?? projectB?.shortName ?? ''
        );
      },
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'type',
      render: () => (
        <Button type="primary" iconProp={['fal', 'plus']}>
          {t('accounting:table.payment')}
        </Button>
      ),
      width: 140,
    },
  ];

  return (
    <div className={classNames(classes.root, className)}>
      <Table<Invoice>
        className={classes.table}
        columns={columns}
        dataSource={invoices}
        rowKey={(row) => row.invoiceId}
        scroll={{ x: 1620, y: 500 }}
        rowClassName={classes.row}
        locale={tableTranslations(t)}
        onRow={(record: Invoice) => {
          return {
            onClick: onRowClick
              ? () => {
                  onRowClick(record);
                }
              : null,
          };
        }}
        loading={{
          spinning: loading,
          indicator: <PrioSpinner alignSelf />,
        }}
        pagination={false}
      />
    </div>
  );
};

const headerItem = (itemKey: string, t: TFunction, classes: Classes) => (
  <div className={classes.tableHeader}>{t(`accounting:table.${itemKey}`)}</div>
);

function tagBackground(status: InvoiceStatus, theme: PrioTheme) {
  if (status === 'paid') return theme.old.palette.chromaticPalette.green;
  if (status === 'partlyPaid') return theme.old.palette.primaryColor;
  return theme.old.palette.chromaticPalette.red;
}

const calcRemainingGross: (
  invoice: Invoice,
  invoicePayments: InvoicePayment[]
) => Money = (invoice, invoicePayments) => {
  return {
    value:
      invoice.grossSum.value -
      invoicePayments.reduce((sum, value) => sum + value.debitSum.value, 0),
    isoCode: invoice.grossSum.isoCode,
  };
};

const calcRemainingNet: (
  invoice: Invoice,
  invoicePayments: InvoicePayment[]
) => Money = (invoice, invoicePayments) => {
  const remainingGross = calcRemainingGross(invoice, invoicePayments);
  const x =
    invoice.grossSum.value !== 0
      ? invoice.netSum.value / invoice.grossSum.value
      : 1;
  return {
    value: remainingGross.value * x,
    isoCode: invoice.netSum.isoCode,
  };
};

export default InvoicesTable;
