import { FormInstance } from 'antd';
import { useState } from 'react';
import { CreateProjectDraftValidationDto } from '../../../models/Project';
import { debounceFunction } from '../../../util';
import { apiValidateProjectCreation } from '../api';

export const useProjectDraftValidate = (form: FormInstance<any>) => {
  const [validationResult, setValidationResult] =
    useState<CreateProjectDraftValidationDto>();
  const [projectToCreateIsValid, setProjectToCreateIsValid] =
    useState<boolean>(false);
  const [projectToCreateValidating, setProjectToCreateValidating] =
    useState<boolean>(false);

  const validateProjectCreationData = debounceFunction(async () => {
    var projectNumber = form.getFieldValue('number');
    var projectName = form.getFieldValue('name');

    setProjectToCreateValidating(true);
    const { result, data } = await apiValidateProjectCreation({
      name: projectName,
      number: projectNumber,
    });
    if (result.status >= 200 && result.status < 300) {
      if (data) {
        setValidationResult({
          projectName: data.projectName,
          projectNumber: data.projectNumber,
        });
        if (data.projectName.isValid && data.projectNumber.isValid) {
          setProjectToCreateIsValid(true);
        } else {
          setProjectToCreateIsValid(false);
        }
      }
    }

    setProjectToCreateValidating(false);
  }, 500);

  return {
    validationResult,
    validateProjectCreationData,
    projectToCreateIsValid,
    projectToCreateValidating,
  };
};
