import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Col, Drawer, Form, InputNumber, Row, Switch, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmployeeId, OfficeId } from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import {
  CreateCompensationPayment,
  MonthlyClose,
} from '../../../../models/TimeKeeping';
import { useForm } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import Flex from '../../../../components/Flex';
import { colon, rowGutter } from '../../../../util/forms';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import { apiCreateCompensationPayment } from '../../../timeKeeping/api';
import ContactPicker from '../../../contacts/components/ContactPicker';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    '& .ant-select-selection-item $overTime': {
      display: 'none',
    },
  },
  companyText: {
    fontSize: 14,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  overTime: {},
}));

interface EmployeeData {
  employeeId: EmployeeId;
  hours: number;
  hasBeenPaidOut: boolean;
}

interface CreateMultipleCompensationPayments {
  expectedPayoutDate: Moment;
  employeeData: EmployeeData[];
}

const defaultEmployeeData: EmployeeData = {
  employeeId: undefined,
  hours: 0,
  hasBeenPaidOut: false,
};

interface CreateCompensationPaymentsDrawerProps {
  className?: string;
  visible: boolean;
  officeId: OfficeId;
  monthlyCloses?: MonthlyClose[];
  onClose: (shouldReload?: boolean) => void;
}

export const CreateCompensationPaymentsDrawer: React.FC<
  CreateCompensationPaymentsDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    visible,
    monthlyCloses: _monthlyCloses,
    officeId,
    onClose,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const [form] = useForm<CreateMultipleCompensationPayments>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyCloses = useMemo(() => _monthlyCloses || [], [_monthlyCloses]);

  const initialValue: CreateMultipleCompensationPayments = useMemo(
    () => ({
      expectedPayoutDate: moment().endOf('month'),
      employeeData:
        monthlyCloses.length > 0
          ? [
              ...monthlyCloses.map(
                ({ employeeId, accumulatedOvertimeHours }) => {
                  return {
                    employeeId,
                    hours: accumulatedOvertimeHours,
                    hasBeenPaidOut: false,
                  };
                }
              ),
              defaultEmployeeData,
            ]
          : [defaultEmployeeData],
    }),
    [monthlyCloses]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClose = (shouldReload?: boolean) => {
    form.resetFields();
    form.setFieldsValue(initialValue);
    setDisabled(initialValue.employeeData.length <= 1);
    onClose(shouldReload);
  };

  const handleFinish = async (value: CreateMultipleCompensationPayments) => {
    setLoading(true);

    const promises = value.employeeData
      .filter(({ employeeId }) => employeeId)
      .map(async (employeeData) => {
        const compensationPayment: CreateCompensationPayment = {
          employeeId: employeeData.employeeId,
          ...(!employeeData.hasBeenPaidOut
            ? {
                expectedPayoutDate: value.expectedPayoutDate
                  .toISOString(true)
                  .split('T')[0],
              }
            : {}),
          hours: employeeData.hours,
          hasBeenPaidOut: employeeData.hasBeenPaidOut,
        };
        return await apiCreateCompensationPayment(
          compensationPayment,
          officeId
        );
      });

    await Promise.all(promises);

    handleOnClose(true);
    setLoading(false);
  };

  const handleOnCancel = () => {
    handleOnClose();
  };

  const handleOnValuesChange = (
    changedValues: any,
    allValues: CreateMultipleCompensationPayments
  ) => {
    if (changedValues.employeeData) {
      let data = allValues.employeeData;
      if (
        allValues.employeeData.filter(({ employeeId }) => !employeeId).length >
        1
      ) {
        data = data.filter(
          ({ employeeId }, index) =>
            employeeId || index === allValues.employeeData.length - 1
        );
        form.setFieldsValue({
          employeeData: data,
        });
      }
      setDisabled(data.length <= 1);
    }
  };

  const handleOnSelect: (value: EmployeeId) => void = (value) => {
    let employeeData: EmployeeData[] = form.getFieldValue('employeeData');
    const index = employeeData.findIndex((entry) => entry.employeeId === value);
    if (index > -1) {
      const { employeeId } = employeeData[index];
      const monthlyClose = monthlyCloses.find(
        ({ employeeId: eId }) => employeeId === eId
      );
      if (monthlyClose) {
        employeeData[index].hours = monthlyClose.overtimeHoursChange;
        form.setFieldsValue({
          employeeData,
        });
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValue);
    setDisabled(initialValue.employeeData.length <= 1);
  }, [form, initialValue]);
  //#endregion

  return (
    <Drawer
      className={classNames(classes.root, className)}
      visible={visible}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      width={theme.old.components.drawerWidth}
      onClose={() => handleOnClose()}
      closable
      destroyOnClose
    >
      <Typography.Title level={2}>
        {t('hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.title')}
      </Typography.Title>
      <Form<CreateMultipleCompensationPayments>
        className={classes.form}
        form={form}
        initialValues={initialValue}
        onFinish={handleFinish}
        layout="vertical"
        onValuesChange={handleOnValuesChange}
      >
        <Flex.Column flex={1} overflowY="auto" overflowX="hidden">
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="expectedPayoutDate"
                label={t(
                  'hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.expectedPayoutDate'
                )}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:timeAndLeaveManagement.monthlyCloseForm.warning.expectedPayoutDate'
                    ),
                  },
                ]}
              >
                <CustomSingleDatePicker
                  id="create_compensation_payments_id"
                  anchorDirection={'ANCHOR_RIGHT'}
                  small={true}
                  regular={false}
                  twoMonths={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                  showDefaultInputIcon={true}
                  inputIconPosition={'after'}
                  disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="employeeData">
            {(fields, { add }) => {
              return fields.map((field, index) => {
                return (
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={index === fields.length - 1 ? 24 : 12}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'employeeId']}
                        label={
                          index === 0 &&
                          t(
                            'hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.employeeId'
                          )
                        }
                        rules={
                          index < fields.length - 1 && [
                            {
                              required: true,
                            },
                          ]
                        }
                      >
                        <ContactPicker
                          disabled={
                            index < fields.length - 1 && (disabled || loading)
                          }
                          officeId={officeId}
                          contactType="InternalContact"
                          label={
                            index === fields.length - 1 &&
                            `${t('common:add')}...`
                          }
                          onSelect={(value) => {
                            if (index === fields.length - 1) {
                              add(defaultEmployeeData);
                            }
                            handleOnSelect(value);
                          }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    {index < fields.length - 1 && (
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'hours']}
                          label={
                            index === 0 &&
                            t(
                              'hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.hours'
                            )
                          }
                          rules={
                            index < fields.length - 1 && [
                              () => ({
                                async validator(rule, value: number) {
                                  if (value > 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject();
                                },
                              }),
                            ]
                          }
                        >
                          <InputNumber
                            min={0.25}
                            step={0.25}
                            precision={2}
                            formatter={(value) => `${value}`.replace('.', ',')}
                            parser={(value) =>
                              parseFloat(value.replace(',', '.')) as 0.25
                            }
                            disabled={disabled || loading}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {index < fields.length - 1 && (
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'hasBeenPaidOut']}
                          label={
                            index === 0 &&
                            t(
                              'hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.hasBeenPaidOut'
                            )
                          }
                          valuePropName="checked"
                        >
                          <Switch disabled={disabled || loading} />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                );
              });
            }}
          </Form.List>
        </Flex.Column>
        <Flex.Row justifyContent="flex-end">
          <Button
            onClick={handleOnCancel}
            disabled={loading}
            type="default"
            style={{ marginRight: '8px' }}
          >
            {t(
              'hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.cancel'
            )}
          </Button>
          <Button
            htmlType="submit"
            disabled={disabled || loading}
            loading={loading}
          >
            {t(
              'hr:timeAndLeaveManagement.createCompensationPaymentsDrawer.payOut'
            )}
          </Button>
        </Flex.Row>
      </Form>
    </Drawer>
  );
};

export default CreateCompensationPaymentsDrawer;
