import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { ThemeProvider } from 'react-jss';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getDocumentSettings,
  getMailSettings,
  RootReducerState,
} from '../../apps/main/rootReducer';
import { MailSelectionListSpacing } from '../../models/Types';
import { MailSettings } from '../../models/UserSettings/MailSettings';
import { PrioTheme } from '../types';
import {
  PRIO_DEFAULT_BORDERS as PRIO_DEFAULT_BORDERS_OLD,
  PRIO_DEFAULT_COMPONENTS as PRIO_DEFAULT_COMPONENTS_OLD,
  PRIO_DEFAULT_PALETTE as PRIO_DEFAULT_PALETTE_OLD,
  PRIO_DEFAULT_SPACING as PRIO_DEFAULT_SPACING_OLD,
  PRIO_DEFAULT_TYPOGRAPHY as PRIO_DEFAULT_TYPOGRAPHY_OLD,
} from '../types/default';
import { DocumentSettings } from '../../models/UserSettings/DocumentSettings';
import Theme from '@prio365/prio365-react-library/lib/ThemeProvider/defaults';
import { ConfigProvider } from 'antd';

const mailListSpacingSelector = createSelector<
  [(state: RootReducerState) => MailSettings],
  MailSelectionListSpacing
>(getMailSettings, (settings) => settings.mailListSpacing);

const documentsListSpacingSelector = createSelector<
  [(state: RootReducerState) => DocumentSettings],
  MailSelectionListSpacing
>(getDocumentSettings, (settings) => settings.documentListSpacing);

interface PrioThemeProviderProps {
  children: ReactNode;
}

export const PrioThemeProvider: React.FC<PrioThemeProviderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const mailListSpacing = useSelector(mailListSpacingSelector);
  const documentListSpacing = useSelector(documentsListSpacingSelector);

  const documentSpacingDimensions = useCallback(
    (full: number, middle: number, tight: number) => {
      switch (documentListSpacing) {
        case 'full':
          return full;
        case 'middle':
          return middle;
        case 'tight':
          return tight;
        default:
          return middle;
      }
    },
    [documentListSpacing]
  );

  const theme: PrioTheme = useMemo(() => {
    return {
      ...Theme,
      old: {
        spacing: PRIO_DEFAULT_SPACING_OLD,
        palette: PRIO_DEFAULT_PALETTE_OLD,
        borders: PRIO_DEFAULT_BORDERS_OLD,
        typography: PRIO_DEFAULT_TYPOGRAPHY_OLD,
        components: {
          ...PRIO_DEFAULT_COMPONENTS_OLD,
          mailMenuMaxWidth:
            mailListSpacing === 'full'
              ? 320
              : mailListSpacing === 'middle'
              ? 320
              : 280,
          mailFolderItem: {
            spacing:
              mailListSpacing === 'full'
                ? 8
                : mailListSpacing === 'middle'
                ? 6
                : 4,
            fontSize:
              mailListSpacing === 'full'
                ? 15
                : mailListSpacing === 'middle'
                ? 13
                : 13,
            lineHeight:
              mailListSpacing === 'full'
                ? 1.5715
                : mailListSpacing === 'middle'
                ? 1.5715
                : 1.4,
            itemHeight:
              mailListSpacing === 'full'
                ? 40
                : mailListSpacing === 'middle'
                ? 32
                : 24,
          },
          mailListItem: {
            spacing:
              mailListSpacing === 'full'
                ? 8
                : mailListSpacing === 'middle'
                ? 6
                : 4,
            fontSize:
              mailListSpacing === 'full'
                ? 15
                : mailListSpacing === 'middle'
                ? 13
                : 12,
            lineHeight:
              mailListSpacing === 'full'
                ? 1.5715
                : mailListSpacing === 'middle'
                ? 1.5715
                : 1.4,
            flaggedMessageBackground: '#fffdd9',
          },
          documentsTable: {
            tableColumnRowHeight: documentSpacingDimensions(50, 40, 30),
            tableRowHeight: documentSpacingDimensions(37, 33, 27),
            font: {
              fontSize: documentSpacingDimensions(15, 13, 13),
              headerFontSize: documentSpacingDimensions(17, 15, 15),
              lineHeight: documentSpacingDimensions(1.5715, 1.5715, 1.4),
            },
            spacing: documentSpacingDimensions(8, 6, 4),
            padding: documentSpacingDimensions(24, 18, 12),
            documentsMenu: {
              width: documentSpacingDimensions(320, 320, 280),
            },
          },
        },
      },
    };
  }, [documentSpacingDimensions, mailListSpacing]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const {
      primaryColor,
      backgroundPalette: { hover, active, alpha: _, ...backgroundPalette },
    } = PRIO_DEFAULT_PALETTE_OLD;
    const { colors: borderColors } = PRIO_DEFAULT_BORDERS_OLD;
    ConfigProvider.config({
      theme: {
        primaryColor,
      },
    });

    const keyMap = {
      ...Object.keys(backgroundPalette).reduce(
        (map, key) => ({
          ...map,
          [`--prio_background_${key}`]: backgroundPalette[key],
        }),
        {}
      ),
      ...Object.keys(hover).reduce(
        (map, key) => ({
          ...map,
          [`--prio_background_hover_${key}`]: hover[key],
        }),
        {}
      ),
      ...Object.keys(active).reduce(
        (map, key) => ({
          ...map,
          [`--prio_background_active_${key}`]: active[key],
        }),
        {}
      ),
      ...Object.keys(borderColors).reduce(
        (map, key) => ({
          ...map,
          [`--prio_borders_colors_${key}`]: borderColors[key],
        }),
        {}
      ),
    };

    Object.keys(keyMap).forEach((key) => {
      document.documentElement.style.setProperty(key, keyMap[key]);
    });
  }, []);
  //#endregion

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default PrioThemeProvider;
