import { ApiResult, apiUrl } from '../../../api';
import { CustomerCourseParticipationReadDto } from '../../../models/Training';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchReteachParticipation: () => Promise<
  ApiResult<CustomerCourseParticipationReadDto[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/training/reteach`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
