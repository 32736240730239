import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Tabs, Typography } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import { OfficeId } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HRMonthlyCloseDetailsDrawer from './HRMonthlyCloseDetailsDrawer';
import MonthlyCloseTimekeepingDaysTable from '../../../timeKeeping/components/MonthlyCloseTimekeepingDaysTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
} from '../../../../apps/main/rootReducer';
import { Contact } from '../../../../models/Contact';
import moment from 'moment';
import { debouncedFetchOfficeHolidaysMe } from '../../../absences/actions';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-top': {
      height: '100%',
    },
  },
}));

interface HRMonthlyCloseDrawerProps {
  monthlyClose?: MonthlyClose;
  open?: boolean;
  setOpen: (open: boolean) => void;
  setSelectedMonthlyClose: (value: MonthlyClose) => void;
  officeId?: OfficeId;
  updateMonthlyClose?: (monthlyClose: MonthlyClose) => void;
  onFinishDetailsTab?: (monthlyClose: MonthlyClose) => void;
  reloadMonthlyCloses: () => void;
}

export const HRMonthlyCloseDrawer: React.FC<HRMonthlyCloseDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    monthlyClose,
    open,
    setOpen,
    officeId,
    onFinishDetailsTab,
    setSelectedMonthlyClose,
    reloadMonthlyCloses,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, monthlyClose?.employeeId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (contact?.officeId && monthlyClose) {
      const start = moment(monthlyClose.month)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = moment(monthlyClose.month)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
    }
  }, [contact, monthlyClose, dispatch]);
  //#endregion

  return (
    <Drawer
      width={theme.old.components.drawerWidth}
      closable={true}
      onClose={() => setOpen(false)}
      visible={open}
      destroyOnClose={true}
      className={classes.root}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
    >
      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <Typography.Title level={2} ellipsis>
          {`${moment(monthlyClose?.month).format('MMMM YYYY')}${
            contact
              ? `, ${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`
              : ''
          }`}
        </Typography.Title>
      </Flex.Column>
      <Tabs>
        <TabPane
          tab={t(
            'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.tabName'
          )}
          key="details"
        >
          <HRMonthlyCloseDetailsDrawer
            setOpen={setOpen}
            monthlyClose={monthlyClose}
            setSelectedMonthlyClose={setSelectedMonthlyClose}
            officeId={officeId}
            onFinish={onFinishDetailsTab}
            reloadMonthlyCloses={reloadMonthlyCloses}
          />
        </TabPane>
        {/* <TabPane tab={'Überblick'} key="timeKeepingDaysOverview"></TabPane> */}
        <TabPane
          tab={t(
            'hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.tabName'
          )}
          key="timeKeepingDaysTable"
        >
          <MonthlyCloseTimekeepingDaysTable monthlyClose={monthlyClose} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default HRMonthlyCloseDrawer;
