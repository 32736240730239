import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';

import countries from 'i18n-iso-countries';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import Flex from '../../../components/Flex';
import { Employee } from '../../../models/Employee';
import { Gender, EmployeeId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import i18n from '../../../i18n';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    borderRadius: '2px',
    paddingTop: theme.spacing.unit(3),
    paddingLeft: theme.spacing.unit(3),
    marginTop: theme.spacing.unit(1),
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  coreDataBoxLeft: {
    borderRight: theme.old.borders.content,
    paddingRight: theme.old.spacing.unit(3),
  },
  fullWidth: {
    width: '100%',
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface CoreDataFormModel {
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  federalStateCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: Moment;
  allowShareBirthday?: boolean;
  birthplace?: string;
}

const coreDataFromEmployee: (employee: Employee) => CoreDataFormModel = (
  employee
) => ({
  phone: employee?.phone,
  eMail: employee?.eMail,
  street: employee?.street,
  zip: employee?.zip,
  city: employee?.city,
  countryCode: employee?.countryCode,
  federalStateCode: employee?.federalStateCode,
  gender: employee?.gender,
  nationality: employee?.nationality,
  birthday: employee?.birthday ? moment(employee?.birthday) : null,
  allowShareBirthday: employee?.allowShareBirthday,
  birthplace: employee?.birthplace,
});

interface ProfilePersonnelCoreDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  disabled: boolean;
  openEditPersonnelDataDrawer: () => void;
}

export const ProfilePersonnelCoreDataForm: React.FC<
  ProfilePersonnelCoreDataFormProps
> = (props) => {
  const { employee /*, openEditPersonnelDataDrawer*/ } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [coreDataForm] = Form.useForm<CoreDataFormModel>();
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    if (employee) {
      coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    }
  }, [employee, coreDataForm]);

  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Typography.Title>
        {t('profile:profileNavigation.personnelData')}
      </Typography.Title>

      <Flex.Column
        flex={1}
        childrenGap={theme.spacing.unit(2)}
        paddingBottom={theme.spacing.unit(3)}
      >
        <Flex.Column>
          <Typography.Text type="secondary">
            {t('hr:personnelFile.form.labels.address')}
          </Typography.Text>
          <Flex.Item>
            {`${
              !coreDataFromEmployee(employee).street
                ? ' - '
                : coreDataFromEmployee(employee).street
            },

                ${
                  !coreDataFromEmployee(employee).zip
                    ? ' - '
                    : coreDataFromEmployee(employee).zip
                }, 

                  ${
                    !coreDataFromEmployee(employee).city
                      ? ' - '
                      : coreDataFromEmployee(employee).city
                  },

                    ${
                      !coreDataFromEmployee(employee).countryCode
                        ? ' - '
                        : countries.getName(
                            coreDataFromEmployee(employee).countryCode,
                            i18n.language.substring(0, 2)
                          )
                    }`}
          </Flex.Item>
        </Flex.Column>

        <Flex.Column>
          <Typography.Text type="secondary">
            {t('hr:personnelFile.form.labels.contactInformation')}
          </Typography.Text>
          <Flex.Item>
            {`${
              !coreDataFromEmployee(employee).eMail
                ? ' - '
                : coreDataFromEmployee(employee).eMail
            },

                ${
                  !coreDataFromEmployee(employee).phone
                    ? ' - '
                    : coreDataFromEmployee(employee).phone
                }`}
          </Flex.Item>
        </Flex.Column>

        <Flex.Column>
          <Typography.Text type="secondary">
            {t('hr:personnelFile.form.labels.gender') +
              ', ' +
              t('hr:personnelFile.form.labels.nationality')}
          </Typography.Text>
          <Flex.Item>
            {`${
              coreDataFromEmployee(employee).gender
                ? t(
                    `contacts:form.gender.${
                      coreDataFromEmployee(employee).gender
                    }`
                  )
                : ' - '
            },

                ${
                  !coreDataFromEmployee(employee).nationality
                    ? ' - '
                    : countries.getName(
                        coreDataFromEmployee(employee).nationality,
                        i18n.language.substring(0, 2)
                      )
                }`}
          </Flex.Item>
        </Flex.Column>

        <Flex.Column>
          <Typography.Text type="secondary">
            {t('hr:personnelFile.form.labels.birthday') +
              ', ' +
              t('hr:personnelFile.form.labels.birthplace') +
              ', ' +
              t('hr:personnelFile.form.labels.allowShareBirthday')}
          </Typography.Text>
          <Flex.Item>
            {`${
              !coreDataFromEmployee(employee).birthday
                ? ' - '
                : coreDataFromEmployee(employee)
                    .birthday.locale('de')
                    .format('L')
            },

                ${
                  !coreDataFromEmployee(employee).birthplace
                    ? ' - '
                    : coreDataFromEmployee(employee).birthplace
                }, 

                ${
                  !coreDataFromEmployee(employee).allowShareBirthday
                    ? ' Geburtstag wird nicht geteilt '
                    : ' Geburtstag wird geteilt '
                }`}
          </Flex.Item>
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};

export default ProfilePersonnelCoreDataForm;
