import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  allowedFileTypesForPdfConversion,
  DriveItem,
} from '../../../models/Drive';
import {
  checkIfOneNote,
  getDriveItemType,
  getPDFType,
  isDriveItemFolder,
} from '../util';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';

declare const Placements: [
  'topLeft',
  'topCenter',
  'topRight',
  'bottomLeft',
  'bottomCenter',
  'bottomRight',
];
declare type Placement = (typeof Placements)[number];

interface DocumentsTableContextMenuProps {
  className?: string;
  iconClassname?: string;
  driveItem: DriveItem;
  isRoot?: boolean;
  parentIsRoot: boolean;
  onMenuRename?: (driveItem: DriveItem) => void;
  onMenuDelete?: (driveItem: DriveItem) => void;
  onMenuDownload?: (driveItem: DriveItem) => void;
  onMenuCopy?: (driveItem: DriveItem) => void;
  onMenuMove?: (driveItem: DriveItem) => void;
  openLocally?: (driveItem: DriveItem, openPdf: boolean) => Promise<void>;
  openInBrowser?: (driveItem: DriveItem) => Promise<void>;
  openParentFolder?: (driveItem: DriveItem) => void;
  onCopyAsPdf?: (driveItem: DriveItem) => void;
  onUnzipArchive?: (driveItem: DriveItem) => void;
  isSearchingDocumentsInWidgetBar?: boolean;
  isKeywordSearch?: boolean;
}
const useStyles = makePrioStyles((theme: PrioTheme) => ({
  button: {
    height: '100%',
    backgroundColor: 'transparent',
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
}));

export const DocumentsTableContextMenu: React.FC<
  DocumentsTableContextMenuProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    driveItem,
    isRoot,
    parentIsRoot,
    onMenuRename,
    onMenuCopy,
    onMenuDelete,
    onMenuDownload,
    onMenuMove,
    openLocally,
    openInBrowser,
    openParentFolder,
    onCopyAsPdf,
    onUnzipArchive,
    isSearchingDocumentsInWidgetBar,
    isKeywordSearch,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles(props);
  const ref = useRef<HTMLButtonElement>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const type = getDriveItemType(driveItem);
  const pdfType = getPDFType(driveItem);

  const [placement, setPlacement] = useState<Placement>('bottomRight');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const convertToPdfIsDisabled = (driveItem: DriveItem) => {
    const splittedFilename = driveItem.name.split('.');
    const fileExtension = splittedFilename[splittedFilename.length - 1];
    if (
      allowedFileTypesForPdfConversion.some(
        (allowedFileExtension) => allowedFileExtension === fileExtension
      )
    )
      return false;
    return true;
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (ref?.current) {
      if (
        ref?.current.getBoundingClientRect().y >
        document.body.offsetHeight - 300
      ) {
        setPlacement('topRight');
      }
    }
  }, [ref]);
  //#endregion

  return (
    <Dropdown
      className={className}
      trigger={['click']}
      placement={placement}
      overlay={
        <Menu id={'prio-project-documents-table-row-menu'}>
          <Menu.Item
            id="download"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              onMenuDownload(driveItem);
            }}
            disabled={isDriveItemFolder(driveItem) || checkIfOneNote(driveItem)}
          >
            {t('documents:table.actions.download')}
          </Menu.Item>

          {(isSearchingDocumentsInWidgetBar || isKeywordSearch) && (
            <Menu.Item
              id="openParentFolder"
              onClick={(e) => {
                e.domEvent.stopPropagation();
                openParentFolder(driveItem);
              }}
              disabled={
                !openParentFolder ||
                (!isSearchingDocumentsInWidgetBar && parentIsRoot)
              }
            >
              {t('documents:table.actions.openParentFolder')}
            </Menu.Item>
          )}

          <Menu.Item
            id="delete"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              onMenuDelete(driveItem);
            }}
            disabled={parentIsRoot}
          >
            {t('documents:table.actions.delete')}
          </Menu.Item>

          <Menu.Item
            id="rename"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              onMenuRename(driveItem);
            }}
          >
            {t('documents:table.actions.rename')}
          </Menu.Item>

          <Menu.Item
            id="move"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              onMenuMove(driveItem);
            }}
          >
            {t('documents:table.actions.move')}
          </Menu.Item>

          <Menu.Item
            id="copy"
            onClick={(e) => {
              e.domEvent.stopPropagation();
              onMenuCopy(driveItem);
            }}
          >
            {t('documents:table.actions.copy')}
          </Menu.Item>

          {type !== 'zip' && (
            <Menu.Item
              id="openInBrowser"
              onClick={(e) => {
                e.domEvent.stopPropagation();
                openInBrowser(driveItem);
              }}
              disabled={!openInBrowser}
            >
              {t('documents:table.actions.openInBrowser')}
            </Menu.Item>
          )}

          {type !== 'zip' && !isDriveItemFolder(driveItem) && (
            <Menu.Item
              id="saveAsPdf"
              onClick={(e) => {
                e.domEvent.stopPropagation();
                onCopyAsPdf(driveItem);
              }}
              disabled={convertToPdfIsDisabled(driveItem) && isRoot}
            >
              {t('documents:table.actions.saveAsPdf')}
            </Menu.Item>
          )}
          {type === 'zip' && (
            <Menu.Item
              id="unzipArchive"
              onClick={(e) => {
                e.domEvent.stopPropagation();
                onUnzipArchive(driveItem);
              }}
              disabled={!onUnzipArchive}
            >
              {t('documents:table.actions.unzipArchive')}
            </Menu.Item>
          )}
          {(type || pdfType) &&
            !isDriveItemFolder(driveItem) &&
            type !== 'zip' && (
              <Menu.Item
                id="openLocally"
                onClick={(e) => {
                  e.domEvent.stopPropagation();
                  openLocally(driveItem, true);
                }}
                disabled={!openLocally}
              >
                {t('documents:table.actions.openLocally')}
              </Menu.Item>
            )}
        </Menu>
      }
    >
      <Button
        iconProp={['fal', 'ellipsis-v']}
        className={classes.button}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={ref}
        type="link"
      />
    </Dropdown>
  );
};

export default DocumentsTableContextMenu;
