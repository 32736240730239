import { PRIO } from '../../../constants';
import { DriveItem } from '../../../models/Drive';
import { DriveItemId, GroupId, ProjectId } from '../../../models/Types';
export const WS_DOCUMENT_CREATED = PRIO + 'WS_DOCUMENT_CREATED';

export const wsDocumentCreated = (
  driveItem: DriveItem,
  projectId: ProjectId,
  driveItemId: DriveItemId,
  groupId: GroupId
) => ({
  type: WS_DOCUMENT_CREATED,
  payload: driveItem,
  meta: { projectId, driveItemId, groupId },
});

export const WS_FETCH_DOCUMENT_COPIED_OR_MOVED =
  PRIO + 'WS_FETCH_DOCUMENT_COPIED_OR_MOVED';

export const wsFetchDocumentCopiedOrMoved = (
  driveItem: DriveItem,
  destinationDriveItemId: DriveItemId,
  destinationGroupId: GroupId,
  sourceDriveItemId: DriveItemId,
  sourceGroupId: GroupId,
  temporarySourceDriveItemId?: DriveItemId
) => ({
  type: WS_FETCH_DOCUMENT_COPIED_OR_MOVED,
  payload: driveItem,
  meta: {
    destinationDriveItemId,
    destinationGroupId,
    sourceDriveItemId,
    sourceGroupId,
    temporarySourceDriveItemId,
  },
});

export const WS_FETCH_ARCHIVE_UNZIPPED = PRIO + 'WS_FETCH_ARCHIVE_UNZIPPED';

export const wsArchiveUnzipped = (
  driveItem: DriveItem,
  projectId: ProjectId,
  driveItemId: DriveItemId,
  groupId: GroupId
) => ({
  type: WS_FETCH_ARCHIVE_UNZIPPED,
  payload: driveItem,
  meta: { driveItem, projectId, driveItemId, groupId },
});
