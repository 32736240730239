import React, { useEffect, useMemo, useState } from 'react';
import { FullAccountingOverview, Invoice } from '../../../models/Accounting';
import classNames from 'classnames';
import { apiFetchInvoicesOverview } from '../api';
import { OfficeId, ProjectId } from '../../../models/Types';
import Flex from '../../../components/Flex';
import AccountingOverviewTable from './AccountingOverviewTable';
import PrioSpinner from '../../../components/PrioSpinner';
import moment from 'moment';
import { Divider, Typography } from 'antd';
import { getCompaniesByIdState } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const EMPTY_OVERVIEW: FullAccountingOverview = {
  total: {
    projectOrCompanyId: null,
    invoices: [],
    outgoingInvoiceSummary: null,
    paymentSummary: null,
    refundSummary: null,
  },
  projectBreakDown: [],
  projectMonthBreakDown: {},
  companyBreakDown: [],
  companyMonthBreakDown: {},
};

interface AccountingOverviewPageProps {
  className?: string;
  invoices: Invoice[];
  projectId?: ProjectId;
  officeId?: OfficeId;
}

export const AccountingOverviewPage: React.FC<AccountingOverviewPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, projectId, officeId } = props;
  const classes = useStyles();
  const { invoices } = props;

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [invoiceOverviews, setInvoiceOverviews] =
    useState<FullAccountingOverview>(EMPTY_OVERVIEW);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const companiesById = useSelector(getCompaniesByIdState);

  const context = useMemo(() => {
    if (officeId) {
      return 'office';
    }
    if (projectId) {
      return 'project';
    }
    return 'global';
  }, [projectId, officeId]);

  const monthBreakdownPropValue = useMemo(() => {
    if (context === 'project') {
      return 'projectMonthBreakDown';
    }
    return 'companyMonthBreakDown';
  }, [context]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchOverview = async () => {
      // get all invoiceIds from invoices
      const invoiceIds = invoices
        .filter((invoice) => invoice.type === 'outgoingInvoice')
        .map((invoice) => invoice.invoiceId);
      setIsFetching(true);
      const { data } = await apiFetchInvoicesOverview(
        { invoices: invoiceIds },
        officeId,
        projectId
      );
      setInvoiceOverviews(data);
      setIsFetching(false);
    };

    fetchOverview();
    // api call get invoice overview
  }, [invoices, officeId, projectId]);
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      childrenGap={theme.old.spacing.baseSpacing}
    >
      {isFetching && !invoiceOverviews?.total && (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <PrioSpinner alignSelf />
        </div>
      )}
      {invoiceOverviews?.total && (
        <AccountingOverviewTable
          accountingOverview={invoiceOverviews?.total}
          month={moment().toISOString()}
          isTotal
        />
      )}

      {Object.keys(invoiceOverviews?.[monthBreakdownPropValue] ?? {})?.map(
        (id) => (
          <Flex.Column gap={theme.old.spacing.baseSpacing}>
            <Divider
              style={{
                marginTop: theme.old.spacing.baseSpacing,
                marginBottom: 0,
              }}
            />
            {context === 'global' && (
              <Typography.Title level={3} style={{ fontSize: 15, margin: 0 }}>
                {companiesById[id]?.fullName}
              </Typography.Title>
            )}
            <Flex.Row gap={theme.old.spacing.baseSpacing} flexWrap="wrap">
              {Object.keys(invoiceOverviews?.[monthBreakdownPropValue]?.[id])
                ?.sort((aMonth, bMonth) => {
                  const a = moment(aMonth);
                  const b = moment(bMonth);
                  return a.isBefore(b) ? -1 : 1;
                })
                ?.map((month, index) => (
                  <Flex.Item flex={1}>
                    <AccountingOverviewTable
                      key={index}
                      accountingOverview={
                        invoiceOverviews?.[monthBreakdownPropValue]?.[id]?.[
                          month
                        ]
                      }
                      month={month}
                    />
                  </Flex.Item>
                ))}
            </Flex.Row>
          </Flex.Column>
        )
      )}
    </Flex.Column>
  );
};

export default AccountingOverviewPage;
