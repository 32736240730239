import { ApiResult, apiUrl } from '../../../api';
import { Department } from '../../../models/Department';
import { Employee, HolidayEntitlement } from '../../../models/Employee';
import { Qualification } from '../../../models/Qualification';
import { MonthlyClose, MonthlyCloseUpdate } from '../../../models/TimeKeeping';
import {
  CreateTrainingRequest,
  Training,
  TrainingAudience,
  TrainingOverview,
  TrainingRequest,
} from '../../../models/Training';
import {
  ContactId,
  EmployeeId,
  MonthlyCloseId,
  OfficeId,
  TrainingId,
  TrainingRequestId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchAudiences: (
  signal?: AbortSignal
) => Promise<ApiResult<TrainingAudience[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/training/Audience`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateAudience: (
  name: string
) => Promise<ApiResult<TrainingAudience>> = async (name) => {
  const result = await fetchWithRetry(`${apiUrl}/training/Audience`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ name }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateTraining: (
  training: CreateTrainingRequest
) => Promise<ApiResult<Training>> = async (training) => {
  const result = await fetchWithRetry(`${apiUrl}/training/Training`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(training),
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchDepartments: (
  signal?: AbortSignal
) => Promise<ApiResult<Department[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/employee/Department`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateDepartment: (
  name: string
) => Promise<ApiResult<Department>> = async (name) => {
  const result = await fetchWithRetry(`${apiUrl}/employee/Department`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ name }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTrainingRequests: (
  trainingId: TrainingId,
  signal?: AbortSignal
) => Promise<ApiResult<TrainingRequest[]>> = async (trainingId, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/${trainingId}/request`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTrainings: (
  signal?: AbortSignal
) => Promise<ApiResult<Training[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/training/Training`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTrainingMe: (
  signal?: AbortSignal
) => Promise<ApiResult<TrainingOverview[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/training/Training/me`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchQualifications: (
  signal?: AbortSignal
) => Promise<ApiResult<Qualification[]>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/employee/Qualification`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateQualification: (
  name: string
) => Promise<ApiResult<Qualification>> = async (name) => {
  const result = await fetchWithRetry(`${apiUrl}/employee/Qualification`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ name }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateTrainingRequests: (
  trainingId: TrainingId,
  participantIds: ContactId[]
) => Promise<ApiResult<TrainingRequest[]>> = async (
  trainingId,
  participantIds
) => {
  const result = await fetchWithRetry(`${apiUrl}/training/Request`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      trainingId,
      participantIds,
    }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateTraining: (
  training: Training
) => Promise<ApiResult<Training>> = async (training) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/Training/${training.trainingId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(training),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeclineTrainingRequest: (
  trainingRequestId: TrainingRequestId
) => Promise<ApiResult<void>> = async (trainingRequestId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/Request/${trainingRequestId}/decline`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiAcceptTrainingRequest: (
  trainingRequestId: TrainingRequestId
) => Promise<ApiResult<void>> = async (trainingRequestId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/Request/${trainingRequestId}/accept`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiActivateTraining: (
  trainingId: TrainingId
) => Promise<ApiResult<void>> = async (trainingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/Training/${trainingId}/activate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiDeactivateTraining: (
  trainingId: TrainingId
) => Promise<ApiResult<void>> = async (trainingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/Training/${trainingId}/deactivate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiArchiveTraining: (
  trainingId: TrainingId
) => Promise<ApiResult<void>> = async (trainingId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/Training/${trainingId}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiFetchEmployee: (
  employeeId: EmployeeId,
  signal?: AbortSignal,
  officeId?: OfficeId
) => Promise<ApiResult<Employee>> = async (employeeId, signal, officeId) => {
  let result: Response = null;
  if (officeId !== undefined && officeId !== '') {
    result = await fetchWithRetry(
      `${apiUrl}/employee/office/${officeId}/Employee/${employeeId}`,
      {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
        signal,
      }
    );
  } else {
    result = await fetchWithRetry(`${apiUrl}/employee/Employee/${employeeId}`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    });
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMonthlyCloses: (
  officeId: OfficeId,
  from: string,
  to: string,
  signal?: AbortSignal
) => Promise<ApiResult<MonthlyClose[]>> = async (
  officeId,
  from,
  to,
  signal
) => {
  let result: Response = null;
  result = await fetchWithRetry(
    `${apiUrl}/timekeeping/office/${officeId}/MonthlyClose`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        from: from,
        to: to,
        officeIds: [officeId],
      }),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchEmployeeMe: (
  signal?: AbortSignal
) => Promise<ApiResult<Employee>> = async (signal) => {
  let result: Response = null;
  result = await fetchWithRetry(`${apiUrl}/employee/EmployeeMe`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    signal,
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiPatchEmployeeMe: (
  employee: Employee,
  rowVersion: string | null
) => Promise<ApiResult<Employee>> = async (employee, rowVersion) => {
  let result: Response = null;
  result = await fetchWithRetry(`${apiUrl}/employee/EmployeeMe`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/merge-patch+json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      ...employee,
      rowVersion,
    }),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiPatchEmployee: (
  employeeId: EmployeeId,
  employee: Employee,
  rowVersion: string | null,
  officeId?: OfficeId
) => Promise<ApiResult<Employee>> = async (
  employeeId,
  employee,
  rowVersion,
  officeId
) => {
  let result: Response = null;
  if (officeId !== undefined && officeId !== '') {
    result = await fetchWithRetry(
      `${apiUrl}/employee/office/${officeId}/Employee/${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/merge-patch+json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          ...employee,
          employeeId,
          rowVersion,
        }),
      }
    );
  } else {
    result = await fetchWithRetry(`${apiUrl}/employee/Employee/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        ...employee,
        employeeId,
        rowVersion,
      }),
    });
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAddHolidayEntitlementEntry: (
  employeeId: EmployeeId,
  officeId: OfficeId,
  holidayEntitlement: HolidayEntitlement
) => Promise<ApiResult<HolidayEntitlement>> = async (
  employeeId,
  officeId,
  holidayEntitlement
) => {
  let result: Response = null;
  if (officeId !== undefined && officeId !== '') {
    result = await fetchWithRetry(
      `${apiUrl}/employee/office/${officeId}/Employee/${employeeId}/holidayEntitlement`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(holidayEntitlement),
      }
    );
  } else {
    result = await fetchWithRetry(
      `${apiUrl}/employee/Employee/${employeeId}/holidayEntitlement`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(holidayEntitlement),
      }
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateMonthlyClose: (
  officeId: OfficeId,
  monthlyCloseUpdate: MonthlyCloseUpdate,
  monthlyCloseId: MonthlyCloseId
) => Promise<ApiResult<MonthlyClose>> = async (
  officeId,
  monthlyCloseUpdate,
  monthlyCloseId
) => {
  let result: Response = null;
  result = await fetchWithRetry(
    `${apiUrl}/timekeeping/office/${officeId}/MonthlyClose/${monthlyCloseId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(monthlyCloseUpdate),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetMonthlyCloseEditableId: (
  employeeId: EmployeeId
) => Promise<
  ApiResult<{ employeeId; editableMonthlyCloseId; nextToCloseMonthlyCloseId }>
> = async (employeeId) => {
  let result: Response = null;
  result = await fetchWithRetry(
    `${apiUrl}/timekeeping/MonthlyClose/editable/${employeeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateHolidayEntitlement: (
  employeeId: EmployeeId,
  holidayEntitlement: HolidayEntitlement,
  officeId: OfficeId
) => Promise<ApiResult<HolidayEntitlement>> = async (
  employeeId,
  holidayEntitlement,
  officeId
) => {
  let result: Response = null;
  if (officeId !== undefined && officeId !== '') {
    result = await fetchWithRetry(
      `${apiUrl}/employee/office/${officeId}/Employee/${employeeId}/holidayEntitlement/${holidayEntitlement.employeeHolidayEntitlementId}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/merge-patch+json',
        },
        method: 'PATCH',
        body: JSON.stringify(holidayEntitlement),
      }
    );
  } else {
    result = await fetchWithRetry(
      `${apiUrl}/employee/Employee/${employeeId}/holidayEntitlement/${holidayEntitlement.employeeHolidayEntitlementId}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/merge-patch+json',
        },
        method: 'PATCH',
        body: JSON.stringify(holidayEntitlement),
      }
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiReopenMonthlyCloses: (
  monthlyCloseId: MonthlyCloseId,
  officeId?: OfficeId
) => Promise<ApiResult<MonthlyClose[]>> = async (monthlyCloseId, officeId) => {
  let result: Response = null;
  result = await fetchWithRetry(
    `${apiUrl}/timekeeping${
      officeId ? `/office/${officeId}` : ''
    }/MonthlyClose/${monthlyCloseId}/reopen`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        monthlyCloseId,
        officeId,
      }),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
