import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Typography,
} from 'antd';
import { rowGutter } from '../util/forms';
import Flex from './Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import useDatePickerLocale from '../hooks/useDatePickerLocale';
import { useForm } from 'antd/lib/form/Form';
import useDebounce from '../hooks/useDebounce';
import eqauls from 'deep-equal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';
import { makePrioStyles } from '../theme/utils';

const { Text } = Typography;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  resetButton: {
    fontSize: 14,
    padding: 0,
  },
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  lastColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  radioLabelCol: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
  },
}));

const today: [Moment, Moment] = [moment().startOf('D'), moment().endOf('D')];

const currentWeek: [Moment, Moment] = [
  moment().startOf('week'),
  moment().endOf('week'),
];

const lastWeek: [Moment, Moment] = [
  moment().subtract(1, 'week').startOf('week'),
  moment().subtract(1, 'week').endOf('week'),
];

const currentMonth: [Moment, Moment] = [
  moment().startOf('month'),
  moment().endOf('month'),
];

const lastMonth: [Moment, Moment] = [
  moment().subtract(1, 'month').startOf('month'),
  moment().subtract(1, 'month').endOf('month'),
];

const currentYear: [Moment, Moment] = [
  moment().startOf('year'),
  moment().endOf('year'),
];

const lastYear: [Moment, Moment] = [
  moment().subtract(1, 'year').startOf('year'),
  moment().subtract(1, 'year').endOf('year'),
];

const currentAndNextYear: [Moment, Moment] = [
  moment().startOf('year'),
  moment().add(1, 'year').endOf('year'),
];

type PreselectedPeriod =
  | 'today'
  | 'lastWeek'
  | 'currentWeek'
  | 'lastMonth'
  | 'currentMonth'
  | 'lastYear'
  | 'currentYear'
  | 'currentAndNextYear'
  | 'custom';

interface AdvancedFilterNode {
  label: string;
  name: string;
  node: ReactNode;
}

interface Filter {
  from: Moment;
  to: Moment;
}

interface Period {
  period: [Moment, Moment];
}

declare type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

const getInitialPeriod = (type: PreselectedPeriod) => {
  switch (type) {
    case 'today': {
      return today;
    }
    case 'currentWeek': {
      return currentWeek;
    }
    case 'lastWeek': {
      return lastWeek;
    }
    case 'currentMonth': {
      return currentMonth;
    }
    case 'lastMonth': {
      return lastMonth;
    }
    case 'currentYear': {
      return currentYear;
    }
    case 'lastYear': {
      return lastYear;
    }
    case 'currentAndNextYear': {
      return currentAndNextYear;
    }
    default: {
      return today;
    }
  }
};

interface TimeRangeFilterProps<AdditionalFilter> {
  children?: AdvancedFilterNode[];
  count?: number;
  selected?: number;
  onChange?: (value: Filter & AdditionalFilter) => void;
  defaultPeriod?: PreselectedPeriod;
}

export function TimeRangeFilter<AdditionalFilter>(
  props: TimeRangeFilterProps<AdditionalFilter>
) {
  //#region ------------------------------ Defaults
  const { children, count, selected, onChange, defaultPeriod } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  const [form] = useForm<Period & AdditionalFilter>();

  const initialFilter = {
    period: getInitialPeriod(defaultPeriod),
  };

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [period, setPeriod] = useState<PreselectedPeriod>(
    defaultPeriod as PreselectedPeriod
  );
  const [currentValues, setCurrentValues] = useState<Period & AdditionalFilter>(
    initialFilter as Period & AdditionalFilter
  );

  const debouncedCurrentValues = useDebounce(currentValues, 500);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const calculateSpan = () => {
    const filtersCount = (children ?? []).filter(Boolean).length;
    return 24 / filtersCount;
  };

  const handlePeriodChange = (value: PreselectedPeriod) => {
    if (value === 'custom') {
      return;
    }
    let period: [Moment, Moment] = [moment(), moment()];
    switch (value) {
      case 'today': {
        period = today;
        break;
      }
      case 'currentWeek': {
        period = currentWeek;
        break;
      }
      case 'currentMonth': {
        period = currentMonth;
        break;
      }
      case 'currentYear': {
        period = currentYear;
        break;
      }
      case 'lastWeek': {
        period = lastWeek;
        break;
      }
      case 'lastMonth': {
        period = lastMonth;
        break;
      }
      case 'lastYear': {
        period = lastYear;
        break;
      }
      case 'currentAndNextYear': {
        period = currentAndNextYear;
        break;
      }
      default: {
        period = form.getFieldsValue(['period']);
        break;
      }
    }
    setPeriod(value);
    form.setFieldsValue({ period } as RecursivePartial<
      Period & AdditionalFilter
    >);
    setCurrentValues({
      ...form.getFieldsValue(),
      period,
    });
  };

  const handleValuesChange = (
    changedValues: RecursivePartial<Period & AdditionalFilter>,
    values: Period & AdditionalFilter
  ) => {
    if (changedValues.period) {
      const newPeriod = changedValues.period;
      if (eqauls(newPeriod, today)) {
        setPeriod('today');
      } else if (eqauls(newPeriod, currentWeek)) {
        setPeriod('currentWeek');
      } else if (eqauls(newPeriod, currentMonth)) {
        setPeriod('currentMonth');
      } else if (eqauls(newPeriod, currentYear)) {
        setPeriod('currentYear');
      } else if (eqauls(newPeriod, lastWeek)) {
        setPeriod('lastWeek');
      } else if (eqauls(newPeriod, lastMonth)) {
        setPeriod('lastMonth');
      } else if (eqauls(newPeriod, lastYear)) {
        setPeriod('lastYear');
      } else if (eqauls(newPeriod, currentAndNextYear)) {
        setPeriod('currentAndNextYear');
      } else {
        setPeriod('custom');
      }
    }
    setCurrentValues(values);
  };

  const handleReset = () => {
    form.resetFields();
    setPeriod(defaultPeriod as PreselectedPeriod);
    setCurrentValues(initialFilter as Period & AdditionalFilter);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (onChange) {
      const { period, ...values } = debouncedCurrentValues;
      onChange({
        ...(values as unknown as AdditionalFilter),
        from: period[0],
        to: period[1],
      });
    }
  }, [debouncedCurrentValues, onChange]);
  //#endregion

  return (
    <Form<Period & AdditionalFilter>
      form={form}
      initialValues={initialFilter}
      layout="vertical"
      className={classes.root}
      onValuesChange={handleValuesChange}
    >
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={8}>
              <Form.Item label={t('common:filter.label.period')}>
                <Select<PreselectedPeriod>
                  className={classes.fullWidth}
                  placeholder={t('common:filter.placeholder.period')}
                  value={period}
                  defaultValue={defaultPeriod as PreselectedPeriod}
                  onChange={handlePeriodChange}
                  options={[
                    {
                      value: 'today',
                      label: t('common:filter.today'),
                    },
                    {
                      value: 'currentWeek',
                      label: t('common:filter.currentWeek'),
                    },
                    {
                      value: 'lastWeek',
                      label: t('common:filter.lastWeek'),
                    },
                    {
                      value: 'currentMonth',
                      label: t('common:filter.currentMonth'),
                    },
                    {
                      value: 'lastMonth',
                      label: t('common:filter.lastMonth'),
                    },
                    {
                      value: 'currentYear',
                      label: t('common:filter.currentYear'),
                    },
                    {
                      value: 'lastYear',
                      label: t('common:filter.lastYear'),
                    },
                    {
                      value: 'currentAndNextYear',
                      label: t('common:filter.currentAndNextYear'),
                    },
                    {
                      value: 'custom',
                      label: t('common:filter.custom'),
                      disabled: true,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={' '} name="period">
                <DatePicker.RangePicker
                  className={classes.fullWidth}
                  style={{ width: '100%' }}
                  format="DD.MM.YYYY"
                  locale={datePickerLocale}
                  suffixIcon={
                    <FontAwesomeIcon icon={['fal', 'calendar-alt']} />
                  }
                  allowClear={false}
                  allowEmpty={[false, false]}
                />
              </Form.Item>
            </Col>
            <Col span={8} className={classes.lastColumn}>
              <Flex.Row
                flex={1}
                alignItems={'center'}
                justifyContent="flex-end"
                childrenGap={theme.old.spacing.defaultPadding}
              >
                <div>
                  {count > 0 && (
                    <Text>
                      {t('accounting:table.results')}:{' '}
                      {selected > 0 && <Text>{selected}/</Text>}
                      {count}
                    </Text>
                  )}
                </div>
                {!eqauls(initialFilter, currentValues) && (
                  <Button
                    type="link"
                    className={classes.resetButton}
                    onClick={handleReset}
                    danger
                  >
                    <FontAwesomeIcon
                      className={classes.trashIcon}
                      icon={['fal', 'trash-alt']}
                    />
                    {t('common:filter.resetFilter')}
                  </Button>
                )}
              </Flex.Row>
            </Col>
          </Row>
          {children && (
            <Collapse
              ghost
              className={classes.collapse}
              defaultActiveKey={['1']}
            >
              <Collapse.Panel
                header={
                  <Flex.Row flex={1}>
                    <div className={classes.radioLabelCol}>
                      {t('common:filter.label.filterHeader')}
                    </div>
                    <Divider className={classes.divider} />
                  </Flex.Row>
                }
                key="1"
              >
                <Row gutter={theme.old.spacing.unit(rowGutter)}>
                  {children.map((child) => (
                    <Col span={calculateSpan()}>
                      <Form.Item label={child.label} name={child.name}>
                        {child.node}
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Collapse.Panel>
            </Collapse>
          )}
        </Col>
      </Row>
    </Form>
  );
}

export default TimeRangeFilter;
