import React, { ChangeEvent, useState } from 'react';
import { Input } from 'antd';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface NumberInputProps {
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  disabled?: boolean;
  allowNegative?: boolean;
}

const numberReg = /^-?\d*(,\d{0,2})?$/;
const positiveNumberReg = /^\d*(,\d{0,2})?$/;

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { value, onChange, placeholder, disabled, allowNegative } = props;

  const classes = useStyles();

  const [stringValue, setStringValue] = useState<string>(
    value?.toFixed(2)?.replace('.', ',') ?? ''
  );

  const onBlur = () => {
    setStringValue(value?.toFixed(2)?.replace('.', ','));
  };

  const onStringChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newStringValue = event.target.value.replace(/\./g, '');
    if (newStringValue.trim() === '') {
      setStringValue(newStringValue);
      if (onChange) {
        onChange(null);
      } else {
        event.preventDefault();
      }
    } else if (
      (allowNegative ? numberReg : positiveNumberReg).test(newStringValue)
    ) {
      const newValue = parseFloat(newStringValue.replace(/,/g, '.'));
      setStringValue(newStringValue);
      if (onChange) {
        onChange(newValue);
      } else {
        event.preventDefault();
      }
    }
  };

  return (
    <Input
      className={classes.root}
      onChange={onStringChanged}
      placeholder={placeholder}
      maxLength={30}
      value={stringValue}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};

export default NumberInput;
