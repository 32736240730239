import React, { CSSProperties } from 'react';
import { Avatar, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { stringToColour } from '../util/index';
import { getUserMe } from '../apps/main/rootReducer';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';
import { User } from '../models/User';
import { Contact } from '../models/Contact';

const STYLES: { [key: string]: CSSProperties } = {
  tiny: {
    width: 18,
    height: 18,
    lineHeight: '18px',
    fontSize: 8,
  },
  small: {
    width: 20,
    height: 20,
    lineHeight: '20px',
    fontSize: 10,
  },
  medium: {
    width: 32,
    height: 32,
    lineHeight: '32px',
    fontSize: 15,
  },
  large: {
    width: 50,
    height: 50,
    lineHeight: '50px',
    fontSize: 25,
  },
  extraLarge: {
    width: 88,
    height: 88,
    lineHeight: '88px',
    fontSize: 44,
  },
};

type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge';

interface UserAvatarProps {
  size?: AvatarSize;
  user?: User;
  contact?: Contact;
}

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  //#region -------------------------------- Variables
  const { size, user, contact } = props;
  const userMe = useSelector(getUserMe);
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region -------------------------------- Methods
  const getUserOrContactName = (user, contact) => {
    if (contact) return [contact.firstName, contact.lastName];
    if (user) return user.displayName?.split(' ') ?? ['', ''];
    return userMe?.displayName?.split(' ') ?? ['', ''];
  };

  const getUserOrContactEmail = (user, contact) => {
    if (contact) return contact.eMail;
    if (user) return user.mail;
    return userMe?.mail;
  };

  const getStyle = () => {
    switch (size) {
      case 'tiny':
        return STYLES.tiny;

      case 'small':
        return STYLES.small;

      case 'medium':
        return STYLES.medium;

      case 'large':
        return STYLES.large;

      case 'extraLarge':
        return STYLES.extraLarge;
    }
  };

  const noPicture = () => {
    const name = getUserOrContactName(user, contact);
    return (
      <Typography.Text style={{ color: theme.old.typography.colors.inverted }}>
        {name[0] && name[1] && name[0][0] + name[1][0]}
      </Typography.Text>
    );
  };

  return (
    <Avatar
      style={{
        fontWeight: theme.old.typography.fontWeight.regular,
        backgroundColor: stringToColour(
          getUserOrContactEmail(user, contact) ?? ''
        ),
        ...getStyle(),
      }}
    >
      {noPicture()}
    </Avatar>
  );
};

export default UserAvatar;
