import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { useSelector } from 'react-redux';
import { getUserMeContactId } from '../../../apps/main/rootReducer';
import { apiFetchEmployeeMe, apiPatchEmployeeMe } from '../../hr/api';
import { Employee } from '../../../models/Employee';
import Flex from '../../../components/Flex';
import PrioSpinner from '../../../components/PrioSpinner';
import { Center } from '../../../components/Center';
import ProfilePersonnelCoreDataForm from './ProfilePersonnelCoreDataForm';
import ProfilePersonnelCompanyDataForm from './ProfilePersonnelCompanyDataForm';
import EditPersonnelDataDrawer from './EditPersonnelDataDrawer';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: theme.spacing.unit(3),
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  content: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  tabs: {
    '& .ant-tabs-content-holder': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    overflowY: 'auto',
    height: '100%',
    '& .ant-tabs': {
      height: '100%',
    },
  },
  holidayEntitlements: {
    height: '100%',
    overflow: 'hidden',
  },
  changeDataBtn: {
    marginBottom: theme.old.spacing.unit(2),
  },
  boxChangeData: {
    paddingTop: theme.spacing.unit(3),
    paddingBottom: theme.spacing.unit(3),
    paddingLeft: theme.spacing.unit(3),
    marginLeft: theme.spacing.unit(3),
    marginRight: theme.spacing.unit(3),
    borderRadius: '2px',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
}));

interface PersonnelDatePageProps {
  className?: string;
}

export const PersonnelDatePage: React.FC<PersonnelDatePageProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const employeeId = useSelector(getUserMeContactId);
  const [employee, setEmployee] = useState<Employee>();
  const [editPersonnelDataDrawerOpen, setEditPersonnelDataDrawerOpen] =
    useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const openEditPersonnelDataDrawer = () =>
    setEditPersonnelDataDrawerOpen(true);
  const closeEditPersonnelDataDrawer = () =>
    setEditPersonnelDataDrawerOpen(false);

  const patchEmployee = async (patchData: Employee) => {
    try {
      const { data } = await apiPatchEmployeeMe(
        patchData,
        employee?.rowVersion ?? ''
      );

      if (data) {
        setEmployee(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:personnelFile.errorMessages.patchEmployee'),
        });
      }
    } catch (e) {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.patchEmployee'),
      });
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const { data } = await apiFetchEmployeeMe();
        if (data) {
          setEmployee(data as Employee);
        }
      } catch {}
    };

    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <div className={classes.boxChangeData}>
        <Flex.Row alignSelf="flex-end" childrenGap={theme.spacing.unit(1)}>
          <Button onClick={openEditPersonnelDataDrawer}>Daten ändern</Button>
        </Flex.Row>
      </div>
      {employee ? (
        <Flex.Row
          className={classes.content}
          childrenGap={theme.old.spacing.unit(1)}
          padding={`${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(
            3
          )}px`}
        >
          <Flex.Item flex={1}>
            <ProfilePersonnelCoreDataForm
              employeeId={employeeId}
              employee={employee}
              disabled={false}
              openEditPersonnelDataDrawer={openEditPersonnelDataDrawer}
            ></ProfilePersonnelCoreDataForm>
          </Flex.Item>
          <Flex.Item flex={1}>
            <ProfilePersonnelCompanyDataForm
              employeeId={employeeId}
              employee={employee}
              disabled={false}
            ></ProfilePersonnelCompanyDataForm>
          </Flex.Item>
        </Flex.Row>
      ) : (
        <Center>
          <PrioSpinner></PrioSpinner>
        </Center>
      )}
      <EditPersonnelDataDrawer
        open={editPersonnelDataDrawerOpen}
        onClose={closeEditPersonnelDataDrawer}
        employeeId={employeeId}
        employee={employee}
        patchEmployee={patchEmployee}
      ></EditPersonnelDataDrawer>
    </Flex.Column>
  );
};

export default PersonnelDatePage;
