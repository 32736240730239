import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import Timeline, { TimelineRef } from '../../../components/Timeline/Timeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  TimelineGroup,
  TimelineItem,
} from '../../../components/Timeline/types';
import {
  DateTimeString,
  MonthlyCloseId,
  ProjectId,
} from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getActiveProject,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { ProjectByIdState } from '../../projects/reducers/projects';
import GroupHeader from './Timeline/GroupHeader';
import GroupSuffix from './Timeline/GroupSuffix';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';
import {
  debounceFunction,
  distinct,
  distinctByProperty,
  isTemporaryId,
} from '../../../util';
import {
  TimeAndLeaveManagementTimelineItem,
  timelineItemsSelector,
} from '../../timeAndLeaveManagement/selectors';
import {
  createTimeKeepingDay,
  debounceMonthlyCloseMe,
  deleteTimeKeepingDay,
  updateTimeKeepingDay,
} from '../actions';
import {
  CreateTimeKeepingEntry,
  TimeKeepingDay,
} from '../../../models/TimeKeeping';
import TimeKeepingTimelinieItem, {
  TimeEntry,
} from './Timeline/TimeKeepingTimelineItem';
import {
  debounceTimeRecordsMe,
  deleteMyTimeRecord,
  updateTimeRecord,
} from '../../timeRecords/actions';
import {
  apiCreateTimeKeepingDay,
  apiDeleteTimeKeepingDayMe,
  apiFetchTimeKeepingDaySuggestion,
  apiUpdateTimeKeeping,
} from '../api';
import { debounceAbsencesMe } from '../../absences/actions';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import TimeAndLeaveTimelineFormManager from '../../timeAndLeaveManagement/components/TimeAndLeaveTimelineFormManager';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  apiDeleteTimeRecords,
  apiUpdateTimeRecord,
} from '../../timeRecords/api';
import {
  TimeRecord,
  UpdateTimeRecordRequest,
} from '../../../models/TimeRecord';
import { mapAbsenceStateToColor } from '../../absences/utils';
import TimeTrackingSuggestionTimelineItem from './Timeline/TimeTrackingSuggestionTimelineItem';
import { apiFetchTimeTrackingSuggestions } from '../../calendar/api';
import { v4 as uuid } from 'uuid';
import TimeKeepingTimlinePopover from './Timeline/TimeKeepingTimlinePopover';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  groupHeaderTitle: {
    padding: theme.old.spacing.unit(0.5),
    width: '100%',
    '& .ant-select': {
      width: '100%',
    },
    '& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector':
      {
        padding: 0,
        border: 'none',
      },
    '& .ant-select-single .ant-select-selector .ant-select-selection-search': {
      left: 0,
    },
  },
  addButton: {
    color: theme.old.palette.primaryColor,
  },
  formManager: {
    backgroundColor: theme.old.palette.backgroundPalette.main,
    maxHeight: '45%',
    boxShadow: theme.old.palette.boxShadow.regular,
    borderRadius: 2,
    margin: `${theme.old.spacing.unit(3)}px ${
      theme.old.spacing.baseSpacing
    }px ${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.baseSpacing}px`,
  },
}));

const createTemporaryId = () => {
  return `t-${uuid()}`;
};

const projectGroupsSelector = (
  projectIds: ProjectId[],
  items: TimeAndLeaveManagementTimelineItem[],
  day: DateTimeString
) =>
  createSelector<
    [(state: RootReducerState) => ProjectByIdState],
    TimelineGroup[]
  >(getProjectByIdState, (byId) =>
    distinctByProperty<TimelineGroup>(
      items.reduce((currentArray, { groupId, startDateTime, type }) => {
        if (
          !projectIds.includes(groupId) &&
          (type === 'timeRecord' || type === 'timeTrackingSuggestion') &&
          startDateTime.split('T')[0] === day
        ) {
          currentArray.push({
            id: groupId,
            title: byId[groupId]?.number ?? '',
          });
        }
        return currentArray;
      }, [] as TimelineGroup[]),
      'id'
    )
      .concat(
        projectIds.map((id) => {
          const project = byId[id];
          return {
            id,
            title: project.number,
          };
        })
      )
      .sort((a, b) => a.title.localeCompare(b.title))
  );

const calcItemOnMove: (
  item: TimeAndLeaveManagementTimelineItem,
  entriesStartTime: DateTimeString,
  sortedEntries: {
    startTime: string;
    endTime: string;
  }[]
) => TimeAndLeaveManagementTimelineItem = (
  item,
  entriesStartTime,
  sortedEntries
) => {
  const deltaInMinutes = moment(item.startDateTime).diff(
    moment(entriesStartTime),
    'minutes'
  );
  const newEntries = sortedEntries.map(({ startTime, endTime }) => ({
    startTime: moment(startTime)
      .add(deltaInMinutes, 'minutes')
      .toISOString(true)
      .split('.')[0],
    endTime: moment(endTime)
      .add(deltaInMinutes, 'minutes')
      .toISOString(true)
      .split('.')[0],
  }));
  return {
    ...item,
    entries: newEntries,
  };
};

const calcOnItemResize: (
  item: TimeAndLeaveManagementTimelineItem,
  entriesStartTime: DateTimeString,
  entriesEndTime: DateTimeString,
  sortedEntries: {
    startTime: string;
    endTime: string;
  }[]
) => TimeAndLeaveManagementTimelineItem = (
  item,
  entriesStartTime,
  entriesEndTime,
  sortedEntries
) => {
  let endTimeDeltaInMinutes = 0;
  if (moment(item.startDateTime).isSame(entriesStartTime)) {
    endTimeDeltaInMinutes = moment(item.endDateTime).diff(
      moment(entriesEndTime),
      'minutes'
    );
  }
  const newEntries: {
    startTime: string;
    endTime: string;
  }[] = sortedEntries.reduce(
    (currentArray, entry, index, array) => {
      if (index > 0) {
        const breakDelta = moment(entry.startTime).diff(
          moment(array[index - 1].endTime),
          'minutes'
        );

        const { endTime: previousEndTime } = currentArray[index - 1];
        const _startTime = moment(previousEndTime)
          .add(breakDelta, 'minutes')
          .toISOString(true)
          .split('.')[0];
        const newEndTime = moment(entry.endTime).add(
          endTimeDeltaInMinutes,
          'minutes'
        );
        let _deltaInMinutes = moment(newEndTime).diff(
          moment(_startTime),
          'minutes'
        );
        if (_deltaInMinutes < 15) {
          _deltaInMinutes = 15;
        }
        currentArray.push({
          startTime: _startTime,
          endTime: moment(_startTime)
            .add(_deltaInMinutes, 'minutes')
            .toISOString(true)
            .split('.')[0],
        });
      } else {
        const newEndTime = moment(entry.endTime).add(
          endTimeDeltaInMinutes,
          'minutes'
        );
        let _deltaInMinutes = moment(newEndTime).diff(
          item.startDateTime,
          'minutes'
        );
        if (_deltaInMinutes < 15) {
          _deltaInMinutes = 15;
        }
        currentArray.push({
          startTime: item.startDateTime,
          endTime: moment(item.startDateTime)
            .add(_deltaInMinutes, 'minutes')
            .toISOString(true)
            .split('.')[0],
        });
      }
      return currentArray;
    },
    [] as {
      startTime: string;
      endTime: string;
    }[]
  );
  return {
    ...item,
    entries: newEntries,
  };
};

const debouncedApiFetchTimeTrackingSuggestions = debounceFunction(
  async (
    from: DateTimeString,
    to: DateTimeString,
    setTimeTrackingSuggestionItems: (
      value: TimeAndLeaveManagementTimelineItem[]
    ) => void
  ) => {
    const { data } = await apiFetchTimeTrackingSuggestions(from, to);
    if (data) {
      setTimeTrackingSuggestionItems(
        data.map((item) => ({
          id: uuid(),
          groupId: item.projectId,
          title: item.name,
          startDateTime: item.start,
          endDateTime: item.end,
          type: 'timeTrackingSuggestion',
          value: item,
          entries: [
            {
              startTime: item.start,
              endTime: item.end,
            },
          ],
          state: 'ok',
          disabled: true,
        }))
      );
    }
  },
  500
);

const snapTo15Minutes = (startTime: DateTimeString, endTime: string) => {
  const _startTime = moment(
    Math.round(moment(startTime).valueOf() / (15 * 60 * 1000)) *
      (15 * 60 * 1000)
  )
    .toISOString(true)
    .split('.')[0];
  const _endTime = moment(
    Math.round(moment(endTime).valueOf() / (15 * 60 * 1000)) * (15 * 60 * 1000)
  )
    .toISOString(true)
    .split('.')[0];
  return {
    startTime: _startTime,
    endTime: _endTime,
  };
};

interface TimeKeepingDrawerContentProps {
  className?: string;
  initialDay?: DateTimeString;
  monthlyCloseChange: (monthlyCloseId: MonthlyCloseId) => void;
}

export const TimeKeepingDrawerContent: React.FC<
  TimeKeepingDrawerContentProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, initialDay, monthlyCloseChange } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const ref = useRef<TimelineRef>(null);

  const [day, setDay] = useState<DateTimeString>(
    (initialDay ?? moment().toISOString(true)).split('T')[0]
  );
  const [maxRange, setMaxRange] = useState<DateTimeString>('18:00');
  const [minRange, setMinRange] = useState<DateTimeString>('07:00');

  const currentMonth = useMemo(() => day.substring(0, 7), [day]);

  const _items: TimeAndLeaveManagementTimelineItem[] = useSelector(
    timelineItemsSelector(currentMonth, minRange, maxRange)
  );

  const [timeTrackingSuggestionItems, setTimeTrackingSuggestionItems] =
    useState<TimeAndLeaveManagementTimelineItem[]>([]);

  const [temporaryItems, setTemporaryItems] = useState<
    TimeAndLeaveManagementTimelineItem[]
  >([]);

  const items = useMemo(
    () => [..._items, ...temporaryItems, ...timeTrackingSuggestionItems],
    [_items, temporaryItems, timeTrackingSuggestionItems]
  );

  const activeProjectId = useSelector(getActiveProject);

  const [addedProjectIds, setAddedProjectIds] = useState<ProjectId[]>([]);

  const projectIds = useMemo(
    () =>
      distinct([
        ...addedProjectIds,
        ...(activeProjectId && activeProjectId !== 'me'
          ? [activeProjectId]
          : []),
      ]),
    [addedProjectIds, activeProjectId]
  );

  const projectGroups = useSelector(
    projectGroupsSelector(projectIds, items, day)
  );

  const groups: TimelineGroup[] = useMemo(
    () => [
      {
        id: 'timeKeeping',
        title: 'Arbeitszeit',
      },
      ...(projectGroups ?? []),
      {
        id: 'addProject',
        title: 'addProjekt',
        disabled: true,
      },
    ],
    [projectGroups]
  );

  const [selectedItem, setSelectedItem] =
    useState<TimeAndLeaveManagementTimelineItem>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnProjectSelect = useCallback(
    (projectId: ProjectId) => {
      setAddedProjectIds([...addedProjectIds, projectId]);
    },
    [addedProjectIds]
  );

  const handleOnProjectRemove = useCallback(
    (projectId: ProjectId) => {
      setAddedProjectIds(addedProjectIds.filter((id) => id !== projectId));
      setTemporaryItems(
        temporaryItems.filter((item) => item.groupId !== projectId)
      );
    },
    [addedProjectIds, temporaryItems]
  );

  const calcItemStyle = useCallback(
    (group: TimelineGroup, item: TimeAndLeaveManagementTimelineItem) => {
      const { type, state } = item;
      switch (type) {
        case 'absence': {
          return {
            backgroundColor: mapAbsenceStateToColor(
              (item.value as AbsenceProposal).absenceState,
              (item.value as AbsenceProposal).absenceType,
              theme
            ),
          };
        }
        case 'timeTrackingSuggestion': {
          return {
            backgroundColor: theme.old.palette.backgroundPalette.hover.main,
          };
        }
        case 'timeKeeping': {
          const { state: timeKeepingState } = item.value as TimeKeepingDay;
          let backgroundColor = undefined;
          if (timeKeepingState === 'approvalRequested') {
            backgroundColor = theme.old.palette.chromaticPalette.yellow;
          }
          if (timeKeepingState === 'notApproved') {
            backgroundColor = theme.old.palette.chromaticPalette.red;
          }
          if (state === 'temporary') {
            backgroundColor = theme.old.palette.backgroundPalette.hover.base;
          } else if (state === 'error') {
            backgroundColor = theme.old.palette.chromaticPalette.red;
          }
          return {
            backgroundColor,
          };
        }
        default: {
          let backgroundColor = undefined;
          if (state === 'temporary') {
            backgroundColor = theme.old.palette.backgroundPalette.hover.base;
          } else if (state === 'error') {
            backgroundColor = theme.old.palette.chromaticPalette.red;
          }
          return {
            backgroundColor,
          };
        }
      }
    },
    [theme]
  );

  const handleOnItemClick: (
    item: TimeAndLeaveManagementTimelineItem,
    group: TimelineGroup
  ) => void = useCallback((item, group) => {
    if (group.id === 'addProject') {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  }, []);

  const handleTimeKeepingUpdate: (
    item: TimeAndLeaveManagementTimelineItem,
    newItem: TimeAndLeaveManagementTimelineItem
  ) => Promise<TimeAndLeaveManagementTimelineItem> = useCallback(
    async (item, newItem) => {
      if (ref.current) {
        ref.current.replaceItem(
          newItem.groupId,
          { ...newItem, disabled: true },
          newItem.id
        );
      }
      let timeKeepingEntries: CreateTimeKeepingEntry[] = [];
      try {
        if (newItem.entries.length === 1) {
          const { data: suggestion } = await apiFetchTimeKeepingDaySuggestion(
            newItem.entries[0].startTime,
            newItem.entries[0].endTime
          );
          if (suggestion) {
            timeKeepingEntries = suggestion.timeKeepingEntries.map(
              ({ startTime, endTime }) => snapTo15Minutes(startTime, endTime)
            );
          } else {
            timeKeepingEntries = newItem.entries;
          }
        } else {
          timeKeepingEntries = newItem.entries;
        }
      } catch (error) {
        timeKeepingEntries = newItem.entries;
      }
      const { data } = await apiUpdateTimeKeeping(item.id, {
        type: (item.value as TimeKeepingDay).type,
        notes: (item.value as TimeKeepingDay).notes,
        timeKeepingEntries,
      });
      if (data) {
        const sortedEntries = data.timeKeepingEntries.sort((a, b) =>
          moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
        );
        dispatch(updateTimeKeepingDay(data, item.id));
        monthlyCloseChange(data.monthlyCloseId);

        return {
          id: data.timeKeepingDayId,
          groupId: item.groupId,
          title: '',
          type: 'timeKeeping',
          startDateTime: sortedEntries[0].startTime,
          endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
          entries: data.timeKeepingEntries,
          value: data,
          state: 'ok',
        } as TimeAndLeaveManagementTimelineItem;
      } else {
        if (ref.current) {
          ref.current.forceUpdate();
        }
      }
      return null;
    },
    [monthlyCloseChange, dispatch]
  );

  const handleTimeRecordUpdate: (
    item: TimeAndLeaveManagementTimelineItem,
    newItem: TimeAndLeaveManagementTimelineItem
  ) => Promise<TimeAndLeaveManagementTimelineItem> = useCallback(
    async (item, newItem) => {
      const itemValue: TimeRecord = newItem.value as TimeRecord;
      const request: UpdateTimeRecordRequest = {
        timeRecordId: itemValue.timeRecordId,
        projectId: itemValue.projectId,
        subProjectId: itemValue.subProjectId,
        description: itemValue.description,
        day: itemValue.day,
        contactId: itemValue.contactId,
        kilometerDistance: itemValue.kilometerDistance,
        title: itemValue.title,
        timeRecordEntries: newItem.entries,
        rowVersion: itemValue.rowVersion,
      };
      if (ref.current) {
        ref.current.replaceItem(
          newItem.groupId,
          { ...newItem, disabled: true },
          newItem.id
        );
      }
      const { data } = await apiUpdateTimeRecord(
        request,
        itemValue.timeRecordId,
        itemValue.rowVersion,
        'me'
      );
      if (data) {
        const sortedEntries = data.timeRecordEntries.sort((a, b) =>
          moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
        );
        dispatch(
          updateTimeRecord(data, data.timeRecordId, item.value as TimeRecord)
        );

        return {
          id: data.timeRecordId,
          groupId: data.projectId,
          title: data.title,
          type: 'timeRecord',
          startDateTime: sortedEntries[0].startTime,
          endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
          entries: data.timeRecordEntries,
          value: data,
          state: 'ok',
        } as TimeAndLeaveManagementTimelineItem;
      }
      notification.open({
        message: t('common:error'),
        description: t('timeRecords:errorMessages.updateError'),
      });
      return null;
    },
    [dispatch, t]
  );

  const handleOnItemCreate: (
    groupId: string,
    item: TimeAndLeaveManagementTimelineItem
  ) => Promise<TimeAndLeaveManagementTimelineItem> = useCallback(
    async (groupId, item) => {
      if (groupId === 'addProject') {
        return null;
      }
      if (ref.current) {
        ref.current.replaceItem(
          item.groupId,
          { ...item, disabled: true },
          item.id
        );
      }
      if (groupId === 'timeKeeping') {
        let timeKeepingEntries: CreateTimeKeepingEntry[] = [];

        try {
          const { data: suggestion } = await apiFetchTimeKeepingDaySuggestion(
            item.startDateTime,
            item.endDateTime
          );

          if (suggestion) {
            timeKeepingEntries = suggestion.timeKeepingEntries.map(
              ({ startTime, endTime }) => snapTo15Minutes(startTime, endTime)
            );
          } else {
            timeKeepingEntries = [
              {
                startTime: item.startDateTime,
                endTime: item.endDateTime,
              },
            ];
          }
        } catch (error) {
          timeKeepingEntries = [
            {
              startTime: item.startDateTime,
              endTime: item.endDateTime,
            },
          ];
        }

        const { data } = await apiCreateTimeKeepingDay({
          type: 'office',
          timeKeepingEntries,
        });

        if (data) {
          const sortedEntries = data.timeKeepingEntries.sort((a, b) =>
            moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
          );
          dispatch(createTimeKeepingDay(item.startDateTime, data));
          monthlyCloseChange(data.monthlyCloseId);
          const newTimeKeepingItem: TimeAndLeaveManagementTimelineItem = {
            id: data.timeKeepingDayId,
            groupId: item.groupId,
            startDateTime: sortedEntries[0].startTime,
            endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
            disabled: false,
            type: 'timeKeeping',
            entries: data.timeKeepingEntries,
            title: '',
            value: data,
            state: 'ok',
          };
          setSelectedItem(newTimeKeepingItem);
          return newTimeKeepingItem;
        }

        return null;
      }

      const newTimeRecordItem: TimeAndLeaveManagementTimelineItem = {
        ...item,
        id: createTemporaryId(),
        disabled: false,
        type: 'timeRecord',
        entries: [
          {
            startTime: item.startDateTime,
            endTime: item.endDateTime,
          },
        ],
        value: null,
        state: 'temporary',
      };
      setSelectedItem(newTimeRecordItem);
      setTemporaryItems([...temporaryItems, newTimeRecordItem]);
      return newTimeRecordItem;
    },
    [temporaryItems, monthlyCloseChange, dispatch]
  );

  const handleOnBreakPopoverClick: (
    item: TimeAndLeaveManagementTimelineItem,
    group: TimelineGroup,
    popoverPositionInMS: number
  ) => void = useCallback(
    async (item, group, popoverPositionInMS) => {
      const { entries, startDateTime } = item;
      const popoverTime = moment(startDateTime).add(
        Math.round(popoverPositionInMS / (1000 * 60)),
        'minutes'
      );
      const entryToSplit = entries.find(
        ({ startTime, endTime }) =>
          moment(startTime).isBefore(popoverTime) &&
          moment(endTime).isAfter(popoverTime)
      );
      const breakStartTime = popoverTime.toISOString(true).split('.')[0];
      const breakEndTime = popoverTime
        .clone()
        .add(15, 'minutes')
        .toISOString(true)
        .split('.')[0];
      if (
        entryToSplit &&
        (item.entries.length === 1 ||
          !(
            breakEndTime === entryToSplit.endTime ||
            breakStartTime === entryToSplit.startTime
          ))
      ) {
        const newItem: TimeAndLeaveManagementTimelineItem = {
          ...item,
          entries: [
            {
              startTime: entryToSplit.startTime,
              endTime: breakStartTime,
            },
            {
              startTime: breakEndTime,
              endTime: entryToSplit.endTime,
            },
            ...item.entries.filter(
              (entry) => entry.startTime !== entryToSplit.startTime
            ),
          ],
        };
        if (isTemporaryId(item.id)) {
          const itemIndex = temporaryItems.findIndex(
            (tempItem) => tempItem.id === item.id
          );
          if (itemIndex > -1) {
            const newItems = [...temporaryItems];
            newItems.splice(itemIndex, 1, newItem);
            setTemporaryItems(newItems);
          }
        } else {
          if (group.id === 'timeKeeping') {
            await handleTimeKeepingUpdate(item, newItem);
          } else {
            await handleTimeRecordUpdate(item, newItem);
          }
        }
      }
    },
    [temporaryItems, handleTimeKeepingUpdate, handleTimeRecordUpdate]
  );

  const handleOnPopoverClick: (
    item: TimeAndLeaveManagementTimelineItem,
    group: TimelineGroup,
    popoverPositionInMS: number
  ) => void = useCallback(
    (item, group, popoverPositionInMS) => {
      if (item.type !== 'timeTrackingSuggestion') {
        handleOnBreakPopoverClick(item, group, popoverPositionInMS);
      }
    },
    [handleOnBreakPopoverClick]
  );

  const handleOnFormCancel = (
    item: TimeAndLeaveManagementTimelineItem,
    type: 'timeKeeping' | 'timeRecord'
  ) => {
    setSelectedItem(null);
    if (isTemporaryId(item.id)) {
      setTemporaryItems(temporaryItems.filter(({ id }) => item.id !== id));
    }
  };

  const handleOnFormFinish = (
    previousId: string,
    item: TimeAndLeaveManagementTimelineItem,
    type: 'timeKeeping' | 'timeRecord'
  ) => {
    if (isTemporaryId(previousId)) {
      setTemporaryItems(temporaryItems.filter(({ id }) => previousId !== id));
      setSelectedItem(item);
      if (addedProjectIds.includes(item.groupId)) {
        setAddedProjectIds(addedProjectIds.filter((id) => id !== item.groupId));
      }
    }
    ref.current.forceUpdate();
  };

  const handleOnFormChange = (
    item: TimeAndLeaveManagementTimelineItem,
    type: 'timeKeeping' | 'timeRecord'
  ) => {
    setSelectedItem(item);
    if (ref.current) ref.current.forceUpdate();
  };

  const handleOnFormDelete = (
    id: string,
    groupId: string,
    type: 'timeKeeping' | 'timeRecord'
  ) => {
    setSelectedItem(null);
  };

  const handleOnItemChange: (
    groupId: string,
    item: TimeAndLeaveManagementTimelineItem
  ) => Promise<TimelineItem> = useCallback(
    async (groupId, item) => {
      const sortedEntries = item.entries.sort((a, b) =>
        moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
      );
      const entriesStartTime = moment(
        Math.min(
          ...sortedEntries.map(({ startTime }) => moment(startTime).valueOf())
        )
      )
        .toISOString(true)
        .split('.')[0];
      const entriesEndTime = moment(
        Math.max(
          ...sortedEntries.map(({ endTime }) => moment(endTime).valueOf())
        )
      )
        .toISOString(true)
        .split('.')[0];

      let newItem: TimeAndLeaveManagementTimelineItem = null;
      if (
        !moment(item.startDateTime).isSame(entriesStartTime) &&
        !moment(item.endDateTime).isSame(entriesEndTime)
      ) {
        newItem = calcItemOnMove(item, entriesStartTime, sortedEntries);
      } else {
        newItem = calcOnItemResize(
          item,
          entriesStartTime,
          entriesEndTime,
          sortedEntries
        );
      }
      if (isTemporaryId(item.id)) {
        const itemIndex = temporaryItems.findIndex(
          (tempItem) => tempItem.id === item.id
        );
        if (itemIndex > -1) {
          const newItems = [...temporaryItems];
          newItems.splice(itemIndex, 1, newItem);
          setTemporaryItems(newItems);
        }
      } else {
        if (groupId === 'timeKeeping') {
          newItem = await handleTimeKeepingUpdate(item, newItem);
        } else {
          newItem = await handleTimeRecordUpdate(item, newItem);
        }
      }
      setSelectedItem(newItem);
      return newItem;
    },
    [temporaryItems, handleTimeKeepingUpdate, handleTimeRecordUpdate]
  );

  const handleOnEntriesChange = useCallback(
    async (
      entries: TimeEntry[],
      item: TimeAndLeaveManagementTimelineItem,
      group: TimelineGroup
    ) => {
      if (ref.current) {
        ref.current.setIsFetching(true);
      }
      let newItem: TimeAndLeaveManagementTimelineItem = {
        ...item,
        entries: entries.map(({ startTime, endTime }) => ({
          startTime,
          endTime,
        })),
      };
      if (isTemporaryId(item.id)) {
        setTemporaryItems([
          ...temporaryItems.filter(({ id }) => id !== item.id),
          newItem,
        ]);
        if (ref.current) {
          ref.current.replaceItem(group.id, newItem, item.id);
          ref.current.setIsFetching(false);
        }
        return true;
      } else {
        let result: TimeAndLeaveManagementTimelineItem = null;
        if (group.id === 'timeKeeping') {
          result = await handleTimeKeepingUpdate(item, newItem);
        } else {
          result = await handleTimeRecordUpdate(item, newItem);
        }
        if (ref.current) {
          ref.current.setIsFetching(false);
          if (result) {
            ref.current.replaceItem(group.id, result, item.id);
          } else {
            ref.current.replaceItem(group.id, item, item.id);
          }
        }
        if (result) {
          setSelectedItem(result);
          return true;
        } else {
          return false;
        }
      }
    },
    [temporaryItems, handleTimeKeepingUpdate, handleTimeRecordUpdate]
  );

  const handleOnBreakChange = useCallback(
    async (groupId: string, item: TimeAndLeaveManagementTimelineItem) => {
      if (isTemporaryId(item.id)) {
        setTemporaryItems([
          ...temporaryItems.filter(({ id }) => id !== item.id),
          item,
        ]);
      } else {
        if (ref.current) {
          ref.current.replaceItem(
            groupId,
            { ...item, disabled: true },
            item.id
          );
          ref.current.setIsFetching(true);
        }
        let result: TimeAndLeaveManagementTimelineItem = null;
        if (groupId === 'timeKeeping') {
          result = await handleTimeKeepingUpdate(item, item);
        } else {
          result = await handleTimeRecordUpdate(item, item);
        }
        if (result) {
          setSelectedItem(result);
        }
        if (ref.current) {
          ref.current.setIsFetching(false);
          ref.current.forceUpdate();
        }
      }
    },
    [temporaryItems, handleTimeKeepingUpdate, handleTimeRecordUpdate]
  );

  const handleOnItemRemove = useCallback(
    async (item: TimeAndLeaveManagementTimelineItem, group: TimelineGroup) => {
      if (isTemporaryId(item.id)) {
        setTemporaryItems(temporaryItems.filter(({ id }) => id !== item.id));
        if (selectedItem?.id === item.id) {
          setSelectedItem(null);
        }
      } else {
        let _result: Response = null;
        if (group.id === 'timeKeeping') {
          const { result } = await apiDeleteTimeKeepingDayMe(item.id);
          _result = result;
          if (_result?.status >= 200 && _result?.status < 300) {
            const { monthlyCloseId, timeKeepingDayId } =
              item.value as TimeKeepingDay;
            dispatch(deleteTimeKeepingDay(monthlyCloseId, timeKeepingDayId));
            monthlyCloseChange(monthlyCloseId);
          }
        } else {
          const { result } = await apiDeleteTimeRecords(
            item.id,
            item.value as TimeRecord,
            'me'
          );
          _result = result;
          if (_result?.status >= 200 && _result?.status < 300) {
            const { timeRecordId } = item.value as TimeRecord;
            dispatch(
              deleteMyTimeRecord(timeRecordId, item.value as TimeRecord, true)
            );
          }
        }
        if (_result?.status >= 200 && _result?.status < 300) {
          setSelectedItem(null);
        } else if (ref.current) {
          ref.current.replaceItem(group.id, item, item.id);
        }
      }
    },
    [selectedItem, temporaryItems, dispatch, monthlyCloseChange]
  );

  const handleOnTimeRangeChange = useCallback(
    async (
      visibleStartTime: moment.Moment,
      visibleEndTime: moment.Moment,
      startTime: moment.Moment,
      endTime: moment.Moment
    ) => {
      if (!moment(currentMonth).isSame(visibleStartTime, 'month')) {
        const _startTime = startTime
          .clone()
          .startOf('month')
          .toISOString(true)
          .split('T')[0];
        const _endTime = endTime
          .clone()
          .endOf('month')
          .toISOString(true)
          .split('T')[0];
        dispatch(debounceMonthlyCloseMe(_startTime, _endTime));
        dispatch(debounceTimeRecordsMe(_startTime, _endTime));
        dispatch(debounceAbsencesMe(_startTime, _endTime));
        debouncedApiFetchTimeTrackingSuggestions(
          _startTime,
          _endTime,
          setTimeTrackingSuggestionItems
        );
      }
      setDay(visibleStartTime.toISOString(true).split('T')[0]);
      setMaxRange(visibleEndTime.toISOString(true).substring(11, 16));
      setMinRange(visibleStartTime.toISOString(true).substring(11, 16));
      setSelectedItem(null);
    },
    [currentMonth, dispatch]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchTimeTrackingSuggestions = async () => {
      const from = moment()
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const to = moment()
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      const { data } = await apiFetchTimeTrackingSuggestions(from, to);
      if (data) {
        setTimeTrackingSuggestionItems(
          data.map((item) => {
            const startTime = moment(`${item.start}Z`)
              .toISOString(true)
              .split('.')[0];
            const endTime = moment(`${item.end}Z`)
              .toISOString(true)
              .split('.')[0];
            return {
              id: uuid(),
              groupId: item.projectId,
              title: item.name,
              startDateTime: startTime,
              endDateTime: endTime,
              type: 'timeTrackingSuggestion',
              value: item,
              entries: [
                {
                  startTime: startTime,
                  endTime: endTime,
                },
              ],
              state: 'ok',
              disabled: true,
            };
          })
        );
      }
    };
    fetchTimeTrackingSuggestions();
  }, []);
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      childrenGap={theme.old.spacing.defaultPadding}
    >
      <Flex.Item flex={1}>
        <Timeline
          id="TimeAndLeaveManagementTimeline"
          ref={ref}
          initialVisibleTimeStart={moment(`${day}T07:00:00`)}
          initialVisibleTimeEnd={moment(`${day}T18:00:00`)}
          initialStartTime={moment(`${day}T00:00:00`)}
          initialEndTime={moment(`${day}T00:00:00`).add('1', 'day')}
          items={items}
          groups={groups}
          prefixWidth={128}
          suffixWidth={102}
          disableTopBar
          enableStepper
          onTimeRangeChange={handleOnTimeRangeChange}
          onItemCreate={handleOnItemCreate}
          onItemChange={handleOnItemChange}
          onItemClick={handleOnItemClick}
          onItemRemove={handleOnItemRemove}
          onPopoverClick={handleOnPopoverClick}
          itemStyle={calcItemStyle}
          itemRenderer={(
            group,
            item: TimeAndLeaveManagementTimelineItem,
            pixelsPerMs,
            gridSnapInMilliseconds
          ) =>
            item.type === 'timeTrackingSuggestion' ? (
              <TimeTrackingSuggestionTimelineItem group={group} item={item} />
            ) : (
              <TimeKeepingTimelinieItem
                item={item as TimeAndLeaveManagementTimelineItem}
                group={group}
                pixelsPerMs={pixelsPerMs}
                gridSnapInMilliseconds={gridSnapInMilliseconds}
                onEntriesChange={handleOnEntriesChange}
              />
            )
          }
          headerSuffixRenderer={() => (
            <Flex.Row
              width={'100%'}
              height={'100%'}
              alignItems="center"
              justifyContent="center"
            >
              <Flex.Row
                flex={1}
                alignItems="center"
                justifyContent="center"
                height={'100%'}
              >
                <FontAwesomeIcon icon={['fal', 'coffee']} />
              </Flex.Row>
              <Flex.Row
                flex={1}
                borderLeft={theme.old.borders.content}
                alignItems="center"
                justifyContent="center"
                height={'100%'}
              >
                <FontAwesomeIcon icon={['fal', 'sigma']} />
              </Flex.Row>
            </Flex.Row>
          )}
          popoverRenderer={(item: TimeAndLeaveManagementTimelineItem, group) =>
            item.type !== 'absence' && (
              <TimeKeepingTimlinePopover item={item} group={group} />
            )
          }
          groupSuffixRenderer={(
            group,
            items,
            visibleTimeStart,
            visibleTimeEnd,
            startTime,
            endTime
          ) => (
            <GroupSuffix
              group={group}
              items={items as TimeAndLeaveManagementTimelineItem[]}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
              startTime={startTime}
              endTime={endTime}
              timelineRef={ref}
              onItemChange={handleOnItemChange}
              onItemCreate={handleOnItemCreate}
              onBreakChange={handleOnBreakChange}
            />
          )}
          groupHeaderRenderer={(group, items) => (
            <GroupHeader
              className={classes.groupHeaderTitle}
              group={group}
              items={items}
              type={
                group.id === 'addProject'
                  ? 'addProject'
                  : group.id === 'timeKeeping'
                  ? 'timeKeeping'
                  : 'timeRecord'
              }
              projectIds={projectGroups.map(({ id }) => id)}
              onProjectSelect={handleOnProjectSelect}
              onProjectRemove={handleOnProjectRemove}
            />
          )}
        />
      </Flex.Item>
      {selectedItem && (
        <TimeAndLeaveTimelineFormManager
          className={classes.formManager}
          selectedItem={selectedItem}
          onCancel={handleOnFormCancel}
          onFinish={handleOnFormFinish}
          onChange={handleOnFormChange}
          onDelete={handleOnFormDelete}
        />
      )}
    </Flex.Column>
  );
};

export default TimeKeepingDrawerContent;
