import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Form, Input, Row } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import TodoListPicker from './TodoListPicker';
import StatusPicker from './StatusPicker';
import ImportancePicker from './ImportancePicker';
import DatePickerWithQuickAction from './DatePickerWithQuickAction';
import { rowGutter } from '../../../../util/forms';
import { makePrioStyles } from '../../../../theme/utils';
import { TodoTaskFormModel } from '../../../../models/Todo';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const { TextArea } = Input;

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    // marginBottom: 0,
    marginBottom: theme.old.spacing.unit(2),
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
    marginBottom: theme.old.spacing.unit(2),
  },
  scrollableFormModal: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 48px)',
  },
  scrollableFormWidget: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 48px - 32px)',
  },
}));

interface TodoFormProps {
  initialValues?: TodoTaskFormModel;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (value: TodoTaskFormModel) => void;
  onCancel?: () => void;
  resetOnFinish?: boolean;
  isModal?: boolean;
}

export const TodoForm: React.FC<TodoFormProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    initialValues,
    disableActionButton,
    disableForm,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    resetOnFinish,
    isModal,
  } = props;
  const [form] = Form.useForm<TodoTaskFormModel>();
  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  const handleFinish = (value: TodoTaskFormModel) => {
    onFinish(value);
    if (resetOnFinish) {
      form.resetFields();
    }
  };

  const handleCancel = () => {
    onCancel();
    if (resetOnFinish) {
      form.resetFields();
    }
  };
  //#endregion
  //#region -------------------------------- Hooks
  //#endregion

  return (
    <Form
      initialValues={initialValues}
      className={classes.root}
      form={form}
      onFinish={handleFinish}
      layout="vertical"
    >
      <div
        className={
          isModal ? classes.scrollableFormModal : classes.scrollableFormWidget
        }
      >
        <Col>
          <Form.Item
            name="todoListId"
            label={t('tasks:todoForm.labels.todoList')}
            rules={[
              {
                required: true,
                message: t('tasks:todoForm.validation.missing.title'),
              },
            ]}
          >
            <TodoListPicker disabled={disableForm} form={form} />
          </Form.Item>
        </Col>
        <Form.Item
          name="title"
          label={t('tasks:todoForm.labels.title')}
          rules={[
            {
              required: true,
              message: t('tasks:todoForm.validation.missing.title'),
            },
          ]}
        >
          <Input disabled={disableForm} />
        </Form.Item>
        <Form.Item
          name="description"
          label={t('tasks:todoForm.labels.description')}
        >
          <TextArea rows={8} disabled={disableForm} />
        </Form.Item>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              name="status"
              label={t('tasks:todoForm.labels.taskStatus')}
            >
              <StatusPicker disabled={disableForm} form={form} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="importance"
              label={t('tasks:todoForm.labels.taskImportance')}
            >
              <ImportancePicker disabled={disableForm} form={form} />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Divider />
        </Col>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              name="dueDate"
              label={t('tasks:todoForm.labels.dueDate')}
            >
              <DatePickerWithQuickAction
                disabled={disableForm}
                type="dueDate"
                form={form}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reminderDate"
              label={t('tasks:todoForm.labels.reminder')}
            >
              <DatePickerWithQuickAction
                disabled={disableForm}
                type="reminder"
                form={form}
              />
            </Form.Item>
          </Col>

          {
            // Implement when needed. Not yet implemented because complexity to high and customer demand to low
            /* <Col span={12}>
          <Form.Item name="repeat" label={t('tasks:todoForm.labels.repeat')}>
            <RepeatTodoPicker form={form} />
          </Form.Item>
        </Col> */
          }
        </Row>
      </div>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Form.Item className={classes.submitButtonFormItem}>
          <Button type="default" onClick={handleCancel}>
            {cancelLabel}
          </Button>

          <Button htmlType="submit" disabled={disableActionButton}>
            {actionLabel}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default TodoForm;
