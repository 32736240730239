import { combineReducers, Reducer } from 'redux';
import {
  FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT,
  CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST,
  FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK,
  CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
  CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT,
  UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
  UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT,
  ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
  ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST,
} from '../actions';
import { ExternalProjectContact } from '../../../models/ProjectContacts';
import { ProjectId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface ExternalProjectContactsState {
  data: DataState;
  meta: ExternalProjectContactsMeta;
}

export interface DataState {
  [projectId: string]: ExternalProjectContact[];
}

const data: Reducer<DataState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }

    /** CREATE */

    case CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...payload,
            isTemporary: true,
            latestUpdate: new Date().toISOString(),
          },
        ],
      };
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId, contactId },
      } = action;
      const contacts = state[projectId];
      if (!contacts) return state;
      return {
        ...state,
        [projectId]: contacts.filter(
          (projectContact: ExternalProjectContact) =>
            projectContact.contactId !== contactId
        ),
      };
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT: {
      const {
        meta: { projectId, contactId },
        payload: createdContact,
      } = action;
      const contacts = state[projectId];
      if (!contacts) return state;
      return {
        ...state,
        [projectId]: contacts.map((projectContact: ExternalProjectContact) =>
          projectContact.contactId === contactId
            ? createdContact
            : projectContact
        ),
      };
    }

    /** UPDATE */

    case UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        payload,
        meta: { projectId, externalProjectContactId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              projectContact.externalProjectContactId !==
              externalProjectContactId
          ),
          {
            ...(state[projectId]?.find(
              (projectContact) =>
                projectContact.externalProjectContactId ===
                externalProjectContactId
            ) ?? {}),
            ...payload,
            latestUpdate: new Date().toISOString(),
          },
        ],
      };
    }

    case UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT: {
      const {
        payload: updatedContact,
        meta: { projectId, externalProjectContactId },
      } = action;
      return {
        ...state,
        [projectId]: (state[projectId] ?? []).map((projectContact) =>
          projectContact.externalProjectContactId !== externalProjectContactId
            ? projectContact
            : updatedContact
        ),
      };
    }

    case UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId, externalProjectContactId },
        rollbackContact,
      } = action;
      if (!rollbackContact) return state;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              projectContact.externalProjectContactId !==
              externalProjectContactId
          ),
          {
            ...rollbackContact,
          },
        ],
      };
    }

    case ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId },
        rollbackContact,
      } = action;
      if (!rollbackContact) return state;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...rollbackContact,
          },
        ],
      };
    }

    case ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        meta: { projectId, externalProjectContactId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              projectContact.externalProjectContactId !==
              externalProjectContactId
          ),
        ],
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface ExternalProjectContactsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ExternalProjectContactsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage:
          'projects:errorMessages.fetchExternalProjectContactsError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ExternalProjectContactsState>({
  data,
  meta,
});

export const getData: (state: any) => DataState = (state: any) => state.data;
export const getAllExternalProjectContacts: (
  state: any,
  projectId: ProjectId
) => ExternalProjectContact[] = (state: any, projectId: ProjectId) =>
  state.data[projectId] ?? [];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
