import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import { useParams } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';
import NavigationBar from '../../../components/NavigationBar';
import { HRAbsenceMenu } from './HRAbsenceMenu';
import { HRAbsenceOpenProposalPage } from './HRAbsenceOpenProposalPage';
import { HRAbsenceAllProposalPage } from './HRAbsenceAllProposalPage';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { useExportAbsenceProposalsToCsv } from '../export';
import HRRemainingAbsencePage from './HRRemainingAbsencePage';
import { PrioTheme } from '../../../theme/types';
import HRHolidayPage from './HRHolidaysPage';
import AbsenceOverviewPage from './AbsenceOverviewPage';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    width: '100%',
    overflow: 'hidden',
  },
  overflow: {
    overflow: 'hidden',
  },
}));

interface HRAbsenceProps {
  className?: string;
  pathPrefix?: string;
  isOffice?: boolean;
}

export const HRAbsence: React.FC<HRAbsenceProps> = (props) => {
  const classes = useStyles();
  const { className, pathPrefix, isOffice = false } = props;
  const { selectedList, officeId } = useParams();
  const { t } = useTranslation();

  const [selectedProposals, setSelectedProposals] = useState<AbsenceProposal[]>(
    []
  );
  const [newHolidayButtonClicked, setNewHolidayButtonClicked] =
    useState<boolean>();

  const exportToCsv = useExportAbsenceProposalsToCsv();

  useEffect(() => {
    setSelectedProposals([]);
  }, [selectedList]);

  let component: React.ReactNode;
  switch (selectedList) {
    case 'overview':
      component = (
        <AbsenceOverviewPage
          className={classNames(classes.content)}
          officeId={officeId}
          context={officeId ? 'office' : 'global'}
        />
      );
      break;
    case 'openproposals':
      component = (
        <HRAbsenceOpenProposalPage
          className={classNames(classes.content)}
          onRowSelectionChange={setSelectedProposals}
          officeId={officeId}
        />
      );
      break;
    case 'allproposals':
      component = (
        <HRAbsenceAllProposalPage
          className={classNames(classes.content)}
          onRowSelectionChange={setSelectedProposals}
          officeId={officeId}
        />
      );
      break;
    case 'remainingabsence':
      component = (
        <HRRemainingAbsencePage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;
    case 'holidays':
      component = (
        <HRHolidayPage
          className={classNames(classes.content)}
          officeId={officeId}
          setNewHolidayButtonClicked={setNewHolidayButtonClicked}
          newHolidayButtonClicked={newHolidayButtonClicked}
        />
      );
      break;
    default:
  }

  const onExportToCsv = () => {
    exportToCsv(selectedProposals);
  };

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>
        {selectedList === 'openproposals' || selectedList === 'allproposals' ? (
          <Button
            disabled={!selectedProposals?.length}
            onClick={onExportToCsv}
            type="default"
            iconProp={['fal', 'file-csv']}
          >
            <span>{t('contacts:navigationBar.exportToCsv')}</span>
          </Button>
        ) : null}
        {selectedList === 'holidays' ? (
          <Button
            onClick={() => setNewHolidayButtonClicked(true)}
            iconProp={['fal', 'plus']}
          >
            <span>{t('absences:form.officeHolidays.newEntryButton')}</span>
          </Button>
        ) : null}
      </NavigationBar>
      <Flex.Row flex={1} className={classes.overflow}>
        <HRAbsenceMenu
          selectedList={selectedList}
          urlPrefix={pathPrefix}
          className={classes.menu}
          isOffice={isOffice}
        />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default HRAbsence;
