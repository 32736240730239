import React, { useState, useEffect, useCallback, useRef } from 'react';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import {
  ProjectId,
  DriveItemId,
  ConfigurationKeys,
  MessageId,
} from '../../../../models/Types';
import { notification, Typography, Dropdown, Menu } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  DriveItem,
  DriveItemDropTarget,
  FolderDriveItem,
} from '../../../../models/Drive';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import {
  apiCopyAs,
  apiCreateDriveFolder,
  apiDeleteDriveItem,
  apiDownloadDriveItem,
  apiFetchDocumentPrefix,
} from '../../api';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentFolderItem,
  getDriveItemIsFetching,
  getProject,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { driveItemDeleted, fetchDriveItemsSagaAction } from '../../actions';
import DriveItemBreadcrumb from '../DriveItemBreadcrumb/DriveItemBreadcrumb';
import { Project } from '../../../../models/Project';
import { DropTargetMonitor } from 'react-dnd';
import {
  DndDriveItemDto,
  DND_TYPE_DRIVE_ITEM_FILE,
  DND_TYPE_EMAIL,
  DND_TYPE_EMAIL_ATTACHMENT,
} from '../../../../dnd/types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewFolderModal from '../../../settings/components/NewFolderModal';
import { Configuration } from '../../../../models/Configuration';
import { apiFetchConfigurations } from '../../../settings/api';
import { urltoFile } from '../../../../util';
import DocumentsWidgetTable from './DocumentsWidgetTable';
import DroppableElement from '../../../../dnd/components/DroppableElement';
import { OnMailAttachmentsDrop } from '../DraggableDriveItem';
import {
  apiSaveAttachmentsToCloud,
  apiUploadAttachments,
} from '../../../mail/api';
import CopyDocumentsDrawer from '../CopyDocumentsDrawer';
import { setActiveProjectDocumentsWidget } from '../../../widgetArea/actions';
import {
  MessageAttachment,
  SaveAttachmentsToCloud,
} from '../../../../models/Message';
import RenameModal from '../Modals/RenameModal';
import DebouncedInputSearch from '../../../../components/DebouncedInputField/DebouncedInputSearch';
import { copyToClipboard } from '../../../../components/CopyableTextTile';
import * as ConfigValues from '../../../../util/configValues';
import { fetchDriveFavorites } from '../../actions/driveFavorites';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import UploadField, {
  PrioFile,
} from '../../../../components/Upload/UploadField';
import {
  sagaStartUploadFiles,
  sagaUploadFiles,
} from '../../sagas/watchUploadFiles';
import { addPrioFilesToUploadList } from '../../actions/uploadLists';
import { updateDriveItemPath } from '../../actions/lastOpenDriveItemFolder';
import { NewDocumentDrawer } from '../Drawers/NewDocumentDrawer';
import { isDriveItemFolder } from '../../util';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    '& .ant-upload': {
      height: '100%',
      width: '100%',
    },
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
  },
  tableRoot: {
    flex: 1,
    overflow: 'auto',
  },
  activeDropTarget: {
    border: theme.old.borders.dashedHighlighted,
    borderWidth: 2,
  },
  content: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  reloadButton: {
    background: 'transparent',
    color: theme.old.palette.primaryColor,
    marginRight: theme.old.spacing.unit(1),
  },
  flexBreadcrumb: {
    overflow: 'hidden',
  },
  flexButtonRow: {
    justifyContent: 'flex-end',
    marginRight: theme.old.spacing.unit(1),
  },
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
  tableNameColumn: {
    maxWidth: 230,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  row: {
    '& > td:nth-child(3) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(3) > button': {
      visibility: 'visible',
    },
  },
  tableContainer: {
    height: '100%',
  },
  searchInput: {
    width: '95%',
    margin: 10,
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  flexRow: {
    height: 32,
    alignItem: 'center',
    padding: theme.old.spacing.unit(0.5),
  },
  pointerOnHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface DocumentsWidgetProps {
  className?: string;
  projectId: ProjectId;
  initialDriveItemId?: DriveItemId;
  size?: number;
  onProjectIdChange?: (projectId: ProjectId) => void;
  onDriveItemIdChange?: (driveItemId: DriveItemId) => void;
}

export const DocumentsWidget = (props: DocumentsWidgetProps) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    projectId,
    className,
    initialDriveItemId: currentFolderDriveItemId,
    onProjectIdChange,
    onDriveItemIdChange,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region -------------------------------- Selectors

  //#endregion

  //#region -------------------------------- Refs
  const widgetBarIsDroppable = useRef<boolean>(false);
  //#endregion

  //#region -------------------------------- States
  const [openNewDocDrawer, setOpenNewDocDrawer] = useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>('');

  const [renameModalDriveItem, setRenameModalDriveItem] =
    useState<DriveItem>(null);

  const [copyDrawerDestinationProjectId, setCopyDrawerDestinationProjectId] =
    useState<ProjectId>(null);

  const [isKeywordSearch, setIsKeywordSearch] = useState<boolean>(false);

  const [searchDriveItemId, setSearchDriveItemId] = useState<DriveItemId>(null);

  //#endregion

  const activeProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const isRoot = !currentFolderDriveItemId;

  const groupId = activeProject?.groupId;

  const [isReloadingDocuments, setIsReloadingDocuments] =
    useState<boolean>(false);

  const [isSearchingDocuments, setIsSearchingDocuments] =
    useState<boolean>(false);

  const rootDriveItemFolderId = `root-group-${groupId}`;

  const currentFolderDriveItem = useSelector<RootReducerState, DriveItem>(
    (state) =>
      getCurrentFolderItem(
        state,
        searchDriveItemId ?? currentFolderDriveItemId ?? rootDriveItemFolderId
      )
  );

  const [copyDrawerDriveItems, setCopyDrawerDriveItems] = useState<DriveItem[]>(
    []
  );
  const [copyDocumentsDrawerState, setCopyDriveDrawerState] = useState<
    'none' | 'move' | 'copy'
  >('none');

  const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);

  const [newFolderCreating, setNewFolderCreating] = useState<boolean>(false);

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  const useDocumentsPrefix =
    configurations?.find(
      (config) =>
        config.key === ConfigurationKeys.USE_PREFIX_TO_SAVE_ATTACHMENT_TO_CLOUD
    )?.value === 'true';

  const [prefix, setPrefix] = useState<string>(null);

  const { isFetching } = useSelector<
    RootReducerState,
    { isFetching: boolean; isFetchingWithNextLink: boolean }
  >((state) =>
    getDriveItemIsFetching(
      state,
      isRoot ? rootDriveItemFolderId : currentFolderDriveItemId
    )
  );

  const rootFolderDriveItemId = useSelector<RootReducerState, DriveItem>(
    (state) => getCurrentFolderItem(state, rootDriveItemFolderId)
  )?.id;
  //#endregion

  //#region -------------------------------- Methods

  const onCreatedDocumentSuccess = (folder: DriveItem) => {
    setOpenNewDocDrawer(false);
    dispatch(
      updateDriveItemPath(projectId, {
        documentsWidget: folder.id,
      })
    );
  };

  const createFolder = useCallback(async () => {
    if (!!groupId) {
      setNewFolderModalOpen(true);
    }
  }, [groupId]);

  const closeRenameModal = () => {
    setRenameModalDriveItem(null);
  };

  const closeNewFolderModal = () => {
    setNewFolderModalOpen(false);
  };

  const disableDrag = (item, monitor, targetDropItem, isOverCurrent) => {
    if (!activeProject || isFetching) {
      return true;
    }
    const itemType = monitor.getItemType();

    switch (itemType) {
      case DND_TYPE_DRIVE_ITEM_FILE: {
        const draggedElement = monitor.getItem() as DndDriveItemDto;

        if (
          !targetDropItem?.driveItem?.id ||
          draggedElement.driveItems.some(
            (x) => x.id === targetDropItem.driveItem.id
          ) ||
          monitor.isOver({ shallow: true }) === false
        ) {
          return true;
        }
        return false;
      }
      case DND_TYPE_EMAIL: {
        return false;
      }
      case DND_TYPE_EMAIL_ATTACHMENT: {
        return false;
      }
    }
    return true;
  };

  const onSearchInputChange = (value: string) => {
    setSearchInput(value);
  };

  //#endregion

  //#region ------------------------------ Handlers
  const handleReloadDocuments = async () => {
    setIsReloadingDocuments(true);
    if (groupId) {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          currentFolderDriveItemId,
          250,
          isRoot,
          false,
          true
        )
      );
      setTimeout(() => {
        setIsReloadingDocuments(false);
      }, 5000);
    }
  };

  const handleSearchingDocuments = async () => {
    setIsSearchingDocuments(true);
  };

  const onCloseCopyDriveItemDrawer = () => {
    setCopyDriveDrawerState('none');
  };

  const onDriveItemClick = (driveItem: DriveItem) => {
    const isFolder = isDriveItemFolder(driveItem);

    if (isFolder) {
      dispatch(
        updateDriveItemPath(projectId, {
          documentsWidget: driveItem.id,
        })
      );
    }
  };

  const saveAttachmentsToCloud = async (
    targetDriveItemId: DriveItemId,
    messageId: MessageId,
    projectId: ProjectId,
    attachments: MessageAttachment[]
  ) => {
    if (!!currentFolderDriveItem?.id) {
      const data: SaveAttachmentsToCloud = {
        targetDriveItemId: targetDriveItemId ?? currentFolderDriveItem.id,
        groupId,
        attachments: attachments
          .filter(
            (attachment) =>
              attachment.contentBytes === '' ||
              attachment.contentBytes === null ||
              attachment.contentBytes === undefined
          )
          .map((attachment) => ({
            messageId,
            attachmentId: attachment.id,
            fileName: `${useDocumentsPrefix ? prefix : ''}${attachment.name}`,
          })),
      };

      const promises = attachments
        .filter(
          (attachment) =>
            attachment.contentBytes !== '' &&
            attachment.contentBytes !== null &&
            attachment.contentBytes !== undefined
        )
        .map((attachment) =>
          urltoFile(
            `data:${attachment.contentType};base64,${attachment.contentBytes}`,
            attachment.name,
            attachment.contentType
          ).then(async (file) => {
            const { result: uploadResult } = await apiUploadAttachments(
              file,
              projectId,
              groupId,
              targetDriveItemId ?? currentFolderDriveItem.id
            );
            if (!(uploadResult.status >= 200 && uploadResult.status < 300)) {
              return false;
            }
            return true;
          })
        );

      if (data.attachments.length > 0) {
        const { result } = await apiSaveAttachmentsToCloud(projectId, data);
        if (result.status >= 200 && result.status < 300) {
          onRefresh();
        } else {
          notification.open({
            message: t('common:error'),
            description: t(
              'mail:messageDisplay.errorMessages.saveAttachmentsToCloud'
            ),
          });
        }
      } else {
        Promise.all(promises).then((values) => {
          if (values.includes(false)) {
            notification.open({
              message: t('common:error'),
              description: t(
                'mail:messageDisplay.errorMessages.saveAttachmentsToCloud'
              ),
            });
          } else {
            // nothing to do
          }
          if (values.includes(true)) {
            onRefresh();
          }
        });
      }
    }
  };

  const onMailAttachmentsDrop: OnMailAttachmentsDrop = (
    attachment,
    targetDriveItem,
    groupId,
    projectId,
    selectedAttachments
  ) => {
    saveAttachmentsToCloud(
      targetDriveItem.id,
      attachment.messageId,
      projectId,
      selectedAttachments ?? [attachment.attachment]
    );
  };

  const onMoveItemsIntoProject: (
    driveItems: DriveItem[],
    destinationProject: Project,
    dropEffect: 'copy' | 'move'
  ) => void = (driveItems, destinationProject, dropEffect) => {
    setCopyDrawerDriveItems(driveItems);
    setCopyDriveDrawerState(dropEffect);
    setCopyDrawerDestinationProjectId(destinationProject.projectId);
  };

  const onRefresh = useCallback(() => {
    if (!!groupId) {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          currentFolderDriveItemId,
          250,
          isRoot,
          false,
          true
        )
      );
    }
  }, [dispatch, currentFolderDriveItemId, groupId, isRoot, projectId]);

  const onMenuRename = (driveItem: DriveItem) => {
    setRenameModalDriveItem(driveItem);
  };

  const onMenuMove = (driveItem: DriveItem) => {
    setCopyDriveDrawerState('move');
    setCopyDrawerDriveItems([driveItem]);
  };

  const onMenuCopy = (driveItem: DriveItem) => {
    setCopyDriveDrawerState('copy');
    setCopyDrawerDriveItems([driveItem]);
  };

  const onMenuDownload = async (driveItem: DriveItem) => {
    if (!(await apiDownloadDriveItem(groupId, driveItem))) {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.downloadFileError'),
      });
    }
  };

  const onOpenParentFolder = (driveItem: DriveItem) => {
    setSearchDriveItemId(null);
    setSearchInput('');
    setIsSearchingDocuments(false);

    if (driveItem?.parentReference?.id !== currentFolderDriveItemId) {
      if (driveItem?.parentReference?.id === rootFolderDriveItemId) {
        dispatch(
          updateDriveItemPath(projectId, {
            documentsWidget: null,
          })
        );
      } else {
        dispatch(
          updateDriveItemPath(projectId, {
            documentsWidget: driveItem.parentReference.id,
          })
        );
      }
    }
  };

  const onMenuDelete = (driveItem: DriveItem) => {
    Modal.confirm({
      icon: null,
      title: t('documents:confirmation.delete.title'),
      content: (
        <Flex.Column>
          <Typography.Text>
            {t('documents:confirmation.delete.content')}
          </Typography.Text>
          <ul>
            <li>
              <Typography.Text>{driveItem.name}</Typography.Text>
            </li>
          </ul>
        </Flex.Column>
      ),
      okText: t('documents:confirmation.delete.okText'),
      cancelText: t('documents:confirmation.delete.cancelText'),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          const { result } = await apiDeleteDriveItem(groupId, driveItem.id);
          if (result.status >= 200 && result.status < 300) {
            dispatch(
              driveItemDeleted(driveItem.id, currentFolderDriveItemId, groupId)
            );
          } else {
            notification.open({
              message: t('common:error'),
              description: t('documents:errorMessages.deleteDocumentError'),
            });
          }

          resolve();
        });
        return promise;
      },
      onCancel() {},
    });
  };

  const onDndMove = (driveItem: DriveItem) => {};

  const onDndCopy = (driveItem: DriveItem) => {};

  const onNewFolderOk = async (trimmedValue: string) => {
    if (trimmedValue === '') {
      closeNewFolderModal();
      return;
    }
    setNewFolderCreating(true);
    const { data } = await apiCreateDriveFolder(
      groupId,
      currentFolderDriveItem?.id,
      trimmedValue
    );
    setNewFolderCreating(false);
    if (data) {
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          currentFolderDriveItem?.id,
          250,
          currentFolderDriveItem === null,
          false,
          true
        )
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.newFolderCreateError'),
      });
    }
    closeNewFolderModal();
  };

  const onNewFolderCancel = () => {
    closeNewFolderModal();
  };

  const onDriveItemBreadCrumbClick = (driveItemId: any) => {
    dispatch(
      updateDriveItemPath(projectId, {
        documentsWidget: driveItemId,
      })
    );
    setIsSearchingDocuments(false);
    setSearchInput(null);
  };

  const onRowOver = (isOver: boolean) => {
    widgetBarIsDroppable.current = !isOver;
  };

  const onCopyAsPdf = async (driveItem: DriveItem) => {
    await apiCopyAs(projectId, driveItem.id, 'pdf');
  };

  const onDropIntoDocumentsWidget = (
    item: any,
    monitor: DropTargetMonitor<unknown, unknown>,
    target: FolderDriveItem
  ) => {
    if (monitor.didDrop()) {
      return undefined;
    }
    const itemType = monitor.getItemType();

    switch (itemType) {
      case DND_TYPE_DRIVE_ITEM_FILE: {
        return {
          type: 'driveItem',
          object: target,
        } as DriveItemDropTarget;
      }
      case DND_TYPE_EMAIL_ATTACHMENT: {
        if (currentFolderDriveItem) {
          onMailAttachmentsDrop(
            item.draggableMessageAttachment,
            currentFolderDriveItem,
            groupId,
            item.projectId,
            item.selectedAttachments ?? undefined
          );
        }
        return undefined;
      }
      case DND_TYPE_EMAIL: {
        return {
          ...item,
          droppedInWidgetDriveFolder: true,
          driveItemId: target.driveItem?.id,
          groupId,
        };
      }
    }
    return undefined;
  };

  const handleOnBeforeUpload = (originFiles: PrioFile[], sessionId: string) => {
    dispatch(
      sagaStartUploadFiles(
        groupId,
        sessionId,
        currentFolderDriveItemId ?? rootDriveItemFolderId,
        projectId
      )
    );
  };

  const handleOnAfterUpload = async (
    fileList: PrioFile[],
    sessionId: string
  ) => {
    dispatch(
      addPrioFilesToUploadList(
        groupId,
        fileList.map(
          ({ fileId, name, webkitRelativePath, size, type, sessionId }) => ({
            fileId,
            sessionId,
            parentDriveItemId:
              currentFolderDriveItemId ?? rootDriveItemFolderId,
            name,
            path: webkitRelativePath,
            size,
            isFolder: false,
            mimeType: type,
            driveItemId: null,
          })
        )
      )
    );
    dispatch(
      sagaUploadFiles(
        groupId,
        sessionId,
        currentFolderDriveItemId ?? rootDriveItemFolderId,
        projectId,
        fileList
      )
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (projectId !== 'me') {
      dispatch(fetchDriveFavorites(projectId));
    }
    return () => {};
  }, [dispatch, projectId]);

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);

  useEffect(() => {
    async function fetchPrefix() {
      const { data } = await apiFetchDocumentPrefix(projectId);
      if (data) {
        setPrefix(data.value);
      }
    }

    if (useDocumentsPrefix && projectId) {
      fetchPrefix();
    }
  }, [useDocumentsPrefix, projectId]);

  useEffect(() => {
    dispatch(setActiveProjectDocumentsWidget(projectId));
  }, [projectId, dispatch]);

  useEffect(() => {
    if (onDriveItemIdChange) {
      if (searchDriveItemId !== null) {
        onDriveItemIdChange(searchDriveItemId);
        dispatch(
          updateDriveItemPath(projectId, {
            documentsWidget: searchDriveItemId,
          })
        );

        setIsSearchingDocuments(false);
        setSearchInput(null);
      } else {
        onDriveItemIdChange(currentFolderDriveItemId);
      }
    }
  }, [
    currentFolderDriveItemId,
    searchDriveItemId,
    onDriveItemIdChange,
    dispatch,
    projectId,
  ]);
  //#endregion

  return (
    <>
      <UploadField
        className={classNames(classes.root, className)}
        onBeforeUpload={handleOnBeforeUpload}
        onAfterUpload={handleOnAfterUpload}
        multiple
        directory
      >
        <DroppableElement
          activeOverlay
          accept={[
            DND_TYPE_DRIVE_ITEM_FILE,
            DND_TYPE_EMAIL,
            DND_TYPE_EMAIL_ATTACHMENT,
          ]}
          onDrop={onDropIntoDocumentsWidget}
          object={
            {
              projectId: activeProject?.projectId,
              groupId: groupId,
              driveItem: currentFolderDriveItem,
            } as FolderDriveItem
          }
          disable={disableDrag}
        >
          <Flex.Column className={classNames(classes.root, className)}>
            <div className={classes.content}>
              {!isSearchingDocuments ? (
                <Flex.Row alignItems="baseline" className={classes.flexRow}>
                  <Flex.Item flex={1} className={classes.flexBreadcrumb}>
                    <DriveItemBreadcrumb
                      driveItem={currentFolderDriveItem}
                      project={activeProject}
                      onItemClick={onDriveItemBreadCrumbClick}
                      onRootChange={(projectId) => {
                        if (onProjectIdChange) {
                          onProjectIdChange(projectId);
                        }
                      }}
                      disableLinkNavigation
                      addProjectList
                    />
                  </Flex.Item>
                  {projectId && groupId && (
                    <Flex.Item>
                      <Button
                        style={{
                          marginRight: 4,
                        }}
                        type="default"
                        iconProp={['fal', 'plus']}
                        onClick={() => setOpenNewDocDrawer(true)}
                      ></Button>
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="search"
                              onClick={handleSearchingDocuments}
                              disabled={isSearchingDocuments}
                              icon={
                                <FontAwesomeIcon icon={['fal', 'search']} />
                              }
                            >
                              {t('documents:widget.header.actions.search')}
                            </Menu.Item>
                            <Menu.Item
                              key="reload"
                              onClick={handleReloadDocuments}
                              disabled={isReloadingDocuments}
                              icon={<FontAwesomeIcon icon={['fal', 'redo']} />}
                            >
                              {t('documents:widget.header.actions.reload')}
                            </Menu.Item>
                            <Menu.Item
                              key="createFolder"
                              disabled={currentFolderDriveItem?.name === 'root'}
                              onClick={createFolder}
                              icon={
                                <FontAwesomeIcon
                                  icon={['fal', 'folder-plus']}
                                />
                              }
                            >
                              {t(
                                'documents:widget.header.actions.createFolder'
                              )}
                            </Menu.Item>
                            <Menu.Item
                              key="copyPrioLink"
                              icon={<FontAwesomeIcon icon={['fal', 'link']} />}
                              onClick={() =>
                                copyToClipboard(
                                  `${
                                    ConfigValues.REACT_APP_API_REDIRECT_URL
                                  }module/prio/projects/${projectId}/documents/${
                                    currentFolderDriveItem?.name === 'root'
                                      ? 'all'
                                      : `folder/${currentFolderDriveItem?.id}`
                                  }`
                                )
                              }
                            >
                              {t('documents:widget.header.actions.copyPrioUrl')}
                            </Menu.Item>
                            <Menu.Item
                              key="copySharepointLink"
                              icon={<FontAwesomeIcon icon={['fal', 'link']} />}
                              onClick={() =>
                                copyToClipboard(
                                  currentFolderDriveItem?.webDavUrl
                                )
                              }
                            >
                              {t(
                                'documents:widget.header.actions.copySharepointUrl'
                              )}
                            </Menu.Item>
                          </Menu>
                        }
                        placement="bottomRight"
                      >
                        <Button
                          type="default"
                          iconProp={['fal', 'ellipsis-v']}
                        />
                      </Dropdown>
                    </Flex.Item>
                  )}
                </Flex.Row>
              ) : (
                <>
                  <DebouncedInputSearch
                    size="small"
                    placeholder={t(
                      'documents:search.placeholderWithProjectNumber',
                      {
                        projectNumber: activeProject.number,
                      }
                    )}
                    onChange={onSearchInputChange}
                    className={classes.searchInput}
                    value={searchInput}
                    autoFocus={true}
                    prefix={null}
                    addonAfter={
                      !isReloadingDocuments ? (
                        <FontAwesomeIcon
                          icon={['fal', 'redo']}
                          onClick={handleReloadDocuments}
                          className={classes.pointerOnHover}
                        />
                      ) : (
                        <PrioSpinner size="small" alignSelf></PrioSpinner>
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        setIsSearchingDocuments(false);
                        setSearchInput(null);
                      }
                    }}
                    suffix={
                      <FontAwesomeIcon
                        icon={['fas', 'times-circle']}
                        onClick={() => {
                          setSearchInput(null);
                          setIsSearchingDocuments(false);
                        }}
                        color={theme.old.typography.colors.muted}
                      />
                    }
                  />
                  {!isKeywordSearch && searchInput !== '' && (
                    <Flex.Row alignItems="baseline" className={classes.flexRow}>
                      <Flex.Item flex={1} className={classes.flexBreadcrumb}>
                        <DriveItemBreadcrumb
                          driveItem={currentFolderDriveItem}
                          project={activeProject}
                          onItemClick={onDriveItemBreadCrumbClick}
                          onRootChange={(projectId) => {
                            if (onProjectIdChange) {
                              onProjectIdChange(projectId);
                            }
                          }}
                          disableLinkNavigation
                          addProjectList
                        />
                      </Flex.Item>
                    </Flex.Row>
                  )}
                </>
              )}
              {projectId && groupId && (
                <>
                  <DocumentsWidgetTable
                    activeProjectId={activeProject.projectId}
                    groupId={groupId}
                    currentFolderDriveItemId={currentFolderDriveItemId}
                    onMenuDelete={onMenuDelete}
                    onMenuDownload={onMenuDownload}
                    onMenuRename={onMenuRename}
                    onMenuMove={onMenuMove}
                    onMenuCopy={onMenuCopy}
                    onDndCopy={onDndCopy}
                    onDndMove={onDndMove}
                    onRowOver={onRowOver}
                    onCopyAsPdf={onCopyAsPdf}
                    onDriveItemClick={onDriveItemClick}
                    onMoveItemsIntoProject={onMoveItemsIntoProject}
                    onMailAttachmentsDrop={onMailAttachmentsDrop}
                    onOpenParentFolder={onOpenParentFolder}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    setIsKeywordSearch={setIsKeywordSearch}
                    isKeywordSearch={isKeywordSearch}
                    isSearchingDocuments={isSearchingDocuments}
                    setSearchDriveItemId={setSearchDriveItemId}
                    searchDriveItemId={searchDriveItemId}
                    setIsSearchingDocuments={setIsSearchingDocuments}
                    setProjectIdFromUrl={(projectId) => {
                      if (onProjectIdChange) {
                        onProjectIdChange(projectId);
                      }
                    }}
                  />
                </>
              )}
            </div>
          </Flex.Column>
        </DroppableElement>
      </UploadField>
      {projectId && groupId && (
        <>
          <RenameModal
            renameDriveItem={renameModalDriveItem}
            folderDriveItemId={currentFolderDriveItemId}
            groupId={groupId}
            onClose={closeRenameModal}
          />
          <NewFolderModal
            currentPath={[
              currentFolderDriveItem?.name === 'root'
                ? t('documents:rootFolder')
                : currentFolderDriveItem?.name ?? '',
            ]}
            visible={
              newFolderModalOpen && currentFolderDriveItem?.name !== 'root'
            }
            onOk={onNewFolderOk}
            onCancel={onNewFolderCancel}
            confirmLoading={newFolderCreating}
          />
          {copyDocumentsDrawerState !== 'none' && (
            <CopyDocumentsDrawer
              drawerState={copyDocumentsDrawerState}
              sourceFolderId={currentFolderDriveItemId}
              sourceProjectId={projectId}
              selectedDriveItems={copyDrawerDriveItems}
              destinationProjectId={copyDrawerDestinationProjectId}
              onClose={onCloseCopyDriveItemDrawer}
            />
          )}
        </>
      )}
      <NewDocumentDrawer
        visible={openNewDocDrawer}
        projectId={projectId}
        groupId={groupId}
        onCancel={() => setOpenNewDocDrawer(false)}
        onSuccess={onCreatedDocumentSuccess}
        driveItem={currentFolderDriveItem}
      />
    </>
  );
};

export default DocumentsWidget;
