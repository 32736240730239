import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  getActiveMailFolderId,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import { MailFolderId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    width: 18,
    marginRight: '15px',
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
  menuCollapsed: {
    transition: 'padding 0.2s',
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
    },
  },
  iconExternalLink: {
    opacity: 0,
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
    '-webkit-transition': 'all 0.3s ease-out',
    transition: 'all 0.3s ease-out',
  },
  menuItemCalendar: {
    '&:hover .prio-project-menu-external-link': {
      opacity: 1,
    },
  },
  itemCollapsed: {
    '&.ant-menu-item > .ant-menu-title-content': {
      overflow: 'hidden',
    },
  },
}));

interface ProjectProfileMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
  collapsed?: boolean;
  hidetimeRecordsAndAbsences?: boolean;
  hideOverview?: boolean;
  hideTrainings?: boolean;
  hidePersonalFile?: boolean;
  hideMessages?: boolean;
  hideDocuments?: boolean;
}

export const ProjectProfileMenu: React.FC<ProjectProfileMenuProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    selectedList,
    urlPrefix,
    collapsed,
    hideMessages,
    hidePersonalFile,
    hideTrainings,
    // hideOverview,
    hidetimeRecordsAndAbsences,
    hideDocuments,
    className,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentUser = useSelector(getUserMe);
  const activeMailFolderId = useSelector<RootReducerState, MailFolderId>(
    (state) => getActiveMailFolderId(state, 'me')
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Menu
      className={classNames(classes.menu, className, {
        [classes.menuCollapsed]: collapsed,
      })}
      selectedKeys={[selectedList]}
      mode="inline"
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {navItem(
        'dashboard',
        'tachometer-alt',
        t,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
      {!hideMessages &&
        navItem(
          'mail',
          'envelope',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed,
          activeMailFolderId
        )}
      {!hideMessages &&
        navItem(
          'messageCenter',
          'envelopes',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {currentUser?.mail && (
        <Menu.Item
          key={'calendar'}
          className={classNames(classes.menuItemCalendar, {
            [classes.itemCollapsed]: collapsed,
          })}
          title={
            <>
              <FontAwesomeIcon
                fixedWidth
                icon={['fal', 'calendar-alt']}
                className={classes.icon}
              />
              <Typography.Text className={classes.navItemText}>
                {t('profile:projectsProfileNavigation.calendar')}
              </Typography.Text>
            </>
          }
        >
          <a
            href={`https://outlook.office.com/calendar/${currentUser.mail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex.Row>
              <Flex.Item flex={1}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'calendar']}
                  className={classes.icon}
                />
                {!collapsed && (
                  <Typography.Text className={classes.navItemText}>
                    {t('profile:projectsProfileNavigation.calendar')}
                  </Typography.Text>
                )}
              </Flex.Item>
              <Flex.Item>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'external-link-alt']}
                  className={classNames(
                    'prio-project-menu-external-link',
                    classes.iconExternalLink
                  )}
                />
              </Flex.Item>
            </Flex.Row>
          </a>
        </Menu.Item>
      )}
      {!hidetimeRecordsAndAbsences &&
        navItem(
          'timeAndLeaveManagement',
          'calendar-range',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {!hideDocuments &&
        navItem(
          'remoteFolders',
          'folder-tree',
          t,
          classes,
          `${urlPrefix}`,
          undefined,
          collapsed
        )}
      {!hideTrainings &&
        navItem(
          'trainings',
          'university',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {!hidePersonalFile &&
        navItem(
          'personalFile',
          'address-card',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
    </Menu>
  );
};

export default ProjectProfileMenu;

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string,
  collapsed?: boolean,
  urlSuffix?: string
) => (
  <Menu.Item
    key={itemKey}
    title={
      <>
        {icon != null ? (
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', icon]}
            className={classes.icon}
          />
        ) : (
          <span className={classes.settingsIcon} />
        )}
        <Typography.Text className={classes.navItemText}>
          {t(`profile:projectsProfileNavigation.${itemKey}`)}
        </Typography.Text>
      </>
    }
  >
    <Link
      to={
        customUrl ??
        `${urlPrefix ?? ''}${itemKey}${urlSuffix ? `/${urlSuffix}` : ''}`
      }
    >
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {!collapsed && (
        <Typography.Text className={classes.navItemText}>
          {t(`profile:projectsProfileNavigation.${itemKey}`)}
        </Typography.Text>
      )}
    </Link>
  </Menu.Item>
);
