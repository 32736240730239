import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAccessRights from '../users/hooks/useAccessRights';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLastAccountingLocation,
  getLastContactsLocation,
  getLastHRLocation,
  getLastProfileLocation,
  getLastProjectLocation,
  getLastSettingsLocation,
} from '../../apps/main/rootReducer';
import { setLastProjectLocation } from '../projects/actions';
import { setLastContactLocation } from '../contacts/actions';
import { setLastAccountingLocation } from '../controlling/actions';
import { setLastHRLocation } from '../hr/actions';
import { setLastSettingsLocation } from '../settings/actions/lastSettingsLocation';
import { setLastProfileLocation } from '../profile/actions';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';

const useStyles = makePrioStyles({
  root: {
    '&.ant-menu': {
      border: 'none',
    },
    '&.ant-menu-root.ant-menu-vertical': {
      boxShadow:
        '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    },
  },
  rootSubMenu: {
    '&.ant-menu': {
      border: 'none',
    },
    '&.ant-menu-root.ant-menu-vertical': {
      boxShadow: 'none',
    },
  },
});

interface MenuMenuProps {
  className?: string;
  closeDropDown?: VoidFunction;
  selectedEntry?: string;
  isSubMenu?: boolean;
}

export const MainMenu: React.FC<MenuMenuProps> = (props) => {
  const classes = useStyles();
  const { closeDropDown, selectedEntry, isSubMenu, className } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lastProjectLocation = useSelector(getLastProjectLocation);
  const lastContactsLocation = useSelector(getLastContactsLocation);
  const lastAccountingLocation = useSelector(getLastAccountingLocation);
  const lastHRLocation = useSelector(getLastHRLocation);
  const lastSettingsLocation = useSelector(getLastSettingsLocation);
  const lastProfileLocation = useSelector(getLastProfileLocation);

  const setLastSession = (path: string) => {
    switch (true) {
      case /^.*\/projects.*?$/.test(path):
        const isInDocuments = path.includes('documents');
        const isMe = path.includes('module/prio/projects/me');
        if (isInDocuments && !isMe) {
          const newPath = `${path.split('/documents')[0]}/documents`;
          dispatch(setLastProjectLocation(newPath));
        } else {
          dispatch(setLastProjectLocation(path));
        }
        break;
      case /^.*\/prio\/contacts.*?$/.test(path):
        dispatch(setLastContactLocation(path));
        break;
      case /^.*\/prio\/controlling.*?$/.test(path):
        dispatch(setLastAccountingLocation(path));
        break;
      case /^.*\/prio\/hr.*?$/.test(path):
        dispatch(setLastHRLocation(path));
        break;
      case /^.*\/prio\/settings.*?$/.test(path):
        dispatch(setLastSettingsLocation(path));
        break;
      case /^.*\/prio\/profile.*?$/.test(path):
        dispatch(setLastProfileLocation(path));
        break;
      default:
        break;
    }
  };

  const {
    showControllingModule,
    showHrModule,
    showPrioSettings,
    showProjectModule,
    showContactsModule,
  } = useAccessRights([
    'showControllingModule',
    'showHrModule',
    'showPrioSettings',
    'showUserProfile',
    'showProjectModule',
    'showContactsModule',
  ]);

  return (
    <Menu
      className={classNames(classes.root, className, {
        [classes.rootSubMenu]: isSubMenu,
      })}
      selectedKeys={[selectedEntry]}
      id={'prio-main-menu'}
    >
      {showProjectModule && (
        <Menu.Item key={'projects'}>
          <Link
            to={lastProjectLocation ?? '/module/prio/projects'}
            onClick={() => {
              setLastSession(window.location.pathname);
              closeDropDown();
            }}
          >
            {t('common:links.projects')}
          </Link>
        </Menu.Item>
      )}
      {showContactsModule && (
        <Menu.Item key={'contacts'}>
          <Link
            to={lastContactsLocation ?? '/module/prio/contacts'}
            onClick={() => {
              setLastSession(window.location.pathname);
              closeDropDown();
            }}
          >
            {t('common:links.contacts')}
          </Link>
        </Menu.Item>
      )}
      {showContactsModule && (
        <Menu.Item key={'profile'}>
          <Link
            to={lastProfileLocation ?? '/module/prio/profile'}
            onClick={() => {
              setLastSession(window.location.pathname);
              closeDropDown();
            }}
          >
            {t('common:links.profile')}
          </Link>
        </Menu.Item>
      )}
      {showControllingModule && (
        <Menu.Item key={'controlling'}>
          <Link
            to={lastAccountingLocation ?? '/module/prio/controlling'}
            onClick={() => {
              setLastSession(window.location.pathname);
              closeDropDown();
            }}
          >
            {t('common:links.controlling')}
          </Link>
        </Menu.Item>
      )}
      {showHrModule && (
        <Menu.Item key={'hr'}>
          <Link
            to={lastHRLocation ?? '/module/prio/hr'}
            onClick={() => {
              setLastSession(window.location.pathname);
              closeDropDown();
            }}
          >
            {t('common:links.hr')}
          </Link>
        </Menu.Item>
      )}
      {showPrioSettings && (
        <Menu.Item key={'settings'}>
          <Link
            to={lastSettingsLocation ?? '/module/prio/settings'}
            onClick={() => {
              setLastSession(window.location.pathname);
              closeDropDown();
            }}
          >
            {t('common:links.settings')}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};
