import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import { notification, Dropdown, Menu } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setDocumentsMetaState } from '../actions/meta';
import { apiCreateDriveFolder } from '../api';
import {
  getCurrentFolderItem,
  getDocumentsMetaAreMultipleSelected,
  getProject,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { DriveItem } from '../../../models/Drive';
import { driveItemNewFolderCreated } from '../actions';
import useResizeObserver from 'use-resize-observer';
import useDebounce from '../../../hooks/useDebounce';
import NewFolderModal from '../../settings/components/NewFolderModal';
import { makePrioStyles } from '../../../theme/utils';
import UploadField, {
  PrioFile,
  UploadFieldProps,
} from '../../../components/Upload/UploadField';
import {
  sagaStartUploadFiles,
  sagaUploadFiles,
} from '../sagas/watchUploadFiles';
import { addPrioFilesToUploadList } from '../actions/uploadLists';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const AVERAGE_BUTTON_WIDTH = 115;
const FULL_NAVIGATION_BAR_WIDTH = 920;

const useStyles = makePrioStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
  },
}));

interface RemoteFolderNavigationBarProps {
  pathPrefix?: string;
}

export const RemoteFolderNavigationBar: React.FC<
  RemoteFolderNavigationBarProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { pathPrefix } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectId: activeProjectId, driveItemId } = useParams();
  const location = useLocation();

  const { ref: root, width: navigationBarWidth } = useResizeObserver();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const debouncedNavigationWidth = useDebounce(navigationBarWidth, 250);
  const groupId = useSelector<RootReducerState, string>(
    (state) => getProject(state, activeProjectId)?.groupId
  );

  const rootFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, `root-group-${groupId}`)
  );
  const areMultipleSelected = useSelector(getDocumentsMetaAreMultipleSelected);

  const currentFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, driveItemId ?? `root-group-${groupId}`)
  );

  const isRoot =
    currentFolder?.name === 'root' || rootFolder?.id === currentFolder?.id;

  const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);
  const [newFolderCreating, setNewFolderCreating] = useState<boolean>(false);

  const uploadProps: UploadFieldProps = useMemo(
    () => ({
      isButton: true,
      multiple: true,
      onBeforeUpload: (originFiles: PrioFile[], sessionId: string) => {
        dispatch(
          sagaStartUploadFiles(
            groupId,
            sessionId,
            driveItemId ?? `root-group-${groupId}`,
            activeProjectId
          )
        );
      },
      onAfterUpload: async (fileList: PrioFile[], sessionId: string) => {
        dispatch(
          addPrioFilesToUploadList(
            groupId,
            fileList.map(
              ({
                fileId,
                name,
                webkitRelativePath,
                size,
                type,
                sessionId,
              }) => ({
                fileId,
                sessionId,
                parentDriveItemId: driveItemId ?? `root-group-${groupId}`,
                name,
                path: webkitRelativePath,
                size,
                isFolder: false,
                mimeType: type,
                driveItemId: null,
              })
            )
          )
        );
        dispatch(
          sagaUploadFiles(
            groupId,
            sessionId,
            driveItemId ?? `root-group-${groupId}`,
            activeProjectId,
            fileList
          )
        );
      },
    }),
    [driveItemId, groupId, activeProjectId, dispatch]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const createFolder = useCallback(async () => {
    if (!!groupId) {
      setNewFolderModalOpen(true);
    }
  }, [groupId]);

  const showMoveDrawer = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'move',
      })
    );
  }, [dispatch]);

  const showCopyDrawer = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'copy',
      })
    );
  }, [dispatch]);

  const showDeleteModal = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        showDeleteDocumentsModal: true,
      })
    );
  }, [dispatch]);

  const handleMenuClick = useCallback(
    ({ key }) => {
      const itemType = key as
        | 'move'
        | 'copy'
        | 'delete'
        | 'sharepoint'
        | 'upload';
      switch (itemType) {
        case 'move': {
          showMoveDrawer();
          break;
        }
        case 'copy': {
          showCopyDrawer();
          break;
        }
        case 'delete': {
          showDeleteModal();
          break;
        }
        case 'sharepoint': {
          if (currentFolder?.webUrl) {
            window.open(currentFolder.webUrl, '_blank');
          }
          break;
        }
        default:
          break;
      }
    },
    [currentFolder, showMoveDrawer, showCopyDrawer, showDeleteModal]
  );

  const onNewFolderOk = async (trimmedValue: string) => {
    if (trimmedValue === '') {
      closeNewFolderModal();
      return;
    }
    setNewFolderCreating(true);
    const { data } = await apiCreateDriveFolder(
      groupId,
      driveItemId ?? `root-group-${groupId}`,
      trimmedValue
    );
    setNewFolderCreating(false);
    if (data) {
      dispatch(
        driveItemNewFolderCreated(
          data,
          driveItemId ?? `root-group-${groupId}`,
          groupId
        )
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.newFolderCreateError'),
      });
    }
    closeNewFolderModal();
  };

  const onNewFolderCancel = () => {
    closeNewFolderModal();
  };

  const closeNewFolderModal = () => {
    setNewFolderModalOpen(false);
  };
  //#endregion

  //#region ------------------------------ Components
  const dropdown = useMemo(
    () => (
      <Dropdown
        trigger={['click']}
        placement={'bottomRight'}
        overlay={
          <Menu onClick={handleMenuClick}>
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 4 * AVERAGE_BUTTON_WIDTH &&
              currentFolder?.webUrl && (
                <Menu.Item
                  key="sharepoint"
                  icon={<FontAwesomeIcon icon={['fal', 'external-link-alt']} />}
                >
                  {t('documents:navigationBar.openInSharePoint')}
                </Menu.Item>
              )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 3 * AVERAGE_BUTTON_WIDTH && (
              <Menu.Item
                key="upload"
                icon={<FontAwesomeIcon icon={['fal', 'arrow-to-top']} />}
              >
                <UploadField {...uploadProps}>
                  {t('documents:navigationBar.upload')}
                </UploadField>
              </Menu.Item>
            )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 2 * AVERAGE_BUTTON_WIDTH && (
              <Menu.Item
                key="move"
                disabled={!areMultipleSelected}
                icon={<FontAwesomeIcon icon={['fal', 'file-export']} />}
              >
                {t('documents:navigationBar.move')}
              </Menu.Item>
            )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - AVERAGE_BUTTON_WIDTH && (
              <Menu.Item
                key="copy"
                disabled={!areMultipleSelected}
                icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
              >
                {t('documents:navigationBar.copy')}
              </Menu.Item>
            )}
            {debouncedNavigationWidth < FULL_NAVIGATION_BAR_WIDTH && (
              <Menu.Item
                key="delete"
                disabled={
                  !areMultipleSelected || !!location.pathname.match(/(all)$/)
                }
                icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
              >
                {t('documents:navigationBar.delete')}
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Button iconProp={['fal', 'ellipsis-h']} />
      </Dropdown>
    ),
    [
      debouncedNavigationWidth,
      currentFolder,
      location,
      areMultipleSelected,
      uploadProps,
      handleMenuClick,
      t,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Routes>
      <Route
        path="*"
        element={
          <>
            <div className={classes.root} ref={root}>
              <Link to={`${pathPrefix ?? ''}new`}>
                <Button iconProp={['fal', 'file']} disabled={!currentFolder}>
                  <span>{t('documents:navigationBar.createDocument')}</span>
                </Button>
              </Link>
              <Button
                onClick={createFolder}
                disabled={isRoot || !currentFolder}
                iconProp={['fal', 'folder-plus']}
              >
                <span>{t('documents:navigationBar.createFolder')}</span>
              </Button>
              {currentFolder?.webUrl &&
                debouncedNavigationWidth >=
                  FULL_NAVIGATION_BAR_WIDTH - 4 * AVERAGE_BUTTON_WIDTH && (
                  <Button
                    href={currentFolder.webUrl}
                    iconProp={['fal', 'external-link-alt']}
                  >
                    <span>{t('documents:navigationBar.openInSharePoint')}</span>
                  </Button>
                )}
              {debouncedNavigationWidth >=
                FULL_NAVIGATION_BAR_WIDTH - 3 * AVERAGE_BUTTON_WIDTH && (
                <UploadField {...uploadProps}>
                  <Button
                    disabled={!currentFolder}
                    iconProp={['fal', 'arrow-to-top']}
                  >
                    {t('documents:navigationBar.upload')}
                  </Button>
                </UploadField>
              )}
              {debouncedNavigationWidth >=
                FULL_NAVIGATION_BAR_WIDTH - 2 * AVERAGE_BUTTON_WIDTH && (
                <Button
                  disabled={!areMultipleSelected || !currentFolder}
                  iconProp={['fal', 'file-export']}
                  onClick={showMoveDrawer}
                >
                  {t('documents:navigationBar.move')}
                </Button>
              )}
              {debouncedNavigationWidth >=
                FULL_NAVIGATION_BAR_WIDTH - AVERAGE_BUTTON_WIDTH && (
                <Button
                  disabled={!areMultipleSelected || !currentFolder}
                  iconProp={['fal', 'copy']}
                  onClick={showCopyDrawer}
                >
                  {t('documents:navigationBar.copy')}
                </Button>
              )}
              {debouncedNavigationWidth >= FULL_NAVIGATION_BAR_WIDTH && (
                <Button
                  disabled={!areMultipleSelected || isRoot || !currentFolder}
                  iconProp={['fal', 'trash']}
                  onClick={showDeleteModal}
                >
                  {t('documents:navigationBar.delete')}
                </Button>
              )}
              {debouncedNavigationWidth < FULL_NAVIGATION_BAR_WIDTH && dropdown}
            </div>
            <NewFolderModal
              currentPath={[
                isRoot ? t('documents:rootFolder') : currentFolder?.name ?? '',
              ]}
              visible={newFolderModalOpen && !isRoot}
              onOk={onNewFolderOk}
              onCancel={onNewFolderCancel}
              confirmLoading={newFolderCreating}
            />
          </>
        }
      />
    </Routes>
  );
};

export default RemoteFolderNavigationBar;
