import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOfficeMeAllOffices } from '../../../apps/main/rootReducer';
import { Office } from '../../../models/Office';
import equals from 'deep-equal';

const useOfficeMeAllOffices = () => {
  const [offices, setOffices] = useState<Office[]>([]);
  const officesMeRedux = useSelector(getOfficeMeAllOffices);

  useEffect(() => {
    if (
      !equals(
        offices.map(({ officeId }) => officeId),
        officesMeRedux.map(({ officeId }) => officeId)
      )
    ) {
      setOffices(officesMeRedux);
    }
  }, [officesMeRedux, offices]);

  return offices;
};

export default useOfficeMeAllOffices;
