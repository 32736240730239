import React, { useState, useCallback, useEffect, useMemo } from 'react';

import HourlyRatesTable from './HourlyRatesTable';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectId } from '../../../models/Types';
import { HourlyRate } from '../../../models/HourlyRate';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllHourlyRates,
  getHourlyRatesIsFetching,
  getKilometerRate,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import KilometerRatesInput from './KilometerRateInput';
import { KilometerRate } from '../../../models/KilometerRate';
import {
  updateKilometerRate,
  updateHourlyRates,
  fetchHourlyRates,
  fetchKilometerRate,
} from '../actions';
import { useTranslation } from 'react-i18next';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  spin: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));

interface HourlyRatesPageProps {
  projectId: ProjectId;
}

export const HourlyRatesPage: React.FC<HourlyRatesPageProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { projectId } = props;
  const { t } = useTranslation();
  const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
    getAllHourlyRates(state, projectId)
  );

  const isHourlyRatesFetching = useSelector(getHourlyRatesIsFetching);

  const kilometerRate = useSelector<RootReducerState, KilometerRate>((state) =>
    getKilometerRate(state, projectId)
  );

  const isKilometerRatesFetching = useSelector(getHourlyRatesIsFetching);

  const [updatedHourlyRates, setUpdatedHourlyRates] =
    useState<HourlyRate[]>(null);

  const [updatedKilometerRate, setUpdatedKilometerRate] =
    useState<KilometerRate>(null);

  const kilometerRateFallback = useMemo(
    () => ({
      projectId,
      internalValue: 0.0,
      externalValue: 0.0,
    }),
    [projectId]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHourlyRates(projectId));
    dispatch(fetchKilometerRate(projectId));
  }, [projectId, dispatch]);

  const onIsDirtyChangedHourlyRates = useCallback(
    (isDirty: boolean, hourlyRates: HourlyRate[]) => {
      if (isDirty) {
        setUpdatedHourlyRates(hourlyRates);
      } else {
        setUpdatedHourlyRates(null);
      }
    },
    [setUpdatedHourlyRates]
  );
  const onIsDirtyChangedKilometerRate = useCallback(
    (isDirty: boolean, kilometerRate: KilometerRate) => {
      if (isDirty) {
        setUpdatedKilometerRate(kilometerRate);
      } else {
        setUpdatedKilometerRate(null);
      }
    },
    [setUpdatedKilometerRate]
  );

  const onSave = () => {
    if (updatedKilometerRate !== null) {
      dispatch(updateKilometerRate(updatedKilometerRate, kilometerRate));
    }
    if (updatedHourlyRates !== null) {
      dispatch(updateHourlyRates(updatedHourlyRates, projectId, hourlyRates));
    }
  };

  return (
    <div className={classes.root}>
      {isHourlyRatesFetching &&
      hourlyRates.length === 0 &&
      isKilometerRatesFetching &&
      !kilometerRate ? (
        <div className={classes.spin}>
          <PrioSpinner size="large" />
        </div>
      ) : (
        <Flex.Column
          className={classes.column}
          childrenGap={theme.old.spacing.unit(2)}
        >
          <HourlyRatesTable
            className={classes.shadow}
            projectId={projectId}
            hourlyRates={hourlyRates}
            onIsDirtyChanged={onIsDirtyChangedHourlyRates}
            loading={isHourlyRatesFetching && hourlyRates.length === 0}
          />
          <KilometerRatesInput
            projectId={projectId}
            className={classes.shadow}
            kilometerRate={kilometerRate ?? kilometerRateFallback}
            onIsDirtyChanged={onIsDirtyChangedKilometerRate}
            loading={isKilometerRatesFetching && !kilometerRate}
          />
          <Flex.Row justifyContent="flex-end">
            <Button
              disabled={!updatedHourlyRates && !updatedKilometerRate}
              onClick={onSave}
            >
              {t('common:actions.save')}
            </Button>
          </Flex.Row>
        </Flex.Column>
      )}
    </div>
  );
};

export default HourlyRatesPage;
