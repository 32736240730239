import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  TimelineGroup,
  TimelineItem,
} from '../../../../components/Timeline/types';
import ProjectPicker from '../../../projects/components/ProjectPicker';
import { ProjectId } from '../../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getActiveProject,
  getProject,
} from '../../../../apps/main/rootReducer';
import { Project } from '../../../../models/Project';
import Flex from '../../../../components/Flex';
import { isTemporaryId } from '../../../../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& svg': {
      visibility: 'hidden',
    },
    '&:hover svg': {
      visibility: 'visible',
    },
  },
  removeIcon: {
    cursor: 'pointer',
    marginRight: 10,
    '&:hover': {
      color: theme.palette.primary.base,
    },
  },
}));

interface GroupHeaderProps {
  className?: string;
  type: 'timeKeeping' | 'timeRecord' | 'addProject';
  group: TimelineGroup;
  items: TimelineItem[];
  projectIds: ProjectId[];
  onProjectSelect: (projectId: ProjectId) => void;
  onProjectRemove: (projectId: ProjectId) => void;
}

export const GroupHeader: React.FC<GroupHeaderProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    type,
    group,
    projectIds,
    items,
    onProjectSelect,
    onProjectRemove,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const project = useSelector<RootReducerState, Project>((state) =>
    type === 'timeRecord' ? getProject(state, group.id) : null
  );

  const activeProjectId = useSelector(getActiveProject);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnDeselectClick = () => {
    onProjectRemove(group.id);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (type === 'addProject') {
    return (
      <div className={classNames(classes.root, className)}>
        <ProjectPicker
          bordered={false}
          label={t('timeKeeping:timeline.groupHeader.addProject')}
          value={null}
          filter={({ projectId }) => !projectIds.includes(projectId)}
          onChange={onProjectSelect}
          dropdownMatchSelectWidth={300}
        />
      </div>
    );
  }

  if (type === 'timeKeeping') {
    return (
      <div className={classNames(classes.root, className)}>
        {t('timeKeeping:timeline.groupHeader.timeKeeping')}
      </div>
    );
  }

  return (
    <Flex.Row
      className={classNames(classes.root, className)}
      alignItems="center"
    >
      <Flex.Item
        flex={1}
        furtherProps={{ title: `${project?.number} ${project?.name}` }}
      >
        {project?.number}
      </Flex.Item>
      {activeProjectId !== group.id &&
        items.filter(({ id }) => !isTemporaryId(id)).length === 0 && (
          <FontAwesomeIcon
            icon={['fal', 'minus-circle']}
            onClick={handleOnDeselectClick}
            className={classes.removeIcon}
          />
        )}
    </Flex.Row>
  );
};

export default GroupHeader;
