import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Drawer, Tabs, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeAndLeaveManagementDrawerState } from '../../../apps/main/rootReducer';
import {
  closeTimeAndLeaveManagementDrawer,
  openTimeAndLeaveManagementDrawer,
} from '../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AbsencesMeDrawerContent from '../../absences/components/AbsencesMeDrawerContent';
import { TimeAndLeaveDrawerTab } from '../reducers/drawer';
import { TimeKeepingDrawerContent } from '../../timeKeeping/components/TimeKeepingDrawerContent';
import TimeRecordsDrawerContent from '../../timeRecords/components/TimeRecordsDrawerContent';
import { syncAbsencesMe } from '../../absences/actions';
import moment from 'moment';
import {
  fetchSingleMonthlyCloseMe,
  syncMonthlyCloseMe,
} from '../../timeKeeping/actions';
import { syncTimeRecordsMe } from '../../timeRecords/actions';
import { MonthlyCloseId } from '../../../models/Types';
import { distinct } from '../../../util';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    zIndex: 999,
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
  tabs: {
    flex: 1,
    overflow: 'hidden',
    '& .ant-tabs-content-holder': {
      flex: 1,
      overflow: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
      overflow: 'hidden',
    },
  },
  tabPaneContent: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface GlobalTimeAndLeaveManagementDrawerProps {
  className?: string;
}

export const GlobalTimeAndLeaveManagementDrawer: React.FC<
  GlobalTimeAndLeaveManagementDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    isOpen,
    tab,
    selectedTimeRange: { startDateTime, endDateTime },
  } = useSelector(getTimeAndLeaveManagementDrawerState);

  const [updatedMonthlyCloseIds, setUpdatedMonthlyCloseIds] = useState<
    MonthlyCloseId[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const addMonthlyCloseId = (id: MonthlyCloseId) => {
    setUpdatedMonthlyCloseIds(distinct([...updatedMonthlyCloseIds, id]));
  };

  const handleOnClose = () => {
    updatedMonthlyCloseIds.forEach((id) => {
      dispatch(fetchSingleMonthlyCloseMe(id));
    });
    setUpdatedMonthlyCloseIds([]);
    dispatch(closeTimeAndLeaveManagementDrawer());
  };

  const handleOnTabChange = (activeKey: TimeAndLeaveDrawerTab) => {
    dispatch(
      openTimeAndLeaveManagementDrawer({
        tab: activeKey,
      })
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (isOpen && !startDateTime && !endDateTime) {
      switch (tab) {
        case 'timeKeeping': {
          const from = moment()
            .subtract(1, 'month')
            .startOf('month')
            .toISOString(true)
            .split('T')[0];
          const to = moment()
            .add(1, 'month')
            .endOf('month')
            .toISOString(true)
            .split('T')[0];
          dispatch(syncMonthlyCloseMe(from, to));
          dispatch(syncTimeRecordsMe(from, to));
          dispatch(syncAbsencesMe(from, to));
          break;
        }
        case 'absence': {
          dispatch(syncAbsencesMe());
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [isOpen, tab, startDateTime, endDateTime, dispatch]);
  //#endregion

  return (
    <Drawer
      className={classNames(classes.root, className)}
      placement="right"
      closable={true}
      visible={isOpen}
      onClose={handleOnClose}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      width={'100%'}
      destroyOnClose
    >
      <Typography.Title level={2}>
        {t('timeAndLeaveManagement:title')}
      </Typography.Title>
      <Tabs
        className={classes.tabs}
        activeKey={tab}
        onChange={handleOnTabChange}
        destroyInactiveTabPane
      >
        <TabPane
          key="timeKeeping"
          tab={t('timeAndLeaveManagement:tabs.timeKeeping')}
        >
          <TimeKeepingDrawerContent
            className={classes.tabPaneContent}
            monthlyCloseChange={addMonthlyCloseId}
            initialDay={startDateTime}
          />
        </TabPane>
        <TabPane
          key="timeRecords"
          tab={t('timeAndLeaveManagement:tabs.timeRecords')}
        >
          <TimeRecordsDrawerContent
            className={classes.tabPaneContent}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </TabPane>
        <TabPane key="absence" tab={t('timeAndLeaveManagement:tabs.absence')}>
          <AbsencesMeDrawerContent
            className={classes.tabPaneContent}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default GlobalTimeAndLeaveManagementDrawer;
