import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import CreateTimeRecordForm from './CreateTimeRecordForm';
import { CreateTimeRecordsRequest } from '../../../../models/TimeRecord';
import { createTimeRecords } from '../../actions';
import { apiCreateTimeRecord } from '../../api';
import { notification } from 'antd';
import { closeTimeAndLeaveManagementDrawer } from '../../../timeAndLeaveManagement/actions';
import { DateTimeString } from '../../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    marginTop: theme.old.spacing.unit(4),
  },
  loadingOverlayCreateTimerecodForm: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffffaa',
  },
}));

interface CreateTimeRecordProps {
  startTime?: DateTimeString;
  endTime?: DateTimeString;
}

export const CreateTimeRecord: React.FC<CreateTimeRecordProps> = (props) => {
  const { startTime, endTime } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnFinish = async (
    value: CreateTimeRecordsRequest,
    isMe: boolean
  ) => {
    await apiCreateTimeRecord(value.timeRecords, value.projectId, isMe)
      .then(({ data }) => {
        dispatch(createTimeRecords(data));
      })
      .catch((error) => {
        notification.open({
          message: t('common:error'),
          description: t('timeRecords:errorMessages.createError'),
        });
        console.error(error.message);
      });
  };

  const handleOnCancel = () => {
    dispatch(closeTimeAndLeaveManagementDrawer());
  };

  return (
    <Flex.Column className={classes.root}>
      <CreateTimeRecordForm
        actionLabel={t('timeRecords:newTimeRecord.actionLabel')}
        cancelLabel={t('common:actions.cancel')}
        className={classes.form}
        onFinish={handleOnFinish}
        onCancel={handleOnCancel}
        starTime={startTime}
        endTime={endTime}
        resetOnFinish
      />
    </Flex.Column>
  );
};

export default CreateTimeRecord;
