import React, { useEffect } from 'react';
import { Select } from 'antd';

import { Office } from '../../../models/Office';
import { useDispatch } from 'react-redux';
import { fetchExternalOffices, fetchInternalOffices } from '../actions';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { OfficeId } from '../../../models/Types';
import useOfficesContext from '../hooks/useOfficesContext';

const useStyles = makePrioStyles((theme) => ({
  root: { width: '100%' },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
}));
interface OfficePickerProps {
  defaultValue?: string | string[];
  value?: string | string[];
  label?: string;
  onChange?: (value: string | string[]) => void;
  required?: boolean;
  disabled?: boolean;

  onlyInternal?: boolean;
  multiple?: boolean;
  fetch?: boolean;
  officeIds?: OfficeId[];
}

export const OfficePicker: React.FC<OfficePickerProps> = (props) => {
  const classes = useStyles();
  const {
    value,
    label,
    onChange,
    disabled,
    onlyInternal,
    multiple,
    defaultValue,
    officeIds,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onlyInternal) {
      dispatch(fetchExternalOffices());
    }
    dispatch(fetchInternalOffices());
  }, [dispatch, onlyInternal]);

  const { internalOffices: offices, getOfficeById } = useOfficesContext();

  const officesLimited = officeIds?.map((officeId) => getOfficeById(officeId));

  const handleOnChange: (value: string) => void = onChange
    ? (value: string) => {
        onChange(value ?? '');
      }
    : null;

  return (
    <Select
      className={classNames(classes.root, {
        [classes.disabledPicker]: disabled,
      })}
      showSearch
      mode={multiple ? 'multiple' : null}
      value={value}
      defaultValue={defaultValue}
      placeholder={label}
      optionFilterProp="children"
      onChange={handleOnChange}
      filterOption={(input, option) =>
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      options={(officesLimited ?? offices)?.map((office: Office) => ({
        value: office.officeId,
        label: office.name ?? '???',
        title: office.name ?? '???',
      }))}
    />
  );
};

export default OfficePicker;
