import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { syncMonthlyCloseMe } from '../../../timeKeeping/actions';
import { useDispatch, useSelector } from 'react-redux';
import { syncTimeRecordsMe } from '../../../timeRecords/actions';
import moment from 'moment';
import {
  RootReducerState,
  getMonthlyCloseMeTimeKeepingDays,
  getTimeRecordIds,
  getTimeRecordsByIdState,
  getUserMe,
} from '../../../../apps/main/rootReducer';
import { TimeKeepingDay } from '../../../../models/TimeKeeping';
import { createSelector } from 'reselect';
import { TimeRecordsByIdState } from '../../../timeRecords/reducers/timeRecords';
import { TimeRecordId } from '../../../../models/Types';
import { User } from '../../../../models/User';
import { TimeRecord } from '../../../../models/TimeRecord';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { Link } from 'react-router-dom';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  circularProgressbar: {
    width: 250,
    height: 250,
    '& > div > div > div > svg': {
      marginTop: '-6.9%',
      marginLeft: '1.1%',
      marginRight: '1.1%',
      paddingBottom: '1.1%',
    },
  },
  tags: {
    backgroundColor: theme.old.palette.backgroundPalette.main,
    borderRadius: theme.old.borders.radius,
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.base,
    height: 32,
    '&:first-child': {
      borderLeft: `12px solid ${theme.old.palette.chromaticPalette.lightBlue}`,
      marginTop: '8px',
    },
    '&:nth-child(2)': {
      borderLeft: `12px solid ${theme.old.palette.backgroundPalette.base}`,
    },
    '&:nth-child(3)': {
      borderLeft: `12px solid ${theme.old.palette.chromaticPalette.lightGrey}`,
    },
  },
  spacingTags: {
    marginRight: '12px',
  },
  tagIcon: {
    marginLeft: '24px',
  },
}));

const timeKeepingDaysSelector = createSelector<
  [(state: RootReducerState) => TimeKeepingDay[]],
  TimeKeepingDay[]
>(
  (state) =>
    getMonthlyCloseMeTimeKeepingDays(
      state,
      moment().toISOString(true).substring(0, 7)
    ),
  (timeKeepingDays) =>
    timeKeepingDays.filter(
      ({ timeKeepingEntries }) =>
        timeKeepingEntries[0]?.startTime &&
        moment(timeKeepingEntries[0]?.startTime).isSame(moment(), 'day')
    )
);

const timeRecordssSelector = createSelector<
  [
    (state: RootReducerState) => TimeRecordsByIdState,
    (state: RootReducerState) => TimeRecordId[],
    (state: RootReducerState) => User,
  ],
  TimeRecord[]
>(getTimeRecordsByIdState, getTimeRecordIds, getUserMe, (byId, ids, userMe) =>
  ids.reduce((currentArray, id) => {
    const timeRecord = byId[id];
    if (
      timeRecord &&
      timeRecord.contactId === userMe.id &&
      moment(timeRecord.day).isSame(moment(), 'day')
    ) {
      currentArray.push(timeRecord);
    }
    return currentArray;
  }, [] as TimeRecord[])
);

interface DashboardTimekeepingTodayItemProps {
  className?: string;
}

export const DashboardTimekeepingTodayItem: React.FC<
  DashboardTimekeepingTodayItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const timeKeepingDays = useSelector(timeKeepingDaysSelector);

  const timeKeepingSumWithoutBreaks = timeKeepingDays.reduce(
    (currentSum, { timeKeepingEntries }) => {
      return (
        currentSum +
        timeKeepingEntries.reduce((_currentSum, { startTime, endTime }) => {
          return (
            _currentSum + moment(endTime).diff(moment(startTime), 'minutes')
          );
        }, 0)
      );
    },
    0
  );

  const timeKeepingSum = timeKeepingDays.reduce(
    (currentSum, { timeKeepingEntries }) => {
      const sortedEntries = timeKeepingEntries.sort((a, b) =>
        moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
      );
      const diffStartEndTime = moment(
        sortedEntries[sortedEntries.length - 1].endTime
      ).diff(moment(sortedEntries[0].startTime), 'minutes');
      return currentSum + diffStartEndTime;
    },
    0
  );

  const timeRecords = useSelector(timeRecordssSelector);

  const timeRecordSum = timeRecords.reduce(
    (currentSum, { timeRecordEntries }) => {
      return (
        currentSum +
        timeRecordEntries.reduce((_currentSum, { startTime, endTime }) => {
          return (
            _currentSum + moment(endTime).diff(moment(startTime), 'minutes')
          );
        }, 0)
      );
    },
    0
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const start = moment()
      .subtract(1, 'month')
      .startOf('month')
      .toISOString(true)
      .split('T')[0];
    const end = moment()
      .add(1, 'month')
      .endOf('month')
      .toISOString(true)
      .split('T')[0];
    dispatch(syncMonthlyCloseMe(start, end));
    dispatch(syncTimeRecordsMe(start, end));
  }, [dispatch]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row
        childrenGap={theme.old.spacing.baseSpacing}
        alignItems="center"
        marginBottom={theme.old.spacing.unit(2)}
      >
        <Typography.Title
          level={3}
          ellipsis
          style={{ marginBottom: 0, flex: 1 }}
        >
          {t('dashboard:timeKeepingToday.title', {
            date: moment().format('DD. MMMM YYYY'),
          })}
        </Typography.Title>
        <Link
          to={`../timeAndLeaveManagement/summary?month=${moment()
            .toISOString(true)
            .substring(0, 7)}`}
        >
          <Button
            iconProp={['fal', 'arrow-right-to-bracket']}
            type="default"
            style={{ fontSize: 16 }}
          />
        </Link>
      </Flex.Row>
      <Flex.Row
        flex={1}
        childrenGap={theme.old.spacing.unit(4)}
        justifyContent="space-around"
      >
        <Flex.Column flex={2}>
          <div className={classes.circularProgressbar}>
            <CircularProgressbarWithChildren
              value={
                timeKeepingSumWithoutBreaks === 0
                  ? 0
                  : (timeKeepingSumWithoutBreaks / timeKeepingSum) * 100
              }
              circleRatio={0.75}
              strokeWidth={16}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 8,
                strokeLinecap: 'butt',
                trailColor:
                  timeKeepingSum === 0
                    ? theme.old.palette.backgroundPalette.sub
                    : theme.old.palette.chromaticPalette.lightGrey,
                pathColor: theme.old.palette.backgroundPalette.base,
              })}
            >
              <CircularProgressbar
                value={
                  timeRecordSum === 0 || timeKeepingSum === 0
                    ? 0
                    : (timeRecordSum / timeKeepingSumWithoutBreaks) * 100
                }
                circleRatio={
                  timeRecordSum === 0 || timeKeepingSum === 0
                    ? 0.74
                    : (timeKeepingSumWithoutBreaks / timeKeepingSum) * 0.74
                }
                strokeWidth={14}
                styles={buildStyles({
                  rotation: 1 / 2 + 104 / 800,
                  strokeLinecap: 'butt',
                  pathColor: theme.old.palette.chromaticPalette.lightBlue,
                })}
              />
            </CircularProgressbarWithChildren>
          </div>
          {/* play button to start timekeeping */}
          {/* <Flex.Row justifyContent="center">
            <FontAwesomeIcon
              size="3x"
              icon={'play'}
              color={theme.old.palette.chromaticPalette.lightGrey}
              style={{
                marginTop: '-176px',
                marginLeft: '-12px',
                zIndex: 999,
                padding: '24px',
              }}
            ></FontAwesomeIcon>
          </Flex.Row> */}
        </Flex.Column>
        <Flex.Column
          flex={1}
          childrenGap={theme.old.spacing.unit(1)}
          className={classes.spacingTags}
        >
          <Flex.Row
            className={classes.tags}
            childrenGap={theme.old.spacing.baseSpacing}
            alignItems="center"
            justifyContent="center"
            furtherProps={{
              title: t('dashboard:timeKeepingToday.tooltips.timeRecords'),
            }}
          >
            <Flex.Column flex={2}>
              <FontAwesomeIcon
                icon={['far', 'bars-progress']}
                className={classes.tagIcon}
              />
            </Flex.Column>
            <Flex.Column flex={3}>
              <div>{`${timeRecordSum === 0 ? 0 : timeRecordSum / 60} h`}</div>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row
            className={classes.tags}
            childrenGap={theme.old.spacing.baseSpacing}
            alignItems="center"
            justifyContent="center"
            furtherProps={{
              title: t('dashboard:timeKeepingToday.tooltips.timeKeeping'),
            }}
          >
            <Flex.Column flex={2}>
              <FontAwesomeIcon
                icon={['fal', 'business-time']}
                className={classes.tagIcon}
              />
            </Flex.Column>
            <Flex.Column flex={3}>
              <div>{`${
                timeKeepingSumWithoutBreaks === 0
                  ? 0
                  : timeKeepingSumWithoutBreaks / 60
              } h`}</div>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row
            className={classes.tags}
            childrenGap={theme.old.spacing.baseSpacing}
            alignItems="center"
            justifyContent="center"
            furtherProps={{
              title: t('dashboard:timeKeepingToday.tooltips.break'),
            }}
          >
            <Flex.Column flex={2}>
              <FontAwesomeIcon
                icon={['fal', 'coffee']}
                className={classes.tagIcon}
              />
            </Flex.Column>
            <Flex.Column flex={3}>
              <div>{`${
                timeKeepingSum - timeKeepingSumWithoutBreaks === 0
                  ? 0
                  : (timeKeepingSum - timeKeepingSumWithoutBreaks) / 60
              } h`}</div>
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
      </Flex.Row>
    </Flex.Column>
  );
};

export default DashboardTimekeepingTodayItem;
