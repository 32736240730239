import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import TimelineBody, { TimelineBodyRef } from './TimelineBody';
import TimelineHeader, { StepMode } from './TimelineHeader';
import Flex from '../Flex';
import { Moment } from 'moment';
import {
  HeaderBarResolutions,
  TimelineGroup,
  TimelineItem,
  TimelineResolutions,
} from './types';
import {
  filterTimelineItemsInRange,
  guessTimelineHeaderResolution,
  mapHeaderBarResolutionToGridResolution,
  mapResolutionToMillisecondSnap,
} from './utils';
import equals from 'deep-equal';
import TimelineItemProvider from './TimelineItemProvider';
import TimelineRowProvider from './TimelineRowProvider';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  prefixColumn: {
    width: 250,
    minHeight: '100%',
    borderBottom: theme.old.borders.content,
  },
}));

export interface TimelineRef {
  /**
   * Method to update the timeline from parent.
   * This method should be used only if neccessary.
   */
  forceUpdate: VoidFunction;
  /**
   * If an temporary item is still in the timeline,
   * you can use this function to replace it.
   * @param groupId id of the group the item exist
   * @param item new item to be set
   * @param previousId id of the item you want to replace
   * @returns
   */
  replaceItem: (
    groupId: string,
    item: TimelineItem,
    previousId: string
  ) => void;
  /**
   *
   * @returns all items in the timeline
   */
  getCurrentItems: () => TimelineItem[];
  /**
   *
   * @param value if true, the timeline will stop setting data from outer
   * @returns
   */
  setIsFetching: (value: boolean) => void;
}

interface TimelineProps {
  className?: string;
  id: string;
  initialVisibleTimeStart: Moment;
  initialVisibleTimeEnd: Moment;
  initialStartTime?: Moment;
  initialEndTime?: Moment;
  items: TimelineItem[];
  groups: TimelineGroup[];
  itemHeight?: number;
  timelineResolutions?: TimelineResolutions;
  prefixWidth?: number;
  suffixWidth?: number;
  onItemChange?: (groupId: string, item: TimelineItem) => Promise<TimelineItem>;
  onItemClick?: (item: TimelineItem, group: TimelineGroup) => void;
  onItemRemove?: (item: TimelineItem, group: TimelineGroup) => void;
  onItemCreate?: (groupId: string, item: TimelineItem) => Promise<TimelineItem>;
  onTimeRangeChange?: (
    visibleTimeStart: Moment,
    visibleTimeEnd: Moment,
    startTime: Moment,
    endTime: Moment
  ) => void;
  groupHeaderRenderer?: (
    group: TimelineGroup,
    items: TimelineItem[],
    start?: Moment,
    end?: Moment
  ) => React.ReactNode;
  groupSuffixRenderer?: (
    group: TimelineGroup,
    items: TimelineItem[],
    visibleStart?: Moment,
    visibleEnd?: Moment,
    start?: Moment,
    end?: Moment
  ) => React.ReactNode;
  popoverRenderer?: (
    item: TimelineItem,
    group: TimelineGroup
  ) => React.ReactNode;
  onPopoverClick?: (
    item: TimelineItem,
    group: TimelineGroup,
    popoverPositionInMS: number
  ) => void;
  gridSnapInMilliseconds?: number;
  disableResize?: boolean;
  disableDrag?: boolean;
  disableAdd?: boolean;
  disableRemove?: boolean;
  disableTopBar?: boolean;
  disableBottomBar?: boolean;
  enableStepper?: boolean;
  stepMode?: StepMode;
  headerPrefixRenderer?: () => React.ReactNode;
  headerSuffixRenderer?: () => React.ReactNode;
  itemRenderer?: (
    group: TimelineGroup,
    item: TimelineItem,
    pixelsPerMs: number,
    gridSnapInMilliseconds: number
  ) => React.ReactNode;
  itemStyle?: (group: TimelineGroup, item: TimelineItem) => React.CSSProperties;
}

export const Timeline = React.memo(
  forwardRef<TimelineRef, TimelineProps>((props, ref) => {
    //#region ------------------------------ Defaults
    const {
      className,
      id,
      initialVisibleTimeStart,
      initialVisibleTimeEnd,
      initialStartTime,
      initialEndTime,
      items,
      groups,
      itemHeight,
      timelineResolutions,
      prefixWidth = 200,
      suffixWidth = 0,
      onItemChange,
      onItemClick,
      onItemRemove,
      onItemCreate,
      onTimeRangeChange,
      groupHeaderRenderer,
      groupSuffixRenderer,
      popoverRenderer,
      onPopoverClick,
      gridSnapInMilliseconds,
      disableResize,
      disableDrag,
      disableAdd,
      disableRemove,
      disableTopBar,
      disableBottomBar,
      enableStepper,
      stepMode,
      headerPrefixRenderer,
      headerSuffixRenderer,
      itemRenderer,
      itemStyle,
    } = props;
    const classes = useStyles();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [timelineWidth, setTimelineWidth] = useState<number>(
      1000 - prefixWidth - suffixWidth
    );

    const [_groups, setGroups] = useState<TimelineGroup[]>(groups);

    const [_visibleTimeStart, setVisibleTimeStart] = useState<Moment>(
      initialVisibleTimeStart
    );
    const [_visibleTimeEnd, setVisibleTimeEnd] = useState<Moment>(
      initialVisibleTimeEnd
    );

    const [_startTime, setStartTime] = useState<Moment>(
      initialStartTime ?? initialVisibleTimeStart
    );
    const [_endTime, setEndTime] = useState<Moment>(
      initialEndTime ?? initialVisibleTimeEnd
    );

    const [_items, setItems] = useState<TimelineItem[]>(
      filterTimelineItemsInRange(items, _startTime, _endTime)
    );

    const itemsRef = useRef<TimelineItem[]>(_items);
    itemsRef.current = _items;

    const bodyRef = useRef<TimelineBodyRef>(null);

    const resolutions: HeaderBarResolutions = useMemo(
      () =>
        timelineResolutions?.headerBarResolutions ??
        guessTimelineHeaderResolution(_visibleTimeStart, initialVisibleTimeEnd),
      [
        timelineResolutions?.headerBarResolutions,
        _visibleTimeStart,
        initialVisibleTimeEnd,
      ]
    );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const forceUpdate = useCallback(() => {
      setItems(filterTimelineItemsInRange(items, _startTime, _endTime));
      setGroups(groups);
      bodyRef.current.forceUpdate();
    }, [items, groups, _startTime, _endTime]);

    const handleOnTimeRangeChange = (
      visibleTimeStart: Moment,
      visibleTimeEnd: Moment,
      startTime: Moment,
      endTime: Moment
    ) => {
      if (onTimeRangeChange) {
        onTimeRangeChange(visibleTimeStart, visibleTimeEnd, startTime, endTime);
      }
      setVisibleTimeStart(visibleTimeStart);
      setVisibleTimeEnd(visibleTimeEnd);
      setStartTime(startTime);
      setEndTime(endTime);
      setItems(filterTimelineItemsInRange(items, startTime, endTime));
    };

    const handleOnVisibleStartEndTimeChange = (
      startTime: Moment,
      endTime: Moment
    ) => {
      setVisibleTimeStart(startTime);
      setVisibleTimeEnd(endTime);
      if (onTimeRangeChange) {
        onTimeRangeChange(startTime, endTime, _startTime, _endTime);
      }
    };

    const getCurrentItems = useCallback(() => {
      return _items;
    }, [_items]);
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      forceUpdate,
      replaceItem: bodyRef.current?.replaceItem,
      getCurrentItems,
      setIsFetching: bodyRef.current?.setIsFetching,
    }));

    useEffect(() => {
      if (!equals(_groups, groups)) {
        forceUpdate();
      }
    }, [_groups, groups, forceUpdate]);

    useEffect(() => {
      if (
        !bodyRef.current?.getIsFetching() &&
        !equals(
          bodyRef.current?.getItems() ?? [],
          filterTimelineItemsInRange(items, _startTime, _endTime)
        )
      ) {
        setItems(filterTimelineItemsInRange(items, _startTime, _endTime));
      }
    }, [items, _startTime, _endTime, forceUpdate]);
    //#endregion

    return (
      <Flex.Column id={id} className={classNames(classes.root, className)}>
        <TimelineHeader
          visibleTimeStart={_visibleTimeStart}
          visibleTimeEnd={_visibleTimeEnd}
          startTime={_startTime}
          endTime={_endTime}
          resolutions={resolutions}
          prefixClassName={classes.prefixColumn}
          timelineWidth={timelineWidth}
          prefixWidth={prefixWidth}
          suffixWidth={suffixWidth}
          disableTopBar={disableTopBar}
          disableBottomBar={disableBottomBar}
          enableStepper={enableStepper}
          onTimeRangeChange={handleOnTimeRangeChange}
          onVisibleStartEndTimeChange={handleOnVisibleStartEndTimeChange}
          suffixRenderer={headerSuffixRenderer}
          prefixRenderer={headerPrefixRenderer}
          stepMode={stepMode}
        />
        <TimelineRowProvider
          value={{
            visibleTimeStart: _visibleTimeStart,
            visibleTimeEnd: _visibleTimeEnd,
            startTime: _startTime,
            endTime: _endTime,
            prefixWidth,
            suffixWidth,
            prefixClassName: classes.prefixColumn,
            groupHeaderRenderer,
            groupSuffixRenderer,
          }}
        >
          <TimelineItemProvider
            value={{
              visibleTimeStart: _visibleTimeStart,
              visibleTimeEnd: _visibleTimeEnd,
              startTime: _startTime,
              endTime: _endTime,
              disableResize,
              itemStyle,
              itemRenderer,
              popoverRenderer,
              onPopoverClick,
            }}
          >
            <TimelineBody
              timelineId={id}
              ref={bodyRef}
              visibleTimeStart={_visibleTimeStart}
              visibleTimeEnd={_visibleTimeEnd}
              startTime={_startTime}
              endTime={_endTime}
              items={_items}
              groups={_groups}
              itemHeight={itemHeight}
              prefixWidth={prefixWidth}
              suffixWidth={suffixWidth}
              resolution={
                timelineResolutions?.grid ??
                mapHeaderBarResolutionToGridResolution(resolutions.bottom)
              }
              gridSnapInMilliseconds={
                gridSnapInMilliseconds ??
                mapResolutionToMillisecondSnap(
                  timelineResolutions?.grid ??
                    mapHeaderBarResolutionToGridResolution(resolutions.bottom),
                  _visibleTimeStart,
                  _visibleTimeEnd
                )
              }
              disableResize={disableResize}
              disableDrag={disableDrag}
              disableAdd={disableAdd}
              disableRemove={disableRemove}
              timelineWidth={timelineWidth}
              setTimelineWidth={setTimelineWidth}
              onItemClick={onItemClick}
              onItemChange={onItemChange}
              onItemRemove={onItemRemove}
              onItemCreate={onItemCreate}
            />
          </TimelineItemProvider>
        </TimelineRowProvider>
      </Flex.Column>
    );
  })
);

export default Timeline;
