import React, { useEffect } from 'react';

import ProjectDistributionListTable from './ProjectDistributionListTable';
import { ProjectId } from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveProjectDistributionList,
  createProjectDistributionList,
  fetchProjectDistribustionList,
  setProjectDistributionDrawerState,
  updateProjectDistributionList,
} from '../actions';
import {
  getProjectDistributionListDrawerState,
  getProjectDistributionListIsFetching,
  getProjectDistributionLists,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectDistributionListDrawerState } from '../reducers/projectDistributionList';
import { Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import ProjectDistributionListForm from './ProjectDistributionListForm';
import { useTranslation } from 'react-i18next';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    flex: 1,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
}));

interface ProjectDistributionListPageProps {
  projectId: ProjectId;
}

export const ProjectDistributionListPage: React.FC<
  ProjectDistributionListPageProps
> = (props) => {
  const classes = useStyles();
  const { projectId } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(
        setProjectDistributionDrawerState({
          isOpen: false,
          selectedEntry: null,
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProjectDistribustionList(projectId));
  }, [dispatch, projectId]);

  const { isOpen, selectedEntry } = useSelector<
    RootReducerState,
    ProjectDistributionListDrawerState
  >((state) => getProjectDistributionListDrawerState(state));

  const width = isOpen ? panelWidth : 0;
  const opacity = isOpen ? '100%' : '0%';

  const projectDistributionLists = useSelector<
    RootReducerState,
    ProjectDistributionList[]
  >((state) => getProjectDistributionLists(state, projectId));

  const isFetching = useSelector(getProjectDistributionListIsFetching);

  const handleCreateProjectDistributionList = () => {
    dispatch(
      setProjectDistributionDrawerState({ isOpen: true, selectedEntry: null })
    );
  };

  const handleOnClick = (list: ProjectDistributionList) => {
    dispatch(
      setProjectDistributionDrawerState({
        isOpen: true,
        selectedEntry: list,
      })
    );
  };

  const handleClose = () => {
    dispatch(
      setProjectDistributionDrawerState({
        isOpen: false,
        selectedEntry: null,
      })
    );
  };

  const handleOnFinish = (entry: ProjectDistributionList) => {
    if (selectedEntry) {
      dispatch(updateProjectDistributionList(entry, selectedEntry));
    } else {
      dispatch(
        createProjectDistributionList(entry, entry.projectDistributionListId)
      );
    }
    dispatch(
      setProjectDistributionDrawerState({
        isOpen: false,
        selectedEntry: null,
      })
    );
  };

  const handleOnDelete = (entry: ProjectDistributionList) => {
    Modal.confirm({
      icon: null,
      title: t('projects:projectDistributionList.modal.title'),
      content: t('projects:projectDistributionList.modal.content', {
        name: entry.name,
      }),
      okText: t('common:actions.delete'),
      cancelText: t('common:actions.cancel'),
      onOk() {
        dispatch(archiveProjectDistributionList(entry));
        dispatch(
          setProjectDistributionDrawerState({
            isOpen: false,
            selectedEntry: null,
          })
        );
      },
      onCancel() {},
    });
  };

  return (
    <Flex.Row className={classes.root}>
      <div
        className={classes.content}
        style={
          isOpen ? { width: `calc(100% - ${panelWidth}px)` } : { width: '100%' }
        }
      >
        {' '}
        <Flex.Row justifyContent="flex-end">
          <Button
            onClick={handleCreateProjectDistributionList}
            iconProp={['fal', 'plus']}
          >
            <span>
              {t('projects:navigationBar.createProjectDistributionList')}
            </span>
          </Button>
        </Flex.Row>
        <ProjectDistributionListTable
          distributionLists={projectDistributionLists}
          onRowClick={handleOnClick}
          onDelete={handleOnDelete}
          loading={isFetching && projectDistributionLists.length === 0}
        />
      </div>
      <div className={classes.panel} style={{ width: width, opacity }}>
        <div className={classes.panelChild}>
          <ProjectDistributionListForm
            onFinish={handleOnFinish}
            onCancel={handleClose}
            onDelete={handleOnDelete}
            initialValues={selectedEntry}
            actionLabel={t('common:actions.save')}
            cancelLabel={t('common:actions.cancel')}
            deleteLabel={t('common:actions.delete')}
            projectId={projectId}
            enableActionButton
          />
        </div>
        <Button
          onClick={handleClose}
          shape="circle"
          iconProp={['fal', 'times']}
          className={classes.closeButton}
        />
      </div>
    </Flex.Row>
  );
};

export default ProjectDistributionListPage;
