import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { apiFetchProjectDocumentTags } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { DocumentTag } from '../../../models/Document';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noneStateLabel: {
    fontWeight: 400,
  },
  completeStateLabel: {
    fontWeight: 500,
  },
  partialStateLabel: {
    color: 'grey',
  },
  noneStateOption: {
    backgroundColor: '#ffffff!important',
  },
  partialStateOption: {
    backgroundColor: '#106ebe08!important',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: '#106ebe40',
    },
  },
  completeStateOption: {
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  hideCheckIcon: {
    '& .ant-select-item-option-state': {
      visibility: 'hidden',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#f5f5f5!important',
    },
  },
  textOverflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const getDocumentTagState = (
  parsedDocumentTags: { documentTag: string; numberOfUses: number }[],
  documentTag: DocumentTag,
  numberOfSelectedDriveItems: number
) => {
  const numberOfUses = parsedDocumentTags.find(
    (parsedDocumentTag) => parsedDocumentTag.documentTag === documentTag.name
  )?.numberOfUses;

  if (numberOfUses === 0) return 'none';
  if (numberOfUses === numberOfSelectedDriveItems) return 'complete';
  if (numberOfUses > 0 && numberOfUses < numberOfSelectedDriveItems)
    return 'partial';
  return 'none';
};

interface ById {
  [key: string]: string[];
}

interface DocumentTagPickerMultiSelectProps {
  className?: string;
  parsedValues?: ById;
  setParsedValues?: (value: ById) => void;
  listItemIdsOfSelectDriveItems?: string[];
  disabled?: boolean;
  projectId: ProjectId;
}

export const DocumentTagPickerMultiSelect: React.FC<
  DocumentTagPickerMultiSelectProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const [documentTags, setDocumentTags] = useState<DocumentTag[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const { t } = useTranslation();

  const {
    className,
    parsedValues,
    setParsedValues,
    listItemIdsOfSelectDriveItems,
    disabled,
    projectId,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const selectedValues = Object.keys(parsedValues).sort((a, b) =>
    a.localeCompare(b)
  );

  const parsedDocumentTags: { documentTag: string; numberOfUses: number }[] =
    Object.entries(parsedValues).reduce((acc, [key, value]) => {
      return [
        ...acc,
        {
          documentTag: key,
          numberOfUses: (value as string[]).length,
        },
      ];
    }, []);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnChange = (value: string[]) => {
    const initialDocumentTag = Object.keys(parsedValues);
    const newDocumentTag = value.filter(
      (documentTag) => !initialDocumentTag.includes(documentTag)
    );
    const removedDocumentTag = initialDocumentTag.filter(
      (documentTag) => !value.includes(documentTag)
    );

    if (newDocumentTag.length > 0) {
      const newParsedValues = newDocumentTag.reduce((acc, documentTag) => {
        return {
          ...acc,
          [documentTag]: listItemIdsOfSelectDriveItems,
        };
      }, parsedValues);
      setParsedValues(newParsedValues);
    }

    if (removedDocumentTag.length > 0) {
      const newParsedValues = removedDocumentTag.reduce((acc, documentTag) => {
        const { [documentTag]: removed, ...rest } = acc;
        return rest;
      }, parsedValues);
      setParsedValues(newParsedValues);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadDocumentTags = async () => {
      try {
        const { data } = await apiFetchProjectDocumentTags(projectId, signal);

        if (data && Array.isArray(data)) {
          setDocumentTags(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        setFetching(false);
      } catch {}
    };
    loadDocumentTags();
    return () => {
      controller.abort();
    };
  }, [setDocumentTags, projectId]);
  //#endregion

  const renderOption = (item: {
    label: string;
    value: string;
    state: 'none' | 'partial' | 'complete';
  }) => {
    return (
      <div
        className={classNames({
          [classes.noneStateLabel]: item.state === 'none',
          [classes.partialStateLabel]: item.state === 'partial',
          [classes.completeStateLabel]: item.state === 'complete',
        })}
      >
        <div className={classes.textOverflowEllipsis}>{item.label}</div>
      </div>
    );
  };

  return (
    <Select
      className={classNames(classes.root, className)}
      mode="multiple"
      disabled={disabled}
      placeholder={t('documents:documentTagPicker.placeholder')}
      notFoundContent={fetching ? <PrioSpinner size="small" /> : null}
      value={selectedValues}
      onChange={handleOnChange}
      showSearch
      options={documentTags.map((documentTag) => {
        const state = getDocumentTagState(
          parsedDocumentTags,
          documentTag,
          listItemIdsOfSelectDriveItems.length
        );
        return {
          value: documentTag.name,
          label: renderOption({
            label: documentTag.name,
            value: documentTag.name,
            state: state,
          }),
          className: classNames(
            {
              [classes.noneStateOption]: state === 'none',
              [classes.partialStateOption]: state === 'partial',
              [classes.completeStateOption]: state === 'complete',
            },
            classes.hideCheckIcon,
            classes.hover
          ),
        };
      })}
    ></Select>
  );
};

export default DocumentTagPickerMultiSelect;
