import React from 'react';
import Button, { ButtonProps } from '@prio365/prio365-react-library/lib/Button';
import PrioSpinner from './PrioSpinner';

type WaitingMouseClick = (
  e: React.MouseEvent<HTMLElement, MouseEvent>
) => Promise<void>;

interface WaitButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: WaitingMouseClick;
  showSpinner?: boolean;
}

export const WaitButton: React.FC<WaitButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { showSpinner, onClick, disabled, ..._props } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [loading, setLoading] = React.useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setLoading(true);
    if (onClick) {
      await onClick(e);
    }
    setLoading(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (showSpinner && loading) {
    return <PrioSpinner size="small" />;
  }

  return (
    <Button
      {..._props}
      onClick={handleOnClick}
      disabled={loading || (disabled ?? false)}
    />
  );
};

export default WaitButton;
