import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  ConfigurationKeys,
  EMailSuffix,
  EmployeeId,
  OfficeId,
} from '../../../../models/Types';
import { Employee } from '../../../../models/Employee';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colon, rowGutter } from '../../../../util/forms';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import OfficePicker from '../../../companies/components/OfficePicker';
import ContactPicker from '../../../contacts/components/ContactPicker';
import {
  emailPattern,
  emailPrefixPattern,
} from '../../../../hooks/useEmailValidation';
import { apiValidateEmail } from '../../../users/api';
import {
  apiFetchConfigurations,
  apiFetchEmailSuffixes,
} from '../../../settings/api';
import { fetchTitleSuggestions } from '../../../settings/actions/titleSuggestions';
import { fetchContactClassifications } from '../../../settings/actions';
import { useDispatch } from 'react-redux';
import { defaultPhonePattern, defaultPhonePlaceholder } from '../../../../util';
import { Configuration } from '../../../../models/Configuration';
import { InternalContact } from '../../../../models/Contact';
import { updateInternalContact } from '../../../contacts/actions';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import useAccessRights from '../../../users/hooks/useAccessRights';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  formContent: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  companyDataBoxLeft: {
    borderRight: theme.old.borders.content,
    paddingRight: theme.old.spacing.unit(3),
  },
  eMailSuffixSelect: {
    '&:before': {
      content: '"@"',
      display: 'inline-block',
      position: 'absolute',
      left: -22,
      top: 3,
      color: theme.old.typography.colors.muted,
    },
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  placeholder: {
    '&.ant-input:placeholder-shown::placeholder': {
      color: 'rgb(0,0,0,0.1)',
    },
  },
  paddingTopOnLastRow: {
    paddingTop: theme?.spacing?.baseSpacing * 2,
  },
}));

interface CompanyDataFormModel {
  officeId?: string;
  managerId?: string;
  department?: string;
  position?: string;
  eMailPrefix?: string;
  eMailSuffix?: string;
  eMail2?: string;
  phone?: string;
  phone2?: string;
  cellphone?: string;
  cellphone2?: string;
  notes?: string;
  signaturePrefix?: string;
  fax?: string;
}

interface PersonnelFileCompanyDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  officeId?: OfficeId;
  patchEmployee: (patchData: Employee) => Promise<void>;
  contact: InternalContact;
}

export const PersonnelFileCompanyDataForm: React.FC<
  PersonnelFileCompanyDataFormProps
> = (props) => {
  const { employeeId, employee, isSaving, officeId, contact } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const { editInternalContact: editInternalContactAllowed } = useAccessRights(
    ['editInternalContact'],
    { officeId: officeId || contact?.officeId }
  );

  const [companyDataForm] = Form.useForm<CompanyDataFormModel>();
  const [editModeCompanyData, setEditModeCompanyData] =
    useState<boolean>(false);
  const [emailSuffixes, setEmailSuffixes] = useState<EMailSuffix[]>([]);
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  const [disabled, setDisabled] = useState<boolean>(false);

  const contactCast = contact as InternalContact;

  //#region ------------------------------ Methods / Handlers

  const companyDataFromEmployee: (
    contact: InternalContact
  ) => CompanyDataFormModel = (contact) => ({
    officeId: contact?.officeId,
    managerId: contact?.managerId,
    department: contact?.department,
    position: contact?.position,
    eMailPrefix: contact?.eMail.split('@')[0],
    eMailSuffix: contact?.eMail.split('@')[1],
    eMail2: contact?.eMail2,
    phone: contact?.phone,
    phone2: contact?.phone2,
    phone3: contact?.phone3,
    cellphone: contact?.cellphone,
    cellphone2: contact?.cellphone2,
    cellphone3: contact?.cellphone3,
    notes: contact?.notes,
    signaturePrefix: contact?.signaturePrefix,
    fax: contact?.fax,
  });

  const editCompanyData = async () => {
    setEditModeCompanyData(true);
  };
  const cancelEditCompanyData = () => {
    companyDataForm.setFieldsValue(companyDataFromEmployee(contactCast));
    setEditModeCompanyData(false);
  };

  const handleFinishCompanyData: (value: CompanyDataFormModel) => void =
    useCallback(
      async (value: CompanyDataFormModel) => {
        const { eMailPrefix, eMailSuffix, ...rest } = value;
        let nullCheckedObject = Object.keys(rest).reduce((sum, currentKey) => {
          if (
            rest[currentKey] ||
            (rest[currentKey] === '' && contact[currentKey])
          ) {
            return {
              ...sum,
              [currentKey]: rest[currentKey],
            };
          }
          return sum;
        }, {});

        const editedUser = contact
          ? {
              ...nullCheckedObject,
              eMail: `${eMailPrefix}@${eMailSuffix}`,
            }
          : null;

        dispatch(
          updateInternalContact(
            editedUser,
            contact.contactId,
            contact.rowVersion,
            contact,
            officeId
          )
        );

        setEditModeCompanyData(false);
      },
      [contact, dispatch, officeId]
    );

  const validateEmail = async (email) => {
    const { data } = await apiValidateEmail(email);

    if (data?.validEmail) {
      return Promise.resolve('validate Email');
    } else {
      return Promise.reject(t('users:form.validation.invalidEmail'));
    }
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setEditModeCompanyData(false);
  }, [employeeId]);

  useEffect(() => {
    if (contactCast) {
      companyDataForm.setFieldsValue(companyDataFromEmployee(contactCast));
    }
  }, [contactCast, companyDataForm]);

  useEffect(() => {
    const loadEmailSuffixes = async () => {
      const { data } = await apiFetchEmailSuffixes();
      if (data) {
        setEmailSuffixes(data);
        if (data?.length > 0) {
          form.setFieldsValue({
            internalContact: { eMailSuffix: data[0] },
          });
        }
      }
    };
    dispatch(fetchTitleSuggestions());
    dispatch(fetchContactClassifications());
    loadEmailSuffixes();
  }, [dispatch, form]);

  useEffect(() => {
    setDisabled(!editModeCompanyData || isSaving);
    if (!!editModeCompanyData || isSaving) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) setConfigurations(data);
      };
      loadConfigs();
    }
  }, [editModeCompanyData, isSaving]);

  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Form<CompanyDataFormModel>
        initialValues={companyDataFromEmployee(contactCast)}
        form={companyDataForm}
        layout="vertical"
        className={classes.form}
        onFinish={handleFinishCompanyData}
      >
        <Flex.Column className={classes.formContent}>
          <Flex.Row>
            <Col className={classes.companyDataBoxLeft} span={12}>
              <div className={classes.heading}>
                <Typography.Text type="secondary">
                  {t('hr:personnelFile.form.labels.contactInformation')}
                </Typography.Text>
              </div>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={12}>
                  <Form.Item
                    name={'eMailPrefix'}
                    label={t('users:form.labels.eMailPrefix')}
                    colon={colon}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        message: t('users:form.validation.invalidEmailPrefix'),
                        pattern: emailPrefixPattern,
                      },
                      ({ getFieldValue }) => ({
                        async validator(rule, value) {
                          if (value?.trim()?.length === 0)
                            return Promise.resolve();
                          const email = `${value}@${getFieldValue(
                            'eMailSuffix'
                          )}`;
                          if (!email.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          if (employee) {
                            return Promise.resolve();
                          }
                          return validateEmail(email);
                        },
                      }),
                      {
                        required: true,
                        message: t('users:form.validation.missingEmailPrefix'),
                      },
                    ]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'eMailSuffix'}
                    label={t('users:form.labels.eMailSuffix')}
                    colon={colon}
                    validateTrigger="onChange"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'projects:form.validation.missingEmailSuffix'
                        ),
                      },
                      ({ getFieldValue }) => ({
                        async validator(rule, value) {
                          if (value?.trim()?.length === 0)
                            return Promise.resolve();
                          if (value == null) {
                            return Promise.reject(
                              t('projects:form.validation.missingEmailSuffix')
                            );
                          }
                          const email = `${getFieldValue(
                            'eMailPrefix'
                          )}@${value}`;
                          if (!email.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          if (employee) {
                            return Promise.resolve();
                          }
                          return validateEmail(email);
                        },
                      }),
                    ]}
                  >
                    <Select
                      className={classNames(classes.eMailSuffixSelect, {
                        [classes.disabledPicker]: disabled,
                      })}
                      disabled={disabled}
                    >
                      {emailSuffixes.map((emailSuffix) => (
                        <Select.Option value={emailSuffix} key={emailSuffix}>
                          {emailSuffix}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    name={'eMail2'}
                    label={t('users:form.labels.eMail2')}
                    colon={colon}
                    validateTrigger="onChange"
                    rules={[
                      () => ({
                        async validator(rule, value) {
                          if (!value || value?.trim()?.length === 0) {
                            return Promise.resolve();
                          }
                          const email = value;
                          if (!email?.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    name={'eMail3'}
                    label={t('users:form.labels.eMail3')}
                    colon={colon}
                    validateTrigger="onChange"
                    rules={[
                      () => ({
                        async validator(rule, value) {
                          if (!value || value?.trim()?.length === 0) {
                            return Promise.resolve();
                          }
                          const email = value;
                          if (!email?.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  span={12}
                  style={{ paddingRight: theme.old.spacing.unit(1) }}
                >
                  <Form.Item
                    name={'phone'}
                    label={t('contacts:form.labels.phone')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'cellphone'}
                    label={t('contacts:form.labels.cellphone')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key ===
                                    ConfigurationKeys.REGEX_MOBILE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  span={12}
                  style={{ paddingRight: theme.old.spacing.unit(1) }}
                >
                  <Form.Item
                    name={'phone2'}
                    label={t('contacts:form.labels.phone2')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'cellphone2'}
                    label={t('contacts:form.labels.cellphone2')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key ===
                                    ConfigurationKeys.REGEX_MOBILE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  span={12}
                  style={{ paddingRight: theme.old.spacing.unit(1) }}
                >
                  <Form.Item
                    name={'phone3'}
                    label={t('contacts:form.labels.phone3')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'cellphone3'}
                    label={t('contacts:form.labels.cellphone3')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key ===
                                    ConfigurationKeys.REGEX_MOBILE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    name={'fax'}
                    label={t('contacts:form.labels.fax')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidFax'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_FAX
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_FAX
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                gutter={theme.old.spacing.unit(rowGutter)}
                style={{
                  marginRight: '0px',
                }}
              >
                <Col
                  span={24}
                  style={{ padding: `0 0 0 ${theme.old.spacing.unit(5)}px` }}
                >
                  <div className={classes.heading}>
                    <Typography.Text type="secondary">
                      {t('hr:personnelFile.form.labels.companyInformation')}
                    </Typography.Text>
                  </div>
                  <Form.Item
                    name={'officeId'}
                    label={t('users:form.labels.officeId')}
                    rules={[
                      {
                        required: true,
                        message: t('users:form.validation.missingOfficeId'),
                      },
                    ]}
                  >
                    <OfficePicker disabled={disabled} onlyInternal />
                  </Form.Item>
                  <Form.Item
                    name={'managerId'}
                    label={t('users:form.labels.managerId')}
                  >
                    <ContactPicker
                      contactType="InternalContact"
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'department'}
                    label={t('contacts:form.labels.department')}
                    colon={colon}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                  <Form.Item
                    name={'position'}
                    label={t('contacts:form.labels.position')}
                    colon={colon}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Flex.Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)} style={{ flex: 1 }}>
            <Col span={12}>
              <Form.Item
                name={'signaturePrefix'}
                label={t('users:form.labels.signaturePrefix')}
                colon={colon}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'notes'}
                label={t('contacts:form.labels.notes')}
                colon={colon}
              >
                <Input.TextArea disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginBottom: '0px' }} />
        </Flex.Column>
        <Flex.Column className={classes?.paddingTopOnLastRow}>
          <Flex.Row
            alignSelf="flex-end"
            childrenGap={theme.old.spacing.unit(1)}
          >
            {editModeCompanyData && (
              <Button
                type="link"
                onClick={cancelEditCompanyData}
                disabled={isSaving}
                style={{ marginRight: '8px' }}
              >
                {t('common:actions.cancel')}
              </Button>
            )}
            {editModeCompanyData ? (
              <Button type={'primary'} htmlType="submit" disabled={isSaving}>
                {t('common:save')}
              </Button>
            ) : (
              <Button
                type={'link'}
                onClick={(e) => {
                  e.preventDefault();
                  editCompanyData();
                }}
                disabled={isSaving || !editInternalContactAllowed}
                style={{ paddingRight: '11px' }}
              >
                {t('common:edit')}
              </Button>
            )}
          </Flex.Row>
        </Flex.Column>
      </Form>
    </Flex.Column>
  );
};

export default PersonnelFileCompanyDataForm;
