import { Drawer, Tabs, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../models/ProjectExtension';
import { ProjectExtensionId, ProjectId } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  apiCreateProjectExtensionForProject,
  apiUpdateProjectExtensionForProject,
} from '../../api';
import LinkProjectExtensionForm from './ExtensionForms/Link/LinkProjectExtensionForm';
import ExtensionAccessList from './ExtensionAccessList';
import { SharedMailboxProjectExtensionFormWrapper } from './ExtensionForms/SharedMailbox/SharedMailboxProjectExtensionFormWrapper';
import SharepointProjectExtensionForm from './ExtensionForms/Sharepoint/SharepointProjectExtensionForm';
import PlannerProjectExtensionForm from './ExtensionForms/Planner/PlannerProjectExtensionForm';
//import SharedMailboxProjectExtensionFormWrapper from './Forms/SharedMailboxProjectExtensionFormWrapper';
//import SharepointProjectExtensionForm from './Forms/SharepointProjectExtensionForm';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  drawerContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {},
  tabs: {
    '&.ant-tabs': {
      height: '100%',
    },
    '& .ant-tabs-content': {
      height: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
    },
  },
}));

interface ProjectExtensionDrawerProps {
  projectExtensionDrawerVisible: boolean;
  setProjectExtensionDrawerVisible: (boolean) => void;
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectExtensionDrawerTab: ProjectExtensionDrawerTabs;
  setProjectExtensionDrawerTab: (ProjectExtensionDrawerTabs) => void;
  projectId: ProjectId;
  projectExtensionCreated: (projectExtension: ProjectExtension) => void;
  projectExtensionUpdated: (projectExtension: ProjectExtension) => void;
  removeExtension?: (projectExtension: ProjectExtension) => void;
  reloadProjectExtension: () => void;
}

export type ProjectExtensionDrawerTabs = 'settings' | 'users';

export const ProjectExtensionDrawer: React.FC<ProjectExtensionDrawerProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    projectExtensionDrawerVisible,
    setProjectExtensionDrawerVisible,
    projectExtension,
    addProjectExtension,
    projectExtensionDrawerTab,
    setProjectExtensionDrawerTab,
    projectExtensionCreated,
    projectExtensionUpdated,
    projectId,
    reloadProjectExtension,
    removeExtension,
  } = props;
  //#region ------------------------------ States / Attributes / Selectors

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onClose = () => {
    setProjectExtensionDrawerVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  const createProjectExtension = async (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ): Promise<ProjectExtension> => {
    // create new project extension with api
    const { data } = await apiCreateProjectExtensionForProject(projectId, [
      createProjectExtension,
    ]);
    if (data) {
      var createdExtension = data.filter(
        (e) => e.displayName === createProjectExtension.displayName
      )[0];
      projectExtensionCreated(createdExtension);
      return createdExtension;
    } else {
      return null;
    }
  };
  const updateProjectExtension = async (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    updateprojectExtension: UpdateProjectExtension
  ): Promise<ProjectExtension> => {
    // create new project extension with api
    const { data } = await apiUpdateProjectExtensionForProject(
      projectId,
      projectExtensionId,
      updateprojectExtension
    );
    if (data) {
      projectExtensionUpdated(data);
      return data;
    } else {
      return null;
    }
  };
  let component: React.ReactNode;

  switch (
    projectExtension?.projectExtensionType ||
    addProjectExtension?.projectExtensionType
  ) {
    case 'sharedMailbox':
      component = (
        <SharedMailboxProjectExtensionFormWrapper
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;
    case 'sharepoint':
      component = (
        <SharepointProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;

    case 'url':
      component = (
        <LinkProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;

    case 'planner':
      component = (
        <PlannerProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;

    default:
      component = <p>{t(`projects:addIn.notSupportedYet`)}</p>;
      break;
  }

  return (
    <Drawer
      width={theme.old.components.drawerWidth}
      placement="right"
      visible={projectExtensionDrawerVisible}
      className={classes.root}
      destroyOnClose={true}
      onClose={onClose}
      footer={
        <>
          {(projectExtension?.projectExtensionType === 'url' ||
            projectExtension?.projectExtensionProcess?.extensionState ===
              'draft') && (
            <Button
              type="default"
              onClick={() => {
                if (removeExtension) {
                  removeExtension(projectExtension);
                }
              }}
              iconProp={['fal', 'trash']}
            >
              {' '}
              {t('common:delete')}
            </Button>
          )}
        </>
      }
    >
      <div className={classes.drawerContent}>
        <Typography.Title level={2}>
          {t(`projects:addIn.title`)}
          {' - '}
          {t(
            `projects:addIn.${
              projectExtension?.projectExtensionType ??
              addProjectExtension?.projectExtensionType
            }`
          )}
        </Typography.Title>
        <Tabs
          onChange={setProjectExtensionDrawerTab}
          activeKey={projectExtensionDrawerTab}
          className={classes.tabs}
        >
          <Tabs.TabPane
            tab={t('projects:addIn.tab.projectExtension')}
            key="settings"
          >
            {component}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('projects:addIn.tab.projectExtensionAccess')}
            key="users"
            disabled={addProjectExtension !== null}
          >
            {projectExtension?.hasDefaultAccess ? (
              <p>{t('projects:addIn.access.hasDefaultAccess')}</p>
            ) : (
              <ExtensionAccessList
                projectId={projectId}
                projectExtension={projectExtension}
                reloadProjectExtension={reloadProjectExtension}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
};

export default ProjectExtensionDrawer;
