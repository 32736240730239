import React, { forwardRef, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ContactsMenu } from './ContactsMenu';
import {
  ContactSelectionListRef,
  ContactsSelectionList,
} from './ContactSelectionList';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { ContactSearchType } from '../../../models/Types';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  contactsNavContainer: {
    maxWidth: theme.old.components.menuMaxWidth,
    overflow: 'auto',
  },
  selectionList: {
    borderRight: theme.old.borders.sub,
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  maxWidthContactList: {
    maxWidth: '350px',
  },
}));

interface ContactsNavigationProps {
  projectId: string;
  className?: string;
  pathPrefix?: string;
  selectedListIds?: string[];
  setSelectedListIds?: (contacts: string[]) => void;
}

export const ContactsNavigation = forwardRef(
  (
    props: ContactsNavigationProps,
    ref: React.ForwardedRef<ContactSelectionListRef>
  ) => {
    const classes = useStyles();
    const { selectedList, contactId, companyId } = useParams();
    const {
      className,
      projectId,
      pathPrefix,
      selectedListIds,
      setSelectedListIds,
    } = props;

    const contactSelectionListRef = useRef<ContactSelectionListRef>(null);

    useEffect(() => {
      setSelectedListIds([]);
    }, [selectedList, setSelectedListIds]);

    // #region ------------------------------ Ref
    React.useImperativeHandle(ref, () => ({
      getAllItems: () => {
        return contactSelectionListRef.current?.getAllItems();
      },
    }));
    // #endregion

    return (
      <Flex.Row className={classNames(classes.root, className)} flex={2}>
        <Flex.Item
          flex={2}
          className={classNames(
            'prio-module-navigation',
            classes.contactsNavContainer
          )}
        >
          <ContactsMenu
            projectId={projectId}
            selectedList={selectedList}
            pathPrefix={pathPrefix}
          />
        </Flex.Item>
        <Flex.Item flex={3} className={classes.maxWidthContactList}>
          <ContactsSelectionList
            ref={contactSelectionListRef}
            projectId={projectId}
            className={classes.selectionList}
            type={selectedList as ContactSearchType}
            pathPrefix={pathPrefix}
            activeId={contactId || companyId}
            setSelectedListIds={setSelectedListIds}
            selectedListIds={selectedListIds}
          />
        </Flex.Item>
      </Flex.Row>
    );
  }
);

export default ContactsNavigation;
