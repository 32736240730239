import {
  ChangeEvent,
  ForwardRefRenderFunction,
  MutableRefObject,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { Button, Dropdown, Input } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import useResizeObserver from 'use-resize-observer';
import Flex from '../Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../theme/types';
import { FormikProps } from 'formik';
import { createUltimateForm } from '../UltimateForm/createUltimateForm';
import {
  UltimateFilterTagTagPopoverContentType,
  UltimateFilterTag,
  CustomLabel,
} from './Tags/UltimateFilterTag';
import { makePrioStyles } from '../../theme/utils';
import _ from 'lodash';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          padding: `0 ${theme.old.spacing.unit(1.5)}px`,
          background: 'transparent',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
    '&.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover':
      {
        zIndex: 1,
      },
    '& .ant-input-group': {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      height: 32,
    },
    '& .ant-input-group-addon': { padding: 0, display: 'block' },
    '& .ant-input-group-addon:first-child': { width: 125 },
    '& .ant-input-group-addon:last-child': { width: 50 },
    '& .ant-input-group-addon .ant-select': {
      margin: 0,
    },
    '& .ant-input-affix-wrapper': {
      padding: `0 11px`,
    },
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input-prefix': {
      overflowX: 'scroll',
      overflowY: 'hidden',
      maxWidth: 'calc(100% - 52.125px)',
      paddingTop: 4,
    },
    '& .ant-input-affix-wrapper > input.ant-input': {
      minWidth: 4,
      padding: `4px 0`,
    },
    '& .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector':
      {
        height: 30,
      },
    '& .ant-select-single .ant-select-selector .ant-select-selection-item': {
      lineHeight: '28px',
    },
  },
  button: {
    '&.ant-btn': {
      height: 24,
      padding: 0,
    },
  },
  dropdownFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface UltimateFilterTagConfigValues<T> {
  contentType: UltimateFilterTagTagPopoverContentType;
  tagLabel: string;
  customLabel?: CustomLabel<T>;
  popoverLabel?: string;
  blockRender?: boolean;
  customRender?: (value: any) => JSX.Element;
}

export declare type UltimateFilterTagConfig<T extends object> = {
  [Key in keyof T]: UltimateFilterTagConfigValues<T[Key]>;
};

export interface UltimateFilterSearchRef<T extends object> {
  formik: FormikProps<T>;
  input: MutableRefObject<Input>;
  value: string | T;
  hasValue: boolean;
  isCurrentSearchAdvancedSearch: boolean;
  submit: () => Promise<void>;
  resetSearch: (shouldSubmit?: boolean) => void;
  setTextValue: (value: string, shouldSubmit?: boolean) => void;
  setFilterValue: (
    value: Partial<T>,
    shouldSubmit?: boolean,
    persistOnFirstReset?: boolean
  ) => void;
}

export declare type IgnoreValueConfig<T> = Partial<{
  [key in keyof T]: any[];
}>;

export interface UltimateFilterSearchProps<T extends object> {
  className?: string;
  filterDropdownClassName?: string;
  disableDropDown?: boolean;
  disableInput?: boolean;
  disableTyping?: boolean;
  placeholder?: string;
  addonBefore?: JSX.Element;
  tagsPopOverConfig: UltimateFilterTagConfig<T>;
  iconWhenValue?: ReactNode;
  initialFilterValues?: T;
  disableLastSearch?: boolean;
  searchInputValue?: string;
  valuesIgnoreConfig?: IgnoreValueConfig<T>;
  children:
    | React.ReactNode
    | ((formikHelpers: FormikProps<T>) => React.ReactNode);
  dropDownRender?: (formik: FormikProps<T>) => ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  onSearchInputChange?: (value: string) => void;
  onSearchInputSubmit?: (value: string) => void;
  onFilterSubmitReset?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClearSearchTerm?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  onFilterSubmit?: (values: T) => void;
  onFilterChange?: (values: T, changedValue: [keyof T, any]) => void;
  onDropDownOpen?: (value: boolean) => void;
}

declare type ValueOptions = 'keep' | 'reset';
export function createUltimateFilter<T extends object>() {
  const UltimateForm = createUltimateForm<T>();
  const UltimateFilterInner: ForwardRefRenderFunction<
    UltimateFilterSearchRef<T>,
    UltimateFilterSearchProps<T>
  > = (
    {
      disableDropDown,
      className,
      filterDropdownClassName,
      disableInput,
      disableTyping: disableTypingFromParent,
      placeholder,
      addonBefore,
      tagsPopOverConfig,
      onFocus: onFocusFromParent,
      onBlur: onBlurFromParent,
      searchInputValue,
      onSearchInputChange: onSearchInputChangeFromParent,
      onSearchInputSubmit: onSearchInputSubmitFromParent,
      onKeyDown: onKeyDownFromParent,
      children,
      onFilterSubmit: onFilterSubmitFromParent,
      onFilterChange: onFilterChangeFromParent,
      onFilterSubmitReset: onFilterSubmitResetFromParent,
      initialFilterValues,
      iconWhenValue,
      valuesIgnoreConfig,
      dropDownRender,
      onClearSearchTerm: onClearSearchTermFromParent,
      onDropDownOpen,
    },
    forwardedRef
  ) => {
    const classes = useStyles();
    const { ref: root, width: bodyMaxWidth } = useResizeObserver();

    const containerRef = useRef<HTMLInputElement | null>(null);
    const formikRef = useRef<FormikProps<T> | null>(null);
    const textInputRef = useRef<Input>();
    const dropDownRef = useRef<HTMLDivElement | null>(null);
    const inputFieldRef = useRef(null);

    const [typingDisabled, setTypingDisabled] = useState(
      disableTypingFromParent
    );

    const [formValues, setFormValues] = useState<T>(initialFilterValues);
    const [filterVisible, setFilterVisible] = useState<boolean>();

    const [ignoreFirstReset, setIgnoreFirstReset] = useState(false);

    const submitForm = (valueOptions: ValueOptions = 'keep') =>
      new Promise<void>((resolve, reject) => {
        try {
          if (isCurrentSearchAdvancedSearch()) {
            switch (valueOptions) {
              case 'reset': {
                formikRef?.current?.submitForm();
                formikRef?.current?.resetForm({ values: {} as T });
                break;
              }
              case 'keep': {
                formikRef?.current?.submitForm();
                formikRef?.current?.resetForm({
                  values: formikRef?.current?.values,
                });
                break;
              }
              default: {
                break;
              }
            }
          } else {
            onSearchInputSubmit(textInputRef?.current?.input?.value);
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });

    const resetSearch = (shouldSubmit = false) => {
      if (ignoreFirstReset) {
        setIgnoreFirstReset(false);
      } else {
        formikRef?.current?.resetForm({ values: {} as T });
        textInputRef?.current?.setValue('');

        if (shouldSubmit) {
          formikRef?.current?.submitForm();
        }
      }
    };

    useImperativeHandle(forwardedRef, () => ({
      formik: formikRef?.current,
      input: textInputRef,
      value: isCurrentSearchAdvancedSearch()
        ? formValues
        : textInputRef?.current?.input?.value,
      submit: submitForm,
      resetSearch: resetSearch,
      setTextValue: (value, shouldSubmit = false) => {
        if (
          textInputRef &&
          textInputRef.current &&
          textInputRef.current.input &&
          textInputRef.current.input &&
          textInputRef.current.input
        ) {
          textInputRef.current?.setState(
            (prevState) => {
              return {
                ...prevState,
                value: value,
              };
            },
            () => {
              if (shouldSubmit) {
                onSearchInputSubmit(value);
              }
            }
          );
        }
      },
      setFilterValue(
        value: T,
        shouldSubmit = false,
        persistOnFirstReset = false
      ) {
        if (persistOnFirstReset) {
          setIgnoreFirstReset(true);
        }
        formikRef?.current?.setFormikState((s) => ({
          ...s,
          values: value,
        }));

        if (shouldSubmit) {
          formikRef?.current?.submitForm().then(() => {
            formikRef?.current?.resetForm({
              values: formikRef?.current?.values,
            });
          });
        }
      },
      get hasValue() {
        return hasValue();
      },
      get isCurrentSearchAdvancedSearch() {
        return isCurrentSearchAdvancedSearch();
      },
    }));

    //#region -------------------------------- Methods
    const isCurrentSearchAdvancedSearch = () => {
      if (countPropertiesWithValues(formValues, valuesIgnoreConfig) > 0) {
        return true;
      }
      return false;
    };

    const hasValue = () => {
      if (countPropertiesWithValues(formValues, valuesIgnoreConfig) > 0) {
        return true;
      }
      if (textInputRef?.current?.input?.value?.trim()) {
        return true;
      }
      return false;
    };

    const purgeFormValue = useCallback(
      (keyName: string): Promise<void> => {
        const setAllSubproperties = (obj: object, value: any) => {
          const copyOfObject = JSON.parse(JSON.stringify(obj));
          for (let key in copyOfObject) {
            if (
              typeof copyOfObject[key] === 'object' &&
              copyOfObject[key] !== null
            ) {
              setAllSubproperties(copyOfObject[key], value);
            } else {
              copyOfObject[key] = value;
            }
          }
          return copyOfObject;
        };

        return new Promise((resolve, reject) => {
          try {
            const targetValue = formValues[keyName];
            if (
              typeof targetValue === 'object' &&
              targetValue !== null &&
              !Array.isArray(targetValue)
            ) {
              const updatedObject = setAllSubproperties(targetValue, null);
              formikRef?.current?.setFieldValue(keyName, updatedObject);
            } else {
              formikRef?.current?.setFieldValue(keyName, null);
            }
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      },
      [formValues]
    );
    //#endregion

    //#region -------------------------------- Handlers

    const onFilterChange = (values: T, changedValue: [keyof T, any]) => {
      setFormValues(values);
      const hasValues =
        countPropertiesWithValues(values, valuesIgnoreConfig) > 0;
      setTypingDisabled(hasValues);
      onFilterChangeFromParent?.(values, changedValue);
    };

    const onFilterSubmit = (values: T) => {
      setFilterVisible(false);
      onDropDownOpen?.(false);
      onFilterSubmitFromParent?.(values);
    };

    const onFilterReset = () => {
      onFilterSubmitResetFromParent?.();
    };

    const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      _.debounce(() => {
        if (!typingDisabled) {
          onSearchInputChangeFromParent?.(e.target.value);
        }
      }, 200);
    };

    const onSearchInputSubmit = (value?: string) => {
      const hasValues =
        countPropertiesWithValues(formValues, valuesIgnoreConfig) > 0;
      if (hasValues) {
        formikRef?.current?.submitForm();
      }

      onSearchInputSubmitFromParent?.(value);
      setFilterVisible(false);
      onDropDownOpen?.(true);
    };

    const onFocusSearchInput = (
      e: React.FocusEvent<HTMLInputElement, Element>
    ) => {
      e.preventDefault();
      e.stopPropagation();

      onFocusFromParent?.();
    };

    const clearSearchTermAndFocus = (
      e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
      e.stopPropagation();
      e.preventDefault();

      if (isCurrentSearchAdvancedSearch()) {
        submitForm('reset');
      } else {
        textInputRef?.current?.setValue('');
        onSearchInputSubmit('');
      }
      onFocusFromParent?.();
      onClearSearchTermFromParent?.(e);
    };

    const openFilter = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      onFocusFromParent?.();
      setFilterVisible(!filterVisible);
      onDropDownOpen?.(!filterVisible);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (textInputRef?.current?.state?.value === '') {
            const hasValues =
              countPropertiesWithValues(formValues, valuesIgnoreConfig) > 0;

            if (hasValues) {
              const lastKey = getLastKeyWithNonNullValue(formValues);
              purgeFormValue(lastKey).then(() => {
                formikRef?.current?.submitForm();
              });
            }
          }
          break;

        case 'Enter':
          const hasValues =
            countPropertiesWithValues(formValues, valuesIgnoreConfig) > 0;
          if (hasValues) {
            formikRef?.current?.submitForm();
          }
          break;
        default:
          break;
      }

      onKeyDownFromParent?.(event);
    };
    //#endregion

    //#region -------------------------------- Effects / Memos

    useEffect(() => {
      if (formValues) {
        const hasValues =
          countPropertiesWithValues(formValues, valuesIgnoreConfig) > 0;

        setTypingDisabled(hasValues);
      }
    }, [formValues, valuesIgnoreConfig]);

    useEffect(() => {
      const onClick = (e: PointerEvent) => {
        if (
          !containerRef?.current?.contains(e.target as Node) &&
          !dropDownRef?.current?.contains(e.target as Node) &&
          !textInputRef?.current?.input?.contains(e.target as Node)
        ) {
          setFilterVisible(false);
          onBlurFromParent?.();
        }
      };

      document.addEventListener('click', onClick);

      return () => {
        document.removeEventListener('click', onClick);
      };
    }, [onBlurFromParent]);

    const tagElements = useMemo(() => {
      const onDeselectPopoverListItem = (keyName: string, value: string) => {
        const currentValueArray = formValues[keyName];

        if (Array.isArray(currentValueArray)) {
          const newValueArray = currentValueArray?.filter((x) => x !== value);

          formikRef?.current?.setFieldValue(keyName, newValueArray).then(() => {
            formikRef?.current?.submitForm();
          });
        } else {
          const copyOfObject = JSON.parse(JSON.stringify(currentValueArray));
          copyOfObject[value] = null;

          formikRef?.current?.setFieldValue(keyName, copyOfObject).then(() => {
            formikRef?.current?.submitForm();
          });
        }
      };

      const onTagClose = (keyName: string) => {
        purgeFormValue(keyName).then(() => {
          formikRef?.current?.submitForm();
        });
      };

      return (
        <Flex.Row>
          {Object.keys(tagsPopOverConfig).map((keyName) => {
            const tagConfig: UltimateFilterTagConfigValues<T> =
              tagsPopOverConfig[keyName];
            switch (tagConfig.contentType) {
              case 'none': {
                if (
                  formValues &&
                  propertyHasValue(
                    formValues[keyName],
                    keyName,
                    valuesIgnoreConfig
                  ) &&
                  !tagConfig.blockRender
                ) {
                  return (
                    <UltimateFilterTag
                      keyName={keyName}
                      popOverLabel={tagConfig?.popoverLabel}
                      label={tagConfig?.tagLabel}
                      contentType={{
                        type: 'none',
                        customLabel: tagConfig?.customLabel,
                        value: formValues ? formValues[keyName] : null,
                      }}
                      onTagClose={onTagClose}
                    />
                  );
                }
                return null;
              }
              case 'custom': {
                if (
                  formValues &&
                  propertyHasValue(
                    formValues[keyName],
                    keyName,
                    valuesIgnoreConfig
                  ) &&
                  !tagConfig.blockRender
                ) {
                  return (
                    <UltimateFilterTag
                      keyName={keyName}
                      popOverLabel={tagConfig?.popoverLabel}
                      label={tagConfig?.tagLabel}
                      contentType={{
                        type: 'custom',
                        customLabel: tagConfig?.customLabel,
                        value: formValues ? formValues[keyName] : null,
                        customRender: tagConfig?.customRender,
                      }}
                      onTagClose={onTagClose}
                    />
                  );
                }
                return null;
              }

              case 'customArray': {
                if (
                  formValues &&
                  propertyHasValue(
                    formValues[keyName],
                    keyName,
                    valuesIgnoreConfig
                  ) &&
                  !tagConfig.blockRender
                ) {
                  return (
                    <UltimateFilterTag
                      keyName={keyName}
                      popOverLabel={tagConfig?.popoverLabel}
                      label={tagConfig?.tagLabel}
                      contentType={{
                        type: 'customArray',
                        customLabel: tagConfig?.customLabel,
                        value: formValues ? formValues[keyName] : null,
                        customRender: tagConfig?.customRender,
                      }}
                      onDeselectTagListItem={onDeselectPopoverListItem}
                      onTagClose={onTagClose}
                    />
                  );
                }
                return null;
              }
              default: {
                if (
                  formValues &&
                  propertyHasValue(
                    formValues[keyName],
                    keyName,
                    valuesIgnoreConfig
                  ) &&
                  !tagConfig.blockRender
                ) {
                  return (
                    <UltimateFilterTag
                      keyName={keyName}
                      label={tagConfig?.tagLabel}
                      contentType={{
                        type: tagConfig?.contentType,
                        customLabel: tagConfig?.customLabel,
                        value: formValues ? formValues[keyName] : null,
                      }}
                      onDeselectTagListItem={onDeselectPopoverListItem}
                      onTagClose={onTagClose}
                    />
                  );
                }
                return null;
              }
            }
          })}
        </Flex.Row>
      );
    }, [formValues, purgeFormValue, tagsPopOverConfig, valuesIgnoreConfig]);
    //#endregion

    return (
      <div className={classNames(classes.root, className)} ref={root}>
        <div ref={containerRef}>
          <Dropdown
            forceRender
            visible={filterVisible}
            disabled={disableDropDown}
            overlay={
              <div ref={dropDownRef}>
                <div
                  className={classNames(
                    classes.dropdownFilterContainer,
                    filterDropdownClassName
                  )}
                >
                  <UltimateForm
                    ref={formikRef}
                    onChange={onFilterChange}
                    onSubmit={onFilterSubmit}
                    onReset={onFilterReset}
                    initialValues={initialFilterValues}
                  >
                    {children}
                  </UltimateForm>
                  {dropDownRender(formikRef.current)}
                </div>
              </div>
            }
            overlayStyle={{ minWidth: bodyMaxWidth }}
          >
            <div className={classes.searchInput} ref={inputFieldRef}>
              <Input.Search
                ref={textInputRef}
                className={classes.search}
                value={typingDisabled ? '' : searchInputValue}
                disabled={disableInput}
                size="middle"
                placeholder={!typingDisabled ? placeholder : null}
                onChange={onSearchInputChange}
                onSearch={onSearchInputSubmit}
                onFocus={onFocusSearchInput}
                addonBefore={addonBefore}
                enterButton={
                  <Button>
                    {hasValue() ? (
                      iconWhenValue ?? <SearchOutlined />
                    ) : (
                      <SearchOutlined />
                    )}
                  </Button>
                }
                onKeyDown={onKeyDown}
                suffix={
                  <Flex.Row alignItems="center">
                    {hasValue() && (
                      <CloseCircleFilled
                        role="button"
                        tabIndex={-1}
                        className="ant-input-clear-icon-has-suffix ant-input-clear-icon"
                        onClick={clearSearchTermAndFocus}
                      />
                    )}
                    <Button
                      onClick={openFilter}
                      className={classes.button}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      <FontAwesomeIcon
                        icon={[
                          'fal',
                          filterVisible ? 'chevron-up' : 'chevron-down',
                        ]}
                      />
                    </Button>
                  </Flex.Row>
                }
                prefix={tagElements}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    );
  };

  const UltimateFilter = forwardRef(UltimateFilterInner);

  return { UltimateFilter, UltimateForm };
}

//#region -------------------------------- Helpers

const getLastKeyWithNonNullValue = (obj) => {
  const keys = Object.keys(obj);

  for (let i = keys.length - 1; i >= 0; i--) {
    const value = obj[keys[i]];

    if (Array.isArray(value)) {
      if (value.length === 0) {
        continue;
      } else {
        // Check each item in the array
        for (const item of value) {
          if (typeof item === 'object' && item !== null) {
            const keyFromSubObject = getLastKeyWithNonNullValue(item);
            if (keyFromSubObject !== null) {
              return keys[i];
            }
          } else if (item) {
            return keys[i];
          }
        }
      }
    }
    // If the value is an object (and not an array)
    else if (typeof value === 'object' && value !== null) {
      const keyFromSubObject = getLastKeyWithNonNullValue(value);
      if (keyFromSubObject !== null) {
        return keys[i];
      }
    }
    // If the value is a primitive type (number, string, etc.)
    else if (value || value === false) {
      return keys[i];
    }
  }

  return null;
};

export const propertyHasValue = <T extends object>(
  value: any,
  keyName: string,
  ignoreValuesConfig: IgnoreValueConfig<T> = {}
) => {
  // Function to check if the value should be ignored based on the configuration
  const shouldIgnoreValue = (value, key) => {
    if (Array.isArray(ignoreValuesConfig[key])) {
      return ignoreValuesConfig[key].includes(value);
    }
    return false;
  };

  // Base case for arrays
  if (Array.isArray(value)) {
    for (const item of value) {
      // Using a special key for array elements in the config
      if (propertyHasValue(item, 'arrayElement', ignoreValuesConfig)) {
        return true;
      }
    }
    return false;
  }

  // Base case for objects (and not arrays)
  if (typeof value === 'object' && value !== null) {
    const keys = Object.keys(value);
    for (const key of keys) {
      if (
        !shouldIgnoreValue(value[key], key) &&
        propertyHasValue(value[key], key, ignoreValuesConfig)
      ) {
        return true;
      }
    }
    return false;
  }

  // Base case for primitive values
  if (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !shouldIgnoreValue(value, keyName)
  ) {
    return true;
  }

  return false;
};

export const countPropertiesWithValues = <T extends object>(
  obj,
  ignoreValuesConfig: IgnoreValueConfig<T> = {}
) => {
  if (obj === undefined || obj === null) return 0;
  const keys = Object.keys(obj);
  let count = 0;

  for (let i = 0; i < keys.length; i++) {
    const value = obj[keys[i]];

    // If the value is an array or an object
    if (typeof value === 'object' && value !== null) {
      count += propertyHasValue(value, keys[i], ignoreValuesConfig) ? 1 : 0;
      count += countPropertiesWithValues(value, ignoreValuesConfig);
    }
    // If the value is a primitive type (number, string, etc.)
    else if (propertyHasValue(value, keys[i], ignoreValuesConfig)) {
      count++;
    }
  }

  return count;
};

//#endregion
