import React, { useEffect, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateId } from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentTemplates } from '../actions/documentTemplates';
import {
  documentTemplatesGetIsFetching,
  documentTemplatesGetTemplates,
} from '../../../apps/main/rootReducer';
import { DocumentTemplate } from '../../../models/DocumentTemplate';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface DocumentTemplatePickerProps {
  value?: DocumentTemplateId;
  placeholder?: string;
  onChange?: (value: DocumentTemplateId) => void;
  disabled?: boolean;
  filter?: (template: DocumentTemplate) => boolean;
  emptyMessage?: string;
  showSearch?: boolean;
  isReport?: boolean;
}

export const DocumentTemplatePicker: React.FC<DocumentTemplatePickerProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const documentTemplates = useSelector(documentTemplatesGetTemplates);
  const isFetching = useSelector(documentTemplatesGetIsFetching);

  const {
    value,
    onChange,
    disabled,
    placeholder,
    filter,
    emptyMessage,
    showSearch,
  } = props;

  useEffect(() => {
    dispatch(fetchDocumentTemplates);
  }, [dispatch]);

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const filteredDocumentTemplates = useMemo(
    () => (filter ? documentTemplates.filter(filter) : documentTemplates),
    [documentTemplates, filter]
  );

  return (
    <Select
      disabled={disabled || isFetching}
      showSearch={showSearch}
      className={classes.root}
      value={value}
      placeholder={placeholder}
      onChange={handleOnChange}
      optionFilterProp="label"
      filterOption={(input, option) =>
        (option?.label?.toString() ?? '')
          ?.toLowerCase()
          ?.indexOf(input?.toLowerCase() ?? '') >= 0
      }
      loading={isFetching}
    >
      {filteredDocumentTemplates.length === 0 ? (
        <Select.Option value="" disabled>
          {emptyMessage || t('documents:documentTemplatePicker.emptyMessage')}
        </Select.Option>
      ) : null}
      {filteredDocumentTemplates.map((value) => (
        <Select.Option
          key={value.documentTemplateId}
          value={value.documentTemplateId}
          label={value.name}
        >
          {value.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DocumentTemplatePicker;
