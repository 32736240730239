import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootReducerState, getContacts } from '../../../apps/main/rootReducer';
import BasisPicker, {
  BasisPickerSelectOptions,
} from '../../../components/BasisPicker';
import { Contact } from '../../../models/Contact';
import { ContactId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface HRRemainingAbsenceContactPickerProps {
  className?: string;
  contactIds: ContactId[];
  onChange?: (value: string | string[]) => void;
  disabled?: boolean;
  defaultValue?: ContactId | ContactId[];
  value?: ContactId | ContactId[];
}

export const HRRemainingAbsenceContactPicker: React.FC<
  HRRemainingAbsenceContactPickerProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const { className, contactIds, onChange, disabled, value, defaultValue } =
    props;

  const contacts = useSelector<RootReducerState, Contact[]>((state) =>
    getContacts(state, contactIds)
  );

  const selectOptions: BasisPickerSelectOptions[] = contacts.map(
    (contact, index) => {
      return {
        label: `${
          contact && contact.firstName
            ? contact.firstName
            : t('common:errorMessages.unknownObject')
        } ${
          contact && contact.lastName
            ? contact.lastName
            : t('common:errorMessages.unknownObject')
        }`,
        value:
          contact && contact.contactId
            ? contact.contactId
            : `${t('common:errorMessages.unknownObject')} - ${index}`,
        key:
          contact && contact.contactId
            ? contact.contactId
            : `${t('common:errorMessages.unknownObject')} - ${index}`,
        isItalic: !contact,
      };
    }
  );
  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks

  //#endregion

  return (
    <BasisPicker
      defaultValue={defaultValue}
      value={value}
      className={classNames(classes.root, className)}
      items={selectOptions}
      optionFilterProp="label"
      multipleSelect
      onChange={onChange}
      disabled={disabled}
      placeHolder={t('common:all')}
    />
  );
};

export default HRRemainingAbsenceContactPicker;
