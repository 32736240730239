import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import * as ConfigValues from '../../../util/configValues';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
  menuCollapsed: {
    transition: 'padding 0.2s',
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
    },
  },
  iconExternalLink: {
    opacity: 0,
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
    '-webkit-transition': 'all 0.3s ease-out',
    transition: 'all 0.3s ease-out',
  },
  menuItemCalendar: {
    '&:hover .prio-project-menu-external-link': {
      opacity: 1,
    },
  },
  itemCollapsed: {
    '&.ant-menu-item > .ant-menu-title-content': {
      overflow: 'hidden',
    },
  },
}));

interface ProfileMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
  collapsed?: boolean;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const { selectedList, urlPrefix, collapsed, className } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Menu
      className={classNames(classes.menu, className, {
        [classes.menuCollapsed]: collapsed,
      })}
      selectedKeys={[selectedList]}
      mode="inline"
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {navItem(
        'personnelData',
        'address-card',
        t,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
      {navItem(
        'prioExtension',
        'puzzle-piece',
        t,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
      {ConfigValues.FLUTTER_APP_TENANT_NAME &&
        ConfigValues.FLUTTER_APP_CLIENT_ID &&
        navItem(
          'prioApp',
          'mobile',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
    </Menu>
  );
};

export default ProfileMenu;

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string,
  collapsed?: boolean
) => (
  <Menu.Item
    key={itemKey}
    title={
      <>
        {icon != null ? (
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', icon]}
            className={classes.icon}
          />
        ) : (
          <span className={classes.settingsIcon} />
        )}
        <Typography.Text className={classes.navItemText}>
          {t(`profile:profileNavigation.${itemKey}`)}
        </Typography.Text>
      </>
    }
  >
    <Link to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {!collapsed && (
        <Typography.Text className={classes.navItemText}>
          {t(`profile:profileNavigation.${itemKey}`)}
        </Typography.Text>
      )}
    </Link>
  </Menu.Item>
);
