import React, {
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import { Dropdown, Menu, Empty } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { formatNumber, fullDateFormatFormatString } from '../../../util';
import ContactText from '../../contacts/components/ContactText';
import { InternalOffice } from '../../../models/Office';
import { AbsenceProposalId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../apps/main/rootReducer';
import PrioSpinner from '../../../components/PrioSpinner';
import moment from 'moment';
import VirtualTable2, {
  VColumn,
} from '../../../components/VirtualTable/VirtualTable2';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& .ant-spin-nested-loading': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-spin-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  row: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 2,
      bottom: 2,
      left: 0,
      width: 5,
      backgroundColor: 'transparent',
    },
  },
  menuButton: {
    backgroundColor: 'transparent',
    height: '100%',
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
  secondaryColumn: {
    ...theme.old.components.table.secondaryColumn,
    padding: 10,
  },
  primaryColumn: {
    paddingLeft: 10,
    height: '100%',
    '& > span': {
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
  },
  stateCell: {
    height: 64,
    padding: '2px 0!important',
  },
  state: {
    height: '100%',
    width: 5,
  },
  stateGreen: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.green,
    },
  },
  stateYellow: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.yellow,
    },
  },
  stateRed: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
  },
  stateGrey: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.grey,
    },
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface AbsenceManagementTableProps {
  className?: string;
  onRowClick?: (entry: AbsenceProposal) => void;
  absenceProposals: AbsenceProposal[];
  officesById: { [key: string]: InternalOffice };
  onAcceptProposal: (absenceProposal: AbsenceProposal) => Promise<void>;
  onDeclineProposal: (absenceProposal: AbsenceProposal) => Promise<void>;
  onRowSelectionChange?: (selectedProposals: AbsenceProposal[]) => void;
  loading?: boolean;
}

export const AbsenceManagementTable: React.FC<AbsenceManagementTableProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const {
    className,
    onRowClick,
    absenceProposals,
    officesById,
    onAcceptProposal,
    onDeclineProposal,
    onRowSelectionChange,
    loading,
  } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [menuSubmitting, setMenuSubmitting] = useState<boolean>(false);

  const contactsByIdState = useSelector(getContactsByIdState);

  const [selected, setSelected] = useState<AbsenceProposal[]>([]);

  const onSelectionChange = (items: AbsenceProposal[]) => {
    if (onRowSelectionChange) {
      onRowSelectionChange(items);
    }
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const isSelected = useCallback(
    (item: AbsenceProposal) => {
      return !!selected.find(
        ({ absenceProposalId }) => absenceProposalId === item.absenceProposalId
      );
    },
    [selected]
  );

  const triggerFunctions = (
    record: AbsenceProposal
  ): HTMLAttributes<HTMLElement> => {
    return {
      onClick: onRowClick
        ? () => {
            onRowClick(record);
          }
        : null,
    };
  };

  const getClassNameTableRow = (item: AbsenceProposal) => {
    switch (item.absenceState) {
      case 'accepted':
        return classNames(classes.row, classes.stateGreen);
      case 'declined':
        return classNames(classes.row, classes.stateRed);
      case 'planned':
        return classNames(classes.row, classes.stateGrey);
      case 'requested':
        return classNames(classes.row, classes.stateYellow);
      case 'revokeAccepted':
        return classNames(classes.row, classes.stateRed);
      case 'revokeDeclined':
        return classNames(classes.row, classes.stateGreen);
      case 'revokeRequested':
        return classNames(classes.row, classes.stateGrey);
      default: {
        return classes.row;
      }
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const noItemsScreen = useCallback(
    () => (
      <div className={classes.noItemsScreen}>
        <div className={classes.noItemsIconContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className={classes.noItemsText}>
                {t('documents:table.noItems')}
              </span>
            }
          />
        </div>
      </div>
    ),
    [classes, t]
  );

  const menu = useCallback(
    (entry: AbsenceProposal) => (
      <Menu>
        <Menu.Item
          disabled={
            menuSubmitting ||
            entry.absenceState === 'accepted' ||
            entry.absenceState === 'declined' ||
            entry.absenceState === 'revokeAccepted' ||
            entry.absenceState === 'revokeDeclined'
          }
          onClick={async (e) => {
            e.domEvent.stopPropagation();
            setMenuSubmitting(true);
            await onAcceptProposal(entry);
            setMenuSubmitting(false);
          }}
        >
          {t('absences:tableMenu.accept')}
        </Menu.Item>
        <Menu.Item
          disabled={
            menuSubmitting ||
            entry.absenceState === 'accepted' ||
            entry.absenceState === 'declined' ||
            entry.absenceState === 'revokeAccepted' ||
            entry.absenceState === 'revokeDeclined'
          }
          onClick={async (e) => {
            e.domEvent.stopPropagation();
            setMenuSubmitting(true);
            await onDeclineProposal(entry);
            setMenuSubmitting(false);
          }}
        >
          {t('absences:tableMenu.decline')}
        </Menu.Item>
      </Menu>
    ),
    [menuSubmitting, t, onAcceptProposal, onDeclineProposal]
  );
  //#endregion

  //#region ------------------------------ Columns
  const columns: VColumn<AbsenceProposal>[] = useMemo(
    () => [
      {
        id: 'applicantId',
        width: 25,
        title: t('absences:absenceManagement.table.columnTitle.applicantId'),
        accessor: 'applicantId',
        sorter: (a, b) =>
          contactsByIdState[
            a.applicantId.toLowerCase()
          ]?.lastName?.localeCompare(
            contactsByIdState[b.applicantId.toLowerCase()]?.lastName
          ),
        Cell: ({ row: { original } }) => (
          <Flex.Row childrenGap={10} className={classes.fullHeight}>
            <Flex.Row
              alignItems="center"
              flex={1}
              className={classes.fullHeight}
            >
              <ContactText contactId={original.applicantId.toLowerCase()} />
            </Flex.Row>
            <Dropdown
              overlay={menu(original)}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                iconProp={['fal', 'ellipsis-v']}
                className={classes.menuButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                type="default"
              />
            </Dropdown>
          </Flex.Row>
        ),
        className: classes.primaryColumn,
      },
      ...((officesById
        ? [
            {
              id: 'officeId',
              accessor: 'officeId',
              title: t('absences:absenceManagement.table.columnTitle.officeId'),
              sortType: (rowA, rowB) =>
                officesById[rowA.original.officeId]?.name?.localeCompare(
                  officesById[rowB.original.officeId]?.name
                ),
              className: classes.secondaryColumn,
              width: 20,
              Cell: ({
                row: {
                  original: { officeId },
                },
              }) => <>{officeId && officesById[officeId]?.name}</>,
            },
          ]
        : []) as VColumn<AbsenceProposal>[]),
      {
        id: 'absenceType',
        accessor: 'absenceType',
        title: t('absences:absenceManagement.table.columnTitle.absenceType'),
        className: classes.secondaryColumn,
        sortType: (rowA, rowB) =>
          t(`absences:types.${rowA.original.absenceType}`).localeCompare(
            t(`absences:types.${rowB.original.absenceType}`)
          ),

        Cell: ({ row: { original } }) => (
          <>{t(`absences:types.${original.absenceType}`)}</>
        ),
        width: 10,
      },
      {
        id: 'period',
        width: 20,
        accessor: 'from',
        sortType: (rowA, rowB) =>
          moment(rowA.original.from).diff(
            moment(rowB.original.from),
            'minutes'
          ),
        title: t('absences:absenceManagement.table.columnTitle.period'),
        className: classes.secondaryColumn,
        Cell: ({ row: { original } }) => (
          <>
            {original.from === original.to ? (
              fullDateFormatFormatString(original.from)
            ) : (
              <>
                {fullDateFormatFormatString(original.from)} {t('common:to')}{' '}
                {fullDateFormatFormatString(original.to)}
              </>
            )}
          </>
        ),
      },
      {
        id: 'days',
        width: 5,
        accessor: 'to',
        sortType: (rowA, rowB) =>
          rowA.original.absentWorkdays - rowB.original.absentWorkdays,
        title: t('absences:absenceManagement.table.columnTitle.days'),
        className: classes.secondaryColumn,
        Cell: ({ row: { original } }) => (
          <>{formatNumber(original.absentWorkdays)}</>
        ),
      },

      {
        id: 'principalId',
        width: 20,
        sortType: (rowA, rowB) =>
          contactsByIdState[rowA.original.principalId]?.lastName?.localeCompare(
            contactsByIdState[rowB.original.principalId]?.lastName
          ),
        accessor: 'principalId',
        title: t('absences:absenceManagement.table.columnTitle.principalId'),
        className: classes.secondaryColumn,
        Cell: ({ value }) => <ContactText contactId={value} plain />,
      },
    ],
    [classes, contactsByIdState, officesById, t, menu]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setSelected([]);
  }, [absenceProposals]);
  //#endregion

  return (
    <VirtualTable2<AbsenceProposal, AbsenceProposalId[]>
      id={'hr-absence-page-table'}
      className={classNames(classes.root, className)}
      selected={isSelected}
      columns={columns}
      data={absenceProposals ?? []}
      rowsAreSelectable
      overscanRowCount={10}
      loading={
        loading && {
          loadingType: 'table',
          indicator: <PrioSpinner alignSelf />,
        }
      }
      noItemsScreen={noItemsScreen()}
      onRow={{
        triggerFunctions: triggerFunctions,
      }}
      onChange={onSelectionChange}
      dataToForceRender={(absenceProposals ?? []).map(
        ({ absenceProposalId }) => absenceProposalId
      )}
      classNameTableRow={getClassNameTableRow}
      columnsResizable
    />
  );
};

export default AbsenceManagementTable;
