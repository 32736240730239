import React, { useState } from 'react';
import {} from 'antd';
import { DateRangePicker } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import './styles/customDateRangePicker.css';
import moment, { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    fontWeight: 'bold',
  },
  navigationIcons: {
    position: 'absolute',
    top: 26,
  },
}));

interface CustomDateRangePickerProps {
  className?: string;
  startDate: Moment;
  startDateId: String;
  startDatePlaceholderText?: String;
  endDate: Moment;
  endDateId: String;
  endDatePlaceholderText?: String;
  onDatesChange: (value: { startDate: Moment; endDate: Moment }) => void;
  focusedInput?: any;
  onFocusChange?: (focusedInput: any) => void;
  anchorDirection?: String;
  small?: boolean;
  regular?: boolean;
  withFullScreenPortal?: boolean;
  daySize?: number;
  hideKeyboardShortcutsPanel?: boolean;
  minimumNights?: number;
  showClearDates?: boolean;
  isOutsideRange?: boolean;
  disabled?: boolean;
}

export const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [focusedInput, setFocusedInput] = useState(props.focusedInput ?? null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div>
      <DateRangePicker
        className={classNames(classes.root, props.className)}
        navPrev={
          <FontAwesomeIcon
            className={classes.navigationIcons}
            style={{ left: 28 }}
            icon={['fal', 'chevron-left']}
          />
        }
        navNext={
          <FontAwesomeIcon
            className={classes.navigationIcons}
            style={{ right: 28 }}
            icon={['fal', 'chevron-right']}
          />
        }
        disabled={props.disabled}
        startDate={props.startDate} // momentPropTypes.momentObj or null,
        startDateId={props.startDateId} // PropTypes.string.isRequired,
        startDatePlaceholderText={props.startDatePlaceholderText}
        endDate={props.endDate} // momentPropTypes.momentObj or null,
        endDateId={props.endDateId} // PropTypes.string.isRequired,
        endDatePlaceholderText={props.endDatePlaceholderText}
        onDatesChange={props.onDatesChange} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput);
        }} // PropTypes.func.isRequired,
        anchorDirection={props.anchorDirection}
        small={props.small}
        regular={props.regular}
        withFullScreenPortal={props.withFullScreenPortal}
        daySize={props.daySize}
        hideKeyboardShortcutsPanel={props.hideKeyboardShortcutsPanel}
        minimumNights={props.minimumNights}
        showClearDates={props.showClearDates}
        isOutsideRange={(day) => {
          if (props.isOutsideRange) {
            let _day = day as Moment;
            if (_day.isBefore(moment())) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }}
      />
    </div>
  );
};

export default CustomDateRangePicker;
