import React, { useEffect, useState } from 'react';
import Flex from '../../../components/Flex';
import { Select, Tooltip, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { EmailString, ProjectId } from '../../../models/Types';
import {
  EmailSearchResult,
  EmailSearchResultContact,
  EmailSearchResultProject,
} from '../../../models/Message';
import useAddressSearch from '../hooks/useAddressSearch';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import TransBtn from 'rc-select/lib/TransBtn';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import PrioSpinner from '../../../components/PrioSpinner';
import { emailPattern } from '../../../hooks/useEmailValidation';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStylesAdressSelect = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    '& .ant-select-selection-item-content .secondary': {
      display: 'none',
    },
  },
  loadingOverlay: {
    background: '#FFFFFFBF',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  secondary: {
    color: theme.old.typography.colors.muted,
  },
  tag: {
    '& .secondary': {
      display: 'none',
    },
  },
  suggestionLabel: {
    fontSize: theme.old.typography.fontSize.tiny,
    color: theme.old.typography.colors.muted,
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
    borderBottom: theme.old.borders.content,
  },
}));

const filterAdresses: (
  input: string,
  option: OptionData | OptionGroupData
) => boolean = (input, option) => {
  const splittedSearchTerm = input
    ?.split(/\s/)
    ?.map((split) => split.toLocaleLowerCase());

  const address: EmailSearchResultContact | EmailSearchResultProject =
    option.data;

  if (!address) {
    return false;
  }

  const searchObject = {
    ...(address?.eMail ? { eMail: address.eMail } : {}),
    ...(address.type === 'contact'
      ? {
          firstName: (address as EmailSearchResultContact).firstName,
          lastName: (address as EmailSearchResultContact).lastName,
        }
      : {
          projectName: (address as EmailSearchResultProject).projectName,
          projectNumber: (address as EmailSearchResultProject).projectNumber,
        }),
  };

  const searchArray = Object.values(searchObject)
    .filter((value) => !!value)
    .map((value) => value.toLocaleLowerCase());

  let matches = 0;
  splittedSearchTerm.forEach((split) => {
    if (searchArray.find((value) => value.includes(split))) {
      matches++;
    }
  });

  if (splittedSearchTerm.length > 0 && matches >= splittedSearchTerm.length) {
    return true;
  }

  return false;
};

const tagRenderer = (props: CustomTagProps, classes: Classes) => {
  const { label, value, closable, onClose } = props;

  return (
    <span className={'ant-select-selection-item'}>
      <Tooltip title={value} className={'ant-select-selection-item-content'}>
        {label}
      </Tooltip>
      {closable && (
        <TransBtn
          className={'ant-select-selection-item-remove'}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          customizeIcon={<CloseOutlined />}
        />
      )}
    </span>
  );
};

const emailSearchResultToHTMLText = (
  value: EmailSearchResult,
  classes: Classes
) => {
  if (value.type === 'contact') {
    const contact = value as EmailSearchResultContact;
    if (contact.lastName && contact.firstName) {
      return (
        <>
          <Typography.Text ellipsis>
            {`${contact?.firstName} ${contact?.lastName}`}
          </Typography.Text>
          <Typography.Text
            ellipsis
            className={classNames('secondary', classes.secondary)}
          >{` <${contact.eMail}>`}</Typography.Text>
        </>
      );
    }
  } else if (value.type === 'project') {
    const project = value as EmailSearchResultProject;
    if (project.projectName) {
      return (
        <>
          <Typography.Text
            ellipsis
          >{`${project?.projectName}`}</Typography.Text>
          <Typography.Text
            ellipsis
            className={classNames('secondary', classes.secondary)}
          >{` <${project.eMail}>`}</Typography.Text>
        </>
      );
    }
  }
  return (
    <Typography.Text
      ellipsis
      className={classNames('secondary', classes.secondary)}
    >{`<${value.eMail}>`}</Typography.Text>
  );
};

interface AddressSelectProps {
  className?: string;
  value?: string[];
  changeHandler?: (value: EmailString[]) => void;
  focus?: boolean;
  bordered?: boolean;
  maxCount?: number | 'responsive';
  open?: boolean;
  closeableTags?: boolean;
  projectId: ProjectId;
  excludedMails?: EmailString[];
}

const AdressSelect: React.FC<AddressSelectProps> = React.memo((props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    value,
    changeHandler,
    focus,
    bordered,
    maxCount,
    open,
    closeableTags,
    projectId,
    excludedMails,
  } = props;
  const classes = useStylesAdressSelect(props);
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [searchTerm, setSearchTerm] = useState<string>('');
  const isSearching = searchTerm !== '';
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const { addresses, isSearching: onlineLoading } = useAddressSearch(
    {
      searchTerm,
      isOnline,
      projectId,
    },
    value
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setSearchTerm('');
  }, [value]);
  //#endregion

  return (
    <Select<EmailString[]>
      mode="tags"
      bordered={bordered ?? false}
      className={classNames(classes.root, className)}
      value={value ?? []}
      tokenSeparators={['; ', ';', ',']}
      open={open}
      onChange={(value) => {
        const emailValues = value.filter((address) =>
          address.match(emailPattern)
        );
        if (changeHandler) {
          changeHandler(emailValues);
        }
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        return false;
      }}
      autoFocus={focus}
      onSearch={(value: string) => {
        if (value === '') {
          setIsOnline(false);
        }
        setSearchTerm(value);
      }}
      dropdownRender={(menu) => (
        <Flex.Column>
          {onlineLoading && (
            <div className={classes.loadingOverlay}>
              <PrioSpinner />
            </div>
          )}
          {!isSearching && projectId !== 'favorites' && (
            <Typography.Text className={classes.suggestionLabel}>
              {t('contacts:actions.suggestions')}
            </Typography.Text>
          )}
          {menu}
          {!isOnline && (isSearching || projectId === 'favorites') && (
            <Button
              type="link"
              onClick={() => {
                setIsOnline(true);
              }}
              iconProp={['fal', 'plus']}
            >
              {t('contacts:actions.showMore')}
            </Button>
          )}
        </Flex.Column>
      )}
      filterOption={filterAdresses}
      tagRender={(props: CustomTagProps) =>
        tagRenderer({ ...props, closable: closeableTags }, classes)
      }
      onSelect={() => {
        setIsOnline(false);
        setSearchTerm('');
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      maxTagCount={maxCount}
    >
      {addresses
        .filter(
          (address) =>
            !!address && !excludedMails?.find((e) => e === address.eMail)
        )
        .map((address) => (
          <Select.Option
            id={address.eMail}
            key={address.eMail}
            value={address.eMail}
            data={address}
          >
            {emailSearchResultToHTMLText(address, classes)}
          </Select.Option>
        ))}
    </Select>
  );
});

export default AdressSelect;
