import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu, notification } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { apiMessageAddCategories, apiMessageRemoveCategories } from '../../api';
import {
  MailCategoryColorName,
  MessageId,
  ProjectId,
} from '../../../../models/Types';
import { categorizedMessage } from '../../actions/actionControllers/messageActionController';
import { colorFromPreset } from '../../util';
import classNames from 'classnames';
import { abortableDispatch } from '../../../../util/abortableDispatch';
import { fetchMailCategories } from '../../actions/actionControllers/categoriesActionController';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import CategoryTag from './CategoryTag';

export const useMailListCategoryListStyles = makePrioStyles((theme) => ({
  root: {},
  selectIcon: {
    marginRight: theme.old.spacing.unit(2),
  },
  plus: {
    fontSize: 13,
    color: 'rgba(0,0,0,0.6)',
    cursor: 'pointer',
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  tagList: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginLeft: theme.old.spacing.baseSpacing,

    position: 'absolute',
    left: '12px',
    top: 0,
    bottom: 0,

    '& > div > .prio-mail-category-hidden-unless-parent-hover': {
      opacity: '0%',
    },
    '&:hover > div > .prio-mail-category-hidden-unless-parent-hover': {
      opacity: '100%',
    },
    '& > div > #hidden-tag-count': {
      display: 'inline-block',
      textAlign: 'center',
      fontSize: theme.old.typography.fontSize.tiny,
      marginLeft: '3px',
      width: 'fit-content',
    },
    '&:hover > div > #hidden-tag-count': {
      display: 'none',
    },
  },
  tagRoot: {
    minWidth: 28,
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child) ': {
      marginLeft: -15,
    },
    '&:not(:first-child) > span > span.prio-mail-category-name': {
      paddingLeft: 15,
    },
    '& > span > span.prio-mail-category-name': {
      display: 'none',
      textAlign: 'center',
    },
    '&:hover > span > span.prio-mail-category-name': {
      display: 'unset',
    },
  },
  tag: {
    '&.ant-tag': {
      marginRight: 0,
      transition: 'none',
      padding: `0 ${theme.old.spacing.unit(1)}px`,
      minWidth: 28,
    },
    '& .prio-mail-category-name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    height: '1.4em!important',
    fontSize: '0.9em!important',
    border: 'none',
    borderRadius: 10,
    alignItems: 'center',
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& > .name': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  tagCloseIconNoMargin: {
    '& .ant-tag-close-icon': {
      margin: 0,
      opacity: 0,
    },
    '&:hover': {
      '& .ant-tag-close-icon': {
        opacity: 1,
      },
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  mailCategoryMenu: {
    maxHeight: '388px',
    overflowY: 'scroll',
  },
}));

const calculateSmallestWidth = (values: string[]) => {
  const estimatedPixels = values.map((value) => value.length * 6);
  const avg =
    estimatedPixels.reduce((a, b) => a + b, 0) / estimatedPixels.length;
  return avg;
};

interface MailListItemCategoryListProps {
  className?: string;
  classes: Classes<
    | 'root'
    | 'flexRow'
    | 'tag'
    | 'plus'
    | 'selectIcon'
    | 'tagList'
    | 'tagRoot'
    | 'tagCloseIconNoMargin'
    | 'mailCategoryMenu'
  >;
  styles?: React.CSSProperties;
  projectId: ProjectId;
  messageId: MessageId;
  disableText?: boolean;
  onChange?: (value: string | string[]) => void;
  values?: string[];
  colorMap: {
    [displayName: string]: MailCategoryColorName;
  };
  overlapSibling?: boolean;
  loading?: boolean;
  visibleTagCount?: number;
  tagListStyle?: React.CSSProperties;
}

export const MailListItemCategoryList: React.FC<
  MailListItemCategoryListProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    styles,
    classes,
    projectId,
    messageId,
    disableText,
    onChange,
    values,
    colorMap,
    loading,
    visibleTagCount,
    tagListStyle,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  const hiddenTagsCount = (values?.length || 0) - visibleTagCount;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [visible, setVisible] = useState<boolean>(false);
  const [isCategoriesFetching, setIsCategoriesFetching] =
    useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSelect = async (key: string) => {
    const { result } = await apiMessageAddCategories(
      projectId,
      [messageId],
      [key]
    );
    if (result.status >= 200 && result.status < 300) {
      dispatch(
        categorizedMessage(
          projectId,
          [
            {
              messageId,
              categories: [key],
            },
          ],
          false
        )
      );
      if (onChange) {
        onChange([...values, key]);
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.updateMailCategoriesError'),
      });
    }
  };

  const handleDeselect = async (key: string) => {
    const indx = values.findIndex((category) => category === key);
    if (indx > -1) {
      const { result } = await apiMessageRemoveCategories(
        projectId,
        [messageId],
        [key]
      );
      if (result.status >= 200 && result.status < 300) {
        dispatch(
          categorizedMessage(
            projectId,
            [
              {
                messageId,
                categories: [key],
              },
            ],
            true
          )
        );
        const nextCategories = values;
        nextCategories.splice(indx, 1);
        if (onChange) {
          onChange([...nextCategories]);
        }
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.updateMailCategoriesError'
          ),
        });
      }
    }
  };

  const handleOnClick = (value: string) => {
    if (value === 'sync') {
      abortableDispatch(
        fetchMailCategories(projectId, true),
        dispatch,
        undefined,
        false,
        undefined,
        () => setIsCategoriesFetching(true),
        () => setIsCategoriesFetching(false)
      );
    } else {
      if (values.includes(value)) {
        handleDeselect(value);
      } else {
        handleSelect(value);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(classes.root, className)}
      style={{
        ...styles,
        marginRight: theme.old.components.mailListItem.spacing,
      }}
    >
      <div
        className={classes.flexRow}
        style={{ alignItems: 'center', position: 'relative' }}
      >
        {!loading ? (
          <Dropdown
            trigger={['click']}
            destroyPopupOnHide
            visible={visible}
            onVisibleChange={setVisible}
            overlay={
              <Menu
                selectedKeys={values ?? []}
                onClick={({ key, domEvent }) => {
                  domEvent.stopPropagation();
                  domEvent.preventDefault();
                  handleOnClick(key);
                }}
                multiple={true}
                className={classes.mailCategoryMenu}
              >
                {Object.entries(colorMap ?? []).map(
                  ([displayName, color], i) => (
                    <Menu.Item key={displayName}>
                      <div
                        className={classes.flexRow}
                        style={{ alignItems: 'baseline' }}
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'tag']}
                          color={colorFromPreset(color, theme)}
                          className={classes.selectIcon}
                        />
                        <div style={{ flex: 1 }}>{displayName}</div>
                      </div>
                    </Menu.Item>
                  )
                )}
                <Menu.Divider />
                <Menu.Item key={'sync'} disabled={isCategoriesFetching}>
                  <div
                    className={classes.flexRow}
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'sync']}
                      className={classes.selectIcon}
                      color={theme.old.typography.colors.muted}
                    />
                    <div>{t('mail:loadCategories')}</div>
                  </div>
                </Menu.Item>
              </Menu>
            }
          >
            <div
              className={classes.plus}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={['fal', 'plus-circle']} />
            </div>
          </Dropdown>
        ) : (
          <div
            className={classes.plus}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={['fal', 'plus-circle']} />
          </div>
        )}
        <div className={classes.tagList} style={tagListStyle}>
          {values?.map((value, index) => (
            <div
              className={classNames(
                classes.tagRoot,
                'prio-mail-list-category-tag-root'
              )}
              style={{
                zIndex: values.length - index,
              }}
              key={'drop-' + value + index}
            >
              <CategoryTag
                className={classNames(classes.tag, 'prio-mail-category-tag', {
                  [classes.tagCloseIconNoMargin]: disableText,
                })}
                hidden={visibleTagCount && visibleTagCount <= index}
                colorMap={colorMap}
                disableText={disableText}
                label={value}
                value={value}
                closable
                disabled={false}
                onClose={() => handleDeselect(value)}
                tagNameStyle={{ width: calculateSmallestWidth(values) }}
              />

              {hiddenTagsCount > 0 && visibleTagCount === index + 1 && (
                <div id="hidden-tag-count">+{hiddenTagsCount}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MailListItemCategoryList;
