import {
  Children,
  FC,
  ForwardedRef,
  cloneElement,
  forwardRef,
  isValidElement,
  useImperativeHandle,
  useRef,
  ForwardRefRenderFunction,
} from 'react';

import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { Formik, Form, FormikProps, Field, FieldProps } from 'formik';
import classNames from 'classnames';

import { FormObserver } from './FormObserver';
import { PrioTheme } from '../../theme/types';
import { Input as AntdInput } from 'antd';
import { Paths } from '../../util/GenericHelper';
import * as Yup from 'yup';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: { overflow: 'auto' },
}));

const useStylesItem = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  label: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    paddingBottom: theme.old.spacing.unit(0.5),
    paddingTop: theme.old.spacing.unit(1.5),
  },
  error: {},
  contentRow: (props: Partial<UltimateFormItemProps<FormModel>>) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop:
      (props.labelAlignment === 'left' || props.labelAlignment === 'right') &&
      theme.old.spacing.unit(1.5),
  }),
  contentLeft: {
    order: -1,
    paddingRight: theme.old.spacing.unit(0.5),
    paddingTop: theme.old.spacing.unit(0),
    paddingBottom: theme.old.spacing.unit(0),
  },
  contentRight: {
    order: 1,
    paddingLeft: theme.old.spacing.unit(0.5),
    paddingTop: theme.old.spacing.unit(0),
    paddingBottom: theme.old.spacing.unit(0),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  itemContent: {
    flex: 1,
    width: '100%',
  },
  labelOffset: (props: Partial<UltimateFormItemProps<FormModel>>) => ({
    transform: `translate(${props.labelXOffset || 0}px, ${
      props.labelYOffset || 0
    }px)`,
  }),
}));

export type FormModel = object;

type UltimateFormWithItem<T> = React.FC<
  UltimateFormProps<T> & { ref?: ForwardedRef<FormikProps<T>> }
> & {
  Item: React.FC<UltimateFormItemProps<T>>;
};

function enhanceChildrenWithFormikProps<FormModel>(
  children: React.ReactNode,
  formikProps: FormikProps<any>,
  handleChange: (e: React.ChangeEvent<any>) => void
) {
  return Children.map(children, (child) => {
    if (!isValidElement(child)) return child;

    //@ts-ignore
    if (child.type.displayName === 'UltimateFormItem') {
      return cloneElement(
        child as React.ReactElement<UltimateFormItemProps<FormModel>>,
        {
          formikProps: {
            ...formikProps,
            handleChange, // Override Formik's handleChange
          },
        }
      );
    }

    if (child.props.children) {
      return cloneElement(child, {
        //@ts-ignore
        children: enhanceChildrenWithFormikProps<T>(
          child.props.children,
          formikProps,
          handleChange
        ),
      });
    }

    return child;
  });
}

const convertNameArrayToDotNotation = (
  nameArray: (string | number)[]
): string => {
  return nameArray.join('.');
};

export interface UltimateFormProps<T> {
  className?: string;
  children:
    | React.ReactNode
    | ((formikProps: FormikProps<T>) => React.ReactNode);
  initialValues?: T;
  validationSchema?: Yup.ObjectSchema<T>;
  onSubmit?: (values: T) => void;
  onChange?: (values: T, changedValue: [keyof T, any]) => void;
  onReset?: () => void;
}

export interface UltimateFormItemProps<FormModel> {
  className?: string;
  innerClassName?: string;
  label?: string | JSX.Element | ((value: any) => string);
  noLabel?: boolean;
  name: Paths<FormModel>;
  labelAlignment?: 'top' | 'right' | 'left' | 'bottom';
  labelXOffset?: number;
  labelYOffset?: number;
  formikProps?: FormikProps<FormModel>;
  children?:
    | React.ReactNode
    | ((props: {
        onChange: (value: any) => void;
        value: any;
        values: any;
        fieldProps: FieldProps;
      }) => JSX.Element);
}

export function createUltimateForm<T>(): UltimateFormWithItem<T> {
  const UltimateFormInner: ForwardRefRenderFunction<
    FormikProps<T>,
    UltimateFormProps<T>
  > = (
    {
      className,
      children,
      initialValues,
      validationSchema,
      onSubmit,
      onChange,
      onReset,
    },
    forwardedRef
  ) => {
    const classes = useStyles();
    const prevValuesRef = useRef<T>(initialValues);
    const formikRef = useRef<FormikProps<T> | null>(null);

    useImperativeHandle(forwardedRef, () => formikRef.current);
    return (
      <div className={classNames(className, classes.root)}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formikRef}
          onReset={onReset}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            const handleCustomChange = (e: React.ChangeEvent<any>) => {
              formikProps.handleChange(e);
            };

            // If children is a function, pass formikProps to it.
            const initialChildren =
              typeof children === 'function' ? children(formikProps) : children;

            const enhancedChildren = enhanceChildrenWithFormikProps(
              initialChildren,
              formikProps,
              handleCustomChange
            );

            return (
              <Form>
                {onChange && (
                  <FormObserver<T>
                    prevValuesRef={prevValuesRef}
                    onChange={onChange}
                  />
                )}
                {enhancedChildren}
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  const Item: FC<UltimateFormItemProps<T>> = ({
    children,
    label,
    noLabel,
    name,
    className,
    innerClassName,
    labelAlignment = 'top',
    labelXOffset = 0,
    labelYOffset = 0,
    formikProps,
  }) => {
    const classes = useStylesItem({
      labelAlignment,
      labelXOffset,
      labelYOffset,
    });

    const fieldName = Array.isArray(name)
      ? convertNameArrayToDotNotation(name)
      : name;

    const renderLabel = (value: any) => (
      <span
        className={classNames(
          classes.label,
          classes.labelOffset,
          labelAlignment === 'right'
            ? classes.contentRight
            : labelAlignment === 'left'
            ? classes.contentLeft
            : ''
        )}
      >
        {typeof label === 'function' ? label(value) : label}
      </span>
    );

    let contentClass;
    switch (labelAlignment) {
      case 'right':
      case 'left':
        contentClass = classes.contentRow;
        break;
      case 'top':
      default:
        contentClass = classes.contentColumn;
        break;
    }

    const handleValueChange = (value: any) => {
      formikProps?.setFieldValue(fieldName, value);
    };

    const hasHTMLInputElementNested = (
      element: React.ReactElement
    ): boolean => {
      const type = element.type;

      if (
        typeof type === 'string' &&
        (type === 'input' || type === 'select' || type === 'textarea')
      )
        return true;

      if (type === AntdInput) return true;

      // Recursive case: dig into the children
      if (element.props && element.props.children) {
        return Children.toArray(element.props.children).some(
          (child) => isValidElement(child) && hasHTMLInputElementNested(child)
        );
      }

      return false;
    };

    const renderField = (fieldProps: FieldProps) => {
      if (typeof children === 'function') {
        return children({
          onChange: (value: any) => handleValueChange(value),
          fieldProps,
          value: fieldProps.field.value,
          values: formikProps.values,
        });
      } else {
        return Children.map(children, (child) => {
          if (isValidElement(child)) {
            // If it's a standard HTML input element
            if (hasHTMLInputElementNested(child)) {
              return cloneElement(child, {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleValueChange(e.target.value),
                ...fieldProps.field,
              });
            } else {
              // For custom components
              return cloneElement(child, {
                //@ts-ignore
                onChange: (value: any) => handleValueChange(value),
                value: fieldProps.field.value,
              });
            }
          }
          return child;
        });
      }
    };
    return (
      <div className={classNames(classes.root, className)}>
        <Field name={fieldName}>
          {(fieldProps: FieldProps) => (
            <>
              <div className={classNames(contentClass, classes.itemContent)}>
                {labelAlignment !== 'bottom' &&
                  !noLabel &&
                  renderLabel(fieldProps.field.value)}
                {renderField(fieldProps)}
                {labelAlignment === 'bottom' &&
                  !noLabel &&
                  renderLabel(fieldProps.field.value)}
              </div>
            </>
          )}
        </Field>

        {/* TODO: Configure form to dynamically display error */}
        {/* {formikProps &&
        formikProps.touched[fieldName] &&
        formikProps.errors[fieldName] ? (
          //@ts-ignore
          <div className={classes.error}>{formikProps.errors[fieldName]}</div>
        ) : null} */}
      </div>
    );
  };

  Item.displayName = 'UltimateFormItem';

  const UltimateForm = forwardRef(
    UltimateFormInner
  ) as unknown as UltimateFormWithItem<T>;

  UltimateForm.Item = Item;

  return UltimateForm;
}
