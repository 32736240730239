import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ProjectExtensionType } from '../models/ProjectExtension';
import { AbsenceType } from '../models/Types';

export const getIconByType: (
  projectExtensionType: ProjectExtensionType
) => IconName = (projectExtensionType: ProjectExtensionType) => {
  if (projectExtensionType) {
    switch (projectExtensionType) {
      case 'sharepoint':
        return 'folders';
      case 'jira':
        return 'folders';
      case 'openExperience':
        return 'tasks';
      case 'planner':
        return 'tasks-alt';
      case 'sharedMailbox':
        return 'envelope';
      case 'url':
        return 'link';
      default:
        return 'question-circle';
    }
  } else {
    return 'plus-circle';
  }
};

export const getIconForAbsenceType: (type: AbsenceType) => IconName = (
  type
) => {
  switch (type) {
    case 'annualLeave':
      return 'island-tropical';
    case 'dayOfIllness':
      return 'pills';
    case 'maternityLeave':
      return 'baby';
    case 'parentalLeave':
      return 'baby';
    case 'paidSpecialLeave':
      return 'island-tropical';
    case 'overtimeCompensation':
      return 'calendar-clock';

    default:
      return 'sign-out';
  }
};
