import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import {
  getActiveProjectProject,
  getCurrentDriveItem,
  getCurrentFolderItem,
  getDocumentsMetaAreMultipleSelected,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setDocumentsMetaState } from '../actions/meta';
import { DriveItem } from '../../../models/Drive';
import { Project } from '../../../models/Project';
import { User } from '../../../models/User';
import { apiCreateDriveFolder } from '../api';
import { fetchDriveItemsSagaAction } from '../actions';
import { GroupId, ProjectId } from '../../../models/Types';
import useResizeObserver from 'use-resize-observer';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import useDebounce from '../../../hooks/useDebounce';
import NewFolderModal from '../../settings/components/NewFolderModal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import UploadField, {
  PrioFile,
  UploadFieldProps,
} from '../../../components/Upload/UploadField';
import {
  sagaStartUploadFiles,
  sagaUploadFiles,
} from '../sagas/watchUploadFiles';
import { addPrioFilesToUploadList } from '../actions/uploadLists';
import classnames from 'classnames';
import { setCurrentPreviewModalVisibility } from '../actions/previewModal';
import { NewDocumentDrawer } from './Drawers/NewDocumentDrawer';
import { isDriveItemFolder } from '../util';

const AVERAGE_BUTTON_WIDTH = 110;
const FULL_NAVIGATION_BAR_WIDTH = 1000;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: 64,
    minHeight: 64,
    borderBottom: theme.old.borders.sub,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  contextPartContainer: {
    paddingLeft: theme.old.spacing.defaultPadding,
    height: 64,
    minHeight: 64,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contextPart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedItemCount: {
    paddingRight: '30px',
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.45)',
    userSelect: 'none',
    margin: 0,
  },
  dropDownButton: {
    height: '32px',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette.blue[50],
    },
  },
  buttonSpacingRight: {
    marginRight: theme.spacing.unit(1),
  },
}));

interface DocumentsNavigationBarProps {
  projectId: ProjectId;
  groupId: GroupId;
  selectedDriveItems?: DriveItem[];
  setSelectedDriveItems?: (selectedDriveItems: DriveItem[]) => void;
}

export const DocumentsNavigationBar: React.FC<DocumentsNavigationBarProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectId, groupId, selectedDriveItems, setSelectedDriveItems } =
    props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const pathPrefix = './';

  const { ref: root, width: navigationBarWidth } = useResizeObserver();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const driveItem = useSelector<RootReducerState, DriveItem>(
    getCurrentDriveItem
  );

  const isRoot = driveItem?.name === 'root';

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const selectedDriveItemsWithoutFolder = useMemo(
    () => selectedDriveItems?.filter((item) => !isDriveItemFolder(item)) ?? [],
    [selectedDriveItems]
  );
  const debouncedNavigationWidth = useDebounce(navigationBarWidth, 250);
  const rootFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, `root-group-${groupId}`)
  );
  const userMe = useSelector<RootReducerState, User>(getUserMe);
  const activeProject = useSelector<RootReducerState, Project>(
    getActiveProjectProject
  );
  const activeOneDriveSyncEnabled =
    !!activeProject && !!userMe?.mail && !!rootFolder?.sharepointIds?.siteId;

  const areMultipleSelected = useSelector(getDocumentsMetaAreMultipleSelected);

  const currentFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, driveItem?.id ?? `root-group-${groupId}`)
  );

  const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);
  const [newFolderCreating, setNewFolderCreating] = useState<boolean>(false);

  const uploadProps: UploadFieldProps = useMemo(
    () => ({
      isButton: true,
      multiple: true,
      onBeforeUpload: (originFiles: PrioFile[], sessionId: string) => {
        dispatch(
          sagaStartUploadFiles(
            groupId,
            sessionId,
            driveItem?.id ?? `root-group-${groupId}`,
            projectId
          )
        );
      },
      onAfterUpload: async (fileList: PrioFile[], sessionId: string) => {
        dispatch(
          addPrioFilesToUploadList(
            groupId,
            fileList.map(
              ({
                fileId,
                name,
                webkitRelativePath,
                size,
                type,
                sessionId,
              }) => ({
                fileId,
                sessionId,
                parentDriveItemId: driveItem?.id ?? `root-group-${groupId}`,
                name,
                path: webkitRelativePath,
                size,
                isFolder: false,
                mimeType: type,
                driveItemId: null,
              })
            )
          )
        );
        dispatch(
          sagaUploadFiles(
            groupId,
            sessionId,
            driveItem?.id ?? `root-group-${groupId}`,
            projectId,
            fileList
          )
        );
      },
    }),
    [driveItem, projectId, groupId, dispatch]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const newDocument = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const onCreateDocumentSuccess = (folder: DriveItem) => {
    setOpenDrawer(false);
    const isFolderRoot = folder?.name === 'root';
    if (folder?.id) {
      navigate(
        !isFolderRoot ? `${pathPrefix}folder/${folder.id}` : `${pathPrefix}all`
      );
    } else {
      navigate(
        driveItem && !isRoot
          ? `${pathPrefix}folder/${driveItem.id}`
          : `${pathPrefix}all`
      );
    }
  };

  const createFolder = useCallback(async () => {
    if (!!groupId) {
      setNewFolderModalOpen(true);
    }
  }, [groupId]);

  const activeOneDriveSync = useCallback(() => {
    const {
      sharepointIds: { siteId, webId, siteUrl: webUrl, listId },
    } = rootFolder;

    const { mail: userEmail } = userMe;

    const webTitle = 'P';
    const listTitle = `${activeProject.number}-${
      activeProject.shortName ?? activeProject.name
    }`;
    const url = `odopen://sync/?siteId=${siteId}&webId=${webId}&webUrl=${webUrl}&listId=${listId}&userEmail=${userEmail}&webTitle=${webTitle}&listTitle=${listTitle}`;

    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [rootFolder, userMe, activeProject]);

  const showMoveDrawer = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'move',
      })
    );
  }, [dispatch]);

  const showCopyDrawer = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'copy',
      })
    );
  }, [dispatch]);

  const openPreviewToEditMetaData = useCallback(() => {
    dispatch(
      setCurrentPreviewModalVisibility(
        true,
        selectedDriveItems[0]?.id,
        groupId,
        selectedDriveItems[0],
        selectedDriveItemsWithoutFolder,
        null,
        projectId,
        '',
        false,
        selectedDriveItemsWithoutFolder
      )
    );
    setSelectedDriveItems([]);
  }, [
    dispatch,
    selectedDriveItems,
    selectedDriveItemsWithoutFolder,
    groupId,
    projectId,
    setSelectedDriveItems,
  ]);

  const showDeleteModal = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        showDeleteDocumentsModal: true,
      })
    );
  }, [dispatch]);

  const handleMenuClick = useCallback(
    ({ key }) => {
      const itemType = key as
        | 'sync'
        | 'move'
        | 'copy'
        | 'delete'
        | 'sharepoint'
        | 'upload';
      switch (itemType) {
        case 'sync': {
          activeOneDriveSync();
          break;
        }
        case 'move': {
          showMoveDrawer();
          break;
        }
        case 'copy': {
          showCopyDrawer();
          break;
        }
        case 'delete': {
          showDeleteModal();
          break;
        }
        case 'sharepoint': {
          if (driveItem?.webUrl) {
            window.open(driveItem.webUrl, '_blank');
          }
          break;
        }
        default:
          break;
      }
    },
    [
      driveItem,
      activeOneDriveSync,
      showMoveDrawer,
      showCopyDrawer,
      showDeleteModal,
    ]
  );

  const onNewFolderOk = async (trimmedValue: string) => {
    if (trimmedValue === '') {
      closeNewFolderModal();
      return;
    }
    setNewFolderCreating(true);
    const { data } = await apiCreateDriveFolder(
      groupId,
      driveItem?.id,
      trimmedValue
    );
    setNewFolderCreating(false);
    if (data) {
      const driveItemId = driveItem?.id ?? `root-group-${groupId}`;
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          driveItem?.id,
          250,
          driveItemId.includes('root-group-'),
          false,
          true
        )
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.newFolderCreateError'),
      });
    }
    closeNewFolderModal();
  };

  const onNewFolderCancel = () => {
    closeNewFolderModal();
  };

  const closeNewFolderModal = () => {
    setNewFolderModalOpen(false);
  };
  //#endregion

  //#region ------------------------------ Components
  const dropdown = useMemo(
    () => (
      <Dropdown
        trigger={['click']}
        placement={'bottomRight'}
        overlay={
          <Menu onClick={handleMenuClick}>
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 5 * AVERAGE_BUTTON_WIDTH && (
              <Menu.Item
                key="sync"
                disabled={!activeOneDriveSyncEnabled}
                icon={<FontAwesomeIcon icon={['fal', 'sync']} />}
              >
                {t('documents:navigationBar.activateOneDriveSync')}
              </Menu.Item>
            )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 4 * AVERAGE_BUTTON_WIDTH &&
              driveItem?.webUrl && (
                <Menu.Item
                  key="sharepoint"
                  disabled={!activeOneDriveSyncEnabled}
                  icon={<FontAwesomeIcon icon={['fal', 'external-link-alt']} />}
                >
                  {t('documents:navigationBar.openInSharePoint')}
                </Menu.Item>
              )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 3 * AVERAGE_BUTTON_WIDTH && (
              <>
                <Menu.Item
                  key="upload"
                  icon={<FontAwesomeIcon icon={['fal', 'arrow-to-top']} />}
                >
                  <UploadField {...uploadProps}>
                    {t('documents:navigationBar.upload')}
                  </UploadField>
                </Menu.Item>
                <Menu.Item
                  key="uploadFolder"
                  icon={<FontAwesomeIcon icon={['fal', 'arrow-to-top']} />}
                >
                  <UploadField {...uploadProps} directory>
                    {t('documents:navigationBar.uploadFolder')}
                  </UploadField>
                </Menu.Item>
              </>
            )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - 2 * AVERAGE_BUTTON_WIDTH && (
              <Menu.Item
                key="move"
                disabled={!areMultipleSelected}
                icon={<FontAwesomeIcon icon={['fal', 'file-export']} />}
              >
                {t('documents:navigationBar.move')}
              </Menu.Item>
            )}
            {debouncedNavigationWidth <
              FULL_NAVIGATION_BAR_WIDTH - AVERAGE_BUTTON_WIDTH && (
              <Menu.Item
                key="copy"
                disabled={!areMultipleSelected}
                icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
              >
                {t('documents:navigationBar.copy')}
              </Menu.Item>
            )}
            {debouncedNavigationWidth < FULL_NAVIGATION_BAR_WIDTH && (
              <Menu.Item
                key="delete"
                disabled={
                  !areMultipleSelected || !!location.pathname.match(/(all)$/)
                }
                icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
              >
                {t('documents:navigationBar.delete')}
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Button
          style={{ height: '64 px ' }}
          iconProp={['fal', 'ellipsis-h']}
          type="default"
        />
      </Dropdown>
    ),
    [
      debouncedNavigationWidth,
      activeOneDriveSyncEnabled,
      driveItem,
      location,
      areMultipleSelected,
      uploadProps,
      handleMenuClick,
      t,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <div id="prio-module-navigation-bar" className={classes.root} ref={root}>
        <Flex.Row
          className={classes.contextPartContainer}
          flex={1}
          alignItems="center"
          childrenGap={theme.old.spacing.unit(1)}
        >
          <div className={classes.contextPart}>
            <Button
              type="primary"
              className={classes.buttonSpacingRight}
              onClick={newDocument}
              iconProp={['fal', 'file']}
            >
              <span>{t('documents:navigationBar.createDocument')}</span>
            </Button>
            <Button
              onClick={createFolder}
              disabled={
                currentFolder?.name === 'root' ||
                rootFolder?.id === driveItem?.id
              }
              className={classes.buttonSpacingRight}
              iconProp={['fal', 'folder-plus']}
              type="default"
            >
              <span>{t('documents:navigationBar.createFolder')}</span>
            </Button>
            {driveItem?.webUrl &&
              debouncedNavigationWidth >=
                FULL_NAVIGATION_BAR_WIDTH - 4 * AVERAGE_BUTTON_WIDTH && (
                <Button
                  href={driveItem.webUrl}
                  disabled={!activeOneDriveSyncEnabled}
                  className={classes.buttonSpacingRight}
                  iconProp={['fal', 'external-link-alt']}
                  type="default"
                >
                  <span>{t('documents:navigationBar.openInSharePoint')}</span>
                </Button>
              )}
            {debouncedNavigationWidth >=
              FULL_NAVIGATION_BAR_WIDTH - 3 * AVERAGE_BUTTON_WIDTH && (
              <Dropdown
                className={classnames(classes.dropDownButton)}
                overlay={
                  <Menu>
                    <UploadField {...uploadProps} directory>
                      <Menu.Item>
                        {t('documents:navigationBar.uploadFolder')}
                      </Menu.Item>
                    </UploadField>
                    <UploadField {...uploadProps}>
                      <Menu.Item>
                        {t('documents:navigationBar.uploadFile')}
                      </Menu.Item>
                    </UploadField>
                  </Menu>
                }
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  className={classes.buttonSpacingRight}
                  suffixIconProp={['fal', 'chevron-down']}
                  type="default"
                >
                  {t('documents:navigationBar.upload')}
                </Button>
              </Dropdown>
            )}
            {debouncedNavigationWidth >=
              FULL_NAVIGATION_BAR_WIDTH - 2 * AVERAGE_BUTTON_WIDTH && (
              <Button
                disabled={!areMultipleSelected}
                iconProp={['fal', 'file-export']}
                className={classes.buttonSpacingRight}
                onClick={showMoveDrawer}
                type="default"
              >
                {t('documents:navigationBar.move')}
              </Button>
            )}
            {debouncedNavigationWidth >=
              FULL_NAVIGATION_BAR_WIDTH - AVERAGE_BUTTON_WIDTH && (
              <Button
                disabled={!areMultipleSelected}
                iconProp={['fal', 'copy']}
                type="default"
                className={classes.buttonSpacingRight}
                onClick={showCopyDrawer}
              >
                {t('documents:navigationBar.copy')}
              </Button>
            )}
            {debouncedNavigationWidth >=
              FULL_NAVIGATION_BAR_WIDTH - AVERAGE_BUTTON_WIDTH && (
              <Button
                disabled={
                  !(
                    areMultipleSelected &&
                    selectedDriveItemsWithoutFolder.length > 0
                  )
                }
                iconProp={['fal', 'pen']}
                type="default"
                className={classes.buttonSpacingRight}
                onClick={openPreviewToEditMetaData}
              >
                {t('documents:navigationBar.editMetaData')}
              </Button>
            )}

            {debouncedNavigationWidth >= FULL_NAVIGATION_BAR_WIDTH && (
              <Button
                type="default"
                disabled={
                  !areMultipleSelected || !!location.pathname.match(/(all)$/)
                }
                iconProp={['fal', 'trash']}
                onClick={showDeleteModal}
              >
                {t('documents:navigationBar.delete')}
              </Button>
            )}
            {debouncedNavigationWidth < FULL_NAVIGATION_BAR_WIDTH && dropdown}
          </div>
          {selectedDriveItems.length > 0 && (
            <div className={classes.selectedItemCount}>
              {`${selectedDriveItems.length} ${t(
                'documents:navigationBar.selectedItemsCount'
              )}`}
            </div>
          )}
        </Flex.Row>
      </div>
      <NewFolderModal
        currentPath={[
          driveItem?.name === 'root'
            ? t('documents:rootFolder')
            : driveItem?.name ?? '',
        ]}
        visible={newFolderModalOpen && driveItem?.name !== 'root'}
        onOk={onNewFolderOk}
        onCancel={onNewFolderCancel}
        confirmLoading={newFolderCreating}
      />
      <NewDocumentDrawer
        driveItem={driveItem}
        projectId={projectId}
        groupId={groupId}
        onSuccess={onCreateDocumentSuccess}
        onCancel={() => {
          setOpenDrawer(false);
        }}
        visible={openDrawer}
      />
    </>
  );
};

export default DocumentsNavigationBar;
