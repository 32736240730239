import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';
import { getContact, RootReducerState } from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { Contact, InternalContact } from '../../../models/Contact';
import { Employee } from '../../../models/Employee';
import { EmployeeId, OfficeId } from '../../../models/Types';
// import { colon } from '../../../util/forms';
import { makePrioStyles } from '../../../theme/utils';
import { InternalOffice } from '../../../models/Office';
import { useSelector } from 'react-redux';
import { apiFetchInternalOffice } from '../../contacts/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    borderRadius: '2px',
    paddingTop: theme.spacing.unit(3),
    paddingLeft: theme.spacing.unit(3),
    marginTop: theme.spacing.unit(1),
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  companyDataBoxLeft: {
    paddingRight: theme.spacing.unit(3),
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface CompanyDataFormModel {
  officeId?: string;
  managerId?: string;
  department?: string;
  position?: string;
  eMailPrefix?: string;
  eMailSuffix?: string;
  eMail2?: string;
  phone?: string;
  phone2?: string;
  cellphone?: string;
  cellphone2?: string;
  notes?: string;
  signaturePrefix?: string;
}

interface ProfilePersonnelCompanyDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  disabled: boolean;
  officeId?: OfficeId;
}

export const ProfilePersonnelCompanyDataForm: React.FC<
  ProfilePersonnelCompanyDataFormProps
> = (props) => {
  const { employeeId } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [companyDataForm] = Form.useForm<CompanyDataFormModel>();
  const [office, setOffice] = useState<InternalOffice>(null);

  const contact = useSelector<RootReducerState, Contact>(
    (state) => getContact(state, employeeId) as InternalContact
  );

  const manager = useSelector<RootReducerState, Contact>(
    (state) => getContact(state, contact.managerId) as InternalContact
  );

  const contactCast = contact as InternalContact;

  //#region ------------------------------ Methods / Handlers

  const companyDataFromEmployee: (
    contact: InternalContact
  ) => CompanyDataFormModel = (contact) => ({
    officeId: contact?.officeId,
    managerId: contact?.managerId,
    department: contact?.department,
    position: contact?.position,
    eMailPrefix: contact?.eMail.split('@')[0],
    eMailSuffix: contact?.eMail.split('@')[1],
    eMail2: contact?.eMail2,
    phone: contact?.phone,
    phone2: contact?.phone2,
    cellphone: contact?.cellphone,
    cellphone2: contact?.cellphone2,
    notes: contact?.notes,
    signaturePrefix: contact?.signaturePrefix,
  });

  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const loadOffice = async () => {
      const { result, data } = await apiFetchInternalOffice(
        contactCast.officeId
      );

      if (result.status >= 200 && result.status < 300) {
        setOffice(data);
      }
    };

    if (contactCast && office === null) {
      loadOffice();
      companyDataForm.setFieldsValue(companyDataFromEmployee(contactCast));
    }
  }, [contactCast, companyDataForm, office]);

  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Typography.Title>
        {t('profile:profileNavigation.employeeData')}
      </Typography.Title>

      <Flex.Column
        flex={1}
        childrenGap={theme.spacing.unit(2)}
        paddingBottom={theme.spacing.unit(3)}
      >
        <Flex.Column>
          <Typography.Text type="secondary">
            {t('users:form.labels.eMailPrefix')}
          </Typography.Text>
          <Flex.Item>
            {`${companyDataFromEmployee(contactCast).eMailPrefix}

                ${'@' + companyDataFromEmployee(contactCast).eMailSuffix},`}
          </Flex.Item>

          <Flex.Item>
            {`${
              !companyDataFromEmployee(contactCast).eMail2
                ? ' - '
                : companyDataFromEmployee(contactCast).eMail2
            },`}
          </Flex.Item>
        </Flex.Column>

        <Flex.Column>
          <Typography.Text type="secondary">
            {t('contacts:form.labels.phone') +
              ' / ' +
              t('contacts:form.labels.cellphone')}
          </Typography.Text>
          <Flex.Item>
            {`${
              !companyDataFromEmployee(contactCast).phone
                ? ' - '
                : companyDataFromEmployee(contactCast).phone
            },

                ${
                  !companyDataFromEmployee(contactCast).phone2
                    ? ' - '
                    : companyDataFromEmployee(contactCast).phone2
                },
                
                ${
                  !companyDataFromEmployee(contactCast).cellphone
                    ? ' - '
                    : companyDataFromEmployee(contactCast).cellphone
                },
                
                ${
                  !companyDataFromEmployee(contactCast).cellphone2
                    ? ' - '
                    : companyDataFromEmployee(contactCast).cellphone2
                }`}
          </Flex.Item>
        </Flex.Column>

        <Flex.Column>
          <Typography.Text type="secondary">
            {t('users:form.labels.officeId') +
              ', ' +
              t('users:form.labels.managerId') +
              ', ' +
              t('contacts:form.labels.department') +
              ', ' +
              t('contacts:form.labels.position')}
          </Typography.Text>
          <Flex.Item>
            {`${office === null ? ' - ' : office.name},

                ${
                  !(manager?.firstName && manager?.lastName)
                    ? ' - '
                    : manager.firstName + ' ' + manager.lastName
                },
                
                ${
                  !companyDataFromEmployee(contactCast).department
                    ? ' - '
                    : companyDataFromEmployee(contactCast).department
                },
                
                ${
                  !companyDataFromEmployee(contactCast).position
                    ? ' - '
                    : companyDataFromEmployee(contactCast).position
                }`}
          </Flex.Item>
        </Flex.Column>

        <Flex.Column>
          <Typography.Text type="secondary">
            {t('users:form.labels.signaturePrefix') +
              ', ' +
              t('contacts:form.labels.notes')}
          </Typography.Text>
          <Flex.Item>
            {`${
              !companyDataFromEmployee(contactCast).signaturePrefix
                ? '-'
                : companyDataFromEmployee(contactCast).signaturePrefix
            },

                ${
                  !companyDataFromEmployee(contactCast).notes
                    ? '-'
                    : companyDataFromEmployee(contactCast).notes
                }`}
          </Flex.Item>
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};

export default ProfilePersonnelCompanyDataForm;
