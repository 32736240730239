import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Drawer, Form, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import DriveItemBreadcrumb from './DriveItemBreadcrumb/DriveItemBreadcrumb';
import { CopyDriveItem, DriveItem } from '../../../models/Drive';
import {
  ConfigurationKeys,
  DriveItemId,
  ProjectId,
} from '../../../models/Types';
import FolderSelectionDrawer from './Drawers/FolderSelectionDrawer';
import { Configuration } from '../../../models/Configuration';
import { apiFetchConfigurations } from '../../settings/api';
import { apiFetchDocumentPrefix } from '../api';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { copyItemsIntoFolder, fetchDriveItemsSagaAction } from '../actions';
import CopyDocumentsDrawerItem from './CopyDocumentsDrawerItem';
import {
  getCurrentFolderItem,
  getProject,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    zIndex: 999,
    '& .ant-drawer-body': {
      overflow: 'hidden',
    },
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  list: {
    width: '100%',
    overflow: 'auto',
  },
  buttonRow: {
    marginTop: `${theme.old.spacing.unit(4)}px!important`,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
}));

interface CopyDocumentsDrawerProps {
  onClose?: (deselect?: boolean) => void;
  drawerState: 'copy' | 'move' | 'none';
  selectedDriveItems: DriveItem[];
  sourceFolderId: DriveItemId;
  sourceProjectId: ProjectId;
  destinationFolderId?: DriveItemId;
  destinationProjectId?: ProjectId;
}

export const CopyDocumentsDrawer: React.FC<CopyDocumentsDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    drawerState,
    selectedDriveItems,
    sourceFolderId: initialSourceFolderId,
    destinationFolderId: initialDestinationFolderId,
    destinationProjectId: initialDestinationProjectId,
    sourceProjectId,
    onClose,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [
    selectTargetFolderSubDrawerVisible,
    setSelectTargetFolderSubDrawerVisible,
  ] = useState<boolean>(false);

  const sourceFolderIsRoot =
    !initialSourceFolderId || !!initialSourceFolderId.match(/^root-group-/);

  const sourceProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, sourceProjectId)
  );

  const [destinationProjectId, setDestinationProjectId] = useState<ProjectId>(
    initialDestinationProjectId ?? sourceProjectId
  );
  const destinationProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, destinationProjectId)
  );

  const [destinationFolderId, setDestinationFolderId] = useState<DriveItemId>(
    initialDestinationFolderId ?? initialSourceFolderId
  );

  const destinationFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(
      state,
      destinationFolderId ?? `root-group-${destinationProject?.groupId}`
    )
  );

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  const useDocumentsPrefix =
    configurations?.find(
      (config) =>
        config.key === ConfigurationKeys.USE_PREFIX_TO_SAVE_ATTACHMENT_TO_CLOUD
    )?.value === 'true';

  const [prefix, setPrefix] = useState<string>(moment().format('YYYY-MM-DD_'));

  const initialDriveItems = selectedDriveItems;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setDestinationFolderId(initialSourceFolderId);
    form.resetFields();
  };

  const openTargetFolderSubDrawer = () => {
    setSelectTargetFolderSubDrawerVisible(true);
  };

  const handleTargetFolderModalOk = (
    destinationFolder: DriveItem,
    destinationProjectId: ProjectId
  ) => {
    setSelectTargetFolderSubDrawerVisible(false);
    setDestinationProjectId(destinationProjectId);
    setDestinationFolderId(destinationFolder?.id);
  };
  const handleTargetFolderModalCancel = () => {
    setSelectTargetFolderSubDrawerVisible(false);
  };

  const handleCopy = (deleteOriginals?: boolean) => {
    const driveItemNames: { [driveItemId: DriveItemId]: string } =
      form.getFieldsValue(true);
    const driveItemsToCopy: CopyDriveItem[] =
      initialDriveItems.map<CopyDriveItem>((driveItem) => ({
        driveItemId: driveItem?.id,
        deleteSourceDriveItem: deleteOriginals ?? false,
        name: driveItemNames[driveItem?.id],
      }));

    dispatch(
      copyItemsIntoFolder(
        sourceProjectId,
        sourceProject?.groupId,
        destinationProject?.groupId,
        driveItemsToCopy,
        initialDriveItems,
        initialSourceFolderId,
        destinationFolder?.id,
        sourceFolderIsRoot,
        destinationFolder?.name === 'root'
      )
    );
    if (onClose) {
      onClose(true);
    }
    setDestinationFolderId(initialSourceFolderId);
    form.resetFields();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);

  useEffect(() => {
    async function fetchPrefix() {
      const { data } = await apiFetchDocumentPrefix(destinationProjectId);
      if (data) {
        setPrefix(data.value);
      }
    }

    if (useDocumentsPrefix && destinationProjectId) {
      fetchPrefix();
    }
  }, [useDocumentsPrefix, destinationProjectId]);

  useEffect(() => {
    setDestinationProjectId(initialDestinationProjectId ?? sourceProjectId);
    setDestinationFolderId(
      !initialDestinationFolderId
        ? initialDestinationProjectId
          ? null
          : initialSourceFolderId
        : initialDestinationFolderId
    );
  }, [
    initialDestinationProjectId,
    sourceProjectId,
    initialSourceFolderId,
    initialDestinationFolderId,
  ]);

  useEffect(() => {
    const formItems = form.getFieldsValue(true);
    if (
      initialDriveItems.find(
        (initialItems) => !Object.keys(formItems).includes(initialItems.id)
      )
    ) {
      form.resetFields();
      form.setFieldsValue(
        initialDriveItems.reduce((map, current) => {
          map[current.id] = `${useDocumentsPrefix ? prefix : ''}${
            current.name
          }`;
          return map;
        }, {})
      );
    }
  }, [form, initialDriveItems, useDocumentsPrefix, prefix]);

  useEffect(() => {
    if (!!destinationProject && drawerState !== 'none') {
      const { projectId, groupId } = destinationProject;
      const driveItemId = destinationFolderId ?? `root-group-${groupId}`;
      const isRoot = driveItemId?.includes('root-group-');

      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          destinationFolderId,
          250,
          isRoot,
          false
        )
      );
    }
  }, [drawerState, destinationProject, destinationFolderId, dispatch]);
  //#endregion

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={handleOnClose}
      visible={drawerState !== 'none'}
      width={'600px'}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.root}
      destroyOnClose
    >
      <Flex.Column
        alignItems="flex-start"
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.content}
      >
        <Typography.Title>
          {drawerState !== 'none' &&
            t(`documents:copyDocumentsDrawer.title.${drawerState}`, {
              amount: initialDriveItems.length,
            })}
        </Typography.Title>
        <Flex.Column
          alignItems="flex-start"
          childrenGap={theme.old.spacing.unit(2)}
          className={classes.list}
          flex={1}
        >
          {initialDriveItems.map((driveItem) => (
            <React.Fragment key={driveItem.id}>
              <CopyDocumentsDrawerItem
                driveItem={driveItem}
                prefix={prefix}
                useDocumentsPrefix={useDocumentsPrefix}
                form={form}
              />
              <Divider />
            </React.Fragment>
          ))}
        </Flex.Column>
        <Flex.Row alignItems="center" childrenGap={theme.old.spacing.unit(1)}>
          <FontAwesomeIcon icon={['fal', 'folder-tree']} />
          <span>{t('mail:messageDisplay.saveDestination')}</span>
          <Button
            onClick={openTargetFolderSubDrawer}
            iconProp={['fal', 'pen']}
            type="link"
          ></Button>
        </Flex.Row>
        <Flex.Row
          alignItems="center"
          childrenGap={theme.old.spacing.unit(1)}
          className={classes.fullWidth}
        >
          <DriveItemBreadcrumb
            project={destinationProject}
            driveItem={destinationFolder}
            onItemClick={(driveItemId) => {
              if (!driveItemId || driveItemId?.includes('rrot-group-')) {
                setDestinationFolderId(null);
              } else {
                setDestinationFolderId(driveItemId);
              }
            }}
            onRootChange={(projectId) => {
              setDestinationProjectId(projectId);
              setDestinationFolderId(null);
            }}
            addProjectList
            disableLinkNavigation
          />
        </Flex.Row>
        <Flex.Row className={classes.buttonRow}>
          <Flex.Item flex={1} />
          <Flex.Item>
            <Button type="default" onClick={handleOnClose}>
              {t('documents:copyDocumentsDrawer.actions.cancel')}
            </Button>
            <Button
              onClick={
                drawerState === 'move'
                  ? () => handleCopy(true)
                  : () => handleCopy(false)
              }
              type="primary"
              style={{ marginLeft: theme.old.spacing.unit(1) }}
              disabled={
                drawerState === 'move' &&
                initialDriveItems
                  .map((item) => item.parentReference?.id)
                  .includes(destinationFolderId)
              }
            >
              {drawerState !== 'none' &&
                t(`documents:copyDocumentsDrawer.actions.${drawerState}`)}
            </Button>
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>
      <FolderSelectionDrawer
        visible={selectTargetFolderSubDrawerVisible}
        onOk={handleTargetFolderModalOk}
        onCancel={handleTargetFolderModalCancel}
        projectId={destinationProjectId}
        selectedFolder={destinationFolderId}
        excludedFolders={[
          ...(drawerState === 'move'
            ? selectedDriveItems.map((item) => item.id)
            : []),
        ]}
        allowProjectChange
      />
    </Drawer>
  );
};

export default CopyDocumentsDrawer;
