import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { List, Modal, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import {
  CreateProjectDraftByProjectDto,
  Project,
} from '../../../../models/Project';
import { useTranslation } from 'react-i18next';
import { CreateProjectFromProjectModal } from './CreateProjectFromProjectModal';
import {
  apiCreateProjectDraftByProject,
  apiDeleteProjectCreationDraft,
  apiGetProjectDraftsById,
} from '../../api';
import UserAvatar from '../../../../components/UserAvatar';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing.baseSpacing * 3,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    overflowY: 'auto',
  },
  listRoot: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  card: {
    boxShadow: theme.palette.boxShadow.regular,
    borderRadius: theme.borders.radius * 2,
    margin: theme.spacing.baseSpacing,
  },
  listCard: {
    borderRadius: theme.borders.radius * 2,
    margin: theme.spacing.baseSpacing,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  cardCover: {
    height: '100px',
    width: '100%',
    color: theme.old.palette.chromaticPalette.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.old.palette.chromaticPalette.blue,
    background: `linear-gradient(50deg, #d1427d 5%, #e63338 25%, #ea5b25 75%, #f39325 100%)`,
    '&::before': {
      content: '',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      background: 'inherit',
      filter: 'blur(10px)',
    },
    borderRadius: theme.borders.radius * 2,
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.spacing.unit(2)}px ${theme.spacing.unit(2)}px`,
    overflow: 'hidden',
    minHeight: `${theme.spacing.unit(8)}px`,
  },
  contentContainer: {
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  contentContainerFloat: {
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  contentScrollable: {
    overflowY: 'auto',
    height: '100%',
    paddingBottom: `${theme.spacing.unit(4)}px`,
  },
}));

interface CreateProjectSelectProps {
  className?: string;
}

export const CreateProjectSelect: React.FC<CreateProjectSelectProps> = (
  props
) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState<string>();
  const [projectDrafts, setProjectDrafts] = useState<Project[]>();
  const [modelVisible, setModelVisible] = useState<boolean>(false);

  const createProjectFromDraft = () => {
    setModelVisible(true);
  };

  const handleProjectFromDraftCreated = async (
    projectToCreate: CreateProjectDraftByProjectDto
  ) => {
    const { data } = await apiCreateProjectDraftByProject(projectToCreate);
    if (data) {
      navigate(`../step/${data.projectId}`);
    }
  };
  const showDeleteModal = (projectId: string) => {
    setProjectIdToDelete(projectId);
    setIsDeleteModalOpen(true);
  };
  const handleOk = () => {
    deleteDraft();
    setIsDeleteModalOpen(false);
    setProjectIdToDelete('');
  };
  const deleteDraft = async () => {
    const { result } = await apiDeleteProjectCreationDraft(projectIdToDelete);
    if (result.status !== 204) {
      notification.error({
        message: t('common:error'),
        description: t('projects:projectCreation.deletion.error'),
      });
    } else {
      const newProjectDrafts = projectDrafts?.filter(
        (project) => project.projectId !== projectIdToDelete
      );
      setProjectDrafts(newProjectDrafts);
    }
  };

  // effects
  useEffect(() => {
    const loadProjectDrafts = async () => {
      const { data } = await apiGetProjectDraftsById();
      if (data) {
        setProjectDrafts(data);
      }
    };
    loadProjectDrafts();
  }, []);

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.wrapContainerWhite}>
          <div className={classes.contentContainerFloat}>
            <Button
              type="primary"
              iconProp={['fal', 'puzzle-piece']}
              onClick={() => {
                navigate(`../step/`);
              }}
            >
              {t(`projects:projectCreation.newProject`)}
            </Button>
            <Button
              type="primary"
              iconProp={['fal', 'puzzle-piece']}
              onClick={() => {
                createProjectFromDraft();
              }}
            >
              {t(`projects:projectCreation.newProjectFromDraft`)}
            </Button>
          </div>
        </div>
        <div className={classes.wrapContainerWhite}>
          <h3>{t('projects:projectCreation.drafts')}</h3>
          <div className={classes.contentScrollable}>
            <List
              itemLayout="vertical"
              size="large"
              className={classes.listRoot}
              dataSource={projectDrafts}
              renderItem={(item) => (
                <List.Item
                  key={item.projectId}
                  className={classes.listCard}
                  actions={[]}
                  extra={[
                    <div className={classes.contentContainerFloat}>
                      <Button
                        type="default"
                        disabled={item.projectId === projectIdToDelete}
                        onClick={() => {
                          navigate(`../step/${item.projectId}?step=data`);
                        }}
                        iconProp={['fal', 'pen']}
                      ></Button>
                      <Button
                        type="default"
                        disabled={item.projectId === projectIdToDelete}
                        onClick={() => {
                          showDeleteModal(item.projectId);
                        }}
                        iconProp={['fal', 'trash']}
                      ></Button>
                    </div>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<UserAvatar />}
                    title={item.shortName}
                    description={item.name}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
      <CreateProjectFromProjectModal
        createProjectFromDraftModelVisible={modelVisible}
        setCreateProjectFromDraftModelVisible={setModelVisible}
        handleFinished={handleProjectFromDraftCreated}
      />
      <Modal
        title={t('projects:projectCreation.deletion.title')}
        visible={isDeleteModalOpen}
        onOk={handleOk}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <p>{t('projects:projectCreation.deletion.description')}</p>
      </Modal>
    </>
  );
};

export default CreateProjectSelect;
