import React, { useEffect, useState } from 'react';
import Flex from '../../../components/Flex';
import TimeRecordsTable from './TimeRecordsTable';
import {
  getAllTimeRecords,
  getMyTimeRecords,
  getTimeRecordsIsFetching,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectId, TimeRecordId } from '../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Result } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import EditTimeRecord from './EditTimeRecord/EditTimeRecord';
import { TimeRecord } from '../../../models/TimeRecord';
import { useTranslation } from 'react-i18next';
import { fetchProjectTimeRecords } from '../actions';
import classNames from 'classnames';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  table: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  panelHeadline: {
    '&.ant-typography': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
}));

type TimeRecordSelection = 'my' | 'all';

interface TimeRecordsPageProps {
  className?: string;
  selectedList: TimeRecordSelection;
  projectId: ProjectId;
  onRowSelectionChange?: (selectedTimeRecordIds: TimeRecordId[]) => void;
}

export const TimeRecordsPage: React.FC<TimeRecordsPageProps> = (props) => {
  const classes = useStyles();
  const {
    className,
    selectedList = 'all',
    projectId,
    onRowSelectionChange,
  } = props;
  const [selectedTimeRecordId, setSelectedTimeRecordId] =
    useState<TimeRecordId | null>(null);

  const isFetching = useSelector(getTimeRecordsIsFetching);

  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && selectedList === 'all') {
      dispatch(fetchProjectTimeRecords(projectId));
    }
  }, [projectId, selectedList, dispatch]);

  const handleTimeRecordClick = (timeRecordId: TimeRecordId) => {
    setSelectedTimeRecordId(timeRecordId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const timeRecords = useSelector<RootReducerState, TimeRecord[]>((state) =>
    selectedList === 'my' ? getMyTimeRecords(state) : getAllTimeRecords(state)
  ).filter((record) => record?.projectId === projectId);

  const width = open ? panelWidth : 0;
  const opacity = open ? '100%' : '0%';

  if (!projectId)
    return (
      <Result
        status="500"
        title={t('common:error')}
        subTitle={t('common:somethingWentWrong')}
        extra={<Button>Back Home</Button>}
      />
    );

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      <TimeRecordsTable
        timeRecords={timeRecords}
        className={classes.table}
        onTimeRecordClick={handleTimeRecordClick}
        onRowSelectionChange={onRowSelectionChange}
        loading={isFetching && timeRecords.length === 0}
      />
      <div className={classes.panel} style={{ width: width, opacity }}>
        <div className={classes.panelChild}>
          <EditTimeRecord
            timeRecordId={selectedTimeRecordId}
            titleComponent={(timeRecord: TimeRecord) => (
              <Typography.Title level={3} className={classes.panelHeadline}>
                <FontAwesomeIcon icon={['fal', 'user-clock']} />{' '}
                {timeRecord.title}
              </Typography.Title>
            )}
            onFinish={handleClose}
            onCancel={handleClose}
            onDelete={handleClose}
            contextType={selectedList === 'my' ? 'me' : 'project'}
          />
        </div>
        <Button
          onClick={handleClose}
          shape="circle"
          iconProp={['fal', 'times']}
          className={classes.closeButton}
          type="link"
        />
      </div>
    </Flex.Row>
  );
};

export default TimeRecordsPage;
