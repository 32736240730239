import React, { CSSProperties } from 'react';
import Flex from '../../../../components/Flex';
import { Project } from '../../../../models/Project';
import { ProjectId, ProjectTabViews } from '../../../../models/Types';
import ProjectMenu from '../ProjectMenu';
import NavigationItemPanelHeaderProject from './NavigationItemPanelHeaderProject';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'height 0.2s',
  },
  menu: {
    background: 'none',
    border: 'none',
    transition: 'padding 0.2s',
  },
}));

interface ProjectDropDownNavigationItemProps {
  className?: string;
  isSubMenu: boolean;
  key: string;
  style: CSSProperties;
  project?: Project;
  pathPrefix?: string;
  selectedSubModule?: string;
  activeProjectId: ProjectId;
  isProjectMember?: boolean;
  type?: ProjectTabViews;
  isParentProject?: boolean;
  collapsed?: boolean;
  setCollapsed?: (projectId: ProjectId, collapsed: boolean) => void;
  isAnySubProjectFavorited?: boolean;
  activeTab?: string;
  rerenderProjectList?: () => void;
}

export const ProjectDropDownNavigationItem: React.FC<
  ProjectDropDownNavigationItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    project,
    style,
    pathPrefix,
    selectedSubModule,
    activeProjectId,
    isProjectMember,
    type,
    isSubMenu,
    isParentProject,
    collapsed,
    className,
    setCollapsed,
    isAnySubProjectFavorited,
    activeTab,
    rerenderProjectList,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(classes.root, className)}
      style={{
        ...style,
        ...(isSubMenu
          ? {
              height: 40,
            }
          : {}),
      }}
    >
      <NavigationItemPanelHeaderProject
        project={project}
        rootPath={pathPrefix}
        projectReady={
          project?.projectStatus === 'ready' ||
          project?.projectStatus === 'finished'
        }
        isProjectMember={isProjectMember}
        isSubMenu={isSubMenu}
        type={type}
        isActive={activeProjectId === project.projectId}
        isParentProject={isParentProject}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        isAnySubProjectFavorited={isAnySubProjectFavorited}
        activeTab={activeTab}
        rerenderProjectList={rerenderProjectList}
      />
      {!isSubMenu &&
        !project.isTemporary &&
        activeProjectId === project.projectId && (
          <Flex.Item flex={1}>
            <ProjectMenu
              projectId={project.projectId}
              selectedProjectId={activeProjectId}
              selectedSubModule={selectedSubModule}
              masterPlanId={project.masterPlanId}
              oneDrivePath={project.oneDrivePath}
              groupId={project.groupId}
              emailSuffix={project.eMailSuffix}
              emailPrefix={project.eMailPrefix}
              isProjectMember={isProjectMember}
              pathPrefix={pathPrefix}
              subsidiaryId={project.subsidiaryId}
              sharedMailboxUserId={project.sharedMailboxUserId}
            />
          </Flex.Item>
        )}
    </div>
  );
};

export default ProjectDropDownNavigationItem;
