import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Popover, Typography } from 'antd';
import PrioSpinner from '../../../../components/PrioSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { apiFetchReteachParticipation } from '../../../training/api';
import {
  CustomerCourseParticipationReadDto,
  CustomerCourseParticipationReadDtoStatus,
  ReteachCourseReadDto,
  ReteachCourseReadDtoType,
  ReteachThumbnails,
} from '../../../../models/Training';
import moment from 'moment';
import { VirtualList } from '@prio365/prio365-react-library';
import { DateTimeString } from '../../../../models/Types';
import i18n from '../../../../i18n';
import { sanitizeHTML } from '../../../../util';
import ReactHtmlParser from 'react-html-parser';
import * as ConfigValues from '../../../../util/configValues';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reloadIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.old.palette.chromaticPalette.lightGrey,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    width: '100%',
    '& a': {
      display: 'none',
    },
    '&:hover a': {
      display: 'block',
    },
  },
  image: {
    width: 80,
    overflow: 'hidden',
    display: 'flex',
    marginLeft: theme.old.spacing.baseSpacing,
    justifyContent: 'center',
  },
  listItemContent: {
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    marginLeft: theme.old.spacing.unit(2),
  },
  listItemTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  listItemTitle: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: theme.old.spacing.unit(2),
  },
  listItemDescription: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '30vh',
    maxWidth: '30vw',
    overflow: 'auto',
    '& > *:not(:last-child)': {
      marginBottom: theme.old.spacing.baseSpacing,
    },
  },
  popoverContentLabel: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    marginBottom: theme.old.spacing.unit(0.5),
  },
  popoverContentDescription: {
    marginBottom: theme.old.spacing.baseSpacing,
  },
  textWrapEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const getCourseType: (type: number) => ReteachCourseReadDtoType = (type) => {
  switch (type) {
    case 1: {
      return 'SINGLE_DAY_ONLINE_COURSE';
    }
    case 2: {
      return 'MULTIPLE_DAYS_ONLINE_COURSE';
    }
    case 3: {
      return 'ONLINE_COURSE';
    }
    case 4: {
      return 'SINGLE_DAY_PRESENCE_TRAINING';
    }
    case 5: {
      return 'MULTIPLE_DAYS_PRESENCE_TRAINING';
    }
    case 6: {
      return 'SINGLE_DAY_LIVE_TRAINING';
    }
    case 7: {
      return 'MULTIPLE_DAYS_LIVE_TRAINING';
    }
    case 8: {
      return 'SINGLE_DAY_HYBRID_TRAINING';
    }
    case 9: {
      return 'MULTIPLE_DAYS_HYBRID_TRAINING';
    }
    default: {
      return 'SINGLE_DAY_ONLINE_COURSE';
    }
  }
};

const getCourseStatus: (
  status: number
) => CustomerCourseParticipationReadDtoStatus = (status) => {
  switch (status) {
    case 1: {
      return 'ADDED';
    }
    case 2: {
      return 'INVITED';
    }
    case 3: {
      return 'INVITATION_ACCEPTED';
    }
    case 4: {
      return 'JOINED';
    }
    case 5: {
      return 'STARTED';
    }
    case 6: {
      return 'COMPLETED';
    }
    case 7: {
      return 'DECLINED';
    }
    case 8: {
      return 'EXPIRED';
    }
    default: {
      return 'ADDED';
    }
  }
};

const parseCourseStatus: (status: number) => string = (status) => {
  const _status = getCourseStatus(status).toLowerCase();
  return i18n.t(`dashboard:reteach.courseStatus.${_status}`);
};

const parseCourseType: (type: number) => string = (type) => {
  const _type = getCourseType(type);
  const suffix = _type.includes('ONLINE')
    ? i18n.t('dashboard:reteach.courseType.online')
    : _type.includes('PRESENCE')
    ? i18n.t('dashboard:reteach.courseType.presence')
    : _type.includes('PRESENCE')
    ? i18n.t('dashboard:reteach.courseType.live')
    : i18n.t('dashboard:reteach.courseType.hybrid');
  return suffix;
};

const parseDateTime: (
  startsAt: DateTimeString,
  endsAt: DateTimeString,
  type: number
) => string = (startsAt, endsAt, type) => {
  const _type = getCourseType(type);
  if (startsAt && endsAt) {
    if (_type.includes('MULTIPLE')) {
      return `${moment(startsAt).format('dd. DD.MM.YYYY')} - ${moment(
        endsAt
      ).format('dd. DD.MM.YYYY')}, ${moment(startsAt).format(
        'HH:mm'
      )} - ${moment(endsAt).format('HH:mm')} ${i18n.t('common:moment.aClock')}`;
    } else if (_type.includes('SINGLE')) {
      return `${moment(startsAt).format('dd. DD.MM.YYYY')}, ${moment(
        startsAt
      ).format('HH:mm')} - ${moment(endsAt).format('HH:mm')} ${i18n.t(
        'common:moment.aClock'
      )}`;
    }
  }
  return '';
};

interface DashboardAbsenceOverviewItemProps {
  className?: string;
}

export const DashboardAbsenceOverviewItem: React.FC<
  DashboardAbsenceOverviewItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [myNextCourses, setMyNextCourses] =
    useState<CustomerCourseParticipationReadDto[]>(null);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchMyNextCourses = useCallback(async () => {
    setIsFetching(true);
    let myNextCourses: CustomerCourseParticipationReadDto[] = [];
    const { data } = await apiFetchReteachParticipation();
    if (data) {
      myNextCourses = data
        .filter(({ course: { startsAt } }) => {
          return (
            moment(startsAt ?? undefined).isSameOrAfter(moment(), 'days') &&
            moment(startsAt ?? undefined).isBefore(
              moment().add(6, 'weeks'),
              'days'
            )
          );
        })
        .filter(({ course: { isDraft } }) => isDraft === false)
        .filter(({ completedAt }) => completedAt === null)
        .sort(({ course: { startsAt: a } }, { course: { startsAt: b } }) =>
          moment(a ?? undefined).diff(moment(b ?? undefined))
        );
    }
    setIsFetching(false);
    setMyNextCourses(myNextCourses);
  }, []);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    fetchMyNextCourses();
  }, [fetchMyNextCourses]);
  //#endregion

  //#region ------------------------------ Components
  const popoverWrapper = useCallback(
    (
      child: React.ReactNode,
      simplify: boolean,
      course: ReteachCourseReadDto,
      thumbnail: ReteachThumbnails
    ) => {
      if (simplify) {
        return child;
      }

      return (
        <Popover
          title={course.name}
          content={
            <div className={classes.popoverContent}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {thumbnail && (
                  <img
                    src={thumbnail.url}
                    width={'50%'}
                    alt={course.name}
                    style={{
                      paddingRight: theme.old.spacing.unit(2),
                    }}
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  {course.startsAt && course.endsAt && (
                    <>
                      <div className={classes.popoverContentLabel}>
                        {t('dashboard:reteach.dateTitle')}
                      </div>
                      <div className={classes.popoverContentDescription}>
                        {parseDateTime(
                          course.startsAt,
                          course.endsAt,
                          course.type
                        )}
                      </div>
                    </>
                  )}
                  <div className={classes.popoverContentLabel}>
                    {t('dashboard:reteach.courseStatusTitle')}
                  </div>
                  <div className={classes.popoverContentDescription}>
                    {parseCourseStatus(course.status)}
                  </div>
                  <div className={classes.popoverContentLabel}>
                    {t('dashboard:reteach.courseTypeTitle')}
                  </div>
                  <div className={classes.popoverContentDescription}>
                    {parseCourseType(course.type)}
                  </div>
                </div>
              </div>
              <div>{ReactHtmlParser(sanitizeHTML(course.description))}</div>
            </div>
          }
        >
          {child}
        </Popover>
      );
    },
    [theme, classes, t]
  );
  // #endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row childrenGap={theme.old.spacing.baseSpacing} marginBottom={8}>
        <Flex.Item>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
            {t('dashboard:reteach.title')}
          </Typography.Title>
        </Flex.Item>
        <Flex.Item flex={1}>
          {isFetching ? (
            <PrioSpinner size="small" />
          ) : (
            <FontAwesomeIcon
              icon={['fal', 'sync']}
              onClick={fetchMyNextCourses}
              className={classes.reloadIcon}
            />
          )}
        </Flex.Item>
        <a
          href={ConfigValues.RETEACH_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={['fal', 'arrow-up-right-from-square']}
            color={theme.old.typography.colors.muted}
          />
        </a>
      </Flex.Row>
      {!myNextCourses || myNextCourses?.length === 0 ? (
        <Flex.Row>
          {!isFetching && (
            <Typography.Text type="secondary">
              {t('dashboard:reteach.noCoursesWithinNext6Weeks')}
            </Typography.Text>
          )}
        </Flex.Row>
      ) : (
        <Flex.Item flex={1}>
          <VirtualList
            items={myNextCourses ?? []}
            rowHeight={64}
            rowRenderer={({ index, key, isScrolling, isVisible }) => {
              const course = myNextCourses[index]?.course;
              const thumbnails = course?.image?.thumbnails;
              const thumbnail: ReteachThumbnails = thumbnails?.reduce(
                (current, thumbnail) => {
                  if (!current) {
                    return thumbnail;
                  }
                  if (current.width < thumbnail.width) {
                    return current;
                  }
                  return current;
                },
                null
              );

              const dateTime = parseDateTime(
                course.startsAt,
                course.endsAt,
                course.type
              );

              const parsedType = parseCourseType(course.type);

              const description = `${
                dateTime ? `${dateTime}, ` : ''
              }${parsedType}`;

              const simplify = isScrolling || !isVisible;

              return (
                <div key={key} className={classes.listItem}>
                  {popoverWrapper(
                    <div className={classes.image}>
                      {thumbnail?.url ? (
                        <img
                          src={thumbnail?.url}
                          style={{
                            width: '100%',
                          }}
                          alt={''}
                        />
                      ) : (
                        <div>
                          <FontAwesomeIcon
                            icon={['fal', 'image']}
                            size="xl"
                            color="lightgrey"
                          />
                        </div>
                      )}
                    </div>,
                    simplify,
                    course,
                    thumbnail
                  )}

                  <div className={classes.listItemContent}>
                    <div className={classes.listItemTitleRow}>
                      <div
                        className={classes.listItemTitle}
                        title={course?.name}
                      >
                        {course?.name}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        classes.listItemDescription,
                        classes.textWrapEllipsis
                      )}
                      title={description}
                    >
                      {description}
                    </div>
                  </div>
                  <a
                    href={course.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'arrow-up-right-from-square']}
                      color={theme.old.palette.primaryColor}
                      style={{
                        marginRight: theme.old.spacing.baseSpacing,
                      }}
                    />
                  </a>
                </div>
              );
            }}
          />
        </Flex.Item>
      )}
    </Flex.Column>
  );
};

export default DashboardAbsenceOverviewItem;
