import React, { useEffect, useState } from 'react';
import { Divider, Input, notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { DocumentTemplateCategoryId } from '../../../models/Types';
import { DocumentTemplateCategory } from '../../../models/DocumentTemplate';
import { useTranslation } from 'react-i18next';
import {
  apiCreateTemplateCategory,
  apiFetchDocumentTemplateCategories,
} from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface DocumentTemplateCategoryPickerProps {
  value?: DocumentTemplateCategoryId[];
  onChange?: (
    value: DocumentTemplateCategoryId[] & DocumentTemplateCategoryId
  ) => void;
  disabled?: boolean;
  multiple?: boolean;
  allowCreate?: boolean;
  emptyLabel?: string;
}

export const DocumentTemplateCategoryPicker: React.FC<
  DocumentTemplateCategoryPickerProps
> = (props) => {
  const classes = useStyles();
  const { value, onChange, disabled, multiple, allowCreate, emptyLabel } =
    props;
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [categories, setCategories] = useState<DocumentTemplateCategory[]>([
    ...(emptyLabel
      ? [
          {
            documentTemplateCategoryId: emptyLabel,
            name: emptyLabel,
          },
        ]
      : []),
  ]);
  const [fetching, setFetching] = useState<boolean>(true);
  const { t } = useTranslation();

  const onNewCategoryNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewCategoryName(event.target.value);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadCategories = async () => {
      try {
        const { data } = await apiFetchDocumentTemplateCategories(signal);

        if (data && Array.isArray(data)) {
          setCategories(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        setFetching(false);
      } catch {}
    };
    loadCategories();
    return () => {
      controller.abort();
    };
  }, [setCategories]);

  const createCategory = async () => {
    const { data } = await apiCreateTemplateCategory(newCategoryName);
    if (data) {
      setCategories(
        [...categories, data].sort((a, b) => a.name.localeCompare(b.name))
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'documents:documentTemplateCategoryPicker.errorMessages.createCategoryError'
        ),
      });
    }
  };

  const handleOnChange = (value) => {
    console.log(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      disabled={disabled}
      className={classes.root}
      mode={multiple ? 'multiple' : null}
      value={categories.length > 0 ? value : []}
      placeholder={t('documents:documentTemplateCategoryPicker.placeholder')}
      notFoundContent={fetching ? <PrioSpinner size="small" /> : null}
      filterOption={(inputValue: string, option) => {
        return option.value.toLowerCase().startsWith(inputValue.toLowerCase());
      }}
      onChange={handleOnChange}
      dropdownRender={
        allowCreate
          ? (menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div
                  style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}
                >
                  <Input
                    style={{ flex: 'auto' }}
                    value={newCategoryName}
                    onChange={onNewCategoryNameChange}
                  />
                  <Button
                    disabled={newCategoryName.trim().length === 0}
                    onClick={createCategory}
                    iconProp={['fal', 'plus']}
                  >
                    {t('documents:documentTemplateCategoryPicker.addAction')}
                  </Button>
                </div>
              </div>
            )
          : null
      }
    >
      {categories?.filter((c) => c?.name !== emptyLabel)?.length === 0 ? (
        <Select.Option value="" disabled>
          {t('documents:documentTemplateCategoryPicker.emptyCategoriesMessage')}
        </Select.Option>
      ) : null}
      {[
        ...(emptyLabel
          ? [
              <Select.Option value={emptyLabel} key="empty">
                {emptyLabel}
              </Select.Option>,
            ]
          : []),
        ...(categories
          ?.filter((c) => c?.name !== emptyLabel)
          ?.map((d) => (
            <Select.Option
              key={d.documentTemplateCategoryId}
              value={d.documentTemplateCategoryId}
            >
              {d.name}
            </Select.Option>
          )) || []),
      ]}
    </Select>
  );
};

export default DocumentTemplateCategoryPicker;
