import { Alert, Col, Form, Input, Row, Select, Switch, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { AttributeValidations } from '../../../../../../models/Project';
import { EMailSuffix, ProjectId } from '../../../../../../models/Types';
import { PrioTheme } from '../../../../../../theme/types';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { colon, rowGutter } from '../../../../../../util/forms';
import {
  getEmailprefix,
  getEmailSuffixIndex,
} from '../../../../../../util/helpers';
import { apiFetchEmailSuffixes } from '../../../../../settings/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    position: 'relative',
    overflow: 'hidden',
  },

  eMailSuffixSelect: {
    '&:before': {
      content: '"@"',
      display: 'inline-block',
      position: 'absolute',
      left: -22,
      top: 3,
      color: theme.typography.colors.muted,
    },
  },
}));

interface SharedMailboxProjectExtensionFormProps {
  sharedMailboxProjectExtensionFormModel: SharedMailboxProjectExtensionFormModel;
  saveForm?: (
    sharedMailboxProjectExtensionFormModel: SharedMailboxProjectExtensionFormModel
  ) => void;
  isCreation: boolean;
  projectId: ProjectId;
  validateData: (
    sharedMailboxProjectExtensionFormModel: SharedMailboxProjectExtensionFormModel
  ) => void;
  validations: AttributeValidations;
}

export interface SharedMailboxProjectExtensionFormModel {
  displayName: string;
  email: string;
  eMailPrefix?: string;
  eMailSuffix?: EMailSuffix;
  hasDefaultAccess: boolean;
}

export const SharedMailboxProjectExtensionForm: React.FC<
  SharedMailboxProjectExtensionFormProps
> = (props) => {
  const {
    sharedMailboxProjectExtensionFormModel,
    saveForm,
    validateData,
    validations,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [emailSuffixes, setEmailSuffixes] = useState<EMailSuffix[]>([]);

  useEffect(() => {
    setDisabled(true);
    const loadEmailSuffixes = async () => {
      const { data } = await apiFetchEmailSuffixes();
      if (data) {
        setEmailSuffixes(data);
        if (data?.length > 0) {
          if (!sharedMailboxProjectExtensionFormModel?.email) {
            form.setFieldsValue({
              eMailSuffix: data[0],
            });
          } else {
            form.setFieldsValue({
              eMailSuffix:
                data[
                  getEmailSuffixIndex(
                    sharedMailboxProjectExtensionFormModel.email,
                    data
                  )
                ],
            });
          }
        }
      }
    };
    loadEmailSuffixes();
    if (sharedMailboxProjectExtensionFormModel?.email) {
      form.setFieldsValue({
        eMailPrefix: getEmailprefix(
          sharedMailboxProjectExtensionFormModel.email
        ),
      });
      form.setFieldsValue({
        displayName: sharedMailboxProjectExtensionFormModel.displayName,
      });
    }
    setDisabled(false);
  }, [form, sharedMailboxProjectExtensionFormModel]);

  const validateProjectData = async () => {
    await validateData(form.getFieldsValue());
  };

  return (
    <>
      {sharedMailboxProjectExtensionFormModel.displayName ? (
        <Typography.Title level={4}>
          {t('projects:addIn.sharedMailbox')}:{' '}
          {sharedMailboxProjectExtensionFormModel.email}
        </Typography.Title>
      ) : (
        <Form
          form={form}
          layout="vertical"
          className={classes.root}
          initialValues={sharedMailboxProjectExtensionFormModel}
          onFinish={(values) => {
            if (saveForm) {
              saveForm(values);
            }
          }}
          onValuesChange={(_, values) => {
            //nothingToDo
          }}
        >
          <Typography.Title level={4}>
            {t('projects:addIn.sharedMailbox')}
          </Typography.Title>
          <Form.Item
            name="displayName"
            colon={colon}
            validateTrigger="onBlur"
            label={t(
              'projects:addIn.extensions.sharedMailbox.form.displayName'
            )}
            rules={[
              () => ({
                async validator(rule, value) {
                  await validateProjectData();
                },
              }),
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>
          <Row
            gutter={theme.spacing.unit(rowGutter)}
            style={{ marginTop: theme.spacing.unit(3) }}
          >
            <Col span={12}>
              <Form.Item
                name="eMailPrefix"
                label={t('projects:form.labels.eMailPrefix')}
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectData();
                    },
                  }),
                ]}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="eMailSuffix"
                label={t('projects:form.labels.eMailSuffix')}
                colon={colon}
                validateTrigger="onChange"
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.missingEmailSuffix'),
                  },
                ]}
              >
                <Select className={classes.eMailSuffixSelect}>
                  {emailSuffixes.map((emailSuffix) => (
                    <Select.Option value={emailSuffix} key={emailSuffix}>
                      {emailSuffix}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="hasDefaultAccess"
                colon={colon}
                label={t('projects:addIn.extensions.form.hasDefaultAccess')}
                required={true}
                rules={[
                  {
                    required: true,
                    message: t(
                      'projects:form.validation.hasDefaultAccessRequired'
                    ),
                  },
                ]}
                valuePropName="checked"
              >
                <Switch disabled={true} />
              </Form.Item>
            </Col>
            {!validations?.isValid &&
              validations?.attributeErrorValidationList?.length > 0 && (
                <Col span={24}>
                  {console.log(validations)}
                  <Alert
                    type="warning"
                    message={validations?.attributeErrorValidationList?.map(
                      (error) => {
                        if (!error.isValid) {
                          return <p>{error.descriptions.join(', ')}</p>;
                        }
                        return null;
                      }
                    )}
                  ></Alert>
                </Col>
              )}
          </Row>

          <Button htmlType="submit" disabled={!validations?.isValid}>
            {t('projects:projectCreation.save')}
          </Button>
        </Form>
      )}
    </>
  );
};

export default SharedMailboxProjectExtensionForm;
