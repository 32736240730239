import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
  getActiveMessageId,
  getActiveProjectInMessageCenter,
  getMailSettings,
  getMessage,
  getSearchMessage,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import ForwardMessageView from './ForwardMessageView';
import MessageView from './MessageView';
import EventMessageView from './EventMessageView';
import { makePrioStyles } from '../../../theme/utils';
import { Message, MessageBody } from '../../../models/Message';
import {
  MailFolderId,
  MeetingMessageType,
  MessageId,
  ProjectId,
} from '../../../models/Types';
import PrioSpinner from '../../../components/PrioSpinner';
import DraftMessagePage from './DraftMessagePage';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  apiDownloadAttachmentToBase64,
  apiFetchAttachments,
  apiFetchEventMessage,
  apiFetchMessage,
  attachmentBlobToUrl,
  apiFetchParsedSmimeAttachments,
} from '../api';
import { debounceFunction } from '../../../util';
import { Dispatch } from 'redux';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { notification } from 'antd';
import i18n from '../../../i18n';
import { addMessageToCache } from '../actions/actionControllers/messageActionController';
import equals from 'deep-equal';

const viewPattern =
  /\/view\/((\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b)|me)\/(message|composer)/;

const inlineImageSrcRegex = /((src="cid(:|%3Ad).*?")|(\[cid(:|%3Ad).*?\]))/g;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  eventView: {
    height: '100%',
    overflow: 'hidden',
  },
  eventCompressedView: {
    '& > div:first-child': {
      padding: '16px 16px 0px',
      marginBottom: '8px',
      '& .ant-tabs-tab': {
        paddingBottom: '6px',
      },
    },
  },
  messageView: {
    height: '100%',
    overflowY: 'auto',
  },
}));

export const fetchAndParseAttachments = async (
  message: Message,
  projectId: ProjectId,
  messageId: MessageId,
  signal: AbortSignal,
  setMessage: (value: Message) => void,
  setHasInlineLoaded: (value: boolean) => void
) => {
  let body = message?.body;
  if (!!body?.content) {
    let content = body.content;
    var tests = content.matchAll(inlineImageSrcRegex);
    let mySubString = Array.from(tests);
    const { data } = await apiFetchAttachments(projectId, messageId);
    if (!data) {
      return;
    }
    for (const attachmentElement of data) {
      const isInline =
        attachmentElement.isInline ||
        /^inline-img-/.test(attachmentElement.name ?? '');
      if (!isInline) {
        if (attachmentElement.name.match(/\.(p7m)$/)) {
          const { data: smimeAttachments } =
            await apiFetchParsedSmimeAttachments(
              projectId,
              messageId,
              attachmentElement.id,
              signal
            );

          if (smimeAttachments?.length > 0) {
            for (const smimeAttachment of smimeAttachments) {
              data.push(smimeAttachment);
            }
          }
        }
      } else {
        let url = '';
        if (!attachmentElement.contentBytes) {
          url = await apiDownloadAttachmentToBase64(
            projectId,
            messageId,
            attachmentElement,
            signal
          );
        } else {
          url = attachmentBlobToUrl(
            attachmentElement.contentBytes,
            attachmentElement.contentType
          );
        }

        if (mySubString) {
          const currentId = mySubString.find((element) => {
            const { name, contentId } = attachmentElement;
            if (!!contentId || !!name) {
              if (!!contentId && element[0].includes(contentId)) {
                return true;
              }
              const nameMatch = name.match(/^(inline-img-)?(.*)$/);
              if (!!name && nameMatch && element[0].includes(nameMatch[2])) {
                return true;
              }
            }
            return false;
          });
          if (currentId) {
            if (!!currentId[0].match(/^\[.*\]$/)) {
              content = content.replace(currentId[0], `<img src="${url}">`);
            } else {
              content = content.replace(currentId[0], `src="${url}"`);
            }
          }
        }
      }
    }
    const bodyToSet: MessageBody = {
      contentType: body.contentType,
      content: content,
    };
    const attachments = data.filter(
      ({ name }) => !(name.match(/\.(p7m)$/) || name.match(/\.(p7s)$/))
    );
    if (!signal.aborted) {
      if (
        !equals(bodyToSet, message.body) ||
        !equals(attachments, message.attachments)
      ) {
        setMessage({
          ...message,
          attachments,
          body: bodyToSet,
        });
      }
      setHasInlineLoaded(true);
    }
  }
};

const debouncedFetchAttachments = debounceFunction(
  fetchAndParseAttachments,
  500
);

const debouncedFetchMessage = debounceFunction(
  async (
    projectId: ProjectId,
    mailFolderId: MailFolderId,
    messageId: MessageId,
    meetingMessageType: MeetingMessageType,
    signal: AbortSignal,
    setMessage: (value: Message) => void,
    setHasInline: (value: boolean) => void,
    setHasInlineLoaded: (value: boolean) => void,
    dispatch: Dispatch,
    navigate: NavigateFunction
  ) => {
    if (messageId) {
      setHasInline(false);
      setHasInlineLoaded(false);
      const { data } =
        meetingMessageType && meetingMessageType !== 'None'
          ? await apiFetchEventMessage(projectId, messageId)
          : await apiFetchMessage(projectId, messageId, signal);

      if (!signal.aborted) {
        if (data) {
          if (!!data?.body?.content?.match(inlineImageSrcRegex)) {
            setHasInline(true);
          }
          dispatch(addMessageToCache(projectId, data));
          setMessage(data);
        } else {
          if (window.location.href.match(viewPattern)) {
            notification.open({
              message: i18n.t('common:error'),
              description: i18n.t('mail:errorMessages.messages.loadError'),
            });
          } else {
            dispatch(setMailListNavigationState(null, projectId));
            navigate(`../mail/${mailFolderId}`);
          }
          setMessage(null);
        }
      }
    } else {
      if (!window.location.href.match(viewPattern)) {
        dispatch(setMailListNavigationState(null, projectId));
        navigate(`../mail/${mailFolderId}`);
      }
      setMessage(null);
    }
  },
  300
);

interface MessageDetailsPageProps {
  noPadding?: boolean;
  backButton?: boolean;
  projectId: ProjectId;
  prefix?: string;
  mailFolderId: MailFolderId;
}

export const MessageDetailsPage: React.FC<MessageDetailsPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const {
    noPadding,
    backButton,
    projectId: componentProjectId,
    prefix,
    mailFolderId,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const messageCenterProjectId = useSelector(getActiveProjectInMessageCenter);
  const projectId =
    componentProjectId === 'favorites'
      ? messageCenterProjectId
      : componentProjectId;

  const messageId = useSelector<RootReducerState, string>((state) =>
    getActiveMessageId(state, projectId)
  );

  const reduxMessage = useSelector<RootReducerState, Message>(
    (state) =>
      getMessage(state, projectId, messageId) ??
      getSearchMessage(state, projectId, messageId)
  );
  const mailSettings = useSelector(getMailSettings);

  const [_message, setMessage] = useState<Message>(null);
  const [hasInline, setHasInline] = useState<boolean>(false);
  const [hasInlineLoaded, setHasInlineLoaded] = useState<boolean>(false);

  const message: Message = useMemo(
    () => ({
      ...reduxMessage,
      body: _message?.body,
      event: _message?.event,
      receivedDateTime:
        reduxMessage?.receivedDateTime ?? _message?.receivedDateTime,
      subject: reduxMessage?.subject ?? _message?.subject,
      from: reduxMessage?.from ?? _message?.from,
      toRecipients: reduxMessage?.toRecipients ?? _message?.toRecipients,
      ccRecipients: reduxMessage?.ccRecipients ?? _message?.ccRecipients,
      bccRecipients: reduxMessage?.bccRecipients ?? _message?.bccRecipients,
      attachments: _message?.attachments ?? reduxMessage?.attachments,
    }),
    [reduxMessage, _message]
  );

  const messageController = useRef<AbortController>(null);
  const attachmentController = useRef<AbortController>(null);

  const messageToCompare = useRef<Message>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setMessage(null);
    messageToCompare.current = null;
    if (messageController.current) {
      messageController.current.abort();
    }
    if (attachmentController.current) {
      attachmentController.current.abort();
    }
    attachmentController.current = new AbortController();
  }, [messageId]);

  useEffect(() => {
    if (!_message && messageId) {
      messageController.current = new AbortController();
      const signal = messageController.current.signal;
      debouncedFetchMessage(
        projectId,
        mailFolderId,
        messageId,
        reduxMessage?.meetingMessageType,
        signal,
        setMessage,
        setHasInline,
        setHasInlineLoaded,
        dispatch,
        navigate
      );
    }
    return () => {
      if (messageController.current) {
        messageController.current.abort();
      }
    };
  }, [
    projectId,
    mailFolderId,
    messageId,
    _message,
    reduxMessage?.meetingMessageType,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (
      _message &&
      !equals(messageToCompare.current, _message) &&
      messageId === _message.id &&
      (_message.hasAttachments || hasInline) &&
      !_message.isDraft &&
      !hasInlineLoaded
    ) {
      attachmentController.current = new AbortController();
      debouncedFetchAttachments(
        _message,
        projectId,
        messageId,
        attachmentController.current.signal,
        (value) => {
          setMessage(value);
          messageToCompare.current = value;
        },
        setHasInlineLoaded
      );
    }
    return () => {
      if (attachmentController.current) {
        attachmentController.current.abort();
      }
    };
  }, [hasInline, hasInlineLoaded, _message, projectId, messageId]);
  //#endregion

  if (!message?.body) {
    return <ForwardMessageView message={message} />;
  }

  if (projectId && message) {
    if (message.isDraft) {
      return (
        <DraftMessagePage
          message={message}
          projectId={projectId}
          mailFolderId={mailFolderId}
          isActiveMessage
        />
      );
    }
    return (
      <>
        {message.meetingMessageType && message.meetingMessageType !== 'None' ? (
          <EventMessageView
            projectId={projectId}
            message={message}
            mailFolderId={mailFolderId}
            backButton={backButton}
            prefix={prefix}
            className={classNames(classes.eventView, {
              [classes.eventCompressedView]:
                mailSettings.mailListSpacing === 'tight',
              [classes.noPadding]: noPadding,
            })}
          />
        ) : (
          <MessageView
            projectId={projectId}
            message={message}
            showTitle
            showActions
            backButton={backButton}
            className={classNames(classes.messageView, {
              [classes.noPadding]: noPadding,
            })}
            openInNewWindow={!window.location.href.match(viewPattern)}
            prefix={prefix}
          />
        )}
      </>
    );
  }

  return (
    <div className="prio-flex-center-center prio-flex-column">
      <PrioSpinner size="large" />
    </div>
  );
};

export default MessageDetailsPage;
