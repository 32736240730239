import React from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { CompanyId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { RootReducerState, getCompanies } from '../../../apps/main/rootReducer';
import { Company } from '../../../models/Company';
import BasisPicker, {
  BasisPickerSelectOptions,
} from '../../../components/BasisPicker';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-select-selection-item': {
      minHeight: 50,
    },
  },
  shortNameText: {
    fontSize: 14,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: theme.old.typography.colors.muted,
  },
}));

interface TimeRecordsFilterCompanyPickerProps {
  companyIds: CompanyId[];
  onChange?: (value: CompanyId | CompanyId[]) => void;
  disabled?: boolean;
  defaultValue?: CompanyId | CompanyId[];
  value?: CompanyId | CompanyId[];
}

export const TimeRecordsFilterCompanyPicker: React.FC<
  TimeRecordsFilterCompanyPickerProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyIds, onChange, disabled, defaultValue, value } = props;
  const companies = useSelector<RootReducerState, Company[]>((state) =>
    getCompanies(state, companyIds)
  ).sort((a: Company, b: Company) => {
    return a.fullName?.localeCompare(b.fullName);
  });

  var selectOptions: BasisPickerSelectOptions[] = [];
  if (companies) {
    selectOptions = companies.map((company, index) => {
      return {
        label:
          company && company.fullName ? (
            <>
              {company.fullName}
              {company.shortName && (
                <span className={classes.shortNameText}>
                  {company.shortName}
                </span>
              )}
            </>
          ) : (
            `${t('common:errorMessages.unknownObject')}`
          ),
        title:
          company && company.fullName
            ? `${company.fullName}` + company.shortName
              ? ' ' + company.shortName
              : ''
            : `${t('common:errorMessages.unknownObject')}`,
        value:
          company && company.companyId
            ? company.companyId
            : `${t('common:errorMessages.unknownObject')}`,
        key:
          company && company.companyId
            ? company.companyId
            : `${t('common:errorMessages.unknownObject')}`,
        isItalic: !company,
      };
    });
  }

  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks
  //#endregion

  return (
    <BasisPicker
      optionFilterProp="label"
      filterOption={(input, option) =>
        option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      defaultValue={defaultValue}
      value={value}
      items={selectOptions}
      multipleSelect
      onChange={onChange}
      disabled={disabled}
      className={classes.root}
      placeHolder={t('common:all')}
    />
  );
};

export default TimeRecordsFilterCompanyPicker;
