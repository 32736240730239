import { Reducer, combineReducers } from 'redux';
import {
  MonthlyClose,
  MonthlyCloseAbsence,
  MonthlyCloseRedux,
  TimeKeepingDay,
} from '../../../models/TimeKeeping';
import {
  DateTimeString,
  MonthlyCloseId,
  TimeKeepingDayId,
} from '../../../models/Types';
import { ReduxAction } from '../../../models/Redux';
import {
  CREATE_TIMEKEEPING_DAY,
  DELETE_TIMEKEEPING_DAY,
  FETCH_MONTHLY_CLOSE_ME_COMMIT,
  FETCH_MONTHLY_CLOSE_ME_REQUEST,
  FETCH_MONTHLY_CLOSE_ME_ROLLBACK,
  FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT,
  UPDATE_TIMEKEEPING_DAY,
} from '../actions';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import { distinct } from '../../../util';

export interface MonthlyCloseMeReducerState {
  byId: MonthlyCloseByIdState;
  ids: MonthlyCloseId[];
  byMonth: ByMonthState;
  meta: MetaState;
}

export const initialState: MonthlyCloseMeReducerState = {
  byId: {},
  ids: [],
  byMonth: {},
  meta: {
    isFetching: false,
    hasError: false,
  },
};

declare type MetaObject = {
  from: DateTimeString;
  to: DateTimeString;
  isInitialFetch: boolean;
  monthDateTime: DateTimeString;
  timeKeepingDayId: TimeKeepingDayId;
  monthlyCloseId: MonthlyCloseId;
};

declare type PayloadObject = MonthlyClose[] &
  MonthlyClose &
  TimeKeepingDay[] &
  TimeKeepingDay;

export interface MonthlyCloseByIdState {
  [monthlyCloseId: MonthlyCloseId]: MonthlyCloseRedux;
}

const byId: Reducer<
  MonthlyCloseByIdState,
  ReduxAction<MetaObject, PayloadObject>
> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        payload,
        meta: { isInitialFetch },
      } = action;

      return (payload as MonthlyClose[]).reduce(
        (map, monthlyClose) => ({
          ...map,
          [monthlyClose.monthlyCloseId]: {
            ...monthlyClose,
            timeKeepingDays: monthlyClose.timeKeepingDays.map(
              (timeKeepingDay) => timeKeepingDay.timeKeepingDayId
            ),
          },
        }),
        isInitialFetch ? {} : state
      );
    }
    case FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        meta: { monthlyCloseId },
        payload,
      } = action as ReduxAction<MetaObject, MonthlyClose>;
      return {
        ...state,
        [monthlyCloseId]: {
          ...payload,
          timeKeepingDays: payload.timeKeepingDays.map(
            (timeKeepingDay) => timeKeepingDay.timeKeepingDayId
          ),
        },
      };
    }
    case CREATE_TIMEKEEPING_DAY: {
      const {
        payload: { timeKeepingDayId, monthlyCloseId },
      } = action as ReduxAction<MetaObject, TimeKeepingDay>;
      if (monthlyCloseId) {
        const { [monthlyCloseId]: monthlyClose, ...rest } = state;
        return {
          ...rest,
          [monthlyCloseId]: {
            ...monthlyClose,
            timeKeepingDays: [
              ...(monthlyClose?.timeKeepingDays ?? []),
              timeKeepingDayId,
            ],
          },
        };
      }
      return state;
    }
    case UPDATE_TIMEKEEPING_DAY: {
      const {
        meta: { timeKeepingDayId },
        payload: { timeKeepingDayId: newTimeKeepingDayId, monthlyCloseId },
      } = action as ReduxAction<MetaObject, TimeKeepingDay>;
      const { [monthlyCloseId]: monthlyClose, ...rest } = state;
      if (monthlyClose) {
        const { timeKeepingDays, ...restMonthlyClose } = monthlyClose;
        return {
          ...rest,
          [monthlyCloseId]: {
            ...restMonthlyClose,
            timeKeepingDays: timeKeepingDays.map((id) =>
              id === timeKeepingDayId ? newTimeKeepingDayId : id
            ),
          },
        };
      }
      return state;
    }
    case DELETE_TIMEKEEPING_DAY: {
      const {
        meta: { monthlyCloseId, timeKeepingDayId },
      } = action;
      const { [monthlyCloseId]: monthlyClose, ...rest } = state;
      if (monthlyClose) {
        const { timeKeepingDays, ...restMonthlyClose } = monthlyClose;
        return {
          ...rest,
          [monthlyCloseId]: {
            ...restMonthlyClose,
            timeKeepingDays: timeKeepingDays.filter(
              (id) => id !== timeKeepingDayId
            ),
          },
        };
      }
      return state;
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<MonthlyCloseId[], ReduxAction<MetaObject, PayloadObject>> = (
  state = initialState.ids,
  action
) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        payload,
        meta: { isInitialFetch },
      } = action;
      const newState = (payload as MonthlyClose[]).map(
        ({ monthlyCloseId }) => monthlyCloseId
      );
      return isInitialFetch ? newState : [...state, ...newState];
    }
    case FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        meta: { monthlyCloseId },
      } = action as ReduxAction<MetaObject, MonthlyClose>;

      return distinct([...state, monthlyCloseId]);
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default: {
      return state;
    }
  }
};

export interface ByMonthState {
  [month: string]: MonthlyCloseId;
}

const byMonth: Reducer<ByMonthState, ReduxAction<MetaObject, PayloadObject>> = (
  state = initialState.byMonth,
  action
) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        payload,
        meta: { isInitialFetch },
      } = action;

      return (payload as MonthlyClose[]).reduce(
        (map, { month, monthlyCloseId }) => ({
          ...map,
          [month.substring(0, 7)]: monthlyCloseId,
        }),
        isInitialFetch ? {} : state
      );
    }
    case FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        meta: { monthlyCloseId },
        payload,
      } = action as ReduxAction<MetaObject, MonthlyClose>;

      const month = payload.month.substring(0, 7);

      return {
        ...state,
        [month]: monthlyCloseId,
      };
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.byMonth;
    }
    default: {
      return state;
    }
  }
};

interface MetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<MetaState, ReduxAction<MetaObject, PayloadObject>> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSE_ME_REQUEST: {
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    }
    case FETCH_MONTHLY_CLOSE_ME_COMMIT: {
      return {
        ...state,
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_MONTHLY_CLOSE_ME_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<MonthlyCloseMeReducerState>({
  byId,
  ids,
  byMonth,
  meta,
});

export const getMonthlyCloseMeTimeKeepingDayIds: (
  state: MonthlyCloseMeReducerState,
  monthDateTime: DateTimeString
) => TimeKeepingDayId[] = ({ byId, byMonth }, monthDateTime) =>
  byId[byMonth[monthDateTime]]?.timeKeepingDays ?? [];

export const getMonthlyCloseAbsences: (
  state: MonthlyCloseMeReducerState,
  monthDateTime: DateTimeString
) => MonthlyCloseAbsence[] = ({ byId, byMonth }, monthDateTime) =>
  byId[byMonth[monthDateTime]]?.monthlyCloseAbsences ?? [];

export const getMonthlyCloseMeByMonth: (
  state: MonthlyCloseMeReducerState,
  monthDateTime: DateTimeString
) => MonthlyCloseRedux = ({ byId, byMonth }, monthDateTime) =>
  byId[byMonth[monthDateTime]];

export const getMonthlyCloseMeIsFetching: (
  state: MonthlyCloseMeReducerState
) => boolean = ({ meta }) => meta.isFetching;
