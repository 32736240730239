import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import TimeRecordsModule from '../../timeRecords/components/TimeRecordsModule';
import InvoicesModule from '../../accounting/components/InvoicesModule';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
}));

interface ControllingOfficeModuleProps {
  className?: string;
}

export const ControllingOfficeModule: React.FC<ControllingOfficeModuleProps> = (
  props
) => {
  const { className } = props;
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <Routes>
        <Route path=":officeId/invoices" element={<InvoicesModule />} />
        <Route
          path=":officeId/timeRecords"
          element={
            <TimeRecordsModule
              contextType="office"
              officeRoles={['officeAdmin', 'officeController']}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default ControllingOfficeModule;
