import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { TimeRecordsFilter } from './TimeRecordsFilter';
import { Drawer, Modal, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  CompanyId,
  ContactId,
  EditTimeRecordContextType,
  OfficeId,
  OfficeRole,
  ProjectId,
  TimeRecordId,
} from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import {
  TimeRecord,
  TimeRecordsFilter as TimeRecordsFilterI,
} from '../../../models/TimeRecord';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useTimeRecordsFilter } from '../hooks';
import {
  RootReducerState,
  getCompaniesByIdState,
  getOffice,
  getTimeAndLeaveManagementDrawerState,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { Office } from '../../../models/Office';
import { useExportTimeRecordsToCsv } from '../export';
import {
  apiDeleteTimeRecords,
  apiFetchMeTimeRecords,
  apiFetchOfficeTimeRecords,
  apiFetchTimeRecords,
} from '../api';
import { TimeRecordsByIdState } from '../reducers/timeRecords';
import {
  closeTimeAndLeaveManagementDrawer,
  openTimeAndLeaveManagementDrawer,
} from '../../timeAndLeaveManagement/actions';
import { ApiResult } from '../../../api';
import { deleteMyTimeRecord } from '../actions';
import { fetchInternalOffices } from '../../companies/actions';
import { syncGlobalProjects } from '../../projects/actions';
import NavigationBar from '../../../components/NavigationBar';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import TimeRecordsTable from './TimeRecordsTable';
import EditTimeRecord from './EditTimeRecord/EditTimeRecord';
import DocumentTemplateFormWrapper from '../../documents/components/DocumentTemplateFrom/DocumentTemplateFormWrapper';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  overview: {
    height: '100%',
    overflow: 'hidden',
  },
  padding: {
    padding: theme.old.spacing.defaultPadding,
  },
  excelIcon: {
    marginLeft: theme.old.spacing.unit(1.5),
    marginRight: theme.old.spacing.unit(1.5),
  },
  filter: {
    width: '100%',
  },
  table: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    cursor: 'pointer',
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  newDocument: {
    height: '100%',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  panelHeadline: {
    '&.ant-typography': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  form: {
    height: 'calc(100% - 33px)',
  },
}));

export interface TimeRecordsModuleRef {
  onExportToCsv: () => void;
  setDeleteSelectionModalVisible: (value: boolean) => void;
}

interface TimeRecordsModuleProps {
  contextType: EditTimeRecordContextType;
  officeRoles?: OfficeRole[];
  isNavBarUnvisible?: boolean;
  timeRecordsMe?: TimeRecord[];
  setSelectedTimeRecordsMe?: (value: string[]) => void;
}

export const TimeRecordsModule = forwardRef<
  TimeRecordsModuleRef,
  TimeRecordsModuleProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    contextType,
    officeRoles,
    isNavBarUnvisible,
    timeRecordsMe,
    setSelectedTimeRecordsMe,
  } = props;

  const { officeId } = useParams();
  const dispatch = useDispatch();
  //#endregion
  //#region ------------------------------ States / Attributes / Selectors
  const companiesById = useSelector(getCompaniesByIdState);

  const [timeRecords, setTimeRecords] = useState<TimeRecord[]>([]);
  const {
    filteredTimeRecords,
    setCurrentAdvancedFilter,
    currentAdvancedFilter,
  } = useTimeRecordsFilter(timeRecordsMe ?? timeRecords);

  const [companyFilterSelected, setCompanyFilterSelected] = useState<
    CompanyId[]
  >([]);
  const [projectFilterSelected, setProjectFilterSelected] = useState<
    CompanyId[]
  >([]);
  const [contactFilterSelected, setContactFilterSelected] = useState<
    CompanyId[]
  >([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [companyId, setCompanyId] = useState<CompanyId>();

  const [deleteSelectionModalVisible, setDeleteSelectionModalVisible] =
    useState<boolean>(false);

  const office = useSelector<RootReducerState, Office>((state) =>
    officeId ? getOffice(state, officeId) : null
  );

  const [selectedTimerecords, setSelectedTimerecords] = useState<string[]>([]);
  const [selectedTimerecordObjects, setSelectedTimerecordObjects] = useState<
    TimeRecord[]
  >([]);
  const exportToCsv = useExportTimeRecordsToCsv();
  const [open, setOpen] = useState<boolean>(false);

  const [selectedTimeRecordId, setSelectedTimeRecordId] =
    useState<TimeRecordId | null>(null);

  var total_sum = filteredTimeRecords.reduce(function (prev, cur) {
    var duration = prev + cur.durationInMinutes / 60;
    var durationString = duration.toFixed(2);
    var rounded = Number(durationString);
    return rounded;
  }, 0);

  const selectedTimerecord = useMemo(
    () =>
      timeRecords.find(
        (record) => record.timeRecordId === selectedTimeRecordId
      ),
    [timeRecords, selectedTimeRecordId]
  );

  const [currentFilter, setCurrentFilter] = useState<TimeRecordsFilterI>({
    from: moment().startOf('week'),
    to: moment().endOf('week'),
  });

  const { createdObjectType } = useSelector(
    getTimeAndLeaveManagementDrawerState
  );

  const userMe = useSelector(getUserMe);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchRecords = useCallback(
    (filter: TimeRecordsFilterI) => {
      const controller = new AbortController();
      const signal = controller.signal;
      const loadTimeRecords = async () => {
        try {
          const from = filter?.from
            ? moment(filter.from)
                .startOf('date')
                .toISOString(true)
                .split('T')[0]
            : undefined;

          const to = filter?.to
            ? moment(filter.to).startOf('date').toISOString(true).split('T')[0]
            : undefined;

          if (contextType === 'me') {
            const { data } = await apiFetchMeTimeRecords(
              { ...filter, from, to },
              signal
            );
            if (data) {
              setTimeRecords(data);
            }
          } else {
            const { data } = officeId
              ? await apiFetchOfficeTimeRecords(
                  { ...filter, from, to },
                  signal,
                  officeId
                )
              : await apiFetchTimeRecords({ ...filter, from, to }, signal);
            if (data) {
              setTimeRecords(data);
            }
          }
          setIsFetching(false);
        } catch {
          setIsFetching(false);
        }
      };
      setIsFetching(true);
      loadTimeRecords();
      return () => {
        controller.abort();
      };
    },
    [officeId, contextType]
  );

  useEffect(() => {
    if (setSelectedTimeRecordsMe) {
      setSelectedTimeRecordsMe(selectedTimerecords);
    }
  }, [selectedTimerecords, setSelectedTimeRecordsMe]);

  const handleFilterChange = (filter: TimeRecordsFilterI) => {
    setCurrentFilter(filter);
    fetchRecords(filter);
  };

  const handleProjectFilterChange = (projectIds: ProjectId[]) => {
    setCurrentAdvancedFilter({
      ...currentAdvancedFilter,
      projectFilters: projectIds,
    });
    setProjectFilterSelected(projectIds);
  };

  const handleOfficeFilterChange = (officeIds: OfficeId[]) => {
    setCurrentAdvancedFilter({
      ...currentAdvancedFilter,
      companyFilters: officeIds,
    });
    setCompanyFilterSelected(officeIds);
  };

  const handleContactFilterChange = (contactIds: ContactId[]) => {
    setCurrentAdvancedFilter({
      ...currentAdvancedFilter,
      contactFilters: contactIds,
    });
    setContactFilterSelected(contactIds);
  };

  const handleResetAdvancedFilters = () => {
    setCurrentAdvancedFilter({
      companyFilters: [],
      contactFilters: [],
      projectFilters: [],
    });
    setCompanyFilterSelected([]);
    setProjectFilterSelected([]);
    setContactFilterSelected([]);
  };

  const onExportToCsv = () => {
    const timeRecordsById: TimeRecordsByIdState = timeRecords.reduce(function (
      map,
      item
    ) {
      map[item.timeRecordId] = item;
      return map;
    }, {});
    exportToCsv(selectedTimerecords, timeRecordsById);
  };

  const onCreateReport = () => {
    showDrawer();
  };
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onCloseReport = () => {
    setVisible(false);
  };

  const onCloseEditRecordDrawer = () => {
    setOpen(false);
  };

  const onCreateDocumentSuccess = () => {
    setVisible(false);
  };
  const onStartCreateDocument = () => {
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
    setSelectedTimeRecordId(null);
  };

  const handleClose = () => setOpen(false);

  const handleTimeRecordClick = (timeRecordId: TimeRecordId) => {
    setSelectedTimeRecordId(timeRecordId);
    setOpen(true);
  };

  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  const handleFinish = (value: TimeRecord) => {
    setTimeout(() => fetchRecords(currentFilter), 250);
    setOpen(false);
    setSelectedTimeRecordId(null);
  };

  const handleDelete = async (value: TimeRecord[]) => {
    await Promise.all(
      value.map((p) => apiDeleteTimeRecords(p.timeRecordId, p, contextType))
    )
      .then((values: ApiResult<undefined>[]) => {
        values.forEach(({ data, result }) => {
          if (data && data['contactId'] === userMe.id) {
            dispatch(
              deleteMyTimeRecord(
                data['timeRecordId'],
                data,
                data['contactId'] === userMe.id
              )
            );
          }
        });
      })
      .catch((error) => {
        notification.open({
          message: t('common:error'),
          description: t('timeRecords:errorMessages.deleteError'),
        });
        console.error(error.message);
      });
    fetchRecords(currentFilter);
    setOpen(false);
    setSelectedTimeRecordId(null);
  };

  const handleModalOk = () => {
    handleDelete(selectedTimerecordObjects);
    setDeleteSelectionModalVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    onExportToCsv,
    setDeleteSelectionModalVisible,
  }));

  useEffect(() => {
    if (!!officeId) {
      dispatch(fetchInternalOffices());
    }
  }, [officeId, dispatch]);

  useEffect(() => {
    if (office?.companyId) {
      setCompanyId(office.companyId);
    }
  }, [office]);

  useEffect(() => {
    dispatch(syncGlobalProjects());
  }, [dispatch]);

  useEffect(() => {
    var selectedObjects = timeRecords.filter(function (timerecord) {
      return selectedTimerecords.includes(timerecord.timeRecordId);
    });
    setSelectedTimerecordObjects(selectedObjects);
  }, [selectedTimerecords, timeRecords]);

  useEffect(() => {
    if (createdObjectType === 'timeRecord') {
      fetchRecords(currentFilter);
      dispatch(closeTimeAndLeaveManagementDrawer('none'));
    }
  }, [createdObjectType, currentFilter, dispatch, fetchRecords]);
  //#endregion

  return (
    <div className={classes.root}>
      {!isNavBarUnvisible && (
        <NavigationBar>
          <Button iconProp={['fal', 'pencil']} onClick={showTimeRecordDrawer}>
            <span>{t('common:navigationBar.recordTime')}</span>
          </Button>
          <Button
            type="default"
            disabled={!selectedTimerecords?.length}
            onClick={onExportToCsv}
            iconProp={['fal', 'file-csv']}
          >
            <span>{t('common:navigationBar.exportToCsv')}</span>
          </Button>
          {contextType !== 'me' && (
            <Button
              disabled={!timeRecords?.length}
              onClick={onCreateReport}
              iconProp={['fal', 'file-excel']}
              type="default"
            >
              <span>{t('timeRecords:navigationBar.createReport')}</span>
            </Button>
          )}
          <Button
            disabled={!selectedTimerecords?.length}
            onClick={() => setDeleteSelectionModalVisible(true)}
            iconProp={['fal', 'trash']}
            type="link"
          >
            <span>{t('common:actions.delete')}</span>
          </Button>
        </NavigationBar>
      )}
      <Flex.Row className={classes.content}>
        <Flex.Column
          className={classNames(classes.overview, {
            [classes.padding]: !isNavBarUnvisible,
          })}
        >
          <TimeRecordsFilter
            className={classes.filter}
            onChange={handleFilterChange}
            companyId={companyId}
            startPeriod={moment().startOf('D')}
            endPeriod={moment().endOf('D')}
            count={filteredTimeRecords.length}
            selected={selectedTimerecords.length}
            contextType={contextType}
            officeRoles={officeRoles}
            onlyOffice={contextType !== 'global'}
            timeRecordsToFilter={timeRecords}
            onProjectChange={handleProjectFilterChange}
            onOfficeChange={handleOfficeFilterChange}
            onContactChange={handleContactFilterChange}
            onResetAdvancedFilter={handleResetAdvancedFilters}
            currentCompanyFilterValue={companyFilterSelected}
            currentProjectFilterValue={projectFilterSelected}
            currentContactFilterValue={contactFilterSelected}
          />
          {timeRecords?.length > 0 && (
            <Flex.Row>
              <h3>Gesamt: {total_sum.toString().replace('.', ',')}h</h3>
            </Flex.Row>
          )}
          <TimeRecordsTable
            timeRecords={filteredTimeRecords}
            className={classes.table}
            onRowSelectionChange={setSelectedTimerecords}
            onTimeRecordClick={handleTimeRecordClick}
            loading={isFetching}
            showProject={true}
          />
        </Flex.Column>
      </Flex.Row>
      <Drawer
        title={t('timeRecords:navigationBar.editTimeRecord')}
        placement="right"
        closable={true}
        onClose={onCloseEditRecordDrawer}
        visible={open}
        width={theme.old.components.drawerWidth}
        destroyOnClose={true}
      >
        <EditTimeRecord
          timeRecordId={selectedTimeRecordId}
          timeRecord={selectedTimerecord}
          titleComponent={(timeRecord: TimeRecord) => (
            <Typography.Title level={3} className={classes.panelHeadline}>
              <FontAwesomeIcon icon={['fal', 'user-clock']} />{' '}
              {timeRecord.title}
            </Typography.Title>
          )}
          onFinish={handleFinish}
          onCancel={handleClose}
          contextType={contextType}
          officeRoles={officeRoles}
          onlyOffice={contextType !== 'global'}
          formClassName={classes.form}
          setOpen={setOpen}
        />
      </Drawer>
      <Drawer
        title={t('timeRecords:navigationBar.createReport')}
        placement="right"
        closable={true}
        onClose={onCloseReport}
        visible={visible}
        width={theme.old.components.drawerWidth}
        destroyOnClose={true}
      >
        <DocumentTemplateFormWrapper
          showOnlyReports={true}
          timeRecords={
            selectedTimerecords.length > 0
              ? selectedTimerecordObjects
              : filteredTimeRecords
          }
          onSuccess={onCreateDocumentSuccess}
          onStartCreateDocument={onStartCreateDocument}
          onCancel={onCancel}
          filter={currentFilter}
          projectPickerFilter={({ isArchived, companyId }) => {
            if (isArchived) {
              return false;
            }
            if (officeId) {
              const company = companiesById[companyId];
              if (company && company.companyType === 'InternalCompany') {
                const { offices } = company;
                const mainOfficeId = offices.find(
                  (office) => office.isMainOffice
                )?.officeId;
                if (!mainOfficeId) {
                  return false;
                }
                if (mainOfficeId !== officeId) {
                  return false;
                }
              }
            }
            return true;
          }}
        />
      </Drawer>
      <Modal
        visible={deleteSelectionModalVisible}
        onOk={() => handleModalOk()}
        onCancel={() => setDeleteSelectionModalVisible(false)}
        title={t('timeRecords:modalDelete.title')}
        okText={t('timeRecords:modalDelete.confirm')}
        cancelText={t('timeRecords:modalDelete.cancel')}
      >
        {t(
          `accounting:modalDelete.content.${
            selectedTimerecordObjects.length === 1 ? `single` : `multiple`
          }`,
          {
            amount: selectedTimerecordObjects.length,
          }
        )}
      </Modal>
    </div>
  );
});

export default TimeRecordsModule;
