import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectId } from '../../../models/Types';
import { Typography, Collapse, Divider, notification, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import { DocumentTag, DocumentTagSet } from '../../../models/Document';
import {
  apiAddGlobalDocumentTagSet,
  apiCreateProjectDocumentTagSet,
  apiFetchDocumentTagSets,
  apiFetchDocumentTags,
  apiRemoveGlobalDocumentTagSet,
  apiUpdateProjectDocumentTagSet,
} from '../../documents/api';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.old.spacing.defaultPadding,
  },
  labelPicker: {
    width: '250px',
  },
  radioLabelCol: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  preview: {
    padding: `${theme.old.spacing.unit(2.5)}px ${theme.old.spacing.unit(3)}px`,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    whiteSpace: 'normal',
  },
  tag: {
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(1)}px`,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.active.sub,
    marginRight: theme.old.spacing.unit(1),
    marginBottom: theme.old.spacing.unit(0.5),
    marginTop: theme.old.spacing.unit(0.5),
    display: 'inline-block',
  },
}));

interface ProjectDocumentSettingsManagementProps {
  className?: string;
  projectId: ProjectId;
}

export const ProjectDocumentSettingsManagement: React.FC<
  ProjectDocumentSettingsManagementProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const { className, projectId } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [allGlobalDocumentTagSets, setAllGlobalDocumentTagSets] = useState<
    DocumentTagSet[]
  >([]);
  const [selectedDocumentTagSetIds, setSelectedDocumentTagSetIds] = useState<
    string[]
  >([]);
  const [
    selectedGlobalDocumentTagIdsFromSets,
    setSelectedGlobalDocumentTagIdsFromSets,
  ] = useState<string[]>([]);
  const [allGlobalDocumentTags, setAllGlobalDocumentTags] = useState<
    DocumentTag[]
  >([]);
  const [projectSpecificDocumentTagIds, setProjectSpecificDocumentTagIds] =
    useState<string[]>([]);

  const [allProjectDocumentTags, setAllProjectDocumentTags] = useState<
    DocumentTag[]
  >([]);

  const globalUnusedDocumentTags = useMemo(() => {
    return allGlobalDocumentTags.filter(
      (tag: DocumentTag) =>
        !selectedGlobalDocumentTagIdsFromSets.includes(tag.documentTagId)
    );
  }, [allGlobalDocumentTags, selectedGlobalDocumentTagIdsFromSets]);

  const [projectDocumentTagSet, setProjectDocumentTagSet] =
    useState<DocumentTagSet>();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getAllGlobalDocumentTags = useCallback(
    async (signal) => {
      try {
        const { data } = await apiFetchDocumentTags(signal);

        if (data && Array.isArray(data)) {
          setAllGlobalDocumentTags(
            data.sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      } catch {}
    },
    [setAllGlobalDocumentTags]
  );

  const getAllGlobalDocumentTagSets = useCallback(async () => {
    try {
      const { data, result } = await apiFetchDocumentTagSets();
      if (data) {
        const _data = data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setAllGlobalDocumentTagSets(_data);
      }
      if (result.status >= 400) {
        notification.open({
          message: t('common:error'),
          description: t('settings:errorMessages.fetchDocumentTagSetsError'),
        });
      }
    } catch (error) {}
  }, [t, setAllGlobalDocumentTagSets]);

  const getDocumentTags = useCallback(
    async (projectId: ProjectId) => {
      try {
        const { data, result } = await apiFetchDocumentTagSets(
          false,
          projectId
        );
        if (data) {
          setProjectDocumentTagSet(
            data.find((set) => set.eTagSetScope === 'project')
          );

          const projectTagIds = data.flatMap((set) =>
            set.eTagSetScope === 'project'
              ? set.containedTags.map((tag) => tag.documentTagId)
              : []
          );
          setProjectSpecificDocumentTagIds(projectTagIds);

          const globalDocumentTagSetIds = data
            .filter((set) => set.eTagSetScope === 'global')
            .map((set) => set.documentTagSetId);
          setSelectedDocumentTagSetIds(globalDocumentTagSetIds);

          const globalDocumentTagIdsFromSets = data.flatMap((set) =>
            set.eTagSetScope === 'global'
              ? set.containedTags.map((tag) => tag.documentTagId)
              : []
          );
          setSelectedGlobalDocumentTagIdsFromSets(globalDocumentTagIdsFromSets);
        }
        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t('settings:errorMessages.fetchDocumentTagSetsError'),
          });
        }
      } catch (error) {}
    },
    [t]
  );

  const addGlobalDocumentTagSet = useCallback(
    async (projectId: ProjectId, tagSetId: string) => {
      try {
        const { result } = await apiAddGlobalDocumentTagSet(
          projectId,
          tagSetId
        );
        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t(
              'projects:errorMessages.addGlobalDocumentTagSetError'
            ),
          });
        }
      } catch (error) {}
    },
    [t]
  );

  const removeGlobalDocumentTagSet = useCallback(
    async (projectId: ProjectId, tagSetId: string) => {
      try {
        const { result } = await apiRemoveGlobalDocumentTagSet(
          projectId,
          tagSetId
        );
        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t(
              'projects:errorMessages.removeGlobalDocumentTagSetError'
            ),
          });
        }
      } catch (error) {}
    },
    [t]
  );

  const updateProjectSpecificDocumentTags = useCallback(
    async (
      projectId: ProjectId,
      documentTagSetId: string,
      updatedDocumentTagSet: {
        name: string;
        description: string;
        containedTags: string[];
      }
    ) => {
      try {
        const { result } = await apiUpdateProjectDocumentTagSet(
          projectId,
          documentTagSetId,
          updatedDocumentTagSet
        );
        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t(
              'project:errorMessages.updateProjectSpecificDocumentTagsError'
            ),
          });
        }
      } catch (error) {}
    },
    [t]
  );

  const createProjectSpecificDocumentTagSet = useCallback(
    async (
      projectId: ProjectId,
      documentTagSet: {
        name: string;
        description: string;
        containedTags: string[];
      }
    ) => {
      try {
        const { result } = await apiCreateProjectDocumentTagSet(
          projectId,
          documentTagSet
        );
        if (result.status >= 400) {
          notification.open({
            message: t('common:error'),
            description: t(
              'projects:errorMessages.createProjectSpecificDocumentTagSetError'
            ),
          });
        }
      } catch (error) {}
      getDocumentTags(projectId);
    },
    [t, getDocumentTags]
  );

  const handleChangeDocumentTagPicker = (value: string[]) => {
    const documentTagNames = value.map((id) => {
      const tag = allGlobalDocumentTags.find((tag) => tag.documentTagId === id);
      return tag ? tag.name : '';
    });
    const updatedDocumentTagSet = {
      name: projectDocumentTagSet?.name ?? 'projectSpecificDocumentTags',
      description: '',
      containedTags: documentTagNames,
    };
    projectDocumentTagSet
      ? updateProjectSpecificDocumentTags(
          projectId,
          projectDocumentTagSet?.documentTagSetId,
          updatedDocumentTagSet
        )
      : createProjectSpecificDocumentTagSet(projectId, updatedDocumentTagSet);
    setProjectSpecificDocumentTagIds(value);
  };

  const handleChangeDocumentTagSetPicker = (value: string[]) => {
    let prevTagSets = selectedDocumentTagSetIds;
    if (value.length > prevTagSets.length) {
      const newTagSetId = value.filter((id) => !prevTagSets.includes(id))[0];
      addGlobalDocumentTagSet(projectId, newTagSetId);
    }
    if (value.length < prevTagSets.length) {
      const removedTagSetId = prevTagSets.filter(
        (id) => !value.includes(id)
      )[0];
      removeGlobalDocumentTagSet(projectId, removedTagSetId);
    }
    setSelectedDocumentTagSetIds(value);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    getAllGlobalDocumentTagSets();
  }, [getAllGlobalDocumentTagSets]);

  useEffect(() => {
    getDocumentTags(projectId);
  }, [getDocumentTags, projectId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAllGlobalDocumentTags(signal);
    return () => {
      controller.abort();
    };
  }, [getAllGlobalDocumentTags]);

  useEffect(() => {
    const removeDuplicatesByName = (arr: DocumentTag[]) => {
      const uniqueNames = {};
      return arr.filter((item) => {
        if (!uniqueNames[item.name]) {
          uniqueNames[item.name] = true;
          return true;
        }
        return false;
      });
    };

    const _tagsFromSets: DocumentTag[] = allGlobalDocumentTagSets
      .filter((set) => selectedDocumentTagSetIds.includes(set.documentTagSetId))
      .reduce(
        (acc, set) => [...acc, ...set.containedTags],
        [] as DocumentTag[]
      );

    const _projectSpecificTags: DocumentTag[] = allGlobalDocumentTags.filter(
      (tag) => projectSpecificDocumentTagIds.includes(tag.documentTagId)
    );

    const _allProjectDocumentTags: DocumentTag[] = [
      ..._projectSpecificTags,
      ..._tagsFromSets,
    ].sort((a, b) => a.name.localeCompare(b.name));

    setAllProjectDocumentTags(removeDuplicatesByName(_allProjectDocumentTags));
  }, [
    projectSpecificDocumentTagIds,
    selectedDocumentTagSetIds,
    allGlobalDocumentTagSets,
    allGlobalDocumentTags,
  ]);

  useEffect(() => {
    setSelectedGlobalDocumentTagIdsFromSets([]);
    selectedDocumentTagSetIds.forEach((id) => {
      const tagSet = allGlobalDocumentTagSets.find(
        (set) => set.documentTagSetId === id
      );
      if (tagSet) {
        setSelectedGlobalDocumentTagIdsFromSets((prev) => [
          ...prev,
          ...(tagSet.containedTags as DocumentTag[]).map(
            (tag) => tag.documentTagId
          ),
        ]);
      }
    });
  }, [selectedDocumentTagSetIds, allGlobalDocumentTagSets]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Typography.Title>
        {t(`projects:projectDocumentSettingsManagement.labels.documentTags`)}
      </Typography.Title>

      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <Flex.Row>
          <div className={classes.labelPicker}>
            {t(
              `projects:projectDocumentSettingsManagement.globalDocumentTagSets`
            )}
          </div>
          <Flex.Item flex={1}>
            <Select
              className={classes.fullWidth}
              mode="multiple"
              value={selectedDocumentTagSetIds}
              onChange={handleChangeDocumentTagSetPicker}
              options={allGlobalDocumentTagSets.map((set: DocumentTagSet) => {
                return {
                  label: set.name,
                  value: set.documentTagSetId,
                };
              })}
            ></Select>
          </Flex.Item>
        </Flex.Row>
        <Flex.Row>
          <div className={classes.labelPicker}>
            {t(
              `projects:projectDocumentSettingsManagement.additionalProjectDocumentTags`
            )}
          </div>
          <Flex.Item flex={1}>
            <Select
              className={classes.fullWidth}
              mode="multiple"
              value={projectSpecificDocumentTagIds}
              onChange={handleChangeDocumentTagPicker}
              options={globalUnusedDocumentTags.map((tag: DocumentTag) => {
                return {
                  label: tag.name,
                  value: tag.documentTagId,
                };
              })}
            ></Select>
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>

      <Collapse ghost className={classes.collapse}>
        <Collapse.Panel
          header={
            <Flex.Row flex={1}>
              <div className={classes.radioLabelCol}>
                {t('projects:projectDocumentSettingsManagement.preview')}
              </div>

              <Divider className={classes.divider} />
            </Flex.Row>
          }
          key="1"
        >
          <div className={classes.preview}>
            {allProjectDocumentTags.length > 0
              ? allProjectDocumentTags.map((tag) => (
                  <span className={classes.tag}>{tag.name}</span>
                ))
              : t('projects:projectDocumentSettingsManagement.noTagsSelected')}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ProjectDocumentSettingsManagement;
