import { Moment } from 'moment';
import {
  TaskId,
  DateTimeString,
  BucketId,
  ProjectId,
  ContactId,
  MessageId,
} from './Types';

export interface Task {
  taskId?: TaskId;
  projectId?: ProjectId;
  title: string;
  description?: string;
  internalProjectContactId?: ContactId;
  bucketId?: BucketId;
  dueDateTime?: DateTimeString; //relative?
  startDateTime?: DateTimeString; //relative?
  details?: {
    references?: {
      [referenceKey: string]: {
        alias: string;
        type: string;
        previewPriority: string;
        lastModifiedBy: {
          user: {
            id: string;
          };
        };
        lastModifiedDateTime: string;
      };
    };
  };
  planId?: string;
}

export interface TaskFormModel {
  projectId?: ProjectId;
  title: string;
  description?: string;
  internalProjectContactId?: ContactId;
  dueDateTime?: Moment; //relative?
  startDateTime?: Moment; //relative?
  bucketId?: string;
}
export interface CreateTaskRequest {
  projectId?: ProjectId;
  title: string;
  description?: string;
  internalProjectContactId?: ContactId;
  bucketId?: BucketId;
  dueDateTime?: DateTimeString; //relative?
  startDateTime?: DateTimeString; //relative?
  sourceEmailUrl?: string;
  messageId?: MessageId;
}

export const emptyCreateTaskRequest: CreateTaskRequest = {
  title: '',
};
