import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { EmployeeId, Gender } from '../../../../models/Types';
import moment, { Moment } from 'moment';
import { Employee } from '../../../../models/Employee';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colon, rowGutter } from '../../../../util/forms';
import CountryPicker from '../../../../components/CountryPicker';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  coreDataBoxLeft: {
    borderRight: theme.old.borders.content,
    paddingRight: theme.old.spacing.unit(3),
  },
  fullWidth: {
    width: '100%',
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
  paddingTopOnLastRow: {
    paddingTop: theme?.spacing?.baseSpacing * 2,
  },
}));

interface CoreDataFormModel {
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  federalStateCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: Moment;
  allowShareBirthday?: boolean;
  birthplace?: string;
}

const coreDataFromEmployee: (employee: Employee) => CoreDataFormModel = (
  employee
) => ({
  phone: employee?.phone,
  eMail: employee?.eMail,
  street: employee?.street,
  zip: employee?.zip,
  city: employee?.city,
  countryCode: employee?.countryCode,
  federalStateCode: employee?.federalStateCode,
  gender: employee?.gender,
  nationality: employee?.nationality,
  birthday: employee?.birthday ? moment(employee?.birthday) : null,
  allowShareBirthday: employee?.allowShareBirthday,
  birthplace: employee?.birthplace,
});

interface PersonnelFileCoreDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const PersonnelFileCoreDataForm: React.FC<
  PersonnelFileCoreDataFormProps
> = (props) => {
  const { employeeId, employee, isSaving, patchEmployee } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [coreDataForm] = Form.useForm<CoreDataFormModel>();
  const [editModeCoreData, setEditModeCoreData] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const editCoreData = () => {
    setEditModeCoreData(true);
  };
  const cancelEditCoreData = () => {
    coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    setEditModeCoreData(false);
  };
  const submitCoreDataForm = () => {
    coreDataForm.submit();
  };

  const handleFinishCoreData = async (coreData: CoreDataFormModel) => {
    const { eMail, phone, ...workaround } = coreData;
    const patchData: Employee = {
      ...workaround,
      eMail,
      phone,
      birthday: coreData.birthday?.toISOString(true).split('T')[0],
    };
    await patchEmployee(patchData).then(() => setEditModeCoreData(false));
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setEditModeCoreData(false);
  }, [employeeId]);

  useEffect(() => {
    if (employee) {
      coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    }
  }, [employee, coreDataForm]);

  useEffect(() => {
    setDisabled(!editModeCoreData || isSaving);
  }, [editModeCoreData, isSaving]);

  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Form<CoreDataFormModel>
        form={coreDataForm}
        onFinish={handleFinishCoreData}
        layout="vertical"
        className={classes.form}
      >
        <Flex.Row flex={1}>
          <Flex.Column flex={1} className={classes.coreDataBoxLeft}>
            <Typography.Text type="secondary" className={classes.heading}>
              {t('hr:personnelFile.form.labels.address')}
            </Typography.Text>
            <Row gutter={rowGutter}>
              <Col span={24}>
                <Form.Item
                  name="street"
                  label={t('hr:personnelFile.form.labels.street')}
                  colon={colon}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col span={8} style={{ paddingRight: theme.old.spacing.unit(1) }}>
                <Form.Item
                  name="zip"
                  label={t('hr:personnelFile.form.labels.zip')}
                  colon={colon}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="city"
                  label={t('hr:personnelFile.form.labels.city')}
                  colon={colon}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col
                span={24}
                style={{ marginBottom: theme.old.spacing.unit(4) }}
              >
                <Form.Item
                  name="countryCode"
                  label={t('hr:personnelFile.form.labels.countryCode')}
                  colon={colon}
                >
                  <CountryPicker disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Typography.Text type="secondary" className={classes.heading}>
              {t('hr:personnelFile.form.labels.contactInformation')}
            </Typography.Text>
            <Row gutter={rowGutter}>
              <Col span={24}>
                <Form.Item
                  name="eMail"
                  label={t('hr:personnelFile.form.labels.eMail')}
                  colon={colon}
                >
                  <Input disabled={!editModeCoreData || isSaving} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col span={24}>
                <Form.Item
                  name="phone"
                  label={t('hr:personnelFile.form.labels.phone')}
                  colon={colon}
                >
                  <Input disabled={!editModeCoreData || isSaving} />
                </Form.Item>
              </Col>
            </Row>
          </Flex.Column>
          <Flex.Column
            flex={1}
            padding={`0 0 0 ${theme.old.spacing.unit(3)}px`}
          >
            <Typography.Text type="secondary" className={classes.heading}>
              {t('hr:personnelFile.form.labels.other')}
            </Typography.Text>
            <Row gutter={rowGutter}>
              <Col span={8} style={{ paddingRight: theme.old.spacing.unit(1) }}>
                <Form.Item
                  name="gender"
                  label={t('hr:personnelFile.form.labels.gender')}
                  colon={colon}
                >
                  <Select
                    disabled={disabled}
                    placeholder={t('contacts:form.labels.gender')}
                    className={disabled ? classes.disabledPicker : ''}
                  >
                    <Select.Option value="male">
                      {t('contacts:form.gender.male')}
                    </Select.Option>
                    <Select.Option value="female">
                      {t('contacts:form.gender.female')}
                    </Select.Option>
                    <Select.Option value="diverse">
                      {t('contacts:form.gender.diverse')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="nationality"
                  label={t('hr:personnelFile.form.labels.nationality')}
                  colon={colon}
                >
                  <CountryPicker disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutter}>
              <Col span={8} style={{ paddingRight: theme.old.spacing.unit(1) }}>
                <Form.Item
                  name="birthday"
                  label={t('hr:personnelFile.form.labels.birthday')}
                  colon={colon}
                  className={classes.fullWidth}
                >
                  <CustomSingleDatePicker
                    id="edit_personnelfile_id"
                    anchorDirection={'ANCHOR_RIGHT'}
                    small={true}
                    regular={false}
                    twoMonths={false}
                    disabled={disabled}
                    withFullScreenPortal={false}
                    daySize={30}
                    hideKeyboardShortcutsPanel={true}
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="birthplace"
                  label={t('hr:personnelFile.form.labels.birthplace')}
                  colon={colon}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="allowShareBirthday"
                  label={t('hr:personnelFile.form.labels.allowShareBirthday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
          </Flex.Column>
        </Flex.Row>
        <Divider style={{ marginBottom: '0px' }} />
      </Form>
      <Flex.Column className={classes?.paddingTopOnLastRow}>
        <Flex.Row alignSelf="flex-end" childrenGap={theme.old.spacing.unit(1)}>
          {editModeCoreData && (
            <Button
              type="link"
              onClick={cancelEditCoreData}
              disabled={isSaving}
              style={{ marginRight: theme.old.spacing.unit(1) }}
            >
              {t('common:actions.cancel')}
            </Button>
          )}
          <Button
            type={editModeCoreData ? 'primary' : 'link'}
            onClick={editModeCoreData ? submitCoreDataForm : editCoreData}
            disabled={isSaving}
          >
            {editModeCoreData ? t('common:save') : t('common:edit')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default PersonnelFileCoreDataForm;
