import React from 'react';
import { Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Flex from '../../../components/Flex';
import CustomTag from '../../../components/CustomTag';
import { DateTimeString } from '../../../models/Types';
import moment, { Moment } from 'moment';
import CompanyText from '../../companies/components/CompanyText';
import {
  DepartmentsById,
  QualificationsById,
} from '../../hr/components/HRTrainings';
import ContactText from '../../contacts/components/ContactText';
import { Contact } from '../../../models/Contact';
import { Company } from '../../../models/Company';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  tag: {
    borderRadius: 12,
  },
  tagGroup: {
    paddingRight: theme.old.spacing.defaultPadding,
  },
  tagTitle: {
    color: '#666666',
    marginBottom: theme.old.spacing.baseSpacing,
  },
  table: {
    paddingTop: theme.old.spacing.defaultPadding,
    paddingBottom: theme.old.spacing.unit(2),
  },
  tableElement: {
    paddingBottom: theme.old.spacing.baseSpacing,
  },
  tableTitle: {
    color: '#666666',
  },
}));

interface TrainingDetailsModuleProps {
  title?: string;
  description?: string;
  trainingManagers?: Array<Contact>;
  applicationDeadline?: DateTimeString;
  trainingOrganizer?: Company;
  eventRoom?: string;
  trainingTargetQualificationIds?: Array<string>;
  trainingTargetDepartmentIds?: Array<string>;
  maxParticipants?: number;
  qualificationsById: QualificationsById;
  departmentsById: DepartmentsById;
}

export const TrainingDetailsModule: React.FC<TrainingDetailsModuleProps> = (
  props
) => {
  const {
    title,
    description,
    trainingTargetQualificationIds,
    trainingTargetDepartmentIds,
    maxParticipants,
    trainingManagers,
    applicationDeadline,
    trainingOrganizer,
    eventRoom,
    qualificationsById,
    departmentsById,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography.Title>{title}</Typography.Title>
      <Typography.Text>{description}</Typography.Text>
      <div className={classes.table}>
        {trainingManagers &&
          details(
            'trainingManager',
            getManagerIds(trainingManagers),
            t,
            classes,
            true
          )}
        {applicationDeadline &&
          details(
            'applicationDeadline',
            [parseApplicationDeadline(moment(applicationDeadline))],
            t,
            classes
          )}
        <Flex.Row className={classes.tableElement}>
          <Flex.Item flex={1}>
            <Typography.Text className={classes.tableTitle}>
              {t(`profile:training.trainingOrganizer`)}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item flex={3}>
            <CompanyText companyId={trainingOrganizer?.companyId} />
          </Flex.Item>
        </Flex.Row>
        {eventRoom && details('eventRoom', [eventRoom], t, classes)}
        {maxParticipants &&
          details('maxParticipants', [String(maxParticipants)], t, classes)}
      </div>
      <Flex.Row>
        <Flex.Item>
          {trainingTargetQualificationIds !== null ? (
            <div className={classes.tagGroup}>
              <div className={classes.tagTitle}>
                <Typography.Text className={classes.tagTitle}>
                  {t(`profile:training.trainingTargetQualificationIds`)}
                </Typography.Text>
              </div>
              <Flex.Row>
                {trainingTargetQualificationIds?.map(
                  (trainingTargetQualificationId) => (
                    <CustomTag
                      type="text"
                      key={trainingTargetQualificationId}
                      text={
                        qualificationsById[trainingTargetQualificationId]?.name
                      }
                    />
                  )
                )}
              </Flex.Row>
            </div>
          ) : null}
        </Flex.Item>
        <Flex.Item>
          {trainingTargetDepartmentIds != null ? (
            <div className={classes.tagGroup}>
              <div className={classes.tagTitle}>
                <Typography.Text className={classes.tagTitle}>
                  {t(`profile:training.trainingTargetDepartmentIds`)}
                </Typography.Text>
              </div>
              <Flex.Row>
                {trainingTargetDepartmentIds?.map(
                  (trainingTargetDepartmentId) => (
                    <CustomTag
                      type="text"
                      key={trainingTargetDepartmentId}
                      text={departmentsById[trainingTargetDepartmentId]?.name}
                    />
                  )
                )}
              </Flex.Row>
            </div>
          ) : null}
        </Flex.Item>
      </Flex.Row>
    </div>
  );
};

const details = (
  itemKey: string,
  itemEntry: Array<string>,
  t: TFunction,
  classes: Classes,
  isContact?: boolean
) => {
  return (
    <Flex.Row className={classes.tableElement}>
      <Flex.Item flex={1}>
        <Typography.Text className={classes.tableTitle}>
          {t(`profile:training.${itemKey}`)}
        </Typography.Text>
      </Flex.Item>
      <Flex.Item flex={3}>
        {itemEntry.map((value, index) => (
          <React.Fragment key={value}>
            {isContact ? (
              <ContactText contactId={value} />
            ) : (
              <span>{value}</span>
            )}
            {index !== itemEntry.length - 1 ? <span>, </span> : null}
          </React.Fragment>
        ))}
      </Flex.Item>
    </Flex.Row>
  );
};

function parseApplicationDeadline(applicationDeadline: Moment) {
  let today: Moment;
  let deadline: string;

  today = moment();
  deadline = moment(applicationDeadline).format('DD. MMMM YYYY');

  return deadline + ' (' + applicationDeadline.from(today) + ')';
}

function getManagerIds(managers: Array<Contact>) {
  var managerIds = [];

  managers.forEach((manager) => {
    managerIds.push(manager.contactId);
  });

  return managerIds;
}

export default TrainingDetailsModule;
