import { useSelector } from 'react-redux';
import {
  getContactsByIdState,
  getProjectByIdState,
  getTimeRecordsByIdState,
} from '../../../apps/main/rootReducer';
import { TimeRecordId } from '../../../models/Types';
import { TimeRecordsByIdState } from '../reducers/timeRecords';
import { exportToCsv } from '../../../util/export';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { ContactsByIdState } from '../../contacts/reducers/contacts';

export const exportTimeRecordsToCsv: (
  timeRecordIds: TimeRecordId[],
  timeRecordsById: TimeRecordsByIdState,
  projectsById: ProjectByIdState,
  contactsById: ContactsByIdState
) => void = (timeRecordIds, timeRecordsById, projectsById, contactsById) => {
  const dataRows: string[][] = timeRecordIds.map((timeRecordId) => {
    const timeRecord = timeRecordsById[timeRecordId];
    if (timeRecord) {
      const project = timeRecord.projectId
        ? projectsById[timeRecord.projectId]
        : null;
      const contact = timeRecord.contactId
        ? contactsById[timeRecord.contactId]
        : null;
      return [
        project?.name,
        timeRecord.day,
        timeRecord.durationInMinutes.toFixed(0),
        timeRecord.title,
        timeRecord.description,
        timeRecord.kilometerDistance.toFixed(2),
        timeRecord.invoiceNumber,
        contact?.firstName,
        contact?.lastName,
      ];
    }
    return [];
  });

  const headers = [
    'projectName',
    'day',
    'durationInMinutes',
    'title',
    'description',
    'kilometerDistance',
    'invoiceNumber',
    'firstName',
    'lastName',
  ];
  exportToCsv(headers, dataRows, 'timeRecords.csv');
};

export function useExportTimeRecordsToCsv(): (
  timeRecordIds: TimeRecordId[],
  timeRecordsById?: TimeRecordsByIdState
) => void {
  const timeRecordsByIdFromRedux = useSelector(getTimeRecordsByIdState);
  const projectsById = useSelector(getProjectByIdState);
  const contactsById = useSelector(getContactsByIdState);

  return (
    timeRecordIds: TimeRecordId[],
    timeRecordsById?: TimeRecordsByIdState
  ) =>
    exportTimeRecordsToCsv(
      timeRecordIds,
      timeRecordsById ?? timeRecordsByIdFromRedux,
      projectsById,
      contactsById
    );
}
