import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  AccountingSearch,
  CalculateOverview,
  CreateInvoice,
  FullAccountingOverview,
  Invoice,
  InvoicePayment,
} from '../../../models/Accounting';
import { InvoiceId, OfficeId, ProjectId } from '../../../models/Types';
import fetchWithRetry from '../../../util/fetchWithRetry';
import { TimespanFilter } from '../../common/TimespanPeriodFilter';

export const apiFetchInvoices: (
  timespanFilter: TimespanFilter,
  officeId?: OfficeId,
  projectId?: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<Invoice[]>> = async (
  timespanFilter,
  officeId,
  projectId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/filterInvoices`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        from: timespanFilter?.from?.toISOString(true).split('T')[0],
        to: timespanFilter?.to?.toISOString(true).split('T')[0],
      }),
      signal,
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

interface QueryParameterKeyValuePair {
  key: string;
  value: any;
}

const appyParams = (...params: QueryParameterKeyValuePair[]) => {
  var queryString = '';

  params.forEach((p, index) => {
    if (index === 0 && params.length !== 0) {
      queryString = '?';
    }

    if (p.value && p.value !== '' && p.value.length !== 0) {
      queryString = queryString.concat(`${p.key}=${p.value}`);
      if (index !== params.length - 1) {
        queryString = queryString.concat('&');
      }
    }
  });
  return queryString;
};

export const apiFetchInvoicesSearch: (
  search: AccountingSearch,
  officeId?: OfficeId,
  projectId?: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<Invoice[]>> = async (
  search,
  officeId,
  projectId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/search` +
      appyParams(
        {
          key: 'invoiceIds',
          value: search.invoiceIds,
        },
        {
          key: 'numbers',
          value: search.numbers,
        },
        {
          key: 'invoiceDate',
          value: search.invoiceDate,
        },
        {
          key: 'invoiceStatus',
          value: search.invoiceStatus,
        },
        {
          key: 'invoiceTypes',
          value: search.invoiceTypes,
        },
        {
          key: 'projectIds',
          value: search.projectIds,
        },
        {
          key: 'titles',
          value: search.titles,
        }
      ),
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
      signal,
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchInvoicesOverview: (
  calculateOverview: CalculateOverview,
  officeId?: OfficeId,
  projectId?: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<FullAccountingOverview>> = async (
  calculateOverview,
  officeId,
  projectId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/calculateOverview`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(calculateOverview),
      signal,
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateInvoice: (
  invoiceUpdate: CreateInvoice,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<Invoice>> = async (invoice, officeId, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(invoice),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateInvoice: (
  invoiceId: InvoiceId,
  invoice: CreateInvoice,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<Invoice>> = async (
  invoiceId,
  invoice,
  officeId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/${invoiceId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(invoice),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiArchiveInvoice: (
  invoiceId: InvoiceId,
  invoice: CreateInvoice,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<Invoice>> = async (
  invoiceId,
  invoice,
  officeId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/${invoiceId}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(invoice),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUnarchiveInvoice: (
  invoiceId: InvoiceId,
  invoice: CreateInvoice,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<Invoice>> = async (
  invoiceId,
  invoice,
  officeId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/${invoiceId}/unarchive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(invoice),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateInvoicePayment: (
  payment: InvoicePayment,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<InvoicePayment>> = async (
  payment,
  officeId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }InvoicePayment/${payment.invoicePaymentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(payment),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateInvoicePayment: (
  payment: InvoicePayment,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<InvoicePayment>> = async (
  payment,
  officeId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }InvoicePayment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payment),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteInvoice: (
  invoiceId: InvoiceId,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<Invoice>> = async (invoiceId, officeId, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }Invoice/${invoiceId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiDeleteInvoicePayment: (
  invoicePaymentId: InvoiceId,
  officeId?: OfficeId,
  projectId?: ProjectId
) => Promise<ApiResult<Invoice>> = async (
  invoicePaymentId,
  officeId,
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/accounting/${
      officeId
        ? `office/${officeId}/`
        : projectId
        ? `project/${projectId}/`
        : ''
    }InvoicePayment/${invoicePaymentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};
