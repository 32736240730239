import React, { useEffect, useState } from 'react';
import Flex from '../../../components/Flex';
import { Center } from '../../../components/Center';
import {
  AbsenceOverview,
  AbsenceProposal,
} from '../../../models/AbsenceProposal';
import { makePrioStyles } from '../../../theme/utils';
import PrioSpinner from '../../../components/PrioSpinner';
import { OfficeId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { getOfficesByIdState } from '../../../apps/main/rootReducer';
import AbsenceOverviewChart from '../../absences/components/overview/AbsenceOverviewChart';
import AbsenceProposalTable from '../../absences/components/AbsenceProposalTable';
import AbsenceProposalDetailsDrawer from '../../absences/components/AbsenceProposalDetailsDrawer';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  overviewSection: {
    paddingBottom: theme.old.spacing.unit(1.5),
  },
  tinyText: {
    fontSize: theme.old.typography.fontSize.tiny,
    color: theme.old.typography.colors.muted,
    marginTop: theme.old.spacing.unit(1.0),
  },
  loadingOverlayAbsenceOverview: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffffaa',
  },
  remainingDaysPreviousYearIcon: {
    marginRight: theme.old.spacing.unit(1),
  },
  alert: {
    marginTop: theme.old.spacing.unit(3),
  },
  absenceListView: {
    height: '50vh',
    overflowY: 'auto',
    borderTop: '1px solid rgb(240, 240, 240)',
  },
  label: {
    fontSize: theme.old.typography.fontSize.label,
    marginTop: theme.old.spacing.unit(0.5),
  },
  vacationDay: {
    backgroundColor: theme.old.palette.primaryColor,
    lineHeight: '50px!important',
  },
  remainingVacationDays: {
    backgroundColor: theme.old.palette.primaryColor,
    lineHeight: '50px!important',
  },
  takenVacationDays: {
    backgroundColor: theme.old.typography.colors.muted,
    lineHeight: '50px!important',
  },
  requestedVacationDays: {
    backgroundColor: theme.old.palette.chromaticPalette.yellow,
    lineHeight: '50px!important',
  },
  acceptedVacationDays: {
    backgroundColor: theme.old.palette.chromaticPalette.green,
    lineHeight: '50px!important',
  },
  centerElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface EmployeeAbsencesOverviewProps {
  absenceOverview: AbsenceOverview;
  absenceOverviewIsFetching;
  officeId?: OfficeId;
}

const EmployeeAbsencesOverview: React.FC<EmployeeAbsencesOverviewProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { absenceOverview, absenceOverviewIsFetching, officeId } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [selectedAbsenceProposal, setSelectedAbsenceProposal] =
    useState<AbsenceProposal | null>(null);
  const [absenceProposals, setAbsenceProposals] = useState<AbsenceProposal[]>(
    absenceOverview?.absenceProposals ?? []
  );

  const officesById = useSelector(getOfficesByIdState);

  const updateAbsenceTable = (absenceProposal: AbsenceProposal) => {
    const copyAbsenceProposals = [...absenceProposals];
    const index = absenceOverview?.absenceProposals.findIndex(
      (proposal) =>
        proposal.absenceProposalId === absenceProposal.absenceProposalId
    );
    if (index > -1) {
      copyAbsenceProposals.splice(index, 1, absenceProposal);
      setAbsenceProposals(copyAbsenceProposals);
    }
  };

  const openDrawer = (value: AbsenceProposal) => {
    setOpen(true);
    setSelectedAbsenceProposal(value);
  };

  useEffect(() => {
    if (absenceOverview) {
      setAbsenceProposals(absenceOverview?.absenceProposals);
    }
  }, [absenceOverview]);

  return (
    <>
      <div className={classes.root}>
        <Flex.Column className={classes.content}>
          {absenceProposals.length === 0 && (
            <div className={classes.centerElement}>
              <Typography.Text type="secondary">
                {t('hr:employeeAbsenceOverview.noData')}
              </Typography.Text>
            </div>
          )}
          <AbsenceOverviewChart
            className={classes.overviewSection}
            absenceOverview={absenceOverview}
          />
          <AbsenceProposalTable
            className={classes.absenceListView}
            absenceProposals={absenceProposals
              ?.filter(function (obj) {
                return obj.absenceState !== 'revokeAccepted';
              })
              .sort((a, b) => Date.parse(b.from) - Date.parse(a.from))}
            tableId="prio-employee"
            onRowClick={openDrawer}
          />
          <AbsenceProposalDetailsDrawer
            open={open}
            setOpen={setOpen}
            absenceProposal={selectedAbsenceProposal}
            officesById={officesById}
            setSelectedAbsenceProposal={setSelectedAbsenceProposal}
            officeId={officeId}
            updateAbsenceTable={updateAbsenceTable}
          ></AbsenceProposalDetailsDrawer>
        </Flex.Column>

        {absenceOverviewIsFetching && (
          <div className={classes.loadingOverlayAbsenceOverview}>
            <Center>
              <PrioSpinner size="large" />
            </Center>
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeeAbsencesOverview;
