import { Form, Input, Select, Switch, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import {
  PlannerId,
  ProjectExtensionId,
  ProjectId,
} from '../../../../../../models/Types';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { colon } from '../../../../../../util/forms';
import { apiFetchPlanners } from '../../../../api';
import { Planner } from '../../../../../../models/Planner';
import { useSelector } from 'react-redux';
import { getProjectByIdState } from '../../../../../../apps/main/rootReducer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    position: 'relative',
  },
}));

export interface PlannerProjectExtensionFormModel {
  key: string;
  value: string;
  displayName: string;
  hasDefaultAccess: boolean;
}

interface PlannerProjectExtensionFormProps {
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export const PlannerProjectExtensionForm: React.FC<
  PlannerProjectExtensionFormProps
> = (props) => {
  const {
    addProjectExtension,
    projectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;
  const classes = useStyles();
  const [form] = Form.useForm();

  const projectsById = useSelector(getProjectByIdState);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedPlannerId, setSelectedPlannerId] = useState<PlannerId>(
    projectExtension?.key ?? null
  );

  const [planners, setPlanners] = useState<Planner[]>([]);
  const { t } = useTranslation();

  const saveOrUpdateExtension = async (
    PlannerProjectExtensionFormModel: PlannerProjectExtensionFormModel
  ) => {
    if (projectExtension && !addProjectExtension) {
      let update: UpdateProjectExtension = {
        displayName: PlannerProjectExtensionFormModel.value,
        value: PlannerProjectExtensionFormModel.value,
        hasDefaultAccess: PlannerProjectExtensionFormModel.hasDefaultAccess,
        key: selectedPlannerId,
      };
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        update
      );
    } else if (addProjectExtension && !projectExtension) {
      let groupIdToSet = projectsById[projectId].groupId;
      setDisabled(true);
      let newProjectExtension: CreateProjectExtension = {
        displayName: PlannerProjectExtensionFormModel.value,
        value: PlannerProjectExtensionFormModel.value,
        hasDefaultAccess: PlannerProjectExtensionFormModel.hasDefaultAccess,
        projectExtensionType: 'planner',
        key: selectedPlannerId ?? null,
        addProjectExtensionOptions: [
          {
            key: 'plannerDetails',
            value: JSON.stringify({ GroupId: groupIdToSet }),
          },
        ],
      };
      await createProjectExtension(projectId, newProjectExtension);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (projectExtension) {
      form.setFieldsValue(projectExtension);
    }
  }, [projectExtension, form]);

  useEffect(() => {
    const getPlanners = async () => {
      const { data } = await apiFetchPlanners(projectId);
      if (data) {
        setPlanners([...data]);
      }
    };
    getPlanners();
  }, [projectId]);

  return (
    <>
      {projectExtension?.displayName ? (
        <>
          <p>
            {t('projects:addIn.extensions.planner.form.titel')}:{' '}
            {projectExtension.displayName}
          </p>
          <p>
            {t('projects:addIn.extensions.planner.form.planner')}:{' '}
            {
              planners.find((planner) => planner.id === projectExtension.key)
                ?.title
            }
          </p>
        </>
      ) : (
        <Form
          form={form}
          layout="vertical"
          className={classes.root}
          initialValues={{
            displayName: 'Link',
            url: 'https://',
            hasDefaultAccess: true,
          }}
          onFinish={(values) => {
            if (saveOrUpdateExtension) {
              saveOrUpdateExtension(values);
            }
          }}
          onValuesChange={(_, values) => {
            //nothingToDo
          }}
        >
          <Typography.Title level={4}>
            {t('projects:addIn.planner')}
          </Typography.Title>
          <Form.Item
            name="value"
            colon={colon}
            label={t('projects:addIn.extensions.planner.form.titel')}
            required={true}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.plannerIdReqired'),
              },
            ]}
          >
            <Input disabled={projectExtension != null || disabled} />
          </Form.Item>
          <Form.Item
            name="key"
            colon={colon}
            label={t('projects:addIn.extensions.planner.form.planner')}
            required={addProjectExtension === null ? true : false}
            rules={[
              {
                required: addProjectExtension === null ? true : false,
                message: t('projects:form.validation.plannerIdReqired'),
              },
            ]}
          >
            <Select
              value={selectedPlannerId}
              optionFilterProp="label"
              onChange={(value) => {
                setSelectedPlannerId(value);
              }}
              disabled={planners.length === 0}
            >
              {planners.map((planner) => (
                <Select.Option
                  value={planner.id}
                  key={planner.title}
                  label={planner.id}
                >
                  {planner.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hasDefaultAccess"
            colon={colon}
            label={t('projects:addIn.extensions.form.hasDefaultAccess')}
            required={true}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.hasDefaultAccessRequired'),
              },
            ]}
            valuePropName="checked"
          >
            <Switch disabled={true} />
          </Form.Item>
          {!projectExtension && (
            <Button htmlType="submit" disabled={disabled}>
              {t('projects:addIn.extensions.form.save')}
            </Button>
          )}
        </Form>
      )}
    </>
  );
};

export default PlannerProjectExtensionForm;
