import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Tabs, notification } from 'antd';
import equals from 'deep-equal';
import { InternalContact, Contact } from '../../../../models/Contact';
import { Employee, HolidayEntitlement } from '../../../../models/Employee';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Center } from '../../../../components/Center';
import {
  getContact,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import useDatePickerLocale from '../../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import {
  apiAddHolidayEntitlementEntry,
  apiUpdateHolidayEntitlement,
  apiFetchEmployee,
  apiPatchEmployee,
} from '../../api';
import { HolidayEntitlementsTable } from './HolidayEntitlementsTable';
import { OffBoardingModal, OffBoardEmployee } from './OffBoardingModal';
import { apiOffBoardEmployee } from '../../../employee/api';
import EmployeeAbsencesOverview from '../EmployeeAbsencesOverview';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useParams } from 'react-router-dom';
import PersonnelFileHeader from './PersonnelFileHeader';
import PersonnelFileCoreDataForm from './PersonnelFileCoreDataForm';
import PersonnelFileContractDataForm from './PersonnelFileContractDataForm';
import PersonnelFileBankDataForm from './PersonnelFileBankDataForm';
import { OfficeId } from '../../../../models/Types';
import PersonnelFileCompanyDataForm from './PersonnelFileCompanyDataForm';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { apiFetchAbsenceProposalOverview } from '../../../absences/api';
import useAccessRights from '../../../users/hooks/useAccessRights';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    position: 'relative',
    maxWidth: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffffaa',
  },
  content: {
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  label: {
    fontSize: theme.old.typography.fontSize.label,
  },
  tabs: {
    '& .ant-tabs-content-holder': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    overflowY: 'auto',
    height: '100%',
    '& .ant-tabs': {
      height: '100%',
    },
  },
  holidayEntitlements: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface PersonnelFileProps {
  className?: string;
  noAvatar?: boolean;
  officeId?: OfficeId;
  pathPrefix?: string;
  onlyShowTabs?: string[];
}

export const PersonnelFile: React.FC<PersonnelFileProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className, noAvatar, pathPrefix, onlyShowTabs } = props;
  const { employeeId, officeId } = useParams();
  const { t } = useTranslation();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const contact: InternalContact = useSelector<RootReducerState, Contact>(
    (state) => getContact(state, employeeId)
  ) as InternalContact;

  const { showUserCoreData, showUserAbsenceOverview } = useAccessRights(
    ['showUserCoreData', 'showUserAbsenceOverview'],
    { officeId: officeId || contact?.officeId }
  );

  const [employee, setEmployee] = useState<Employee>({ employeeId });
  const [employeeIsFetching, setEmployeeIsFetching] = useState<boolean>(false);

  const [absenceOverview, setAbsenceOverview] =
    useState<AbsenceOverview | null>();
  const [absenceOverviewIsFetching, setAbsenceOverviewIsFetching] =
    useState<boolean>(false);

  const [updatedHolidayEntitlements, setUpdatedHolidayEntitlements] = useState<
    HolidayEntitlement[] | null
  >();

  const [offBoardingModalOpen, setOffBoardingModalOpen] =
    useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchAbsenceOverview = useCallback(async () => {
    setAbsenceOverviewIsFetching(true);
    const { result, data } = await apiFetchAbsenceProposalOverview(
      employeeId,
      undefined,
      officeId
    );
    setAbsenceOverviewIsFetching(false);
    if (result.status >= 200 && result.status < 300) {
      setAbsenceOverview({
        ...data,
        absenceProposals: data.absenceProposals.map(
          ({
            principalId,
            substituteId,
            applicantId,
            notifyContactIds,
            ...rest
          }) => ({
            ...rest,
            principalId: principalId.toLowerCase(),
            substituteId: substituteId.toLowerCase(),
            applicantId: applicantId.toLowerCase(),
            notifyContactIds: notifyContactIds.map((id) => id.toLowerCase()),
          })
        ),
      });
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.fetchAbsenceOverview'),
      });
    }
  }, [employeeId, officeId, t]);

  const patchEmployee = async (patchData: Employee) => {
    setIsSaving(true);
    try {
      const { data } = await apiPatchEmployee(
        employeeId,
        patchData,
        employee?.rowVersion ?? '',
        officeId
      );
      if (data) {
        setEmployee(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:personnelFile.errorMessages.patchEmployee'),
        });
      }
      setIsSaving(false);
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.patchEmployee'),
      });
      setIsSaving(false);
    }
  };

  const onNewEntitlement = async (holidayEntitlement: HolidayEntitlement) => {
    setIsSaving(true);
    setEmployee({
      ...employee,
      employeeHolidayEntitlements: [
        ...(employee?.employeeHolidayEntitlements ?? []),
        holidayEntitlement,
      ],
    });
    try {
      const { isValid, employeeHolidayEntitlementId, ...dataToSubmit } =
        holidayEntitlement;
      const { data } = await apiAddHolidayEntitlementEntry(
        employeeId,
        officeId,
        {
          ...dataToSubmit,
          employeeId,
        }
      );
      if (data) {
        const updatedEmployee = {
          ...employee,
          employeeHolidayEntitlements: [
            ...(employee.employeeHolidayEntitlements ?? []),
            data,
          ],
        };
        setEmployee(updatedEmployee);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:personnelFile.errorMessages.patchEmployee'),
        });
        setEmployee(employee);
      }
      setIsSaving(false);
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.newEntitlement'),
      });
      setIsSaving(false);
      setEmployee(employee);
    }
  };

  const onIsDirtyChangedHolidayEntitlement = useCallback(
    (isDirty: boolean, holidayEntitlements: HolidayEntitlement[]) => {
      if (isDirty) {
        setUpdatedHolidayEntitlements(holidayEntitlements);
      } else {
        setUpdatedHolidayEntitlements(null);
      }
    },
    [setUpdatedHolidayEntitlements]
  );

  const onSaveUpdatedHolidayEntitlements = async () => {
    setIsSaving(true);
    const updatedHolidaysEntitlements = await Promise.all(
      updatedHolidayEntitlements.map(async (holidayEntitlement, index) => {
        const originalHolidayEntitlement =
          employee.employeeHolidayEntitlements[index];
        if (!equals(holidayEntitlement, originalHolidayEntitlement)) {
          return await doSaveHolidayEntitlement(
            holidayEntitlement,
            originalHolidayEntitlement
          );
        }
        return holidayEntitlement;
      })
    );
    setEmployee({
      ...employee,
      employeeHolidayEntitlements: updatedHolidaysEntitlements,
    });
    setIsSaving(false);
  };

  const doSaveHolidayEntitlement: (
    holidayEntitlement: HolidayEntitlement,
    originalHolidayEntitlement: HolidayEntitlement
  ) => Promise<HolidayEntitlement> = async (
    holidayEntitlement,
    originalHolidayEntitlement
  ) => {
    const { data } = await apiUpdateHolidayEntitlement(
      employeeId,
      holidayEntitlement,
      officeId
    );
    return data ?? originalHolidayEntitlement;
  };

  const offBoard = () => {
    setOffBoardingModalOpen(true);
  };
  const closeOffBoardingModal = () => {
    setOffBoardingModalOpen(false);
  };
  const offBoardEmployee = async (offBoardEmployee: OffBoardEmployee) => {
    const fd = new FormData();
    fd.append('leavingDate', offBoardEmployee.leavingDate.toISOString());
    fd.append('leavingReason', offBoardEmployee.leavingReason);
    fd.append(
      'lockAccountDate',
      offBoardEmployee.lockAccountDate.toISOString()
    );
    fd.append('forwardEmailsTo', offBoardEmployee.forwardEmailsTo);
    if (offBoardEmployee.attachment?.length > 0) {
      fd.append('attachment', offBoardEmployee.attachment[0]);
    }
    const { data } = await apiOffBoardEmployee(employeeId, fd);
    if (data) {
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:offBoarding.errorMessages.offBoardEmployeeError'),
      });
      return false;
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const controller = new AbortController();
    if (!showUserCoreData) return () => controller.abort();
    const signal = controller.signal;
    const fetchEmployee = async () => {
      try {
        const { data } = await apiFetchEmployee(employeeId, signal, officeId);
        if (data) {
          setEmployee(data);
        }
        setEmployeeIsFetching(false);
      } catch {}
    };
    setEmployeeIsFetching(true);
    fetchEmployee();
    return () => controller.abort();
  }, [employeeId, officeId, showUserCoreData]);

  useEffect(() => {
    if (showUserAbsenceOverview) fetchAbsenceOverview();
  }, [employeeId, fetchAbsenceOverview, showUserAbsenceOverview]);
  //#endregion

  if (!employeeId) {
    return (
      <Flex.Item flex={1} className={classNames(classes.root, className)}>
        <Center>
          <Typography>{t('hr:personnelFile.pleaseChoose')}</Typography>
        </Center>
      </Flex.Item>
    );
  }

  if (!contact) {
    return (
      <Flex.Item flex={1} className={classNames(classes.root, className)}>
        <Center>
          <PrioSpinner size="large" />
        </Center>
      </Flex.Item>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.content}
        childrenGap={theme.old.spacing.unit(3)}
        padding={`${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(
          3
        )}px`}
      >
        <PersonnelFileHeader
          contact={contact}
          employee={employee}
          offBoard={offBoard}
          noAvatar={noAvatar}
          pathPrefix={pathPrefix}
        />
        <Tabs className={classes.tabs}>
          {(!onlyShowTabs || onlyShowTabs?.includes('coreData')) && (
            <Tabs.TabPane
              tab={t('hr:personnelFile.pivot.coreData')}
              key="coreData"
            >
              <PersonnelFileCoreDataForm
                employeeId={employeeId}
                employee={employee}
                isSaving={isSaving}
                datePickerLocale={datePickerLocale}
                patchEmployee={patchEmployee}
              />
            </Tabs.TabPane>
          )}
          {(!onlyShowTabs || onlyShowTabs?.includes('companyData')) && (
            <Tabs.TabPane
              tab={t('hr:personnelFile.pivot.companyData')}
              key="companyData"
            >
              <PersonnelFileCompanyDataForm
                employeeId={employeeId}
                employee={employee}
                isSaving={isSaving}
                datePickerLocale={datePickerLocale}
                patchEmployee={patchEmployee}
                officeId={officeId}
                contact={contact}
              />{' '}
            </Tabs.TabPane>
          )}
          {(!onlyShowTabs || onlyShowTabs?.includes('contractData')) && (
            <Tabs.TabPane
              tab={t('hr:personnelFile.pivot.contractData')}
              key="contractData"
            >
              <PersonnelFileContractDataForm
                employeeId={employeeId}
                employee={employee}
                isSaving={isSaving}
                datePickerLocale={datePickerLocale}
                patchEmployee={patchEmployee}
              />
            </Tabs.TabPane>
          )}
          {(!onlyShowTabs || onlyShowTabs?.includes('holidayEntitlements')) && (
            <Tabs.TabPane
              tab={t('hr:personnelFile.pivot.holidayEntitlements')}
              key="holidayEntitlements"
            >
              <HolidayEntitlementsTable
                updatedHolidayEntitlements={updatedHolidayEntitlements}
                onSaveUpdatedHolidayEntitlements={
                  onSaveUpdatedHolidayEntitlements
                }
                entitlements={employee?.employeeHolidayEntitlements ?? []}
                onNewEntitlement={onNewEntitlement}
                isSaving={isSaving}
                onIsDirtyChanged={onIsDirtyChangedHolidayEntitlement}
              />
            </Tabs.TabPane>
          )}
          {(!onlyShowTabs || onlyShowTabs?.includes('absenceOverview')) && (
            <Tabs.TabPane
              tab={t('hr:personnelFile.pivot.absenceOverview')}
              key="absenceOverview"
            >
              <EmployeeAbsencesOverview
                absenceOverview={absenceOverview}
                absenceOverviewIsFetching={absenceOverviewIsFetching}
                officeId={officeId}
              />
            </Tabs.TabPane>
          )}
          {(!onlyShowTabs || onlyShowTabs?.includes('bankData')) && (
            <Tabs.TabPane
              tab={t('hr:personnelFile.pivot.bankData')}
              key="bankData"
            >
              <PersonnelFileBankDataForm
                employeeId={employeeId}
                employee={employee}
                isSaving={isSaving}
                patchEmployee={patchEmployee}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Flex.Column>
      {employeeIsFetching && (
        <div className={classes.loadingOverlay}>
          <Center>
            <PrioSpinner size="large" />
          </Center>
        </div>
      )}
      <OffBoardingModal
        open={offBoardingModalOpen}
        onClose={closeOffBoardingModal}
        offBoardEmployee={offBoardEmployee}
      />
    </div>
  );
};

export default PersonnelFile;
