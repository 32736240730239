import React from 'react';
import { Drawer, notification, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import AddEmployeeForm from './AddEmployeeForm';
import { AddEmployee } from '../../../models/Employee';
import { apiSubmitEmployeeOnboarding } from '../../employee/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

interface AddEmployeeDrawerProps {
  onClose?: VoidFunction;
  open?: boolean;
}
const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  importUser: {
    height: '100%',
  },
  importAadUser: {
    flex: 1,
  },
  title: {
    paddingBottom: theme.old.spacing.unit(2),
    marginTop: -theme.old.spacing.unit(1),
  },
  scrollabe: {
    flex: 1,
    overflow: 'auto',
  },
  drawer: {
    zIndex: 999,
  },
}));

export const AddEmployeeDrawer: React.FC<AddEmployeeDrawerProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { onClose, open } = props;

  const closeDrawer = () => {
    if (onClose) {
      onClose();
    }
  };

  const submitEmployeeOnboarding: (
    addEmployee: AddEmployee
  ) => Promise<boolean> = async (addEmployee) => {
    const { data } = await apiSubmitEmployeeOnboarding(
      addEmployee,
      addEmployee.officeId
    );
    if (data) {
      closeDrawer();
      notification.open({
        message: t('common:success'),
        description: t(
          'hr:addEmployeeDrawer.successMessages.submitEmployeeOnboardingSuccess'
        ),
        duration: 15,
      });
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:addEmployeeDrawer.errorMessages.submitEmployeeOnboardingError'
        ),
      });
      return false;
    }
  };

  const renderContent = () => {
    return (
      <Flex.Column className={classes.importUser}>
        <div className={classes.title}>
          <Typography.Title level={2}>
            {t('hr:addEmployeeDrawer.title')}
          </Typography.Title>
        </div>
        <div className={classes.scrollabe}>
          <AddEmployeeForm
            actionLabel={t('hr:addEmployeeDrawer.actions.submitForm')}
            cancelLabel={t('common:actions.cancel')}
            onFinishCreate={submitEmployeeOnboarding}
          />
        </div>
      </Flex.Column>
    );
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={closeDrawer}
      visible={open}
      width={theme.old.components.drawerWidth}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.drawer}
      destroyOnClose
    >
      {renderContent()}
    </Drawer>
  );
};

export default AddEmployeeDrawer;
