import { Reducer, combineReducers } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ReduxAction } from '../../../models/Redux';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import {
  FETCH_OFFICE_HOLIDAY_COMMIT,
  FETCH_OFFICE_HOLIDAY_REQUEST,
  FETCH_OFFICE_HOLIDAY_ROLLBACK,
} from '../actions';
import { DateTimeString, OfficeHolidayId } from '../../../models/Types';
import moment from 'moment';

export interface OfficeHolidayState {
  byId: OfficeHolidayByIdState;
  ids: OfficeHolidayId[];
  meta: MetaState;
}

export const initialState: OfficeHolidayState = {
  byId: {},
  ids: [],
  meta: {
    isFetching: false,
    hasError: false,
  },
};

interface OfficeHolidayByIdState {
  [officeHolidayId: OfficeHolidayId]: OfficeHoliday;
}

const byId: Reducer<
  OfficeHolidayByIdState,
  ReduxAction<unknown, OfficeHoliday[]>
> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_OFFICE_HOLIDAY_COMMIT: {
      const { payload } = action;
      return payload.reduce(
        (map, holiday) => ({
          ...map,
          [holiday.date]: holiday,
        }),
        {}
      );
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<OfficeHolidayId[], ReduxAction<unknown, OfficeHoliday[]>> = (
  state = initialState.ids,
  action
) => {
  switch (action.type) {
    case FETCH_OFFICE_HOLIDAY_COMMIT: {
      const { payload } = action;
      return payload.map(({ date }) => date);
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default: {
      return state;
    }
  }
};

interface MetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<MetaState, ReduxAction> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_OFFICE_HOLIDAY_REQUEST: {
      return {
        isFetching: true,
        hasError: false,
      };
    }
    case FETCH_OFFICE_HOLIDAY_COMMIT: {
      return {
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_OFFICE_HOLIDAY_ROLLBACK: {
      return {
        isFetching: false,
        hasError: true,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<OfficeHolidayState>({
  byId,
  ids,
  meta,
});

export const getOfficeHolidays: (
  state: OfficeHolidayState
) => OfficeHoliday[] = ({ ids, byId }) =>
  ids.map((id) => byId[id]).filter((holiday) => !!holiday);

export const getOfficeHolidaysOfMonth: (
  state: OfficeHolidayState,
  dateTime: DateTimeString
) => OfficeHoliday[] = ({ ids, byId }, dateTime) => {
  const holidaysThisMonth = ids
    .map((id) => byId[id])
    .filter(
      (holiday) =>
        holiday && moment(holiday.date).isSame(moment(dateTime), 'month')
    );
  return holidaysThisMonth ?? [];
};

export const getOfficeHolidaysOfTimeRange: (
  state: OfficeHolidayState,
  from: DateTimeString,
  to: DateTimeString
) => OfficeHoliday[] = ({ ids, byId }, from, to) => {
  const holidays = ids
    .map((id) => byId[id])
    .filter(
      (holiday) =>
        holiday &&
        moment(holiday.date).isSameOrAfter(moment(from), 'month') &&
        moment(holiday.date).isSameOrBefore(moment(to), 'month')
    );
  return holidays ?? [];
};
