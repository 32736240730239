import { useCallback, useState } from 'react';
import { DriveItem } from '../../../models/Drive';
import { checkIfLink } from '../util';
import { apiFetchDocument, apiFetchDocumentWithCaching } from '../api';
import { notification } from 'antd';
import { useGetLocalUrlByDriveId } from './useGetLocalUrlByDriveId';
import { GroupId, ProjectId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { getDocumentsCachingEnabled } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';

export const useDriveItemActions = (groupId: GroupId, projectId: ProjectId) => {
  const [isFetchingUrl, setIsFetchingUrl] = useState<boolean>(false);

  const { t } = useTranslation();
  const { getLocalUrlByDriveItem } = useGetLocalUrlByDriveId();

  const isDocumentsCachingEnabled =
    useSelector(getDocumentsCachingEnabled) ?? false;

  const openLinkInBrowser = (urlName: string) => {
    const url = urlName.slice(0, -4);
    window.open('https://' + url, '_blank');
  };
  const openInBrowser = useCallback(
    async (driveItem: DriveItem) => {
      const isLink = checkIfLink(driveItem?.name);
      if (isLink) {
        openLinkInBrowser(driveItem.name);

        return;
      }
      if (!isFetchingUrl) {
        const a = document.createElement('a');
        let item: DriveItem = driveItem;
        if (!driveItem.parentReference?.path) {
          setIsFetchingUrl(true);
          const { data } =
            isDocumentsCachingEnabled === true
              ? await apiFetchDocumentWithCaching(projectId, driveItem.id)
              : await apiFetchDocument(groupId, driveItem.id);
          setIsFetchingUrl(false);
          if (data) {
            item = data;
          } else {
            notification.open({
              message: t('common:error'),
              description: t('documents:errorMessages.fetchDriveItemError'),
            });
            document.body.removeChild(a);
            return;
          }
        }
        a.href = item.webUrl;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    [groupId, isFetchingUrl, t, projectId, isDocumentsCachingEnabled]
  );

  const openLocally = useCallback(
    async (driveItem: DriveItem, openPdf) => {
      if (!isFetchingUrl) {
        const a = document.createElement('a');

        var url = await getLocalUrlByDriveItem(
          groupId,
          projectId,
          driveItem,
          setIsFetchingUrl,
          openPdf
        );
        if (url) {
          a.href = url;

          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
        }
        document.body.removeChild(a);
      }
    },
    [getLocalUrlByDriveItem, groupId, isFetchingUrl, projectId]
  );

  return {
    openInBrowser,
    openLocally,
    openLinkInBrowser,
  };
};
