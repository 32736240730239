import React, { useEffect, useState } from 'react';

import { apiFinishProjectCreation, apiGetProjectById } from '../../api';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from '@prio365/prio365-react-library';
import { Project } from '../../../../models/Project';
import { useSelector } from 'react-redux';
import { getCompaniesByIdState } from '../../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.spacing.unit(2)}px ${theme.spacing.unit(2)}px`,
    marginBottom: `${theme.spacing.unit(2)}px`,
    overflow: 'hidden',
    minHeight: `${theme.spacing.unit(8)}px`,
  },
  wrapContainerWhiteMain: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.spacing.unit(2)}px ${theme.spacing.unit(2)}px`,
    overflow: 'hidden',
    height: '100%',
  },
  contentContainerFloat: {
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .ant-form-item': {
      width: '100%',
    },
    '& .ant-row': {
      width: '100%',
    },
  },
  contentContainer: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    gap: `${theme.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  spacing: {
    marginLeft: theme.spacing.unit(2),
  },
  floatRight: {
    float: 'right',
  },
}));

interface CreateProjectSummaryFormProps {
  className?: string;
  projectId: string;
  nextStep?: (projectId: string, step: string) => void;
}

export const CreateProjectSummaryForm: React.FC<
  CreateProjectSummaryFormProps
> = (props) => {
  const classes = useStyles();
  const { projectId } = props;
  const [creating, setCreating] = useState<boolean>(false);
  const [project, setProject] = useState<Project>(null);
  const companiesById = useSelector(getCompaniesByIdState);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const createProject = async () => {
    if (projectId) {
      setCreating(true);
      const { data } = await apiFinishProjectCreation(projectId);
      if (data) {
        notification.open({
          message: t('common:success'),
          description: t('projects:newProject.projectCreated'),
        });
        navigate(`../../../`);
      }
      setCreating(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      const loadProject = async () => {
        const { data } = await apiGetProjectById(projectId);
        if (
          (data && data?.projectStatus && data?.projectStatus === 'draft') ||
          data.projectStatus === 10
        ) {
          setProject(data);
        }
      };
      loadProject();
    }
  }, [projectId]);

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.wrapContainerWhiteMain}>
          {project && (
            <>
              <Card
                title={`${project.number} - ${project.name}`}
                secondaryCard={true}
                contentNode={
                  <>
                    <p
                      title={
                        companiesById?.[project.subsidiaryId.toLowerCase()]
                          ? companiesById?.[project.subsidiaryId.toLowerCase()]
                              .shortName +
                              ' - ' +
                              companiesById?.[
                                project.subsidiaryId.toLowerCase()
                              ].fullName ??
                            companiesById?.[project.subsidiaryId.toLowerCase()]
                              .shortName
                          : ''
                      }
                    >
                      {t('projects:form.labels.internalOffice')}:{' '}
                      {companiesById?.[project.subsidiaryId.toLowerCase()]
                        ? companiesById?.[project.subsidiaryId.toLowerCase()]
                            .shortName +
                            ' - ' +
                            companiesById?.[project.subsidiaryId.toLowerCase()]
                              .fullName ??
                          companiesById?.[project.subsidiaryId.toLowerCase()]
                            .shortName
                        : ''}
                    </p>
                    <p
                      title={
                        companiesById?.[project.companyId]
                          ? companiesById?.[project.companyId].shortName +
                              ' - ' +
                              companiesById?.[project.companyId].fullName ??
                            companiesById?.[project.companyId].shortName
                          : ''
                      }
                    >
                      {t('projects:form.labels.companyId')}:{' '}
                      {companiesById?.[project.companyId]
                        ? companiesById?.[project.companyId].shortName +
                            ' - ' +
                            companiesById?.[project.companyId].fullName ??
                          companiesById?.[project.companyId].shortName
                        : ''}
                    </p>
                  </>
                }
              ></Card>
              <p></p>
              {/*
              <Card
                title={t('projects:projectCreation.tabs.addins')}
                secondaryCard={true}
                contentNode={
                  <>
                    {t('projects:projectCreation.extensionCount')}:{' '}
                    {project.projectExtensions.length}
                  </>
                }
              ></Card>
              */}
            </>
          )}
        </div>
        <div className={classes.wrapContainerWhite}>
          <Button
            onClick={() => {
              createProject();
            }}
            disabled={creating}
            iconProp={['fal', 'check']}
          >
            {t('projects:createProject.buttons.createProject')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateProjectSummaryForm;
