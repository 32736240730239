import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getProjectsAndParentProjects,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import BasisPicker, {
  BasisPickerSelectOptions,
} from '../../../components/BasisPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
  },
}));

interface TimeRecordsFilterProjectPickerProps {
  projectIds: string[];
  onChange?: (value: string | string[]) => void;
  disabled?: boolean;
  defaultValue?: ProjectId | ProjectId[];
  value?: ProjectId | ProjectId[];
}

export const TimeRecordsFilterProjectPicker: React.FC<
  TimeRecordsFilterProjectPickerProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectIds, onChange, disabled, value, defaultValue } = props;
  const projects = useSelector<RootReducerState, Project[]>((state) =>
    getProjectsAndParentProjects(state, projectIds)
  );

  const sortedProjects: Project[] = useMemo(() => {
    const parentProjects = projects
      .filter((project) => project && !project.parentProject)
      .sort((a, b) => a.number.localeCompare(b.number));
    const childProjects = projects
      .filter((project) => project && project.parentProject)
      .sort((a, b) => a.number.localeCompare(b.number));
    const _sortedProjects = [];

    parentProjects.forEach((parentProject) => {
      _sortedProjects.push(parentProject);
      childProjects.forEach((childProject) => {
        if (childProject.parentProject === parentProject.projectId) {
          _sortedProjects.push(childProject);
        }
      });
    });
    return _sortedProjects;
  }, [projects]);

  const selectOptions: BasisPickerSelectOptions[] = sortedProjects.map(
    (project, index) => {
      return {
        title:
          project && project.number && project.shortName && project.name
            ? `${project.number}${
                project.shortName ? ` ${project.shortName}` : ''
              } ${project.name}`
            : t('common:errorMessages.unknownObject'),
        value:
          project && project.projectId
            ? project.projectId
            : `${t('common:errorMessages.unknownObject')}`,
        key:
          project && project.projectId
            ? project.projectId
            : `${t('common:errorMessages.unknownObject')} - ${index}`,
        label:
          project && project.number && (project.name || project.shortName) ? (
            <>
              {project.parentProject && (
                <FontAwesomeIcon
                  icon={['fal', 'horizontal-rule']}
                  size="sm"
                  className={classes.icon}
                ></FontAwesomeIcon>
              )}
              {`${project.number} ${
                project.shortName !== null && project.shortName !== ''
                  ? project.shortName
                  : project.name
              }`}
            </>
          ) : (
            t('common:errorMessages.unknownObject')
          ),
        isItalic: !project,
      };
    }
  );
  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks

  //#endregion

  return (
    <BasisPicker
      optionFilterProp="label"
      filterOption={(input, option) =>
        option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      defaultValue={defaultValue}
      value={value}
      className={classes.root}
      items={selectOptions}
      multipleSelect
      onChange={onChange}
      disabled={disabled}
      placeHolder={t('timeRecords:timeRecordsFilterProjectPicker.label')}
    />
  );
};

export default TimeRecordsFilterProjectPicker;
