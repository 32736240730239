import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import useAccessRights from '../../users/hooks/useAccessRights';
import ControllingOfficeModule from './ControllingOfficeModule';
import { makePrioStyles } from '../../../theme/utils';
import TimeRecordsModule from '../../timeRecords/components/TimeRecordsModule';
import InvoicesModule from '../../accounting/components/InvoicesModule';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
}));

interface ControllingModuleProps {}

export const ControllingModule: React.FC<ControllingModuleProps> = (props) => {
  const classes = useStyles();

  const { showControllingModule, showGlobalInControllingModule } =
    useAccessRights(['showControllingModule', 'showGlobalInControllingModule']);

  return (
    <div className={classes.root}>
      <Routes>
        {showControllingModule && (
          <Route
            path="office/*"
            element={<ControllingOfficeModule className={classes.fullHeight} />}
          />
        )}
        {showGlobalInControllingModule && (
          <>
            <Route path="invoices/*" element={<InvoicesModule />} />
            <Route
              path="timeRecords/*"
              element={<TimeRecordsModule contextType="global" />}
            />
          </>
        )}
      </Routes>
    </div>
  );
};

export default ControllingModule;
