import {
  actionChannel,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { PRIO } from '../../../constants';
import { apiUpdateDocumentMetaData } from '../api';
import {
  OPEN_CURRENT_PREVIEW_MODAL,
  updateDriveItemMetaDataPreviewModal,
} from '../actions/previewModal';
import { fetchDriveItems } from '../actions';
import { updateIsDocumentMetaDataSaving } from '../actions/isDocumentMetaDataSaving';
import { DriveItemId } from '../../../models/Types';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import { SAGA_REBUILD } from '../../../util/sagas';
import { isLoggedIn } from '../../../apps/main/rootReducer';
import { UpdateDriveItemField } from '../../../models/Drive';

export const DEBOUNCED_DOCUMENT_META_DATA_UPDATE =
  PRIO + 'DEBOUNCED_DOCUMENT_META_DATA_UPDATE';

export interface DebouncedDocumentMetaDataUpdate {
  type: typeof DEBOUNCED_DOCUMENT_META_DATA_UPDATE;
  payload: {
    requestBody: UpdateDriveItemField;
    driveItemId: DriveItemId;
    isRoot: boolean;
  };
}

function* watchDebouncedDocumentMetaDataUpdate(
  _action: DebouncedDocumentMetaDataUpdate
) {
  let action = _action;
  const driveItemId = action.payload.driveItemId;
  try {
    let previewModalIsVisible = true;
    const channel = yield actionChannel(OPEN_CURRENT_PREVIEW_MODAL);
    const metaDataUpdateChannel = yield actionChannel(
      (__action) =>
        __action.type === DEBOUNCED_DOCUMENT_META_DATA_UPDATE &&
        __action.payload?.driveItemId === driveItemId
    );
    do {
      while (true) {
        yield put(
          updateIsDocumentMetaDataSaving({
            [action.payload.driveItemId]: true,
          })
        );

        const { debounced, latestAction } = yield race({
          debounced: delay(2000),
          latestAction: take(metaDataUpdateChannel),
        });

        if (debounced) {
          const { data } = yield call(
            apiUpdateDocumentMetaData,
            action.payload.requestBody
          );
          if (data.length === 0) {
            yield put(
              updateDriveItemMetaDataPreviewModal(
                action.payload.requestBody.updateDriveItemFieldDtos
              )
            );
            yield put(
              updateIsDocumentMetaDataSaving({
                [action.payload.driveItemId]: false,
              })
            );
          }
          break;
        }
        action = latestAction;
      }
      const { latestAction, previewModal } = yield race({
        latestAction: take(metaDataUpdateChannel),
        previewModal: take(channel),
      });

      if (previewModal?.visibility === false) {
        previewModalIsVisible = false;
        yield put(
          fetchDriveItems(
            action.payload.requestBody.groupId,
            action.payload.isRoot ? null : action.payload.driveItemId
          )
        );
      }

      if (latestAction) {
        action = latestAction;
      }
    } while (previewModalIsVisible);
  } catch (error) {
    console.error('Error in watchDebouncedDocumentMetaDataUpdate', error);
    yield watchDebouncedDocumentMetaDataUpdate(action);
  }
}

function* watchUpdateDriveItemMetaData(
  action: DebouncedDocumentMetaDataUpdate,
  removeDriveItemId: (driveItemId: DriveItemId) => void
) {
  yield call(watchDebouncedDocumentMetaDataUpdate, action);
  removeDriveItemId(action.payload.driveItemId);
}

function* driveItemMetaDataUpdateManager() {
  let driveItemIds: DriveItemId[] = [];
  const channel = yield actionChannel(DEBOUNCED_DOCUMENT_META_DATA_UPDATE);

  function removeDriveItemId(driveItemId: DriveItemId) {
    driveItemIds = driveItemIds.filter((id) => id !== driveItemId);
  }

  while (true) {
    let action: DebouncedDocumentMetaDataUpdate = yield take(channel);
    if (!driveItemIds.includes(action.payload.driveItemId)) {
      driveItemIds = [...driveItemIds, action.payload.driveItemId];
      yield fork(watchUpdateDriveItemMetaData, action, removeDriveItemId);
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(driveItemMetaDataUpdateManager), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in mainTask', error);
    yield mainTask();
  }
}

function* watchUpdateDriveItemMetaData2() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}

export default watchUpdateDriveItemMetaData2;
