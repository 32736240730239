import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Drawer, Popover, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  getCompaniesIsFetching,
  getContactsDrawerState,
  getContactsIsFetching,
} from '../../../apps/main/rootReducer';
import ContactsTabs from './ContactsTabs';
import {
  closeContactsDrawer,
  ContactTabs,
  setContactsDrawerTab,
  setContactsDrawerState,
} from '../actions/drawer';
import ContactDetailsDrawer from './ContactDetailsDrawer';
import CompanyDetailsDrawer from '../../companies/components/CompanyDetailsDrawer';
import CreateContact from './CreateContact';
import EditContact from './EditContact';
import { ContactId, CompanyId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import CreateCompany from '../../companies/components/CreateCompany';
import EditCompany from '../../companies/components/EditCompany';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchCompanies } from '../../companies/actions';
import Flex from '../../../components/Flex';
import { fetchContactsOffset } from '../actions';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { apiSyncContactBook } from '../api';

interface GlobalContactsDrawerProps {
  className?: string;
}
const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },

  contactDetails: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    padding: `${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px 0`,
  },
  contactTabs: {
    flex: 1,
  },
  tabBar: {
    paddingTop: 0,
  },
  flexColExpand: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    zIndex: 999,
  },
  icon: {
    color: `${theme.old.typography.colors.muted}!important`,
    cursor: 'pointer',
  },
}));

const initialValuesContactForm = {
  contactId: '',
  title: '',
  firstName: '',
  lastName: '',
  department: '',
  position: '',
  eMail: '',
  eMail2: '',
  phone: '',
  phone2: '',
  cellphone: '',
  cellphone2: '',
  fax: '',
  notes: '',
};

export const GlobalContactsDrawer: React.FC<GlobalContactsDrawerProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const contactsDrawerState = useSelector(getContactsDrawerState); // Remove or fill with selector
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, view, tab, selectedCompany } = contactsDrawerState;

  const [searchInput, setSearchInput] = React.useState<string>('');
  const [syncContactBookStarted, setSyncContactBookStarted] =
    React.useState<boolean>(false);

  const isContacsFetching = useSelector(getContactsIsFetching);
  const isCompaniesFetching = useSelector(getCompaniesIsFetching);

  const closeDrawer = () => {
    dispatch(closeContactsDrawer());
    setSearchInput('');
  };

  const onTabChange = (activeKey: ContactTabs) => {
    dispatch(setContactsDrawerTab(activeKey));
  };
  const onContactClick = (contactId: ContactId) => {
    dispatch(
      setContactsDrawerState({
        selectedContact: contactId,
        view: 'contactDetail',
      })
    );
  };
  const onCompanyClick = (companyId: CompanyId) => {
    dispatch(
      setContactsDrawerState({
        selectedCompany: companyId,
        view: 'companyDetail',
      })
    );
  };

  const handleContactsReload = () => {
    dispatch(fetchContactsOffset());
    dispatch(fetchCompanies());
  };
  const handleResyncReload = async () => {
    await apiSyncContactBook();
    setSyncContactBookStarted(true);
  };

  const renderContent = () => {
    switch (view) {
      case 'list':
        return (
          <div
            id="prio-global-contacts-drawer-list"
            className={classNames(classes.root, className)}
          >
            <Flex.Row
              alignItems="baseline"
              childrenGap={theme.old.spacing.baseSpacing}
              className={classes.title}
            >
              <Typography.Title level={2}>
                {t('contacts:title')}
              </Typography.Title>
              <Popover
                trigger="click"
                content={
                  <>
                    <Flex.Row>
                      <Button
                        onClick={() => {
                          if (!(isContacsFetching || isCompaniesFetching)) {
                            handleContactsReload();
                          }
                        }}
                        disabled={isContacsFetching || isCompaniesFetching}
                        iconProp={['fal', 'sync']}
                      >
                        {t('contacts:reload')}
                      </Button>
                    </Flex.Row>
                    <p> </p>
                    <Flex.Row>
                      {syncContactBookStarted ? (
                        <p>{t('contacts:resyncStarted')}</p>
                      ) : (
                        <Button
                          onClick={() => {
                            handleResyncReload();
                          }}
                          type="primary"
                        >
                          <FontAwesomeIcon icon={['fal', 'address-book']} />{' '}
                          {t('contacts:resync')}
                        </Button>
                      )}
                    </Flex.Row>
                  </>
                }
              >
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={['fal', 'sync']}
                  size="xs"
                  spin={isContacsFetching || isCompaniesFetching}
                />
              </Popover>
            </Flex.Row>
            <ContactsTabs
              activeKey={tab}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              onChange={onTabChange}
              onContactClick={onContactClick}
              onCompanyClick={onCompanyClick}
              className={classes.contactTabs}
              tabBarClassName={classes.tabBar}
            />
          </div>
        );
      case 'contactDetail':
        return <ContactDetailsDrawer />;
      case 'contactNew':
        return (
          <CreateContact
            className={classNames(classes.contactDetails, classes.noPadding)}
            noAvatar
            initialValues={{
              ...initialValuesContactForm,
              companyId: selectedCompany as CompanyId,
            }}
          />
        );
      case 'contactEdit':
        return (
          <EditContact
            className={classNames(classes.contactDetails, classes.noPadding)}
            formClassName={classes.flexColExpand}
            noAvatar
          />
        );
      case 'companyDetail':
        return <CompanyDetailsDrawer />;
      case 'companyNew':
        return (
          <CreateCompany
            className={classNames(classes.contactDetails, classes.noPadding)}
            noAvatar
          />
        );
      case 'companyEdit':
        return (
          <EditCompany
            className={classNames(classes.contactDetails, classes.noPadding)}
            formClassName={classes.flexColExpand}
            noAvatar
          />
        );
      default:
        return <></>;
    }
  };

  const bodyStyle: () => React.CSSProperties | null = () => {
    switch (view) {
      case 'list':
        return { padding: 0 };
      case 'contactDetail':
      case 'companyDetail':
        return {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        };
      default:
        return null;
    }
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={closeDrawer}
      visible={open}
      width={theme.old.components.drawerWidth}
      bodyStyle={bodyStyle()}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.drawer}
      destroyOnClose
    >
      {renderContent()}
    </Drawer>
  );
};

export default GlobalContactsDrawer;
