import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NavigationBar from '../../../components/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import TimeRecordsTable from './TimeRecordsTable';
import moment from 'moment';
import {
  TimeRecord,
  TimeRecordsFilter as TimeRecordsFilterI,
} from '../../../models/TimeRecord';
import { apiDeleteTimeRecords, apiFetchTimeRecords } from '../api';
import { Drawer, Modal, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useExportTimeRecordsToCsv } from '../export';
import { TimeRecordsByIdState } from '../reducers/timeRecords';
import { DocumentTemplateFormWrapper } from '../../documents/components/DocumentTemplateFrom/DocumentTemplateFormWrapper';
import EditTimeRecord from './EditTimeRecord/EditTimeRecord';
import {
  CompanyId,
  ContactId,
  OfficeId,
  ProjectId,
  TimeRecordId,
} from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTimeAndLeaveManagementDrawerState,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { useTimeRecordsFilter } from '../hooks/index';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { ApiResult } from '../../../api';
import { deleteMyTimeRecord } from '../actions';
import {
  closeTimeAndLeaveManagementDrawer,
  openTimeAndLeaveManagementDrawer,
} from '../../timeAndLeaveManagement/actions';
import TimeRecordsFilter from './TimeRecordsFilter';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  overview: {
    height: '100%',
    overflow: 'hidden',
    padding: theme.old.spacing.defaultPadding,
  },
  excelIcon: {
    marginLeft: theme.old.spacing.unit(1.5),
    marginRight: theme.old.spacing.unit(1.5),
  },
  filter: {
    width: '100%',
  },
  table: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    cursor: 'pointer',
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  noScroll: {
    overflow: 'hidden',
    height: '100%',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  panelHeadline: {
    '&.ant-typography': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  form: {
    height: 'calc(100% - 33px)',
  },
}));

interface TimeRecordControllingSubModuleProps {
  projectId: ProjectId;
}

export const TimeRecordControllingSubModule: React.FC<
  TimeRecordControllingSubModuleProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------- States / Attributes / Selectors
  const [currentFilter, setCurrentFilter] = useState<TimeRecordsFilterI>({
    from: moment().startOf('week'),
    to: moment().endOf('week'),
  });
  const [timeRecords, setTimeRecords] = useState<TimeRecord[]>([]);
  const {
    filteredTimeRecords,
    setCurrentAdvancedFilter,
    currentAdvancedFilter,
  } = useTimeRecordsFilter(timeRecords);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [selectedTimeRecords, setSelectedTimeRecords] = useState<string[]>([]);
  const [selectedTimeRecordObjects, setSelectedTimeRecordObjects] = useState<
    TimeRecord[]
  >([]);

  var total_sum = filteredTimeRecords.reduce(function (prev, cur) {
    var duration = prev + cur.durationInMinutes / 60;
    var durationString = duration.toFixed(2);
    var rounded = Number(durationString);
    return rounded;
  }, 0);

  const [open, setOpen] = useState<boolean>(false);

  const [selectedTimeRecordId, setSelectedTimeRecordId] =
    useState<TimeRecordId | null>(null);

  const [companyFilterSelected, setCompanyFilterSelected] = useState<
    CompanyId[]
  >([]);
  const [projectFilterSelected, setProjectFilterSelected] = useState<
    ProjectId[]
  >([]);
  const [contactFilterSelected, setContactFilterSelected] = useState<
    ContactId[]
  >([]);

  const selectedTimecord = useMemo(
    () =>
      timeRecords.find(
        (record) => record.timeRecordId === selectedTimeRecordId
      ),
    [timeRecords, selectedTimeRecordId]
  );

  const { createdObjectType } = useSelector(
    getTimeAndLeaveManagementDrawerState
  );

  const [deleteSelectionModalVisible, setDeleteSelectionModalVisible] =
    useState<boolean>(false);

  const userMe = useSelector(getUserMe);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const showDrawer = () => {
    setVisible(true);
  };

  const onCreateReport = () => {
    showDrawer();
  };
  const onClose = () => {
    setVisible(false);
  };
  const onCloseEditRecordDrawer = () => {
    setOpen(false);
  };
  const onCreateDocumentSuccess = () => {
    setVisible(false);
  };
  const onStartCreateDocument = () => {
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const fetchRecords = useCallback(
    (filter: TimeRecordsFilterI) => {
      const controller = new AbortController();
      const signal = controller.signal;
      const loadTimeRecords = async () => {
        setIsFetching(true);
        try {
          const from = filter?.from
            ? moment(filter.from)
                .startOf('date')
                .toISOString(true)
                .split('T')[0]
            : undefined;
          const to = filter?.to
            ? moment(filter.to).startOf('date').toISOString(true).split('T')[0]
            : undefined;
          const { data } = await apiFetchTimeRecords(
            { ...filter, from, to },
            signal,
            projectId,
            true
          );
          if (data) {
            setTimeRecords(data);
          }
        } catch {}
        setIsFetching(false);
      };
      loadTimeRecords();
      return () => {
        controller.abort();
      };
    },
    [projectId]
  );

  const handleFilterChange = (filter: TimeRecordsFilterI) => {
    setCurrentFilter(filter);
    fetchRecords(filter);
  };

  const handleProjectFilterChange = (projectIds: ProjectId[]) => {
    setCurrentAdvancedFilter({
      ...currentAdvancedFilter,
      projectFilters: projectIds,
    });
    setProjectFilterSelected(projectIds);
  };

  const handleOfficeFilterChange = (officeIds: OfficeId[]) => {
    setCurrentAdvancedFilter({
      ...currentAdvancedFilter,
      companyFilters: officeIds,
    });
    setCompanyFilterSelected(officeIds);
  };

  const handleContactFilterChange = (contactIds: ContactId[]) => {
    setCurrentAdvancedFilter({
      ...currentAdvancedFilter,
      contactFilters: contactIds,
    });
    setContactFilterSelected(contactIds);
  };

  const handleResetAdvancedFilters = () => {
    setCurrentAdvancedFilter({
      companyFilters: [],
      contactFilters: [],
      projectFilters: [],
    });
    setCompanyFilterSelected([]);
    setProjectFilterSelected([]);
    setContactFilterSelected([]);
  };
  const exportToCsv = useExportTimeRecordsToCsv();

  const onExportToCsv = () => {
    const timeRecordsById: TimeRecordsByIdState = timeRecords.reduce(function (
      map,
      item
    ) {
      map[item.timeRecordId] = item;
      return map;
    }, {});
    exportToCsv(selectedTimeRecords, timeRecordsById);
  };

  const onExportAllToCsv = () => {
    const timeRecordsById: TimeRecordsByIdState = timeRecords.reduce(function (
      map,
      item
    ) {
      map[item.timeRecordId] = item;
      return map;
    }, {});
    exportToCsv(
      timeRecords.map((record) => record.timeRecordId),
      timeRecordsById
    );
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedTimeRecordId(null);
  };

  const handleFinish = (value: TimeRecord) => {
    if (currentFilter) {
      setTimeout(() => fetchRecords(currentFilter), 250);
    }
    handleClose();
  };

  const handleTimeRecordClick = (timeRecordId: TimeRecordId) => {
    setSelectedTimeRecordId(timeRecordId);
    setOpen(true);
  };

  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  const handleDelete = async (value: TimeRecord[]) => {
    await Promise.all(
      value.map((p) => apiDeleteTimeRecords(p.timeRecordId, p, 'project'))
    )
      .then((values: ApiResult<undefined>[]) => {
        values.forEach(({ data, result }) => {
          if (data && data['contactId'] === userMe.id) {
            dispatch(
              deleteMyTimeRecord(
                data['timeRecordId'],
                data,
                data['contactId'] === userMe.id
              )
            );
          }
        });
      })
      .catch((error) => {
        notification.open({
          message: t('common:error'),
          description: t('timeRecords:errorMessages.deleteError'),
        });
        console.error(error.message);
      });
    if (currentFilter && fetchRecords) {
      fetchRecords(currentFilter);
    }
    if (setOpen) setOpen(false);
    if (setSelectedTimeRecordId) setSelectedTimeRecordId(null);
  };

  const handleModalOk = () => {
    handleDelete(selectedTimeRecordObjects);
    setDeleteSelectionModalVisible(false);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    var selectedObjects = timeRecords.filter(function (timerecord) {
      return selectedTimeRecords.includes(timerecord.timeRecordId);
    });
    setSelectedTimeRecordObjects(selectedObjects);
  }, [selectedTimeRecords, timeRecords]);

  useEffect(() => {
    if (createdObjectType === 'timeRecord') {
      fetchRecords(currentFilter);
      dispatch(closeTimeAndLeaveManagementDrawer('none'));
    }
  }, [createdObjectType, currentFilter, dispatch, fetchRecords]);
  //#endregion

  if (!projectId) return null;

  return (
    <div className={classes.root}>
      <NavigationBar>
        <Button onClick={showTimeRecordDrawer} iconProp={['fal', 'pencil']}>
          <span>{t('common:navigationBar.recordTime')}</span>
        </Button>
        <Button
          disabled={!timeRecords?.length}
          onClick={onExportAllToCsv}
          iconProp={['fal', 'file-csv']}
          type="default"
        >
          <span>{t('common:navigationBar.exportToCsv')}</span>
        </Button>
        <Button
          disabled={!selectedTimeRecords?.length}
          onClick={onExportToCsv}
          iconProp={['fal', 'file-csv']}
          type="default"
        >
          <span>{t('common:navigationBar.exportSelectedToCsv')}</span>
        </Button>
        <Button
          disabled={!timeRecords?.length}
          onClick={onCreateReport}
          iconProp={['fal', 'file-excel']}
          type="default"
        >
          <span>{t('timeRecords:navigationBar.createReport')}</span>
        </Button>
        <Button
          disabled={!selectedTimeRecords?.length}
          onClick={() => setDeleteSelectionModalVisible(true)}
          iconProp={['fal', 'trash']}
          type="link"
        >
          <span>{t('common:actions.delete')}</span>
        </Button>
      </NavigationBar>
      <Flex.Column className={classes.overview}>
        <TimeRecordsFilter
          className={classes.filter}
          onChange={handleFilterChange}
          projectId={projectId}
          startPeriod={moment().startOf('D')}
          endPeriod={moment().endOf('D')}
          contextType={'project'}
          timeRecordsToFilter={timeRecords}
          onProjectChange={handleProjectFilterChange}
          onOfficeChange={handleOfficeFilterChange}
          onContactChange={handleContactFilterChange}
          onResetAdvancedFilter={handleResetAdvancedFilters}
          currentCompanyFilterValue={companyFilterSelected}
          currentProjectFilterValue={projectFilterSelected}
          currentContactFilterValue={contactFilterSelected}
        />
        {timeRecords?.length > 0 && (
          <Flex.Row>
            <h3>
              {t('timeRecords:total', {
                value: total_sum.toString().replace('.', ','),
              })}
            </h3>
          </Flex.Row>
        )}
        <TimeRecordsTable
          timeRecords={filteredTimeRecords}
          className={classes.table}
          onRowSelectionChange={setSelectedTimeRecords}
          onTimeRecordClick={handleTimeRecordClick}
          loading={isFetching}
          showProject={true}
        />
      </Flex.Column>
      <Drawer
        title={t('timeRecords:navigationBar.editTimeRecord')}
        placement="right"
        closable={true}
        onClose={onCloseEditRecordDrawer}
        visible={open}
        width={theme.old.components.drawerWidth}
        destroyOnClose={true}
      >
        <EditTimeRecord
          timeRecordId={selectedTimeRecordId}
          timeRecord={selectedTimecord}
          titleComponent={(timeRecord: TimeRecord) => (
            <Typography.Title level={3} className={classes.panelHeadline}>
              <FontAwesomeIcon icon={['fal', 'user-clock']} />{' '}
              {timeRecord.title}
            </Typography.Title>
          )}
          onFinish={handleFinish}
          onCancel={handleClose}
          contextType={'project'}
          formClassName={classes.form}
          setOpen={setOpen}
        />
      </Drawer>

      <Drawer
        title={t('timeRecords:navigationBar.createReport')}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={theme.old.components.drawerWidth}
        destroyOnClose={true}
      >
        <DocumentTemplateFormWrapper
          showOnlyReports={true}
          timeRecords={
            selectedTimeRecords.length > 0
              ? selectedTimeRecordObjects
              : filteredTimeRecords
          }
          onSuccess={onCreateDocumentSuccess}
          onStartCreateDocument={onStartCreateDocument}
          onCancel={onCancel}
          filter={currentFilter}
          projectPickerFilter={({
            isArchived,
            projectId: _projectId,
            parentProject,
          }) => {
            return (
              !isArchived &&
              (_projectId === projectId || parentProject === projectId)
            );
          }}
        />
      </Drawer>
      <Modal
        visible={deleteSelectionModalVisible}
        onOk={() => handleModalOk()}
        onCancel={() => setDeleteSelectionModalVisible(false)}
        title={t('timeRecords:modalDelete.title')}
        okText={t('timeRecords:modalDelete.confirm')}
        cancelText={t('timeRecords:modalDelete.cancel')}
      >
        {t(
          `accounting:modalDelete.content.${
            selectedTimeRecordObjects.length === 1 ? `single` : `multiple`
          }`,
          {
            amount: selectedTimeRecordObjects.length,
          }
        )}
      </Modal>
    </div>
  );
};
export default TimeRecordControllingSubModule;
