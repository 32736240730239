import { Col, Form, Input, Row, Select, Switch, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import { ProjectExtensionId, ProjectId } from '../../../../../../models/Types';
import { PrioTheme } from '../../../../../../theme/types';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { colon, rowGutter } from '../../../../../../util/forms';
import { apiFetchProjectFileStructure } from '../../../../../settings/api';
import { ProjectFileSystemStructureId } from '../../../../../../models/ProjectFileStructure';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    position: 'relative',
  },
}));

export interface SharepointProjectExtensionFormModel {
  displayName: string;
  projectFileSystemStructureId: string;
  hasDefaultAccess: boolean;
}

interface SharepointProjectExtensionFormProps {
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export const SharepointProjectExtensionForm: React.FC<
  SharepointProjectExtensionFormProps
> = (props) => {
  const {
    projectExtension,
    addProjectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;
  const classes = useStyles();
  const [form] = Form.useForm();
  const theme = useTheme<PrioTheme>();

  const [initialValue, setInitialValue] =
    useState<SharepointProjectExtensionFormModel>(
      projectExtension
        ? {
            displayName: projectExtension.displayName,
            projectFileSystemStructureId: projectExtension.options?.find(
              (option) => option.key === 'projectFileSystemStructureId'
            )?.value,
            hasDefaultAccess: projectExtension.hasDefaultAccess,
          }
        : {
            displayName: '',
            projectFileSystemStructureId: '',
            hasDefaultAccess: true,
          }
    );
  const [projectFileStructure, setProjectFileStructure] = useState<
    ProjectFileSystemStructureId[]
  >([]);
  const { t } = useTranslation();

  const saveOrUpdateExtension = async (
    sharepointProjectExtensionFormModel: SharepointProjectExtensionFormModel
  ) => {
    if (projectExtension && !addProjectExtension) {
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        {
          displayName: sharepointProjectExtensionFormModel.displayName,

          hasDefaultAccess:
            sharepointProjectExtensionFormModel?.hasDefaultAccess,
        }
      );
    } else if (addProjectExtension && !projectExtension) {
      await createProjectExtension(projectId, {
        displayName: sharepointProjectExtensionFormModel.displayName,
        projectExtensionType: 'sharepoint',
        hasDefaultAccess: true,
        addProjectExtensionOptions: [
          {
            key: 'projectFileSystemStructureId',
            value:
              sharepointProjectExtensionFormModel.projectFileSystemStructureId,
          },
          {
            key: 'teams',
            value: JSON.stringify({
              allowCreatePrivateChannels: true,
              allowCreateUpdateChannels: true,
              allowUserEditMessages: true,
              allowUserDeleteMessages: true,
              allowGiphy: true,
              giphyContentRating: 'strict',
            }),
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (projectExtension) {
      setInitialValue({
        displayName: projectExtension.displayName,
        projectFileSystemStructureId: projectExtension.options?.find(
          (option) => option.key === 'projectFileSystemStructureId'
        )?.value,
        hasDefaultAccess: projectExtension.hasDefaultAccess,
      });
    }
    form.resetFields();
  }, [projectExtension, form]);

  useEffect(() => {
    const loadProjectFileStructure = async () => {
      const { data } = await apiFetchProjectFileStructure();

      if (data) {
        setProjectFileStructure(data);
        if (data?.length > 0) {
          form.setFieldsValue({
            projectFileSystemStructureId:
              data[0]['projectFileSystemStructureId'],
          });
        }
      }
    };
    loadProjectFileStructure();
  }, [form]);

  return (
    <>
      {initialValue?.displayName ? (
        <>
          <p>
            {t('projects:addIn.sharepoint')}: {initialValue.displayName}
          </p>
          <p>
            {t('projects:form.labels.fileStructure')}:{' '}
            {
              projectFileStructure.find(
                (fileStructure) =>
                  fileStructure.projectFileSystemStructureId ===
                  initialValue.projectFileSystemStructureId
              )?.name
            }
          </p>
        </>
      ) : (
        <Form
          form={form}
          layout="vertical"
          className={classes.root}
          initialValues={initialValue}
          onFinish={(values) => {
            saveOrUpdateExtension(values);
          }}
          onValuesChange={(_, values) => {
            //nothingToDo
          }}
        >
          <Typography.Title level={4}>
            {t('projects:addIn.sharepoint')}
          </Typography.Title>
          <Form.Item
            name="displayName"
            required={true}
            colon={colon}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.displayName'),
              },
            ]}
            label={t('projects:addIn.extensions.sharepoint.form.displayName')}
          >
            <Input />
          </Form.Item>
          <Row
            gutter={theme.spacing.unit(rowGutter)}
            style={{ marginTop: theme.spacing.unit(3) }}
          >
            <Col span={12}>
              <Form.Item
                name="projectFileSystemStructureId"
                label={t('projects:form.labels.fileStructure')}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.missingFileStructure'),
                  },
                ]}
              >
                <Select>
                  {projectFileStructure.map((fileStructure) => (
                    <Select.Option
                      value={fileStructure.projectFileSystemStructureId}
                      key={fileStructure.projectFileSystemStructureId}
                    >
                      {fileStructure.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {
              <Col span={24}>
                <Form.Item
                  name="hasDefaultAccess"
                  colon={colon}
                  label={t('projects:addIn.extensions.form.hasDefaultAccess')}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'projects:form.validation.hasDefaultAccessRequired'
                      ),
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Switch defaultChecked={true} disabled={true} />
                </Form.Item>
              </Col>
            }
          </Row>

          <Button htmlType="submit">
            {t('projects:projectCreation.save')}
          </Button>
        </Form>
      )}
    </>
  );
};

export default SharepointProjectExtensionForm;
