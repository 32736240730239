import { useEffect, useState } from 'react';
import { TimeRecord } from '../../../models/TimeRecord';
import { CurrentAdvancedFilter } from '../components/AdvancedTimeRecordsFilter';

export const useTimeRecordsFilter = (timeRecords: TimeRecord[]) => {
  const [currentAdvancedFilter, setCurrentAdvancedFilter] =
    useState<CurrentAdvancedFilter>({
      projectFilters: [],
      contactFilters: [],
      companyFilters: [],
    });

  const [filteredTimeRecords, setFilteredTimeRecords] = useState<TimeRecord[]>(
    []
  );

  useEffect(() => {
    var timeRecordsToFilter = timeRecords;

    if (currentAdvancedFilter.companyFilters.length > 0) {
      timeRecordsToFilter = timeRecordsToFilter.filter(
        (timeRecord) =>
          currentAdvancedFilter.companyFilters.filter(
            (companyFilter) => companyFilter !== timeRecord.companyId
          ).length ===
          currentAdvancedFilter.companyFilters.length - 1
      );
    }
    if (currentAdvancedFilter.projectFilters.length > 0) {
      timeRecordsToFilter = timeRecordsToFilter.filter(
        (timeRecord) =>
          currentAdvancedFilter.projectFilters.filter(
            (projectFilter) => projectFilter !== timeRecord.projectId
          ).length ===
          currentAdvancedFilter.projectFilters.length - 1
      );
    }
    if (currentAdvancedFilter.contactFilters.length > 0) {
      timeRecordsToFilter = timeRecordsToFilter.filter(
        (timeRecord) =>
          currentAdvancedFilter.contactFilters.filter(
            (contactFilter) => contactFilter !== timeRecord.contactId
          ).length ===
          currentAdvancedFilter.contactFilters.length - 1
      );
    }

    setFilteredTimeRecords(timeRecordsToFilter);
  }, [currentAdvancedFilter, timeRecords]);

  return {
    currentAdvancedFilter,
    setCurrentAdvancedFilter,
    filteredTimeRecords,
  };
};
