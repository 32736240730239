import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Divider, Form, Row } from 'antd';
import TimeRecordsFilterProjectPicker from './TimeRecordsFilterProjectPicker';
import TimeRecordsFilterCompanyPicker from './TimeRecordsFilterCompanyPicker';
import TimeRecordsFilterContactPicker from './TimeRecordsFilterContactPicker';
import { TimeRecord, TimeRecordsFilter } from '../../../models/TimeRecord';

import {
  CompanyId,
  ContactId,
  OfficeId,
  ProjectId,
} from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { rowGutter } from '../../../util/forms';
import { distinctArray } from '../../../util';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  input: {
    width: '100%',
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  radioLabelCol: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
  },
}));

export type CurrentAdvancedFilter = {
  projectFilters: ProjectId[];
  contactFilters: ContactId[];
  companyFilters: CompanyId[];
};
interface AdvancedTimeRecordsFilterProps {
  timeRecordsFilterValues?: TimeRecordsFilter;
  companyFilterValue?: CompanyId | CompanyId[];
  projectFilterValue?: ProjectId | ProjectId[];
  contactFilterValue?: ContactId | ContactId[];
  disableCompanyPicker?: boolean;
  disableProjectPicker?: boolean;
  disableContactPicker?: boolean;
  companyFilter?: boolean;
  projectFilter?: boolean;
  contactFilter?: boolean;
  onOfficeChange: (offices: OfficeId | OfficeId[]) => void;
  onProjectChange: (projects: ProjectId | ProjectId[]) => void;
  onContactChange: (contact: ContactId | ContactId[]) => void;
  timeRecords: TimeRecord[];
}

export const AdvancedTimeRecordsFilter: React.FC<
  AdvancedTimeRecordsFilterProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    companyFilterValue,
    projectFilterValue,
    contactFilterValue,
    disableCompanyPicker,
    disableContactPicker,
    disableProjectPicker,
    companyFilter,
    projectFilter,
    contactFilter,
    onOfficeChange,
    onProjectChange,
    onContactChange,
    timeRecords,
  } = props;

  const filterProjectIds = distinctArray(timeRecords, 'projectId').map(
    (timeRecord) => timeRecord.projectId
  );

  const filterOfficeIds = distinctArray(timeRecords, 'companyId').map(
    (timeRecord) => timeRecord.companyId
  );

  const filterContactsIds = distinctArray(timeRecords, 'contactId').map(
    (timeRecord) => timeRecord.contactId
  );

  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  const calculateSpan = () => {
    const filters = [companyFilter, projectFilter, contactFilter];
    const filtersCount = filters.filter(Boolean).length;
    return 24 / filtersCount;
  };
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks
  //#endregion

  return (
    <Collapse ghost className={classes.collapse} defaultActiveKey={['1']}>
      <Collapse.Panel
        header={
          <Flex.Row flex={1}>
            <div className={classes.radioLabelCol}>
              {t('common:filter.label.filterHeader')}
            </div>
            <Divider className={classes.divider} />
          </Flex.Row>
        }
        key="1"
      >
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          {companyFilter && (
            <Col span={calculateSpan()}>
              <Form.Item label={t('common:filter.label.companyId')}>
                <TimeRecordsFilterCompanyPicker
                  value={companyFilterValue}
                  companyIds={filterOfficeIds}
                  onChange={onOfficeChange}
                  disabled={disableCompanyPicker}
                />
              </Form.Item>
            </Col>
          )}
          {projectFilter && (
            <Col span={calculateSpan()}>
              <Form.Item label={t('common:filter.label.projectId')}>
                {
                  <TimeRecordsFilterProjectPicker
                    value={projectFilterValue}
                    projectIds={filterProjectIds}
                    onChange={onProjectChange}
                    disabled={disableProjectPicker}
                  />
                }
              </Form.Item>
            </Col>
          )}
          {contactFilter && (
            <Col span={calculateSpan()}>
              <Form.Item label={t('common:filter.label.employees')}>
                <TimeRecordsFilterContactPicker
                  value={contactFilterValue}
                  contactIds={filterContactsIds}
                  onChange={onContactChange}
                  disabled={disableContactPicker}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};

export default AdvancedTimeRecordsFilter;
