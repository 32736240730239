import React, { useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../components/NavigationBar';
import InvoicesManagement from './InvoicesManagement';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  excel: {
    marginLeft: theme.old.spacing.defaultPadding,
  },
  excelIcon: {
    marginLeft: theme.old.spacing.unit(1.5),
    marginRight: theme.old.spacing.unit(1.5),
  },
}));

interface InvoicesModuleProps {
  className?: string;
}

export const InvoicesModule: React.FC<InvoicesModuleProps> = (props) => {
  const classes = useStyles();
  const { className } = props;
  const { t } = useTranslation();

  const { officeId } = useParams();

  const [createInvoiceActive, setCreateInvoiceActive] =
    useState<boolean>(false);

  const toggleCreateInvoiceActive = () =>
    setCreateInvoiceActive(!createInvoiceActive);

  const onPanelClose = () => setCreateInvoiceActive(false);

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>
        <Button
          type="primary"
          onClick={toggleCreateInvoiceActive}
          iconProp={['fal', 'file-medical']}
        >
          <span>{t('accounting:navigationBar.createInvoice')}</span>
        </Button>
        {/*
        <div className={classes.excel}>
          <FontAwesomeIcon
            className={classes.excelIcon}
            icon={['fal', 'file-excel']}
          />
          <span>{t('accounting:navigationBar.exportExcel')}</span>
        </div>
        */}
      </NavigationBar>
      <InvoicesManagement
        createInvoiceActive={createInvoiceActive}
        onClose={onPanelClose}
        officeId={officeId}
      />
    </Flex.Column>
  );
};

export default InvoicesModule;
