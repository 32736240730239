import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { makePrioStyles } from '../../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  AbsenceProposalId,
  AbsenceState,
  DateTimeString,
} from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import HeaderComponent from '../../../calendar/components/HeaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  TimeAndLeaveCalendarEvent,
  calendarEventsSelector,
  missingWorkingDaysSelector,
} from '../../selectors';
import Toolbar from '../../../calendar/components/Toolbar';
import ToolbarContent from './ToolbarContent';
import classNames from 'classnames';
import { mapAbsenceStateToColor } from '../../../absences/utils';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import MonthEventComponent from './MonthEventComponent';
import {
  debounceAbsencesMe,
  debouncedFetchOfficeHolidaysMe,
} from '../../../absences/actions';
import { debounceMonthlyCloseMe } from '../../../timeKeeping/actions';
import { debounceTimeRecordsMe } from '../../../timeRecords/actions';
import { openTimeAndLeaveManagementDrawer } from '../../actions';
import {
  RootReducerState,
  getAbsenceOverview,
  getAbsenceProposalMe,
  getAbsenceProposalsMe,
  getContact,
  getMonthlyCloseMeByMonth,
  getOfficeHolidaysOfTimeRange,
  getUserMe,
} from '../../../../apps/main/rootReducer';
import {
  AbsenceProposal,
  OfficeHoliday,
} from '../../../../models/AbsenceProposal';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import { Contact } from '../../../../models/Contact';
import { useSearchParams } from 'react-router-dom';
import MonthlyCloseConfirmDrawer from '../../../timeKeeping/components/MonthlyCloseConfirmDrawer';
import UpdateAbsenceProposal from '../../../absences/components/UpdateAbsenceProposal';
import { Drawer } from 'antd';

const localizer = momentLocalizer(moment);

const useStyles = makePrioStyles((theme) => ({
  workingHours: {
    '& .rbc-event': {
      backgroundColor: `${theme.old.palette.chromaticPalette.yellow} !important`,
    },
  },
  fullHeight: {
    '& .ant-drawer-body': {
      ' & > div': {
        height: '100%',
      },
    },
  },
  root: {
    padding: '24px',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& .rbc-row-content': {
      height: '100%',
    },
    '& .rbc-addons-dnd-row-body': {
      height: 'calc(100% - 32px)',
      '& .rbc-row': {
        height: '45%',
      },
    },
    '& .rbc-row': {
      minHeight: 32,
    },
    '& .rbc-event': {
      padding: `${theme.old.spacing.unit(0.25)}px ${theme.old.spacing.unit(
        1
      )}px`,
      height: '100%',
      backgroundColor: theme.old.palette.primaryColor,
      borderRadius: theme.old.spacing.unit(0.25),
      '&:hover': {
        opacity: 0.85,
      },
    },
    '& .rbc-event-content': {
      color: theme.old.typography.colors.inverted,
      height: '100%',
    },
    '& .rbc-addons-dnd .rbc-event': {
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    },
    '& .rbc-event:focus': {
      outline: 0,
    },
    '& .rbc-row-segment': {
      padding: '0 3px 3px 3px',
    },
    '& .rbc-event.rbc-selected': {
      backgroundColor: 'var(--ant-primary-5)',
    },
    '& .rbc-events-container': {
      margin: 0,
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon':
      {
        borderLeft: `${theme.old.spacing.unit(0.5)}px double`,
        height: '100%',
      },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor': {
      top: '25%',
      bottom: '25%',
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon':
      {
        borderTop: `${theme.old.spacing.unit(0.5)}px double`,
        margin: '4px auto',
        width: '25%',
      },
    '& .rbc-today': {
      backgroundColor: theme.old.palette.backgroundPalette.active.content,
    },
    '& .rbc-current-time-indicator': {
      backgroundColor: theme.old.palette.primaryColor,
    },
    '& .rbc-slot-selection': {
      backgroundColor: theme.old.palette.primaryColor,
      color: theme.old.typography.colors.muted,
    },
    '& .rbc-header': {
      borderLeft: 'none',
      fontSize: '16px',
      fontWeight: 'normal',
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: theme.old.borders.content,
    },
    '& .rbc-off-range-bg': {
      backgroundColor: theme.old.palette.backgroundPalette.sub,
    },
    '& .rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
    '& .rbc-off-range': {
      color: 'rgba(0, 0, 0, 0.2)',
    },
    '& .rbc-month-view': {
      border: 'none',
    },
    '& .rbc-time-view': {
      border: 'none',
    },
    '& .rbc-time-header': {
      borderRight: 'none',
    },
    '& .rbc-date-cell': {
      padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(
        1
      )}px`,
    },
    '& .rbc-date-cell.rbc-now': {
      color: theme.old.palette.primaryColor,
      '& button': {
        fontWeight: theme.old.typography.fontWeight.bold,
      },
    },
    '& .rbc-event-continues-prior': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '& .rbc-event-continues-after': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& .rbc-time-view .rbc-allday-cell': {
      height: 'auto',
    },
    '& .rbc-time-header-cell': {
      height: 32,
    },
    '& .rbc-day-slot .rbc-event': {
      border: 'none',
      borderRight: '3px solid white',
      borderTop: '3px solid white',
    },
    '& .rbc-day-bg': {
      borderLeft: theme.old.borders.content,
      overflow: 'hidden',
    },
    '& .rbc-month-row + .rbc-month-row': {
      borderTop: theme.old.borders.content,
    },
    '& .rbc-event-label': {
      display: 'none',
    },
    '& .rbc-time-content': {
      borderTop: theme.old.borders.content,
    },
    '& .rbc-timeslot-group': {
      borderBottom: theme.old.borders.content,
    },
    '& .ant-tabs-top > .ant-tabs-nav::before': {
      borderBottom: theme.old.borders.content,
    },
  },
  showMore: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(1)}px`,
    fontSize: '100%',
    fontWeight: 'normal',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  plus: {
    marginRight: theme.old.spacing.unit(0.5),
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  header: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stateGreen: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}80`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.green}20`,
    },
    '&.rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  stateGreenHoliday: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}40`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.green}20`,
    },
    '&.rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  stateGreenWeekend: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}40`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.green}20`,
    },
    '&.rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  stateYellow: {
    backgroundColor: `${theme.old.palette.chromaticPalette.yellow}80`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.yellow}20`,
    },
    '&.rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  stateYellowWeekend: {
    backgroundColor: `${theme.old.palette.chromaticPalette.yellow}40`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.yellow}20`,
    },
    '&.rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  stateRed: {
    backgroundColor: `${theme.old.palette.chromaticPalette.red}80`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.red}20`,
    },
  },
  stateGrey: {
    backgroundColor: `${theme.old.palette.chromaticPalette.grey}80`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.grey}30`,
    },
  },
  stateGreyWeekend: {
    backgroundColor: `${theme.old.palette.chromaticPalette.grey}40`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.grey}20`,
    },
  },

  missingWorkingDay: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: `2px solid ${theme.old.palette.chromaticPalette.red}`,
      borderRadius: 2,
    },
    '&.rbc-today::after': {
      border: `2px solid ${theme.old.palette.primaryColor}`,
    },
  },
  outOfRange: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  holidayTitle: {
    width: 'calc(100% - 32px)',
    height: '32px',
    padding: `${theme.old.spacing.unit(0.5)}px 0px ${theme.old.spacing.unit(
      0.5
    )}px ${theme.old.spacing.unit(1)}px`,
  },
  holidayTitleOneLineElipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  approvalRequested: {},
  '@keyframes gradientAnimation': {
    '0%': {
      backgroundPosition: '0%',
    },
    '100%': {
      backgroundPosition: '-100%',
    },
  },
  holiday: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}40`,
  },
  weekend: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}40`,
    '&.rbc-off-range-bg': {
      backgroundColor: `${theme.old.palette.chromaticPalette.green}20`,
    },
    '&.rbc-selected-cell': {
      backgroundColor: theme.old.palette.primaryColor,
    },
    '&$stateYellow': {
      backgroundColor: `${theme.old.palette.chromaticPalette.yellow}40`,
      '&.rbc-off-range-bg': {
        backgroundColor: `${theme.old.palette.chromaticPalette.yellow}20`,
      },
      '&.rbc-selected-cell': {
        backgroundColor: theme.old.palette.primaryColor,
      },
    },
    '&$stateGrey': {
      backgroundColor: `${theme.old.palette.chromaticPalette.grey}40`,
    },
  },
}));

const mapAbsenceStateToClassName = (
  absenceState: AbsenceState,
  classes: Classes
) => {
  switch (absenceState) {
    case 'accepted':
      return classes.stateGreen;
    case 'declined':
      return classes.stateRed;
    case 'requested':
      return classes.stateYellow;
    case 'revokeAccepted':
      return classes.stateRed;
    case 'revokeDeclined':
      return classes.stateGreen;
    case 'revokeRequested':
      return classes.stateYellow;
    case 'planned':
      return classes.stateGrey;
    default:
      return classes.stateGrey;
  }
};

const todayMonth = moment().format('YYYY-MM');

export interface TimeAndLeaveManagementCalendarPropsRef {
  confirmCurrentMonthlyClose: () => void;
}

interface TimeAndLeaveManagementCalendarProps {
  className?: string;
  onEmployeeConfirmationChange?: (value: undefined | boolean) => void;
  isClosedMonth?: boolean;
  isMonthClosable?: boolean;
}

export const TimeAndLeaveManagementCalendar = forwardRef<
  TimeAndLeaveManagementCalendarPropsRef,
  TimeAndLeaveManagementCalendarProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const {
    className,
    onEmployeeConfirmationChange,
    isClosedMonth,
    isMonthClosable,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const absenceOverview = useSelector(getAbsenceOverview);
  const absenceProposals = useSelector(getAbsenceProposalsMe);

  const searchParamsMonth = useMemo(
    () => searchParams.get('month'),
    [searchParams]
  );

  const [currentDate, setCurrentDate] = useState<DateTimeString>(null);

  const currentMonthDate = useMemo(
    () => currentDate ?? searchParamsMonth ?? todayMonth,
    [currentDate, searchParamsMonth]
  );

  const [selectedAbsenceProposalId, setSelectedAbsenceProposalId] =
    useState<AbsenceProposalId>(null);
  const [showMoreBackgroundColor, setShowMoreBackgroundColor] =
    useState<string>(theme.old.palette.backgroundPalette.content);
  const [showMoreColor, setShowMoreColor] = useState<string>('#000');

  const calendarEvents = useSelector(calendarEventsSelector(currentMonthDate));
  const missingWorkingDaysByDay = useSelector(
    missingWorkingDaysSelector(currentMonthDate)
  );
  const userMe = useSelector(getUserMe);
  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, userMe?.id)
  );

  const currentMonthlyClose = useSelector<RootReducerState, MonthlyClose>(
    (state) => getMonthlyCloseMeByMonth(state, currentMonthDate)
  );

  const officeHolidaysThreeMonthsTimeRange = useSelector<
    RootReducerState,
    OfficeHoliday[]
  >((state) =>
    getOfficeHolidaysOfTimeRange(
      state,
      moment(currentMonthDate)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .substring(0, 7),
      moment(currentMonthDate)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .substring(0, 7)
    )
  );

  const absenceProposal = useSelector<RootReducerState, AbsenceProposal>(
    (state) => getAbsenceProposalMe(state, selectedAbsenceProposalId)
  );

  const formats = {
    dayRangeHeaderFormat: ({ start, end }) => {
      if (start.getMonth() === end.getMonth()) {
        return (
          moment(start).format('D.') + ' - ' + moment(end).format('D. MMMM')
        );
      }
      return (
        moment(start).format('D. MMMM') + ' - ' + moment(end).format('D. MMMM')
      );
    },
    dayHeaderFormat: ({ day }) => {
      return moment(day).format('D. MMMM, dd.');
    },
  };

  const [showConfirmMonthlyClose, setShowConfirmMonthlyClose] =
    useState<boolean>(false);

  const calendarEventsWithoutUnnecessaryAnnualLeavePlanning: TimeAndLeaveCalendarEvent[] =
    useMemo(() => {
      const calendarEventsAbsences = calendarEvents.filter(
        (event) =>
          event?.resources[0]?.resources?.absenceType !== 'annualLeavePlanning'
      );
      const calendarEventsAnnualLeavePlanning = calendarEvents.filter(
        (event) =>
          event?.resources[0]?.resources?.absenceType === 'annualLeavePlanning'
      );
      const _calendarEvents = [...calendarEventsAbsences];

      const filterDuplicate = (absencePlanning: TimeAndLeaveCalendarEvent) => {
        const from = moment(absencePlanning.start);
        const to = moment(absencePlanning.end);

        const duplicate = _calendarEvents.find((absence) => {
          const fromAbsence = moment(absence.start);
          const toAbsence = moment(absence.end);

          return fromAbsence.isSame(from) && toAbsence.isSame(to);
        });
        return !duplicate ? true : false;
      };

      _calendarEvents.push(
        ...calendarEventsAnnualLeavePlanning.filter(filterDuplicate)
      );

      return _calendarEvents;
    }, [calendarEvents]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnSelectedEvent = (event: TimeAndLeaveCalendarEvent) => {
    if (event?.resources[0]?.resources?.type !== 'absence') {
      dispatch(
        openTimeAndLeaveManagementDrawer({
          tab: 'timeKeeping',
          selectedTimeRange: {
            startDateTime: moment(event.start).toISOString(true).split('T')[0],
            endDateTime: moment(event.end).toISOString(true).split('T')[0],
          },
        })
      );
    } else {
      setSelectedAbsenceProposalId(event.id);
      setAbsenceProposalDetailsDrawerOpen(true);
    }
  };

  const handleOnSelectedSlot = (value: any) => {
    dispatch(
      openTimeAndLeaveManagementDrawer({
        selectedTimeRange: {
          startDateTime: moment(value.start).toISOString(true).split('T')[0],
          endDateTime: moment(value.end)
            .subtract(1, 'days')
            .toISOString(true)
            .split('T')[0],
        },
      })
    );
  };

  const handleToolbarChange = useCallback(
    (dateTime: DateTimeString) => {
      const start = moment(dateTime)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = moment(dateTime)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      dispatch(debounceAbsencesMe(start, end));
      dispatch(debounceMonthlyCloseMe(start, end));
      dispatch(debounceTimeRecordsMe(start, end));
      if (contact?.officeId) {
        dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
      }
      setCurrentDate(dateTime.substring(0, 7));
      setSearchParams({ month: dateTime.substring(0, 7) });
    },
    [contact, dispatch, setSearchParams]
  );

  const getAllDayClassName = (date: Date) => {
    const event = calendarEventsWithoutUnnecessaryAnnualLeavePlanning
      .sort()
      .find((event) => {
        return (
          event.allDay &&
          moment(date).isSameOrAfter(moment(event.start), 'day') &&
          moment(date).isSameOrBefore(moment(event.end), 'day')
        );
      });
    const isHoliday = officeHolidaysThreeMonthsTimeRange.find(
      (holiday) => moment(holiday.date).isSame(date),
      'day'
    );
    const isOutOfRange = !moment(date).isSame(
      moment(currentMonthDate),
      'month'
    );
    const isWeekend =
      moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7;
    const absenceType = event?.resources[0]?.resources?.type;

    if (isHoliday && isOutOfRange) return classes.stateGreenHoliday;
    if (isHoliday) return classes.stateGreenHoliday;
    if (event) {
      if (absenceType === 'absence') {
        const { absenceState, firstIsHalfDay, lastIsHalfDay } =
          event?.resources[0]?.resources;
        const missingWorkingDay =
          missingWorkingDaysByDay[moment(date).toISOString(true).split('T')[0]];
        if (!event.allDay) {
          return '';
        }
        return classNames(mapAbsenceStateToClassName(absenceState, classes), {
          [classes.holiday]: isHoliday,
          [classes.weekend]: isWeekend,
          [classes.missingWorkingDay]:
            (absenceState !== 'accepted' ||
              (absenceState === 'accepted' &&
                (firstIsHalfDay || lastIsHalfDay))) &&
            Object.keys(missingWorkingDaysByDay).length > 0 &&
            !!missingWorkingDay,
        });
      }
    }

    if (isOutOfRange && absenceType !== 'absence') return classes.outOfRange;

    if (Object.keys(missingWorkingDaysByDay).length > 0) {
      const missingWorkingDay =
        missingWorkingDaysByDay[moment(date).toISOString(true).split('T')[0]];
      if (missingWorkingDay) {
        return classes.missingWorkingDay;
      }
    }
    return null;
  };

  const getEventBackgroundColor = (event: TimeAndLeaveCalendarEvent) => {
    switch (event?.resources[0]?.resources.type) {
      case 'absence': {
        const { absenceState, absenceStateType } =
          event?.resources[0]?.resources;
        return mapAbsenceStateToColor(absenceState, absenceStateType, theme);
      }
      default: {
        const includesRequest = (event?.resources ?? [])?.find(
          ({ resources }) =>
            resources?.type === 'timeKeeping' &&
            resources?.state === 'approvalRequested'
        );
        const includesNotApproved = (event?.resources ?? [])?.find(
          ({ resources }) =>
            resources?.type === 'timeKeeping' &&
            resources?.state === 'notApproved'
        );
        if (includesRequest) {
          return theme.old.palette.chromaticPalette.yellow;
        }
        if (includesNotApproved) {
          return theme.old.palette.chromaticPalette.red;
        }
        return theme.old.palette.backgroundPalette.base;
      }
    }
  };

  const getSpecialHeight = (event: TimeAndLeaveCalendarEvent) => {
    switch (event?.resources[0]?.resources.type) {
      default: {
        return '100%';
      }
    }
  };

  const confirmCurrentMonthlyClose = () => {
    setShowConfirmMonthlyClose(true);
  };
  //#endregion

  //#region ------------------------------ Components
  const customToolbar = useCallback(
    (toolbar) => {
      return (
        <Toolbar
          toolbar={toolbar}
          onChange={handleToolbarChange}
          disabledViews={['day', 'week', 'month']}
        >
          <ToolbarContent date={toolbar.date} />
        </Toolbar>
      );
    },
    [handleToolbarChange]
  );

  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    confirmCurrentMonthlyClose,
  }));

  useEffect(() => {
    if (!currentDate) {
      const start = moment(currentMonthDate)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = moment(currentMonthDate)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      dispatch(debounceAbsencesMe(start, end));
      dispatch(debounceMonthlyCloseMe(start, end));
      dispatch(debounceTimeRecordsMe(start, end));
      if (contact?.officeId) {
        dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
      }
      setCurrentDate(currentMonthDate);
    }
  }, [currentDate, currentMonthDate, contact, dispatch]);

  useEffect(() => {
    if (onEmployeeConfirmationChange) {
      if (currentMonthlyClose?.employeeConfirmation === undefined) {
        onEmployeeConfirmationChange(false);
      } else {
        onEmployeeConfirmationChange(
          !currentMonthlyClose?.employeeConfirmation
        );
      }
    }
  }, [currentMonthlyClose?.employeeConfirmation, onEmployeeConfirmationChange]);

  useEffect(() => {
    if (!searchParamsMonth) {
      setSearchParams({
        month: todayMonth,
      });
    }
  }, [searchParamsMonth, setSearchParams]);
  //#endregion
  const [
    absenceProposalDetailsDrawerOpen,
    setAbsenceProposalDetailsDrawerOpen,
  ] = useState<boolean>(false);
  return (
    <div className={classNames(classes.root, className)}>
      <Calendar
        scrollToTime={
          new Date(moment().hours(7).minutes(0).seconds(0).toDate())
        }
        formats={formats}
        events={calendarEventsWithoutUnnecessaryAnnualLeavePlanning}
        localizer={localizer}
        defaultDate={currentMonthDate}
        view={'month'}
        views={['month']}
        onSelectEvent={handleOnSelectedEvent} //returns Event
        dayLayoutAlgorithm="no-overlap"
        dayPropGetter={(date: Date) => ({
          className: getAllDayClassName(date),
        })}
        eventPropGetter={(event: TimeAndLeaveCalendarEvent) => ({
          style: {
            backgroundColor: getEventBackgroundColor(event),
            height: getSpecialHeight(event),
          },
          className: (event?.resources ?? [])?.find(
            ({ resources }) =>
              resources?.type === 'timeKeeping' &&
              resources?.state === 'approvalRequested'
          )
            ? classes.approvalRequested
            : undefined,
        })}
        onSelectSlot={(value) => handleOnSelectedSlot(value)}
        drilldownView="month"
        onDrillDown={(date: Date) =>
          handleOnSelectedSlot({ start: moment(date), end: moment(date) })
        }
        popup
        components={{
          toolbar: customToolbar,
          dateCellWrapper: (date) => {
            const dateHoliday = officeHolidaysThreeMonthsTimeRange.find(
              (holiday) =>
                moment(holiday.date).isSame(moment(date.value), 'day')
            );
            const events =
              calendarEventsWithoutUnnecessaryAnnualLeavePlanning.filter(
                (event) => {
                  return (
                    moment(event.start).isSame(moment(date.value), 'day') ||
                    moment(event.end).isSame(moment(date.value), 'day') ||
                    moment(date.value).isBetween(
                      moment(event.start),
                      moment(event.end)
                    )
                  );
                }
              );
            const isOutOfRange = !moment(date.value).isSame(
              moment(currentMonthDate),
              'month'
            );
            return (
              <div {...date.children.props}>
                {dateHoliday && (
                  <div
                    className={classNames(classes.holidayTitle, {
                      [classes.holidayTitleOneLineElipsis]: events.length > 0,
                    })}
                    style={{
                      color: `rgba(0, 0, 0${isOutOfRange ? ',0.2' : ''}
                    `,
                    }}
                  >
                    {dateHoliday.name}
                  </div>
                )}
              </div>
            );
          },
          month: {
            header: (value) => <HeaderComponent value={value} view={'month'} />,
            event: (value) => <MonthEventComponent event={value.event} />,
          },
        }}
        messages={{
          showMore: (total) => (
            <div
              onMouseOver={() => {
                setShowMoreBackgroundColor(
                  theme.old.palette.backgroundPalette.content
                );
                setShowMoreColor(theme.old.palette.primaryColor);
              }}
              onMouseEnter={() => {
                setShowMoreBackgroundColor(
                  theme.old.palette.backgroundPalette.content
                );
                setShowMoreColor(theme.old.palette.primaryColor);
              }}
              onMouseLeave={() => {
                setShowMoreBackgroundColor(
                  theme.old.palette.backgroundPalette.content
                );
                setShowMoreColor('#000');
              }}
              className={classes.showMore}
              style={{
                backgroundColor: showMoreBackgroundColor,
                color: showMoreColor,
              }}
            >
              <FontAwesomeIcon
                icon={['fal', 'plus']}
                className={classes.plus}
                onMouseOver={() => {
                  setShowMoreBackgroundColor(
                    theme.old.palette.backgroundPalette.content
                  );
                  setShowMoreColor(theme.old.palette.primaryColor);
                }}
                onMouseEnter={() => {
                  setShowMoreBackgroundColor(
                    theme.old.palette.backgroundPalette.content
                  );
                  setShowMoreColor(theme.old.palette.primaryColor);
                }}
                onMouseLeave={() => {
                  setShowMoreBackgroundColor(
                    theme.old.palette.backgroundPalette.content
                  );
                  setShowMoreColor('#000');
                }}
                style={{
                  backgroundColor: showMoreBackgroundColor,
                  color: showMoreColor,
                }}
              />
              {`${total} ${t('calendar:showMore')}`}
            </div>
          ),
        }}
        selectable
        showAllEvents={false}
      />
      <MonthlyCloseConfirmDrawer
        monthlyClose={currentMonthlyClose}
        visible={showConfirmMonthlyClose}
        onClose={() => {
          setShowConfirmMonthlyClose(false);
        }}
        enableEditableTimeKeepingDays
        isClosedMonth={isClosedMonth}
        isMonthClosable={isMonthClosable}
      />
      <Drawer
        width={theme.old.components.drawerWidth}
        closable={true}
        onClose={() => setAbsenceProposalDetailsDrawerOpen(false)}
        visible={absenceProposalDetailsDrawerOpen}
        closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
        destroyOnClose={true}
        className={classes.fullHeight}
      >
        <UpdateAbsenceProposal
          absenceOverview={{ ...absenceOverview, absenceProposals }}
          absenceProposal={absenceProposal}
          onFinish={() => setAbsenceProposalDetailsDrawerOpen(false)}
          setDrawerOpen={setAbsenceProposalDetailsDrawerOpen}
        />
      </Drawer>
    </div>
  );
});

export default TimeAndLeaveManagementCalendar;
