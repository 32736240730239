import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import moment, { Moment } from 'moment';
import { timebarFormat as defaultTimebarFormat, TimebarFormat } from './const';
import { HeaderBarResolutions } from './types';
import { calculateSections } from './utils';
import Flex from '../Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from 'theming';
import { PrioTheme } from '../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
  },
  headerBarPrefixCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBarSuffixCell: {
    borderLeft: theme.old.borders.content,
    borderBottom: theme.old.borders.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  barOuter: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    height: '100%',
  },
  bar: {
    minHeight: 32,
    flex: 1,
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
  },
  bottomBar: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  },
  barItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderLeft: theme.old.borders.content,
    borderBottom: theme.old.borders.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    fontSize: theme.old.typography.fontSize.small,
  },
  currentSection: {
    minWidth: 175,
    textAlign: 'center',
  },
  visibleTimeRangeStepper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: 'transparent',
    width: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));

export declare type StepMode = 'jump' | 'stepDiff' | 'stepResolution';

interface TimelineHeaderProps {
  className?: string;
  prefixClassName?: string;
  suffixClassName?: string;
  visibleTimeStart: Moment;
  visibleTimeEnd: Moment;
  startTime: Moment;
  endTime: Moment;
  resolutions: HeaderBarResolutions;
  timebarFormat?: TimebarFormat;
  disableTopBar?: boolean;
  disableBottomBar?: boolean;
  enableStepper?: boolean;
  timelineWidth: number;
  prefixWidth: number;
  suffixWidth: number;
  prefixRenderer?: () => React.ReactNode;
  suffixRenderer?: () => React.ReactNode;
  onTimeRangeChange: (
    visibleTimeStart: Moment,
    visibleTimeEnd: Moment,
    startTime: Moment,
    endTime: Moment
  ) => void;
  onVisibleStartEndTimeChange?: (startTime: Moment, endTime: Moment) => void;
  stepMode?: StepMode;
}

export const TimelineHeader: React.FC<TimelineHeaderProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    prefixClassName,
    suffixClassName,
    visibleTimeStart,
    visibleTimeEnd,
    startTime,
    endTime,
    resolutions,
    timebarFormat = defaultTimebarFormat,
    disableTopBar,
    disableBottomBar,
    enableStepper,
    timelineWidth,
    prefixWidth,
    suffixWidth,
    prefixRenderer,
    suffixRenderer,
    onTimeRangeChange,
    onVisibleStartEndTimeChange,
    stepMode = 'jump',
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const topSections = useMemo(
    () =>
      calculateSections(
        resolutions.top,
        visibleTimeStart,
        visibleTimeEnd,
        timelineWidth
      ),
    [resolutions.top, visibleTimeStart, visibleTimeEnd, timelineWidth]
  );

  const bottomSections = useMemo(
    () =>
      calculateSections(
        resolutions.bottom,
        visibleTimeStart,
        visibleTimeEnd,
        timelineWidth
      ),
    [resolutions.bottom, visibleTimeStart, visibleTimeEnd, timelineWidth]
  );

  const formatLength = useMemo(
    () => (timelineWidth > 720 ? 'long' : 'short'),
    [timelineWidth]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleStepToday = () => {
    let nextStartTime: Moment = startTime.clone();
    let nextEndTime: Moment = endTime.clone();

    const todayStartDiff = nextStartTime.diff(moment(), resolutions.top);
    const todayEndDiff = nextEndTime.diff(moment(), resolutions.top);
    const diff =
      todayStartDiff >= 0
        ? Math.max(todayStartDiff, todayEndDiff)
        : Math.min(todayStartDiff, todayEndDiff);

    switch (stepMode) {
      case 'jump': {
        nextStartTime = startTime.clone().subtract(diff, resolutions.top);
        nextEndTime = endTime.clone().subtract(diff, resolutions.top);
        break;
      }
      case 'stepDiff': {
        const diff = nextEndTime.diff(nextStartTime, resolutions.bottom);
        while (
          nextStartTime.isAfter(moment()) ||
          nextEndTime.isBefore(moment())
        ) {
          if (nextEndTime.isBefore(moment())) {
            nextStartTime = nextEndTime.clone();
            nextEndTime = nextStartTime.clone().add(diff, resolutions.bottom);
          } else {
            nextEndTime = nextStartTime.clone();
            nextStartTime = nextEndTime
              .clone()
              .subtract(diff, resolutions.bottom);
          }
        }
        break;
      }
      case 'stepResolution': {
        nextStartTime = moment().startOf(resolutions.top);
        nextEndTime = moment().endOf(resolutions.top);
        break;
      }
    }

    handelTimeRangeChange(nextStartTime, nextEndTime);
  };

  const handleStepBackward = () => {
    let nextStartTime: Moment = startTime.clone();
    let nextEndTime: Moment = endTime.clone();

    switch (stepMode) {
      case 'jump': {
        nextStartTime = startTime.clone().subtract(1, resolutions.top);
        nextEndTime = endTime.clone().subtract(1, resolutions.top);
        break;
      }
      case 'stepDiff': {
        const diff = endTime.diff(startTime, resolutions.bottom);
        nextStartTime = startTime.clone().subtract(diff, resolutions.bottom);
        nextEndTime = startTime.clone();
        break;
      }
      case 'stepResolution': {
        nextStartTime = startTime
          .clone()
          .subtract(1, resolutions.top)
          .startOf(resolutions.top);
        nextEndTime = endTime
          .clone()
          .subtract(1, resolutions.top)
          .endOf(resolutions.top);
        break;
      }
    }

    handelTimeRangeChange(nextStartTime, nextEndTime);
  };

  const handleStepForward = () => {
    let nextStartTime: Moment = startTime.clone();
    let nextEndTime: Moment = endTime.clone();
    switch (stepMode) {
      case 'jump': {
        nextStartTime = startTime.clone().add(1, resolutions.top);
        nextEndTime = endTime.clone().add(1, resolutions.top);
        break;
      }
      case 'stepDiff': {
        const diff = endTime.diff(startTime, resolutions.bottom);
        nextStartTime = endTime.clone();
        nextEndTime = endTime.clone().add(diff, resolutions.bottom);
        break;
      }
      case 'stepResolution': {
        nextStartTime = startTime
          .clone()
          .add(1, resolutions.top)
          .startOf(resolutions.top);
        nextEndTime = endTime
          .clone()
          .add(1, resolutions.top)
          .endOf(resolutions.top);
        break;
      }
    }
    handelTimeRangeChange(nextStartTime, nextEndTime);
  };

  const handelTimeRangeChange = (
    nextStartTime: Moment,
    nextEndTime: Moment
  ) => {
    const diffStartTimeNotVisible = visibleTimeStart.diff(
      startTime,
      resolutions.bottom
    );
    const diffEndTimeNotVisible = endTime.diff(
      visibleTimeEnd,
      resolutions.bottom
    );

    const nextVisualStart: Moment = nextStartTime
      .clone()
      .add(diffStartTimeNotVisible, resolutions.bottom);
    const nextVisualEnd: Moment = nextEndTime
      .clone()
      .subtract(diffEndTimeNotVisible, resolutions.bottom);

    onTimeRangeChange(
      nextVisualStart,
      nextVisualEnd,
      nextStartTime,
      nextEndTime
    );
  };

  const handleOnVisibleStartEndTimeChange = (direction: 'left' | 'right') => {
    if (onVisibleStartEndTimeChange) {
      if (direction === 'left') {
        onVisibleStartEndTimeChange(
          visibleTimeStart.clone().subtract(1, resolutions.bottom),
          visibleTimeEnd.clone().subtract(1, resolutions.bottom)
        );
      } else {
        onVisibleStartEndTimeChange(
          visibleTimeStart.clone().add(1, resolutions.bottom),
          visibleTimeEnd.clone().add(1, resolutions.bottom)
        );
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column>
        {enableStepper && (
          <Flex.Row
            className={classes.stepper}
            childrenGap={theme.old.spacing.unit(1)}
          >
            <Button
              type="link"
              iconProp={['fal', 'calendar']}
              onClick={handleStepToday}
            />
            <Button
              type="link"
              iconProp={['fal', 'chevron-left']}
              onClick={handleStepBackward}
            />

            <div className={classes.currentSection}>
              {topSections
                .at(
                  (topSections.length - 1) % 2 === 1
                    ? Math.round((topSections.length - 1) / 2)
                    : (topSections.length - 1) / 2
                )
                ?.date?.format(
                  timebarFormat.majorLabels[resolutions.top].long
                ) ?? ''}
            </div>

            <Button
              type="link"
              iconProp={['fal', 'chevron-right']}
              onClick={handleStepForward}
            />
          </Flex.Row>
        )}
        <Flex.Row flex={1} className={classes.header}>
          <div
            className={classNames(classes.headerBarPrefixCell, prefixClassName)}
            style={{ width: prefixWidth }}
          >
            {prefixRenderer && prefixRenderer()}
          </div>
          <div className={classes.barOuter}>
            {!disableTopBar && (
              <div className={classNames(classes.bar, classes.topBar)}>
                {topSections.map(({ width, date }) => (
                  <div
                    className={classes.barItem}
                    style={{
                      width,
                    }}
                  >
                    {date.format(
                      timebarFormat.majorLabels[resolutions.top][formatLength]
                    )}
                  </div>
                ))}
              </div>
            )}
            {!disableBottomBar && (
              <div className={classNames(classes.bar, classes.bottomBar)}>
                {startTime.isBefore(visibleTimeStart, resolutions.bottom) && (
                  <div
                    className={classes.visibleTimeRangeStepper}
                    style={{
                      left: 0,
                      background: `linear-gradient(90deg, ${theme.old.palette.backgroundPalette.main}80 0%, rgba(0,0,0,0) 50%)`,
                    }}
                    onClick={() => handleOnVisibleStartEndTimeChange('left')}
                  >
                    <FontAwesomeIcon icon={['fal', 'chevron-left']} />
                  </div>
                )}
                {bottomSections.map(({ width, date }) => (
                  <div
                    className={classes.barItem}
                    style={{
                      width,
                    }}
                  >
                    {date.format(
                      timebarFormat.minorLabels[resolutions.bottom][
                        formatLength
                      ]
                    )}
                  </div>
                ))}
                {endTime.isAfter(visibleTimeEnd, resolutions.bottom) && (
                  <div
                    className={classes.visibleTimeRangeStepper}
                    style={{
                      right: 0,
                      background: `linear-gradient(270deg, ${theme.old.palette.backgroundPalette.main}80 0%, rgba(0,0,0,0) 50%)`,
                    }}
                    onClick={() => handleOnVisibleStartEndTimeChange('right')}
                  >
                    <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(classes.headerBarSuffixCell, suffixClassName)}
            style={{ width: suffixWidth }}
          >
            {suffixRenderer && suffixRenderer()}
          </div>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default TimelineHeader;
