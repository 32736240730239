import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { MonthlyCloseId, OfficeId } from '../../../../models/Types';
import Flex from '../../../../components/Flex';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { apiFetchMonthlyCloses } from '../../api';
import HRMonthlyClosePageTable from './HRMonthlyClosePageTable';
import useDatePickerLocale from '../../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import equals from 'deep-equal';
import HRMonthlyCloseDrawer from './HRMonthlyCloseDrawer';
import CreateCompensationPaymentsDrawer from './CreateCompensationPaymentsDrawer';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../../apps/main/rootReducer';
import { sortContactsHelper } from '../../../contacts/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    flex: 1,
  },
  content: {
    padding: theme.old.spacing.defaultPadding,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  picker: {
    minWidth: 200,
  },
}));

interface HRMonthlyClosePageProps {
  className?: string;
  officeId?: OfficeId;
  setMultipleCompensationPaymentsSelected: (value: boolean) => void;
}

export interface HRMonthlyClosePageRefProps {
  openCreateCompensationPaymentDrawer: VoidFunction;
}

export const HRMonthlyClosePage = forwardRef<
  HRMonthlyClosePageRefProps,
  HRMonthlyClosePageProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const { className, officeId, setMultipleCompensationPaymentsSelected } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contactsById = useSelector(getContactsByIdState);
  const [monthlyCloses, setMonthlyCloses] = useState<MonthlyClose[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment().startOf('month').subtract(1, 'month').add(1, 'day').utc()
  );
  const [selectedMonthlyClose, setSelectedMonthlyClose] =
    useState<MonthlyClose | null>(null);

  const [selectedMonthlyCloses, setSelectedMonthlyCloses] = useState<
    MonthlyClose[]
  >([]);

  const [
    showCreateCompensationPaymentsDrawer,
    setShowCreateCompensationPaymentsDrawer,
  ] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onMonthlyCloseSelect = (monthlyClose: MonthlyClose) => {
    setSelectedMonthlyClose(monthlyClose);
    setOpen(true);
  };

  const handleOnRecalculateMonthlyClose = (
    monthlyClose: MonthlyClose,
    previousId: MonthlyCloseId
  ) => {
    const index = monthlyCloses.findIndex(
      (mc) => mc.monthlyCloseId === previousId
    );
    if (index > -1) {
      const newMonthlyCloses = [...monthlyCloses];
      newMonthlyCloses[index] = monthlyClose;
      setMonthlyCloses(newMonthlyCloses);
    }
    if (equals(selectedMonthlyClose, monthlyClose)) {
      setSelectedMonthlyClose(monthlyClose);
    }
  };

  const getMonthlyCloses = useCallback(
    async (value: Moment) => {
      if (value) {
        setSelectedDate(value);
        setLoading(true);
        const { result, data } = await apiFetchMonthlyCloses(
          officeId,
          value.startOf('month').utc().toISOString(),
          value.endOf('month').utc().toISOString()
        );
        if (result.status >= 200 && result.status < 300) {
          setMonthlyCloses(
            data.sort(
              ({ employeeId: employeeIdA }, { employeeId: employeeIdB }) => {
                const a = contactsById[employeeIdA];
                const b = contactsById[employeeIdB];
                return sortContactsHelper(a, b);
              }
            )
          );
        } else {
          setMonthlyCloses([]);
        }
        setLoading(false);
      }
    },
    [officeId, contactsById]
  );

  const reloadMonthlyCloses = async () => {
    await getMonthlyCloses(selectedDate);
  };

  const handleOnFinishDetailsTab = (monthlyClose: MonthlyClose) => {
    const index = monthlyCloses.findIndex(
      (mc) => mc.monthlyCloseId === monthlyClose.monthlyCloseId
    );
    if (index > -1) {
      const newMonthlyCloses = [...monthlyCloses];
      newMonthlyCloses[index] = monthlyClose;
      setMonthlyCloses(
        newMonthlyCloses.sort(
          ({ employeeId: employeeIdA }, { employeeId: employeeIdB }) => {
            const a = contactsById[employeeIdA];
            const b = contactsById[employeeIdB];
            return sortContactsHelper(a, b);
          }
        )
      );
    }
    setSelectedMonthlyClose(monthlyClose);
  };

  const handleOnRowSelectionChange = (monthlyCloses: MonthlyClose[]) => {
    setSelectedMonthlyCloses(monthlyCloses);
    setMultipleCompensationPaymentsSelected(monthlyCloses.length > 0);
  };

  const handleOnCreateCompensationPaymentsDrawerClose = (
    shouldReload?: boolean
  ) => {
    if (shouldReload) {
      reloadMonthlyCloses();
    }
    setShowCreateCompensationPaymentsDrawer(false);
    setSelectedMonthlyCloses([]);
    setMultipleCompensationPaymentsSelected(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    openCreateCompensationPaymentDrawer: () => {
      setShowCreateCompensationPaymentsDrawer(true);
    },
  }));

  useEffect(() => {
    getMonthlyCloses(
      moment().startOf('month').subtract(1, 'month').add(1, 'day').utc()
    );
  }, [getMonthlyCloses]);
  //#endregion

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      <Flex.Column className={classes.content}>
        <Flex.Row>
          <DatePicker
            onChange={getMonthlyCloses}
            picker="month"
            placeholder={t('hr:timeAndLeaveManagement.monthPicker')}
            className={classes.picker}
            locale={datePickerLocale}
            value={selectedDate}
          />
          <Button
            id="prio-month-picker-search"
            onClick={reloadMonthlyCloses}
            iconProp={['fal', 'search']}
            type="link"
          ></Button>
        </Flex.Row>
        <HRMonthlyClosePageTable
          officeId={officeId}
          monthlyCloses={monthlyCloses}
          selectedMonthlyCloses={selectedMonthlyCloses}
          tableId={'mcp1'}
          onRowClick={onMonthlyCloseSelect}
          onRecalculate={handleOnRecalculateMonthlyClose}
          onRowSelectionChange={handleOnRowSelectionChange}
          loading={loading}
        />
      </Flex.Column>
      <HRMonthlyCloseDrawer
        open={open}
        setOpen={setOpen}
        monthlyClose={selectedMonthlyClose}
        setSelectedMonthlyClose={setSelectedMonthlyClose}
        officeId={officeId}
        onFinishDetailsTab={handleOnFinishDetailsTab}
        reloadMonthlyCloses={reloadMonthlyCloses}
      />
      <CreateCompensationPaymentsDrawer
        visible={showCreateCompensationPaymentsDrawer}
        officeId={officeId}
        monthlyCloses={selectedMonthlyCloses.sort(
          ({ employeeId: employeeIdA }, { employeeId: employeeIdB }) => {
            const a = contactsById[employeeIdA];
            const b = contactsById[employeeIdB];
            return sortContactsHelper(a, b);
          }
        )}
        onClose={handleOnCreateCompensationPaymentsDrawerClose}
      />
    </Flex.Row>
  );
});

export default HRMonthlyClosePage;
